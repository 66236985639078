<!-- <div class="row p-2"></div>
<h1 class=" text-success mr-auto ml-auto text-center mb-4">{{ 'SUBSCRIPTION.membersip_plans' | translate }}</h1>
<div class="row pt-0">
	<h4 *ngIf="plans.length == 0" class="text-danger text-center m-auto">{{ 'SUBSCRIPTION.no_plans' | translate }}</h4>
	<div class="col-md-6 col-lg-6 col-xl-3" *ngFor="let plan of plans">
		<div class="card card-pricing text-center px-3 mb-4 "> <span *ngIf="selectedlanguage == 'fr';else english" class="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-success text-white shadow-sm plan-name">{{plan.plan_name_french}}</span>
			<ng-template #english> <span class="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-success text-white shadow-sm plan-name">{{plan.plan_name}}</span>
			</ng-template>
			<div class="bg-transparent card-header pt-4 border-0">
				<h1 class="h1 font-weight-normal text-success text-center mb-0 dollar" data-pricing-value="15">$<span class="price">{{plan.plans.amount/100}} + Taxes</span><span class="h6 text-muted ml-2"></span></h1>
			</div>
			<h3 *ngIf="selectedlanguage == 'fr';else otherlang" class="mb-4">{{plan.plan_name_french}}</h3>
			<ng-template #otherlang>
				<h3 class="mb-4">{{plan.plan_name}}</h3>
			</ng-template>
			<div class="card-body pt-0">
				<div *ngIf="currentSubscription && currentSubscription.subscription.plan.id == plan.plans.id"> <strong>{{ 'SUBSCRIPTION.start_date' | translate }}</strong>
					<p>{{unixStartDate * 1000 | date : 'MM/dd/yyyy H:mm'}}</p> <strong>{{ 'SUBSCRIPTION.end_date' | translate }}</strong>
					<p>{{unixEndDate * 1000 | date : 'MM/dd/yyyy H:mm'}}</p>
				</div>
				<h3 *ngIf="currentSubscription && currentSubscription.subscription.plan.id == plan.plans.id"><span class="badge badge-warning text-light">{{ 'SUBSCRIPTION.current_plan' | translate }}</span></h3>
				<button *ngIf="!currentSubscription || currentSubscription.subscription.plan.id != plan.plans.id" type="button" class="btn btn-success mb-3 planBtn" (click)="selectModal(plan.plans.id)"><strong>{{ 'SUBSCRIPTION.select_plan' | translate }}</strong>
				</button>
			</div>
		</div>
	</div>
	<div *ngIf="plans && plans.length != 0 && selectedlanguage == 'en'" class="col-12">
		<h4 class="subs-heading">TERMS AND POLICY</h4>
		<ul class="list-unstyled">
			<li>The subscription is billed in advance. You will receive an invoice for each delivery made in public mode.</li>
			<li>The invoice amount will be debited from the credit card on file.</li>
			<li>The start of billing will be on the date of subscription.</li>
			<li>A delivery billed in public mode corresponds to any complete order by the delivery person via the application.</li>
			<li>The service is renewed automatically at the end of your subscription.</li>
			<li>You may discontinue the service upon written notice at least 10 days before the end of your subscription cycle by email.</li>
			<li>In order to prepare your accesses as well as the other essential elements for a successful implementation, you agree to provide us with a deadline of up to 24 hours following your subscription.</li>
			<li>Vaistat Solutions Inc. reserves the right to modify all of the terms and conditions herein upon 30 days notice.</li>
		</ul>
		<h4 class="subs-heading">THANK YOU FOR YOUR TRUST, VAISTAT SOLUTIONS INC.</h4>
	</div>
	<div *ngIf="plans && plans.length != 0 && selectedlanguage == 'fr'" class="col-12">
		<h4 class="subs-heading">TERMES ET CONDITIONS</h4>
		<ul class="list-unstyled">
			<li>L’abonnement est facturé à l’avance. Vous recevrez une facture pour chaque livraison effectuée en mode public.</li>
			<li>Le montant de la facture sera prélevé à la carte de crédit inscrit au dossier.</li>
			<li>Le début de la facturation sera à la date de l'inscription.</li>
			<li>Une livraison facturée en mode public correspond à toute commande complétée par le livreur via l’application.</li>
			<li>Le service est renouvelé automatiquement à la fin de votre abonnement.</li>
			<li>Vous pouvez interrompre le service sur avis écrit d’au moins 10 jours avant la fin du cycle de votre abonnement par courriel.</li>
			<li>Afin de préparer vos accès ainsi que les autres éléments essentiels à une implantation réussie, vous acceptez de nous fournir un délais allant jusqu'a 24 heures suivant votre abonnement.</li>
			<li>Solutions Vaistat Inc. se réserve le droit de modifier, l’ensemble des termes et conditions de la présente sur préavis de 30 jours.</li>
		</ul>
		<h4 class="subs-heading">SOLUTIONS VAISTAT INC. VOUS REMERCIE DE VOTRE CONFIANCE</h4>
	</div>
</div>

<div bsModal #successModal1="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-danger" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">Confirmation</h4>
				<button type="button" class="close" (click)="successModal1.hide()" aria-label="Close"> <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>Do you really want to cancel this plan ?</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" (click)="successModal1.hide()">{{'JOBS.no' | translate}}</button>
				<button type="button" class="btn btn-secondary" (click)="cancelSubscription()">{{'JOBS.yes' | translate}}</button>
			</div>
		</div>
	</div>
</div>
<div bsModal #successModal2="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-success" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">Confirmation</h4>
				<button type="button" class="close" (click)="successModal2.hide()" aria-label="Close"> <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>{{ 'SUBSCRIPTION.select_plan_comment' | translate }}</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" (click)="successModal2.hide()">{{'JOBS.no' | translate}}</button>
				<button type="button" class="btn btn-secondary" (click)="selectPlan()">{{'JOBS.yes' | translate}}</button>
			</div>
		</div>
	</div>
</div> -->

<link
  href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
  rel="stylesheet"
/>
<div class="card mt-3">
  <div class="card-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="scp-breadcrumb">
          <ul class="breadcrumb">
            <li class="b-jobs">
              <a>{{ "SUBSCRIPTION.Subscription" | translate }}</a
              ><br />
              <p class="membership-plan-txt">
                {{ "SUBSCRIPTION.membersip_plans" | translate }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row subscriptions-plans">
      <div
        class="col-md-3"
        *ngFor="let plan of plans; let i = index"
        [ngClass]="i % 2 != 0 ? 'subscription-margin' : ''"
      >
        <div class="card">
          <div class="card-body" style="padding: 20px 0 0 0">
            <p *ngIf="selectedlanguage == 'fr'; else english">
              {{ plan.plan_name_french }}
            </p>
            <ng-template #english>
              <p>{{ plan.plan_name }}</p>
            </ng-template>
            <div class="underline"></div>
            <h4>
              ${{ plan.plans.amount / 100 }} +
              {{ "SUBSCRIPTION.taxes" | translate }}
            </h4>
            <p *ngIf="plan.plan_name == 'Yearly'">
              {{ "SUBSCRIPTION.yearly_comment" | translate }}
            </p>

            <div class="bottom-footer subscription-bg{{ i + 1 }}">
              <button
                class="btn btn-default"
                *ngIf="plan_id == plan._id; else elsePlan"
                (click)="selectPlanData(plan._id)"
                style="color: white; background-color: #4dbd74"
              >
                {{ "SUBSCRIPTION.selected_plan" | translate }}
              </button>
              <ng-template #elsePlan>
                <button
                  class="btn btn-default"
                  *ngIf="selectPlanId?.planId != plan.plans.id"
                  (click)="selectPlanData(plan._id)"
                >
                  {{ "SUBSCRIPTION.select_plan" | translate }}
                </button>
                <button
                  class="btn btn-default"
                  *ngIf="selectPlanId?.planId == plan.plans.id"
                  style="color: white; background-color: #4dbd74"
                >
                  {{ "SUBSCRIPTION.current_plan" | translate }}
                </button>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h4 *ngIf="plans.length == 0" class="text-danger text-center m-auto">
      {{ "SUBSCRIPTION.no_plans" | translate }}
    </h4>
    <div class="row">
      <button
        *ngIf="plan_id"
        class="btn btn-default process-pay-btn"
        (click)="goToPaymentPage()"
      >
        {{ "SUBSCRIPTION.proceed_to_pay" | translate }}
      </button>
    </div>
    <!-- <hr> -->
    <div class="card accordians" style="padding: 10px 20px">
      <div *ngIf="selectedlanguage == 'en'; else frenchPolicy" class="col-12">
        <div class="card-body" style="padding: 0; text-align: center">
          <p>TERMS AND POLICY</p>
        </div>
        <br />
        <ul class="list-unstyled">
          <li>
            The subscription is billed in advance. You will receive an invoice
            for each delivery made in public mode.
          </li>
          <li>
            The invoice amount will be debited from the credit card on file.
          </li>
          <li>The start of billing will be on the date of subscription.</li>
          <li>
            A delivery billed in public mode corresponds to any complete order
            by the delivery person via the application.
          </li>
          <li>
            The service is renewed automatically at the end of your
            subscription.
          </li>
          <li>
            You may discontinue the service upon written notice at least 10 days
            before the end of your subscription cycle by email.
          </li>
          <li>
            In order to prepare your accesses as well as the other essential
            elements for a successful implementation, you agree to provide us
            with a deadline of up to 24 hours following your subscription.
          </li>
          <li>
            Vaistat Solutions Inc. reserves the right to modify all of the terms
            and conditions herein upon 30 days notice.
          </li>
        </ul>
        <h4
          class="subs-heading"
          style="border-top: 1px solid #ccc; background-color: white"
        >
          THANK YOU FOR YOUR TRUST, VAISTAT SOLUTIONS INC.
        </h4>
      </div>
      <ng-template #frenchPolicy>
        <div *ngIf="selectedlanguage == 'fr'" class="col-12">
          <div class="card-body" style="padding: 0; text-align: center">
            <p>TERMES ET CONDITIONS</p>
          </div>
          <br />
          <ul class="list-unstyled">
            <li>
              L’abonnement est facturé à l’avance. Vous recevrez une facture
              pour chaque livraison effectuée en mode public.
            </li>
            <li>
              Le montant de la facture sera prélevé à la carte de crédit inscrit
              au dossier.
            </li>
            <li>Le début de la facturation sera à la date de l'inscription.</li>
            <li>
              Une livraison facturée en mode public correspond à toute commande
              complétée par le livreur via l’application.
            </li>
            <li>
              Le service est renouvelé automatiquement à la fin de votre
              abonnement.
            </li>
            <li>
              Vous pouvez interrompre le service sur avis écrit d’au moins 10
              jours avant la fin du cycle de votre abonnement par courriel.
            </li>
            <li>
              Afin de préparer vos accès ainsi que les autres éléments
              essentiels à une implantation réussie, vous acceptez de nous
              fournir un délais allant jusqu'a 24 heures suivant votre
              abonnement.
            </li>
            <li>
              Solutions Vaistat Inc. se réserve le droit de modifier, l’ensemble
              des termes et conditions de la présente sur préavis de 30 jours.
            </li>
          </ul>
          <h4
            class="subs-heading"
            style="border-top: 1px solid #ccc; background-color: white"
          >
            SOLUTIONS VAISTAT INC. VOUS REMERCIE DE VOTRE CONFIANCE
          </h4>
        </div>
      </ng-template>
    </div>
  </div>
</div>
