<div class="mt-3">
  <app-table
    [theadTemplate]="theadTemplateJobs"
    [tbodyTemplate]="tbodyTemplateJobs"
    [noFoundTemplate]="noFoundTemplateJobs"
    [totalItems]="jobs_count"
    (onPageChange)="onPageChange($event)"
    [currentPage]="pageNumber"
  ></app-table>
  <ng-template #theadTemplateJobs>
    <tr>
      <th *ngIf="showCheckBox">
        <div class="form-check">
          <input
            class="form-check-input"
            id="flexCheckDefault"
            type="checkbox"
            style="width: 20px; height: 20px"
            (change)="checkAll()"
            [(ngModel)]="checkedAll"
          />
          <label
            class="form-check-label"
            for="flexCheckDefault"
            style="padding-left: 5px; padding-top: 7px"
          >
            {{ "JOBS.all" | translate }}
          </label>
        </div>
      </th>

      <th>{{ "JOBS.job_id" | translate }}</th>
      <th style="width: 15%">{{ "JOBS.client_name" | translate }}</th>
      <th>{{ "JOBS.address" | translate }}</th>
      <th>{{ "JOBS.initial" | translate }}</th>
      <th>{{ "JOBS.due_date" | translate }}</th>
      <th>{{ "JOBS.status" | translate }}</th>
      <th>{{ "JOBS.amount" | translate }}</th>
      <ng-container *ngTemplateOutlet="extraColumnThTemplate"></ng-container>
      <th>{{ "CLIENTS.driver_id" | translate }}</th>
      <th>{{ "JOBS.action" | translate }}</th>
    </tr>
  </ng-template>
  <ng-template #tbodyTemplateJobs>
    <tbody [formGroup]="formGroup">
      <tr
        formArrayName="checkGroup"
        [ngClass]="{
          delivery: job.job_type == jobType.DELIVERY,
          pickup: job.job_type == jobType.PICKUP
        }"
        style="position: relative"
        *ngFor="
          let job of jobs
            | paginate
              : {
                  itemsPerPage: 10,
                  currentPage: pageNumber,
                  totalItems: jobs_count,
                  id: 'first'
                };
          let i = index
        "
      >
        <ng-container [formGroupName]="i">
          <td *ngIf="showCheckBox">
            <input
              *ngIf="arrayCheckGroup?.value[i]?.sent === false"
              type="checkbox"
              formControlName="check"
              style="width: 20px; height: 20px"
              (change)="onChangeCheck($event)"
            />
            <i
              *ngIf="arrayCheckGroup?.value[i]?.sent === true"
              class="fa fa-check text-success fa-2x"
              aria-hidden="true"
            ></i>
          </td>
          <td
            (click)="
              showJobModel(
                job._id,
                jobDetailsBodyTemplate,
                jobDetailsHeaderTemplate
              )
            "
          >
            <p-tag
              severity="danger"
              *ngIf="job.delivery_type === '1'"
              value="Urgent"
              style="position: absolute; top: -5px; left: 0; font-size: 10px"
            />
            {{ job.booking_code }}
          </td>

          <td
            *ngIf="job.customer_type == '2'; else activeElse"
            (click)="
              showJobModel(
                job._id,
                jobDetailsBodyTemplate,
                jobDetailsHeaderTemplate
              )
            "
          >
            <span style="font-weight: 800; text-transform: uppercase">{{
              job.customer_l_name
            }}</span>
            {{ job.customer_f_name }}
          </td>
          <ng-template #activeElse>
            <td
              (click)="
                showJobModel(
                  job._id,
                  jobDetailsBodyTemplate,
                  jobDetailsHeaderTemplate
                )
              "
            >
              {{
                job.establishment_name
                  ? job.establishment_name
                  : job.customer_name
              }}
            </td>
          </ng-template>

          <td
            *ngIf="job.job_type == '1'"
            (click)="
              showJobModel(
                job._id,
                jobDetailsBodyTemplate,
                jobDetailsHeaderTemplate
              )
            "
          >
            {{ sortAddress(job.drop_off_location) }}
          </td>

          <td
            *ngIf="job.job_type == '2'"
            (click)="
              showJobModel(
                job._id,
                jobDetailsBodyTemplate,
                jobDetailsHeaderTemplate
              )
            "
          >
            {{ sortAddress(job.pick_up_location) }}
          </td>

          <td
            (click)="
              showJobModel(
                job._id,
                jobDetailsBodyTemplate,
                jobDetailsHeaderTemplate
              )
            "
            *ngIf="job.job_create_initials; else initialsElse"
          >
            {{ job.job_create_initials }}
          </td>
          <ng-template #initialsElse>
            <td
              (click)="
                showJobModel(
                  job._id,
                  jobDetailsBodyTemplate,
                  jobDetailsHeaderTemplate
                )
              "
            >
              N/A
            </td>
          </ng-template>

          <td
            (click)="
              showJobModel(
                job._id,
                jobDetailsBodyTemplate,
                jobDetailsHeaderTemplate
              )
            "
          >
            {{ modifyDate(job.job_date) }}
          </td>
          <td
            (click)="
              showJobModel(
                job._id,
                jobDetailsBodyTemplate,
                jobDetailsHeaderTemplate
              )
            "
          >
            <button
              *ngIf="
                job.job_status == 'P' &&
                job.isAccepted == false &&
                !job.isExpired
              "
              class="stats-active"
              style="background: #ffe4c8; color: #ff8b17"
            >
              {{ "JOBS.pending" | translate }}
            </button>

            <button
              *ngIf="job.job_status == 'P' && job.isAccepted == true"
              class="stats-active"
            >
              {{ "JOBS.accepted" | translate }}
            </button>
            <button *ngIf="job.job_status == 'A'" class="stats-active">
              {{ "JOBS.active" | translate }}
            </button>
            <button *ngIf="job.job_status == 'C'" class="stats-complete">
              {{ "JOBS.completed" | translate }}
            </button>

            <button
              *ngIf="
                job.isExpired == true &&
                job.job_status != 'C' &&
                job.job_status != 'N' &&
                job.job_status != 'CN'
              "
              class="stats-complete"
              style="background: #efe2e2; color: #ff010d"
            >
              {{ "JOBS.expired" | translate }}
            </button>

            <button
              *ngIf="job.job_status == 'CN'"
              class="stats-complete"
              style="background: #efe2e2; color: #ff010d"
            >
              {{ "JOBS.cancelled" | translate }}
            </button>
            <button *ngIf="job.job_status == 'N'" class="stats-notcomplete">
              N/D
            </button>
            <a
              class="ml-2"
              *ngIf="
                ((job.job_status == 'CN' && job.isExpired == false) ||
                  job.job_status == 'N') &&
                !job.recreatedJobId
              "
              ><i
                class="fa fa-undo"
                [ngbTooltip]="'JOBS.RECREATE_JOB' | translate"
                aria-hidden="true"
                (click)="openRecreateJobModal(job._id, $event)"
              ></i
            ></a>
            <a class="ml-2" *ngIf="job.recreatedJobId"
              ><i
                class="fa fa-info-circle"
                [ngbTooltip]="'JOBS.IS_ALREADY_RECREATED' | translate"
                aria-hidden="true"
              ></i
            ></a>
            <div *ngIf="job.isRecursiveJob">
              <button class="stats-recursive text-danger">
                <i class="fa fa-circle mr-2" aria-hidden="true"></i
                >{{ "JOBS.recursive" | translate }}
              </button>
            </div>
          </td>
          <td
            (click)="
              showJobModel(
                job._id,
                jobDetailsBodyTemplate,
                jobDetailsHeaderTemplate
              )
            "
          >
            <span class="amount">${{ job.job_amount | number : "1.2-2" }}</span>
            <p
              *ngIf="
                job.job_status == 'C' &&
                job.customer_payment_id != null &&
                job.customer_payment_id.payment_method == '2'
              "
              class="cash"
            >
              {{ "JOBS.cash" | translate }}
            </p>

            <p
              *ngIf="
                job.job_status == 'C' &&
                job.customer_payment_id != null &&
                job.customer_payment_id.payment_method == '1'
              "
              class="cash"
            >
              {{ "JOBS.card" | translate }}
            </p>

            <p
              *ngIf="
                job.job_status == 'C' &&
                job.customer_payment_id != null &&
                job.customer_payment_id.payment_method == '3'
              "
              class="cash"
            >
              {{ "JOBS.cheque" | translate }}
            </p>
            <p
              *ngIf="
                job.job_status == 'C' &&
                job.customer_payment_id != null &&
                job.customer_payment_id.payment_method == '5'
              "
              class="cash"
            >
              {{ "TPV" | translate }}
            </p>
          </td>
          <ng-container
            [ngTemplateOutlet]="extraColumnTdTemplate"
            [ngTemplateOutletContext]="{
              $implicit: job
            }"
          ></ng-container>
          <td
            style="cursor: pointer"
            (click)="
              showJobModel(
                job._id,
                jobDetailsBodyTemplate,
                jobDetailsHeaderTemplate
              )
            "
          >
            {{ job?.driver_id?.username ? job?.driver_id?.username : "N/A" }}
          </td>

          <!-- Action td -->
          <td>
            <ul
              *ngIf="
                !job.transferToAnotherDispatch?.isTransferred;
                else jobTransferred
              "
              class="action-icons"
            >
              <!--    <li>
                <img
                  src="assets/img/new-ui/notification.svg"
                  (click)="openNotificationsModal(job._id)"
                  ngbTooltip="Driver Notifications"
                />
              </li> -->
              <li
                *ngIf="
                  (job.job_status === 'A' || job.job_status === 'P') &&
                  job.isExpired == false
                "
              >
                <img
                  src="assets/img/new-ui/edit.svg"
                  (click)="
                    editJobModel(
                      job._id,
                      editJobBodyTemplate,
                      editJobHeaderTemplate
                    )
                  "
                  ngbTooltip="Edit"
                />
              </li>
              <ng-container
                [ngTemplateOutlet]="tableActionTemplate"
                [ngTemplateOutletContext]="{
                  $implicit: job
                }"
              ></ng-container>
              <li>
                <img
                  src="assets/img/new-ui/print.svg"
                  (click)="getPdf(job._id)"
                  ngbTooltip="Download"
                />
              </li>
              <li
                *ngIf="
                  (job.job_status === 'A' || job.job_status === 'P') &&
                  job.driver_id
                "
              >
                <img
                  src="assets/img/new-ui/notification.svg"
                  (click)="openNotificationModal(job.driver_id._id, job._id)"
                  ngbTooltip="Send Notification"
                />
              </li>
            </ul>
          </td>
          <ng-template #jobTransferred
            ><span class="font-weight-bold">{{
              "JOBS.transferred" | translate
            }}</span></ng-template
          >
        </ng-container>
      </tr>
    </tbody>
  </ng-template>
  <ng-template #noFoundTemplateJobs>
    <div class="no-jobs-found">
      <p class="no-item text-center text-danger mb-0" *ngIf="!jobs.length">
        {{ "JOBS.no_jobs_found" | translate }}
      </p>
    </div>
  </ng-template>
</div>
<ng-template #jobDetailsHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3">
    <ng-container
      *ngTemplateOutlet="jobDetailsComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #jobDetailsBodyTemplate>
  <app-job-details [job_id]="job_id"></app-job-details>
</ng-template>

<ng-template #editJobHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3">
    <ng-container
      *ngTemplateOutlet="editJobComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #editJobBodyTemplate>
  <app-edit-job [job_id]="job_id" [parentModalRef]="bsModalRef"></app-edit-job>
</ng-template>

<ng-template #recreateJobTemplate>
  <p *ngIf="driverMessage?.reasonForNotDelivered">
    <span class="font-weight-bold"
      >{{ "JOBS.REASON_FOR_NOT_DELIVERED" | translate }} :
    </span>
    {{ driverMessage?.reasonForNotDelivered }}
  </p>
  <p *ngIf="driverMessage?.commentForNotDelivered">
    <span class="font-weight-bold"
      >{{ "JOBS.COMMENT_FOR_NOT_DELIVERED" | translate }} : </span
    >{{ driverMessage?.commentForNotDelivered }}
  </p>
  <p *ngIf="driverMessage?.cancel_note_driver">
    <span class="font-weight-bold"
      >{{ "JOBS.CANCEL_NOTE_DRIVER" | translate }} : </span
    >{{ driverMessage?.cancel_note_driver }}
  </p>
  <ng-container *ngFor="let img of driverMessage.imageForNotDelivered">
    <ng-template [ngIf]="img">
      <img
        [src]="img"
        class="m-2 point"
        style="width: 50px; height: 50px"
        (click)="openImage(img, fullSizeImageTemplate)"
      />
    </ng-template>
  </ng-container>
  <div class="calender-custom">
    <input
      class="form-control box-m-in"
      [minDate]="minDate"
      placeholder="Date"
      [(ngModel)]="recreateJobDate"
      ngbDatepicker
      #recreateJobDatePicker="ngbDatepicker"
      [readonly]="true"
      (click)="recreateJobDatePicker.toggle()"
      style="cursor: pointer; display: inline-block; width: 100%"
      firstDayOfWeek="7"
    />
    <img
      src="./assets/img/new-ui/calendar_field.svg"
      (click)="recreateJobDatePicker.toggle()"
    />
  </div>
  <textarea
    [(ngModel)]="reasonForRecreateJob"
    class="form-control"
    [placeholder]="'JOBS.REASON' | translate"
    rows="4"
  ></textarea>
</ng-template>

<ng-template #fullSizeImageTemplate>
  <div class="d-flex justify-content-center">
    <img [src]="fullSizeImage" class="img-fluid" />
  </div>
</ng-template>

<div
  bsModal
  #sendToDriverNotificationModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          {{ "JOBS.send_notification_comment" | translate }}
        </h4>
        <button
          type="button"
          class="close"
          (click)="sendToDriverNotificationModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="form-group">
          <input
            type="text"
            class="form-control inputs"
            placeholder="{{ 'JOBS.enter_title' | translate }}"
            [(ngModel)]="notificationTitle"
          />
        </div>
        <div class="form-group">
          <textarea
            class="form-control area-mess"
            rows="5"
            placeholder="{{ 'JOBS.enter_message' | translate }}"
            [(ngModel)]="notificationText"
          ></textarea>
        </div>
      </div>

      <div class="modal-footer" style="margin: auto; margin-right: 0">
        <button
          type="button"
          class="btn btn-secondary client-cancel-btn"
          (click)="sendToDriverNotificationModal.hide()"
        >
          {{ "JOBS.cancel" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary add-client-btn"
          style="width: 80px"
          (click)="sendNotificationToDriver()"
        >
          {{ "JOBS.send" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #notificationsModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          {{ "JOBS.notifications" | translate }}
        </h4>
        <button
          type="button"
          class="close"
          (click)="notificationsModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <table class="table">
          <tr *ngFor="let noti of driverNotifications">
            <td>
              <img
                *ngIf="noti?.driver_id && !noti?.driver_id.profile_img"
                class="mr-2"
                width="50px"
                src="assets/img/new-ui/avatar_without_image_notification.svg"
              />
              <img
                *ngIf="noti?.driver_id && noti?.driver_id.profile_img"
                class="mr-2"
                width="50px"
                [src]="noti?.driver_id.profile_img"
              />
            </td>
            <td>
              <b>{{ noti?.driver_id.fullname }}</b>
            </td>
            <td>
              <span *ngIf="language == 'en'">{{ noti.message }}</span>
              <span *ngIf="language == 'fr'">
                {{ noti.french_message }}
              </span>
            </td>
          </tr>
        </table>
      </div>

      <div class="modal-footer" style="margin: auto; margin-right: 0">
        <button
          type="button"
          class="btn btn-secondary client-cancel-btn"
          (click)="notificationsModal.hide()"
        >
          {{ "JOBS.close" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
