('use strict');
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { PushNotificationService } from './push-notification.service';
// import { Subject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})

export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  firebaseToken: string;
  //  private componentMethodCallSource = new Subject<any>();
  // Observable string streams
  //  componentMethodCalled$ = this.componentMethodCallSource.asObservable();

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    public PushNotification: PushNotificationService,
  ) { }

  /**
 * update token in firebase database
 * 
 * @param userId userId as a key 
 * @param token token as a value
 */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFireDB.object('fcmTokens/').update(data)
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission() {
    const promise = new Promise((resolve, reject) => {
      this.angularFireMessaging.requestToken.subscribe(
        (token) => {
          resolve(token);
        },
        (err) => {
          console.error('Unable to get permission to notify.', err);
          reject(err);
        }
      );
    });
    return promise;
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        // this.callComponentMethod();
        // this.componentMethodCallSource.next();
        this.currentMessage.next(payload);
        this.PushNotification.generateNotification(payload);
        this.PushNotification.callComponentMethod();
        //1 FOR GET  UNREAD NOTIFICATION
      });
  }


}
