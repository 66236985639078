<ng-template #dynamicContent>
  <div class="d-flex gap-1 ml-auto">
    <app-button
      *ngIf="jobDetails?.isAccepted == false"
      [imageSrc]="'./assets/img/new-ui/refresh_map.svg'"
      [buttonText]="'CREATEJOB.retry' | translate"
      (buttonClick)="editJob('retry')"
    ></app-button>
    <app-button
      [imageSrc]="'./assets/img/new-ui/save.svg'"
      [buttonText]="
        recursive_id
          ? ('JOBS.save_job_recursive' | translate)
          : ('CREATEJOB.save_job' | translate)
      "
      (click)="editJob('save')"
    ></app-button>
  </div>
</ng-template>
<form>
  <div class="row">
    <div class="col-xl-6 col-sm-12 float-left border-right">
      <div class="padding-all">
        <div class="c-info">
          <h5 class="font-weight-bold">
            {{ "CREATEJOB.customer_type" | translate }}
          </h5>
          <div class="main-box">
            <div class="radio-box custom-radio-width">
              <input
                id="radio-1"
                type="radio"
                name="radio-01"
                [value]="1"
                [(ngModel)]="selected_customer"
                [ngModelOptions]="{ standalone: true }"
                (click)="changeCustomerType(1)"
              />
              <label for="radio-1" class="c-j-label">
                <span> {{ "CREATEJOB.hospital" | translate }}</span>
              </label>
              <input
                id="radio-2"
                type="radio"
                name="radio-01"
                [value]="2"
                [(ngModel)]="selected_customer"
                [ngModelOptions]="{ standalone: true }"
                (click)="changeCustomerType(2)"
              />
              <label for="radio-2" class="c-j-label">
                <span>{{ "CREATEJOB.patient" | translate }}</span>
              </label>
              <input
                id="radio-3"
                type="radio"
                name="radio-01"
                [value]="3"
                [(ngModel)]="selected_customer"
                [ngModelOptions]="{ standalone: true }"
                (click)="changeCustomerType(3)"
              />
              <label for="radio-3" class="c-j-label">
                <span> {{ "CREATEJOB.doctor" | translate }} </span>
              </label>
              <input
                id="radio-4"
                type="radio"
                name="radio-01"
                [value]="4"
                [(ngModel)]="selected_customer"
                [ngModelOptions]="{ standalone: true }"
                (click)="changeCustomerType(4)"
              />
              <label for="radio-4" class="c-j-label">
                <span> Cpo</span>
              </label>
            </div>
          </div>
        </div>

        <div class="c-info" style="margin-top: 5px">
          <div class="d-flex gap-1">
            <p>{{ "CREATEJOB.customer_info" | translate }}</p>
            <div *ngIf="customer_type == '1'" class="custom-radio-width">
              <input
                id="singleMultiple1"
                name="singleMultiple"
                type="radio"
                (click)="changeCustomerTypeSelection(1)"
                [checked]="customerTypeSelection == 1"
              />
              <label class="c-j-label" for="singleMultiple1">{{
                "CREATEJOB.single" | translate
              }}</label>
              <input
                id="singleMultiple2"
                name="singleMultiple"
                type="radio"
                (click)="changeCustomerTypeSelection(2)"
                [checked]="customerTypeSelection == '2'"
              />
              <label class="c-j-label" for="singleMultiple2">{{
                "CREATEJOB.multiple" | translate
              }}</label>
            </div>
          </div>

          <div *ngIf="customer_type != '2'" class="row mt-3">
            <div class="form-group col-md-6">
              <p-autoComplete
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="establishment_name"
                [suggestions]="establishments"
                (completeMethod)="searchEstablishmentDelivery($event.query)"
                (onSelect)="onSelectEstablish($event)"
                field="establishment_name"
                [size]="30"
                [minLength]="1"
                placeholder="{{ 'CREATEJOB.establish_name' | translate }}"
                class="auto-in auto-cust"
              >
                <ng-template let-customer_name pTemplate="item">
                  <div>{{ customer_name.establishment_name }}</div>
                </ng-template>
              </p-autoComplete>
            </div>
            <div class="form-group col-md-6">
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'CREATEJOB.establishment_email' | translate }}"
                maxlength="40"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="establishment_email"
              />
            </div>
            <div class="form-group col-md-6">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ dial_code }}</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{
                    'CREATEJOB.establishment_phone' | translate
                  }}"
                  maxlength="12"
                  (ngModelChange)="establishmentPhoneChange($event)"
                  [ngModel]="establishment_phone"
                  [ngModelOptions]="{ standalone: true }"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                />
              </div>
            </div>
          </div>

          <div class="d-flex" *ngIf="customer_type != '2'">
            <div class="d-flex gap-1">
              <p>
                {{
                  customer_type == "4"
                    ? ("CREATEJOB.person_responsible" | translate)
                    : ("CREATEJOB.information" | translate)
                }}
              </p>
              <div class="custom-radio-width">
                <input
                  id="receptionMedicareDeliveryNo"
                  name="receptionMedicare"
                  type="radio"
                  (click)="onSelectTag('0', [16, 17])"
                  [checked]="selected_type == '0'"
                />
                <label class="c-j-label" for="receptionMedicareDeliveryNo">{{
                  "CREATEJOB.no" | translate
                }}</label>
                <input
                  id="receptionMedicare1"
                  name="receptionMedicare"
                  type="radio"
                  checked
                  (click)="onSelectTag(16, 17)"
                  [checked]="selected_type == '16'"
                />
                <label class="c-j-label" for="receptionMedicare1">{{
                  "CREATEJOB.reception" | translate
                }}</label>
                <span *ngIf="customer_type != '4'">
                  <input
                    id="receptionMedicare2"
                    name="receptionMedicare"
                    type="radio"
                    (click)="onSelectTag(17, 16)"
                    [checked]="selected_type == '17'"
                  />
                  <label class="c-j-label" for="receptionMedicare2">{{
                    "CREATEJOB.medicare" | translate
                  }}</label>
                </span>
              </div>
            </div>
          </div>

          <div
            class="row"
            *ngIf="
              customerTypeSelection == 1 && establishmentTagSelection == '0'
            "
          >
            <div class="col-md-6">
              <div class="form-group">
                <div class="animated-inputs">
                  <p-autoComplete
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="customer_name"
                    [suggestions]="residenceAllClients"
                    (completeMethod)="search_customer($event.query)"
                    (onSelect)="onSelect($event)"
                    field="customer_f_name"
                    [size]="30"
                    [minLength]="1"
                    placeholder="{{ 'CREATEJOB.firstname' | translate }}"
                    class="auto-in auto-cust"
                  >
                    <ng-template let-customer_name pTemplate="item">
                      <div>
                        {{ customer_name.customer_f_name }}
                        {{ customer_name.customer_l_name }}, ({{
                          customer_name.country_code
                        }}
                        {{ customer_name.phone_no }})
                      </div>
                    </ng-template>
                  </p-autoComplete>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <div class="animated-inputs">
                  <p-autoComplete
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="customer_L_name"
                    [suggestions]="residenceAllClients"
                    (completeMethod)="search_customer($event.query)"
                    field="customer_l_name"
                    [size]="30"
                    [minLength]="1"
                    placeholder="{{ 'CREATEJOB.lastname' | translate }}"
                    class="auto-in auto-cust"
                  >
                    <ng-template let-customer_name pTemplate="item">
                      <div (click)="setLastName(customer_name)">
                        {{ customer_name.customer_f_name }}
                        {{ customer_name.customer_l_name }}, ({{
                          customer_name.country_code
                        }}
                        {{ customer_name.phone_no }})
                      </div>
                    </ng-template>
                  </p-autoComplete>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <div class="animated-inputs">
                  <div class="form-group w-100">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">{{ dial_code }}</span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="{{ 'CREATEJOB.phone_no' | translate }}"
                        (ngModelChange)="mychange($event)"
                        [ngModel]="iban_no"
                        [ngModelOptions]="{ standalone: true }"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        maxlength="12"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <div class="animated-inputs">
                  <div class="form-group w-100">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{ 'CREATEJOB.customer_email' | translate }}"
                      [(ngModel)]="customer_email"
                      [ngModelOptions]="{ standalone: true }"
                      maxlength="40"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row mb-3"
            *ngIf="customer_type == '2' && customerAddresses.length"
          >
            <div class="col-md-2 d-flex align-items-center">
              <div class="font-weight-bold">
                {{ "CREATEJOB.address" | translate }}
              </div>
            </div>
            <div class="col-md-10">
              <p-dropdown
                placeholder="{{ 'CREATEJOB.customer_location' | translate }}"
                [options]="customerAddresses"
                [formControl]="selectedAddress"
                optionLabel="{{ language === 'fr' ? 'address_fr' : 'address' }}"
                (onChange)="handleAddressChange($event, true)"
              >
              </p-dropdown>
            </div>
          </div>

          <div class="row animated-pd" style="position: relative">
            <div class="form-group w-100">
              <div class="animated-inputs">
                <div class="">
                  <input
                    type="text"
                    [(ngModel)]="locationString"
                    class="form-control location-input"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="{{
                      'CREATEJOB.customer_location' | translate
                    }}"
                    [disabled]="isDisabledInputLocation"
                    (click)="openModalSelectLocation()"
                  />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="customerTypeSelection == 2">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <div class="my-custom-dropdown">
                    <ng-multiselect-dropdown
                      placeholder="{{
                        'CREATEJOB.select_customers' | translate
                      }}"
                      [settings]="dropdownSettings2"
                      [data]="residenceAllClients"
                      [(ngModel)]="residenceAllClient"
                      [ngModelOptions]="{ standalone: true }"
                      (onSelect)="onItemSelect2($event)"
                      (onDeSelect)="onDeSelect2($event)"
                      (onSelectAll)="onSelectAll2($event)"
                      (onDeSelectAll)="onDeSelectAll2($event)"
                      style="width: 90%; background-color: white"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="customerTypeSelection == 1">
            <div *ngIf="establishmentTagSelection == '0'">
              <div class="row"></div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="animated-inputs">
                      <div class="form-group w-100">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="{{
                            'CREATEJOB.appartment_no' | translate
                          }}"
                          [(ngModel)]="appartment_no"
                          [ngModelOptions]="{ standalone: true }"
                          maxlength="20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="animated-inputs">
                      <div class="form-group w-100">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="{{ 'CREATEJOB.door_code' | translate }}"
                          [(ngModel)]="door_code"
                          [ngModelOptions]="{ standalone: true }"
                          maxlength="20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group w-100">
              <textarea
                id="textarea"
                rows="1"
                class="form-control"
                placeholder="{{ 'CREATEJOB.note' | translate }}"
                [(ngModel)]="note"
                [ngModelOptions]="{ standalone: true }"
              ></textarea>
              <div
                class="form-group w-100"
                style="margin-top: 10px; border-radius: 0.25rem"
              >
                <textarea
                  id="textarea"
                  rows="1"
                  class="form-control"
                  placeholder="{{ 'CREATEJOB.note_custo' | translate }}"
                  [(ngModel)]="customer_note"
                ></textarea>
              </div>
            </div>
            <div
              class="row"
              *ngIf="jobDetails?.job_type == '1' && customer_type != '4'"
            >
              <div class="col-md-6">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="{{ 'CREATEJOB.item_price' | translate }}"
                    [(ngModel)]="item_price"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    [ngModelOptions]="{ standalone: true }"
                    maxlength="10"
                    [disabled]="is_edit == 'false' ? true : null"
                  />
                </div>
              </div>

              <div class="col-md-6 text-center">
                <span
                  class="custom-checkbox item-price-charge checkbox-style-1"
                >
                  <input
                    type="checkbox"
                    name="Charge"
                    id="51"
                    [checked]="is_checked == 'false' ? true : null"
                    (click)="disableItemPrice()"
                  />
                  <span
                    class="box"
                    [ngStyle]="{ 'background-color': '#46BF88' }"
                    style="margin-right: 0"
                    >{{ "CREATEJOB.charge_to_account" | translate }}</span
                  >
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- for job recurring -->
        <div class="c-info">
          <p>{{ "CREATEJOB.job_recurring" | translate }}</p>
          <div
            *ngIf="jobDetails?.recursive_job_type || recursive_id"
            class="d-flex gap-1 flex-wrap main-box"
          >
            <div class="radio-box custom-radio-width">
              <div *ngIf="jobDetails?.recursive_job_type?.recurisve_type == ''">
                <input id="j-1" type="radio" name="j-01" checked />
                <label for="j-1" class="c-j-label">{{
                  "CREATEJOB.one_time" | translate
                }}</label>
              </div>

              <div
                *ngIf="
                  jobDetails?.recursive_job_type?.recurisve_type == 'D' ||
                  recursiveJobType.recursive_type == 'D'
                "
              >
                <input id="j-2" type="radio" name="j-01" checked />
                <label for="j-2" class="c-j-label">{{
                  "CREATEJOB.daily" | translate
                }}</label>
              </div>

              <div
                *ngIf="
                  (jobDetails?.recursive_job_type?.recurisve_type == 'D' &&
                    jobDetails?.recursive_job_type?.daily_type == '1') ||
                  (recursiveJobType.recursive_type == 'D' &&
                    recursiveJobType.daily_type == '1')
                "
                class="custom-control custom-radio cust-yes-btn res-radio"
                style="padding-left: 0"
              >
                <p>{{ "CREATEJOB.everyday" | translate }}</p>
              </div>

              <div
                *ngIf="
                  (jobDetails?.recursive_job_type?.recurisve_type == 'D' &&
                    jobDetails?.recursive_job_type?.daily_type == '2') ||
                  (recursiveJobType.recursive_type == 'D' &&
                    recursiveJobType.daily_type == '2')
                "
                class="custom-control custom-radio cust-no-btn res-radio"
                style="padding-left: 0"
              >
                <p>{{ "CREATEJOB.mon_sat" | translate }}</p>
              </div>

              <div
                *ngIf="
                  (jobDetails?.recursive_job_type?.recurisve_type == 'D' &&
                    jobDetails?.recursive_job_type?.daily_type == '3') ||
                  (recursiveJobType.recursive_type == 'D' &&
                    recursiveJobType.daily_type == '3')
                "
                class="custom-control custom-radio cust-no-btn res-radio"
                style="padding-left: 0"
              >
                <p>{{ "CREATEJOB.mon_fri" | translate }}</p>
              </div>

              <div
                *ngIf="
                  jobDetails?.recursive_job_type?.recurisve_type == 'W' ||
                  recursiveJobType.recursive_type == 'W'
                "
              >
                <input id="j-3" type="radio" name="j-01" checked />
                <label for="j-3" class="c-j-label">{{
                  "CREATEJOB.weekely" | translate
                }}</label>

                <ng-container *ngIf="language == 'en'">
                  <ul class="w-day">
                    <li
                      *ngFor="
                        let day of jobDetails?.recursive_job_type
                          ?.selected_week_days ||
                          recursiveJobType.selected_week_days
                      "
                    >
                      {{ day?.item_text }}
                    </li>
                  </ul>
                </ng-container>
                <ng-container *ngIf="language == 'fr'">
                  <ul class="w-day">
                    <li
                      *ngFor="
                        let day of jobDetails?.recursive_job_type
                          ?.selected_week_days ||
                          recursiveJobType.selected_week_days
                      "
                    >
                      {{ day?.item_text_french }}
                    </li>
                  </ul>
                </ng-container>
              </div>

              <div
                *ngIf="
                  jobDetails?.recursive_job_type?.recurisve_type == 'M' ||
                  recursiveJobType.recursive_type == 'M'
                "
              >
                <input id="j-4" type="radio" name="j-01" checked />
                <label for="j-4" class="c-j-label">{{
                  "CREATEJOB.monthly" | translate
                }}</label>
              </div>

              <div
                *ngIf="
                  jobDetails?.recursive_job_type?.recurisve_type == 'BW' ||
                  recursiveJobType.recursive_type == 'BW'
                "
              >
                <input id="j-5" type="radio" name="j-01" checked />
                <label for="j-5" class="c-j-label">{{
                  "CREATEJOB.biweekly" | translate
                }}</label>
              </div>

              <div
                *ngIf="
                  jobDetails?.recursive_job_type?.recurisve_type == 'FW' ||
                  recursiveJobType.recursive_type == 'FW'
                "
              >
                <input id="j-6" type="radio" name="j-01" checked />
                <label for="j-6" class="c-j-label">{{
                  "CREATEJOB.every-4-week" | translate
                }}</label>
              </div>
            </div>
            <div *ngIf="!recursive_id" class="d-flex align-items-center">
              <div class="mr-2 font-weight-bold">
                <strong>{{ "CREATEJOB.job_date" | translate }}</strong>
              </div>
              <input
                class="form-control point date-picker dateVx"
                placeholder="{{ 'CREATEJOB.select_job_date' | translate }}"
                [(ngModel)]="jobDate"
                [ngModelOptions]="{ standalone: true }"
                ngbDatepicker
                #end="ngbDatepicker"
                #buttonEl
                (click)="end.toggle()"
                [readonly]="true"
                (ngModelChange)="onDateSelected($event)"
                firstDayOfWeek="7"
              />
            </div>
          </div>
          <!-- Select end date for recursive jobs -->
          <div *ngIf="recursive_id" class="px-0 col-12 d-flex">
            <div class="text-center">
              <span class="custom-checkbox item-price-charge checkbox-style-1">
                <input
                  type="checkbox"
                  (click)="onChangeIsContinuous()"
                  [checked]="isContinuousJob"
                />
                <span
                  class="box"
                  [ngStyle]="{ 'background-color': '#46BF88' }"
                  style="margin-right: 0"
                  >{{
                    !isContinuousJob
                      ? ("CREATEJOB.ending_date_on" | translate)
                      : ("CREATEJOB.ending_date_off" | translate)
                  }}</span
                >
              </span>
            </div>
            <div *ngIf="!isContinuousJob" class="d-flex align-items-center">
              <div class="col-6 font-weight-bold pr-1">
                {{ "CREATEJOB.recurring_end_date" | translate }}
              </div>

              <input
                class="form-control point date-picker"
                style="width: 180px"
                placeholder="{{ 'CREATEJOB.recurring_end_date' | translate }}"
                [(ngModel)]="recursiveEndDate"
                [ngModelOptions]="{ standalone: true }"
                ngbDatepicker
                #end="ngbDatepicker"
                #buttonEl
                (click)="end.toggle()"
                [readonly]="true"
                firstDayOfWeek="7"
              />
            </div>
          </div>
        </div>

        <div class="c-info mt-2">
          <p>{{ "CREATEJOB.timing" | translate }}</p>
          <div class="main-box d-flex gap-1">
            <div class="radio-box custom-radio-width">
              <input
                id="beforep"
                type="radio"
                name="timingSelectionPickup"
                [value]="1"
                [(ngModel)]="jobTimingObj.type"
                [ngModelOptions]="{ standalone: true }"
                (click)="
                  jobTimingObj.type = 1;
                  jobTimingObj.nameEn = 'Before';
                  jobTimingObj.nameFr = 'Avant'
                "
              />
              <label for="beforep" class="c-j-label">{{
                "CREATEJOB.before" | translate
              }}</label>

              <input
                id="afterp"
                type="radio"
                name="timingSelectionPickup"
                [value]="2"
                [(ngModel)]="jobTimingObj.type"
                [ngModelOptions]="{ standalone: true }"
                (click)="
                  jobTimingObj.type = 2;
                  jobTimingObj.nameEn = 'After';
                  jobTimingObj.nameFr = 'Après'
                "
              />
              <label for="afterp" class="c-j-label">{{
                "CREATEJOB.after" | translate
              }}</label>

              <input
                id="betweenp"
                type="radio"
                name="timingSelectionPickup"
                [value]="3"
                [(ngModel)]="jobTimingObj.type"
                [ngModelOptions]="{ standalone: true }"
                (click)="
                  jobTimingObj.type = 3;
                  jobTimingObj.nameEn = 'Between';
                  jobTimingObj.nameFr = 'Entre'
                "
              />
              <label for="betweenp" class="c-j-label">{{
                "CREATEJOB.between" | translate
              }}</label>
            </div>
            <div
              *ngIf="jobTimingObj.type"
              style="display: flex; align-items: center"
            >
              <div class="mr-2 font-weight-bold">
                {{ "CREATEJOB.time_set" | translate }}
              </div>
              <!-- todo -->
              <app-time-picker
                [time]="jobTimingObj.startTime"
                (onChange)="timerOnChange($event)"
              ></app-time-picker>

              <p *ngIf="jobTimingObj.type == 3" class="mx-3 font-weight-bold">
                -
              </p>
              <!-- todo -->
              <app-time-picker
                *ngIf="jobTimingObj.type == 3"
                [time]="jobTimingObj.endTime"
                (onChange)="timerOnChange2($event)"
              ></app-time-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <div class="col-xl-6 col-sm-12 float-left">
      <div class="padding-all">
        <h5 class="font-weight-bold mb-4">
          {{ "JOBDETAILS.job_details" | translate }}
        </h5>
        <div class="c-info">
          <p>{{ "CREATEJOB.job_tags" | translate }}</p>
          <div class="multiChoice my-3">
            <span *ngFor="let tag of job_tags" class="jobTag custom-checkbox">
              <input
                type="checkbox"
                [checked]="tag.running"
                [(ngModel)]="tag.running"
                [ngModelOptions]="{ standalone: true }"
                name="{{ tag.tag_type }}"
                id="{{ tag.tag_type }}"
                (change)="onChangeCategory($event, tag)"
                [disabled]="
                  disableTags || tag.tag_type == '16' || tag.tag_type == '17'
                "
              />

              <span
                *ngIf="language == 'en'"
                class="box"
                [ngStyle]="{ 'background-color': '#46BF88' }"
                >{{ tag.tag_for }}</span
              >
              <span
                *ngIf="language == 'fr'"
                class="box"
                [ngStyle]="{ 'background-color': tag.tag_code }"
                >{{ tag.tag_for_french }}</span
              >
            </span>
          </div>
        </div>

        <div
          *ngIf="customer_type == '4'"
          class="d-flex gap-1 package flex-wrap my-3"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold mr-2">
              {{ "CREATEJOB.packages" | translate }}
            </div>
            <input
              type="text"
              class="input-no form-control"
              [(ngModel)]="packageObj.package"
              [ngModelOptions]="{ standalone: true }"
              oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
              (ngModelChange)="packageChange($event)"
              maxlength="5"
            />
          </div>

          <div class="d-flex align-items-center">
            <div class="font-weight-bold mr-2">S</div>
            <input
              type="text"
              class="input-no form-control"
              [(ngModel)]="packageObj.small"
              [ngModelOptions]="{ standalone: true }"
              oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
              maxlength="5"
            />
          </div>

          <div class="d-flex align-items-center">
            <div class="font-weight-bold mr-2">L</div>
            <input
              type="text"
              class="input-no form-control"
              [(ngModel)]="packageObj.large"
              [ngModelOptions]="{ standalone: true }"
              oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
              maxlength="5"
            />
          </div>

          <div class="d-flex align-items-center">
            <div class="font-weight-bold mr-2">XL</div>
            <input
              type="text"
              class="input-no form-control"
              [(ngModel)]="packageObj.extraLarge"
              [ngModelOptions]="{ standalone: true }"
              oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1'); "
              maxlength="5"
            />
          </div>

          <div class="d-flex align-items-center">
            <div class="font-weight-bold mr-2">
              {{ "CREATEJOB.labels" | translate }}
            </div>
            <input
              disabled
              type="text"
              class="input-no form-control"
              [(ngModel)]="packageObj.label"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>

          <div class="d-flex align-items-center">
            <div class="font-weight-bold mr-2">
              {{ "CREATEJOB.weight" | translate }}
            </div>
            <input
              type="text"
              class="input-no form-control"
              [(ngModel)]="packageObj.weight"
              [ngModelOptions]="{ standalone: true }"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              maxlength="8"
            />
          </div>
        </div>

        <div class="c-info">
          <p>{{ "CREATEJOB.available_delivery_type" | translate }}</p>
          <div class="main-box d-type delivery-type-box">
            <div class="d-flex flex-wrap">
              <div class="pl-0 pr-1 col-lg-6 d-flex position-relative">
                <input
                  id="radio-6"
                  type="radio"
                  name="radio-02"
                  (click)="deliver_type('2')"
                  [value]="'2'"
                  [(ngModel)]="dog"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label for="radio-6" class="flex-grow-1 urgent-delivery">
                  <div class="media pr-2">
                    <img
                      src="/assets/img/new-ui/normal_delivery_inactive.svg"
                      alt=""
                      class="mr-3 truck-icon"
                      style="width: 40px"
                    />
                    <div class="media-body">
                      <div class="u-deliv">
                        <p class="sub-title sub-title-14">
                          {{ "CREATEJOB.normal_delivery" | translate }}
                        </p>
                        <p>{{ "CREATEJOB.normal_comment" | translate }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex u-deliv">
                    <p class="col-md-3">
                      <span class="font-weight-bold" style="font-size: 14px"
                        >${{ driver_charges_normal | number : "1.2-2" }}</span
                      ><span style="width: 55px">Price</span>
                    </p>

                    <p class="col-md-3">
                      <span class="font-weight-bold" style="font-size: 14px">
                        ${{
                          (driver_charges_normal * 5) / 100 | number : "1.2-2"
                        }}</span
                      ><span style="width: 55px">TPS</span>
                    </p>
                    <p class="col-md-3">
                      <span class="font-weight-bold" style="font-size: 14px">
                        ${{
                          (driver_charges_normal * 9.975) / 100
                            | number : "1.2-2"
                        }}</span
                      ><span style="width: 55px">{{
                        "CREATEJOB.tax_gst" | translate
                      }}</span>
                    </p>

                    <p class="col-md-3">
                      <span class="font-weight-bold" style="font-size: 14px">
                        ${{ normalCharges | number : "1.2-2" }}</span
                      ><span style="width: 55px">Total</span>
                    </p>
                  </div>
                </label>
                <div class="edit-button">
                  <i
                    class="edit-delivery1"
                    (click)="openModal2()"
                    style="font-size: 18px; cursor: pointer"
                    class="fa fa-pencil"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div class="pr-0 pl-1 col-lg-6 d-flex position-relative">
                <input
                  id="radio-5"
                  type="radio"
                  name="radio-02"
                  [value]="'1'"
                  [(ngModel)]="dog"
                  [ngModelOptions]="{ standalone: true }"
                  (click)="deliver_type('1')"
                />
                <label for="radio-5" class="flex-grow-1 urgent-delivery">
                  <div class="media pr-2">
                    <img
                      src="/assets/img/new-ui/urgent_delivery_inactive.svg"
                      class="mr-3 urgent-del truck-icon"
                      style="width: 50px"
                    />
                    <div class="media-body">
                      <div class="u-deliv">
                        <p class="sub-title sub-title-14">
                          {{ "CREATEJOB.urgent_delivery" | translate }}
                        </p>
                        <p class="">
                          {{ hourUrgent }}
                          {{ "CREATEJOB.urgent_comment" | translate }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex u-deliv">
                    <p class="col-md-3">
                      <span class="font-weight-bold" style="font-size: 14px"
                        >${{ driver_charges_urgent | number : "1.2-2" }}</span
                      ><span style="width: 55px">Price</span>
                    </p>

                    <p class="col-md-3">
                      <span class="font-weight-bold" style="font-size: 14px">
                        ${{
                          (driver_charges_urgent * 5) / 100 | number : "1.2-2"
                        }}</span
                      ><span style="width: 55px">TPS</span>
                    </p>
                    <p class="col-md-3">
                      <span class="font-weight-bold" style="font-size: 14px">
                        ${{
                          (driver_charges_urgent * 9.975) / 100
                            | number : "1.2-2"
                        }}</span
                      ><span style="width: 55px">{{
                        "CREATEJOB.tax_gst" | translate
                      }}</span>
                    </p>

                    <p class="col-md-3">
                      <span class="font-weight-bold" style="font-size: 14px">
                        ${{ urgentCharges | number : "1.2-2" }}</span
                      ><span style="width: 55px">Total</span>
                    </p>
                  </div>
                </label>
                <div class="edit-button">
                  <i
                    class="edit-delivery"
                    (click)="openModal1()"
                    style="font-size: 18px; cursor: pointer"
                    class="fa fa-pencil"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <ng-container *ngIf="pharmacyId === '60806cf89eea8c53ead502ac'">
                <div class="pl-0 pr-1 col-lg-6 d-flex position-relative">
                  <input
                    id="radio-7"
                    type="radio"
                    name="radio-02"
                    [value]="'3'"
                    [(ngModel)]="dog"
                    [ngModelOptions]="{ standalone: true }"
                    (click)="deliver_type('3')"
                  />
                  <label for="radio-7" class="flex-grow-1 urgent-delivery">
                    <div class="media pr-2">
                      <img
                        src="/assets/img/new-ui/normal_delivery_inactive.svg"
                        class="mr-3 urgent-del truck-icon"
                        style="width: 50px"
                      />
                      <div class="media-body">
                        <div class="u-deliv">
                          <p class="sub-title sub-title-14">
                            {{ "CREATEJOB.next_day_delivery" | translate }}
                          </p>
                          <div class="d-flex u-deliv">
                            <p class="col-md-3">
                              <span
                                class="font-weight-bold"
                                style="font-size: 14px"
                                >$0.00</span
                              ><span style="width: 55px">Price</span>
                            </p>

                            <p class="col-md-3">
                              <span
                                class="font-weight-bold"
                                style="font-size: 14px"
                              >
                                $0.00</span
                              ><span style="width: 55px">TPS</span>
                            </p>
                            <p class="col-md-3">
                              <span
                                class="font-weight-bold"
                                style="font-size: 14px"
                              >
                                $0.00</span
                              ><span style="width: 55px">{{
                                "CREATEJOB.tax_gst" | translate
                              }}</span>
                            </p>

                            <p class="col-md-3">
                              <span
                                class="font-weight-bold"
                                style="font-size: 14px"
                                >$0.00</span
                              ><span style="width: 55px">Total</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <p class="est-pric" style="color: #23282c">
          {{ "CREATEJOB.estimate_distance" | translate }}
          <span class="font-weight-bold"> {{ distance }} KM</span>
        </p>
        <div
          class="job-for-box"
          *ngIf="jobDetails?.isAccepted == false || recursive_id"
        >
          <div class="d-flex gap-1 align-items-center">
            <div class="font-weight-bold" style="font-size: 14px">
              {{ "CREATEJOB.job_for" | translate }}
            </div>
            <div class="radio-box custom-radio-width">
              <input
                id="radio-11"
                type="radio"
                name="radio-03"
                [value]="1"
                [(ngModel)]="fish"
                [ngModelOptions]="{ standalone: true }"
                (click)="selectJobFor('1')"
              />
              <label class="c-j-label" for="radio-11">{{
                "CREATEJOB.public" | translate
              }}</label>

              <input
                id="radio-12"
                type="radio"
                name="radio-03"
                [value]="2"
                [(ngModel)]="fish"
                [ngModelOptions]="{ standalone: true }"
                (click)="selectJobFor('2')"
              />
              <label class="c-j-label" for="radio-12">{{
                "CREATEJOB.private" | translate
              }}</label>

              <input
                *ngIf="connectedDispatches.length"
                id="radio-13"
                type="radio"
                name="radio-03"
                [value]="3"
                [(ngModel)]="fish"
                [ngModelOptions]="{ standalone: true }"
                (click)="selectJobFor('3')"
              />
              <label
                *ngIf="connectedDispatches.length"
                class="c-j-label"
                for="radio-13"
                >{{ "CREATEJOB.dispatch_company" | translate }}</label
              >
            </div>
          </div>
        </div>
        <div
          *ngIf="
            (jobDetails?.isAccepted == false || recursive_id) &&
            jobFor == '2' &&
            !isDispatchSelected
          "
        >
          <div class="row mt-3 align-items-center">
            <div class="col-md-3">
              <p class="font-weight-bold">
                {{ "CREATEJOB.driver_for_this_job" | translate }}
              </p>
            </div>
            <div class="col-md-9">
              <div class="form-group">
                <div class="my-custom-dropdown">
                  <ng-multiselect-dropdown
                    placeholder="{{
                      'CREATEJOB.driver_for_this_job' | translate
                    }}"
                    [settings]="dropdownSettings1"
                    [data]="drivers"
                    [(ngModel)]="username"
                    [ngModelOptions]="{ standalone: true }"
                    (onSelect)="onItemSelect1($event)"
                    (onDeSelect)="onDeSelect1($event)"
                    (onSelectAll)="onSelectAll1($event)"
                    (onDeSelectAll)="onDeSelectAll1($event)"
                    style="width: 90%; background-color: white"
                  >
                  </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- DISPATCH SELECTION START -->
        <div
          *ngIf="
            ((jobDetails?.isAccepted == false && jobFor == '2') ||
              (jobFor == '3' && recursive_id)) &&
            isDispatchSelected &&
            connectedDispatches.length
          "
        >
          <div class="row mt-3 align-items-center">
            <div class="col-md-2">
              <p class="font-weight-bold">
                {{ "CREATEJOB.select_dispatch" | translate }}
              </p>
            </div>
            <div class="col-md-10">
              <div class="form-group">
                <div class="my-custom-dropdown">
                  <ng-multiselect-dropdown
                    placeholder="{{ 'CREATEJOB.select_dispatch' | translate }}"
                    [settings]="dropdownSettingsDispatch"
                    [data]="connectedDispatches"
                    [(ngModel)]="selectedDispatchForJob"
                    [ngModelOptions]="{ standalone: true }"
                    (onSelect)="onItemSelectDispatch($event)"
                    (onDeSelect)="onDeSelectDispatch($event)"
                    (onSelectAll)="onSelectAllDispatch($event)"
                    (onDeSelectAll)="onDeSelectAllDispatch($event)"
                    style="width: 90%; background-color: white"
                  >
                  </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- DISPATCH SELECTION END -->

        <!-- RECURSIVE EDIT  -->

        <div
          class="row r-pdng mt-3"
          *ngIf="jobDetails?.isRecursiveJob == true && !recursive_id"
        >
          <div class="custom-control custom-checkbox mb-3">
            <input
              type="checkbox"
              [value]="true"
              [(ngModel)]="edit_all_recursive_jobs"
              [ngModelOptions]="{ standalone: true }"
              class="custom-control-input"
              id="edit_recursive"
              name="example1"
            />
            <label class="custom-control-label" for="edit_recursive"></label>
          </div>
          <label class="mr-2" style="font-weight: 600"
            >{{ "CREATEJOB.modify_future_jobs" | translate }}
          </label>
        </div>
        <!-- RECURSIVE EDIT -->

        <div class="customfileupload filecstm-btn mb-3">
          <div class="d-flex align-items-center gap-1">
            <div class="fileUpload">
              <p>{{ "CREATEJOB.attach_doc" | translate }}</p>
              <input
                type="file"
                class="uploadlogo"
                accept="application/pdf,image/png, image/jpeg,image/jpg,.doc,.docx "
                name="myfile"
                (change)="onFileSelect($event)"
              />
            </div>
            <div *ngIf="attachedDocument.docName" class="upload-label">
              {{ attachedDocument.docName }}
              <span class="ml-1 point" (click)="cancelDocument()">
                <img src="assets/img/new-ui/close_attach_document.svg" />
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex text-center">
          <span
            class="reverse-checkbox-color custom-checkbox item-price-charge checkbox-style-1"
          >
            <input
              type="checkbox"
              (click)="onChangeIsRoundTrip()"
              [checked]="isRoundTrip"
            />
            <span
              class="box"
              [ngStyle]="{ 'background-color': '#2a2f3b' }"
              style="margin-right: 0"
              >{{
                isRoundTrip
                  ? ("CREATEJOB.round_trip" | translate)
                  : ("CREATEJOB.one_way" | translate)
              }}</span
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</form>

<div
  bsModal
  #successModal1="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-success" role="document">
    <div class="modal-content">
      <div class="modal-header bg-success">
        <h4 class="modal-title">
          {{ "CREATEJOB.edit_urgent_job" | translate }}
        </h4>
        <button
          type="button"
          class="close"
          (click)="successModal1.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form action="/action_page.php">
          <div class="form-group">
            <label for="pwd"
              ><strong>{{ "CREATEJOB.enter_amount" | translate }}:</strong>
            </label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="urgent_charge"
              [ngModelOptions]="{ standalone: true }"
              placeholder="{{ 'CREATEJOB.enter_amount' | translate }}"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              maxlength="6"
            />
          </div>
        </form>
        <p class="text-danger">
          * {{ "CREATEJOB.changing_price_comment" | translate }}
        </p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="successModal1.hide()"
        >
          {{ "CREATEJOB.cancel" | translate }}
        </button>
        <button type="button" class="btn btn-success" (click)="save1()">
          {{ "CREATEJOB.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<div
  bsModal
  #successModal2="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-success" role="document">
    <div class="modal-content">
      <div class="modal-header bg-success">
        <h4 class="modal-title">
          {{ "CREATEJOB.edit_normal_job" | translate }}
        </h4>
        <button
          type="button"
          class="close"
          (click)="successModal2.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form action="/action_page.php">
          <div class="form-group">
            <label for="pwd"
              ><strong>{{ "CREATEJOB.enter_amount" | translate }}:</strong>
            </label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="normal_charge"
              [ngModelOptions]="{ standalone: true }"
              placeholder="{{ 'CREATEJOB.enter_amount' | translate }}"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              maxlength="6"
            />
          </div>
        </form>
        <p class="text-danger">
          * {{ "CREATEJOB.changing_price_comment" | translate }}
        </p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="successModal2.hide()"
        >
          {{ "CREATEJOB.cancel" | translate }}
        </button>
        <button type="button" class="btn btn-success" (click)="save2()">
          {{ "CREATEJOB.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #locationHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3 justify-content-between">
    <h5 class="modal-title">{{ "LOCATION.select_location" | translate }}</h5>
    <ng-container
      *ngTemplateOutlet="locationAgmComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #locationBodyTemplate>
  <div class="mx-3">
    <app-location-agm
      [item]="locationData"
      (getLocationData)="handleAddressChange($event, false, modal)"
      [activeAlias]="customer_id != '' ? true : false"
      [defaultOtherAddress]="true"
    ></app-location-agm>
  </div>
</ng-template>
