import { Component, OnInit } from '@angular/core';
import { ChartOptions } from 'chart.js';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../../../services.service';

@Component({
  selector: 'app-job-statistics',
  templateUrl: './job-statistics.component.html',
  styleUrls: ['./job-statistics.component.scss']
})
export class JobStatisticsComponent implements OnInit {
  typeQuantityIncome = 'income';
  typeFrecuency = 'month';
  dispatchUSer: any = {}
  dataTotal = []
  newPipe$ = this.service.filterPharmacyfil();
  value = moment().format(`YYYY`);
  anioActually = `${this.value}/01/01`;
  end = moment().format("yyyy/MM/DD");
  startDate: any = '';
  endDate: any = '';




  chartData = [
    {
      data: [],
      label: 'Jobs Complete',
      backgroundColor: "rgba(70, 191, 136, 0.5)",
      borderColor: "rgba(70, 191, 136, 0.5)",
      hoverBackgroundColor: "rgba(70, 191, 136, 1)",
      hoverBorderColor: "rgba(70, 191, 136, 1)",
    },
    {
      data: [],
      label: 'Jobs Pending',
      backgroundColor: "rgba(75, 85, 109, 0.5)",
      borderColor: "rgba(75, 85, 109, 0.5)",
      hoverBackgroundColor: "rgba(75, 85, 109, 1)",
      hoverBorderColor: "rgba(75, 85, 109, 1)",
    },
    {
      data: [],
      label: 'Jobs Cancel',
      backgroundColor: "rgba(90, 139, 219, 0.5)",
      borderColor: "rgba(90, 139, 219, 0.5)",
      hoverBackgroundColor: "rgba(90, 139, 219, 1)",
      hoverBorderColor: "rgba(90, 139, 219, 1)",
    },
    {
      data: [],
      label: '',
      backgroundColor: "rgba(171, 127, 236, 0.1)",
      borderColor: "rgba(171, 127, 236, 0.1)",
      hoverBackgroundColor: "rgba(171, 127, 236, 1)",
      hoverBorderColor: "rgba(171, 127, 236, 1)",
    },

  ]
  chartLabels = []
  chartOptions: ChartOptions = {
    responsive: true,
  }
  dateRangeOption: any = {
    startDate: this.anioActually,
    // startDate: getAnio(),
    endDate: moment().format("yyyy/MM/DD"),
    format: "YYYY/MM/DD",
  }
  _id = '';
  user_id: any;

  anioList: number[] = [];
  startYear: number = 2020;
  endYear: number = new Date().getFullYear();
  startMonth: string = this.endYear + '-01'
  endMonth: string = this.endYear + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0');
  isActiveCustomFilter: boolean = false;
  localData: any;

  constructor(private service: ServicesService,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService,) { }

  ngOnInit(): void {
    this.startDate = this.dateRangeOption.startDate;
    this.endDate = this.dateRangeOption.endDate;
    this.fillListOfYear();
    this.localData = JSON.parse(localStorage.getItem("currentUser"));
    this.user_id = this.localData._id;
    this.service.getJobCompleteStatics({
      "filterType": this.typeFrecuency,
      "_id": this.user_id
    }).pipe().subscribe(data => {
      if (data.code == 200) {
        data.data.chartData.forEach((current, i) => {
          this.chartData[i].label = data.data.chartData[i].label;

          this.chartData[i].data = data.data.chartData[i].data;
        })

        this.chartLabels = data.data.chartLabels;
      } else {
        this.toastr.error('error', data.message);
      }
      this.spinner.hide();

    })
  }


  onChangeFrecuency(type: string) {

    this.typeFrecuency = type;
    this.getStatics()


  }
  fillListOfYear() {
    let year = new Date().getFullYear();
    for (let i = this.startYear; i < year + 1; i) {
      this.anioList.push(i);
      i++;
    }
  }
  selectedDate(dateOutput: any) {
    this.startDate = dateOutput.startDate;
    this.endDate = dateOutput.endDate;
    // this.getActiveJobs();

    // this.getAllGlobalSummary();
    this.getStatics();
  }
  getStatics() {
    let filter: any = {
      "filterType": this.typeFrecuency,
      "_id": this.user_id
    }
    // const listStart =this.startDate.split("/");
    // const listEnd =this.endDate.split("/");
    if (filter.filterType === "year" && this.isActiveCustomFilter) {
      if (this.startYear > this.endYear) {
        this.toastr.error('error', 'Start year must be less than end year');
        this.startYear = this.endYear;
      }
      filter.startDate = this.startYear;
      filter.endDate = this.endYear;
    }
    if (filter.filterType === "month" && this.isActiveCustomFilter) {
      const listStart = this.startMonth.split("-");
      const listEnd = this.endMonth.split("-");
      if (+listStart[0] + +listStart[1] > +listEnd[0] + +listEnd[1]) {
        this.toastr.error('error', 'Start month must be less than end month');
        this.startMonth = this.endMonth;
      }
      filter.startDate = this.startMonth;
      filter.endDate = this.endMonth;
    }
    if (filter.filterType === "day" && this.isActiveCustomFilter) {
      if (moment(this.startDate).isAfter(this.endDate)) {
        this.toastr.error('error', 'Start date must be less than end date');
        this.startDate = this.endDate;
      }
      filter.startDate = this.startDate;
      filter.endDate = this.endDate;
    }

    this.spinner.show();

    this.service.getJobCompleteStatics(filter).pipe().subscribe(data => {
      if (data.code == 200) {
        data.data.chartData.forEach((current, i) => {
          this.chartData[i].label = data.data.chartData[i].label;

          this.chartData[i].data = data.data.chartData[i].data;
        })

        this.chartLabels = data.data.chartLabels;
      } else {
        this.toastr.error('error', data.message);
      }
      this.spinner.hide();

    })
    this.chartLabels = [];
    this.dataTotal = [];
  }

}
