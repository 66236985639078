<p class="create-date" *ngIf="!comments.length">N/A</p>
<div class="list" *ngIf="comments.length">
  <div class="card-list" *ngFor="let comment of comments">
    <div class="comment-card">
      <a class="card-title">{{ comment.message }}</a>
      <div class="fechayhora">
        <span>{{ comment.createAt.split('T')[0] }}</span> /
        <span>{{ comment.createAt.split('T')[1].split('.')[0] }}</span>
      </div>
    </div>
  </div>
</div>
