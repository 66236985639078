<link
    href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
    rel="stylesheet">
<div class="card mt-3">
    <div class="card-body">
        <div class="row">
            <div class="col-lg-4">
                <div class="scp-breadcrumb">
                    <ul class="breadcrumb">
                        <li class="b-jobs"><a>{{ 'BRANDS.title' | translate }}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-4">
                <form class="search-box-custom">
                    <input type="text" name="search" placeholder="{{ 'SEARCH' | translate }}" class="animated-search"
                        (ngModelChange)="searchBrand($event)" [(ngModel)]="filter"
                        [ngModelOptions]="{standalone: true}" />
                </form>
            </div>

            <div class="col-md-4">
                <ul class="invoice-list">
                    <li>
                        <a routerLink="/brands-create">
                            <button class="add-invoice-btn" id="openRighSideBar">
                                {{ "BRANDS.add_new" | translate }}
                            </button>

                        </a>

                    </li>
                </ul>
            </div>

        </div>

        <div class="row">
            <div class="table-responsive">
                <table class="table table-borderless jobs-table">
                    <thead>
                        <tr style="align-items: center;">
                            <th>{{'BRANDS.name_table' | translate }}</th>
                            <th>{{'BRANDS.name_tableFr' | translate }}</th>
                            <th>{{'BRANDS.image_table' | translate }}</th>
                            <th style="width: 4%; text-align: center;">{{'BRANDS.action_table' | translate }}</th>

                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            *ngFor="let brands of allBrand | paginate: { itemsPerPage: 10,currentPage: page, totalItems:allBrandsCount}">

                            <td>
                                {{brands.name}}
                            </td>

                            <td>
                                {{brands.nameFr}}
                            </td>

                            <td>

                            </td>
                            <td>
                                <ul class="brands-actions-btn">
                                    <li>
                                        <a routerLink="/brands-update/{{brands._id}}"><i class="fa fa-edit text-danger"
                                                ngbTooltip="Edit Brand" id="openRightSideBarToEdit"
                                                aria-hidden="true"></i></a>
                                    </li>

                                    <li>
                                        <a>
                                            <i class="fa fa-trash text-danger" ngbTooltip="Delete"
                                                (click)="openDeleteModel(brands._id)" aria-hidden="true">
                                            </i>
                                        </a>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>

                </table>
                <div class="no-jobs-found">
                    <p class="no-item text-center text-danger mb-0" *ngIf="!allBrand.length">
                        {{'CLIENTS.no_client_found' | translate}}</p>
                </div>
                <div class="row f-right" style="margin-right: 40px;">
                    <pagination-controls *ngIf="allBrand.length" (pageChange)="onPageChange($event)">
                    </pagination-controls>
                </div>
            </div>
        </div>

        <!-------delete modal---->
        <div bsModal #deleteBrand="bs-modal" class="modal fade" [config]="{ backdrop: 'static' ,keyboard : false}"
            tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md add-client-modal" role="document">
                <div class="modal-content" style="width:90%">
                    <div class="modal-header">
                        <div class="modal-title-absolute">
                            <button class="btn btn-default">CONFIRMATION</button>
                        </div>
                        <button type="button" class="close" (click)="deleteBrand.hide()" aria-label="Close"> <span
                                aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">

                        <div class="c-info col-md-12">
                            <p style="font-size: 12px;">{{'BRANDS.delete_Comment' | translate}}</p>
                        </div>

                    </div>
                    <div class="modal-footer" style="margin: auto; margin-right: 0;">
                        <button type="button" class="btn btn-secondary client-cancel-btn"
                            (click)="deleteBrand.hide()">{{'CREATEJOB.no' | translate}}</button>
                        <button type="button" class="btn btn-secondary add-client-btn" style="width: 80px;"
                            (click)="deleteExistBrand()">{{'CREATEJOB.yes' | translate}}</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>

    </div>
</div>