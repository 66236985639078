<div class="modal-content">
  <div class="row justify-content-center">
    <div class="col-10 mt-3">
      <!-- si continuas se cerrara session en los demas dispositivos -->
      <h4 class="text-center">Are you sure?</h4>
      <p class="text-center">
        If you continue, you’ll be logged out an all other devices will be disconnected
      </p>
    </div>
  </div>
  <div class="row justify-content-center mb-3">
    <button
      type="button"
      class="btn btn-secondary mx-2"
      (click)="modal.dismiss('Cross click')"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-success mx-2"
      (click)="modal.close('Continuo')"
    >
      Continue
    </button>
  </div>
</div>
