<div class="modal-header">
  <h4 class="modal-title">{{ "JOBS.convert_confirmation" | translate }}</h4>
</div>
<div class="row r-pdng">
  <div class="col-12">
    <label class="mr-2" style="font-weight: 600"
      >{{ "JOBS.convert_recursive_v2" | translate }}
    </label>
  </div>
  <div class="col-12">
    <!-- radio button -->
    <div class="form-check form-check-inline">
      <input
        class="px-3"
        type="radio"
        name="inlineRadioOptions"
        id="inlineRadio1"
        [value]="false"
        [(ngModel)]="selectedOption"
      />
      <label
        class="form-check-label px-4"
        style="width: auto"
        for="inlineRadio1"
        >No
      </label>
      <input
        class="px-3"
        type="radio"
        name="inlineRadioOptions"
        id="inlineRadio2"
        [value]="true"
        [(ngModel)]="selectedOption"
      />
      <label
        class="form-check-label px-4"
        style="width: auto"
        for="inlineRadio2"
      >
        Yes
      </label>
    </div>
  </div>
</div>
<ng-template #dynamicContentTemplate>
  <div class="d-flex gap-1 flex-grow-1">
    <app-button
      [imageSrc]="'./assets/img/new-ui/dowload_as_pdf.svg'"
      [buttonText]="'Convert to v2' | translate"
      (buttonClick)="convert()"
    ></app-button>
  </div>
</ng-template>
