<ng-template #dynamicContent>
  <app-button
    [imageSrc]="'./assets/img/new-ui/plus.svg'"
    [buttonText]="'TAG.add_new_tag' | translate"
    (buttonClick)="openAddNewJobTagModal()"
  ></app-button>
</ng-template>
<div class="mt-3">
  <app-table
    [theadTemplate]="theadTemplate"
    [tbodyTemplate]="tbodyTemplate"
    [noFoundTemplate]="noFoundTemplate"
    [totalItems]="userJobTagsCount"
    (onPageChange)="onPageChange($event)"
    [currentPage]="pageNumber"
  ></app-table>
  <ng-template #theadTemplate>
    <tr>
      <th>{{ "TAG.tag_name_english" | translate }}</th>
      <th>{{ "TAG.tag_name_french" | translate }}</th>
      <th>{{ "TAG.tag_price" | translate }}</th>
      <th>{{ "TAG.tag_code" | translate }}</th>
      <th>{{ "TAG.tag_status" | translate }}</th>
      <th>ACTION</th>
    </tr>
  </ng-template>
  <ng-template #tbodyTemplate>
    <tbody>
      <tr
        *ngFor="
          let userJobTag of userJobTags
            | paginate
              : {
                  itemsPerPage: 10,
                  currentPage: pageNumber,
                  totalItems: userJobTagsCount
                }
        "
      >
        <td>
          {{ userJobTag?.tag_for }}
        </td>

        <td>
          {{ userJobTag?.tag_for_french }}
        </td>

        <td>
          {{ userJobTag?.tag_price }}
        </td>

        <td>
          <input
            type="color"
            style="padding: 1px"
            [value]="userJobTag?.tag_code"
            disabled
          />
        </td>

        <td>
          <div class="custom-control custom-checkbox mb-3">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customCheck"
              name="example1"
              [checked]="!userJobTag?.isDisabled"
              disabled
            />
            <label class="custom-control-label" for="customCheck"></label>
          </div>
        </td>
        <td>
          <ul class="action-icons">
            <li *ngIf="userJobTag?.user_id == user_id">
              <img
                src="assets/img/new-ui/edit.svg"
                (click)="openEditJobTagModal(userJobTag._id)"
                ngbTooltip="Edit"
              />
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </ng-template>
  <ng-template #noFoundTemplate>
    <div class="no-jobs-found">
      <p
        class="no-item text-center text-danger mb-0"
        *ngIf="!userJobTags.length"
      >
        {{ "TAG.no_tag_found" | translate }}
      </p>
    </div>
  </ng-template>
</div>

<!-------modal---->
<ng-template #addNewJobTagTemplate>
  <div class="modal-body addNewJobTagModal">
    <form [formGroup]="addNewJobTagForm">
      <table class="table table-sm">
        <tbody>
          <tr>
            <td class="font-weight-bold">
              {{ "TAG.tag_name_english" | translate }}
            </td>
            <td>
              <input
                type="text"
                class="form-control"
                name="tag_for"
                formControlName="tag_for"
                placeholder="Tag Name"
                [(ngModel)]="tag.tag_for"
                [ngClass]="{
                  'is-invalid':
                    submittedAddNewJobTagForm &&
                    addNewJobTagForm.controls.tag_for.errors
                }"
              />
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold">
              {{ "TAG.tag_name_french" | translate }}
            </td>
            <td>
              <input
                type="text"
                class="form-control"
                name="tag_for_french"
                formControlName="tag_for_french"
                placeholder="Tag Name French"
                [(ngModel)]="tag.tag_for_french"
                [ngClass]="{
                  'is-invalid':
                    submittedAddNewJobTagForm &&
                    addNewJobTagForm.controls.tag_for_french.errors
                }"
              />
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold">{{ "TAG.tag_code" | translate }}</td>
            <td>
              <input
                type="color"
                class="form-control"
                name="tag_code"
                style="max-width: 6em; padding: 1px"
                formControlName="tag_code"
                [(ngModel)]="tag.tag_code"
                [ngClass]="{
                  'is-invalid':
                    submittedAddNewJobTagForm &&
                    addNewJobTagForm.controls.tag_code.errors
                }"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</ng-template>
<!-- /.modal -->

<!-------modal---->
<ng-template #editJobTagTemplate>
  <div class="modal-body editJobTagModal">
    <form [formGroup]="editJobTagForm">
      <table class="table table-sm">
        <tbody>
          <tr>
            <td class="font-weight-bold">
              {{ "TAG.tag_name_english" | translate }}
            </td>
            <td>
              <input
                type="text"
                class="form-control"
                name="tag_for"
                formControlName="tag_for"
                placeholder="Tag Name"
                [(ngModel)]="tag.tag_for"
                [ngClass]="{
                  'is-invalid':
                    submittedEditJobTagForm &&
                    editJobTagForm.controls.tag_for.errors
                }"
              />
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold">
              {{ "TAG.tag_name_french" | translate }}
            </td>
            <td>
              <input
                type="text"
                class="form-control"
                name="tag_for_french"
                formControlName="tag_for_french"
                placeholder="Tag Name French"
                [(ngModel)]="tag.tag_for_french"
                [ngClass]="{
                  'is-invalid':
                    submittedEditJobTagForm &&
                    editJobTagForm.controls.tag_for_french.errors
                }"
              />
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold">{{ "TAG.tag_code" | translate }}</td>
            <td>
              <input
                type="color"
                class="form-control"
                name="tag_code"
                style="max-width: 6em; padding: 1px"
                formControlName="tag_code"
                [(ngModel)]="tag.tag_code"
                [ngClass]="{
                  'is-invalid':
                    submittedEditJobTagForm &&
                    editJobTagForm.controls.tag_code.errors
                }"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</ng-template>
<!-- /.modal -->
