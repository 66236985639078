import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ComentType, ServicesService } from '../../../services.service';

@Component({
  selector: 'app-commet',
  templateUrl: './commet.component.html',
  styleUrls: ['./commet.component.scss']
})
export class CommetComponent implements OnInit {
  public newComment = '';
  arrayFrutas = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];
  commets: any[];
  @Input() _id: any;
  @Input() modal: NgbModalRef;

  constructor(
    private services: ServicesService,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService,
  ) {
    // this.getAllComments()
  }

  ngOnInit(): void {
    this.getAllComments()
    console.log(`---${this._id}`);
  }


  addComment(message) {
    if (!message) return;
    const data: ComentType = {
      "message": message,
      "file_upload": ""
    }
    this.services.addJobComents(this._id, data).subscribe(dataComent => {
      this.getAllComments()
    }, error => {
      console.log('error update :', error);
    });


  }
  getAllComments() {
    this.getPrescriptionComments(this._id);
  }
  getPrescriptionComments(id) {
    this.spinner.show();
    this.services.getCommentsByIdJobs(id).pipe().subscribe(data => {
      this.spinner.hide();
      this.commets = [...data.comments];
      this.services.setMensajeCambio(data);
    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });
  }
  confirmCommentModal() {
    if (this.newComment != '') {
      this.addComment(this.newComment);
      this.newComment = '';
    }
  }
}
