import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  ServicesService
} from '../../services.service';
// import {
//   Router
// } from '@angular/router';
import { Router } from '@angular/router';
import moment from 'moment';
import {
  BsModalRef,
  BsModalService,
  ModalDirective
} from 'ngx-bootstrap/modal';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import { ModalComponent } from "../../shared/component/modal/modal.component";
import { RecursiveLabelType } from '../../utils/Enums';
import { EditJobComponent } from '../edit-job/edit-job.component';
import { JobDetailsComponent } from '../job-details/job-details.component';
import { ConvertRecursiveJobToV2ModalComponent } from './convert-recursive-job-to-v2-modal/convert-recursive-job-to-v2-modal.component';
const FileSaver = require('file-saver');

@Component({
  selector: 'app-extend-recursive-jobs',
  templateUrl: './extend-recursive-jobs.component.html',
  styleUrls: ['./extend-recursive-jobs.component.scss']
})
export class ExtendRecursiveJobsComponent implements OnInit {
  @Input() endpoint: string;
  @ViewChild('successModal1') successModal1: ModalDirective;
  @ViewChild('sendToDriverNotificationModal') public sendToDriverNotificationModal: ModalDirective;
  @ViewChild('dynamicContentTemplate', { static: false }) dynamicContentTemplate: TemplateRef<any>;
  @ViewChild('requestTemplate') requestTemplate: TemplateRef<any>;
  @ViewChild('requestHeaderTemplate') requestHeaderTemplate: TemplateRef<any>;
  @ViewChild(JobDetailsComponent) jobDetailsComponent: JobDetailsComponent;
  @ViewChild(EditJobComponent) editJobComponent: EditJobComponent;
  @ViewChild(ConvertRecursiveJobToV2ModalComponent) convertJobToV2ModalComponent: ConvertRecursiveJobToV2ModalComponent
  user_id = "";
  jobs = [];
  jobs_count = 0;
  pageNumber = 1;
  searchEvent = "";
  searchDate = '';
  Date: any;
  timeCountForSearch = null;
  pageNumberForIndividualJobs = 1;
  individualJobs = [];
  individualJobsCount = 0;
  searchEventIndividualJobs = "";
  timerCountForIndividualSearch = null;
  language = 'en';
  filterBy = "0";
  bsModalRef: BsModalRef;
  notificationTitle = '';
  notificationText = '';
  driver_id_notification = '';
  job_id_notification = '';
  selectedJob = null
  job_id = '';
  recursiveType = RecursiveLabelType;
  recursiveManagementId = ""

  constructor(
    private logoutFunction: DefaultLayoutComponent,
    private toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    let lang = JSON.parse(localStorage.getItem("language"));
    if (lang && (lang == 'en' || lang == 'fr')) {
      this.language = lang;
    } else {
      this.language = 'en';
    }
    setTimeout(() => {
      var selectedDate = JSON.parse(localStorage.getItem("selected_date"));
      if (selectedDate) {
        var current = new Date(selectedDate);
        this.Date = {
          year: current.getFullYear(),
          month: current.getMonth() + 1,
          day: current.getDate()
        };
      } else {
        var current = new Date();
        this.Date = {
          year: current.getFullYear(),
          month: current.getMonth() + 1,
          day: current.getDate()
        }
      }
    }, 50);

    this.filterBy = '0';
    this.searchEvent = "";
    this.timeCountForSearch = null;
    this.pageNumber = 1;
    this.searchEventIndividualJobs = "";
    this.timerCountForIndividualSearch = null;
    this.pageNumberForIndividualJobs = 1;
    let localData = JSON.parse(localStorage.getItem("currentUser"));
    this.user_id = localData._id;
    this.getJobs();
  }

  getJobs() {
    this.spinner.show();
    var localData = JSON.parse(localStorage.getItem("currentUser"));
    this.services.fetchJobsRecursiveV2(localData._id, this.pageNumber, this.searchEvent).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      console.log(data, 'recursive data')
      if (data.code == 200) {
        this.jobs = data.result;
        this.jobs_count = data.count;
        this.spinner.hide();
        return
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });
  }

  onPageChange(pageNumber) {
    this.pageNumber = pageNumber;
    this.getJobs();
  }

  onSearch(event) {
    this.filterBy = '0';
    this.pageNumber = 1;
    if (this.timeCountForSearch != null) {
      clearTimeout(this.timeCountForSearch)
    }
    this.timeCountForSearch = setTimeout(() => {
      this.searchEvent = event;
      this.getJobs();
    }, 1200);

  }

  openRequestModal() {
    const initialState = {
      content: this.requestTemplate,
      header: this.requestHeaderTemplate,
    };
    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-xl modal-dialog-centered",
    });


  }

  sendRequestToJob(job) {
    if (!job.secretCode) {
      this.toastr.error('', 'Enter secret code.');
      return;
    }
    if (job.secretCode.length != 6) {
      this.toastr.error('', 'Secret code must be 6 characters.');
      return;
    }

    this.spinner.show();
    this.services.sendLinkRequest(this.user_id, job._id, job.secretCode).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.ngOnInit();
        this.bsModalRef.hide();
        return;
        // this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.error('', data.message);
        return;

      }
    })

  }

  showNextJobDate(date) {

    return moment().isAfter(date) || date == null ? "N/A" : this.modifyDate(date)
  }

  searchWithDate(data) {
    this.searchEvent = '';
    this.pageNumber = 1;
    var updatedPage = JSON.parse(localStorage.getItem("pageNumber"));
    var userSearch = JSON.parse(localStorage.getItem("userSearch"));
    this.searchDate = this.Date.month + '/' + this.Date.day + '/' + this.Date.year;

    if (updatedPage) {
      this.pageNumber = updatedPage;
    }

    if (userSearch) {
      this.searchEvent = userSearch;
    }

    if (data == 1) {
      this.pageNumber = 1;
      this.searchEvent = '';
    }

    this.getJobs();
  }

  //SORT ADDRESS FUNCTION START HERE 
  sortAddress(address) {
    const address1 = address.split(',');
    if (address1.length > 4) {
      return address1[0] + ',' + address1[1] + ',' + address1[2] + ',' + address1[3];
    } else {
      var str = '';
      for (const data of address1) {
        str += data + ',';
      }
      return str.slice(0, -1)
    }
  }

  //MODIFY DATE FUNCTION
  modifyDate(str) {
    try {
      const mydate = str.split('T')[0]
      const convert = mydate.split('-')
      return convert[0] + '/' + convert[1] + '/' + convert[2]
    } catch (error) {
      return ''
    }

  }

  //CANCEL JOB SECTION START HERE
  cancelJobModal(job_id) {
    this.successModal1.show();
    this.recursiveManagementId = job_id


  }

  cancelJob() {

    this.spinner.show();
    this.services.cancel_recursive_management(this.recursiveManagementId).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.successModal1.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.successModal1.hide();
        // this.ngOnInit();
        this.getJobs();
        this.toastr.success('Job Cancelled Successfully');
        this.spinner.hide();
        return;
      } else {
        this.successModal1.hide();
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }

    });
  }


  //JOB DETAILS FUNCTION STAR HERE
  jobDetails(job_id) {
    localStorage.setItem('job_id', JSON.stringify(job_id));
    localStorage.setItem('back_type', JSON.stringify("2"));
    localStorage.setItem('selected_date', JSON.stringify(this.searchDate));
    localStorage.setItem('pageNumber', JSON.stringify(this.pageNumber));
    localStorage.setItem('userSearch', JSON.stringify(this.searchEvent));
    this.router.navigate(['/job-details']);
  }
  //EDIT JOB FUNCTION START HERE
  navigate_editjob(id) {
    localStorage.setItem('edit_job_id', JSON.stringify(id));
    localStorage.setItem('selected_date', JSON.stringify(this.searchDate));
    localStorage.setItem('pageNumber', JSON.stringify(this.pageNumber));
    localStorage.setItem('userSearch', JSON.stringify(this.searchEvent));
    this.router.navigate(['/edit-job']);
  }

  openNotificationModal(driver_id, job_id) {
    this.notificationTitle = "";
    this.notificationText = "";
    this.driver_id_notification = driver_id;
    this.job_id_notification = job_id;
    this.sendToDriverNotificationModal.show();
  }

  sendNotificationToDriver() {
    if (!this.notificationTitle || !this.notificationText) {
      this.toastr.error('', 'Enter notification fields.');
      return;
    }
    this.spinner.show();
    this.services.sendNotificationToDriver(this.driver_id_notification, this.user_id, this.notificationText, this.notificationTitle, this.job_id_notification).pipe().subscribe(data => {
      if (data.code == 200) {
        this.sendToDriverNotificationModal.hide();
        this.toastr.success("sucess", "");
        this.spinner.hide();
        return;

      } else {
        this.sendToDriverNotificationModal.hide();
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    })

  }

  getPdf(job_id) {

    if (this.user_id && job_id) {
      this.spinner.show();
      this.services.getJobDetailsPdfNew(this.user_id, job_id).pipe().subscribe(data => {
        if (data.code == 400) {
          this.spinner.hide();
          this.toastr.error('', data.message);
          this.logoutFunction.logout();
          return;
        }
        if (data.code == 200) {

          const bufferDataArray = new Uint8Array(data.pdf.data).buffer;
          let pdfBlob = new Blob([bufferDataArray], { type: 'application/pdf' });
          FileSaver.saveAs(pdfBlob, 'jobDetails.pdf');

          this.spinner.hide();
          return;

        } else {
          this.spinner.hide();
          this.toastr.error('', data.message);
          return;

        }

      })
    }

  }

  showJobModel(job_id, content, header) {
    this.job_id = job_id
    const initialState = {
      content: content,
      header: header,
    };

    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: `modal-xxl modal-dialog-centered`,
    });

  }
  openModalConvertJobToV2(header: any, content: any, job: any) {
    this.job_id = job._id
    this.jobDetails = job
    const initialState = {
      content: content,
      header: header
    };
    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-xxl modal-dialog-centered",
    });
  }

  editJobModel(jobId, content, header) {
    this.job_id = jobId
    const initialState = {
      content: content,
      header: header

    };
    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-xxl modal-dialog-centered",
    });
  }
}

