<div class="modal-body">
    <div class="row p-2">
        <div class="col-md-4 col-sm-12">
            <div class="row">
                <div class="col-12">
                    <label for="note_id">{{'JOBS.note' | translate}}</label>
                    <textarea id="note_id" class="form-control" [(ngModel)]="noteStore"
                        [ngModelOptions]="{standalone: true}"></textarea>
                </div>

                <div class="col-12 p-4">
                    <button type="button" class="btn btn-secondary add-client-btn" (click)="storeNote()"
                        style="width: 80px;">{{'JOBS.send' |
                        translate}}</button>
                </div>
            </div>
        </div>
        <div class="col-md-8 col-sm-12">
            <div class="row p-4">
                <h3>{{'JOBS.list_note' | translate}}</h3>
                <div class="col-12">
                    <table class="table jobs-table">
                        <thead>
                            <tr>
                                <th> {{'JOBS.note' | translate}} </th>
                                <th> {{'JOBS.date' | translate}} </th>
                                <th> {{'JOBDETAILS.name' | translate}} </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="dataNote.length > 0">
                            <tr *ngFor="let noteData of dataNote">
                                <td>{{noteData.note}}</td>
                                <td>{{noteData.createdAt | date}}</td>
                                <td>
                                    <span *ngIf="noteData.userId.fullname != '' ">{{noteData.userId.fullname}}</span>
                                    <span
                                        *ngIf="noteData.userId.fullname == '' ">{{noteData.userId.pharmacy_name}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer" style="margin: auto; margin-right: 0;">
    <button type="button" (click)="toogle()" class="btn btn-secondary client-cancel-btn">{{'JOBS.cancel' |
        translate}}</button>
</div>