import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { PushNotificationService } from './push-notification.service';

@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  socket;

  constructor(
    private notif: PushNotificationService,
    // public refreshNotifications : DefaultLayoutComponent
    
  ) {
    // this.notif.requestPermission();
   }
  // setupSocketConnection() {
  //   var localData2 = JSON.parse(localStorage.getItem("currentUser"));
  //   if(localData2 && localData2._id){
     
  //     this.socket = io('http://18.141.224.119:3008',{query: {token1: localData2._id}});
      
  //     this.socket.on('accept', (data: any) => {
       
  
  //       if (localData2) {
  //         // this.refreshNotifications.refresh_notifications();
  //         var selected_language = JSON.parse(localStorage.getItem("language"));
  //         if (selected_language == "fr") {
  //           this.notif.generateNotification({
  //             'title': 'Travail accepté',
  //             'alertContent': ' Travail accepté par le conducteur!'
  //           })
  //         } else {
  //           this.notif.generateNotification({
  //             'title': 'Job Accepted',
  //             'alertContent': 'Job Accepted By Driver!'
  //           })
  //         }
  
  
  //       }
  
  //       console.log('data:', data);
  //       return;
  
  //     });
  
  //     this.socket.on('newJob', (data: any) => {
  //       console.log('newJob from driver', data);
  //       if (localData2) {
  //         // this.refreshNotifications.refresh_notifications();
  //         var selected_language = JSON.parse(localStorage.getItem("language"));
  //         if (selected_language == "fr") {
  //           this.notif.generateNotification({
  //             'title': 'Nouveau travail',
  //             'alertContent': 'Emploi créé par' + " " + data.driverName
  //           })
  
  //         } else {
  //           this.notif.generateNotification({
  //             'title': 'New Job',
  //             'alertContent': 'Job Created By' + " " + data.driverName
  //           })
  //         }
  
  
  
  //       }
  
  
  //     });
  
  //     this.socket.on('Decline_private_job', (data: any) => {
  
  //       if (localData2) {
  //         // this.refreshNotifications.refresh_notifications();
  //         var selected_language = JSON.parse(localStorage.getItem("language"));
  //         if (selected_language == "fr") {
  //           this.notif.generateNotification({
  //             'title': 'Travail refusé!',
  //             'alertContent': 'Travail refusé par' + " " + data.driverName
  //           })
  
  //         } else {
  //           this.notif.generateNotification({
  //             'title': 'Job Declined!',
  //             'alertContent': 'Job Declined By' + " " + data.driverName
  //           })
  //         }
  
  //       }
  
  
  //     });
  
  //     this.socket.on('single_ownership', (data: any) => {
  //       console.log("single_ownership", data);
  
  //       if (localData2) {
  //         // this.refreshNotifications.refresh_notifications();
  //         var selected_language = JSON.parse(localStorage.getItem("language"));
  //         if (selected_language == "fr") {
  //           this.notif.generateNotification({
  //             'title': 'Travail accepté!',
  //             'alertContent': 'Travail accepté par' + " " + data.driver_id.fullname
  //           })
  
  //         } else {
  //           this.notif.generateNotification({
  //             'title': 'Job Accepted!',
  //             'alertContent': 'Job Accepted By' + " " + data.driver_id.fullname
  //           })
  //         }
  
  //       }
  
  
  //     });
  //     this.socket.on('ownership', (data: any) => {
  //       console.log("ownershipdata", data);
  
  //       if (localData2) {
  //         // this.refreshNotifications.refresh_notifications();
  //         var selected_language = JSON.parse(localStorage.getItem("language"));
  //         if (selected_language == "fr") {
  //           this.notif.generateNotification({
  //             'title': 'Prise de possession!',
  //             'alertContent': 'Prise de possession par' + " " + data.driverName
  //           })
  
  //         } else {
  //           this.notif.generateNotification({
  //             'title': 'Ownership Taken!',
  //             'alertContent': 'Ownership Taken By' + " " + data.driverName
  //           })
  
  //         }
  
         
  //       }
  
  
  //     });
  
  //     this.socket.on('job_complete', (data: any) => {
  //       if (localData2) {
  //         // this.refreshNotifications.refresh_notifications();
  //         var selected_language = JSON.parse(localStorage.getItem("language"));
  //         if (selected_language == "fr") {
  //           this.notif.generateNotification({
  //             'title': 'Travail terminé!',
  //             'alertContent': 'Travail terminé par' + " " + data.driverName
  //           })
  
  //         } else {
  //           this.notif.generateNotification({
  //             'title': 'Job Completed!',
  //             'alertContent': 'Job Completed By' + " " + data.driverName
  //           })
  //         }
  
  //       }
  
  
  //     });
  
  //     this.socket.on('picked_up', (data: any) => {
  //       if (localData2) {
  //         // this.refreshNotifications.refresh_notifications();
  //         var selected_language = JSON.parse(localStorage.getItem("language"));
  //         if (selected_language == "fr") {
  //           this.notif.generateNotification({
  //             'title': 'Article ramassé',
  //             'alertContent': 'Article ramassé par' + " " + data.driverName
  //           })
  
  //         } else {
  //           this.notif.generateNotification({
  //             'title': 'Item Picked Up',
  //             'alertContent': 'Item Picked Up By' + " " + data.driverName
  //           })
  //         }
  
  //       }
  
  
  //     });
  
  //     this.socket.on('job_cancel_by_driver', (data: any) => {
  //       if (localData2) {
  //         // this.refreshNotifications.refresh_notifications();
  //         var selected_language = JSON.parse(localStorage.getItem("language"));
  //         if (selected_language == "fr") {
  //           this.notif.generateNotification({
  //             'title': 'Travail annulé',
  //             'alertContent': 'Travail annulé par' + " " + data.driverName
  //           })
  
  //         } else {
  //           this.notif.generateNotification({
  //             'title': 'Job Cancelled',
  //             'alertContent': 'Job Cancelled By' + " " + data.driverName
  //           })
  //         }
  
  //       }
  
  
  //     });
  
  //     this.socket.on('ownership_all', (data: any) => {
  //       console.log("ownership_all", data);
  
  //       if (localData2) {
  //         // this.refreshNotifications.refresh_notifications();
  //         var selected_language = JSON.parse(localStorage.getItem("language"));
  //         if (selected_language == "fr") {
  //           this.notif.generateNotification({
  //             'title': 'Tous les travaux acceptés!',
  //             'alertContent': 'Tous les travaux acceptés par le conducteur!'
  //           })
  
  //         } else {
  //           this.notif.generateNotification({
  //             'title': 'All Jobs Accepted!',
  //             'alertContent': 'All Jobs Accepted By driver!'
  //           })
  //         }
  
        
  //       }
  
  
  //     });
  
  //     this.socket.on("payment_recieved", (data: any) => {
  //       if (localData2) {
  //         // this.refreshNotifications.refresh_notifications();
  //         var selected_language = JSON.parse(localStorage.getItem("language"));
  //         if (selected_language == "fr") {
  //           this.notif.generateNotification({
  //             'title': 'Paiement libéré!',
  //             'alertContent': 'Votre paiement libéré par Vaistat!'
  //           })
  
  //         } else {
  //           this.notif.generateNotification({
  //             'title': 'Payment Released!',
  //             'alertContent': 'Your Payment Released By Vaistat!'
  //           })
  //         }
  
      
  //       }
  
  //     })
  
  //     this.socket.on("ride_payment_failed", (data: any) => {
  //       if (localData2) {
  //         // this.refreshNotifications.refresh_notifications();
  //         var selected_language = JSON.parse(localStorage.getItem("language"));
  //         if (selected_language == "fr") {
  //           this.notif.generateNotification({
  //             'title': 'Paiement échoué!',
  //             'alertContent': 'Échec du paiement du trajet!'
  //           });
  
  //         } else {
  //           this.notif.generateNotification({
  //             'title': 'Payment Failed!',
  //             'alertContent': 'Ride Payment failed!'
  //           });
  //         }
  
  //       }
  
  //     })
  

  //   }

  //   return this.socket;
    
  // }
  disconnectSocketConnection(){
    
    if(this.socket){
       console.log("DISCONNECT SOCKET SERVICE")
      this.socket.disconnect();
    }
  }
}
