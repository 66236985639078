<ng-template #dynamicContent>
  <div class="d-flex col-md-6 gap-1">
    <select class="form-control" style="width: unset" [(ngModel)]="sortBy" [ngModelOptions]="{ standalone: true }"
      (change)="sortByFilter($event)">
      <option [value]="0">{{ "CLIENTS.latest" | translate }}</option>
      <option [value]="1">{{ "CLIENTS.ascending" | translate }}</option>
      <option [value]="2">{{ "CLIENTS.descending" | translate }}</option>
    </select>
    <div class="flex-grow-1">
      <form>
        <input type="text" name="search" placeholder="{{ 'JOBS.search_customer' | translate }}" class="animated-search"
          (ngModelChange)="searchClients($event)" [(ngModel)]="searchEvent" [ngModelOptions]="{ standalone: true }" />
      </form>
    </div>

    <div>
      <app-button [imageSrc]="'./assets/img/new-ui/add_new.svg'" [buttonText]="'CLIENTS.add_new' | translate"
        (buttonClick)="showClientModal()"></app-button>
    </div>
  </div>
</ng-template>
<div class="mt-3">
  <app-table [theadTemplate]="theadTemplate" [tbodyTemplate]="tbodyTemplate" [noFoundTemplate]="noFoundTemplate"
    [totalItems]="allClientsCount" (onPageChange)="onPageChange($event)" [currentPage]="pageNumber"></app-table>
  <ng-template #theadTemplate>
    <tr>
      <th>{{ "CLIENTS.name" | translate }}</th>
      <th>{{ "CLIENTS.address" | translate }}</th>
      <th>{{ "CLIENTS.establishment" | translate }}</th>
      <th>{{ "CLIENTS.phone_no" | translate }}</th>
      <th>{{ "CLIENTS.email" | translate }}</th>
      <th>ACTION</th>
    </tr>
  </ng-template>

  <ng-template #tbodyTemplate>
    <tbody>
      <tr *ngFor="
          let clients of allClients
            | paginate
              : {
                  itemsPerPage: 10,
                  currentPage: pageNumber,
                  totalItems: allClientsCount
                }
        ">
        <td>
          <strong>{{ clients?.customer_l_name }} </strong>{{ clients?.customer_f_name }}
        </td>
        <td style="width: 30%">{{ sortAddress(clients?.location) }}</td>
        <td class="d-flex align-items-center gap-1">
          <div>
            {{
            clients?.establishment_id?.establishment_name
            ? clients?.establishment_id.establishment_name
            : "N/A"
            }}
          </div>

          <ul class="action-icons">
            <li>
              <img src="assets/img/new-ui/sync.svg" (click)="updateClientConfirmationModel(clients)" />
            </li>
          </ul>
        </td>
        <td>{{ clients?.country_code }}{{ clients?.phone_no }}</td>
        <td>{{ clients?.email ? clients?.email : "N/A" }}</td>

        <td>
          <ul class="action-icons">
            <li>
              <img src="assets/img/new-ui/driver.svg" (click)="
                  openListAddressesModal(
                    listAddrssesBodyTemplate,
                    listAddrssesHeaderTemplate,
                    clients
                  )
                " ngbTooltip="Address list" />
            </li>
            <li>
              <img src="assets/img/new-ui/edit.svg" (click)="showClientModal(clients)" ngbTooltip="Edit" />
            </li>
            <li>
              <img src="assets/img/new-ui/delete.svg" (click)="openDeleteModel(clients._id)" ngbTooltip="Delete" />
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </ng-template>

  <ng-template #noFoundTemplate>
    <div class="no-jobs-found">
      <p class="no-item text-center text-danger mb-0" *ngIf="!allClients.length">
        {{ "CLIENTS.no_client_found" | translate }}
      </p>
    </div>
  </ng-template>
</div>

<!-------modal---->
<ng-template #upsertClientTemplate>
  <p class="sub-title center-sub-title">
    {{ "CLIENTS.establishment_info" | translate }}
  </p>

  <div class="d-flex flex-column gap-1" [formGroup]="registerForm">
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "CREATEJOB.firstname" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input type="text" formControlName="firstName" class="form-control text-capitalize"
          [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
          placeholder="{{ 'CREATEJOB.firstname' | translate }}" />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "CREATEJOB.lastname" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input type="text" formControlName="lastName" class="form-control text-capitalize"
          [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
          placeholder="{{ 'CREATEJOB.lastname' | translate }}" />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "CREATEJOB.phone_no" | translate }}
      </div>
      <div class="input-group col-7 pl-0">
        <div *ngIf="phoneNumber" class="input-group-prepend">
          <span class="input-group-text">{{ countryCode }}</span>
        </div>
        <input type="text" class="form-control" placeholder="{{ 'CREATEJOB.phone_no' | translate }}"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          (ngModelChange)="mychange($event)" [ngModel]="phoneNumber" [ngModelOptions]="{ standalone: true }"
          maxlength="12" />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "CLIENTS.email" | translate }}
      </div>

      <div class="col-7 pl-0">
        <input type="text" formControlName="email" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="{{ 'CLIENTS.email' | translate }}" />
      </div>
    </div>
    <div *ngIf="!customer_id" class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "CLIENTS.location" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input type="text" formControlName="Location" class="form-control"
          placeholder="{{ 'CLIENTS.location' | translate }}"
          [ngClass]="{ 'is-invalid': submitted && f.Location.errors }" readonly (click)="
            openModalSelectLocation(
              selectLocationBodyTemplate,
              selectLocationHeaderTemplate
            )
          " />
      </div>
    </div>
    <div *ngIf="!customer_id" class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "JOBDETAILS.alias_address" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input type="text" [formControl]="
            language === 'en' ? location_alias_en : location_alias_fr
          " class="form-control" readonly placeholder="{{ 'JOBDETAILS.alias_address' | translate }}" (click)="
            openModalSelectLocation(
              selectLocationBodyTemplate,
              selectLocationHeaderTemplate
            )
          " />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "JOBDETAILS.appartment_no" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input type="text" formControlName="aptNo" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.aptNo.errors }"
          placeholder="{{ 'JOBDETAILS.appartment_no' | translate }}" />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "JOBDETAILS.door_code" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input type="text" formControlName="doorCode" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.doorCode.errors }"
          placeholder="{{ 'JOBDETAILS.door_code' | translate }}" />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "Client Note" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input type="text" formControlName="customer_note" class="form-control" [ngClass]="{
            'is-invalid': submitted && f.customer_note.errors
          }" placeholder="{{ 'Client Note' | translate }}" />
      </div>
    </div>

    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "Client Parent" | translate }}
      </div>
      <div class="col-7 pl-0">

        <select formControlName="customer_parent" class="form-control" class="form-control" [ngClass]="{
          'is-invalid': submitted && f.customer_parent.errors
        }" style="height: 35px; border-radius: 0.25rem" [(ngModel)]="customer_parent" (change)="filterClients($event)">
          <option *ngFor="let c of sortedClients" value="{{c._id}}">{{c.customer_f_name}} {{c.customer_l_name}}</option>
        </select>
      </div>
    </div>
  </div>
</ng-template>

<!-- /.modal -->

<ng-template #deleteClientTemplate>
  <div class="text-center">{{ "CLIENTS.delete_Comment" | translate }}</div>
</ng-template>

<!-- /.modal -->

<!-- get all residence function start here -->
<ng-template #updateEstablishmentNameTemplate>
  <div class="form-group my-selct mb-0">
    <p-autoComplete [ngModelOptions]="{ standalone: true }" [(ngModel)]="establishment_name"
      [suggestions]="establishments" (completeMethod)="getAllEstablishmentData($event.query)"
      (onSelect)="onSelectEstablish($event)" field="establishment_name" [size]="30" [minLength]="1"
      placeholder="{{ 'CREATEJOB.establish_name' | translate }}" class="auto-in auto-cust">
      <ng-template let-customer_name pTemplate="item">
        <div>{{ customer_name.establishment_name }}</div>
      </ng-template>
    </p-autoComplete>
  </div>
</ng-template>
<ng-template #selectLocationHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3 justify-content-between">
    <h5 class="modal-title">{{ "ADDRESS.new_address" | translate }}</h5>
    <ng-container *ngTemplateOutlet="locationAgmComponent?.dynamicContentTemplate"></ng-container>
  </div>
</ng-template>
<ng-template #selectLocationBodyTemplate>
  <app-location-agm [activeAlias]="true" [item]="locationData"
    (getLocationData)="handleAddressChange($event)"></app-location-agm>
</ng-template>

<ng-template #listAddrssesHeaderTemplate>
  <div class="d-flex flex-grow-1 justify-content-end mr-3">
    <ng-container *ngTemplateOutlet="
        listClientAddressesModalComponent?.dynamicContentTemplate
      "></ng-container>
  </div>
</ng-template>

<ng-template #listAddrssesBodyTemplate>
  <app-list-client-addresses-modal [client]="selectedClient"
    (refreshTableClients)="refreshTableClients()"></app-list-client-addresses-modal>
</ng-template>