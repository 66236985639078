import { Component, OnInit, ViewChild } from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  ModalDirective
} from 'ngx-bootstrap/modal';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';

import 'moment-timezone';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {
  @ViewChild('deleteBrand') public deleteBrand: ModalDirective;

  public allBrand = [];
  allBrandsCount = 0;
  submitted = false;
  page = 1;
  public language = "en";
  sort = 1;
  filter = '';
  localData: any;
  customer_id: any;
  pageNumber: any;

  pages: number[] = [];
  selected: number | undefined = 0;

  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.sort = 1;
    this.submitted = false;
    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en"
    }

    this.localData = JSON.parse(localStorage.getItem("currentUser"));
    this.filter = '';
    this.getAllBrand(this.filter, this.page, this.sort);


  }

  //SEARCH CLIENTS FUNCTION START HERE
  searchBrand(event) {
    if (event.length == 0) {
      return this.ngOnInit();
    }
    if (event.length <= 0) {
      return console.log('less then 2');
    }

    console.log(event);
    this.filter = event;
    this.page = 1;
    this.sort = 1;
    this.getAllBrand(this.filter, this.page, this.sort);
  }
  /*
    searchBrand(event) {
      this.page = 1;
      this.searchEvent = event ? event : "";
      if (event.length == 0) {
        localStorage.setItem("page", JSON.stringify("1"));
        localStorage.setItem("filter", JSON.stringify(""));
        return this.getAllBrand(this.filter, this.page, this.sort);;
      }
      if (this.searchBrand.length > 2) {
        this.getAllBrand(this.filter, this.page, this.sort);;
      }
    }
  
    */

  //GET ALL CLIENT API START HERE
  getAllBrand(filter, page, sort) {
    this.spinner.show();
    this.services.brandAll(filter, page, sort).pipe().subscribe(data => {

      this.spinner.hide();
      this.allBrand = data.entityList;
      this.allBrandsCount = data.totalRegister;

      //console.log(data);


    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });
  }

  //ON PAGE CHANGE FUNCTION
  onPageChange(event) {
    this.page = event;
    console.log(event);
    this.getAllBrand(this.filter, this.page, this.sort);
  }

  //DELETE CLIENT CONFIRMATION MODEL
  openDeleteModel(id) {
    this.customer_id = id;
    this.deleteBrand.show();
  }

  deleteExistBrand() {
    this.spinner.show();
    this.services.deleteBrand(this.customer_id).pipe().subscribe(data => {

      this.spinner.hide();
      this.deleteBrand.hide();
      // this.ngOnInit();
      this.getAllBrand(this.filter, this.page, this.sort)
      return;

    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });
  }




}
