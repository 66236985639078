<ng-template #dynamicContent>
  <div class="d-flex col-md-6 gap-1">
    <div class="flex-grow-1">
      <form>
        <input
          type="text"
          name="search"
          placeholder="{{ 'JOBS.search_customer' | translate }}"
          class="animated-search"
          (ngModelChange)="
            showDetails
              ? customerInfo.searchClients($event)
              : searchResidence($event)
          "
          [(ngModel)]="showDetails ? customerInfo?.userSearch : searchEvent"
          [ngModelOptions]="{ standalone: true }"
        />
      </form>
    </div>
    <div>
      <app-button
        [imageSrc]="'./assets/img/new-ui/add_new.svg'"
        [buttonText]="'CLIENTS.add_new' | translate"
        (buttonClick)="
          showDetails ? customerInfo.showClientModal() : showResidenceModal()
        "
      ></app-button>
    </div>
  </div>
</ng-template>

<div *ngIf="!showDetails; else details" class="mt-3">
  <app-table
    [theadTemplate]="theadTemplate"
    [tbodyTemplate]="tbodyTemplate"
    [noFoundTemplate]="noFoundTemplate"
    [totalItems]="allResidenceCount"
    (onPageChange)="onPageChange($event)"
    [currentPage]="pageNumber"
  ></app-table>
  <ng-template #theadTemplate>
    <tr>
      <th>{{ "CLIENTS.establish_name" | translate }}</th>
      <th>{{ "CLIENTS.address" | translate }}</th>
      <th>{{ "CLIENTS.phone_no" | translate }}</th>
      <th>{{ "CLIENTS.email" | translate }}</th>
      <th>{{ "CLIENTS.no_of_customers" | translate }}</th>
      <th>ACTION</th>
    </tr>
  </ng-template>

  <ng-template #tbodyTemplate>
    <tbody>
      <tr
        *ngFor="
          let residence of allResidence
            | paginate
              : {
                  itemsPerPage: 10,
                  currentPage: pageNumber,
                  totalItems: allResidenceCount
                }
        "
      >
        <td
          *ngIf="residence?.establishment_name; else EstablishmentElse"
          (click)="goToCustomerInfo(residence._id, residence.customers_count)"
        >
          {{ residence?.establishment_name }}
        </td>
        <ng-template #EstablishmentElse>
          <td
            (click)="goToCustomerInfo(residence._id, residence.customers_count)"
          >
            N/A
          </td>
        </ng-template>

        <td
          (click)="goToCustomerInfo(residence._id, residence.customers_count)"
        >
          {{ sortAddress(residence?.location) }}
        </td>

        <td
          (click)="goToCustomerInfo(residence._id, residence.customers_count)"
        >
          {{
            residence?.establishment_phone
              ? residence?.establishment_country_code +
                " " +
                residence?.establishment_phone
              : "N/A"
          }}
        </td>

        <td
          (click)="goToCustomerInfo(residence._id, residence.customers_count)"
        >
          {{
            residence?.establishment_email
              ? residence?.establishment_email
              : "N/A"
          }}
        </td>

        <td
          (click)="goToCustomerInfo(residence._id, residence.customers_count)"
        >
          {{ residence?.customers_count }}
        </td>

        <td>
          <ul class="action-icons">
            <li>
              <img
                src="assets/img/new-ui/edit.svg"
                (click)="showResidenceModal(residence)"
                ngbTooltip="Edit"
              />
            </li>
            <li>
              <img
                src="assets/img/new-ui/delete.svg"
                (click)="openDeleteModel(residence._id)"
                ngbTooltip="Delete"
              />
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </ng-template>

  <ng-template #noFoundTemplate>
    <div class="no-jobs-found">
      <p
        class="no-item text-center text-danger mb-0"
        *ngIf="!allResidence.length"
      >
        {{ "CLIENTS.no_client_found" | translate }}
      </p>
    </div>
  </ng-template>
</div>
<!-------modal---->
<ng-template #upsertResidenceTemplate>
  <p class="sub-title center-sub-title">
    {{ "CLIENTS.establishment_info" | translate }}
  </p>
  <form class="d-flex flex-column gap-1" [formGroup]="registerForm">
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "CLIENTS.establishment_name" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input
          type="text"
          formControlName="residenceName"
          class="form-control"
          [ngClass]="{
            'is-invalid': submitted && f.residenceName.errors
          }"
          placeholder="{{ 'CLIENTS.establishment_name' | translate }}"
        />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "CLIENTS.location" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input
          ngx-google-places-autocomplete
          formControlName="residenceLocation"
          [options]="options"
          placeholder="{{ 'CLIENTS.location' | translate }}"
          #placesRef="ngx-places"
          [ngClass]="{
            'is-invalid': submitted && f.residenceLocation.errors
          }"
          (onAddressChange)="handleAddressChange($event)"
          class="form-control"
        />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "CLIENTS.establishment_email" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input
          type="text"
          formControlName="establishment_email"
          class="form-control"
          [ngClass]="{
            'is-invalid': submitted && f.establishment_email.errors
          }"
          placeholder="{{ 'CLIENTS.establishment_email' | translate }}"
        />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "Establishment Note" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input
          type="text"
          formControlName="customer_note"
          class="form-control"
          [ngClass]="{
            'is-invalid': submitted && f.customer_note.errors
          }"
          placeholder="{{ 'Establishment Note' | translate }}"
        />
      </div>
    </div>
  </form>
</ng-template>

<!-- /.modal -->

<ng-template #deleteResidenceTemplate>
  <div class="text-center">{{ delete_message | translate }}</div>
</ng-template>

<!-- /.modal -->
<ng-template #details>
  <app-establishment-customer-info
    #customerInfo
    [establish_cust_type]="customer_type"
    [establish_cust_id]="establishCustomerId"
    [(show)]="showDetails"
  ></app-establishment-customer-info>
</ng-template>
