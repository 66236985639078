import {
  Component,
  OnInit
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  ToastrService
} from 'ngx-toastr';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import {
  AuthService
} from '../../auth.service';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  TranslateService
} from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  public selectedLanguage = "en";
  cookieStatus = true;
  constructor(
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    public toastr: ToastrService,
    private authServices: AuthService,
    private spinner: NgxSpinnerService,
    public ts: TranslateService
  ) {
    ts.addLangs(['en', 'fr']);
    ts.setDefaultLang('en');

    const browserLang = ts.getBrowserLang();
    ts.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  ngOnInit() {

    this.cookieStatus = JSON.parse(localStorage.getItem("cookiesStatus")) ? JSON.parse(localStorage.getItem("cookiesStatus")) : false;
    var lang = JSON.parse(localStorage.getItem("language"));
    if (!lang) {
      lang = "en"
    }
    this.selectedLanguage = lang;
    localStorage.setItem('language', JSON.stringify(lang));
    this.ts.use(lang);

    if (this.authServices.isLoggedIn()) {
      this.router.navigate(['/dashboard']);
    }

    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]

    });



  }
  get f() {
    return this.forgotForm.controls;
  }


  onSubmit() {
    console.log('onSubmit')
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotForm.invalid) {
      return;
    }

    // this.loading = true;
    this.spinner.show();
    this.authServices.forgot_password(this.f.email.value).pipe().subscribe(data => {
      if (data.code == 200) {
        this.toastr.success('success', 'Password Reset Successfully!')
        this.router.navigate(['login']);
        this.spinner.hide();
      } else {
        this.toastr.error('error', data.message);
        this.spinner.hide();
        return;
      }
    })

  }

  switchLang(lang: string) {
    this.spinner.show();
    localStorage.setItem('language', JSON.stringify(lang));
    this.ts.use(lang);
    this.selectedLanguage = lang;
    this.spinner.hide();
    // this.navItems = [];
  }

  changeCookieStatus() {
    this.cookieStatus = true;
    localStorage.setItem('cookiesStatus', JSON.stringify('true'));

  }
}