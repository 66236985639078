<div class="mt-3">
  <div class="row">
    <div class="col-md-12">
      <div class="scp-breadcrumb">
        <ul class="breadcrumb">
          <li class="b-jobs">
            <a>{{ "HOME.dashboard" | translate }}</a>
          </li>
          <!-- Map -->
          <li class="align-right-1">
            <div class="custom-control mb-3">
              <input
                type="button"
                [(ngModel)]="openMap"
                (click)="openMap = !openMap; mapCargar()"
                class="custom-control-input"
                id="customSwitches"
              />
              <label
                class="btn btn-dark"
                for="customSwitches"
                *ngIf="!openMap"
                >{{ "DASHBOARD.show_map" | translate }}</label
              >
              <label
                class="btn btn-dark"
                for="customSwitches"
                *ngIf="openMap"
                >{{ "DASHBOARD.hide_map" | translate }}</label
              >
            </div>
          </li>
          <!-- Stats -->
          <li class="align-right-2">
            <div class="custom-control mb-3">
              <input
                type="button"
                (click)="openStatistics = !openStatistics; openStatics()"
                class="custom-control-input"
                id="customSwitchesSt"
              />
              <img src="" alt="" />
              <label
                class="btn btn-dark"
                for="customSwitchesSt"
                *ngIf="!openStatistics"
                >{{ "DASHBOARD.show_stats" | translate }}</label
              >
              <label
                class="btn btn-dark"
                for="customSwitchesSt"
                *ngIf="openStatistics"
                >{{ "DASHBOARD.hide_stats" | translate }}</label
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row mt-3 px-0">
    <div class="col-md-3 pr-0">
      <div
        class="card dashboard-cards point"
        (click)="goToJobsList('new-jobs')"
      >
        <div class="card-body col-md-12 p-2">
          <div class="side-img mt-1">
            <img src="assets/img/new-ui/new_jobs.svg" alt="new-job-img" />
          </div>
          <div class="col-md status">
            <h3>{{ new_jobs }}</h3>
            <p>{{ "DASHBOARD.new_job_request" | translate }}</p>
            <p>
              (
              {{ public_new_jobs }}
              {{ "DASHBOARD.JOB.public" | translate }} |
              {{ private_new_jobs }}
              {{ "DASHBOARD.JOB.private" | translate }}
              )
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 pr-0">
      <div
        class="card dashboard-cards point"
        (click)="goToJobsList('active-jobs')"
      >
        <div class="card-body col-md-12 p-2">
          <div class="side-img mt-1">
            <img
              src="assets/img/new-ui/active_jobs.svg"
              alt="active-jobs-img"
            />
          </div>
          <div class="col-md status">
            <h3>{{ active_jobs }}</h3>
            <p>{{ "DASHBOARD.active_jobs" | translate }}</p>
            <p>
              (
              {{ public_active_jobs }}
              {{ "DASHBOARD.JOB.public" | translate }} |
              {{ private_active_jobs }}
              {{ "DASHBOARD.JOB.private" | translate }}
              )
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 pr-0">
      <div
        class="card dashboard-cards point"
        (click)="goToJobsList('completed-jobs')"
      >
        <div class="card-body col-md-12 p-2">
          <div class="side-img mt-1">
            <img
              src="assets/img/new-ui/completed_jobs.svg"
              alt="completed-jobs-img"
            />
          </div>
          <div class="col-md status">
            <h3>{{ only_completed_count }}</h3>
            <p>{{ "DASHBOARD.completed_jobs" | translate }}</p>
            <p>
              (
              {{ public_only_completed_count }}
              {{ "DASHBOARD.JOB.public" | translate }} |
              {{ private_only_completed_count }}
              {{ "DASHBOARD.JOB.private" | translate }}
              )
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 pr-0">
      <div class="card dashboard-cards point" (click)="goToJobsList()">
        <div class="card-body col-md-12 p-2">
          <div class="side-img mt-1">
            <img
              src="assets/img/new-ui/total_expenses.svg"
              alt="total-expenses-img"
            />
          </div>
          <div class="col-md status">
            <h3>${{ avg_revenue | number : "1.2-2" }}</h3>
            <p>{{ "DASHBOARD.total_expenses" | translate }}</p>
            <p>
              ( ${{ public_avg_revenue | number : "1.2-2" }}
              {{ "DASHBOARD.JOB.public" | translate }}, ${{
                private_avg_revenue | number : "1.2-2"
              }}
              {{ "DASHBOARD.JOB.private" | translate }}
              )
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="dashboard-map" *ngIf="openMap">
        <h3 class="map-title mb-2">{{ "DASHBOARD.map" | translate }}</h3>
        <app-drivers-map></app-drivers-map>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ng-container class="card" *ngIf="openStatistics">
        <h3 class="stats-title mb-3">{{ "DASHBOARD.stats" | translate }}</h3>
        <app-job-statistics></app-job-statistics>
      </ng-container>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-12">
      <select
        class="form-control form-control-lg select-dashboard"
        [(ngModel)]="jobFilter"
        (change)="changeStatus(jobFilter)"
      >
        <option value="P">
          {{ "DASHBOARD.new_job_request" | translate }}
        </option>
        <option value="A">{{ "JOBS.active_jobs" | translate }}</option>
        <option value="C">
          {{ "DASHBOARD.completed_jobs" | translate }}
        </option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="bg-griss py-2" style="width: 100%">
      <div class="row mb-3 mt-2">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <input
            pInputText
            [(ngModel)]="keyword"
            class="form-control my-2"
            type="text"
            placeholder="Search keyword"
            style="width: 100%"
            (input)="searchKeyword()"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-9 col-12">
          <div class="flex">
            <button
              *ngFor="let dataWeek of dayWeek"
              class="btn m-1"
              [ngClass]="
                dataWeek.active ? 'btn-success' : 'btn-outline-success'
              "
              (click)="activeButtonDays(dataWeek)"
            >
              {{ "JOBS." + dataWeek.days | translate }}
            </button>

            <button
              [ngClass]="weekButton ? 'btn-success' : 'btn-outline-success'"
              (click)="weekButton = true; onWeek()"
              class="btn"
            >
              {{ "JOBS.week" | translate }}
            </button>
            <button
              [ngClass]="monthButton ? 'btn-success' : 'btn-outline-success'"
              (click)="monthButton = true; onMonth()"
              class="btn"
            >
              {{ "JOBS.month" | translate }}
            </button>
          </div>
        </div>
        <div class="col-md-3 col-12">
          <i
            class="fa fa-chevron-left calendar-chevron-rigth"
            (click)="searchWithDate(0)"
            aria-hidden="true"
          ></i>
          <div class="calender-custom">
            <input
              class="form-control box-m-in"
              [navigation]="'select'"
              [minDate]="{ year: 1900, month: 1, day: 1 }"
              placeholder="Date"
              [(ngModel)]="dateValue"
              ngbDatepicker
              #buttonEl="ngbDatepicker"
              #start="ngbDatepicker"
              [readonly]="true"
              (click)="start.toggle()"
              (ngModelChange)="searchWithDate(2)"
              style="cursor: pointer; display: inline-block; margin-right: 10px"
              firstDayOfWeek="7"
            />
            <i
              class="fa fa-calendar point"
              (click)="start.toggle()"
              aria-hidden="true"
            ></i>
          </div>
          <i
            class="fa fa-chevron-right calendar-chevron"
            aria-hidden="true"
            (click)="searchWithDate(1)"
          ></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12"></div>
        <div
          class="col-md-6 col-12"
          style="justify-content: end; display: flex"
        >
          <div ngbDropdown class="d-inline-block m-1">
            <button
              type="button"
              class="btn btn-outline-primary"
              id="dropdownBasic1"
              ngbDropdownToggle
            >
              {{ "JOBS.columns" | translate }}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button
                class="btn"
                ngbDropdownItem
                *ngFor="let datacolumns of columns"
                style="text-transform: uppercase"
                (click)="hideColumn(datacolumns)"
                [ngStyle]="{
                  'background-color': datacolumns.visible
                    ? '#cecefe'
                    : '#20a8d8'
                }"
                [style.color]="datacolumns.visible ? 'black' : 'white'"
              >
                {{ "JOBS." + datacolumns.name | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <table
        class="ui-widget-header jobs-table ui-table ui-widget ui-table-responsive"
        style="width: 100%"
      >
        <thead class="ui-table-caption ui-widget-header">
          <tr class="not-shadow">
            <th class="d-flex" style="padding-left: 1.1rem">
              <input
                type="checkbox"
                name="activeCheckbox"
                [(ngModel)]="activeCheckbox"
                style="width: 20px; height: 20px"
                (click)="activeCheckbox = !activeCheckbox; addSelectAll($event)"
              />
              <span class="mx-2 pt-1">{{
                "JOBS.client_name" | translate
              }}</span>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="ListJob">
          <tr
            *ngFor="let infolist of ListJob; let i = index"
            class="not-shadow"
          >
            <td [attr.colspan]="countColumns" class="boder-table">
              <table
                class="table-borderless jobs-table table"
                [style.width]="
                  infolist?.active ? '100%' : '-webkit-fill-available'
                "
              >
                <thead class="ui-table-caption ui-widget-header no-boder">
                  <tr class="not-shadow">
                    <th
                      [attr.colspan]="countColumns - 1"
                      class="text-align-left headerTh"
                    >
                      <button
                        (click)="infolist.active = !infolist.active"
                        class="btn btn-sm btn-secondary"
                        *ngIf="infolist.active"
                      >
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </button>
                      <button
                        (click)="infolist.active = !infolist.active"
                        class="btn btn-sm btn-success"
                        *ngIf="!infolist.active"
                      >
                        <i class="fa fa-chevron-up" aria-hidden="true"></i>
                      </button>
                      <span class="mx-3">
                        {{ infolist.jobs[0]?.customer_f_name }}
                        {{ infolist.jobs[0]?.customer_l_name }}
                        <small class="text-muted mx-2">
                          - Count ({{ infolist.count }})</small
                        >
                      </span>
                    </th>
                    <th colspan="1" class="float-right">
                      <button
                        class="btn btn-outline-success btn-sm"
                        style="width: max-content"
                        (click)="openHistorialModal(infolist.customer_id)"
                      >
                        <i class="fa fa-history" aria-hidden="true"></i
                        ><span class="mx-2">{{
                          "JOBS.history" | translate
                        }}</span>
                      </button>
                    </th>
                  </tr>
                  <tr class="not-shadow">
                    <th></th>
                    <th *ngIf="columns[5].visible">
                      {{ "JOBS.job_id" | translate }}
                    </th>
                    <th>{{ "JOBDETAILS.job_type" | translate }}</th>
                    <th *ngIf="columns[0].visible">
                      {{ "JOBS.address" | translate }}
                    </th>
                    <th *ngIf="columns[1].visible">
                      {{ "JOBS.due_date" | translate }}
                    </th>
                    <th *ngIf="columns[2].visible">
                      {{ "JOBS.status" | translate }}
                    </th>
                    <th *ngIf="columns[3].visible">
                      {{ "JOBS.amount" | translate }}
                    </th>
                    <th *ngIf="columns[4].visible">
                      {{ "JOBS.driver_name" | translate }}
                    </th>
                    <th>{{ "JOBS.action" | translate }}</th>
                  </tr>
                </thead>
                <tbody *ngIf="!infolist.active">
                  <tr
                    *ngFor="let activeJobsData of infolist.jobs"
                    class="not-shadow"
                    style="border: 1px solid #d9d9d9; border-radius: 5px"
                  >
                    <td class="text-center">
                      <input
                        type="checkbox"
                        [value]="activeJobsData._id"
                        style="width: 20px; height: 20px; cursor: pointer"
                        [(ngModel)]="activeJobsData.selectStatus"
                      />
                    </td>
                    <td *ngIf="columns[5].visible" class="text-center">
                      {{ activeJobsData.booking_code }}
                    </td>
                    <td
                      (click)="
                        showJobModel(
                          activeJobsData._id,
                          jobDetailsBodyTemplate,
                          jobDetailsHeaderTemplate
                        )
                      "
                      class="text-center"
                    >
                      <button
                        *ngIf="activeJobsData.job_type == '1'"
                        class="stats-active"
                        style="background: #d0d7ff; color: #0b2194"
                      >
                        {{ "JOBS.job_type_delivery" | translate }}
                      </button>
                      <button
                        *ngIf="activeJobsData.job_type == '2'"
                        class="stats-active"
                        style="background: #ffe4c8; color: #ff8b17"
                      >
                        {{ "JOBS.job_type_pickup" | translate }}
                      </button>
                    </td>

                    <td
                      (click)="
                        showJobModel(
                          activeJobsData._id,
                          jobDetailsBodyTemplate,
                          jobDetailsHeaderTemplate
                        )
                      "
                      *ngIf="columns[0].visible"
                      class="text-center"
                    >
                      {{ sortAddress(activeJobsData.drop_off_location) }}
                    </td>

                    <td
                      (click)="
                        showJobModel(
                          activeJobsData._id,
                          jobDetailsBodyTemplate,
                          jobDetailsHeaderTemplate
                        )
                      "
                      *ngIf="columns[1].visible"
                      class="text-center"
                    >
                      {{ modifyDate(activeJobsData.job_date) }}
                    </td>
                    <td
                      (click)="
                        showJobModel(
                          activeJobsData._id,
                          jobDetailsBodyTemplate,
                          jobDetailsHeaderTemplate
                        )
                      "
                      *ngIf="columns[2].visible"
                      class="text-center"
                    >
                      <button
                        *ngIf="activeJobsData.job_status == 'A'"
                        class="stats-active"
                      >
                        {{ "JOBS.active" | translate }}
                      </button>

                      <button
                        *ngIf="activeJobsData.job_status == 'C'"
                        class="stats-complete"
                      >
                        {{ "JOBS.completed" | translate }}
                      </button>

                      <button
                        *ngIf="
                          activeJobsData.isExpired == true &&
                          activeJobsData.job_status != 'C' &&
                          activeJobsData.job_status != 'N' &&
                          activeJobsData.job_status != 'CN'
                        "
                        class="stats-complete"
                        style="background: #efe2e2; color: #ff010d"
                      >
                        {{ "JOBS.expired" | translate }}
                      </button>

                      <button
                        *ngIf="activeJobsData.job_status == 'CN'"
                        class="stats-complete"
                        style="background: #efe2e2; color: #ff010d"
                      >
                        {{ "JOBS.cancelled" | translate }}
                      </button>

                      <button
                        *ngIf="
                          activeJobsData.job_status == 'P' &&
                          activeJobsData.isAccepted == false
                        "
                        class="stats-active"
                        style="background: #ffe4c8; color: #ff8b17"
                      >
                        {{ "JOBS.pending" | translate }}
                      </button>

                      <button
                        *ngIf="
                          activeJobsData.job_status == 'P' &&
                          activeJobsData.isAccepted == true
                        "
                        class="stats-active"
                      >
                        {{ "JOBS.accepted" | translate }}
                      </button>

                      <div *ngIf="isRecursiveJob">
                        <button class="stats-recursive text-danger">
                          <i class="fa fa-circle mr-2" aria-hidden="true"></i
                          >{{ "JOBS.recursive" | translate }}
                        </button>
                      </div>
                    </td>
                    <td
                      (click)="
                        showJobModel(
                          activeJobsData._id,
                          jobDetailsBodyTemplate,
                          jobDetailsHeaderTemplate
                        )
                      "
                      *ngIf="columns[3].visible"
                      class="text-center"
                    >
                      <span class="amount" style="font-size: 16px"
                        >${{
                          activeJobsData.job_amount | number : "1.2-2"
                        }}</span
                      >
                      <p
                        *ngIf="
                          activeJobsData.job_status == 'C' &&
                          activeJobsData.customer_payment_id != null &&
                          activeJobsData.customer_payment_id.payment_method ==
                            '2'
                        "
                        class="cash"
                      >
                        {{ "JOBS.cash" | translate }}
                      </p>

                      <p
                        *ngIf="
                          activeJobsData.job_status == 'C' &&
                          activeJobsData.customer_payment_id != null &&
                          activeJobsData.customer_payment_id.payment_method ==
                            '1'
                        "
                        class="cash"
                      >
                        {{ "JOBS.card" | translate }}
                      </p>

                      <p
                        *ngIf="
                          activeJobsData.job_status == 'C' &&
                          activeJobsData.customer_payment_id != null &&
                          activeJobsData.customer_payment_id.payment_method ==
                            '3'
                        "
                        class="cash"
                      >
                        {{ "JOBS.cheque" | translate }}
                      </p>
                      <p
                        *ngIf="
                          activeJobsData.job_status == 'C' &&
                          activeJobsData.customer_payment_id != null &&
                          activeJobsData.customer_payment_id.payment_method ==
                            '5'
                        "
                        class="cash"
                      >
                        {{ "TPV" | translate }}
                      </p>
                    </td>
                    <td *ngIf="columns[4].visible" class="text-center">
                      <span *ngIf="activeJobsData.driver_id; else driverElse">
                        {{ activeJobsData.driver_id.username }}</span
                      >

                      <ng-template #driverElse> N/A </ng-template>
                    </td>
                    <td class="text-center">
                      <div class="row">
                        <button
                          class="download-pdf point"
                          (click)="getPdf(activeJobsData._id)"
                          ngbTooltip="{{ 'INVOICES.downloadPdf' | translate }}"
                        >
                          <i class="fa fa-print" aria-hidden="true"></i>
                        </button>

                        <button
                          *ngIf="activeJobsData.job_status !== 'C'"
                          class="download-pdf point"
                          (click)="
                            editJobModel(
                              activeJobsData._id,
                              editJobBodyTemplate,
                              editJobHeaderTemplate
                            )
                          "
                        >
                          <i
                            class="fa fa-pencil"
                            aria-hidden="true"
                            style="cursor: pointer"
                            ngbTooltip="{{ 'JOBS.edit_job' | translate }}"
                          ></i>
                        </button>

                        <button
                          *ngIf="activeJobsData.job_status !== 'C'"
                          class="download-pdf point"
                          (click)="cancelJobModal(activeJobsData)"
                          ngbTooltip="{{ 'CREATEJOB.cancel_job' | translate }}"
                          href="#"
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>

                        <button
                          class="download-pdf point"
                          ngbTooltip="{{ 'JOBS.note' | translate }}"
                          (click)="opensendNoteModal(activeJobsData._id)"
                        >
                          <i class="fa fa-edit" aria-hidden="true"></i>
                        </button>

                        <button
                          class="download-pdf point"
                          (click)="openRecursiveModal(activeJobsData)"
                          ngbTooltip="{{ 'JOBS.recursive' | translate }}"
                          href="#"
                        >
                          <i class="fa fa-refresh" aria-hidden="true"></i>
                        </button>

                        <button
                          class="download-pdf point"
                          (click)="
                            showJobModel(
                              activeJobsData._id,
                              jobDetailsBodyTemplate,
                              jobDetailsHeaderTemplate
                            )
                          "
                          ngbTooltip="{{
                            'JOBDETAILS.job_details' | translate
                          }}"
                          href="#"
                        >
                          <i class="fa fa-eye" aria-hidden="true"></i>
                        </button>

                        <button
                          class="download-pdf point"
                          (click)="
                            openApproveOwnershipModal(activeJobsData._id)
                          "
                          *ngIf="
                            activeJobsData.ownershipApprovedByPharmacy ==
                              false &&
                            activeJobsData.job_status == 'P' &&
                            activeJobsData.isAccepted == true &&
                            activeJobsData.isExpired == false &&
                            activeJobsData.ride_fare > 0 &&
                            activeJobsData.jobFor == '1'
                          "
                        >
                          <i
                            class="fa fa-check-circle"
                            aria-hidden="true"
                            style="cursor: pointer; color: #0fc303"
                            ngbTooltip="Approve ownership"
                          ></i>
                        </button>
                        <button
                          class="download-pdf point"
                          (click)="
                            openNotificationModal(
                              activeJobsData.driver_id,
                              activeJobsData._id
                            )
                          "
                          *ngIf="
                            activeJobsData.isAccepted == true &&
                            activeJobsData.driver_id
                          "
                        >
                          <i
                            class="fa"
                            [class]="
                              infoList?.customer?.lastLogin
                                ? 'fa-mobile'
                                : 'fa-bell'
                            "
                            aria-hidden="true"
                            style="cursor: pointer; color: #0fc303"
                            ngbTooltip="Send Notification"
                          ></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <app-pagitanion-jobs
      [countPage]="pageSize"
      (paginatenews)="onPaginate($event)"
      (paginatresult)="onPaginateResult($event)"
      style="width: 100%"
      #paginationsApp
    ></app-pagitanion-jobs>
  </div>

  <div style="height: 5vh"></div>
</div>

<div
  bsModal
  #successModal2="bs-modal"
  [config]="{ backdrop: 'static', keyboard: false }"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-warning" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ "DASHBOARD.alert" | translate }}</h4>
        <button
          type="button"
          class="close"
          (click)="successModal2.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <strong>{{ "DASHBOARD.subscribe_plan" | translate }}</strong>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="successModal2.hide()"
        >
          {{ "DASHBOARD.cancel" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="subscribeRedirect()"
        >
          {{ "DASHBOARD.view_plans" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #policyModal="bs-modal"
  [config]="{ backdrop: 'static', keyboard: false }"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-warning" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ "DASHBOARD.terms_policy" | translate }}</h4>
        <!-- <button type="button" class="close" (click)="policyModal.hide()" aria-label="Close"> <span aria-hidden="true">&times;</span>
                </button> -->
      </div>
      <!-- <div class="modal-body"> <strong>{{'DASHBOARD.subscribe_plan' | translate}}</strong>
              </div> -->
      <div class="modal-body">
        <iframe
          *ngIf="language == 'en'"
          src="https://apiuser.vaistat.com/privacyEn"
          style="zoom: 0.6"
          width="99.6%"
          height="600"
          frameborder="1"
        ></iframe>
        <iframe
          *ngIf="language == 'fr'"
          src="https://apiuser.vaistat.com/privacyFr"
          style="zoom: 0.6"
          width="99.6%"
          height="600"
          frameborder="1"
        ></iframe>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="privacy_policy_action('2')"
        >
          {{ "DASHBOARD.decline" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="privacy_policy_action('1')"
        >
          {{ "DASHBOARD.accept" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #recursiveModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ "JOBS.recursive_job" | translate }}</h4>
        <button
          type="button"
          class="close"
          (click)="recursiveModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <app-job-recursive></app-job-recursive>
      </div>

      <div class="modal-footer" style="margin: auto; margin-right: 0">
        <button
          type="button"
          class="btn btn-secondary client-cancel-btn"
          (click)="recursiveModal.hide()"
        >
          {{ "JOBS.cancel" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #approveOwnershipModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md add-client-modal" role="document">
    <div class="modal-content" style="width: 90%">
      <div class="modal-header">
        <div class="modal-title-absolute">
          <button class="btn btn-default">CONFIRMATION</button>
        </div>
        <button
          type="button"
          class="close"
          (click)="approveOwnershipModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="c-info col-md-12">
          <p style="font-size: 12px">
            {{ "JOBDETAILS.approve_ownership_comment" | translate }}
          </p>
        </div>
      </div>
      <div class="modal-footer" style="margin: auto; margin-right: 0">
        <button
          type="button"
          class="btn btn-secondary client-cancel-btn"
          (click)="approveOwnershipModal.hide()"
        >
          {{ "CREATEJOB.no" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary add-client-btn"
          style="width: 80px"
          (click)="approveJobPharmacy()"
        >
          {{ "CREATEJOB.yes" | translate }}
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>

<div
  bsModal
  #sendToDriverNotificationModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          {{ "JOBS.send_notification_comment" | translate }}
        </h4>
        <button
          type="button"
          class="close"
          (click)="sendToDriverNotificationModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="form-group">
          <input
            type="text"
            class="form-control inputs"
            placeholder="{{ 'JOBS.enter_title' | translate }}"
            [(ngModel)]="notificationTitle"
          />
        </div>
        <div class="form-group">
          <textarea
            class="form-control area-mess"
            rows="5"
            placeholder="{{ 'JOBS.enter_message' | translate }}"
            [(ngModel)]="notificationText"
          ></textarea>
        </div>
      </div>

      <div class="modal-footer" style="margin: auto; margin-right: 0">
        <button
          type="button"
          class="btn btn-secondary client-cancel-btn"
          (click)="sendToDriverNotificationModal.hide()"
        >
          {{ "JOBS.cancel" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary add-client-btn"
          style="width: 80px"
          (click)="sendNotificationToDriver()"
        >
          {{ "JOBS.send" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #sendNoteModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ "JOBS.note" | translate }}</h4>
        <button
          type="button"
          class="close"
          (click)="sendNoteModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Modal body -->
      <app-notes
        [idJobs]="JobId"
        (close)="onClose($event)"
        #appNotes
      ></app-notes>
    </div>
  </div>
</div>

<div
  bsModal
  #histotyModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ "JOBS.history" | translate }}</h4>
        <button
          type="button"
          class="close"
          (click)="onCloseHistorial()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Modal body -->
      <app-list-jobs
        [idJobs]="JobId"
        (close)="onCloseHistorial()"
        #appListJobs
      ></app-list-jobs>
    </div>
  </div>
</div>
<ng-template #jobDetailsHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3">
    <ng-container
      *ngTemplateOutlet="jobDetailsComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #jobDetailsBodyTemplate>
  <app-job-details [job_id]="job_id"></app-job-details>
</ng-template>

<ng-template #editJobHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3">
    <ng-container
      *ngTemplateOutlet="editJobComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #editJobBodyTemplate>
  <app-edit-job [job_id]="job_id" [parentModalRef]="bsModalRef"></app-edit-job>
</ng-template>
