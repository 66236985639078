import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import 'moment-timezone';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';

@Component({
  selector: 'app-brands-update',
  templateUrl: './brands-update.component.html',
  styleUrls: ['./brands-update.component.scss']
})
export class BrandsUpdateComponent implements OnInit {
  editBrandForm: UntypedFormGroup;
  public language = "en";
  file!: File;
  id: '';
  name: any;
  nameFr: any;
  isFeatured2: any;
  brand: [];
  updateImage: boolean;



  constructor(

    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    public routern: Router,
    public routeAct: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {

    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en"
    }


    this.editBrandForm = this.formBuilder.group({

      name: ['', [Validators.required]],
      nameFr: ['', [Validators.required]],
      isFeatured: [1]

    });

    this.id = this.routeAct.snapshot.params["id"];
    var localData = JSON.parse(localStorage.getItem("currentBrand"));


    this.services.getBrandId(this.id).pipe().subscribe((data) => {

      this.spinner.hide();

      this.brand = data;

      //  this.brand = this.brand.find
      localStorage.setItem('currentBrand', JSON.stringify(this.id));
      localStorage.setItem('currentFile', JSON.stringify(this.file));
      this.name = data.name;
      this.nameFr = data.nameFr;
      this.file = data.keyS3;
      this.isFeatured2 = data.isFeatured;
      this.id = data._id;
      console.log(data);



    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });

    //TEMPLATE Brand FORM 
    this.editBrandForm.controls['name'].setValue(localData.name);
    this.editBrandForm.controls['nameFr'].setValue(localData.nameFr);
    // this.editBrandForm.controls['isFeatured'].setValue(this.isFeatured);


  }

  back() {
    this.router.navigate(['/brands']);
  }



  onFileSelect(event) {

    this.file = event.target.files[0];
    console.log(this.file)

  }


  /*
    onFileSelect(event) {
      console.log("event",event);    
      this.file = event.target.files[0];
      console.log(this.file);
    }
  
    */

  get f() { return this.editBrandForm.controls; }


  brandUpdate() {



    const values = this.editBrandForm.value;


    console.log(values);
    console.log(this.id);


    if (!this.f.name.value.trim()) {
      return this.toastr.error('', 'Please enter first name');
    }
    else if (!this.f.nameFr.value.trim()) {
      return this.toastr.error('', 'Please enter last name');
    }
    if (this.file == null) {
      this.updateImage = false;
    } if (this.file != null) {
      this.updateImage = true;
    }



    if (this.f.name.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.name.value);
      if (testing == true) {
        return this.toastr.error('', 'name not valid!');
      }
    }
    if (this.f.nameFr.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.nameFr.value);
      if (testing == true) {
        return this.toastr.error('', 'name Fr not valid!');
      }

      if (this.editBrandForm.invalid) {
        return;
      }
    }

    console.log(this.file);
    this.spinner.show();
    console.log(this.updateImage);

    this.services.updateBrand(this.id, values, this.brand).pipe().subscribe(data => {
      console.log(data)
      this.spinner.hide();
      this.ngOnInit();
      this.routern.navigate(['/brands']);
      return this.toastr.success('successfully update brand');


    }, error => {
      this.spinner.hide()
      return this.toastr.error('error', error.error.message);
    });



  }


}
