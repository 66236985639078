<div class="mt-3">
  <div class="mb-3">
    <app-button [imageSrc]="'./assets/img/new-ui/close.svg'" [buttonText]="'CLIENTS.back_to_list' | translate"
      (buttonClick)="back()"></app-button>
  </div>
  <app-table [theadTemplate]="theadTemplate" [tbodyTemplate]="tbodyTemplate" [noFoundTemplate]="noFoundTemplate"
    [totalItems]="allClientsCount" (onPageChange)="onPageChange($event)" [currentPage]="pageNumber"></app-table>

  <ng-template #theadTemplate>
    <tr>
      <th>{{ "CLIENTS.name" | translate }}</th>
      <th>{{ "CLIENTS.email" | translate }}</th>
      <th>{{ "CLIENTS.phone_no" | translate }}</th>
      <th>{{ "CLIENTS.appartment_no" | translate }}</th>
      <th>{{ "CLIENTS.door_code" | translate }}</th>
      <th>ACTION</th>
    </tr>
  </ng-template>

  <ng-template #tbodyTemplate>
    <tbody>
      <tr *ngFor="
          let clients of allClients
            | paginate
              : {
                  itemsPerPage: 10,
                  currentPage: pageNumber,
                  totalItems: allClientsCount
                }
        ">
        <td>
          <strong>{{ clients?.customer_l_name }} </strong>{{ clients?.customer_f_name }}
        </td>
        <td>{{ clients?.email ? clients.email : "N/A" }}</td>
        <td>{{ clients?.country_code }}{{ clients?.phone_no }}</td>
        <td>{{ clients?.appartment_no ? clients.appartment_no : "N/A" }}</td>
        <td>{{ clients?.door_code ? clients.door_code : "N/A" }}</td>

        <td>
          <ul class="action-icons">
            <li>
              <img src="assets/img/new-ui/edit.svg" (click)="showClientModal(clients)" ngbTooltip="Edit" />
            </li>
            <li *ngIf="clients.recursiveJobs.length">
              <i class="fa fa-repeat" aria-hidden="true" style="color: #ff8b17"
                (click)="openRecursiveModal(clients.recursiveJobs)"></i>
            </li>
            <li>
              <img src="assets/img/new-ui/delete.svg" (click)="openDeleteModel(clients._id)" ngbTooltip="Delete" />
            </li>
          </ul>
          <button *ngIf="clients.recursiveJobs.length" class="stats-recursive text-danger"
            (click)="openRecursiveModal(clients.recursiveJobs)">
            {{ "JOBS.recursive" | translate }}
          </button>
        </td>
      </tr>
    </tbody>
  </ng-template>

  <ng-template #noFoundTemplate>
    <div class="no-jobs-found">
      <p class="no-item text-center text-danger mb-0" *ngIf="!allClients.length">
        {{ "CLIENTS.no_client_found" | translate }}
      </p>
    </div>
  </ng-template>
</div>

<!-------modal---->
<ng-template #upsertClientTemplate>
  <p class="sub-title center-sub-title">
    {{ "CLIENTS.establishment_info" | translate }}
  </p>

  <div class="d-flex flex-column gap-1" [formGroup]="registerForm">
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "CREATEJOB.firstname" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input type="text" formControlName="firstName" class="form-control text-capitalize"
          [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
          placeholder="{{ 'CREATEJOB.firstname' | translate }}" />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "CREATEJOB.lastname" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input type="text" formControlName="lastName" class="form-control text-capitalize"
          [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
          placeholder="{{ 'CREATEJOB.lastname' | translate }}" />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "CREATEJOB.phone_no" | translate }}
      </div>
      <div class="input-group col-7 pl-0">
        <div *ngIf="phoneNumber" class="input-group-prepend">
          <span class="input-group-text">{{ countryCode }}</span>
        </div>
        <input type="text" class="form-control" placeholder="{{ 'CREATEJOB.phone_no' | translate }}"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          (ngModelChange)="mychange($event)" [ngModel]="phoneNumber" [ngModelOptions]="{ standalone: true }"
          maxlength="12" />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "CLIENTS.email" | translate }}
      </div>

      <div class="col-7 pl-0">
        <input type="text" formControlName="email" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="{{ 'CLIENTS.email' | translate }}" />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "JOBDETAILS.appartment_no" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input type="text" formControlName="aptNo" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.aptNo.errors }"
          placeholder="{{ 'JOBDETAILS.appartment_no' | translate }}" />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "JOBDETAILS.door_code" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input type="text" formControlName="doorCode" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.doorCode.errors }"
          placeholder="{{ 'JOBDETAILS.door_code' | translate }}" />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "Client Note" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input type="text" formControlName="customer_note" class="form-control" [ngClass]="{
            'is-invalid': submitted && f.customer_note.errors
          }" placeholder="{{ 'Client Note' | translate }}" />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="font-weight-bold col-5 pr-0">
        {{ "Client Parent" | translate }}
      </div>
      <div class="col-7 pl-0">
        <input type="text" formControlName="customer_parent" class="form-control" [ngClass]="{
            'is-invalid': submitted && f.customer_parent.errors
          }" placeholder="{{ 'Client Parent' | translate }}" />
      </div>
    </div>
  </div>
</ng-template>

<!-- /.modal -->
<ng-template #deleteClientTemplate>
  <div class="text-center">{{ "CLIENTS.delete_Comment" | translate }}</div>
</ng-template>

<div bsModal #recurringModel="bs-modal" class="modal fade" [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-warning" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          {{ "CREATEJOB.recursive_jobs" | translate }}
        </h4>
        <button type="button" class="close" (click)="recurringModel.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body pb-0">
        <ul class="pop-job-list">
          <li *ngFor="let rJob of recursiveJobs">
            <p>
              <span class="text-success"><i class="fa fa-briefcase"></i> </span>&nbsp;{{ rJob.job_id.establishment_name
              }} (Job Code -
              {{ rJob.job_id.booking_code }})
            </p>
            <p *ngIf="rJob.repeatType == 'D' && rJob.dailyType == '1'">
              <span class="text-success"><i class="fa fa-calendar"></i> </span>&nbsp;{{ "CREATEJOB.daily_everyday" |
              translate }}
            </p>
            <p *ngIf="rJob.repeatType == 'D' && rJob.dailyType == '2'">
              <span class="text-success"><i class="fa fa-calendar"></i> </span>&nbsp;{{ "CREATEJOB.daily_mon_sat" |
              translate }}
            </p>
            <p *ngIf="rJob.repeatType == 'D' && rJob.dailyType == '3'">
              <span class="text-success"><i class="fa fa-calendar"></i> </span>&nbsp;{{ "CREATEJOB.daily_mon_fri" |
              translate }}
            </p>

            <p *ngIf="rJob.repeatType == 'W'" style="margin-right: 50px">
              <span class="text-success"><i class="fa fa-calendar"></i> </span>&nbsp;{{ "CREATEJOB.weekely" | translate
              }} <br />
              <ng-container *ngIf="language == 'en'">
                (<span *ngFor="let day of rJob.weekDay">
                  {{ day.item_text }} </span>)
              </ng-container>
              <ng-container *ngIf="language == 'fr'">
                (<span *ngFor="let day of rJob.weekDay">
                  {{ day.item_text_french }} </span>)
              </ng-container>
            </p>
            <p *ngIf="rJob.repeatType == 'M'">
              <span class="text-success"><i class="fa fa-calendar"></i> </span>&nbsp;{{ "CREATEJOB.monthly" | translate
              }}
            </p>
            <p *ngIf="rJob.repeatType == 'M'">
              <span class="text-success"><i class="fa fa-clock-o"></i> </span>&nbsp;{{ modifyDate(rJob.monthlyDate,
              rJob.time) }}
            </p>

            <span class="pop-list-btn btn" (click)="removeModalOpen(rJob._id)">
              {{ "CREATEJOB.remove" | translate }}</span>
          </li>
        </ul>
      </div>
      <div class="modal-footer pt-0">
        <button type="button" class="btn btn-secondary" (click)="recurringModel.hide()">
          {{ "CREATEJOB.cancel" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div bsModal #removeModal="bs-modal" class="modal fade" [config]="{ backdrop: 'static', keyboard: false }" tabindex="-1"
  role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-danger modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" class="close" (click)="removeModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ "CLIENTS.remove_comment" | translate }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="removeModal.hide()">
          {{ "CREATEJOB.no" | translate }}
        </button>

        <button type="button" class="btn btn-secondary" (click)="removeJob()">
          {{ "CREATEJOB.yes" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>