import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { JobDetailsComponent } from './views/job-details/job-details.component';
import { JobsComponent } from './views/jobs/jobs.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { SignContractComponent } from './views/sign-contract/sign-contract.component';

import { ShopModuleGuard } from './guards/shop-module.guard';
import { BrandsCreateComponent } from './views/brands-create/brands-create.component';
import { BrandsUpdateComponent } from './views/brands-update/brands-update.component';
import { BrandsComponent } from './views/brands/brands.component';
import { CalendarComponent } from './views/calendar/calendar.component';
import { CategoryCreateComponent } from './views/category-create/category-create.component';
import { CategoryUpdateComponent } from './views/category-update/category-update.component';
import { CategoryComponent } from './views/category/category.component';
import { ClientsComponent } from './views/clients/clients.component';
import { CreateDriverComponent } from './views/create-driver/create-driver.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { DriverDetailsComponent } from './views/driver-details/driver-details.component';
import { ExtendRecursiveJobsComponent } from './views/extend-recursive-jobs/extend-recursive-jobs.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ImportJobsComponent } from './views/import-jobs/import-jobs.component';
import { InvoiceComponent } from './views/invoice/invoice.component';
import { OrderDetailComponent } from './views/order-detail/order-detail.component';
import { OrdersComponent } from './views/orders/orders.component';
import { PrescriptionDetailsComponent } from './views/prescription-details/prescription-details.component';
import { PrescriptionsComponent } from './views/prescriptions/prescriptions.component';
import { ProductCreateComponent } from './views/product-create/product-create.component';
import { ProductUpdateComponent } from './views/product-update/product-update.component';
import { ProductComponent } from './views/product/product.component';
import { ProductsComponent } from './views/products/products.component';
import { ProfileComponent } from './views/profile/profile.component';
import { ReportsComponent } from './views/reports/reports.component';
import { SubscriptionPaymentComponent } from './views/subscription-payment/subscription-payment.component';
import { SubscriptionPlansComponent } from './views/subscription-plans/subscription-plans.component';
import { TermsPolicyComponent } from './views/terms-policy/terms-policy.component';
import { TotalExpensesListComponent } from './views/total-expenses-list/total-expenses-list.component';
import { TransactionsComponent } from './views/transactions/transactions.component';
import { VerifyPharmacyComponent } from './views/verify-pharmacy/verify-pharmacy.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
    // canActivate: [AuthGuard]
  },

  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },

  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },

  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register'
    }
  },

  {
    path: 'sign-contract',
    component: SignContractComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Sign Contract'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Password'
    }
  },
  {
    path: 'verification',
    component: VerifyPharmacyComponent,
    data: {
      title: 'Verification'
    }
  },
  {
    path: 'home',
    component: P404Component,
    data: {
      title: 'Page 404'
    }

  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      // {
      //   path: 'base',
      //   loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule),
      //   canActivate:[AuthGuard]
      // },

      {
        path: 'jobs',
        component: JobsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'job-details',
        component: JobDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'driver-details',
        component: DriverDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'transactions',
        component: TransactionsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'invoice',
        component: InvoiceComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'subscription-payment',
        component: SubscriptionPaymentComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'subscription-plans',
        component: SubscriptionPlansComponent,
        canActivate: [AuthGuard]
      },

      {
        path: 'clients',
        component: ClientsComponent,
        canActivate: [AuthGuard]
      },

      {
        path: 'create-driver',
        component: CreateDriverComponent,
        canActivate: [AuthGuard]
      },

      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'import-jobs',
        component: ImportJobsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'total-expenses-list',
        component: TotalExpensesListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'terms-policy',
        component: TermsPolicyComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'management-recursive-jobs',
        component: ExtendRecursiveJobsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'brands',
        component: BrandsComponent,
        canActivate: [ShopModuleGuard]
      },
      {
        path: 'brands-create',
        component: BrandsCreateComponent,
        canActivate: [ShopModuleGuard]
      },
      {
        path: 'brands-update/:id',
        component: BrandsUpdateComponent,
        canActivate: [ShopModuleGuard]
      },
      {
        path: 'category',
        component: CategoryComponent,
        canActivate: [ShopModuleGuard]
      },
      {
        path: 'category-create',
        component: CategoryCreateComponent,
        canActivate: [ShopModuleGuard]
      },
      {
        path: 'category-update/:id',
        component: CategoryUpdateComponent,
        canActivate: [ShopModuleGuard]
      },
      {
        path: 'product',
        component: ProductComponent,
        canActivate: [ShopModuleGuard]
      },
      {
        path: 'products/:id',
        component: ProductsComponent,
        canActivate: [ShopModuleGuard]
      },
      {
        path: 'product-create',
        component: ProductCreateComponent,
        canActivate: [ShopModuleGuard]
      },
      {
        path: 'product-update/:id',
        component: ProductUpdateComponent,
        canActivate: [ShopModuleGuard]
      },
      {
        path: 'orders',
        component: OrdersComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'orders-detail/:id',
        component: OrderDetailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'prescriptions',
        component: PrescriptionsComponent,
        canActivate: [ShopModuleGuard]
      },
      {
        path: 'prescriptions/:id',
        component: PrescriptionDetailsComponent,
        canActivate: [ShopModuleGuard]
      },
      {
        path: "calendar",
        component: CalendarComponent,
        canActivate: [AuthGuard]
      }

    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }