// import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  Router
} from '@angular/router';
import moment from 'moment';
import {
  ModalDirective
} from 'ngx-bootstrap/modal';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import {
  NgxSpinnerService
} from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';

export interface typeJob {
  JobType: string;
  CustomerFirstName: string;
  CustomerLastName: string;
  CustomerStreetAddress: string;
  CustomerCity: string;
  CustomerState: string;
  CustomerCountry: string;
  CustomerZipCode: string;
  CustomerPhone: string;
  CustomerEmail: string;
  AppartmentNo: string;
  DoorCode: string;
  Note: string;
  ItemPrice: string;
  DeliveryType: string;
  JobFor: string;
  DriversUsername: string;
  JobDate: string;
  EmployeeInitials: string;
  JobTags: string;
  TimingTags: string;

}
@Component({
  selector: 'app-import-jobs',
  templateUrl: './import-jobs.component.html',
  styleUrls: ['./import-jobs.component.scss']
})
export class ImportJobsComponent implements OnInit {
  header = true;
  fileInputLabel = "";
  user_id: any;
  uploadForm: UntypedFormGroup;
  arrayJobsSuccess: any = [];
  arrayJobsError: any = [];
  submitted: boolean = false;
  formGroup: UntypedFormGroup;
  totalRecordsLoaded = 0;
  constructor(
    // private http: HttpClient,
    private formBuilder: UntypedFormBuilder,
    public toastr: ToastrService,
    private ngxCsvParser: NgxCsvParser,
    private router: Router,
    private services: ServicesService,
    private spinner: NgxSpinnerService,
    public logoutFunction: DefaultLayoutComponent,

  ) { }
  @ViewChild('fileImportInput') fileImportInput: any;
  InputVar: ElementRef;
  @ViewChild('csvAnalyzeModal') public csvAnalyzeModal: ModalDirective;

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      uploadFile: ['']
    });
    var localData = JSON.parse(localStorage.getItem("currentUser"));
    this.user_id = localData?._id;
    this.header = true;
    this.fileInputLabel = "";

    this.formGroup = this.formBuilder.group({
      rowsJobs: this.formBuilder.array([])
    });
  }

  get csvRecords(): UntypedFormArray {
    return this.formGroup.get('rowsJobs') as UntypedFormArray;
  }

  get controls() {
    return this.formGroup.controls;
  }

  // Your applications input change listener for the CSV File
  fileChangeListener(file, fileName): void {
    if (file) {
      this.fileInputLabel = fileName.name;
    }
    // Parse the file you want to select for the operation along with the configuration
    this.ngxCsvParser.parse(file, { header: this.header, delimiter: ',' })
      .pipe().subscribe((result: Array<any>) => {
        result.forEach(item => {
          this.csvRecords.push(this.formBuilder.group({
            JobType: [(item[0] ? this.validateJobType(item[0]) : this.validateJobType(item?.JobType)) || '', Validators.required],
            CustomerFirstName: [(item[1] ? item[1] : item?.CustomerFirstName) || '', Validators.required],
            CustomerLastName: [(item[2] ? item[2] : item?.CustomerLastName) || '', Validators.required],
            CustomerStreetAddress: [(item[3] ? item[3] : item?.CustomerStreetAddress) || '', Validators.required],
            CustomerCity: [(item[4] ? item[4] : item?.CustomerCity) || '', Validators.required],
            CustomerState: [(item[5] ? item[5] : item?.CustomerState) || '', Validators.required],
            CustomerCountry: [(item[6] ? item[6] : item?.CustomerCountry) || '', Validators.required],
            CustomerZipCode: [(item[7] ? item[7] : item?.CustomerZipCode) || '', []],
            CustomerPhone: [(item[8] ? item[8] : item?.CustomerPhone) || '', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
            CustomerEmail: [(item[9] ? item[9] : item?.CustomerEmail) || '', [Validators.required, Validators.email]],
            AppartmentNo: [(item[10] ? item[10] : item?.AppartmentNo) || '', []],
            DoorCode: [(item[11] ? item[11] : item?.DoorCode) || '', []],
            Note: [(item[12] ? item[12] : item?.Note) || '', []],
            ItemPrice: [(item[13] ? item[13] : item?.ItemPrice) || 0, [Validators.required]],
            DeliveryType: [(item[14] ? this.validateDeliveryType(item[14]) : this.validateDeliveryType(item?.DeliveryType)) || '', [Validators.required]],
            JobFor: [(item[15] ? this.validateJobFor(item[15]) : this.validateJobFor(item?.JobFor)) || '', [Validators.required]],
            DriversUsername: [(item[16] ? item[16] : item?.DriversUsername) || '', []],
            JobDate: [(item[17] ? this.formatDate1(item[17]) : this.formatDate1(item?.JobDate)) || '', [Validators.required]],
            EmployeeInitials: [(item[18] ? item[18] : item?.EmployeeInitials) || '', []],
            JobTags: [(item[19] ? item[19] : item?.JobTags) || '', []],
            TimingTags: [(item[20] ? this.validateTimingTags(item[20]) : this.validateTimingTags(item?.TimingTags)) || '', [Validators.required]], //
          }));
        });
        this.totalRecordsLoaded = this.csvRecords.length;
      }, (error: NgxCSVParserError) => {
        this.toastr.error('Something went wrong');
        return false;
      });
  }

  addArrayAFormGroup(array: typeJob[]) {
    array.forEach(item => {
      this.csvRecords.push(this.formBuilder.group({
        JobType: [item?.JobType || '', Validators.required],
        CustomerFirstName: [item?.CustomerFirstName || '', Validators.required],
        CustomerLastName: [item?.CustomerLastName || '', Validators.required],
        CustomerStreetAddress: [item?.CustomerStreetAddress || '', Validators.required],
        CustomerCity: [item?.CustomerCity || '', Validators.required],
        CustomerState: [item?.CustomerState || '', Validators.required],
        CustomerCountry: [item?.CustomerCountry || '', Validators.required],
        CustomerZipCode: [item?.CustomerZipCode || '', []],
        CustomerPhone: [item?.CustomerPhone || '', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
        CustomerEmail: [item?.CustomerEmail || '', []],
        AppartmentNo: [item?.AppartmentNo || '', []],
        DoorCode: [item?.DoorCode || '', []],
        Note: [item?.Note || '', []],
        ItemPrice: [item?.ItemPrice || '', []],
        DeliveryType: [item?.DeliveryType || '', [Validators.required]],
        JobFor: [item?.JobFor || '', [Validators.required]],
        DriversUsername: [item?.DriversUsername || '', []],
        JobDate: [item?.JobDate || '', Validators.required],
        EmployeeInitials: [item?.EmployeeInitials || '', []],
        JobTags: [item?.JobTags || '', []],
        TimingTags: [item?.TimingTags || '', [Validators.required]],
      }));
    });
  }

  onFileSelect(event) {
    this.arrayJobsError = [];
    this.arrayJobsSuccess = [];
    const file = event.target.files[0];
    this.fileInputLabel = file.name;
    this.uploadForm.get('uploadFile').setValue(file);
    this.fileChangeListener(file, file.name);
  }

  onSubmit() {
    if (!this.uploadForm.get('uploadFile').value) {
      this.toastr.error('', 'Choose file');
      return false;
    }

    this.importJobs();
  }

  onDeleteRow(index) {
    this.csvRecords.removeAt(index);
  }

  cancel() {
    this.arrayJobsError = [];
    this.arrayJobsSuccess = [];
    this.csvRecords.clear();
    this.csvRecords.reset();

    this.router.navigateByUrl('/changePassword', {
      skipLocationChange: true
    }).then(() => {
      this.router.navigate(['import-jobs']);
    });
  }

  async importJobs() {
    this.arrayJobsError = [];

    this.submitted = true;
    if (this.formGroup.invalid) {
      this.toastr.error('Please fill all required fields');
      return false;
    }
    if (this.csvRecords.getRawValue().length) {
      this.spinner.show();
      const jobs: typeJob[] = this.csvRecords.getRawValue().map(job => job);

      for (const [index, job] of jobs.entries()) {
        await this.services.importCsvJobsWithArray(this.user_id, [{ ...job, JobDate: this.formatDate2(job.JobDate) }]).toPromise().then(async (data) => {
          // if (data.code == 400) {
          //   this.spinner.hide();
          //   this.toastr.error('', data?.message);
          //   this.logoutFunction.logout();
          //   return;
          // }

          if (data.code == 200) {
            if (data.successJobs && data.successJobs.length) {
              this.toastr.success('Success job: ' + job?.CustomerFirstName + ' ' + job?.CustomerLastName);
              this.arrayJobsSuccess.push(data?.successJobs[0]);
            }
            if (data.errorJobs && data.errorJobs.length) {
              this.toastr.error('Error job: ' + job?.CustomerFirstName + ' ' + job?.CustomerLastName);
              this.arrayJobsError.push({ ...job })
            }
          } else {
            this.spinner.hide();
            this.toastr.error('', data?.message);
            this.arrayJobsError.push({ ...job })
          }
        }).catch((error) => {
          this.arrayJobsError.push({ ...job })
          this.spinner.hide();
          this.toastr.error('Something went wrong');
          return;
        });
      }

      this.spinner.hide();
      this.csvRecords.reset();
      this.csvRecords.clear();
      this.addArrayAFormGroup(this.arrayJobsError);

    } else {
      this.spinner.hide();
      return;
    }
  }

  formatDate1(date: string) {
    // date = 29/02/23
    try {
      const dateArray = date.split('/');
      if (dateArray.length != 3) {
        return null;
      }
      const year = dateArray[2].length == 2 ? (2000 + +dateArray[2]) : dateArray[2];
      const month = dateArray[1].length == 1 ? ('0' + dateArray[1]) : dateArray[1];
      const day = dateArray[0].length == 1 ? ('0' + dateArray[0]) : dateArray[0];

      return moment(year + '-' + month + '-' + day).format('YYYY-MM-DD');
    } catch {
      return null;
    }
  }

  formatDate2(date: string) {
    return moment(date).format('DD/MM/YY');
  }


  validateJobType(item: string) {
    const value = item ? item.toLowerCase() : '';
    const result = ['delivery', 'pickup'].includes(value);
    return result ? value : null;
  }

  validateDeliveryType(item: string) {
    const value = item ? item.toLowerCase() : '';
    const result = ['normal', 'urgent'].includes(value);
    return result ? value : null;
  }

  validateTimingTags(item: string) {
    const value = item ? item.toLowerCase() : '';
    const result = ['morning', 'afternoon', 'evening', 'night'].includes(value);
    return result ? value : null;
  }

  validateJobFor(item: string) {
    const value = item ? item.toLowerCase() : '';
    const result = ['public', 'private', 'dispatch'].includes(value);
    return result ? value : null;
  }

  downloadTemplate() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../../assets/files/modelo_import_panel.csv');
    link.setAttribute('download', 'template.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
