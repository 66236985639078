<div class="b-jobs mb-3">{{ "HOME.terms_and_policy" | translate }}</div>

<iframe
  *ngIf="selectedlanguage == 'en'"
  src="https://apiuser.vaistat.com/privacyEn"
  style="zoom: 0.6"
  width="99.6%"
  height="900"
  frameborder="1"
></iframe>
<iframe
  *ngIf="selectedlanguage == 'fr'"
  src="https://apiuser.vaistat.com/privacyFr"
  style="zoom: 0.6"
  width="99.6%"
  height="900"
  frameborder="1"
></iframe>
