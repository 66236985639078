<ng-template #dynamicContent>
  <div class="d-flex gap-1 col-6">
    <ng-container *ngTemplateOutlet="tagSummeryDetails?.dynamicContentTemplate">
    </ng-container>
    <div>
      <app-button
        [imageSrc]="'./assets/img/new-ui/dowload_as_pdf.svg'"
        [buttonText]="'REPORT.print' | translate"
        (buttonClick)="getPdf()"
      ></app-button>
    </div>
  </div>
</ng-template>
<div class="mt-3">
  <app-custom-calendar-datepickers
    [(startDate)]="startDate"
    [(endDate)]="endDate"
    [(fromDate)]="fromDate"
    [(toDate)]="toDate"
    (getData)="getAllTagsList()"
  ></app-custom-calendar-datepickers>
</div>
<div class="d-flex flex-wrap gap-1 mb-3">
  <div *ngFor="let tag of allTags">
    <div
      (click)="goToTagDetailPage(tag)"
      class="tag"
      [ngClass]="{ active: selectedTag?._id === tag._id }"
    >
      <span
        class="circle-background"
        [ngClass]="{ black: tag?.no_of_times_used > 0 }"
        >{{ tag?.no_of_times_used }}</span
      >
      <span>{{
        selectedlanguage == "en" ? tag?.tag_for : tag?.tag_for_french
      }}</span>
    </div>
  </div>
</div>
<div class="sub-title">
  {{ "REPORT.tag" | translate }} :
  {{
    selectedlanguage == "en"
      ? selectedTag?.tag_for
      : selectedTag?.tag_for_french
  }}
</div>
<app-tag-summery-details
  #tagSummeryDetails
  [tag_type]="selectedTag?.tag_type"
  [startDate]="startDate"
  [endDate]="endDate"
></app-tag-summery-details>
