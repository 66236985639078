import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import "moment-timezone";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DefaultLayoutComponent } from "../../containers/default-layout/default-layout.component";
import { ServicesService } from "../../services.service";

@Component({
  selector: "app-order-detail",
  templateUrl: "./order-detail.component.html",
  styleUrls: ["./order-detail.component.scss"],
})
export class OrderDetailComponent implements OnInit {
  public allOrderDetail = null;
  allOrderDetailCount = 0;
  currentPage: 0;
  submitted = false;
  page = 1;
  public language = "en";
  sort = "0";
  filter = "";
  localData: any;
  customer_id: any;
  pageNumber: any;
  id: "";
  nameProduct: "";
  batch_number: "";
  prescripcion: "";
  status: "";
  price: "";
  quantity: "";
  detail: "";
  createAt: "";
  email: "";
  phone_no: "";
  orderNum: "";
  customer_name: "";
  statusOrder: string = 'pending';
  totalOrder: "";
  location = "";
  origin = "";
  appartment_n: "";
  customer_note: "";
  delivery_note: "";
  door_code: "";
  pickup_note: "";
  idOrder: "";

  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    public routeAct: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.sort = "0";
    this.submitted = false;
    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en";
    }
    this.id = this.routeAct.snapshot.params["id"];

    this.page = 1;
    this.filter = "";

    this.getAllOrderDetail(this.id);
  }

  getAllOrderDetail(id) {
    this.spinner.show();
    this.services
      .orderDetailAll(id)
      .pipe()
      .subscribe(
        (data) => {
          this.spinner.hide();
          this.idOrder = data?.result?._id;
          this.createAt = data?.result?.order_date;
          this.email = data?.result?.user?.email;
          this.phone_no = data?.result?.deliveryAddress?.mobile_no;
          this.orderNum = data?.result?.order_no;
          this.customer_name = data?.result?.user?.full_name;
          this.statusOrder = data?.result?.status;
          this.totalOrder = data?.result?.total;
          this.location = data?.result?.seller?.location;
          this.origin = data?.result?.deliveryAddress?.location;
          this.appartment_n = data?.result?.seller.appartment_no
            ? data?.result?.seller.appartment_no
            : "N/A";
          this.customer_note = data?.result?.deliveryInstructions?.length
            ? data?.result?.deliveryInstructions?.customer_note
            : "N/A";
          this.delivery_note = data?.result?.deliveryInstructions?.length
            ? data?.result?.deliveryInstructions?.delivery_note
            : "N/A";
          this.door_code = data?.result?.deliveryInstructions?.length
            ? data?.result?.deliveryInstructions?.door_code
            : "N/A";
          this.pickup_note = data?.result?.deliveryInstructions?.length
            ? data?.result?.deliveryInstructions?.pickup_note
            : "N/A";

          this.allOrderDetail = data.result;
        },
        (error) => {
          this.spinner.hide();
          return this.toastr.error("error", error.error.message);
        }
      );
  }

  //FUNCTION TO MODIFY DATE TIME
  modifyDateTime(str) {
    if (str) {
      const mydate = str.split("T")[0];
      var time = str.split("T")[1];
      var splTime = time.split(":");
      const convert = mydate.split("-");
      return (
        convert[0] +
        "/" +
        convert[1] +
        "/" +
        convert[2] +
        "  " +
        splTime[0] +
        ":" +
        splTime[1]
      );
    }
  }

  back() {
    this.router.navigate(["/orders"]);
  }

  acceptOrder() {
    this.spinner.show();
    this.services
      .updateSubOrder(this.id, "accepted")
      .pipe()
      .subscribe(
        (data) => {
          if (data.message == "Success") {
            this.toastr.success("sucess", "Order accepted successfully");
            this.getAllOrderDetail(this.id);
            this.spinner.hide();
          } else {
            this.toastr.error("error", data.message);
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
          return this.toastr.error("error", error.error.message);
        }
      );
  }

  rejectOrder() {
    this.spinner.show();
    this.services
      .updateSubOrder(this.id, "rejected")
      .pipe()
      .subscribe(
        (data) => {
          if (data.message == "Success") {
            this.toastr.success("sucess", "Order declined successfully");
            this.getAllOrderDetail(this.id);
            this.spinner.hide();
          } else {
            this.toastr.error("error", data.message);
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
          return this.toastr.error("error", error.error.message);
        }
      );
  }

  createSubOrderjob() {
    this.spinner.show();
    this.services
      .createJobSubOrder(this.id)
      .pipe()
      .subscribe(
        (data) => {
          if (data.message == "Success") {
            this.toastr.success("sucess", "Job created successfully");
            this.getAllOrderDetail(this.id);
            this.spinner.hide();
          } else {
            this.toastr.error("error", data.message);
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
          return this.toastr.error("error", error.error.message);
        }
      );
  }
}
// PR