import {
    Component,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    Router
} from '@angular/router';
import {
    NgbDate
} from '@ng-bootstrap/ng-bootstrap';
import {
    NgxSpinnerService
} from 'ngx-spinner';
import {
    ToastrService
} from 'ngx-toastr';
import {
    DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
    ServicesService
} from '../../services.service';
import { CommonMethods } from '../_helpers/_common-methods';
import { TagSummeryDetailsComponent } from '../tag-summery-details/tag-summery-details.component';
const FileSaver = require('file-saver');

@Component({
    selector: 'app-tags-summary',
    templateUrl: './tags-summary.component.html',
    styleUrls: ['./tags-summary.component.scss']
})
export class TagsSummaryComponent implements OnInit {
    @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
    @ViewChild('tagSummeryDetails') tagSummeryDetailsComponent: TagSummeryDetailsComponent;
    fromDate: NgbDate;
    toDate: NgbDate | null = null;
    userData: any;
    pageNumber = 1;
    allTags = [];
    allTagsCount = 0;
    selectedlanguage = 'en';
    searchEvent = '';
    startDate = '';
    endDate = '';
    srcPdf: any;
    selectedTag: any
    isFirstLoad = true

    constructor(
        public logoutFunction: DefaultLayoutComponent,
        public toastr: ToastrService,
        private services: ServicesService,
        public router: Router,
        private spinner: NgxSpinnerService,
        private commonMethods: CommonMethods
    ) { }


    //ONINIT FUNCTION
    ngOnInit() {
        var language = JSON.parse(localStorage.getItem("language"));

        if (language == 'fr') {
            this.selectedlanguage = 'fr';
        } else {
            this.selectedlanguage = 'en';
        }
    }

    //GET ALL TAGS API FUNCTION
    getAllTagsList() {
        this.spinner.show();
        this.userData = JSON.parse(localStorage.getItem("currentUser"));
        this.services.getPharmacyAllTags(this.userData._id, this.pageNumber, this.searchEvent, this.startDate, this.endDate).pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            console.log(data);
            if (data.code == 200) {
                this.allTags = data.result;
                this.allTagsCount = data.count;
                if (this.allTags.length > 0 && this.isFirstLoad) {
                    this.selectedTag = this.allTags[0];
                    this.isFirstLoad = false;
                }

                this.spinner.hide();
                this.tagSummeryDetailsComponent.pageNumber = 1;
                this.tagSummeryDetailsComponent.getAllJobsListAccTags();
                return;
            } else {
                this.spinner.hide();
                this.allTags = [];
                this.allTagsCount = 0;
                return;
            }
        });
    }


    //FUNCTION FOR GO TO DETAIL PAGE
    goToTagDetailPage(tag) {
        this.tagSummeryDetailsComponent.pageNumber = 1;
        this.selectedTag = tag
    }

    // GET PDF 
    getPdf() {
        if (!this.startDate) {
            this.toastr.error('error', 'Please enter start date');
            return false;
        } else if (!this.endDate) {
            this.toastr.error('error', 'Please enter end date');
            return false;
        }
        this.spinner.show();
        this.services.getAllUsedTagsPdf(this.userData._id, this.startDate, this.endDate, '1').pipe().subscribe(data => {

            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            if (data.code == 200) {
                const bufferDataArray = new Uint8Array(data.pdf.data).buffer;
                let pdfBlob = new Blob([bufferDataArray], { type: 'application/pdf' });

                this.downloadPdf(pdfBlob);
                this.spinner.hide();
                return
            } else {
                this.toastr.error('', data.message);
                this.spinner.hide();
                return;
            }

        })

    }

    downloadPdf(pdfBlob) {
        let date = new Date().toDateString();
        var currentDate = this.commonMethods.formatDate(date);
        var pdfDate = this.commonMethods.modifyPdfDate(currentDate)

        const pdfName = 'Pharmacy_Tag_Summary' + pdfDate + '.pdf';
        FileSaver.saveAs(pdfBlob, pdfName);
    }

}