export interface Contract {
    isSigned: boolean,
    signatureDate: string | null,
    signatureImageUrl: string,
    contractUrl: string,
    selectedPlanId: string | null,
    pharmacyLicence: {
        licenceNumber: string,
        expiryDate: string | null
    },
    pharmacyLegalName: string;
    pharmacyFinancialEmail: string;
    pharmacyOfficeAddress: string;
    pharmacyOwnerName: string;
}

export function initContract(): Contract {
    return {
        isSigned: false,
        signatureDate: null,
        signatureImageUrl: "",
        contractUrl: "",
        selectedPlanId: null,
        pharmacyLicence: {
            licenceNumber: "",
            expiryDate: null
        },
        pharmacyLegalName: "",
        pharmacyFinancialEmail: "",
        pharmacyOfficeAddress: "",
        pharmacyOwnerName: ""
    }
}