import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  HttpClient
} from '@angular/common/http';
import {
  ServicesService
} from '../../services.service';
import {
  ToastrService
} from 'ngx-toastr';
import {
  Router
} from '@angular/router';
import {
  ModalDirective
} from 'ngx-bootstrap/modal';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';

@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.scss']
})
export class DriverDetailsComponent implements OnInit {
  @ViewChild('commentModal') public commentModal: ModalDirective;
  @ViewChild('successModal11') public successModal11: ModalDirective;

  driver_id: any;
  driver_details: any;
  latestComments = [];
  allComments = [];
  selected_image: any;
  constructor(
      public toastr: ToastrService,
      private services: ServicesService,
      private http: HttpClient,
      public router: Router,
      public logoutFunction: DefaultLayoutComponent,
      private spinner: NgxSpinnerService) {}

  ngOnInit() {
      this.driver_id = JSON.parse(localStorage.getItem("driver_id"));
      this.spinner.show();
      this.services.getSingleDriver(this.driver_id).pipe().subscribe(data => {
          if (data.code == 400) {
              this.spinner.hide();
              this.toastr.error('', data.message);
              this.logoutFunction.logout();
              return;
          }
          if (data.code == 200) {
              this.driver_details = data.result;
              this.latestComments = data.latestComments;
              this.allComments = data.driverComments;
              this.spinner.hide();
              return;

          } else {
              this.spinner.hide();
              this.toastr.error('error', data.message);
              return;
          }
      })
  }
  modifyDateTime(str) {
      if (str) {
          const mydate = str.split('T')[0];
          var time = str.split('T')[1];
          var splTime = time.split(':');
          const convert = mydate.split('-');
          return convert[0] + '/' + convert[1] + '/' + convert[2] + '  ' + splTime[0] + ":" + splTime[1];
      }


  }
  imageSelection(image) {
      this.selected_image = image;
      this.successModal11.show();
  }


}