<div class="col-12 d-flex justify-content-between mt-3">
  <div>
    <button
      *ngIf="jobsListComponent?.isSelectedCheck"
      type="button"
      class="btn btn-success btn-sm px-3"
      (click)="sendMassiveNotifications()"
      style="margin-top: 20px"
    >
      <strong class="mx-2">{{
        "JOBS.send_notification_to_all_selected" | translate
      }}</strong>
      <i class="fa fa-send"></i>
    </button>
    <button
      *ngIf="jobsListComponent?.isSelectedCheck"
      type="button"
      class="btn btn-danger btn-sm px-3"
      (click)="cancelJobModal()"
      style="margin-top: 20px"
    >
      <strong class="mx-2">Cancel Jobs </strong>
      <i
        class="fa fa-times text-danger"
        aria-hidden="true"
        style="cursor: pointer"
        ngbTooltip="Cancel job"
      ></i>
    </button>
    <button
      *ngIf="jobsListComponent?.isSentCheck"
      type="button"
      class="btn btn-danger btn-sm px-3"
      (click)="jobsListComponent?.onCleanChecksAndSent()"
      style="margin-top: 20px; margin-left: 10px"
    >
      <strong class="mx-2">Clean checks</strong>
      <i class="fa fa-clean"></i>
    </button>
  </div>
  <div class="d-flex align-items-end"></div>
</div>
<app-jobs-list
  endpoint="searchNewJobs"
  [tableActionTemplate]="tableActionTemplate"
  [showCheckBox]="true"
></app-jobs-list>

<ng-template #tableActionTemplate let-job>
  <li>
    <img
      src="assets/img/new-ui/delete.svg"
      (click)="cancelJobModal(job)"
      ngbTooltip="Cancel job"
    />
  </li>
  <li
    *ngIf="
      job?.ownershipApprovedByPharmacy == false &&
      job?.job_status == 'P' &&
      job?.isAccepted == true &&
      job?.isExpired == false &&
      job?.ride_fare > 0 &&
      job?.jobFor == '1'
    "
  >
    <i
      class="fa fa-check-circle"
      aria-hidden="true"
      style="cursor: pointer; color: #0fc303"
      (click)="openApproveOwnershipModal(job?._id)"
      ngbTooltip="Approve ownership"
    ></i>
  </li>
</ng-template>
<!-- CANCEL JOB CONFIRMATION MODEL START HERE -->
<div
  bsModal
  #successModal1="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-danger" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button
          type="button"
          class="close"
          (click)="successModal1.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row r-pdng">
          <div
            class="custom-control custom-checkbox mb-3"
            *ngIf="isRecursiveJob"
          >
            <input
              type="checkbox"
              [(ngModel)]="is_cancel_job"
              class="custom-control-input"
              id="customCheck"
              name="example1"
            />
            <label class="custom-control-label" for="customCheck"></label>
          </div>
          <label class="mr-2" style="font-weight: 600"
            >{{ "JOBS.cancel_comment" | translate }}
          </label>
        </div>
        <div class="row r-pdng" *ngIf="isRecursiveJob">
          <div class="custom-control custom-checkbox mb-3">
            <input
              type="checkbox"
              [(ngModel)]="cancel_recursive_jobs"
              class="custom-control-input"
              id="customCheck1"
              name="example1"
            />
            <label class="custom-control-label" for="customCheck1"></label>
          </div>
          <label class="mr-2" style="font-weight: 600"
            >{{ "JOBS.cancel_recursive_comment" | translate }}
          </label>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="successModal1.hide()"
        >
          {{ "JOBS.no" | translate }}
        </button>
        <button type="button" class="btn btn-secondary" (click)="cancelJob()">
          {{ "JOBS.yes" | translate }}
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>

<div
  bsModal
  #approveOwnershipModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md add-client-modal" role="document">
    <div class="modal-content" style="width: 90%">
      <div class="modal-header">
        <div class="modal-title-absolute">
          <button class="btn btn-default">CONFIRMATION</button>
        </div>
        <button
          type="button"
          class="close"
          (click)="approveOwnershipModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="c-info col-md-12">
          <p style="font-size: 12px">
            {{ "JOBDETAILS.approve_ownership_comment" | translate }}
          </p>
        </div>
      </div>
      <div class="modal-footer" style="margin: auto; margin-right: 0">
        <button
          type="button"
          class="btn btn-secondary client-cancel-btn"
          (click)="approveOwnershipModal.hide()"
        >
          {{ "CREATEJOB.no" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary add-client-btn"
          style="width: 80px"
          (click)="approveJobPharmacy()"
        >
          {{ "CREATEJOB.yes" | translate }}
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>

<div
  bsModal
  #qrCodeConfirmModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-warning" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button
          type="button"
          class="close"
          (click)="qrCodeConfirmModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row r-pdng">
          <label class="mr-2" style="font-weight: 600"
            >{{ "JOBS.multiple_jobs_qrcode_msg" | translate }}
          </label>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="qrCodeConfirmModal.hide()"
        >
          {{ "JOBS.no" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="generateQrCode()"
        >
          {{ "JOBS.yes" | translate }}
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
