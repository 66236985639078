import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../../services.service';

import 'jspdf-autotable';
import * as moment from 'moment';
import { DefaultLayoutComponent } from '../../containers/default-layout/default-layout.component';
const FileSaver = require('file-saver');

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
  p1: number = 1;
  public transactions = [];
  public transactionsCount: any;
  public sDate: any;
  public eDate: any;
  public pagetype: any;
  startDate: string;
  endDate: string;
  user_id: any;
  srcPdf: any;


  constructor(
    public toastr: ToastrService,
    private services: ServicesService,
    private http: HttpClient,
    public router: Router,
    private spinner: NgxSpinnerService,
    public logoutFunction: DefaultLayoutComponent) { }

  ngOnInit() {
    this.srcPdf = "";
    this.sDate = '';
    this.eDate = '';
    this.spinner.show();
    var localData = JSON.parse(localStorage.getItem("currentUser"));
    this.user_id = localData._id;
    this.services.getRideTransactions(localData._id, this.p1).pipe().subscribe((data) => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        console.log("data:", data);
        this.transactions = data.result;
        this.transactionsCount = data.transactionsCount;
        this.pagetype = data.type;

        this.spinner.hide();

      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    })



  }



  resetPage() {
    this.router.navigateByUrl('/changePassword', { skipLocationChange: true }).then(() => {
      this.router.navigate(['transactions']);
    });
  }


  //search by date function start here
  SearchByDate() {
    if (!this.sDate) {
      this.toastr.error('error', 'Please enter start date');
      return false;
    } else if (!this.eDate) {
      this.toastr.error('error', 'Please enter end date');
      return false;
    }
    this.startDate = this.sDate.month + '/' + this.sDate.day + '/' + this.sDate.year;
    this.endDate = this.eDate.month + '/' + this.eDate.day + '/' + this.eDate.year;
    if (moment(this.startDate) > moment(this.endDate)) {
      this.toastr.error('error', 'Please enter start date less then end date');
      return false;
    }
    this.p1 = 1;
    this.transitionSearchByDate();
  }

  getPdfByDate() {
    if (!this.sDate) {
      this.toastr.error('error', 'Please enter start date');
      return false;
    } else if (!this.eDate) {
      this.toastr.error('error', 'Please enter end date');
      return false;
    }
    this.startDate = this.sDate.month + '/' + this.sDate.day + '/' + this.sDate.year;
    this.endDate = this.eDate.month + '/' + this.eDate.day + '/' + this.eDate.year;
    if (moment(this.startDate) > moment(this.endDate)) {
      this.toastr.error('error', 'Please enter start date less then end date');
      return false;
    }
    this.spinner.show();
    this.services.sendRidetransactionsPdf(this.user_id, this.startDate, this.endDate, '1').pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.srcPdf = data.pdfUrl;
        this.downloadPdf();
        this.spinner.hide();

      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    })
  }
  downloadPdf() {
    let date = new Date().toDateString();
    var currentDate = this.formatDate(date);
    var pdfDate = this.modifyPdfDate(currentDate)
    const pdfUrl = this.srcPdf;
    const pdfName = 'Pharmacy_' + pdfDate + '.pdf';
    FileSaver.saveAs(pdfUrl, pdfName);
  }
  modifyPdfDate(str) {
    const mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    const a = new Date(str.split('T')[0]);
    const month_ = a.getMonth();
    const date_ = a.getDate();
    const year_ = a.getFullYear();
    return (mS[month_] + ' ' + date_ + ',' + year_);
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }




  // transactions pdf
  sendTransactionsPdf() {
    if (!this.sDate) {
      this.toastr.error('error', 'Please enter start date');
      return false;
    } else if (!this.eDate) {
      this.toastr.error('error', 'Please enter end date');
      return false;
    }
    this.startDate = this.sDate.month + '/' + this.sDate.day + '/' + this.sDate.year;
    this.endDate = this.eDate.month + '/' + this.eDate.day + '/' + this.eDate.year;
    if (moment(this.startDate) > moment(this.endDate)) {
      this.toastr.error('error', 'Please enter start date less then end date');
      return false;
    }
    this.spinner.show();
    this.services.sendRidetransactionsPdf(this.user_id, this.startDate, this.endDate, '2').pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.spinner.hide();
        this.toastr.success("Pdf Sent On Your Registered Email");
        return;

      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    })

  }





  //search date pagination
  onPageChangeforDate(p1) {
    this.spinner.show();
    this.p1 = p1;
    this.transitionSearchByDate();
  }

  transitionSearchByDate() {
    var localData = JSON.parse(localStorage.getItem("currentUser"));
    this.spinner.show();
    this.services.ridetransactionsByDate(localData._id, this.p1, this.startDate, this.endDate).pipe().subscribe((data) => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        console.log("data:", data);
        this.transactions = data.result;
        this.pagetype = data.type;
        console.log(this.pagetype, 'ttttttt')
        this.transactionsCount = data.transactionsCount;
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });
  }

  onPageChange(p1) {
    this.spinner.show();
    var localData = JSON.parse(localStorage.getItem("currentUser"));
    this.p1 = p1;
    this.services.getRideTransactions(localData._id, this.p1).pipe().subscribe((data) => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        console.log("data:", data);
        this.transactions = data.result;
        this.transactionsCount = data.transactionsCount;
        this.spinner.hide();

      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });

  }



  modifyDate(str) {
    const mydate = str.split('T')[0]
    const convert = mydate.split('-')
    //return convert[1]+'/'+convert[2]+'/'+convert[0]
    return convert[0] + '/' + convert[1] + '/' + convert[2]
  }

}
