<p class="create-date" *ngIf="!comments.length">N/A</p>
<div id="chat-sala" class="list" *ngIf="comments.length > 0">
  <div class="card-list" *ngFor="let comment of comments">
    <div class="comment-card">
      <!-- <button
        class="card-button"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button> -->
      <div ngbDropdown>
        <button
          id="dropdownBasic1"
          class="card-button"
          type="button"
          aria-controls="dropdown-basic"
          ngbDropdownToggle
        >
          <!-- <i class="fa fa-ellipsis-v" aria-hidden="true"></i> -->
        </button>
        <ul
          id="dropdown-basic"
          ngbDropdownMenu
          class="dropdown-menu"
          role="menu"
          aria-labelledby="button-basic"
        >
          <li role="menuitem">
            <!-- todo -->
            <!-- updatePharmacyModalEmail -->
            <a class="dropdown-item">
              <i
                class="fa fa-trash"
                aria-hidden="true"
                style="font-size: 15px; font-weight: bold"
                (click)="deleteComments(comment._id)"
              ></i>
              DELETE
            </a>
          </li>
        </ul>
      </div>

      <a class="card-title">{{ comment.message }}</a>
      <p></p>
      <div class="fechayhora">
        <span>{{ comment?.createAt?.split("T")[0] }}</span> /
        <span>{{ comment?.createAt?.split("T")[1].split(".")[0] }}</span>
      </div>
      <!-- <button (click)="deleteComments(comment._id)">delete</button> -->
    </div>
    <!-- <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item (click)="deleteComments(comment._id)">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-menu> -->
  </div>
</div>
