<ng-template #dynamicContent>
  <div class="d-flex col-lg-8 flex-wrap flex-md-nowrap gap-1">
    <div class="flex-grow-1">
      <form>
        <input
          type="text"
          name="search"
          placeholder="{{ 'CLIENTS.search_driver' | translate }}"
          class="animated-search"
          (ngModelChange)="searchDrivers($event)"
          [(ngModel)]="searchEvent"
          [ngModelOptions]="{ standalone: true }"
        />
      </form>
    </div>

    <div>
      <app-button
        [imageSrc]="'./assets/img/new-ui/reset.svg'"
        [buttonText]="'JOBS.reset' | translate"
        (buttonClick)="ngOnInit()"
      ></app-button>
    </div>
    <div>
      <app-button
        [imageSrc]="'./assets/img/new-ui/connect_driver.svg'"
        [buttonText]="'CLIENTS.connect_driver' | translate"
        (buttonClick)="openRequestModal()"
      ></app-button>
    </div>
    <div>
      <app-button
        buttonText="{{ 'CLIENTS.add' | translate }} {{
          'HOME.driver' | translate
        }}"
        (buttonClick)="openAddDriverModal()"
      ></app-button>
    </div>
  </div>
</ng-template>
<div class="mt-3">
  <app-table
    [theadTemplate]="theadTemplateDrivers"
    [tbodyTemplate]="tbodyTemplateDrivers"
    [noFoundTemplate]="noFoundTemplateDrivers"
    [totalItems]="drivers_count"
    (onPageChange)="onPageChange($event)"
    [currentPage]="pageNumber"
  ></app-table>
  <ng-template #theadTemplateDrivers>
    <tr>
      <th>{{ "CLIENTS.driver_name" | translate }}</th>
      <th>{{ "CLIENTS.email" | translate }}</th>
      <th>{{ "CLIENTS.username" | translate }}</th>
      <th>{{ "CLIENTS.phone_no" | translate }}</th>

      <th>ACTION</th>
    </tr>
  </ng-template>
  <ng-template #tbodyTemplateDrivers>
    <tbody>
      <tr
        *ngFor="
          let driver of drivers
            | paginate
              : {
                  itemsPerPage: 10,
                  currentPage: pageNumber,
                  totalItems: drivers_count,
                  id: 'first'
                }
        "
      >
        <td>
          <div class="media">
            <div [ngClass]="driver?.profile_img ? 'server-approved-img' : ''">
              <img
                *ngIf="driver?.profile_img"
                [src]="driver?.profile_img"
                alt="John Doe"
                class="mr-3 rounded-circle"
                style="width: 35px"
              />
            </div>
            <img
              *ngIf="!driver?.profile_img"
              src="assets/img/new-ui/avatar_without_image.svg"
              alt="John Doe"
              class="mr-3 rounded-circle"
              style="width: 35px"
            />
            <div class="media-body align-self-center">
              <p class="mb-0">{{ driver?.fullname }}</p>
            </div>
          </div>
        </td>
        <td>{{ driver?.email ? driver?.email : "N/A" }}</td>
        <td>{{ driver?.username ? driver?.username : "N/A" }}</td>
        <td>{{ driver?.country_code }} {{ driver?.mobile_no }}</td>

        <td>
          <ul class="action-icons">
            <li>
              <img
                src="assets/img/new-ui/delete.svg"
                (click)="openRemoveDriverModal(driver._id)"
                ngbTooltip="Remove"
              />
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </ng-template>
  <ng-template #noFoundTemplateDrivers>
    <div class="no-jobs-found">
      <p class="no-item text-center text-danger mb-0" *ngIf="!drivers.length">
        {{ "CLIENTS.no_drivers" | translate }}
      </p>
    </div>
  </ng-template>
</div>

<!-------request driver model start here---->
<ng-template #requestHeaderTemplate>
  <div class="d-flex col-lg-6 ml-auto gap-1">
    <div class="flex-grow-1">
      <form>
        <input
          type="text"
          name="search"
          placeholder="{{ 'CLIENTS.search_driver' | translate }}"
          class="animated-search"
          (ngModelChange)="searchIndividualDrivers($event)"
          [ngModel]="searchEventIndividualDrivers"
          [ngModelOptions]="{ standalone: true }"
        />
      </form>
    </div>
    <app-button
      [imageSrc]="'./assets/img/new-ui/reset.svg'"
      [buttonText]="'JOBS.reset' | translate"
      (buttonClick)="resetIndividualModal()"
    ></app-button>
  </div>
</ng-template>

<ng-template #requestTemplate>
  <ng-template #theadTemplateIndividualDrivers>
    <tr>
      <th>{{ "CLIENTS.driver_name" | translate }}</th>
      <th>{{ "CLIENTS.email" | translate }}</th>
      <th>{{ "CLIENTS.username" | translate }}</th>
      <th>{{ "CLIENTS.secret_code" | translate }}</th>
      <!-- <th>{{ 'CLIENTS.phone_no' | translate }}</th> -->
      <th>ACTION</th>
    </tr>
  </ng-template>
  <ng-template #tbodyTemplateIndividualDrivers>
    <tbody>
      <tr
        *ngFor="
          let driver of individualDrivers
            | paginate
              : {
                  itemsPerPage: 10,
                  currentPage: pageNumberForIndividualDrivers,
                  totalItems: individualDriversCount,
                  id: 'second'
                }
        "
      >
        <td>
          <div class="media">
            <div [ngClass]="driver?.profile_img ? 'server-approved-img' : ''">
              <img
                *ngIf="driver?.profile_img"
                [src]="driver?.profile_img"
                alt="John Doe"
                class="mr-3 rounded-circle"
                style="width: 35px"
              />
            </div>
            <img
              *ngIf="!driver?.profile_img"
              src="assets/img/new-ui/avatar_without_image.svg"
              alt="John Doe"
              class="mr-3 rounded-circle"
              style="width: 35px"
            />
            <div class="media-body align-self-center">
              <p class="mb-0">{{ driver?.fullname }}</p>
            </div>
          </div>
        </td>
        <td>{{ driver?.email }}</td>
        <td>{{ driver?.username }}</td>
        <td>
          <input
            placeholder="{{ 'CLIENTS.enter_code' | translate }}"
            maxlength="6"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').replace(/(\..*)\./g, '$1');"
            class="form-control"
            [(ngModel)]="driver.secretCode"
            [ngModelOptions]="{ standalone: true }"
          />
        </td>
        <!-- <td>{{driver?.country_code}} {{driver?.mobile_no}}</td> -->

        <td>
          <ul class="action-icons">
            <li>
              <img
                src="assets/img/new-ui/add.svg"
                (click)="sendRequestToDriver(driver)"
                [ngbTooltip]="'CLIENTS.connect' | translate"
              />
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </ng-template>
  <ng-template #noFoundTemplateIndividualDrivers>
    <div class="no-jobs-found">
      <p
        class="no-item text-center text-danger mb-0"
        *ngIf="!individualDrivers.length"
      >
        {{ "CLIENTS.no_drivers" | translate }}
      </p>
    </div>
  </ng-template>
  <div class="sub-title left-sub-title">
    {{ "CLIENTS.search_driver" | translate }}
  </div>
  <div class="mb-4">
    <app-table
      [theadTemplate]="theadTemplateIndividualDrivers"
      [tbodyTemplate]="tbodyTemplateIndividualDrivers"
      [noFoundTemplate]="noFoundTemplateIndividualDrivers"
      [totalItems]="individualDriversCount"
      (onPageChange)="onIndividualDriversPageChange($event)"
      [currentPage]="pageNumberForIndividualDrivers"
    ></app-table>
  </div>
</ng-template>

<!-- /.request driver model end here -->

<!-- Remove driver modal start-->
<ng-template #deleteDriverTemplate>
  <div class="text-center">{{ "CLIENTS.remove_comment" | translate }}</div>
</ng-template>

<!-- Remove driver modal end -->

<ng-template #addDriverTemplate>
  <app-create-driver
    [parentModalRef]="bsModalRef"
    (onCreateDriver)="onPageLoad()"
  ></app-create-driver>
</ng-template>
