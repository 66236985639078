import {
    Component,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    Router
} from '@angular/router';
import {
    NgbCalendar,
    NgbDate,
    NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import {
    NgxSpinnerService
} from 'ngx-spinner';
import {
    ToastrService
} from 'ngx-toastr';
import {
    DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
    ServicesService
} from '../../services.service';
import { DateTab } from '../../utils/Enums';
import { CommonMethods } from '../_helpers/_common-methods';
import { GlobalSummaryDetailsComponent } from '../global-summary-details/global-summary-details.component';
const FileSaver = require('file-saver');

@Component({
    selector: 'app-global-summary',
    templateUrl: './global-summary.component.html',
    styleUrls: ['./global-summary.component.scss']
})
export class GlobalSummaryComponent implements OnInit {
    @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
    @ViewChild('globalSummaryDetails') globalSummaryDetailsComponent: GlobalSummaryDetailsComponent;
    fromDate: NgbDate;
    toDate: NgbDate | null = null;
    userData: any;
    findAllCardPaymentsCount = 0;
    findAllCardPaymentsAmount = 0;
    findAllCashPaymentsCount = 0;
    findAllCashPaymentsAmount = 0;
    findAllTpvPaymentsAmount = 0;
    findAllTpvdPaymentsCount = 0;
    findAllChequePaymentsCount = 0;
    findAllChequePaymentsAmount = 0;
    cardRequestAmount = 0;
    cashRequestAmount = 0;
    tpvRequestAmount = 0;
    chequeRequestAmount = 0;
    totalJobs = 0;
    totalCollectedAmount = 0;
    totalCollectedRequestedAmount = 0;
    startDate: string;
    endDate: string;
    cardPaymentId = [];
    cashPaymentId = [];
    tpvPaymentId = [];
    allJobsPaymentIds = [];
    chequePaymentId = [];
    showDetails: boolean = false;
    srcPdf: any;
    dateTab = DateTab;
    range: DateTab = DateTab.Today

    constructor(
        public logoutFunction: DefaultLayoutComponent,
        public toastr: ToastrService,
        private services: ServicesService,
        public router: Router,
        private spinner: NgxSpinnerService,
        public calendar: NgbCalendar,
        public formatter: NgbDateParserFormatter,
        private commonMethods: CommonMethods
    ) {

    }

    ngOnInit() {
        this.userData = JSON.parse(localStorage.getItem("currentUser"));

    }


    //API CALLED HERE
    globalSummaryDetails() {
        this.spinner.show();
        console.log(this.userData._id, this.startDate, this.endDate);
        this.services.globalSummaryDetails(this.userData._id, this.startDate, this.endDate).pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            console.log(data, 'this data');
            if (data.code == 200) {

                this.findAllCardPaymentsAmount = data.cardDetails.cardAmount;
                this.findAllCardPaymentsCount = data.cardDetails.cardCount;
                this.cardRequestAmount = data.cardDetails.cardRequestAmount;
                this.cardPaymentId = data.cardDetails.cardPaymentId;

                this.findAllCashPaymentsAmount = data.cashDetails.cashAmount;
                this.findAllCashPaymentsCount = data.cashDetails.cashCount;
                this.cashRequestAmount = data.cashDetails.cashRequestAmount;
                this.cashPaymentId = data.cashDetails.cashPaymentId;

                this.findAllChequePaymentsAmount = data.chequeDetails.chequeAmount;
                this.findAllChequePaymentsCount = data.chequeDetails.chequeCount;
                this.chequeRequestAmount = data.chequeDetails.chequeRequestAmount;
                this.chequePaymentId = data.chequeDetails.chequePaymentId;

                this.findAllTpvPaymentsAmount = data.TPVDetails.TPV_Amount;
                this.findAllTpvdPaymentsCount = data.TPVDetails.TPV_Count;
                this.tpvRequestAmount = data.TPVDetails.TPV_RequestAmount;
                this.tpvPaymentId = data.TPVDetails.TPV_PaymentId;


                this.totalCollectedAmount = this.findAllCardPaymentsAmount + this.findAllCashPaymentsAmount + this.findAllChequePaymentsAmount + this.findAllTpvPaymentsAmount;

                this.totalCollectedRequestedAmount = this.cashRequestAmount + this.cardRequestAmount + this.chequeRequestAmount + this.tpvRequestAmount;

                this.totalJobs = this.findAllCardPaymentsCount + this.findAllCashPaymentsCount + this.findAllChequePaymentsCount + this.findAllTpvdPaymentsCount;

                this.spinner.hide();
                return
            } else {
                this.spinner.hide();
                return;
            }
        });
    }


    //FUNCTION FOR GO TO DETAIL PAGE
    goToTagDetailPage(payment_type, count) {
        console.log(payment_type, count, 'this ')
        if (!count)
            return
        else
            switch (payment_type) {
                case '1':
                    var allJobsPaymentIds = this.cardPaymentId;
                    break;
                case '2':
                    var allJobsPaymentIds = this.cashPaymentId;
                    break;

                case '3':
                    var allJobsPaymentIds = this.chequePaymentId;
                    break;
                case '5':
                    var allJobsPaymentIds = this.tpvPaymentId;
                    break;

                default:
                    break;
            }
        this.allJobsPaymentIds = allJobsPaymentIds;
        this.showDetails = true

    }

    // GET PDF 
    getPdf() {
        if (!this.startDate) {
            this.toastr.error('error', 'Please enter start date');
            return false;
        } else if (!this.endDate) {
            this.toastr.error('error', 'Please enter end date');
            return false;
        }
        this.spinner.show();
        this.services.getGlobalSummaryPdf(this.userData._id, this.startDate, this.endDate, '1').pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            if (data.code == 200) {

                const bufferDataArray = new Uint8Array(data.pdf.data).buffer;
                let pdfBlob = new Blob([bufferDataArray], { type: 'application/pdf' });

                this.downloadPdf(pdfBlob);
                this.spinner.hide();
                return
            } else {
                this.toastr.error('', data.message);
                this.spinner.hide();
                return;
            }

        })

    }

    downloadPdf(pdfBlob) {
        let date = new Date().toDateString();
        var currentDate = this.commonMethods.formatDate(date);
        var pdfDate = this.commonMethods.modifyPdfDate(currentDate)

        const pdfName = 'Pharmacy_Global_Summary' + pdfDate + '.pdf';
        FileSaver.saveAs(pdfBlob, pdfName);
    }

}