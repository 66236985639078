import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RouteService } from '../../../services/route.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {
  @Input() theadTemplate: any;
  @Input() tbodyTemplate: any;
  @Input() noFoundTemplate: any;
  @Input() totalItems: number;
  @Input() itemsPerPage: number = 10;
  @Output() onPageChange = new EventEmitter<number>();
  @Input() currentPage: number = 1;
  lastPage: number
  currentPageRange: string
  constructor(private routeService: RouteService) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    const { totalItems } = changes

    if (totalItems?.currentValue && totalItems.currentValue !== totalItems.previousValue) {

      this.currentPageRange = this.getCurrentPageRange(this.currentPage);
      this.lastPage = Math.ceil(this.totalItems / this.itemsPerPage);
    }

  }
  handlePageChange(value): void {

    if (value) {
      const page = parseInt(value)
      this.currentPage = page;
      this.currentPageRange = this.getCurrentPageRange(page);
      this.onPageChange.emit(page);
    }

  }
  getCurrentPageRange(currentPage: number): string {

    const startIndex = (currentPage - 1) * this.itemsPerPage + 1;
    const endIndex = Math.min(currentPage * this.itemsPerPage, this.totalItems);
    return `${startIndex}-${endIndex}`;
  }

}