<div class="row justify-content-between scp-breadcrumb mb-4">
  <div class="b-jobs">
    <a>{{ "recursive_jobs" | translate }} </a>
  </div>

  <div class="d-flex col-md-6 gap-1">
    <div class="flex-grow-1">
      <form>
        <input
          type="text"
          placeholder="{{ 'JOBS.search_job' | translate }}"
          class="animated-search"
          (ngModelChange)="onSearch($event)"
          [(ngModel)]="searchEvent"
          [ngModelOptions]="{ standalone: true }"
        />
      </form>
    </div>

    <div>
      <app-button
        [imageSrc]="'./assets/img/new-ui/reset.svg'"
        [buttonText]="'JOBS.reset' | translate"
        (buttonClick)="ngOnInit()"
      ></app-button>
    </div>
  </div>
</div>

<div>
  <div class="mt-3">
    <app-table
      [theadTemplate]="theadTemplateJobs"
      [tbodyTemplate]="tbodyTemplateJobs"
      [noFoundTemplate]="noFoundTemplateJobs"
      [totalItems]="jobs_count"
      (onPageChange)="onPageChange($event)"
    ></app-table>
    <ng-template #theadTemplateJobs>
      <tr>
        <th>{{ "JOBDETAILS.job_type" | translate }}</th>
        <th>{{ "JOBS.client_name" | translate }}</th>
        <th>{{ "JOBS.address" | translate }}</th>
        <th>{{ "JOBS.recursive_type" | translate }}</th>
        <th>{{ "JOBS.end_date" | translate }}</th>
        <th>{{ "JOBS.recurring_start_date" | translate }}</th>
        <th>{{ "JOBS.action" | translate }}</th>
      </tr>
    </ng-template>
    <ng-template #tbodyTemplateJobs>
      <tbody>
        <tr
          *ngFor="
            let recursiveJob of jobs
              | paginate
                : {
                    itemsPerPage: 10,
                    currentPage: pageNumber,
                    totalItems: jobs_count,
                    id: 'first'
                  }
          "
        >
          <td *ngIf="recursiveJob.base_job_values.job_type == '1'">
            <button
              class="stats-active"
              style="background: #d0d7ff; color: #0b2194"
            >
              {{ "JOBS.job_type_delivery" | translate }}
            </button>
          </td>

          <td *ngIf="recursiveJob.base_job_values.job_type == '2'">
            <button
              class="stats-active"
              style="background: #ffe4c8; color: #ff8b17"
            >
              {{ "JOBS.job_type_pickup" | translate }}
            </button>
          </td>

          <td *ngIf="recursiveJob.customer_type == '2'; else newRequestElse">
            <span style="font-weight: 800; text-transform: uppercase">{{
              recursiveJob.base_job_values.customer_l_name
            }}</span>
            {{ recursiveJob.base_job_values.customer_f_name }}
          </td>
          <ng-template #newRequestElse>
            <td>
              {{
                recursiveJob.base_job_values.establishment_name
                  ? recursiveJob.base_job_values.establishment_name
                  : recursiveJob.base_job_values.customer_name
              }}
            </td>
          </ng-template>

          <td *ngIf="recursiveJob.base_job_values.job_type == '1'">
            {{ sortAddress(recursiveJob.base_job_values.drop_off_location) }}
          </td>
          <td *ngIf="recursiveJob.base_job_values.job_type == '2'">
            {{ sortAddress(recursiveJob.base_job_values.pick_up_location) }}
          </td>

          <td>
            <div
              *ngIf="
                recursiveJob.recursive_job_type &&
                recursiveJob.recursive_job_type.recursive_type
              "
            >
              <button
                *ngIf="
                  recursiveJob.recursive_job_type.recursive_type ==
                  recursiveType.DAiLY
                "
                class="stats-active"
                style="background: #48884a; color: #fff"
              >
                <small> {{ "CREATEJOB.daily" | translate }} </small>
              </button>

              <button
                *ngIf="
                  recursiveJob.recursive_job_type.recursive_type ==
                  recursiveType.WEEKLY
                "
                class="stats-active"
                style="background: #48884a; color: #fff"
              >
                <small> {{ "CREATEJOB.weekely" | translate }} </small>
              </button>

              <button
                *ngIf="
                  recursiveJob.recursive_job_type.recursive_type ==
                  recursiveType.MONTHLY
                "
                class="stats-active"
                style="background: #48884a; color: #fff"
              >
                <small> {{ "CREATEJOB.monthly" | translate }} </small>
              </button>

              <button
                *ngIf="
                  recursiveJob.recursive_job_type.recursive_type ==
                  recursiveType.BEWEEKLY
                "
                class="stats-active"
                style="background: #48884a; color: #fff"
              >
                <small> {{ "CREATEJOB.biweekly" | translate }} </small>
              </button>

              <button
                *ngIf="
                  recursiveJob.recursive_job_type.recursive_type ==
                  recursiveType.FOURWEEKLY
                "
                class="stats-active"
                style="background: #48884a; color: #fff"
              >
                <small> {{ "CREATEJOB.every-4-week" | translate }} </small>
              </button>
            </div>
          </td>

          <td>
            {{ modifyDate(recursiveJob.end_date) }}
          </td>
          <td>
            {{
              recursiveJob.start_date
                ? modifyDate(recursiveJob.start_date)
                : "N/A"
            }}
          </td>

          <td style="width: 10%">
            <ul class="action-icons">
              <li>
                <img
                  src="assets/img/new-ui/sync.svg"
                  (click)="
                    openModalConvertJobToV2(
                      convertJobToV2HeaderTemplate,
                      convertJobToV2BodyTemplate,
                      recursiveJob
                    )
                  "
                  ngbTooltip="Convert to v2"
                />
              </li>
              <li>
                <img
                  src="assets/img/new-ui/edit.svg"
                  (click)="
                    editJobModel(
                      recursiveJob._id,
                      editJobBodyTemplate,
                      editJobHeaderTemplate
                    )
                  "
                  ngbTooltip="Edit"
                />
              </li>
              <li>
                <img
                  src="assets/img/new-ui/delete.svg"
                  (click)="cancelJobModal(recursiveJob._id)"
                  ngbTooltip="Cancel job"
                />
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </ng-template>
    <ng-template #noFoundTemplateJobs>
      <div class="no-jobs-found">
        <p class="no-item text-center text-danger mb-0" *ngIf="!jobs.length">
          {{ "JOBS.no_jobs" | translate }}
        </p>
      </div>
    </ng-template>
  </div>
</div>
<ng-template #jobDetailsHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3">
    <ng-container
      *ngTemplateOutlet="jobDetailsComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #jobDetailsBodyTemplate>
  <app-job-details [job_id]="job_id"></app-job-details>
</ng-template>

<ng-template #editJobHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3">
    <ng-container
      *ngTemplateOutlet="editJobComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #editJobBodyTemplate>
  <app-edit-job
    [parentModalRef]="bsModalRef"
    [recursive_id]="job_id"
  ></app-edit-job>
</ng-template>

<ng-template #convertJobToV2HeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3">
    <ng-container
      *ngTemplateOutlet="convertJobToV2ModalComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #convertJobToV2BodyTemplate>
  <app-convert-recursive-job-to-v2-modal
    [item]="jobDetails"
  ></app-convert-recursive-job-to-v2-modal>
</ng-template>
<!-- CANCEL JOB CONFIRMATION MODEL START HERE -->
<div
  bsModal
  #successModal1="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-danger" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button
          type="button"
          class="close"
          (click)="successModal1.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label class="mr-2" style="font-weight: 600"
          >{{ "JOBS.cancel_recursive_comment" | translate }}
        </label>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="successModal1.hide()"
        >
          {{ "JOBS.no" | translate }}
        </button>
        <button type="button" class="btn btn-secondary" (click)="cancelJob()">
          {{ "JOBS.yes" | translate }}
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
