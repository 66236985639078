import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  Router
} from '@angular/router';
import 'moment-timezone';
import { TabsetComponent } from "ngx-bootstrap/tabs";
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';
@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss']
})
export class ProductCreateComponent implements OnInit {
  createProductForm: UntypedFormGroup;
  createProductFormTab3: UntypedFormGroup;
  createProductFormTab2: UntypedFormGroup;
  public language = "en";
  file!: File;
  allBrand: [];
  allCategory: [];
  presentation: [];
  type: [];
  submitted = false;
  product: any;
  productDetails: any[];

  @ViewChild(TabsetComponent)
  tabset: TabsetComponent;



  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    public routern: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {

    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en"
    }



    //ADD NEW RESIDENCE FORM 
    this.createProductForm = this.formBuilder.group({
      brand: ['', Validators.required],
      category: ['', Validators.required],
      name: ['', Validators.required],
      detail: ['', Validators.required],
      prescription: [''],
      active: [1],

    });

    console.log(this.createProductForm);

    this.createProductFormTab2 = this.formBuilder.group({
      name: ['', Validators.required],
      presentationId: ['', Validators.required],
      quantity: [, Validators.required],
      unitTypeId: ['', Validators.required],
      price: [, Validators.required],
      tax: [, Validators.required],
    })



    this.createProductFormTab3 = this.formBuilder.group({
      nameFr: ['', Validators.required],
      detailFr: ['', Validators.required],
    })
    this.productDetails = [];
    this.getBrand();
    this.getCategory();
    this.getPresentation();
    this.getType();
  }

  nextTab(tabId: string) {
    const tab = this.tabset.tabs.find(tab => tab.id === tabId)
    tab.active = true
  }

  getBrand() {

    this.services.brandLis().pipe().subscribe(data => {

      this.spinner.hide();
      this.allBrand = data.entityList;
    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });

  }
  getCategory() {

    this.services.categoryLis().pipe().subscribe(data => {

      this.spinner.hide();
      this.allCategory = data.entityList;
    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });


  }

  fetchNews(event) {
    console.log("")
  }

  getPresentation() {
    this.services.presentationLis().pipe().subscribe(data => {

      this.spinner.hide();
      this.presentation = data.entityList;
    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });
  }
  getPresentationName(id: string): string {
    var ans = '';
    this.presentation.forEach((e) => {
      if (e['_id'] == id) {
        ans = e['name'];
      }
    });
    return ans;
  }
  getTypeName(id: string): string {
    var ans = '';
    this.type.forEach((e) => {
      if (e['_id'] == id) {
        ans = e['name'];
      }
    });
    return ans;
  }
  getType() {
    this.services.typeLis().pipe().subscribe(data => {

      this.spinner.hide();
      this.type = data.entityList;
    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });
  }

  onFileSelect(event) {
    console.log("event", event);
    this.file = event.target.files[0];
  }

  back() {
    this.router.navigate(['/product']);
  }

  get f() { return this.createProductForm.controls; }
  get g() { return this.createProductFormTab2.controls; }
  get d() { return this.createProductFormTab3.controls; }

  addDetail() {



    if (!this.g.name.value.trim()) {
      return this.toastr.error('', 'Please enter first name');
    }
    else if (!this.g.presentationId.value.trim()) {
      return this.toastr.error('', 'Please select Presentation');
    }
    else if (this.g.quantity.value === 0) {
      return this.toastr.error('', 'Please enter Quantity');
    }
    else if (!this.g.unitTypeId.value.trim()) {
      return this.toastr.error('', 'Please select Size');
    }

    else if (this.g.price.value === 0) {
      return this.toastr.error('', 'Please enter Price');
    }

    else if (this.g.tax.value === 0) {
      return this.toastr.error('', 'Please enter Tax');
    }



    if (this.g.name.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.g.name.value);
      if (testing == true) {
        return this.toastr.error('', 'name not valid!');
      }
    }
    if (this.g.presentationId.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.g.presentationId.value);
      if (testing == true) {
        return this.toastr.error('', 'Presentation not valid!');
      }
    }

    if (this.g.quantity.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.g.quantity.value);
      if (testing == true) {
        return this.toastr.error('', 'Quantity not valid!');
      }
    }

    if (this.g.unitTypeId.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.g.unitTypeId.value);
      if (testing == true) {
        return this.toastr.error('', 'Size not valid!');
      }
    }

    if (this.g.price.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.g.price.value);
      if (testing == true) {
        return this.toastr.error('', 'Price not valid!');
      }
    }

    if (this.g.tax.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.g.tax.value);
      if (testing == true) {
        return this.toastr.error('', 'Tax not valid!');
      }
    }

    let detail = this.createProductFormTab2.value;


    //let productDetails: string[] = [];
    //productDetails.push(detail)
    this.productDetails.push(detail);


    this.product = JSON.stringify(this.productDetails)
    //this.createProduct(productDetails);
    return this.toastr.success('detail added successfully');


  }

  createProduct() {

    this.submitted = true;



    if (!this.f.category.value.trim()) {
      return this.toastr.error('', 'Please select Category');
    }
    if (!this.f.brand.value.trim()) {
      return this.toastr.error('', 'Please select Brand');
    }
    if (!this.f.name.value.trim()) {
      return this.toastr.error('', 'Please enter Name');
    }
    if (!this.f.detail.value.trim()) {
      return this.toastr.error('', 'Please enter Detail');
    }

    if (this.f.active.value == 0) {
      return this.toastr.error('', 'Please enter first name');
    }


    if (this.f.category.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.category.value);
      if (testing == true) {
        return this.toastr.error('', 'category not valid!');
      }
    }

    if (this.f.brand.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.brand.value);
      if (testing == true) {
        return this.toastr.error('', 'brand not valid!');
      }
    }
    if (this.f.name.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.name.value);
      if (testing == true) {
        return this.toastr.error('', 'name not valid!');
      }
    }

    if (this.f.detail.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.detail.value);
      if (testing == true) {
        return this.toastr.error('', 'detail not valid!');
      }
    }

    if (this.f.active.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.active.value);
      if (testing == true) {
        return this.toastr.error('', 'active not valid!');
      }
    }



    if (!this.d.nameFr.value.trim()) {
      return this.toastr.error('', 'Please enter first name');
    }
    else if (!this.d.detailFr.value.trim()) {
      return this.toastr.error('', 'Please select Presentation');
    }

    if (this.d.nameFr.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.d.nameFr.value);
      if (testing == true) {
        return this.toastr.error('', 'name not valid!');
      }
    }

    if (this.d.detailFr.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.d.detailFr.value);
      if (testing == true) {
        return this.toastr.error('', 'name not valid!');
      }
    }

    const valuePrincipal = this.createProductForm.value;
    const values = this.createProductFormTab3.value;




    this.spinner.show();

    this.services.createProduct(values, valuePrincipal, this.product, this.file).pipe().subscribe(data => {
      this.submitted = false;
      this.spinner.hide();
      this.ngOnInit();
      this.routern.navigate(['/product']);
      return this.toastr.success('successfully created Product');


    }, error => {
      this.spinner.hide()
      return this.toastr.error('error', error.error.message);
    });


  }





}
