                    <!-- for job recurring -->
                    <div class="c-info">
                        <p>{{ 'CREATEJOB.job_recurring' | translate }}</p>
                        <div class="main-box">
                          <div class="radio-box custom-radio-width">
                            <input id="j-1" type="radio" name="j-01" [value]="1" [(ngModel)]="job_recurring_type"
                              [ngModelOptions]="{standalone: true}" (click)="job_recurring_type = 1" />
                            <label for="j-1" class="c-j-label">{{ 'CREATEJOB.one_time' | translate }}</label>
                            <input id="j-2" type="radio" name="j-01" [value]="2" [(ngModel)]="job_recurring_type"
                              [ngModelOptions]="{standalone: true}" (click)="job_recurring_type = 2" />
                            <label for="j-2" class="c-j-label">{{ 'CREATEJOB.daily' | translate }}</label>
                            <input id="j-3" type="radio" name="j-01" [value]="3" [(ngModel)]="job_recurring_type"
                              [ngModelOptions]="{standalone: true}" (click)="job_recurring_type = 3" />
                            <label for="j-3" class="c-j-label">{{ 'CREATEJOB.weekely' | translate }}</label>
                            <input id="j-4" type="radio" name="j-01" [value]="4" [(ngModel)]="job_recurring_type"
                              [ngModelOptions]="{standalone: true}" (click)="job_recurring_type = 4" />
                            <label for="j-4" class="c-j-label">{{ 'CREATEJOB.monthly' | translate }}</label>
  
                            <input id="j-5" type="radio" name="j-01" [value]="5" [(ngModel)]="job_recurring_type"
                            [ngModelOptions]="{standalone: true}" (click)="job_recurring_type = 5" />
                          <label for="j-5" class="c-j-label">{{ 'CREATEJOB.biweekly' | translate }}</label>
  
                          <input id="j-6" type="radio" name="j-01" [value]="6" [(ngModel)]="job_recurring_type"
                            [ngModelOptions]="{standalone: true}" (click)="job_recurring_type = 6" />
                          <label for="j-6" class="c-j-label">{{ 'CREATEJOB.every-4-week' | translate }}</label>
  
                          </div>
                        </div>
                      </div>
                      <div *ngIf="eLat_long.length && location1" class="form-group w-90 w100-main">
                        <p class="mt-3 mb-0"><strong>{{ 'CREATEJOB.job_date' | translate }}</strong></p>
                        <input class="form-control point" placeholder="{{ 'CREATEJOB.select_job_date' | translate }}"
                          formControlName="jobDate" ngbDatepicker #end="ngbDatepicker" #buttonEl (click)="end.toggle()"
                          [readonly]="true" [ngClass]="{ 'is-invalid': submitted && f.jobDate.errors }"
                          (ngModelChange)="onDateSelected($event)" firstDayOfWeek="7"/>
                        <div *ngIf="submitted && f.jobDate.errors" class="invalid-feedback"></div>
                      </div>
                      <div *ngIf="job_recurring_type == 3" class="col-md-11 pl-0">
                        <form class="form-inline">
                          <div class="form-group w-100">
                            <ng-multiselect-dropdown placeholder="{{ 'CREATEJOB.select_weekdays' | translate }}"
                              [data]="dropdownList" [(ngModel)]="weekDays" [ngModelOptions]="{standalone: true}"
                              [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                              (onSelectAll)="onSelectAll($event)" (onDeSelect)="onDeSelect($event)"
                              (onDeSelectAll)="onDeSelectAll($event)" style="background-color: white;">
                            </ng-multiselect-dropdown>
                          </div>
                        </form>
                      </div>
                      <div *ngIf="job_recurring_type == 2" class="form-group inp-box show-radio custom-radio-btns">
                        <div class="custom-control custom-radio cust-yes-btn res-radio">
                          <input type="radio" class="custom-control-input" id="recursiveJobDelivery1" name="recursiveJobDelivery" checked
                            (click)="dailyJobType = 1" [value]="1" [(ngModel)]="dailyJobType"
                            [ngModelOptions]="{standalone: true}">
                          <label class="custom-control-label pl-2"
                            for="recursiveJobDelivery1">{{ 'CREATEJOB.everyday' | translate }}</label>
                        </div>
                        <div class="custom-control custom-radio cust-no-btn res-radio">
                          <input type="radio" class="custom-control-input" id="recursiveJobDelivery2" name="recursiveJobDelivery"
                            (click)="dailyJobType = 2" [value]="2" [(ngModel)]="dailyJobType"
                            [ngModelOptions]="{standalone: true}">
                          <label class="custom-control-label pl-2"
                            for="recursiveJobDelivery2">{{ 'CREATEJOB.mon_sat' | translate }}</label>
                        </div>
                        <div class="custom-control custom-radio cust-no-btn res-radio">
                          <input type="radio" class="custom-control-input" id="recursiveJobDelivery3" name="recursiveJobDelivery"
                            (click)="dailyJobType = 3" [value]="3" [(ngModel)]="dailyJobType"
                            [ngModelOptions]="{standalone: true}">
                          <label class="custom-control-label pl-2"
                            for="recursiveJobDelivery3">{{ 'CREATEJOB.mon_fri' | translate }}</label>
                        </div>
                      </div>
                      <div *ngIf="job_recurring_type == 2 || job_recurring_type == 3 || job_recurring_type == 4 || job_recurring_type == 5 || job_recurring_type == 6">
                        <ngb-timepicker [(ngModel)]="timeForAutoJob" [ngModelOptions]="{standalone: true}"
                          [readonlyInputs]="readonlyInputs" [minuteStep]="minuteStep"></ngb-timepicker>
                          <!-- Select end date for recursive jobs -->
  
                          <div class="form-group w-90 w100-main">
                            <p class="mt-3 mb-0"><strong>{{ 'CREATEJOB.recurring_end_date' | translate }}</strong></p>
                            <input class="form-control point" placeholder="{{ 'CREATEJOB.recurring_end_date' | translate }}" [(ngModel)]="recursiveEndDateDelivery" [ngModelOptions]="{standalone: true}"
                              ngbDatepicker #end="ngbDatepicker" #buttonEl (click)="end.toggle()"
                              [readonly]="true"
                              (ngModelChange)="onRecursiveEndDate($event)" firstDayOfWeek="7"/>
                          </div>
  
  
                      </div>
                      <!-- end job recurring -->
  
                      <!-- TIMING SELECTION START -->
  
  
                      <div class="c-info">
                        <p>{{ 'CREATEJOB.select_timing' | translate }}</p>
                        <div class="main-box">
                          <div class="radio-box custom-radio-width">
  
                            <input id="before" type="radio" name="timingSelectionDelivery" [value]="1" [(ngModel)]="jobTimingObj.type"
                              [ngModelOptions]="{standalone: true}" (click)="jobTimingObj.type = 1;jobTimingObj.nameEn = 'Before';jobTimingObj.nameFr = 'Avant'" />
                            <label for="before" class="c-j-label">{{ 'CREATEJOB.before' | translate }}</label>
  
                            <input id="after" type="radio" name="timingSelectionDelivery" [value]="2" [(ngModel)]="jobTimingObj.type"
                              [ngModelOptions]="{standalone: true}" (click)="jobTimingObj.type = 2;jobTimingObj.nameEn = 'After';jobTimingObj.nameFr = 'Après'" />
                            <label for="after" class="c-j-label">{{ 'CREATEJOB.after' | translate }}</label>
  
                            <input id="between" type="radio" name="timingSelectionDelivery" [value]="3" [(ngModel)]="jobTimingObj.type"
                              [ngModelOptions]="{standalone: true}" (click)="jobTimingObj.type = 3;jobTimingObj.nameEn = 'Between';jobTimingObj.nameFr = 'Entre'" />
                            <label for="between" class="c-j-label">{{ 'CREATEJOB.between' | translate }}</label>
                             
                            <div *ngIf="jobTimingObj.type" style="display: flex; align-items: center;">
  
                            <ngb-timepicker [(ngModel)]="jobTimingObj.startTime" [ngModelOptions]="{standalone: true}"
                            [readonlyInputs]="readonlyInputs" [minuteStep]="minuteStep"></ngb-timepicker>
  
                            <p *ngIf="jobTimingObj.type == 3" class="mx-3">{{ 'CREATEJOB.to' | translate }}</p>
  
                            <ngb-timepicker  *ngIf="jobTimingObj.type == 3" [(ngModel)]="jobTimingObj.endTime" [ngModelOptions]="{standalone: true}"
                            [readonlyInputs]="readonlyInputs" [minuteStep]="minuteStep"></ngb-timepicker>
  
                            </div>
  
                          
  
                          </div>
                        </div>
                      </div>
  
  
                      <!-- TIMING SELECTION END -->