import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-page-modal-login',
  templateUrl: './page-modal-login.component.html',
  styleUrls: ['./page-modal-login.component.scss']
})
export class PageModalLoginComponent implements OnInit {


  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  updateParameter() {
    // this.modal.close('Ok click');
    console.log(`click`)

  }
  close() {
    // this.modal.close('Ok click');
    console.log(`ss`)
    this.modal.dismiss('Cross click')
  }
}
