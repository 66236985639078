import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    Router
} from '@angular/router';
import {
    NgbCalendar,
    NgbDate,
    NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import {
    NgxSpinnerService
} from 'ngx-spinner';
import {
    ToastrService
} from 'ngx-toastr';
import {
    DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
    ServicesService
} from '../../services.service';
import { RouteService } from '../../services/route.service';
import { CommonMethods } from '../_helpers/_common-methods';
@Component({
    selector: 'app-courier-summary-details',
    templateUrl: './courier-summary-details.component.html',
    styleUrls: ['./courier-summary-details.component.scss']
})
export class CourierSummaryDetailsComponent implements OnInit {

    hoveredDate: NgbDate | null = null;
    fromDate: NgbDate;
    toDate: NgbDate | null = null;
    newdate: any;
    Date: any;
    searchWithDate: any;
    pageNumber = 1;
    searchEvent = '';
    userData: any;
    startDate: any;
    endDate: any;
    driverAllJobs = [];
    driverAllJobsCount = 0;
    @Input() driver_id: any;
    @Input() show: boolean = false
    @Output() showChange = new EventEmitter<boolean>();

    constructor(
        public logoutFunction: DefaultLayoutComponent,
        public toastr: ToastrService,
        private services: ServicesService,
        public router: Router,
        private spinner: NgxSpinnerService,
        public calendar: NgbCalendar,
        public formatter: NgbDateParserFormatter,
        private routeService: RouteService,
        private commonMethods: CommonMethods // used in html file
    ) { }

    ngOnInit() {

        this.userData = JSON.parse(localStorage.getItem("currentUser"));
    }


    //ON JOBS SEARCH
    searchJobs(event) {
        this.pageNumber = 1;
        this.searchEvent = event ? event : '';

        if (event.length == 0) {
            return this.getAllCompletedJobs();
        }
        if (this.searchEvent.length > 2) {
            this.getAllCompletedJobs();
        }
    }

    //API CALLED HERE
    getAllCompletedJobs() {
        this.spinner.show();
        this.services.driversAllJobs(this.userData._id, this.driver_id, this.pageNumber, this.searchEvent, this.startDate, this.endDate).pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            console.log(data);
            if (data.code == 200) {
                this.driverAllJobs = data.result;
                this.driverAllJobsCount = data.count;
                this.spinner.hide();
                return
            } else {
                this.driverAllJobs = [];
                this.driverAllJobsCount = 0;
                this.spinner.hide();
                return;
            }
        });
    }

    onPageChange(event) {
        this.pageNumber = event ? event : this.pageNumber;
        this.getAllCompletedJobs();
    }

    //FUNCTION TO GO BACK PAGE
    BackPage() {

        this.show = false;
        this.showChange.emit(this.show);
    }

}