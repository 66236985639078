<div class="mt-3">
  <div class="row">
    <div class="col-lg-12">
      <div class="scp-breadcrumb">
        <ul class="breadcrumb">
          <li class="b-jobs">
            <p class="sub-title">
              {{ "SUBSCRIPTION.subscription_details" | translate }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row subscriptions-plans" *ngIf="currentSubscription">
    <div class="col-md-3">
      <div class="">
        <div class="" style="padding: 20px 0 0 0">
          <p *ngIf="selectedlanguage == 'fr'; else english">
            {{ subscriptionPlanName?.plan_name_french }}
          </p>
          <ng-template #english>
            <p>{{ subscriptionPlanName?.plan_name }}</p>
          </ng-template>
          <div class="underline"></div>
          <h4>
            ${{ subscriptionAmount }} + {{ "SUBSCRIPTION.taxes" | translate }}
          </h4>
          <div class="bottom-footer">
            <button
              class="btn btn-default"
              style="color: white; background-color: #4dbd74"
            >
              {{ "SUBSCRIPTION.selected_plan" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9 d-flex flex-column justify-content-between">
      <table class="selected-subscrition-information">
        <tr>
          <td>{{ "SUBSCRIPTION.price" | translate }}</td>
          <td>
            <span>${{ subscriptionAmount }}</span>
          </td>
        </tr>
        <tr>
          <td>{{ "SUBSCRIPTION.status" | translate }}</td>
          <td>
            <span style="color: #0eba19">{{ subscriptionPlanStatus }}</span>
          </td>
        </tr>
        <tr>
          <td>{{ "SUBSCRIPTION.expiry_date" | translate }}</td>
          <td>
            <span>{{ expireDate | date }}</span>
          </td>
        </tr>
      </table>
      <div
        *ngIf="currentSubscription.subscribedBy == 'PHARMACY'; else elsePlan"
        class="mt-2"
      >
        <app-button
          [buttonText]="'SUBSCRIPTION.change_plan' | translate"
          (buttonClick)="updateSubscriptionPlan()"
        ></app-button>
      </div>

      <ng-template #elsePlan>
        <h4 style="text-align: left; color: red">
          Subscribed by
          {{ currentSubscription?.dispatchCompanyId.companyName }}
        </h4>
      </ng-template>
    </div>
  </div>
  <p *ngIf="!currentSubscription">
    {{ "SUBSCRIPTION.no_subscription_found" | translate }}
    <app-button
      class="ml-2"
      [buttonText]="'SUBSCRIPTION.subscribe_now' | translate"
      (buttonClick)="navigateToSubscriptionPlans()"
    ></app-button>
  </p>

  <div class="accordians">
    <div class="sub-title">
      {{ "SUBSCRIPTION.terms_and_policy.title" | translate }}
    </div>
    <br />
    <div class="d-flex flex-column gap-1">
      <div>
        {{ "SUBSCRIPTION.terms_and_policy.billing_advance" | translate }}
      </div>
      <div>
        {{ "SUBSCRIPTION.terms_and_policy.invoice_amount" | translate }}
      </div>
      <div>
        {{ "SUBSCRIPTION.terms_and_policy.billing_start_date" | translate }}
      </div>
      <div>
        {{ "SUBSCRIPTION.terms_and_policy.delivery_public_mode" | translate }}
      </div>
      <div>
        {{ "SUBSCRIPTION.terms_and_policy.auto_renewal" | translate }}
      </div>
      <div>
        {{ "SUBSCRIPTION.terms_and_policy.discontinue_service" | translate }}
      </div>
      <div>
        {{ "SUBSCRIPTION.terms_and_policy.prepare_access" | translate }}
      </div>
      <div>
        {{ "SUBSCRIPTION.terms_and_policy.modify_terms" | translate }}
      </div>
      <div>
        {{ "SUBSCRIPTION.terms_and_policy.thank_you" | translate }}
      </div>
    </div>
  </div>
</div>
