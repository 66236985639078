<div class="row justify-content-between scp-breadcrumb mb-4">
  <div class="b-jobs">
    <a>{{ "profile" | translate }} </a>
  </div>

  <ng-container *ngIf="selectedTabIndex === 'manage-card'">
    <ng-container
      *ngTemplateOutlet="manageCardComponent?.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === 'private-drivers'">
    <ng-container
      *ngTemplateOutlet="privateDriversComponent?.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === 'dispatch'">
    <ng-container
      *ngTemplateOutlet="dispatchComponent.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === 'edit-profile'">
    <ng-container
      *ngTemplateOutlet="editProfileComponent.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === 'manage-job-tags'">
    <ng-container
      *ngTemplateOutlet="manageJobTagsComponent.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === 'change-password'">
    <ng-container
      *ngTemplateOutlet="changePasswordComponent?.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
</div>

<div>
  <tabset>
    <tab
      (selectTab)="onTabChange($event)"
      id="manage-card"
      [heading]="'CARD.manage_cards' | translate"
    >
      <app-manage-card></app-manage-card>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      id="subscription"
      [heading]="'SUBSCRIPTION.Subscription' | translate"
    >
      <app-subscription></app-subscription>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      id="private-drivers"
      [heading]="'private_drivers' | translate"
    >
      <app-private-drivers></app-private-drivers>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      id="dispatch"
      [heading]="'dispatch' | translate"
    >
      <app-dispatch></app-dispatch>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      id="edit-profile"
      [heading]="'edit_profile' | translate"
    >
      <app-edit-profile></app-edit-profile>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      id="manage-job-tags"
      [heading]="'TAG.manage_job_tags' | translate"
    >
      <app-manage-job-tags></app-manage-job-tags>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      id="change-password"
      [heading]="'change_password' | translate"
    >
      <app-change-password></app-change-password>
    </tab>
  </tabset>
</div>
