import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-answer-modal',
  templateUrl: './answer-modal.component.html',
  styleUrls: ['./answer-modal.component.scss'],

})
export class AnswerModalComponent implements OnInit {

  @Input() item: any;
  @Input() products: [];
  @Input() modal: NgbModalRef;
  @Output() reloadTable = new EventEmitter();
  resultAttend = 'accept';
  comment = '';
  productDetails = [];
  formGroup: UntypedFormGroup;
  loading: boolean = false;
  private data: any;

  // myControl = new FormControl('');
  // options: string[] = ['One', 'Two', 'Three'];
  // filteredOptions: Observable<string[]>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public toastr: ToastrService,

  ) { }

  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   console.log('data =>', this.options.filter(option => option.toLowerCase().includes(filterValue)))

  //   return this.options.filter(option => option.toLowerCase().includes(filterValue));
  // }


  get controls() {
    // return (this.formGroup.get('details') as FormArray).controls;
    return this.formGroup.controls;
  }

  returnData() {
    this.data = {
      status: this.resultAttend,
      comment: this.comment,
      details: this.productDetails,
    };
    this.modal.close(this.data);
  }
  getName(product) {
    return product.name + ' - ' + product.presentation.name + ' - ' + product.unit_type.name;
  }
  get detailsArray() {
    return this.formGroup.get('details') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      name: [''],
      productDetailId: ['', Validators.required],
      amount: [1, Validators.required],
      details: [''],
    })

    // this.filteredOptions = this.myControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filter(value || '')),
    // );

    // console.log('data', this.filteredOptions);
  }



  removeDetail(product) {
    let index = -1;
    this.productDetails.forEach((v: any, i) => {
      if (v.productId == product.productId && v.name == product.name && v.quantity == product.quantity) {
        index = i;
      }
    });
    this.productDetails.splice(index, 1);
  }
  addDetail() {

    if (!this.controls.productDetailId.value.trim()) {
      return this.toastr.error('', 'Please select Presentation');
    }
    else if (this.controls.amount.value < 1) {
      return this.toastr.error('', 'Please enter Quantity');
    }


    if (this.controls.amount.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.controls.amount.value);
      if (testing == true) {
        return this.toastr.error('', 'Quantity not valid!');
      }
    }

    this.controls.name.setValue(this.controls.productDetailId.value);
    // this.products.forEach((v: any) => {
    //   if (v._id == this.controls.productDetailId.value) {
    //     this.controls.name.setValue(v.name);
    //   }
    // });

    let detail = this.formGroup.value;
    this.formGroup.reset();
    console.log(detail);
    this.productDetails.push(detail);
    console.log(this.productDetails);

    return this.toastr.success('detail added successfully');

  }

  onChangeAnswer(value) {
    this.resultAttend = value;
  }



}
