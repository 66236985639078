import {
   HttpClient,
   HttpHeaders,
   HttpParams
} from '@angular/common/http';
import {
   Injectable
} from '@angular/core';
import {
   BehaviorSubject,
   Observable
} from 'rxjs';
import {
   environment
} from '../environments/environment';
//import { ConsoleReporter } from 'jasmine';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
export interface ApiResponse {
   code: number;
   message: string;
   result: any;
   count?: number;
}


export interface GetNoteForNotDeliveredAndCancelledJobResponse
   extends ApiResponse { }

export interface GetUploadFileResponse
   extends ApiResponse {
   url: string;
}

export interface RegisterResponse {
   code: number;
   message: string;
   result: any;
}
export interface getActiveJobsResponse {
   code: number;
   message: string;
   result: any;
   activeJobs: any;
   activeJobsCount: any;
}
export interface getDistancePriceResponse {
   code: number;
   message: string;
   distance: number;
   normalPrice: number;
   urgent_price: number;
   overallTax: number;
   result: any;
   roundTripFee: number;
}
export interface createJobPharmacyresponse {
   code: number;
   message: string;
   result: any;
}

export interface findDriverResponse {
   code: number;
   message: string;
   driversInRange: any;
}

export interface acceptJobPharmacyResponse {
   code: number;
   message: string;
   result: any;
}

export interface declineJobPharmacyResponse {
   code: number;
   message: string;
   result: any;
}

export interface getSingleJobDetailResponse {
   code: number;
   message: string;
   result: any;
}
export interface getNewJobsResponse {
   code: number;
   message: string;
   newJobsRequest: any;
   newJobsRequestCount: any;
}
export interface getCompletedJobresponse {
   code: number;
   message: string;
   completedJobs: any;
   completedJobsCount: any;
}

export interface changePasswordResponse {
   code: number;
   message: string;
   result: any;
}
export interface editProfileResponse {
   code: number;
   message: string;
   result: any;
}

export type ComentType = {
   "message": string,
   "file_upload": string
}

@Injectable({
   providedIn: 'root'
})
export class ServicesService {

   private headers = new HttpHeaders().set('Content-Type', 'application/json');
   private baseUri = environment.apiUrl;
   private baseShop = environment.apiShop;
   // private baseApp = environment.apiApp;
   private locationUrl = environment.locationUrl;
   mensajeCambio: Subject<any> = new Subject<any>();
   //  private headers = new HttpHeaders().set('Content-Type', 'application/json');

   private httpOptions = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json'
      }),
      withCredentials: false,
   };
   private _selected$ = new BehaviorSubject<any | null>(null);

   constructor(private http: HttpClient) {

   }


   //GET ACTIVE JOB FUNCTION
   getActiveJobs(user_id, pageNumber): Observable<getActiveJobsResponse> {
      return this.http.post<getActiveJobsResponse>(this.baseUri + '/jobs/getActiveJobsPharmacy', {
         user_id,
         pageNumber
      }, this.httpOptions)
   }
   getJobStatics(data): Observable<any> {
      // return this.http.post<any>('http://localhost:8080/api/v5//admin/getJobStatics', data, this.httpOptions);

      return this.http.post<any>(this.baseUri + '/admin/getJobStatics', data, this.httpOptions);
      // return this.http.post<any>(this.baseUri + '/admin/getJobCompleteStatics', data, this.httpOptions);
   }
   getJobCompleteStatics(data): Observable<any> {
      // return this.http.post<any>('http://localhost:8080/api/v5//admin/getJobCompleteStatics', data, {
      //     headers: this.headers
      // });
      return this.http.post<any>(this.baseUri + '/admin/getJobCompleteStatics', data, this.httpOptions);
   }
   filterPharmacyfil(): Observable<any> {
      return this.http.post<any>(this.baseUri + '/admin/getAllPharmacyNew', this.httpOptions).pipe(
         map(data => {
            return data.result
         }),
         map(result => {
            return result.map(x => {
               return { "value": x._id, "label": x.pharmacy_name }
            })
         })
      );
   }

   // GET PRICE ACCORDING TO DISTANCE
   getDistancePrice(sLat_long, eLat_long, jobDate, origin, destination, job_type, orderType): Observable<getDistancePriceResponse> {
      return this.http.post<getDistancePriceResponse>(this.baseUri + '/jobs/newJobDistancePrice', {
         sLat_long,
         eLat_long,
         jobDate,
         origin,
         destination,
         job_type,
         orderType,
      }, this.httpOptions);
   }


   //CREATE A NEW JOB FUNCTION 
   createJobPharmacy(user_id, pick_up_location, drop_off_location, customer_name, customer_phone, note, customer_note, customer_parent, to_be_received_by_type, to_be_received_by_name, delivery_type, customer_type, pickup_lat_long, dropoff_lat_long, taxes, ride_fare, distance, job_from, item_price, job_type, job_date, job_tags, tax, processing_fee, ride_fare_without_tax, customer_email, customer_appartment, customer_door_code, customer_country_code, other_fare, timeZone, night_charge, weekend_charge, repeatType, dailyDate, time, day, monthlyDate, dailyType, customer_f_name, customer_l_name, establishment_name, selected_drivers, stateProcessingFees, adminTax, state_tax_id, job_create_initials, multiple_customers, customerTypeSelection, multiple_customers_info, establishmentTagSelection, recursiveJobEndDate, isPrivate, totalTagsPrices, dispatchCompanyId, selectedDispatchForJob, stateTaxObj, establishment_email, packageObj, jobTimingObj, attachedDocument, establishment_phone, continuous, isRoundTrip, parent_job = ''): Observable<createJobPharmacyresponse> {

      // console.log("entro en el servicio");
      // let respuesta2 = {
      //    user_id,
      //    pick_up_location,
      //    drop_off_location,
      //    customer_name,
      //    customer_phone,
      //    note,
      //    customer_note,
      //    to_be_received_by_type,
      //    to_be_received_by_name,

      //    delivery_type,
      //    customer_type,
      //    pickup_lat_long,
      //    dropoff_lat_long,
      //    taxes,
      //    ride_fare,
      //    distance,
      //    job_from,
      //    item_price,
      //    job_type,
      //    job_date,
      //    job_tags,
      //    tax,
      //    processing_fee,
      //    ride_fare_without_tax,
      //    customer_email,
      //    customer_appartment,
      //    customer_door_code,
      //    customer_country_code,
      //    other_fare,
      //    timeZone,
      //    night_charge,
      //    weekend_charge,
      //    repeatType,
      //    dailyDate,
      //    time,
      //    day,
      //    monthlyDate,
      //    dailyType,
      //    customer_f_name,
      //    customer_l_name,
      //    establishment_name,
      //    selected_drivers,
      //    stateProcessingFees,
      //    adminTax,
      //    state_tax_id,
      //    job_create_initials,
      //    multiple_customers,
      //    customerTypeSelection,
      //    multiple_customers_info,
      //    establishmentTagSelection,
      //    recursiveJobEndDate,
      //    isPrivate,
      //    totalTagsPrices,
      //    dispatchCompanyId,
      //    selectedDispatchForJob,
      //    stateTaxObj,
      //    establishment_email,
      //    packageObj,
      //    jobTimingObj,
      //    attachedDocument,
      //    establishment_phone,
      //    continuous
      // }

      // console.log(respuesta2);

      const respuesta = this.http.post<createJobPharmacyresponse>(this.baseUri + '/jobs/createJob', {
         user_id,
         pick_up_location,
         drop_off_location,
         customer_name,
         customer_phone,
         note,
         customer_note,
         customer_parent,
         to_be_received_by_type,
         to_be_received_by_name,
         delivery_type,
         customer_type,
         pickup_lat_long,
         dropoff_lat_long,
         taxes,
         ride_fare,
         distance,
         job_from,
         item_price,
         job_type,
         job_date,
         job_tags,
         tax,
         processing_fee,
         ride_fare_without_tax,
         customer_email,
         customer_appartment,
         customer_door_code,
         customer_country_code,
         other_fare,
         timeZone,
         night_charge,
         weekend_charge,
         repeatType,
         dailyDate,
         time,
         day,
         monthlyDate,
         dailyType,
         customer_f_name,
         customer_l_name,
         establishment_name,
         selected_drivers,
         stateProcessingFees,
         adminTax,
         state_tax_id,
         job_create_initials,
         multiple_customers,
         customerTypeSelection,
         multiple_customers_info,
         establishmentTagSelection,
         recursiveJobEndDate,
         isPrivate,
         totalTagsPrices,
         dispatchCompanyId,
         selectedDispatchForJob,
         stateTaxObj,
         establishment_email,
         packageObj,
         jobTimingObj,
         attachedDocument,
         establishment_phone,
         continuous,
         isRoundTrip,
         parent_job
      }, this.httpOptions);
      // console.log(respuesta);
      return respuesta
   }
   //FUNCTION TO CREATE PICKUP JOB
   createPickupJob(user_id, pick_up_location, drop_off_location, customer_name, customer_phone, note, delivery_type, customer_type, pickup_lat_long, dropoff_lat_long, taxes, ride_fare, distance, job_from, job_type, job_date, job_tags, tax, processing_fee, ride_fare_without_tax, customer_email, customer_appartment, customer_door_code, customer_note, customer_parent, to_be_received_by_type, to_be_received_by_name, customer_country_code, other_fare, timeZone, night_charge, weekend_charge, repeatType, dailyDate, time, day, monthlyDate, dailyType, customer_f_name, customer_l_name, establishment_name, selected_drivers, stateProcessingFees, adminTax, state_tax_id, job_create_initials, multiple_customers, customerTypeSelection, multiple_customers_info, establishmentTagSelection, recursiveJobEndDate, isPrivate, totalTagsPrices, dispatchCompanyId, selectedDispatchForJob, stateTaxObj, establishment_email, packageObj, jobTimingObj, attachedDocument, establishment_phone, continuous, isRoundTrip, parent_job): Observable<any> {

      return this.http.post<any>(this.baseUri + '/jobs/createPickupJob', {
         user_id,
         pick_up_location,
         drop_off_location,
         customer_name,
         customer_phone,
         note,
         delivery_type,
         customer_type,
         pickup_lat_long,
         dropoff_lat_long,
         taxes,
         ride_fare,
         distance,
         job_from,
         job_type,
         job_date,
         job_tags,
         tax,
         processing_fee,
         ride_fare_without_tax,
         customer_email,
         customer_appartment,
         customer_door_code,
         customer_note,
         customer_parent,
         to_be_received_by_type,
         to_be_received_by_name,
         customer_country_code,
         other_fare,
         timeZone,
         night_charge,
         weekend_charge,
         repeatType,
         dailyDate,
         time,
         day,
         monthlyDate,
         dailyType,
         customer_f_name,
         customer_l_name,
         establishment_name,
         selected_drivers,
         stateProcessingFees,
         adminTax,
         state_tax_id,
         job_create_initials,
         multiple_customers,
         customerTypeSelection,
         multiple_customers_info,
         establishmentTagSelection,
         recursiveJobEndDate,
         isPrivate,
         totalTagsPrices,
         dispatchCompanyId,
         selectedDispatchForJob,
         stateTaxObj,
         establishment_email,
         packageObj,
         jobTimingObj,
         attachedDocument,
         establishment_phone,
         continuous,
         isRoundTrip,
         parent_job
      }, this.httpOptions);
   }
   recreateJob(data): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/recreateJob',
         data
         , this.httpOptions)
   }

   //EDIT A NEW JOB FUNCTION
   edit_job(user_id, job_id, pick_up_location, drop_off_location, customer_name, customer_phone, note, customer_note, customer_parent, delivery_type, customer_type, pickup_lat_long, dropoff_lat_long, taxes, ride_fare, distance, job_from, item_price, job_type, job_date, job_tags, tax, processing_fee, ride_fare_without_tax, customer_email, customer_appartment, customer_door_code, customer_country_code, other_fare, night_charge, weekend_charge, repeatType, dailyDate, time, day, monthlyDate, dailyType, customer_f_name, customer_l_name, establishment_name, selected_drivers, adminTax, stateProcessingFees, state_tax_id, multiple_customers, customerTypeSelection, multiple_customers_info, establishmentTagSelection, isPrivate, edit_all_recursive_jobs, totalTagsPrices, dispatchCompanyId, editType, selectedDispatchForJob, stateTaxObj, establishment_email, packageObj, jobTimingObj, attachedDocument, establishment_phone, isRoundTrip): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/edit_job', {
         user_id,
         job_id,
         pick_up_location,
         drop_off_location,
         customer_name,
         customer_phone,
         note,
         customer_note,
         customer_parent,
         delivery_type,
         customer_type,
         pickup_lat_long,
         dropoff_lat_long,
         taxes,
         ride_fare,
         distance,
         job_from,
         item_price,
         job_type,
         job_date,
         job_tags,
         tax,
         processing_fee,
         ride_fare_without_tax,
         customer_email,
         customer_appartment,
         customer_door_code,
         customer_country_code,
         other_fare,
         night_charge,
         weekend_charge,
         repeatType,
         dailyDate, //este
         time, //este
         day,
         monthlyDate,
         dailyType,
         customer_f_name,
         customer_l_name,
         establishment_name,
         selected_drivers,
         adminTax,
         stateProcessingFees,// este
         state_tax_id,
         multiple_customers,
         customerTypeSelection,
         multiple_customers_info,
         establishmentTagSelection,
         isPrivate,
         edit_all_recursive_jobs,//este
         totalTagsPrices,
         dispatchCompanyId,
         editType, // este
         selectedDispatchForJob,
         stateTaxObj,
         establishment_email,
         packageObj,
         jobTimingObj,
         attachedDocument,
         establishment_phone,
         isRoundTrip
      }, this.httpOptions);
   }


   //FUNCTION TO FIND NEAREST DRIVERS
   findDriver(pharmacy_id, job_id, driver_id, isPrivate, selected_drivers): Observable<findDriverResponse> {
      return this.http.post<findDriverResponse>(this.baseUri + '/jobs/nearbyDriver', {
         pharmacy_id,
         job_id,
         driver_id,
         isPrivate,
         selected_drivers
      }, this.httpOptions);
   }

   //API TO GET PHARMACY JOB REQUESTS
   pharmacyJobs(user_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/getJobsPharmacy', {
         user_id
      }, this.httpOptions);
   }

   //FUNCTION TO ACCEPT JOB BY PHARMACY
   acceptJobPharmacy(job_id, driver_id): Observable<acceptJobPharmacyResponse> {
      return this.http.post<acceptJobPharmacyResponse>(this.baseUri + '/jobs/acceptJobPharmacy', {
         job_id,
         driver_id
      }, this.httpOptions);
   }

   // DECLINE JOB BY PHARMACY
   declineJobPharmacy(job_id, driver_id): Observable<declineJobPharmacyResponse> {
      return this.http.post<declineJobPharmacyResponse>(this.baseUri + '/jobs/declineJobPharmacy', {
         job_id,
         driver_id
      }, this.httpOptions);
   }

   //GET SINGLE JOB DETAILS
   getSingleJobDetails(job_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/getSingleJob', {
         job_id
      }, this.httpOptions);
   }

   //GET NEW JOB API
   getNewJobs(user_id, pageNumber): Observable<getNewJobsResponse> {
      return this.http.post<getNewJobsResponse>(this.baseUri + '/jobs/getNewRequestedJobs', {
         user_id,
         pageNumber
      }, this.httpOptions);
   }

   //GET COMPLETED JOB API
   getCompletedJobs(user_id, pageNumber): Observable<getCompletedJobresponse> {
      return this.http.post<getCompletedJobresponse>(this.baseUri + '/jobs/getCompletedJobsPharmacy', {
         user_id,
         pageNumber
      }, this.httpOptions);
   }


   // FUNCTION TO GET NOT DELIVERED JOBS
   getNotDeliveredJobs(user_id, pageNumber): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/getNotDeliveredJobPharmacy', {
         user_id,
         pageNumber
      }, this.httpOptions);
   }


   //FUNCTION TO CHANGE PASSWORD PHARMACY
   changePassword(user_id, oldPassword, newPassword): Observable<changePasswordResponse> {
      return this.http.post<changePasswordResponse>(this.baseUri + '/users/changePasswordPharmacy', {
         user_id,
         oldPassword,
         newPassword
      }, this.httpOptions);
   }

   //FUNCTION TO EDIT USER PROFILE
   editProfile(user_id, pharmacy_name, location, lat_long, mobile_no, country_shortname, country_longname, state_shortname, state_longname, city_shortname, city_longname, postcode, pharmacy_landline_num, fax_country_code, fax_number, apartment_suite, suite_number): Observable<editProfileResponse> {
      return this.http.post<editProfileResponse>(this.baseUri + '/users/pharmacyEditProfile', {
         user_id,
         pharmacy_name,
         location,
         lat_long,
         mobile_no,
         country: {
            "short_name": country_shortname,
            "long_name": country_longname
         },
         state: {
            "short_name": state_shortname,
            "long_name": state_longname
         },
         city: {
            "short_name": city_shortname,
            "long_name": city_longname
         },
         postcode,
         pharmacy_landline_num,
         fax_country_code,
         fax_number,
         apartment_suite,
         suite_number
      }, this.httpOptions);
   }

   //GET USER CARDS
   getUserCards(user_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/userCards', {
         user_id
      }, this.httpOptions);
   }

   // FUNCTION TO ADD NEW CARDS
   addCard(user_id, card_no, cvc, expiryMonth, expiryYear, card_holder, isPrimary): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/addCardPharmacy', {
         user_id,
         card_no,
         cvc,
         expiryMonth,
         expiryYear,
         card_holder,
         isPrimary
      }, this.httpOptions)
   }

   //FUNCTION TO SELECT PRIMARY CARDS
   selectCard(user_id, card_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/selectCard', {
         user_id,
         card_id
      }, this.httpOptions)
   }


   //FUNCTION TO DELETE EXIST CARDS
   deleteCard(user_id, card_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/deleteCardPharmacy', {
         user_id,
         card_id
      }, this.httpOptions);
   }





   // FUNCTION TO UPLOAD PHARMACY PROFILE IMAGE
   imageUpload(user_id, profile_img): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/pharmacyEditProfile', {
         user_id,
         profile_img
      }, this.httpOptions);
   }

   // FUNCTION TO GET ALL DRIVERS IN RANGE
   getAllDrivers(user_id, job_date, customer_type): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/getAllDrivers', {
         user_id,
         job_date,
         customer_type
      }, this.httpOptions);
   }

   //FUNCTION TO SEARCH DRIVERS
   searchDriver(driver_name, user_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/searchDrivers', {
         driver_name,
         user_id
      }, this.httpOptions);
   }


   // SEARCH ALL DRIVERS
   searchAllDrivers(user_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/getAllNearbyDriversPharmacy', {
         user_id
      }, this.httpOptions);
   }

   //FUNCTION TO SEARCH CUSTOMERS TYPE
   searchCustomer(user_id, customer_name, establishment_id, customer_type): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/searchPharmacyCustomers', {
         user_id,
         customer_name,
         establishment_id,
         customer_type
      }, this.httpOptions);
   }


   //FUNCTION TO SEARCH ESTABLISHMENT
   searchEstablishment(user_id, establishment_name, customer_type, customerTypeSelection): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/searchPharmacyEstablishment', {
         user_id,
         establishment_name,
         customer_type,
         customerTypeSelection
      }, this.httpOptions);
   }

   //FUNCTION TO GET INVOICES
   getInvoiceJobs(user_id, pageNumber): Observable<any> {
      return this.http.post<any>(this.baseUri + '/transactions/get_job_for_invoices', {
         user_id,
         pageNumber
      }, this.httpOptions);
   }

   // GET TRANSITIONS OR INVOICES
   getInvoiceTransactions(user_id, pageNumber, type): Observable<any> {
      return this.http.post<any>(this.baseUri + '/transactions/getTransactions', {
         user_id,
         pageNumber,
         type
      }, this.httpOptions);
   }

   // CANCEL JOB BY PHARMACY
   cancel_job_pharmacy(user_id, job_id, is_cancel_job, cancel_recursive_jobs): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/cancel_job_pharamcy', {
         user_id,
         job_id,
         is_cancel_job,
         cancel_recursive_jobs
      }, this.httpOptions);
   }

   cancel_recursive_management(recursive_management_id): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUri + '/jobs/cancel_recursive_management', {
         recursive_management_id
      }, this.httpOptions);
   }

   // EDIT JOB AMOUNT
   edit_delivery_amount(amount): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/edit_rideamount', {
         amount
      }, this.httpOptions);
   }

   // GET RIDER TRANSITIONS 
   getRideTransactions(user_id, pageNumber): Observable<any> {
      return this.http.post<any>(this.baseUri + '/transactions/getRideTransactionsPharmacy', {
         user_id,
         pageNumber
      }, this.httpOptions);
   }

   //GET PHARMACY NOTIFICATIONS
   getNotifications(user_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/getPharamcyNotifications', {
         user_id
      }, this.httpOptions);
   }

   //GET PHARMACY NOTIFICATIONS
   getJobNotifications(job_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/getJobNotifications', {
         job_id
      }, this.httpOptions);
   }

   // REQUEST FOR PAYMENT TO ADMIN
   request_for_payment(user_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/request_for_payment', {
         user_id
      }, this.httpOptions);
   }


   //INVOICE SEARCH BY JOB DATE
   invoice_search_byDate(user_id, startDate, endDate, pageNumber): Observable<any> {
      return this.http.post<any>(this.baseUri + '/transactions/pharmacyInvoiceByDate', {
         user_id,
         startDate,
         endDate,
         pageNumber
      }, this.httpOptions);
   }

   //GET DRIVERS ON MAP
   getDriversMaps(user_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/getNerbyDrivers', {
         user_id
      }, this.httpOptions);
   }

   //RIDE PAYMENT BY PHARMACY
   ride_payment(user_id, job_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/ride_payment_by_pharmacy', {
         user_id,
         job_id
      }, this.httpOptions);
   }

   // GET ALL & SEARCH COMPLETED JOBS
   get_all_jobs_for_Calendar_date(user_id, fromDate, toDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/searchCompletedjobs', {
         user_id,
         fromDate,
         toDate
      }, this.httpOptions);
   }

   // GET ALL & SEARCH JOBS
   search_jobs(user_id, keyword, pageNumber, searchDate, endpoint): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/' + endpoint, {
         user_id,
         keyword,
         pageNumber,
         searchDate
      }, this.httpOptions);
   }

   // GET ALL & SEARCH COMPLETED JOBS
   search_completed_jobs(user_id, keyword, pageNumber, searchDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/searchCompletedjobs', {
         user_id,
         keyword,
         pageNumber,
         searchDate
      }, this.httpOptions);
   }

   //SEARCH FOR NEW JOBS AND ALL JOBS
   search_new_jobs(user_id, keyword, pageNumber, searchDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/searchNewJobs', {
         user_id,
         keyword,
         pageNumber,
         searchDate
      }, this.httpOptions);
   }

   search_parent_jobs(customer_id, location): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/searchParentJobs', {
         customer_id,
         location
      }, this.httpOptions);
   }

   //SEARCH ACTIVE JOBS AND ALL ACTIVE JOBS
   search_active_jobs(user_id, keyword, pageNumber, searchDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/searchActivejobs', {
         user_id,
         keyword,
         pageNumber,
         searchDate
      }, this.httpOptions);
   }

   //SEARCH NOT DELIVERED JOBS AND ALL ND JOBS
   search_notDelivered_jobs(user_id, keyword, pageNumber, searchDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/searchNotDeliveredjobs', {
         user_id,
         keyword,
         pageNumber,
         searchDate
      }, this.httpOptions);
   }

   // READ ALL NOTIFICATIONS
   readAllNotification(user_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/readAllNotificationsPharmacy', {
         user_id
      }, this.httpOptions);
   }

   //SEARCH RIDE TRANSITIONS
   ridetransactionsByDate(user_id, pageNumber, startDate, endDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/transactions/pharmacyRidetransactionSearch', {
         user_id,
         pageNumber,
         startDate,
         endDate
      }, this.httpOptions);
   }


   // RESCHEDULE EXPIRE JOB PHARMACY
   reschedule_expire_job(user_id, job_id, type): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/reschedule_expire_job', {
         user_id,
         job_id,
         type
      }, this.httpOptions);
   }

   // FUNCTION TO CHANGE LANGUAGE
   changeLanguage(user_id, language): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/changeLanguage', {
         user_id,
         language
      }, this.httpOptions);
   }


   // SEND INVOICE PDF TO PHARMACY
   sendPdfPharmacy(user_id, startDate, endDate, sentType): Observable<any> {
      return this.http.get<any>(this.baseUri + '/admin/generatePdfPharmacyInvoices', {
         params: {
            user_id,
            startDate,
            endDate,
            sentType
         }
      });
   }
   getNoteForNotDeliveredAndCancelledJob(data): Observable<any> {
      return this.http
         .get<GetNoteForNotDeliveredAndCancelledJobResponse>(
            `${this.baseUri}/jobs/getNoteForNotDeliveredAndCancelledJob`,
            {
               params: data,
            }
         )
   }

   // SEND RIDE TRANSITION INVOICE PDF TO PHARMACY
   sendRidetransactionsPdf(user_id, startDate, endDate, sentType): Observable<any> {
      return this.http.get<any>(this.baseUri + '/admin/rideChargesPdf', {
         params: {
            user_id,
            startDate,
            endDate,
            sentType
         }
      });

   }


   //GET CUSTOMER LIST 
   getClientsList(user_id, pageNumber): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/getAllClientsPharmacy', {
         user_id,
         pageNumber
      }, this.httpOptions);
   }


   //SEARCH GET CLIENT LIST
   searchClients(user_id, keyword, pageNumber): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/searchPharmacyCustomersPagination', {
         user_id,
         keyword,
         pageNumber
      }, this.httpOptions);
   }

   // ADD CLIENT BY PHARMACY
   addClientPharamcy(user_id, phone_no, country_code, location, lat_long, email, appartment_no, door_code, customer_note,
      customer_parent, customer_f_name, customer_l_name, customer_type, location_alias_en: string, location_alias_fr: string, city: string, zipcode: string): Observable<any> {


      return this.http.post<any>(this.baseUri + '/users/addClientsPharmacy', {
         user_id,
         phone_no,
         country_code,
         location,
         location_alias_en,
         location_alias_fr,
         lat_long,
         email,
         appartment_no,
         door_code,
         customer_note,
         customer_parent,
         customer_f_name,
         customer_l_name,
         customer_type,
         city,
         zipcode
      }, this.httpOptions);


   }

   //GET ALL SUBSCRIPTION PLANS
   getAllSubscriptionPlans(user_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/subscription/getAllPlans', {
         user_id
      }, this.httpOptions);
   }

   // GET CURRENT SUBSCRIPTION PLANS
   getCurrentSubscription(user_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/subscription/getCurrentSubscriptionPlan', {
         user_id
      }, this.httpOptions);
   }

   // GET SELECTED PLANS DATA
   getSelectedPlan(plan_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/subscription/getSelectedPlan', {
         plan_id
      }, this.httpOptions);
   }


   // CREATE A SUBSCRIPTION
   createSubscription(user_id, plan_id, stripe_customerId): Observable<any> {
      return this.http.post<any>(this.baseUri + '/subscription/subscription_payments', {
         user_id,
         plan_id,
         stripe_customerId
      }, this.httpOptions);
   }

   //CANCEL A SUBSCRIPTION
   cancelSubscription(user_id, subscription_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/subscription/subscription_cancel', {
         user_id,
         subscription_id
      }, this.httpOptions);
   }

   // UPDATE A SUBSCRIPTION
   updateSubscription(user_id, subscription_id, plan_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/subscription/subscription_update', {
         user_id,
         subscription_id,
         plan_id
      }, this.httpOptions);
   }

   //GET DRIVER PROFILE
   getSingleDriver(driver_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/getSingleDriverPharamcy', {
         driver_id
      }, this.httpOptions);
   }


   // EDIT A CLIENT 
   editClient(user_id, customer_id, email, country_code, phone_no, appartment_no, door_code, customer_note, customer_parent, customer_f_name, customer_l_name, customer_type, location, lat_long): Observable<any> {

      return this.http.post<any>(this.baseUri + '/users/editClient', {
         user_id,
         customer_id,
         email,
         country_code,
         phone_no,
         appartment_no,
         door_code,
         customer_note,
         customer_parent,
         customer_f_name,
         customer_l_name,
         customer_type,
         location,
         lat_long
      }, this.httpOptions);


   }

   //DELETE A CLIENT
   deleteClient(user_id, customer_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/deleteClient', {
         user_id,
         customer_id
      }, this.httpOptions);
   }

   //GET JOB TAGS ACC TO TIMING
   getTimingJobTags(fullTime, user_id, customer_type): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/getTimeJobTags', {
         fullTime,
         user_id,
         customer_type
      }, this.httpOptions);
   }

   // REMOVE RECURSIVE JOBS
   removeRecursiveJob(repeatJob_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/removeRepeatJob', {
         repeatJob_id
      }, this.httpOptions);
   }

   //VERIFY EMAIL OTP AND PHONE OTP
   verifyEmailPhoneOtp(user_id, otp_mobile, otp_email): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/verifyMobilePharmacy', {
         user_id,
         otp_mobile,
         otp_email
      }, this.httpOptions);
   }
   // RESEND PHONE OTP
   resendPhoneOtp(user_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/resendMobileOtp', {
         user_id
      }, this.httpOptions);
   }
   // RESEND EMAIL OTP
   resendEmailOtp(user_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/resendEmailOtp', {
         user_id
      }, this.httpOptions);
   }

   // SEND OTP TO EDIT MOBILE NOT ON EDIT PROFILE SCREEN
   sendOtpEditProfile(user_id, phone_no): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/sendOtpEditProfile', {
         user_id,
         phone_no
      }, this.httpOptions);
   }


   // VERIFY EDIT MOBILE NUMBER
   verifyPhoneNumberOnly(user_id, otp, phone_no): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/verifyMobileOnlyPharmacy', {
         user_id,
         otp,
         phone_no
      }, this.httpOptions);
   }

   // GET RECURSIVE JOBS WITH PAGE NUMBER
   getRecursiveJobs(user_id, pageNumber): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/getRecursiveJobPharmacy', {
         user_id,
         pageNumber
      }, this.httpOptions);
   }

   // SEARCH RECURSIVE JOBS
   search_recursive_job(user_id, keyword, pageNumber, searchDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/searchRecursiveJobs', {
         user_id,
         keyword,
         pageNumber,
         searchDate
      }, this.httpOptions);
   }

   fetchJobsRecursiveV2(user_id: string, pageNumber: number, keyword: string): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/getRecursiveJobList', {
         user_id,
         pageNumber,
         keyword: keyword
      }, this.httpOptions);
   }



   // SEARCH CHARGE TO ACCOUNT
   search_charge_to_account(user_id, pageNumber, keyword, searchDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/searchChargeToAccount', {
         user_id,
         pageNumber,
         keyword,
         searchDate
      }, this.httpOptions);

   }


   // EDIT EMAIL THEN VERIFICATION
   editEmailVerification(user_id, email): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/editEmail', {
         user_id,
         email
      }, this.httpOptions);

   }
   // EDIT PHONE NO VERIFICATION
   editPhoneVerifivation(user_id, mobile_no, country_code, country_flag_name): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/editPhone', {
         user_id,
         mobile_no,
         country_code,
         country_flag_name
      }, this.httpOptions);

   }

   // COLLECT CASH FROM DRIVER
   updatePharmacyCollectedAmount(job_id, collected_amount, collected_amount_note, collected_amount_initial, mark_cash_collected): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/collectCashFromDriver', {
         job_id,
         collected_amount,
         collected_amount_note,
         collected_amount_initial,
         mark_cash_collected
      }, this.httpOptions);

   }

   // COLLECT CHEQUE FROM DRIVER
   updatePharmacyChequeAmount(job_id, cheque_collected_from_driver, collected_amount_note, collected_amount_initial): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/collectChequeFromDriver', {
         job_id,
         cheque_collected_from_driver,
         collected_amount_note,
         collected_amount_initial
      }, this.httpOptions);
   }

   // GET ALL CLIENTS API START HERE
   getAllClients(user_id, pageNumber, customer_type, keyword, sortBy): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/getAllClientsPharmacy', {
         user_id,
         pageNumber,
         customer_type,
         keyword,
         sortBy
      }, this.httpOptions);
   }

   // ADD NEW RESIDENCE/MEDICAL/OTHER API START HERE
   addNewResidence(user_id, establishment_name, customer_note, customer_parent, customer_type, location, lat_long, establishment_email): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/addEstablishment', {
         user_id,
         establishment_name,
         customer_note,
         customer_parent,
         customer_type,
         location,
         lat_long,
         establishment_email
      }, this.httpOptions);
   }

   //GET ALL ESTABLISHMENT CLIENT API START HERE
   getAllEstablishmentCustomers(user_id, pageNumber, customer_type, keyword, establishment_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/getAllClientsEstablishment', {
         user_id,
         pageNumber,
         customer_type,
         keyword,
         establishment_id
      }, this.httpOptions);
   }

   // ADD NEW RESIDENCE CLIENT API START HERE 
   addNewClient(user_id, establishment_id, customer_f_name, customer_l_name, email, country_code, phone_no, appartment_no, customer_note, door_code, customer_type): Observable<any> {

      return this.http.post<any>(this.baseUri + '/users/addEstablishmentClients', {
         user_id,
         establishment_id,
         customer_f_name,
         customer_l_name,
         email,
         country_code,
         phone_no,
         appartment_no,
         customer_note,
         door_code,
         customer_type
      }, this.httpOptions);
   }


   // DELETE RESIDENCE/MEDICAL/OTHER API START HERE
   deleteResidenceClient(user_id, customer_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/deleteClient', {
         user_id,
         customer_id
      }, this.httpOptions);
   }

   // DELETE RESIDENCE/MEDICAL/OTHER API START HERE
   deleteResidence(user_id, establishment_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/deleteEstablishment', {
         user_id,
         establishment_id
      }, this.httpOptions);
   }

   // EDIT RESIDENCE/MEDICAL/OTHER API START HERE
   editEstablishment(user_id, establishment_id, establishment_name, customer_note, customer_parent, customer_type, establishment_email): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/editEstablishment', {
         user_id,
         establishment_id,
         establishment_name,
         customer_note,
         customer_parent,
         customer_type,
         establishment_email
      }, this.httpOptions);
   }

   // GET ALL ESTABLISHMENT API START HERE
   getAllEstablishments(user_id, keyword): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/getAllEstablishments', {
         user_id,
         keyword
      }, this.httpOptions);
   }


   //UPDATE CLIENT UNDER ESTABLISHMENT API START HERE
   updateClientUnderEstablishment(user_id, customer_id, establishment_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/linkCustomerToEstablishment', {
         user_id,
         customer_id,
         establishment_id
      }, this.httpOptions);
   }

   //APPROVE JOB OWNERSHIP BY PHARMACY
   approveJobOwnership(user_id, job_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/approveOwnershipByPharamcy', {
         user_id,
         job_id
      }, this.httpOptions);
   }


   //GET PHARMACY USED ALL TAGS
   getPharmacyAllTags(user_id, pageNumber, keyword, startDate, endDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/getTagsSummary', {
         user_id,
         pageNumber,
         keyword,
         startDate,
         endDate
      }, this.httpOptions);
   }


   //GET JOBS ACCORDING TO TAG SELECTION
   getJobsAccToTagsSelection(user_id, tag_type, pageNumber, keyword, startDate, endDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/getJobsAccToTagsSelection', {
         user_id,
         tag_type,
         pageNumber,
         keyword,
         startDate,
         endDate
      }, this.httpOptions);
   }


   //GET JOBS ACCORDING TO TAG SELECTION
   getAllCancelledJobs(user_id, pageNumber, keyword, startDate, endDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/getAllCancelledJobs', {
         user_id,
         pageNumber,
         keyword,
         startDate,
         endDate
      }, this.httpOptions);
   }


   //GET JOBS ACCORDING TO TAG SELECTION
   getAllCompletedJobs(user_id, pageNumber, keyword, startDate, endDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/getAllCompletedJobs', {
         user_id,
         pageNumber,
         keyword,
         startDate,
         endDate
      }, this.httpOptions);
   }


   //GET JOBS Public CHARGED-ORDERS TAG SELECTION
   getChargedPublicJobs(user_id, pageNumber, keyword, startDate, endDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/getChargedPublicJobs', {
         user_id,
         pageNumber,
         keyword,
         startDate,
         endDate
      }, this.httpOptions);
   }



   //GET JOBS ACCORDING TO TAG SELECTION
   courierSummeryDetails(user_id, pageNumber, keyword, startDate, endDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/getDriversWithCompletedJobsCount', {
         user_id,
         pageNumber,
         keyword,
         startDate,
         endDate
      }, this.httpOptions);
   }


   //GET JOBS ACCORDING TO TAG SELECTION
   driversAllJobs(user_id, driver_id, pageNumber, keyword, startDate, endDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/driversGetAllJobs', {
         user_id,
         driver_id,
         pageNumber,
         keyword,
         startDate,
         endDate
      }, this.httpOptions);
   }


   //GET JOBS ACCORDING TO TAG SELECTION
   globalSummaryDetails(user_id, startDate, endDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/PharmacyAllPaymentDetails', {
         user_id,
         startDate,
         endDate
      }, this.httpOptions);
   }


   //GET JOBS ACCORDING TO TAG SELECTION
   getjobsAccPaymentMethodSelection(user_id, job_id, pageNumber, keyword): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/getjobsAccPaymentMethodSelection', {
         user_id,
         job_id,
         pageNumber,
         keyword
      }, this.httpOptions);
   }
   // import csv jobs

   importCsvJobs(file): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/importCsvJobs',
         file
      );
   }

   // GET TOTAL EXPENSES OF PHARMACY
   getTotalExpenses(user_id, pageNumber, startDate, endDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/getTotalExpensesPharmacy', { user_id, pageNumber, startDate, endDate }

      );

   }
   // DOWNLAOD EXPENSES PDF
   getExpensesPdf(user_id, startDate, endDate, sentType): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/totalExpensesPdf', { user_id, startDate, endDate, sentType }

      );

   }

   // DOWNLOAD JOB DETIALS PDF
   getJobDetailsPdf(user_id, job_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/jobDetailsPdf', { user_id, job_id }
      );

   }


   // Analyze csv details
   importCsvJobsAnalize(file): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/importCsvJobsAnalize',
         file
      );
   }
   // IMPORT CSV JOBS WITH JSON ARRAY
   importCsvJobsWithArray(user_id, csvArray): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/importCsvJobsWithArray', { user_id, csvArray }
      );
   }

   // GET GLOBAL SUMMARY PDF
   getGlobalSummaryPdf(user_id, startDate, endDate, sentType): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/globalSummaryPdf', { user_id, startDate, endDate, sentType }

      );

   }

   // GET COURIER SUMMARY PDF
   getCourierSummaryPdf(user_id, startDate, endDate, sentType): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/courierSummaryPdf', { user_id, startDate, endDate, sentType }

      );

   }

   // GET DELIVERED ORDERS PDF
   getDeliveredOrdersPdf(user_id, startDate, endDate, sentType): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/deliveredOrderPdf', { user_id, startDate, endDate, sentType }

      );

   }

   // GET CHARGED ORDERS PDF
   getChargedOrderPdf(user_id, startDate, endDate, sentType): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/chargedOrderPdf', { user_id, startDate, endDate, sentType }

      );

   }

   // GET CANCELLED ORDERS PDF
   getCancelledOrdersPdf(user_id, startDate, endDate, sentType): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/cancelledOrdersPdf', { user_id, startDate, endDate, sentType }

      );

   }
   //GET ALL USED TAGS
   getAllUsedTagsPdf(user_id, startDate, endDate, sentType): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/jobTagsPdf', { user_id, startDate, endDate, sentType }

      );

   }
   // privay policy accept
   privacy_policy_accept(user_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/privacy_policy_accept', { user_id }

      );

   }

   // update secret code
   updateSecretCode(user_id, secret_code): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/updateSecretCode', { user_id, secret_code }

      );

   }

   // logout pharmacy 
   logout(user_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/logoutPharmacy', { user_id }

      );

   }
   getJobDetailsForEditJob(job_id, user_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/getJobDetailsForEditJob', { job_id, user_id }
      );
   }
   // get invoices pharmacy
   getInvoiceForPharmacy(user_id, pageNumber, startDate, endDate, type, dataState): Observable<any> {
      return this.http.post<any>(this.baseUri + '/transactions/getTransactionsPharmacy', { user_id, pageNumber, startDate, endDate, type, dataState })

   }
   sendNotificationToDriver(driver_id, user_id, notificationText, notificationTitle, job_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/sendNotificationToDriver', { driver_id, user_id, notificationText, notificationTitle, job_id })

   }

   getPharmacyPrivateDrivers(user_id, pageNumber, keyword, filterBy): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/getPharmacyPrivateDrivers', { user_id, pageNumber, keyword, filterBy })

   }

   getIndividualDrivers(user_id, pageNumber, keyword): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/getIndividualDrivers', { user_id, pageNumber, keyword })

   }
   sendLinkRequest(user_id, driver_id, secretCode): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/sendLinkRequestToDriver', { user_id, driver_id, secretCode })


   }

   removePrivateDriver(user_id, driver_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/removePrivateDriver', { user_id, driver_id })


   }

   updateNotificationStatus(user_id, status): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/updateEmailNotificationStatus', { user_id, status })


   }

   getPharmacyProfile(user_id, language = 'en'): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/getProfilePharmacy', { user_id, language })
   }

   fetchDataFromGoogleApi(data): Observable<any> {
      console.log("In services.fetchDataFromGoogleApi");
      console.log(data);
      return this.http.post<any>(this.baseUri + '/users/fetchDataFromGoogleApi', data);
   }

   getConnectedDispatch(user_id, pageNumber, keyword): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/getConnectedDispatch', { user_id, pageNumber, keyword })
   }
   removeDispatch(user_id, dispatchCompanyId): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/removeDispatchByPharmacy', { user_id, dispatchCompanyId })


   }
   generateMultipleJobsQrCode(user_id, jobIds): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/generateQrCodeForMultipleJobs', { user_id, jobIds })


   }

   getCustomerDetailsPdf(user_id, job_id, customer_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/generateResidenceClientDetailsPdf', { user_id, job_id, customer_id })

   }

   getJobDetailsPdfNew(user_id, job_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/getJobDetailsPdfNew', { user_id, job_id }
      );

   }

   checkJobDuplicacy(user_id, job_type, customer_id, establishment_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/checkDuplicateJob', { user_id, job_type, customer_id, establishment_id }
      );

   }
   getExtendRecursiveJobs(user_id, pageNumber): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/getExtendRecursiveJobs', { user_id, pageNumber }
      );
   }

   getRecursiveJobForCalendarView(user_id, startDate, endDate): Observable<any> {
      let queryParameters = new HttpParams();

      queryParameters = queryParameters.append("user_id", user_id)
         .append('startDate', startDate)
         .append("endDate", endDate);

      return this.http.get<any>(this.baseUri + '/jobs/getCalendarRecursiveJobs', { params: queryParameters });
   }

   extendRecursiveJob(user_id, job_id, startDate, endDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/jobs/extendRecursiveJob', { user_id, job_id, startDate, endDate }
      );
   }
   updateProfile(user_id): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/checkPharmacyFaxStatus', {
         user_id
      }, this.httpOptions);
   }


   //getNotesJobId 
   getNotesJobId(job_id): Observable<any> {
      return this.http.get<any>(this.baseUri + '/users/note-order-job/' + job_id, this.httpOptions);
   }
   //storeNotesJobId 
   storeNotesJobId(orderId, note, userId): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/note-order-job/', { orderId, note, userId }, this.httpOptions);
   }

   //users/group-jobs-pharmacy
   groupJobsPharmacy(user_id, keyword, pageNumber, searchDate, searchWithDateEnd, job_status, pageSize): Observable<any> {
      return this.http.post<any>(
         this.baseUri + "/users/group-jobs-pharmacy",
         {
            user_id,
            keyword,
            pageNumber,
            searchDate,
            searchWithDateEnd, job_status,
            pageSize
         },
         this.httpOptions
      );
   }


   //All Jobgs
   allJobsPharmacy(data: { user_id, pageNumber, startDate, endDate, generatePdf, job_status } = { user_id: "", pageNumber: 0, startDate: '', endDate: '', generatePdf: false, job_status: '' }): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(
         this.baseUri + "/users/get-all-jobs",
         data
         ,
         this.httpOptions
      );
   }

   //SEARCH FOR NEW JOBS AND ALL JOBS
   getGroupJobs(user_id, keyword, pageNumber, searchDate, searchDateEnd): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/group-jobs', {
         user_id,
         keyword,
         pageNumber,
         searchDate,
         searchDateEnd
      }, this.httpOptions);
   }

   // GET TOTAL EXPENSES OF DASHBOARD
   getTotalDashboard(user_id, pageNumber, startDate, endDate): Observable<any> {
      return this.http.post<any>(this.baseUri + '/reports/getTotalExpensesDashboard', { user_id, pageNumber, startDate, endDate }

      );

   }
   //*SHOP ENDPOINTS

   brandAll(filter, page, sort): Observable<any> {

      return this.http.get<any>(this.baseShop + 'brand', { params: { filter, page, sort } }

         ,);
   }
   brandLis(): Observable<any> {
      return this.http.get<any>(this.baseShop + 'brand'

         ,);
   }

   getBrandId(id): Observable<any> {
      return this.http.get<any>(this.baseShop + 'brand/' + id

         ,);
   }

   // CREATE BRAND
   createBrand(obj: any, file: File): Observable<any> {

      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', obj.brand_name);
      formData.append('nameFr', obj.nameFr);
      formData.append('isFeatured', obj.isFeatured);
      return this.http.post<any>(this.baseShop + 'brand',
         formData
         ,);
   }

   // UPDATE BRAND
   updateBrand(id, obj: any, brand): Observable<any> {


      const formData = new FormData();
      formData.append('_id', id);
      formData.append('name', obj.name);
      formData.append('nameFr', obj.nameFr);
      formData.append('isFeatured', obj.isFeatured);

      return this.http.put<any>(this.baseShop + 'brand',
         formData
         ,);
   }


   //FUNCTION TO DELETE BRAND
   deleteBrand(id): Observable<any> {
      return this.http.delete<any>(this.baseShop + 'brand/' + id, {

      });
   }


   categoryAll(filter, page, sort): Observable<any> {


      return this.http.get<any>(this.baseShop + 'categories', { params: { filter, page, sort } },);
   }

   categoryLis(): Observable<any> {
      return this.http.get<any>(this.baseShop + 'categories'
         ,);
   }

   getCategoryId(id): Observable<any> {
      return this.http.get<any>(this.baseShop + 'categories/' + id

         ,);
   }

   deleteCategory(id): Observable<any> {
      return this.http.delete<any>(this.baseShop + 'categories/' + id, {

      });
   }

   // CREATE BRAND
   createCategory(obj: any, file: File, id): Observable<any> {

      const formData = new FormData();
      formData.append('pharmacyId', id);
      formData.append('file', file);
      formData.append('name', obj.name);
      formData.append('nameFr', obj.nameFr);
      formData.append('isFeatured', obj.isFeatured);
      return this.http.post<any>(this.baseShop + 'categories',
         formData
         ,);
   }

   // UPDATE BRAND
   updateCategory(id, obj: any, brand, pharmacyId): Observable<any> {


      const formData = new FormData();
      formData.append('_id', id);
      formData.append('name', obj.name);
      formData.append('nameFr', obj.nameFr);
      formData.append('isFeatured', obj.isFeatured);
      formData.append('pharmacyId', pharmacyId);
      return this.http.put<any>(this.baseShop + 'categories',
         formData
         ,);
   }

   productAll(filter, page, sort) {

      return this.http.get<any>(this.baseShop + 'products', { params: { filter, page, sort } });
   }
   productAllList() {

      return this.http.get<any>(this.baseShop + 'products/products-details');
   }
   product(id) {
      return this.http.get<any>(this.baseShop + 'products/' + id,)
   }

   getPresentation(id) {
      return this.http.get<any>(this.baseShop + 'presentations/' + id,)
   }

   presentationLis() {
      return this.http.get<any>(this.baseShop + 'presentations',)
   }

   typeLis() {
      return this.http.get<any>(this.baseShop + 'unit-type',)
   }

   ordersAll(user_id, page) {

      return this.http.post<any>(this.baseShop + 'orders', { user_id, pageNumber: page },);
   }

   orderDetailAll(id) {
      return this.http.post<any>(this.baseShop + `orders/${id}`, { id })
   }

   updateSubOrder(id, status) {
      return this.http.post<any>(this.baseShop + `orders/updateSuborderStatus`, { id: id, status: status })
   }

   createJobSubOrder(id) {
      return this.http.post<any>(this.baseShop + `jobs/create/job/of/order-new`, { id: id })
   }



   createProduct(values: any, valuePrincipal: any, product, file: File): Observable<any> {

      const formData = new FormData();
      formData.append('name', valuePrincipal.name);
      formData.append('nameFr', values.nameFr);
      formData.append('detail', valuePrincipal.detail);
      formData.append('detailFr', values.detailFr);
      formData.append('productDetails', product);
      formData.append('prescription', valuePrincipal.prescription);
      formData.append('active', valuePrincipal.active);
      formData.append('brandId ', valuePrincipal.brand);
      formData.append('categoryId', valuePrincipal.category);
      formData.append('file', file);

      return this.http.post<any>(this.baseShop + 'products',
         formData
         ,);
   }


   updateProduct(id: string, values: any, valuePrincipal: any, product, file: File): Observable<any> {

      const formData = new FormData();
      formData.append('_id', id);
      formData.append('name', valuePrincipal.name);
      formData.append('nameFr', values.nameFr);
      formData.append('detail', valuePrincipal.detail);
      formData.append('detailFr', values.detailFr);
      formData.append('productDetails', product);
      formData.append('prescription', valuePrincipal.prescription);
      formData.append('active', valuePrincipal.active);
      formData.append('brandId ', valuePrincipal.brand);
      formData.append('categoryId', valuePrincipal.category);
      formData.append('file', file);
      var queryParameter = '?updateImage=true';
      if (file == undefined) {
         queryParameter = '?updateImage=false';
      }

      return this.http.put<any>(this.baseShop + 'products' + queryParameter,
         formData
      );
   }


   //FUNCTION TO DELETE BRAND
   deleteProduct(id): Observable<any> {
      return this.http.delete<any>(this.baseShop + 'products/' + id, {

      });
   }
   public get selectedValue(): any | null {
      return this._selected$.value;
   }


   public set setSelected$(idOrder: any | null) {
      this._selected$.next(idOrder);
      // console.log(this._selected$);
   }

   public cleanSelected(): any {
      this._selected$.next(null);
   }

   getPrescriptionsList(filter, page, sort, sortDirection) {
      return this.http.get<any>(this.baseShop + 'order/prescriptions/list', { params: { filter, page, sort, sortDirection } },);
   }
   getPrescriptionDetails(id) {
      return this.http.get<any>(this.baseShop + 'order/' + id,);
   }
   updatePrescriptionStatus(id, data) {
      return this.http.put<any>(this.baseShop + 'order/' + id, data);
   }
   addOrderComment(id, data) {
      return this.http.post<any>(this.baseShop + 'order/' + id + '/comment', data);
   }
   getOrderComments(id) {
      return this.http.get<any>(this.baseShop + 'order/' + id + '/comments');
   }

   sendNotification(data) {
      return this.http.post<any>(this.baseUri + '/jobs/send_job_notification', data);
   }

   // coments jobs
   addJobComents(idJob: string, data: ComentType) {
      return this.http.post<any>(this.baseUri + '/jobs/' + idJob + '/comments', data);

   }
   getCommentsByIdJobs(idJob: string) {
      return this.http.get<any>(this.baseUri + '/jobs/' + idJob + '/comments');
   }

   deleteCommentByIdJobs(idJob: string, idComent: string): Observable<any> {
      return this.http.delete<any>(this.baseUri + '/jobs/' + idJob + '/comments/' + idComent);
   }
   getMensajeCambio() {
      return this.mensajeCambio.asObservable();
   }

   setMensajeCambio(mensaje: any) {
      this.mensajeCambio.next(mensaje);
   }

   deleteJobRecursiveV2(data) {
      return this.http.post<any>(this.baseUri + '/jobs/deleteRecursiveJobV2', data);
   }

   convertJobRecursiveToV2(data) {
      return this.http.post<any>(this.baseUri + '/jobs/convertToRecursiveJobV2', data);
   }

   getDetailsForEditRecursiveV2(recursive_id, user_id) {
      return this.http.post<any>(this.baseUri + '/jobs/getDetailsForEditRecursiveV2', {
         recursive_id,
         user_id
      });
   }

   editJobRecursiveV2(data) {
      return this.http.post<any>(this.baseUri + '/jobs/editRecursiveJobV2', data);
   }

   convertOrderToJob(id) {
      return this.http.get<any>(this.baseUri + '/jobs/create/job/of/order/' + id);
   }

   getAddressesClient(id) {
      return this.http.get<any>(this.baseUri + '/customers/' + id + '/directions');
   }

   addNewAddressClient(id: string, data) {
      return this.http.post<any>(this.baseUri + '/customers/' + id + '/directions', data);
   }

   updateAddressClient(clientId: string, addressId, data) {
      return this.http.patch<any>(this.baseUri + '/customers/' + clientId + '/directions/' + addressId, data);
   }

   updateDefaultAddressClient(clientId: string, addressId, data) {
      return this.http.patch<any>(this.baseUri + '/customers/' + clientId + '/directions/' + addressId, data);
   }

   deleteAddressClient(clientId: string, addressId) {
      return this.http.delete<any>(this.baseUri + '/customers/' + clientId + '/directions/' + addressId);
   }

   getActiveDriversJobs(data): Observable<any> {
      // return this.http.post<any>(`${this.locationUrl}jobs/drivers/with-active-jobs`, data);
      return this.http.post<any>(this.baseUri + '/driver/getDriversRealTime', data);
   }

   deleteDriver(_id: any): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/deleteDriver', {
         _id,
      }, this.httpOptions);
   }

   editDriver(data: any): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/editDriver', {
         data,
      }, this.httpOptions);
   }


   addDriver(data: any): Observable<any> {
      // Use FormData to handle files upload

      const formData = new FormData();
      for (let key in data) {
         if (data.hasOwnProperty(key)) {
            formData.append(key, data[key]);
         }
      }

      return this.http.post<any>(this.baseUri + '/users/createDriver', formData);
   }

   getAllPharmacyDrivers(_id: any, pageNumber: any, searchEvent: any, sortBy: string): Observable<any> {
      return this.http.get<any>(this.baseUri + '/users/getPharmacyDrivers', {
         ...this.httpOptions, params: {
            _id,
            pageNumber,
            searchEvent,
            sortBy
         }
      });
   }

   // FILTER DRIVER LISTING WITH KEYWORD
   filterDrivers(data): Observable<any> {
      return this.http.get<any>(this.baseUri + '/users/getPharmacyDrivers', {
         headers: this.headers,
         params: data
      });
   }

   savePharmacyContract(data): Observable<any> {
      return this.http.post<any>(this.baseUri + '/users/savePharmacyContract', data, this.httpOptions);
   }

   addJobTag(tag): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUri + '/users/addJobTag', tag, this.httpOptions);
   }

   editJobTag(tag): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUri + '/users/editJobTag', tag, this.httpOptions);
   }

   getUserJobTags(data): Observable<ApiResponse> {
      return this.http.get<ApiResponse>(this.baseUri + '/users/getUserJobTags', {
         headers: this.headers,
         params: data
      });
   }



   uploadFile(data: { file: File, fileName: string, _id: string, containerName: string }): Observable<GetUploadFileResponse> {
      // Use FormData to handle files upload

      const formData = new FormData();
      for (let key in data) {
         if (data.hasOwnProperty(key)) {
            formData.append(key, data[key]);
         }
      }

      return this.http.post<GetUploadFileResponse>(this.baseUri + 'upload/uploadFile', formData);
   }

   updateAttachedDocumentUrl(data: { _id: string, url: string, docName: string, isRecursiveManagement?: boolean } = { _id: '', url: '', docName: '', isRecursiveManagement: false }): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.baseUri + '/jobs/updateAttachedDocumentUrl', data, this.httpOptions);
   }
}
