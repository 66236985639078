<div class="">
  <div class="modal-header">
    <h4 class="modal-title">New Comment</h4>
    <button type="button" class="" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <div class="row"> -->
    <div class="">
      <div style="">
        <app-coment-message [id]="_id"></app-coment-message>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="comment">New Comment</label>
          <textarea
            class="form-control"
            placeholder="Enter a comment ..."
            id="comment"
            rows="3"
            [(ngModel)]="newComment"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">
      {{ "SUBSCRIPTION.back" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      (click)="confirmCommentModal()"
    >
      {{ "CLIENTS.send" | translate }}
    </button>
  </div>
</div>
