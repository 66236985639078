import { Component, OnInit } from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';

// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// const FileSaver = require('file-saver');

@Component({
  selector: 'app-prescriptions',
  templateUrl: './prescriptions.component.html',
  styleUrls: ['./prescriptions.component.scss']
})
export class PrescriptionsComponent implements OnInit {

  public allPrescriptions = [];
  allPrescriptionsCount = 0;
  currentPage: 0;
  submitted = false;
  page = 1;
  public language = "en";
  sort = 'date';
  sortDirection = -1;
  filter = '';
  localData: any;
  customer_id: any;
  pageNumber: any;
  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    private spinner: NgxSpinnerService,
    // private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    // this.sort = 1;
    this.submitted = false;
    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en"
    }

    this.page = 1;
    this.filter = '';

    this.getAllPrescriptions(this.filter, this.page, this.sort, this.sortDirection);

  }

  //SEARCH CLIENTS FUNCTION START HERE
  searchPrescription(event) {
    if (event.length == 0) {
      return this.ngOnInit();
    }
    if (event.length <= 0) {
      return console.log('less then 2');
    }

    this.filter = event;
    this.page = 1;
    // this.sort = 1;
    this.getAllPrescriptions(this.filter, this.page, this.sort, this.sortDirection);
  }

  //ON PAGE CHANGE FUNCTION
  onPageChange(event) {
    this.page = event;
    this.getAllPrescriptions(this.filter, this.page, this.sort, this.sortDirection);
  }

  reload() {
    this.getAllPrescriptions(this.filter, this.page, this.sort, this.sortDirection);
  }

  getAllPrescriptions(filter, page, sort, sortDirection) {
    this.spinner.show();
    this.services.getPrescriptionsList(filter, page, sort, sortDirection).pipe().subscribe(data => {
      this.spinner.hide();
      this.allPrescriptions = [...data.entityList];

      this.allPrescriptionsCount = data.totalRegister;
      this.pageNumber = data.currentPage
    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error?.error?.message);
    });

  }

  goToDetails(order) {
    if (order.status === 'Order sent') {
      const data = {
        status: 'Order received'
      }
      this.services.updatePrescriptionStatus(order?._id, data).subscribe(data => {
        this.toastr.success('Success', 'Order received');
        this.reload();
      }, error => {
        console.log('error update :', error);
      }
      )
    }
    this.router.navigate(['/prescriptions/' + order._id]);

    // FileSaver.saveAs(order?.urlS3, 'prescription.pdf');
  }

  downloadFile(order) {
    window.open(order?.urlS3, '_blank');
  }

  // onAnswerModal(modal, order) {
  //   this.modalService.open(modal, { backdrop: 'static' }).result.then((result) => {
  //     console.log('result:', result);
  //   });
  //   console.log('order selected modal:', order);
  // }

}
