<link
  href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
  rel="stylesheet"
/>
<div class="card mt-3">
  <div class="card-body">
    <div class="row">
      <div class="col-lg-4">
        <div class="scp-breadcrumb">
          <ul class="breadcrumb">
            <li class="b-jobs">
              <a>{{ 'PRESCRIPTION.title' | translate }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md-4">
        <form class="search-box-custom">
          <input
            type="text"
            name="search"
            placeholder="{{ 'SEARCH' | translate }}"
            class="animated-search"
            (ngModelChange)="searchPrescription($event)"
            [(ngModel)]="filter"
            [ngModelOptions]="{ standalone: true }"
          />
        </form>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table table-borderless jobs-table">
          <thead>
            <tr style="align-items: center">
              <th style="text-align: center">
                {{ 'PRESCRIPTION.code' | translate }}
              </th>
              <th style="text-align: center">
                {{ 'PRESCRIPTION.amount' | translate }}
              </th>
              <th style="text-align: center">
                {{ 'PRESCRIPTION.date' | translate }}
              </th>
              <th style="text-align: center">
                {{ 'PRESCRIPTION.status' | translate }}
              </th>
              <th style="text-align: center"></th>
            </tr>
          </thead>

          <tbody>
            <tr
              *ngFor="
                let order of allPrescriptions
                  | paginate
                    : {
                        itemsPerPage: 10,
                        currentPage: pageNumber,
                        totalItems: allPrescriptionsCount
                      }
              "
            >
              <td style="text-align: center" (click)="goToDetails(order)">
                {{ order.code }}
              </td>

              <td style="text-align: center" (click)="goToDetails(order)">
                {{ order.total | currency : 'USD' : true : '1.2-2' }}
              </td>

              <td
                style="text-align: center"
                style="text-align: center"
                (click)="goToDetails(order)"
              >
                {{ order.date | date : 'dd/MM/yyyy HH:mm' }}
              </td>

              <td
                style="text-align: center"
                style="text-align: center"
                (click)="goToDetails(order)"
              >
                {{ order.status }}
              </td>
              <td (click)="goToDetails(order)">
                <a style="color: #d8e1fc"
                  ><i class="fa fa-chevron-right" aria-hidden="true"></i
                ></a>
              </td>
              <!-- <td style="text-align: center;">
                <a *ngIf="order?.urlS3" href="javascript:void(0)" class="button-icon" (click)="downloadFile(order)"
                  ngbTooltip="Watch">
                  <i class="fa fa-file"></i>
                </a>


              </td> -->
            </tr>
          </tbody>
        </table>

        <div class="no-jobs-found">
          <p
            class="no-item text-center text-danger mb-0"
            *ngIf="!allPrescriptions.length"
          >
            {{ 'CLIENTS.no_client_found' | translate }}
          </p>
        </div>

        <div class="row f-right" style="margin-right: 40px">
          <pagination-controls
            *ngIf="allPrescriptions.length"
            (pageChange)="onPageChange($event)"
          >
            2</pagination-controls
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <ng-template #answerModal let-modal>
  <app-answer-modal [item]="modalItem" [modal]="modal" (reloadTable)="reload()"></app-answer-modal>
</ng-template> -->
