import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  Router
} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import 'moment-timezone';
import {
  BsModalRef,
  BsModalService
} from 'ngx-bootstrap/modal';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';
import { LocationAgmComponent } from '../../shared/component/location-agm/location-agm.component';
import { ModalComponent } from "../../shared/component/modal/modal.component";
import { ListClientAddressesModalComponent } from './list-client-addresses-modal/list-client-addresses-modal.component';
@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {
  @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
  @ViewChild('updateEstablishmentNameTemplate') updateEstablishmentNameTemplate: TemplateRef<any>;
  @ViewChild('deleteClientTemplate') deleteClientTemplate: TemplateRef<any>;
  @ViewChild('upsertClientTemplate') upsertClientTemplate: TemplateRef<any>;
  @ViewChild(LocationAgmComponent) locationAgmComponent: LocationAgmComponent;
  @ViewChild(ListClientAddressesModalComponent) listClientAddressesModalComponent: ListClientAddressesModalComponent;
  allClients = [];
  sortedClients = [];
  allClientsCount = 0;
  pageNumber = 1;
  registerForm: UntypedFormGroup;
  submitted = false;
  residenceLocation: any;
  lat: any;
  long: any;
  lat_long = [];
  iban_no: any;
  phoneNumber: any;
  countryCode: any;
  customer_id: any;
  selectionType: any;
  localData: any;
  clientLocation: any;
  userSearch = '';
  currentPage: any;
  searchEvent = '';
  recursiveJobs = [];
  repeatJob_id: any;
  public language = "en";
  options: any;
  establishments = [];
  selectedEstablish: any;
  establishment_id: any;
  establishment_name: string;
  sortBy = "0";

  locationData: any = {};
  location_alias_en: UntypedFormControl = new UntypedFormControl(null);
  location_alias_fr: UntypedFormControl = new UntypedFormControl(null);
  city: string;
  zipcode: string;
  bsModalRef: BsModalRef;
  bsModalRefNested: BsModalRef;
  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private bsModalService: BsModalService,
  ) { }

  ngOnInit() {
    this.sortBy = '0';
    this.submitted = false;
    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en"
    }
    //ADD NEW RESIDENCE FORM 
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      Location: ['', Validators.required],
      phoneNumber: [''],
      email: ['', [Validators.email]],
      aptNo: [''],
      doorCode: [''],
      customer_note: [''],
      customer_parent: [''],
    });

    this.localData = JSON.parse(localStorage.getItem("currentUser"));
    this.pageNumber = 1;
    this.userSearch = '';
    this.getAllClients(this.userSearch, this.pageNumber);
  }

  refreshTableClients() {
    this.getAllClients(this.userSearch, this.pageNumber);
  }

  //GET ALL CLIENT API START HERE
  getAllClients(searchEvent, pageNumber) {
    this.spinner.show();
    this.services.getAllClients(this.localData._id, pageNumber, '2', searchEvent, this.sortBy).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.allClients = data.result;
        this.sortedClients = data.result.sort((a, b) => (a.customer_f_name.localeCompare(b.customer_f_name)))
        this.allClientsCount = data.count;
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });
  }

  //ON PAGE CHANGE FUNCTION
  onPageChange(page) {
    this.pageNumber = page;
    this.getAllClients(this.userSearch, this.pageNumber);
  }

  //SORT ADDRESS FUNCTION START HERE
  sortAddress(address) {
    const address1 = address.split(',');
    if (address1.length > 5) {
      return address1[0] + ',' + address1[1] + ',' + address1[2] + ',' + address1[3] + ',' + address1[4];
    } else {
      var str = '';
      for (const data of address1) {
        str += data + ',';
      }
      return str.slice(0, -1)
    }
  }

  //SEARCH CLIENTS FUNCTION START HERE
  searchClients(event) {
    if (event.length == 0) {
      return this.ngOnInit();
    }
    if (event.length <= 0) {
      return console.log('less then 2');
    }
    this.userSearch = event;
    this.pageNumber = 1;
    this.sortBy = '0';
    this.getAllClients(this.userSearch, this.pageNumber);
  }

  //SEARCH ADDRESS FUNCTION START HERE
  handleAddressChange(address) {
    this.clientLocation = address?.formatted_address;
    this.location_alias_en.setValue(address?.aliasEn);
    this.location_alias_fr.setValue(address?.aliasFr);
    this.f.Location.setValue(this.clientLocation);
    this.lat = address.geometry.location.lat();
    this.long = address.geometry.location.lng();
    this.lat_long = [this.lat, this.long]

    let city = null;
    let zipcode = null;

    //Todo: get city and zipcode
    address?.address_components.forEach(data => {
      if (data.types[0] == "locality") {
        city = data.long_name;
      }
      if (data.types[0] == "postal_code") {
        zipcode = data.long_name;
      }
    });
    this.city = city;
    this.zipcode = zipcode;

    this.locationData = {
      lat: this.lat,
      lng: this.long,
      address: this.clientLocation,
      aliasEn: this.location_alias_en.value,
      aliasFr: this.location_alias_fr.value,
    }
    this.bsModalRefNested.hide();
  }

  showClientModal(data = null) {
    this.registerForm.reset();
    this.submitted = false;
    this.phoneNumber = '';
    this.customer_id = '';
    this.locationData = {
      lat: null,
      lng: null,
      address: null,
      aliasEn: null,
      aliasFr: null,
    }
    if (data) {
      this.customer_id = data._id;

      this.phoneNumber = data.phone_no;
      this.countryCode = data.country_code
      this.registerForm.controls['firstName'].setValue(data.customer_f_name);
      this.registerForm.controls['lastName'].setValue(data.customer_l_name);
      this.registerForm.controls['phoneNumber'].setValue(data.phone_no);
      this.registerForm.controls['Location'].setValue(data.location);
      this.registerForm.controls['email'].setValue(data.email);
      this.registerForm.controls['aptNo'].setValue(data.appartment_no);
      this.registerForm.controls['doorCode'].setValue(data.door_code);
      this.registerForm.controls['customer_note'].setValue(data.customer_note);
      this.registerForm.controls['customer_parent'].setValue(data.customer_parent);
      this.clientLocation = data.location;
      if (data.establishment_id) {
        this.registerForm.controls['Location'].disable();
      }
      this.lat_long = data.lat_long;

      this.locationData = {
        lat: data.lat_long[0],
        lng: data.lat_long[1],
        address: data.location
      }
    }
    const initialState = {
      content: this.upsertClientTemplate,
      config: {
        title: data ? "CLIENTS.edit_client" : "CLIENTS.add_client",

      },
      onClick: this.upsertClient.bind(this, data),

    };
    this.bsModalRef = this.bsModalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-md modal-dialog-centered",
    });
  }
  upsertClient(data) {
    this.submitted = true;
    if (!this.f.firstName.value.trim()) {
      return this.toastr.error('', 'Please enter first name');
    }
    else if (!this.f.lastName.value.trim()) {
      return this.toastr.error('', 'Please enter last name');
    }
    if (this.f.firstName.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.firstName.value);
      if (testing == true) {
        return this.toastr.error('', 'First name not valid!');
      }
    }
    if (this.f.lastName.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.firstName.value);
      if (testing == true) {
        return this.toastr.error('', 'Last name not valid!');
      }
    }

    if (this.f.customer_note.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.customer_note.value);
      if (testing == true) {
        return this.toastr.error('', 'Last name not valid!');
      }
    }

    if (!this.phoneNumber) {
      return this.toastr.error('', 'Please enter phone number');
    }
    if (this.phoneNumber.length != 12) {
      return this.toastr.error('', 'Please enter valid phone number');
    }

    if (this.f.email.value && this.validateEmail(this.f.email.value) == false) {
      return this.toastr.error('', 'Email is not valid!')
    }
    if (!this.f.Location.value.trim()) {
      return this.toastr.error('', 'Please enter Location');
    }

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    const customer_email_aux = !this.f.email.value ? 'default@customer.com' : this.f.email.value.trim();

    this.spinner.show();
    data ? this.editClient(customer_email_aux) : this.addNewClient(customer_email_aux);

  }

  get f() { return this.registerForm.controls; }

  //ADD NEW CLIENT FUNCTION START HERE
  addNewClient(customer_email_aux) {

    this.services.addClientPharamcy(this.localData._id, this.phoneNumber, this.localData.country_code, this.clientLocation, this.lat_long, customer_email_aux, this.f.aptNo.value, this.f.doorCode.value, this.f.customer_note.value, this.f.customer_parent.value, this.f.firstName.value, this.f.lastName.value.toUpperCase(), '2', this.location_alias_en.value, this.location_alias_fr.value, this.city, this.zipcode).pipe().
      subscribe(data => {

        if (data.code == 400) {
          this.spinner.hide();
          this.toastr.error('', data.message);
          this.logoutFunction.logout();
          return;
        }
        if (data.code == 200) {
          this.submitted = false;
          this.bsModalRef.hide();
          this.userSearch = '';
          this.registerForm.controls['Location'].setValue('');
          this.ngOnInit();
        } else {
          this.spinner.hide();
          this.toastr.error('error', data.message);
          return;
        }
      });
  }

  //UPDATE MOBILE NUMBER FUNCTION START HERE
  mychange(val) {
    const self = this;
    let chIbn = val.split('-').join('');
    if (chIbn.length > 0) {
      // chIbn = chIbn.match(new RegExp('.{1,3}', 'g')).join('-');
      chIbn = chIbn.match(/\d{3}(?=\d{2,3})|\d+/g).join("-");
    }
    this.phoneNumber = chIbn;
    //this.registerForm.controls['phoneNumber'].setValue(chIbn);
  }

  //VALIDATE EMAIL FUNCTION
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  //EDIT CLIENT API START HERE
  editClient(customer_email_aux) {

    this.services.editClient(this.localData._id, this.customer_id, customer_email_aux, this.localData.country_code, this.phoneNumber, this.f.aptNo.value, this.f.doorCode.value, this.f.customer_note.value, this.f.customer_parent.value, this.f.firstName.value, this.f.lastName.value.toUpperCase(), '2', this.clientLocation, this.lat_long).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.submitted = false;
        this.registerForm.controls['Location'].setValue('');
        this.bsModalRef.hide();
        // this.ngOnInit();
        this.getAllClients(this.userSearch, this.pageNumber);
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });
  }


  //DELETE CLIENT CONFIRMATION MODEL
  openDeleteModel(id) {
    this.customer_id = id;
    const initialState = {
      content: this.deleteClientTemplate,
      config: {
        title: "Confirmation",
        action: "CREATEJOB.yes",
        cancel: "CREATEJOB.no"
      },
      onClick: this.deleteExistClient.bind(this),

    };
    this.bsModalRef = this.bsModalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-sm modal-dialog-centered",
    });
  }

  //DELETE EXIST CLIENT FUNCTION
  deleteExistClient() {
    this.spinner.show();
    this.services.deleteResidenceClient(this.localData._id, this.customer_id).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.bsModalRef.hide();
        // this.ngOnInit();
        this.getAllClients(this.userSearch, this.pageNumber);
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });
  }


  //MODIFY DATE FUNCTION
  modifyDate(str, time) {
    const mydate = str.split('T')[0]
    const convert = mydate.split('-')
    var date = convert[1] + '/' + convert[2] + '/' + convert[0];
    var dateTime = moment(date + " " + time.hours + ":" + time.min, 'MM/DD/YYYY HH:mm').format("MM/DD/YYYY HH:mm")
    return dateTime;
  }


  //GET ALL ESTABLISHMENT DATA
  getAllEstablishmentData(event) {


    var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
    var testing = format.test(event);
    if (testing == true) {
      this.toastr.error('', 'Establishment name not valid!');
      return;
    }

    this.spinner.show();
    this.services.getAllEstablishments(this.localData._id, event).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.spinner.hide();
        this.establishments = data.result;
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });
  }


  //ON ESTABLISHMENT SELECT
  onSelectEstablish(event) {
    this.establishment_id = event._id
  }

  //confirmation model to update client
  updateClientConfirmationModel(data) {
    this.establishment_name = '';
    this.customer_id = data._id;
    const initialState = {
      content: this.updateEstablishmentNameTemplate,
      config: {
        title: "CLIENTS.UPDATE_CLIENT_UNDER_ESTABLISHMENT",

      },
      onClick: this.updateClientUnderEstablishment.bind(this),

    };
    this.bsModalRef = this.bsModalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-md modal-dialog-centered",
    });
  }



  //UPDATE CLIENT TO MERGE WITH A RESIDENCE
  updateClientUnderEstablishment() {
    if (!this.customer_id || !this.establishment_id) {
      return this.toastr.error('', 'Please select establishment');
    }

    this.spinner.show();
    this.services.updateClientUnderEstablishment(this.localData._id, this.customer_id, this.establishment_id).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.toastr.success('', 'Client updated under establishment');
        this.bsModalRef.hide();
        this.establishment_name = '';
        this.getAllClients(this.userSearch, this.pageNumber);
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });
  }

  sortByFilter(event) {
    this.sortBy = event.target.value;
    this.searchEvent = "";
    this.userSearch = '';
    this.pageNumber = 1;
    this.getAllClients(this.userSearch, this.pageNumber);
  }

  openModalSelectLocation(modal, header) {

    const initialState = {
      content: modal,
      header: header,

    };
    this.bsModalRefNested = this.bsModalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-xl modal-dialog-centered",
    });
  }

  selectedClient: any = null;
  openListAddressesModal(modal, header, client: any) {
    this.selectedClient = client;
    const initialState = {
      content: modal,
      header: header,

    };
    this.bsModalRefNested = this.bsModalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-xl modal-dialog-centered",
    });

  }

}
