import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ResidenceComponent } from '../residence/residence.component';

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.scss']
})
export class OthersComponent implements OnInit {
  @ViewChild('residenceComponent', { static: false }) residenceComponent: ResidenceComponent;
  dynamicContentTemplate: TemplateRef<any>;
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.dynamicContentTemplate = this.residenceComponent.dynamicContentTemplate;

  }

}
