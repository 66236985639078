import { DOCUMENT } from "@angular/common";
import {
    Component, HostListener, Inject, Injectable, OnDestroy, OnInit, ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
    BsModalRef,
    BsModalService, ModalDirective
} from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { environment } from "../../../environments/environment";
import { PushNotificationService } from "../../push-notification.service";
import { PusherServiceService } from "../../pusher-service.service";
import { ServicesService } from "../../services.service";
import { ParameterService } from "../../services/admin-api/parameter.service";
import { ModalComponent } from "../../shared/component/modal/modal.component";
import { CreateJobComponent } from "../../views/create-job/create-job.component";
import { JobDetailsComponent } from "../../views/job-details/job-details.component";
@Component({
    selector: "app-dashboard",
    templateUrl: "./default-layout.component.html",
    styleUrls: ["./default-layout.component.css"],
})
@Injectable({
    providedIn: "root",
})
export class DefaultLayoutComponent implements OnDestroy, OnInit {
    private angularSubscription: Subscription;
    @ViewChild(CreateJobComponent) createJobComponent: CreateJobComponent;
    @ViewChild(JobDetailsComponent) jobDetailsComponent: JobDetailsComponent;
    @HostListener("window:beforeunload", ["$event"]) unloadHandler(
        event: Event
    ) {
        localStorage.setItem("pageNumber", JSON.stringify("1"));
        localStorage.setItem("userSearch", JSON.stringify(""));
    }
    public profile_img: any;
    public pharmacy_name: string;
    public socket: SocketIOClient.Socket;
    modalBody: string;
    @ViewChild("primaryModal") public primaryModal: ModalDirective;

    public navItems = [];
    public LangChangeEvent: any;
    public sidebarMinimized = true;
    private changes: MutationObserver;
    public element: HTMLElement;
    driver_id: any;
    job_id: any;
    public getCurrentYear: any;
    jobIdtest: any;
    public notifications = [];
    public notiCount: any;
    public dash = "Dashboard";
    public jobz = "Jobs";
    public manage_cards = "Manage cards";
    public transaction = "Transactions";
    public invoice = "Invoices";
    public profile = "Profile";
    public edit_profile = "Edit Profile";
    public change_password = "Change Password";
    public clients = "Clients";

    public subscription = "Subscription";
    public new_job_request = "New job request";
    public active_jobs = "Active jobs";
    public completed_jobs = "Completed jobs";
    public not_delivered = "Not delivered jobs";
    public recursive_jobs = "Recursive jobs";
    public charged_account = "Charged to account";
    public calendar = "Calendr";
    public selectedLanguage = "en";
    public client = "Client";
    public residence = "Residence";
    public medical = "Medical";
    public other = "Other";
    public import_jobs = "Import jobs";
    public report = "Report";
    public global_summary = "Global summary";
    public courier_summary = "Courier summary";
    public delivered_summary = "Delivered orders";
    public charged_orders = "Charged Orders";
    public cancelled_orders = "Cancelled orders";
    public tag_summary = "Tags summary";
    public terms_policy = "Terms and policy";
    public user_guide = "User guide";
    public shop = "Shop";
    public brand = "Brand";
    public category = "Category";
    public product = "Product";
    public orders = "Orders";
    public create_brand = "Create Brand";
    public create_category = "Create Category";
    public prescriptions = "Prescriptions";
    footer_text = "Terms and policy";
    user_id = "";
    private_drivers = "My drivers";
    dispatch = "Dispatch";
    recursive_job_management = "Recursive job management ";

    cookieStatus = true;
    socketStatus = false; // initial socket status false

    shopModule = false;
    guies$ = this.parameterService.getAllGuies();
    termsAndConditions$ = this.parameterService.getTermsAndCondition();
    bsModalRef: BsModalRef;
    guiesUrls = [];
    jobDetailsId = "";
    constructor(
        private router: Router,
        private services: ServicesService,
        public toastr: ToastrService,
        private notif: PushNotificationService,
        private pusherService: PusherServiceService,
        private spinner: NgxSpinnerService,
        public ts: TranslateService,
        private parameterService: ParameterService,
        private modalService: BsModalService,

        @Inject(DOCUMENT) _document?: any
    ) {
        // this.notif.requestPermission();

        this.changes = new MutationObserver((mutations) => {
            this.sidebarMinimized =
                _document.body.classList.contains("sidebar-minimized");
        });
        this.element = _document.body;
        this.changes.observe(<Element>this.element, {
            attributes: true,
            attributeFilter: ["class"],
        });

        ts.addLangs(["en", "fr"]);
        ts.setDefaultLang("en");

        const browserLang = ts.getBrowserLang();
        ts.use(browserLang.match(/en|fr/) ? browserLang : "en");
        var localData2 = JSON.parse(localStorage.getItem("currentUser"));
        this.user_id = localData2?._id;
    }



    ngOnInit() {
        var localData2 = JSON.parse(localStorage.getItem("currentUser"));
        this.user_id = localData2._id;
        this.cookieStatus = JSON.parse(localStorage.getItem("cookiesStatus"))
            ? JSON.parse(localStorage.getItem("cookiesStatus"))
            : false;
        var lang = JSON.parse(localStorage.getItem("language"));
        if (!lang) {
            lang = "en";
        }
        this.selectedLanguage = lang;
        this.footer_text =
            this.selectedLanguage == "fr"
                ? "Termes et conditions"
                : "Terms and policy";
        this.services
            .getPharmacyProfile(this.user_id)
            .pipe()
            .subscribe((data) => {
                this.shopModule = data.result.shopEnabled;
                // console.log(this.shopModule);
                this.navItems = [
                    {
                        key: "HOME.dashboard",
                        name: this.dash,
                        url: "/dashboard",
                        icon: "dashboard"
                    },

                    // {
                    //     key: "HOME.orders",
                    //     name: this.orders,
                    //     url: "/orders",
                    //     icon: "icon-speedometer",
                    // },
                    {
                        key: "HOME.jobs",
                        name: this.jobz,
                        url: "/jobs",
                        icon: "jobs"
                    },
                    {
                        key: "HOME.recursive_job_management",
                        name: this.recursive_job_management,
                        url: "/management-recursive-jobs",
                        icon: "recursive_job_management"
                    },

                    {
                        key: "HOME.clients",
                        name: this.clients,
                        url: "/clients",
                        icon: "clients"

                    },

                    {
                        key: "HOME.import_jobs",
                        name: this.import_jobs,
                        url: "/import-jobs",
                        icon: "import_jobs"
                    },
                    {
                        key: "HOME.calendar",
                        name: this.calendar,
                        url: "/calendar",
                        icon: "icon-calendar",
                    },
                    {
                        key: "Reports",
                        name: this.report,
                        url: "/reports",
                        icon: "reports"
                    },

                    {
                        key: "HOME.invoices",
                        name: this.invoice,
                        url: "/invoice",
                        icon: "invoices"
                    },
                    {
                        key: "HOME.profile",
                        name: this.profile,
                        url: "/profile",
                        icon: "profile_light",
                    },

                ];
                // console.log('SHOP MODULE');
                // console.log(this.shopModule);

                if (this.shopModule) {
                    this.navItems.push(
                        {
                            key: "HOME.shop",
                            name: this.shop,
                            url: "/pages",
                            icon: "icon-basket-loaded",
                            children: [
                                {
                                    key: "HOME.brand",
                                    name: this.brand,
                                    url: "/brands",
                                    icon: "icon-badge",
                                },
                                {
                                    key: "HOME.category",
                                    name: this.category,
                                    url: "/category",
                                    icon: "icon-tag",
                                },
                                {
                                    key: "HOME.product",
                                    name: this.product,
                                    url: "/product",
                                    icon: "icon-diamond",
                                },
                                {
                                    key: "HOME.orders",
                                    name: this.orders,
                                    url: "/orders",
                                    icon: "icon-bag",
                                },
                                {
                                    key: "HOME.prescriptions",
                                    name: this.prescriptions,
                                    url: "/prescriptions",
                                    icon: "icon-bag",
                                },
                            ],
                        },
                    );
                }
                this.navItems.push(
                    this.navItems.push({
                        key: "HOME.terms_policy",
                        name: this.terms_policy,
                        url: "/terms-policy",
                        icon: "terms_and_policy"
                    })
                );
                this.navItems.push(
                    this.navItems.push({
                        key: "HOME.user_guide",
                        name: this.user_guide,
                        url: this.guiesUrls[this.selectedLanguage == "en" ? 0 : 1]?.value,
                        icon: "user_guide"
                    })
                );
            });

        localStorage.setItem("language", JSON.stringify(lang));
        this.ts.use(lang);
        this.ts
            .get([
                "dashboard",
                "jobs",
                "clients",
                "manage_cards",
                "subscription",
                "transactions",
                "invoices",
                "profile",
                "edit_profile",
                "change_password",
                "new_job_request",
                "active_jobs",
                "completed_jobs",
                "not_delivered",
                "recursive_jobs",
                "charged_account",
                "residence",
                "client",
                "medical_center",
                "other",
                "import_jobs",
                "report",
                "global_summary",
                "courier_summary",
                "delivered_summary",
                "charged_orders",
                "cancelled_orders",
                "tag_summary",
                "terms_policy",
                "user_guide",
                "private_drivers",
                "dispatch",
                "recursive_job_management",
                "brand",
                "create_brand",
                "category",
                "create_category",
                "product",
                "orders",
                "calendar_menu"
            ])
            .subscribe(async (res) => {
                this.dash = await res.dashboard;
                this.jobz = await res.jobs;
                this.clients = await res.clients;
                this.manage_cards = await res.manage_cards;
                this.subscription = await res.subscription;
                this.transaction = await res.transactions;
                this.invoice = await res.invoices;
                this.profile = await res.profile;
                this.edit_profile = await res.edit_profile;
                this.change_password = await res.change_password;
                this.new_job_request = await res.new_job_request;
                this.active_jobs = await res.active_jobs;
                this.completed_jobs = await res.completed_jobs;
                this.not_delivered = await res.not_delivered;
                this.recursive_jobs = await res.recursive_jobs;
                this.charged_account = await res.charged_account;
                this.residence = await res.residence;
                this.client = await res.client;
                this.medical = await res.medical_center;
                this.other = await res.other;
                this.import_jobs = await res.import_jobs;
                this.report = await res.report;
                this.global_summary = await res.global_summary;
                this.courier_summary = await res.courier_summary;
                this.delivered_summary = await res.delivered_summary;
                this.cancelled_orders = await res.cancelled_orders;
                this.tag_summary = await res.tag_summary;
                this.terms_policy = await res.terms_policy;
                this.user_guide = await res.user_guide;
                this.private_drivers = await res.private_drivers;
                this.dispatch = await res.dispatch;
                this.recursive_job_management = await res.recursive_job_management;
                this.brand = await res.brand;
                this.create_brand = await res.create_brand;
                this.category = await res.category;
                this.create_category = await res.create_category;
                this.product = await res.product;
                this.orders = await res.orders;
                this.calendar = await res.calendar_menu;

            });

        this.spinner.show();
        this.services
            .changeLanguage(this.user_id, lang)
            .pipe()
            .subscribe((data) => {
                if (data.code == 400) {
                    this.spinner.hide();
                    this.logout();
                    return;
                }
                if (data.code == 200) {
                    this.services
                        .getNotifications(this.user_id)
                        .pipe()
                        .subscribe((dataNoti) => {
                            if (dataNoti.code == 200) {
                                this.notifications = dataNoti.result;
                                this.notiCount = dataNoti.notiCount;
                                this.spinner.hide();
                            } else {
                                this.toastr.error("error", dataNoti.message);
                                this.spinner.hide();
                            }
                        });
                } else {
                    this.spinner.hide();
                    this.toastr.error("error", data.message);
                }
            });
        this.profile_img = localData2.profile_img;
        this.pharmacy_name = localData2.pharmacy_name;

        this.getCurrentYear = new Date().getFullYear();

        if (this.angularSubscription) {
            this.angularSubscription.unsubscribe();
        }

        this.angularSubscription = this.notif.componentMethodCalled$.subscribe(
            () => {
                this.refresh_notifications();
            }
        );
        this.parameterService.getAllGuies().subscribe((guies) => {

            this.guiesUrls = guies;

        });
        this.initPusher();
    }



    initPusher() {

        const orderChannel = this.pusherService.init(environment.CHANNEL_CHANGE_STATUS_JOB + '_' + this.user_id) as unknown as { bind?: any };
        if (orderChannel.bind) {
            orderChannel.bind('new_order', data => {
                console.log('event JOB ACTIVED', data);
                this.refresh_notifications();
            })
        }
    }

    ngOnDestroy(): void {
        this.pusherService.removeChannel(environment.CHANNEL_CHANGE_STATUS_JOB + '_' + this.user_id);

        if (this.angularSubscription) {
            this.angularSubscription.unsubscribe();
        }
        this.changes.disconnect();
    }

    changeGuide(url: string) {
        if (url !== "-") {
            window.open(url, '_blank');
        }
        this.guies$ = this.parameterService.getAllGuies();
    }

    onChangeTC(url: string) {
        console.log(url);
        if (url !== "-") {
            window.open(url, '_blank');
        }
        this.termsAndConditions$ = this.parameterService.getTermsAndCondition();
    }

    switchLang(lang: string) {
        var s1 = this.router.url;
        var s2 = s1.substr(1);

        this.spinner.show();
        localStorage.setItem("language", JSON.stringify(lang));
        this.ts.use(lang);
        this.selectedLanguage = lang;
        this.ts
            .get([
                "dashboard",
                "jobs",
                "clients",
                "manage_cards",
                "subscription",
                "transactions",
                "invoices",
                "profile",
                "edit_profile",
                "change_password",
                "new_job_request",
                "active_jobs",
                "completed_jobs",
                "not_delivered",
                "recursive_jobs",
                "charged_account",
                "residence",
                "client",
                "medical_center",
                "other",
                "import_jobs",
                "report",
                "global_summary",
                "courier_summary",
                "delivered_summary",
                "charged_orders",
                "cancelled_orders",
                "tag_summary",
                "terms_policy",
                "calendar_menu"
            ])
            .subscribe(async (res) => {
                this.dash = res.dashboard;
                this.jobz = res.jobs;
                this.clients = res.clients;
                this.manage_cards = res.manage_cards;
                this.subscription = res.subscription;
                this.transaction = res.transactions;
                this.invoice = res.invoices;
                this.profile = res.profile;
                this.edit_profile = res.edit_profile;
                this.change_password = res.change_password;

                this.new_job_request = await res.new_job_request;
                this.active_jobs = await res.active_jobs;
                this.completed_jobs = await res.completed_jobs;
                this.not_delivered = await res.not_delivered;
                this.recursive_jobs = await res.recursive_jobs;
                this.charged_account = await res.charged_account;
                this.residence = await res.residence;
                this.client = await res.client;
                this.medical = await res.medical_center;
                this.other = await res.other;
                this.import_jobs = await res.import_jobs;
                this.report = await res.report;
                this.global_summary = await res.global_summary;
                this.courier_summary = await res.courier_summary;
                this.delivered_summary = await res.delivered_summary;
                this.charged_orders = await res.charged_orders;
                this.cancelled_orders = await res.cancelled_orders;
                this.tag_summary = await res.tag_summary;
                this.terms_policy = await res.terms_policy;
                this.calendar = await res.calendar_menu;
                this.router
                    .navigateByUrl("/changePassword", {
                        skipLocationChange: true,
                    })
                    .then(() => {
                        this.router.navigate([s2]);
                    });
                this.ngOnInit();
            });

        this.guies$ = this.parameterService.getAllGuies();


        this.termsAndConditions$ = this.parameterService.getTermsAndCondition();
    }

    public refresh_notifications() {
        let localData = JSON.parse(localStorage.getItem("currentUser"));
        if (localData && localData._id) {
            this.services
                .getNotifications(localData._id)
                .pipe()
                .subscribe((data) => {
                    if (data.code == 200) {
                        this.notifications = data.result;
                        this.notiCount = data.notiCount;
                    } else {
                        this.toastr.error("error", data.message);
                    }
                });
        }

        // this.cdRef.detectChanges();
    }



    logout() {
        let localData = JSON.parse(localStorage.getItem("currentUser"));
        this.router.navigate(["login"]);
        localStorage.removeItem("currentUser");
        localStorage.removeItem("job_id");
        localStorage.removeItem("tab");
        localStorage.setItem("currentSubscriptionId", JSON.stringify({}));
        // localStorage.clear();
        if (localData && localData._id) {
            this.services
                .logout(localData._id)
                .pipe()
                .subscribe((data) => {
                    if (data.code == 200) {
                        return;
                    } else {
                        this.toastr.error("error", data.message);
                        return;
                    }
                });
        }
    }

    createJob(content, header) {
        const initialState = {
            content: content,
            header: header,
        };

        this.bsModalRef = this.modalService.show(ModalComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            class: `modal-xxl modal-dialog-centered`,
        });

    }

    //ACCEPT JOB FUNCTION
    acceptJob() {
        if (!this.job_id) {
            this.toastr.error("error", "job id is required");
            return;
        }
        if (!this.driver_id) {
            this.toastr.error("error", "driver id is required");
            return;
        }
        this.services
            .acceptJobPharmacy(this.job_id, this.driver_id)
            .pipe()
            .subscribe((data) => {
                if (data.code == 200) {
                    this.primaryModal.hide();
                    this.toastr.success("sucess", "Job accepted successfully");
                    this.job_id = "";
                    this.driver_id = "";
                    this.router
                        .navigateByUrl("/changePassword", {
                            skipLocationChange: true,
                        })
                        .then(() => {
                            this.router.navigate(["jobs"]);
                        });
                    return;
                } else {
                    this.primaryModal.hide();
                    this.toastr.error("error", data.message);
                    this.job_id = "";
                    this.driver_id = "";
                    return;
                }
            });
    }

    //DECLINE JOB FUNCTION
    declineJob() {
        if (!this.job_id) {
            this.toastr.error("error", "job id is required");
            return;
        }
        if (!this.driver_id) {
            this.toastr.error("error", "driver id is required");
            return;
        }

        this.services
            .declineJobPharmacy(this.job_id, this.driver_id)
            .pipe()
            .subscribe((data) => {
                if (data.code == 200) {
                    this.primaryModal.hide();
                    this.toastr.success("sucess", "Job declined successfully");
                    this.job_id = "";
                    this.driver_id = "";
                    this.router
                        .navigateByUrl("/changePassword", {
                            skipLocationChange: true,
                        })
                        .then(() => {
                            this.router.navigate(["jobs"]);
                        });
                    return;
                } else {
                    this.primaryModal.hide();
                    this.toastr.error("error", data.message);
                    this.job_id = "";
                    this.driver_id = "";
                    return;
                }
            });
    }
    reload() {
        var localData2 = JSON.parse(localStorage.getItem("currentUser"));
        this.profile_img = localData2.profile_img;
    }

    profile_nav() {
        sessionStorage.setItem("profile_tab_index", "edit-profile");
        this.router.navigate(["profile"]);
    }

    //REDIRECT NOTIFICATION FUNCTION
    redirect(noti, content, header) {

        if (noti.notification_type == 18) {
            this.router
                .navigateByUrl("/changePassword", {
                    skipLocationChange: true,
                })
                .then(() => {
                    this.router.navigate(["jobs"]);
                });
            return;
        }
        if (noti.notification_type == 4) {
            this.router
                .navigateByUrl("/changePassword", {
                    skipLocationChange: true,
                })
                .then(() => {
                    this.router.navigate(["invoice"]);
                });
        } else {
            if (noti.job_id) {
                this.jobDetailsId = noti.job_id;
                const initialState = {
                    content: content,
                    header: header,
                };

                this.bsModalRef = this.modalService.show(ModalComponent, {
                    initialState,
                    animated: true,
                    backdrop: "static",
                    class: `modal-xxl modal-dialog-centered`,
                });
            }
        }
    }

    //READ NOTIFICATION FUNCTION
    readAllnotifiactions() {
        this.services
            .readAllNotification(this.user_id)
            .pipe()
            .subscribe((data) => {
                if (data.code == 400) {
                    this.spinner.hide();
                    this.toastr.error("", data.message);
                    this.logout();
                    return;
                }
                if (data.code == 200) {
                    this.notiCount = data.notiCount;
                    return;
                } else {
                    this.toastr.error("error", data.message);
                    return;
                }
            });
    }

    //PRIVACY URL FUNCTION
    privacyRedirect() {
        if (this.selectedLanguage == "fr") {
            window.location.href = "https://apiuser.vaistat.com/privacyFr";
        } else {
            window.location.href = "https://apiuser.vaistat.com/privacyEn";
        }
    }

    //COOKIE STATUS
    changeCookieStatus() {
        this.cookieStatus = true;
        localStorage.setItem("cookiesStatus", JSON.stringify("true"));
    }

    //GET SOCKET CONNECTION FUNCTION
    getSocketConnection() {
        return this.socket;
    }

    //UPDATE DATE FUNCTION START HERE
    updateDate() {
        localStorage.setItem("selected_date", JSON.stringify(""));
        localStorage.setItem("pageNumber", JSON.stringify("1"));
        localStorage.setItem("userSearch", JSON.stringify(""));
        localStorage.setItem("dateRangeSelected", JSON.stringify([]));
        localStorage.setItem("allJobsPaymentIds", JSON.stringify([]));
    }
}