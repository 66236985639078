import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  Router
} from '@angular/router';
import {
  NgbModal,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import {
  BsModalRef,
  BsModalService,
  ModalDirective,
} from 'ngx-bootstrap/modal';
import {
  ImageCroppedEvent
} from 'ngx-image-cropper';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  Subscription, timer
} from "rxjs";
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';

import { environment } from '../../../environments/environment';
import { LocationAgmComponent } from '../../shared/component/location-agm/location-agm.component';
import { ModalComponent } from "../../shared/component/modal/modal.component";
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})


export class EditProfileComponent implements OnInit {
  @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
  @ViewChild('otpModal') public otpModal: ModalDirective;
  @ViewChild('secretCodeTemplate') secretCodeTemplate: TemplateRef<any>;
  @ViewChild(LocationAgmComponent) locationAgmComponent: LocationAgmComponent;
  bsModalRef: BsModalRef;
  public dial_code: any;
  public options: any;
  imageChangedEvent: any = '';
  showEditView = false;
  croppedImage: any = '';
  croppedImg1: any;
  public modalData: any;
  user_id: any;
  pharmacy_name: any;
  apartment_suite: any;
  fax_country_code: any;
  fax_number: "";
  suite_number: any;
  public profile_img: any;
  pharmacy_location: any;
  pharmacy_location_new: any;
  phone_no: any;
  email: any;
  public location1: any;
  lat: any;
  long: any;
  phone_no_new: any;
  pharmacy_name_new: any;
  lat1: any;
  long1: any;
  buf: any;
  imageUrl: any;
  public country: any;
  public country_shortname: any;
  public state: any;
  public state_shortname: any;
  public city: any;
  public city_shortname: any;
  public cityStatus = false;
  public countryStatus = false;
  public stateStatus = false;
  public postalcode: any;
  public postal_codestatus = false;
  otp: any;
  timeLeft: number = 180;
  interval;
  countDown: Subscription;
  counter = 180;
  tick = 1000;
  pharmacy_landline_num = "";
  editProfileForm: UntypedFormGroup;
  submitted = false;
  secretCode = ''; // random generated secret code for pharmacy used by dispatch
  pharmacy_id = '';
  emailNotifications = true;
  business_hours = []; // business hours to display in the profile page

  editLocation = false;
  locationData = {}
  public handleAddressChange(address, modal: NgbModalRef) {

    this.cityStatus = false;
    this.stateStatus = false;
    this.countryStatus = false;
    this.postal_codestatus = false;
    this.location1 = address.formatted_address;
    this.f.pharmacy_location.setValue(this.location1);
    this.lat = address.geometry.location.lat();
    this.long = address.geometry.location.lng();
    var data;
    for (data of address.address_components) {
      if (data.types[0] == "locality") {
        this.city = data.long_name;
        this.city_shortname = data.short_name;
        this.cityStatus = true;
      }

      if (data.types[0] == "administrative_area_level_1") {
        this.state = data.long_name;
        this.state_shortname = data.short_name;
        this.stateStatus = true;
      }
      if (data.types[0] == "country") {
        this.country = data.long_name;
        this.country_shortname = data.short_name;
        this.countryStatus = true;
      }
      if (data.types[0] == "postal_code") {
        this.postalcode = data.long_name;
        this.postal_codestatus = true;
      }

    }

    this.locationData = {
      lat: this.lat,
      lng: this.long,
      address: this.location1,
    }
    this.bsModalRef.hide();
  }

  constructor(
    private modalService: NgbModal,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public reloadFunction: DefaultLayoutComponent,
    private formBuilder: UntypedFormBuilder,
    private bsModalService: BsModalService,

  ) {

  }
  @ViewChild('content') private content;
  // @ViewChild('ref_pharmacy_location') private refPharmacyLocation;

  ngOnInit() {
    this.spinner.show();
    //EDIT PROFILE FORM 
    this.secretCode = ''
    this.submitted = false
    this.editProfileForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      pharmacy_name: ['', [Validators.required]],
      mobile_no: [''],
      pharmacy_landline_num2: [''],
      fax_country_code: [''],
      fax_number2: ['', [Validators.required]],
      apartment_suite: [''],
      suite_number: [''],
      pharmacy_location: ['', Validators.required],
    });
    this.editProfileForm.disable();

    this.imageChangedEvent = "";
    this.content = "";
    this.croppedImage = "";
    this.croppedImg1 = "";

    var localData = JSON.parse(localStorage.getItem("currentUser"));
    this.user_id = localData._id;
    this.otp = "";

    this.showEditView = false;

    const language = JSON.parse(localStorage.getItem("language"));
    this.services.getPharmacyProfile(this.user_id, language).pipe().subscribe(data => {
      console.log('data:', data);
      if (data.code == 400) {
        this.spinner.hide();
        this.reloadFunction.logout();
        return;
      }
      if (data.code == 200) {
        localStorage.setItem('currentUser', JSON.stringify(data.result));
        this.setDataOfLocalStorage();
        this.emailNotifications = data.result.emailNotifications;

        this.pharmacy_id = data.result._id.toString();
        this.dial_code = data.result.country_code;

        this.pharmacy_name = data.result.pharmacy_name;
        this.profile_img = data.result.profile_img;
        this.pharmacy_location = data.result.location;
        this.location1 = data.result.location;
        this.phone_no = data.result.mobile_no;
        // this.email = data.result.email;
        this.lat = data.result.lat_long[0];
        this.long = data.result.lat_long[1];
        this.country = data.result.country.long_name;
        this.country_shortname = data.result.country.short_name;
        this.state = data.result.state.long_name;
        this.state_shortname = data.result.state.short_name;
        this.city = data.result.city.long_name;
        this.city_shortname = data.result.city.short_name;
        this.pharmacy_landline_num = data.result.pharmacy_landline_num;
        this.fax_country_code = data.result.fax_country_code;
        this.fax_number = data.result.fax_number;
        this.apartment_suite = data.result.apartment_suite;
        this.suite_number = data.result.suite_number;
        this.business_hours = data.weekday_text; // set business hours

        this.spinner.hide();

        this.editLocation = data.result.changeAddressEnabled;

        this.locationData = {
          lat: this.lat,
          lng: this.long,
          address: this.pharmacy_location,
        }
      } else {
        this.toastr.error('error', data.message);
        this.spinner.hide();
        return;

      }
      this.editProfileForm.disable();
    })




  }

  setDataOfLocalStorage() {
    var localData = JSON.parse(localStorage.getItem("currentUser"));
    this.user_id = localData._id;

    //TEMPLATE DRIVEN FORM 
    this.editProfileForm.controls['pharmacy_name'].setValue(localData.pharmacy_name);
    this.editProfileForm.controls['email'].setValue(localData.email);
    this.editProfileForm.controls['pharmacy_location'].setValue(localData.location);
    this.editProfileForm.controls['mobile_no'].setValue(localData.mobile_no);
    this.editProfileForm.controls['pharmacy_landline_num2'].setValue(localData.pharmacy_landline_num);
    this.editProfileForm.controls['fax_country_code'].setValue(localData.fax_country_code);
    this.editProfileForm.controls['fax_number2'].setValue(localData.fax_number);
    this.editProfileForm.controls['apartment_suite'].setValue(localData.apartment_suite);
    this.editProfileForm.controls['suite_number'].setValue(localData.suite_number);
  }

  //REFRSH THE COMPONENT
  refresh() {
    this.ngOnInit()

    this.modalService.dismissAll();
  }


  //FILE TO UPLOAD IMAGE
  fileChangeEvent(event: any): void {

    this.imageChangedEvent = event;

    if (event.target.files.length > 0) {
      this.open(this.content);
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob;

  }
  imageLoaded() {

    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    this.toastr.error('error', 'Image not valid');
    this.modalService.dismissAll();


    return;
    // show message
  }
  open(content) {
    const initialState = {
      content: content,
      config: {
        title: "Crop",


      },
      onClick: this.uploadFile.bind(this),

    };
    this.bsModalRef = this.bsModalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-md modal-dialog-centered",
    });

  }

  get f() {
    return this.editProfileForm.controls;
  }

  // FUNCTION TO EDIT DISPATCH PROFILE
  editDispatchProfile(type) {
    if (type == '1') {
      this.showEditView = true;
      this.editProfileForm.enable();
      this.editProfileForm.enable();
      this.editProfileForm.controls['email'].disable();
      if (this.editLocation == false) {
        this.editProfileForm.controls['pharmacy_location'].disable();
      }
    } else {
      this.showEditView = false;
      this.editProfileForm.disable();
      this.ngOnInit();
    }
  }



  //EDIT PROFILE FUNCTION
  edit_profile() {
    this.submitted = true;
    this.otp = "";

    if (!this.f.pharmacy_name.value.trim()) {
      this.toastr.error('', 'please enter pharmacy name');
      return;
    } else if (!this.f.pharmacy_landline_num2.value || this.f.pharmacy_landline_num2.value.trim() === "") {
      this.toastr.error('', 'please enter Landline Number');
      return;

    } else if (!this.f.pharmacy_location.value.trim()) {
      this.toastr.error('', 'please enter location');
      return;
    }


    if (!this.f.mobile_no.value.trim()) {
      this.toastr.error('', 'Please enter phone no');
      return;
    }

    if (!this.f.fax_country_code.value.trim()) {
      this.toastr.error('', 'Please enter Fax country code');
      return;
    } else if (this.f.fax_country_code.value.trim().length !== 3) {
      this.toastr.error('', 'Please enter a valid fax country code (three digits)');
      return;
    }


    if (!this.f.fax_number2.value.trim()) {
      this.toastr.error('', 'please enter fax number');
      return;
    }

    // if (!this.f.apartment_suite.value.trim()) {
    //   this.toastr.error('', 'please enter pharmacy suite');
    //   return;
    // }

    // if (this.f.suite_number.value.trim() === "") {
    //   this.toastr.error('', 'please enter suite number');
    //   return;
    // }


    if (this.editProfileForm.invalid) {
      return;
    }

    if (this.countryStatus == true && this.stateStatus == false && this.cityStatus == false && this.postal_codestatus == false) {
      this.state_shortname = "";
      this.state = "";
      this.city_shortname = "";
      this.city = "";
      this.postalcode = "";
    }
    if (this.countryStatus == true && this.stateStatus == true && this.cityStatus == false && this.postal_codestatus == false) {
      this.city_shortname = "";
      this.city = "";
      this.postalcode = "";
    }
    if (this.countryStatus == true && this.stateStatus == true && this.cityStatus == true && this.postal_codestatus == false) {
      this.postalcode = "";
    }

    if (!this.location1) {
      this.toastr.error('', 'please enter location');
      return;
    }
    if (!this.pharmacy_location || this.pharmacy_location.trim() === "") {
      this.toastr.error('', 'please enter location');
      return;

    }



    this.spinner.show();


    var userDetails = JSON.parse(localStorage.getItem("currentUser"));

    if (userDetails.mobile_no != this.f.mobile_no.value) {
      this.services.sendOtpEditProfile(this.user_id, this.f.mobile_no.value).pipe().subscribe(data => {
        if (data.code == 200) {
          this.otpModal.show();
          this.spinner.hide();
          if (this.countDown) {
            this.countDown.unsubscribe();
          }
          this.counter = 180;
          this.countDown = timer(0, this.tick).subscribe(() => {
            if (this.counter > 0) {
              --this.counter;
            } else {
              this.counter = 0;
              if (this.countDown) {
                this.countDown.unsubscribe();
              }
            }
          });
          return false;
        } else {
          this.toastr.error('error', data.message);
          this.spinner.hide();
          return;
        }
      });

    } else {

      this.services.editProfile(this.user_id, this.f.pharmacy_name.value, this.location1, [this.lat, this.long], this.f.mobile_no.value, this.country_shortname, this.country, this.state_shortname, this.state, this.city_shortname, this.city, this.postalcode, this.f.pharmacy_landline_num2.value, this.f.fax_country_code.value, this.f.fax_number2.value, this.f.apartment_suite.value, this.f.suite_number.value).pipe().subscribe(data => {
        if (data.code == 400) {
          this.spinner.hide();
          this.toastr.error('', data.message);
          this.reloadFunction.logout();
          return;
        }
        if (data.code == 200) {
          this.toastr.success('success', 'Edit Profile Successfully');
          localStorage.setItem('currentUser', JSON.stringify(data.result));
          this.ngOnInit();
          this.spinner.hide();
          return;

        } else {
          this.toastr.error('error', data.message);
          this.spinner.hide();
          return;
        }
      });
    }
  }

  //VERIFY OTP FUNCTION 
  verifyOtp() {
    if (!this.otp) {
      this.toastr.error('', "Enter otp");
      return;
    }
    this.spinner.show();
    this.services.verifyPhoneNumberOnly(this.user_id, this.otp, this.f.mobile_no.value).pipe().subscribe(data => {
      if (data.code == 200) {
        this.otpModal.hide();
        this.toastr.success('', 'Success');
        localStorage.setItem('currentUser', JSON.stringify(data.result));
        this.ngOnInit();
        this.spinner.hide();
        return;


      } else {
        this.toastr.error('error', data.message);
        this.spinner.hide();
        return;
      }
    });
  }


  //UPLOAD IMAGE FUNCTION 
  async uploadFile() {

    this.spinner.show();
    var blob = this.croppedImage;

    if (!blob) {
      this.toastr.error('error', "image not found");
      this.spinner.hide();
      return;
    }

    const file = new File([blob], `cropped-image.jpg`, { type: blob.type });

    try {
      const uploadData = await this.services.uploadFile({ file, _id: this.user_id, fileName: 'profile-image', containerName: environment.AZURE_STORAGE_PHARMACY_CONTAINER_NAME }).toPromise();
      this.imageUrl = uploadData?.url;


      this.services.imageUpload(this.user_id, this.imageUrl).pipe().subscribe(data => {
        if (data.code == 400) {
          this.spinner.hide();
          this.toastr.error('', data.message);
          this.reloadFunction.logout();
          return;
        }
        if (data.code == 200) {
          localStorage.setItem('currentUser', JSON.stringify(data.result));
          this.profile_img = data.result.profile_img + '?time=' + new Date().getTime();

          this.bsModalRef.hide();

          this.toastr.success('success', 'Image changed Successfully');
          this.spinner.hide();

          return;

        } else {
          this.toastr.error('error', data.message);
          this.spinner.hide();
          return;

        }

      });
    } catch (err) {
      this.spinner.hide();
      this.toastr.error('error', err.message);
    }
  }


  //CHANGE MOBILE NUMBER PATTER FUNCTION
  mychange(val) {
    const self = this;
    let chIbn = val.split('-').join('');
    if (chIbn.length > 0) {
      // chIbn = chIbn.match(new RegExp('.{1,3}', 'g')).join('-');
      chIbn = chIbn.match(/\d{3}(?=\d{2,3})|\d+/g).join("-");
    }
    this.f.mobile_no = chIbn;
    // this.jobForm2.controls['customer_phone2'].setValue(this.iban_no);
  }

  //CHANGE LANDLINE NUM PATTERN 2
  mychange1(val) {
    const self = this;
    let chIbn = val.split('-').join('');
    if (chIbn.length > 0) {
      chIbn = chIbn.match(/\d{3}(?=\d{2,3})|\d+/g).join("-");
    }
    this.f.pharmacy_landline_num2 = chIbn;


    // this.jobForm2.controls['customer_phone2'].setValue(this.iban_no);
  }

  //ON DESTROY INTERVAL
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
  }

  //RESEND OTP FUNCTION 
  resendOtp() {
    this.spinner.show();
    this.services.sendOtpEditProfile(this.user_id, this.f.mobile_no.value).pipe().subscribe(data => {
      if (data.code == 200) {
        this.spinner.hide();
        this.toastr.success('success', 'Otp sent successfully');
        return false;
      } else {
        this.toastr.error('error', data.message);
        this.spinner.hide();
        return;
      }
    });
  }

  generateCode(length) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }
  async copyCode() {
    try {
      await navigator.clipboard.writeText(this.secretCode);
      this.toastr.success('', 'Code copied to clipboard');
    } catch (error) {
      console.error('Failed to copy code to clipboard:', error);
    }
  }
  openSecretCodeModal() {
    this.spinner.show();
    this.secretCode = this.generateCode(10);
    this.services.updateSecretCode(this.user_id, this.secretCode).pipe().subscribe(data => {
      if (data.code == 200) {
        this.spinner.hide();
        const initialState = {
          content: this.secretCodeTemplate,
          hideActionBtn: true,
          cancelImageSrc: null,
          config: {
            title: "EDITPROFILE.secret_code",
            cancel: "Ok",
          },
        };
        this.bsModalRef = this.bsModalService.show(ModalComponent, {
          initialState,
          animated: true,
          backdrop: "static",
          class: "modal-sd modal-dialog-centered",
        });
        return;
      } else {
        this.toastr.error('error', data.message);
        this.spinner.hide();
        return;

      }
    })


  }

  onChangeEmailNotification(event) {
    this.spinner.show();
    this.services.updateNotificationStatus(this.user_id, event).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        // this.toastr.error('', data.message);
        this.reloadFunction.logout();
        return;

      }
      if (data.code == 200) {
        // this.emailNotifications = data.result.emailNotifications;
        localStorage.setItem('currentUser', JSON.stringify(data.result));
        this.ngOnInit();
        // this.spinner.hide();
        return;

      } else {
        this.toastr.error('error', data.message);
        this.spinner.hide();
        return;

      }
    })
  }

  openModalSelectLocation(content, header) {
    const initialState = {
      content: content,
      header: header


    };
    this.bsModalRef = this.bsModalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-xl modal-dialog-centered",
    });
  }

  private formatHoursText(hours_text) {
    if (hours_text.toLowerCase() === "closed") {
      return hours_text;
    } else {
      const parts = hours_text.split(" – ");
      const from = parts[0];
      const to = parts[1];
      return {
        from: from,
        to: to
      };
    }
  }

  private formatBusinessHours(weekday_text: []) {
    let formatted_business_hours = {};
    if (weekday_text) {
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      weekday_text.forEach((day_text: string) => {
        daysOfWeek.forEach((day) => {
          if (day_text.includes(`${day}:`)) {
            formatted_business_hours[day.toLowerCase().substring(0, 3)] = this.formatHoursText(day_text.split(`${day}:`)[1].trim());
          }
        });
      });
    }
    return formatted_business_hours;
  }

}