import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  NgxSpinnerService
} from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../../../services.service';

@Component({
  selector: 'app-convert-recursive-job-to-v2-modal',
  templateUrl: './convert-recursive-job-to-v2-modal.component.html',
  styleUrls: ['./convert-recursive-job-to-v2-modal.component.scss']
})
export class ConvertRecursiveJobToV2ModalComponent implements OnInit {

  @Input() item: any;
  @Input() modal: NgbModalRef;
  selectedOption: boolean = false;
  @ViewChild('dynamicContentTemplate', { static: false }) dynamicContentTemplate: TemplateRef<any>;

  constructor(
    private services: ServicesService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
  }

  convert() {
    this.spinner.show();
    var localData = JSON.parse(localStorage.getItem("currentUser"));
    const DATA = {
      "job_id": this.item._id,
      "user_id": localData._id,
      "continuous": this.selectedOption
    }
    this.services.convertJobRecursiveToV2(DATA).pipe().subscribe(data => {
      if (data.code == 200) {
        this.toastr.success('Job recursive convert successfully');
        this.spinner.hide();
        this.modal.close(true)
        return;
      } else {
        this.spinner.hide();
        this.toastr.error('error', data?.message);
        return;
      }
    })
  }
}