<a
  class="job-link"
  href="#"
  (click)="
    showJobModel(jobDetailsBodyTemplate, jobDetailsHeaderTemplate, $event)
  "
  style="font-size: 14px; font-weight: 400; cursor: pointer"
>
  - {{ job.customer_f_name || "" }} {{ job.customer_l_name || "" }}</a
><br />

<ng-template #jobDetailsHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3">
    <ng-container
      *ngTemplateOutlet="jobDetailsComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #jobDetailsBodyTemplate>
  <app-job-details [job_id]="job._id"></app-job-details>
</ng-template>
