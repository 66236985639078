<link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
<div class="card mt-3">
    <div class="card-body">
        <div class="row">
            <div class="col-lg-4">
              <div class="scp-breadcrumb">
                <ul class="breadcrumb">

                    <li class="back-btn-arrow" style="cursor: pointer;"><a (click)="back()" class="back-arrow"><i
                        class="fa fa-arrow-left mr-2" aria-hidden="true" style="color:#3EAA79; margin-top: 0;"></i>Back</a>
                   </li>

                    <li class="b-jobs"><a>{{ 'REPORT.expenses_job' | translate }}</a></li>
                </ul>
            </div>
            </div>
            <div class="col-lg-4">
              <ul class="custom-calender-datepickers">
                <li><div class="calender-custom">
                  <input #dpFromDate  ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="true"  [displayMonths]="1" [dayTemplate]="t" outsideDays="hidden" [readonly]="true" tabindex="-1" class="form-control point" placeholder="YYYY/MM/DD" [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)" (click)="datepicker.toggle()" [minDate]="{year: 1900, month: 1, day: 1}" (dateSelect)="onDateSelection($event)" firstDayOfWeek="7">
                 
                </div></li>
                <li> <div class="datepicker-cal-icon"><i class="fa fa-calendar point" (click)="datepicker.toggle()" aria-hidden="true"></i></div></li>
                <li>
                  <div class="calender-custom">
                    <input #dpToDate name="datepicker" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="true"  [displayMonths]="1" [dayTemplate]="t" outsideDays="hidden" [readonly]="true" tabindex="-1" class="form-control point" placeholder="YYYY/MM/DD" [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)" [minDate]="{year: 1900, month: 1, day: 1}" (click)="datepicker.toggle()" (dateSelect)="onDateSelection($event)" firstDayOfWeek="7">
                    <ng-template #t let-date let-focused="focused">
                      <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                      </span>
                    </ng-template>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 text-right">
              <a class="download-pdf point" (click)="getPdf()"><i class="fa fa-download" aria-hidden="true"></i></a>
            </div>
            <!-- <div class="col-lg-4">
             <form class="search-box-custom">
              <input type="text" name="search" placeholder="{{ 'JOBS.search_customer' | translate }}"
                class="animated-search" (ngModelChange)="searchJobs($event)" [ngModel]="searchEvent"
                [ngModelOptions]="{standalone: true}">
            </form>
            </div> -->
            <!-- <div class="col-lg-2">
              <button type="button" class="btn btn-success restBtn"
                (click)="reset_page()"><strong>{{ 'JOBS.reset' | translate }}</strong></button>
            </div> -->
            </div>

            <div class="row">
                <div class="table-responsive">
                  <table class="table table-borderless jobs-table">
                      <thead>
                          <tr>
                            <th>{{'JOBS.job_id' | translate }}</th>
                            <th>{{'JOBS.client_name' | translate }}</th>
                            <th>{{'JOBS.address' | translate }}</th>
                            <!-- <th>{{'JOBS.initial' | translate }}</th> -->
                            <th>{{'JOBS.due_date' | translate }}</th>
                            <th>Ride fare</th>
                           
                            <th>{{'JOBS.driver_name' | translate }}</th>
                          </tr>
                      </thead>
                        <tbody>
                          <tr
                            *ngFor="let completedJob of allCompletedJobs | paginate: { itemsPerPage: 10, currentPage: pageNumber,totalItems:allCompletedJobsCount }" (click)="jobDetails(completedJob.job_id._id)">
                            <td>{{completedJob.job_id.booking_code}}</td>
              
                            <td *ngIf="completedJob.job_id.customer_type == '2';else notDeliverElse">
                              {{completedJob.job_id.customer_f_name ? completedJob.job_id.customer_f_name +' '+ completedJob.job_id.customer_l_name  : completedJob.job_id.customer_name}}
                            </td>
                            <ng-template #notDeliverElse>
                              <td>
                                <span>{{completedJob.job_id.establishment_name ? completedJob.job_id.establishment_name : completedJob.job_id.customer_name}}</span>
                              </td>
                            </ng-template>
              
                            <td *ngIf="completedJob.job_id.job_type == '1'">{{sortAddress(completedJob.job_id.drop_off_location)}}</td>

                            <td *ngIf="completedJob.job_id.job_type == '2'">{{sortAddress(completedJob.job_id.pick_up_location)}}</td>
              
                            <!-- <td *ngIf='completedJob.job_id.job_create_initials; else initialsElse'>
                              {{completedJob.job_id.job_create_initials}}</td>
                            <ng-template #initialsElse>
                              <td>N/A</td>
                            </ng-template> -->
              
                            <td>{{modifyDate(completedJob.job_id.job_date)}}</td>
              
                            <td><span class="amount">${{completedJob.job_id.ride_fare | number : '1.2-2'}}</span>
                             
                            </td>    

                            <td *ngIf="completedJob.job_id.driver_id; else driverElse">{{completedJob.job_id.driver_id.username}}</td>
                            <ng-template #driverElse>
                              <td>N/A</td>
                            </ng-template>
              
                          </tr>
                        </tbody>
                  </table>
                  <div class="no-jobs-found"><p class="no-item text-center text-danger mb-0" *ngIf="!allCompletedJobs.length">{{'JOBS.no_jobs_found' | translate}}
                  </p></div> 
              </div>
              </div>
              <div class="row f-right">
                <pagination-controls *ngIf="allCompletedJobs.length" (pageChange)="onPageChange($event)"></pagination-controls>
              </div>
    </div>
</div>


