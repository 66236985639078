import {
    Component, enableProdMode
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {
    Router
} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    TranslateService
} from '@ngx-translate/core';
import {
    NgxSpinnerService
} from "ngx-spinner";
import {
    ToastrService
} from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import {
    AuthService
} from '../../auth.service';
import { PageModalLoginComponent } from '../../core/pages/page-modal-login/page-modal-login.component';
import { MessagingService } from '../../messaging.service';
import { PusherServiceService } from '../../pusher-service.service';
import { ServicesService } from '../../services.service';
import { ParameterService } from '../../services/admin-api/parameter.service';

enableProdMode();

@Component({
    selector: 'app-dashboard',
    templateUrl: 'login.component.html',
})
export class LoginComponent {

    loginForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    public selectedLanguage = "en";
    cookieStatus = true;
    public Time: any;
    firebaseToken;
    isLogin = false;

    constructor(
        public router: Router,
        private formBuilder: UntypedFormBuilder,
        public toastr: ToastrService,
        private authServices: AuthService,
        private spinner: NgxSpinnerService,
        public ts: TranslateService,
        private modalService: NgbModal,
        private services: ServicesService,
        private parameterService: ParameterService,

        private pusher: PusherServiceService,
        private messagingService: MessagingService
    ) {
        ts.addLangs(['en', 'fr']);
        ts.setDefaultLang('en');
        const browserLang = ts.getBrowserLang();
        ts.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    }

    ngOnInit() {
        this.cookieStatus = JSON.parse(localStorage.getItem("cookiesStatus")) ? JSON.parse(localStorage.getItem("cookiesStatus")) : false;

        var lang = JSON.parse(localStorage.getItem("language"));
        if (!lang) {
            lang = "en"
        }
        this.selectedLanguage = lang;
        localStorage.setItem('language', JSON.stringify(lang));
        this.ts.use(lang);

        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
        // setTimeout(() => {
        //     this.openModal();

        // }, 4000)
        console.log('ENTER IN LOGIN SECTION');
        //CONDITION TO CHECK PHARMACY LOGIN FROM ADMIN SIDE:
        var url = window.location.href
        var myurl = url.split('%')
        if (myurl.length > 1) {
            var myurl2 = myurl[1].split('=')
            var myurl3 = myurl2[0].split('F')
            this.Time = myurl3[1];
            var token = myurl[0].split('?')
            var jwtToken = token[1];
            // Do your operations
            var endDate = new Date();
            var seconds = (endDate.getTime() - this.Time) / 1000;
            localStorage.removeItem("currentUser");
            if (seconds <= 35) {
                this.spinner.show();
                this.authServices.pharmacyLoginByAdmin(jwtToken).pipe().subscribe(data => {
                    console.log(data, 'this dataaaaaaaa');
                    if (data.code == 200) {
                        localStorage.setItem('currentUser', JSON.stringify(data.result));
                        this.router.navigate(['dashboard']);
                        this.spinner.hide();
                        this.toastr.success('success', 'Login Successfully')
                    } else {
                        // console.log(data.message,'errorrrrrr');
                        this.toastr.error('', data.message)
                        localStorage.removeItem("currentUser");
                        this.spinner.hide();
                        return;
                    }
                });
            } else {
                localStorage.removeItem("currentUser");
                return console.log('SESSION EXPIRED!!!!!!');
            }
        } else {
            if (this.authServices.isLoggedIn()) {
                this.router.navigate(['/dashboard']);

            }
        }
        this.getFirebaseToken();
        this.getDefaultParameter("time-for-urgent-jobs");
        this.getDefaultParameter("num-jobs-recursives");
    }

    async getDefaultParameter(settingName: string) {
        await this.parameterService.getOneParameter(settingName).pipe().subscribe(response => {
            localStorage.setItem(settingName, JSON.stringify(response.parameter));

        });
    }

    //FUNCTION TO GET FIREBASE TOKEN 
    async getFirebaseToken() {
        this.firebaseToken = await this.messagingService.requestPermission()
        // this.messagingService.receiveMessage();
        console.log("firebaseToken", this.firebaseToken);
    }

    get f() {
        return this.loginForm.controls;
    }

    onSubmit() {
        console.log('onSubmit')
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        let devices = this.firebaseToken ? [{ 'device_type': 'W', 'device_token': this.firebaseToken }] : [];
        this.login(devices);
        // this.loading = true;
        // this.authServices.login(this.f.email.value, this.f.password.value, devices).pipe().subscribe(data => {
        //     if (data.code == 200) {
        //         localStorage.setItem('currentUser', JSON.stringify(data.result));
        //         localStorage.setItem('currentSubscriptionId', JSON.stringify({}));
        //         if (data.result.email_verified == false || data.result.mobile_verified == false) {
        //             this.router.navigate(['verification']);
        //         } else {
        //             this.router.navigate(['dashboard']);
        //             this.spinner.hide();
        //             this.toastr.success('success', 'Login Successfully')
        //         }

        //     } else if (data.code == 100) {
        //         this.toastr.success('', data.message);
        //         this.spinner.hide();
        //         return;

        //     } else {
        //         this.toastr.error('', data.message);
        //         this.spinner.hide();
        //         return;
        //     }
        // }, (err) => {
        //     // this.toastr.error('', err.message);
        //     if (err.status === 400) {
        //         this.openModal();

        //     }
        //     this.spinner.hide();

        // });
    }
    login(devices: any, logoutDevices = null) {
        this.spinner.show();

        this.authServices.login(this.f.email.value, this.f.password.value, devices, logoutDevices).pipe().subscribe(data => {
            if (data.code == 200) {
                localStorage.setItem('currentUser', JSON.stringify(data.result));
                localStorage.setItem('currentSubscriptionId', JSON.stringify({}));
                this.eventNew(data.result);
                if (data.result.email_verified == false || data.result.mobile_verified == false) {
                    this.router.navigate(['verification']);
                } else {


                    this.router.navigate(['dashboard']);
                    this.spinner.hide();
                    this.toastr.success('success', 'Login Successfully')



                }

            } else if (data.code == 100) {
                this.toastr.success('', data.message);
                this.spinner.hide();
                return;

            } else {
                console.log(data.message);
                this.toastr.error('', data.message);
                this.spinner.hide();
                return;
            }
        }, (err) => {
            // this.toastr.error('', err.message);
            if (err.status === 400) {
                this.openModal();

            }
            this.spinner.hide();

        });
    }

    openModal() {
        this.modalService.open(PageModalLoginComponent, { centered: true, size: 'sm', backdrop: 'static' }).result.then(
            (result) => {
                console.log(`Closed with: ${result}`);
                let devices = this.firebaseToken ? [{ 'device_type': 'W', 'device_token': this.firebaseToken }] : [];
                this.login(devices, true);
            },
            (reason) => {
                // console.log(`Dismissed ${reason}`);
            },
        );

    }
    eventNew(data: any) {
        const { _id } = data;
        console.log({ _id });
        const jobChannel = this.pusher.init(environment.CHANNEL_USER) as unknown as { bind?: any };
        if (jobChannel.bind) {
            jobChannel.bind(`id-${_id}`, (data) => {
                this.spinner.show();
                console.log({ data });
                console.log(`LOGOUT`);
                this.logout()
            });
        }
    }

    register() {
        this.router.navigate(['register'])
    }
    logout() {
        this.toastr.warning("", `There is an active session`);
        this.spinner.hide();
        localStorage.removeItem("DispatchUser");
        this.router.navigateByUrl("/login");
        let localData = JSON.parse(localStorage.getItem("currentUser"));
        this.router.navigate(["login"]);
        localStorage.removeItem("currentUser");
        localStorage.removeItem("job_id");
        localStorage.removeItem("tab");
        localStorage.setItem("currentSubscriptionId", JSON.stringify({}));
        // localStorage.clear();
        if (localData && localData._id) {
            this.services
                .logout(localData._id)
                .pipe()
                .subscribe((data) => {
                    if (data.code == 200) {
                        return;
                    } else {
                        this.toastr.error("error", data.message);
                        return;
                    }
                });
        }
    }

    switchLang(lang: string) {
        this.spinner.show();
        localStorage.setItem('language', JSON.stringify(lang));
        this.ts.use(lang);
        this.selectedLanguage = lang;
        this.spinner.hide();
    }

    changeCookieStatus() {
        this.cookieStatus = true;
        localStorage.setItem('cookiesStatus', JSON.stringify('true'));
    }

}