import {
    Component,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobsListComponent } from '../jobs-list/jobs-list.component';

const FileSaver = require('file-saver');

@Component({
    selector: 'app-recursive-jobs',
    templateUrl: './recursive-jobs.component.html',
    styleUrls: ['./recursive-jobs.component.scss']
})
export class RecursiveJobsComponent implements OnInit {
    @ViewChild(JobsListComponent) jobsListComponent: JobsListComponent;
    dynamicContentTemplate: TemplateRef<any>;

    constructor(

    ) {

    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.dynamicContentTemplate = this.jobsListComponent.dynamicContentTemplate;

    }
}