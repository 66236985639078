import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Subscription, timer } from "rxjs";
import { AuthService } from '../../auth.service';
import { ServicesService } from '../../services.service';


@Component({
  selector: 'app-verify-pharmacy',
  templateUrl: './verify-pharmacy.component.html',
  styleUrls: ['./verify-pharmacy.component.scss']
})
export class VerifyPharmacyComponent implements OnInit {

  public iban_no: any;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;

  CountryISO = CountryISO;
  // preferredCountries: CountryISO[] = [CountryISO.Canada, CountryISO.India];
  preferredCountries = []
  public phone: any;

  // changePreferredCountries() {
  //   this.preferredCountries = [];
  // }

  @ViewChild('editEmailModal') public editEmailModal: ModalDirective;
  @ViewChild('editPhoneModal') public editPhoneModal: ModalDirective;
  public selectedLanguage = "en";
  verifyForm: UntypedFormGroup;
  phoneForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  timeLeft: number = 180;
  interval;
  countDown: Subscription;
  counter = 180;
  tick = 1000;
  userPhoneNumber: any;
  userEmail: any;
  email_for_edit: any;
  phone_for_edit: any;
  cookieStatus = true;
  user_id: any;
  onlyPhoneNumber: any;
  public country_code: "+1";
  phonetext = "";
  country_flag_name = "ca";

  constructor(private formBuilder: UntypedFormBuilder,
    public toastr: ToastrService,
    private authServices: AuthService,
    private http: HttpClient,
    public router: Router,
    private spinner: NgxSpinnerService,
    public ts: TranslateService,
    private services: ServicesService
  ) {
    ts.addLangs(['en', 'fr']);
    ts.setDefaultLang('en');

    const browserLang = ts.getBrowserLang();
    ts.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  ngOnInit() {


    this.cookieStatus = JSON.parse(localStorage.getItem("cookiesStatus")) ? JSON.parse(localStorage.getItem("cookiesStatus")) : false;

    this.verifyForm = this.formBuilder.group({
      email_otp: ['', [Validators.required]],
      phone_otp: ['', Validators.required]
    });

    this.phoneForm = new UntypedFormGroup({
      phone: new UntypedFormControl("", [Validators.required])
    });



    var localData = JSON.parse(localStorage.getItem("currentUser"));
    if (localData) {
      this.user_id = localData._id;
      var lang = JSON.parse(localStorage.getItem("language"));
      if (!lang) {
        lang = "en"
      }
      // if(localData.country_code =="+91"){
      //   this.preferredCountries = [CountryISO.India,CountryISO.Canada]
      // }else{
      //   this.preferredCountries = [CountryISO.Canada,CountryISO.India]

      // }
      if (localData.country_flag_name) {
        this.country_flag_name = localData.country_flag_name;
      }

      this.preferredCountries.push(this.country_flag_name);

      this.userPhoneNumber = localData.country_code + ' ' + localData.mobile_no;
      this.phonetext = localData.mobile_no;
      this.userEmail = localData.email;
      this.email_for_edit = localData.email;
      this.selectedLanguage = lang;
      localStorage.setItem('language', JSON.stringify(lang));
      this.ts.use(lang);
      this.spinner.hide();
      // if (this.authServices.isLoggedIn()) {
      //   // this.router.navigate(['/dashboard']);
      //   this.router.navigate(['/sign-contract']);
      // }
      // this.startTimer();

    } else {
      this.router.navigate(['login']);
      return false;
    }

    this.countDown = timer(0, this.tick).subscribe(() => {
      console.log("Timer")
      if (this.counter > 0) {
        --this.counter;
      } else {
        this.counter = 0;
        if (this.countDown) {
          this.countDown.unsubscribe();
        }

        // this.countDown=null;
      }

    });

  }



  get f() { return this.verifyForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.verifyForm.invalid) {
      return;
    }
    var localData = JSON.parse(localStorage.getItem("currentUser"));
    if (localData) {
      this.spinner.show();
      this.services.verifyEmailPhoneOtp(localData._id, this.f.phone_otp.value, this.f.email_otp.value).pipe().subscribe(data => {
        if (data.code == 200) {
          if (data.result.isVerified == true) {
            localStorage.setItem('currentUser', JSON.stringify(data.result));
            this.router.navigate(['dashboard']);
            // this.router.navigate(['/sign-contract']);
            this.spinner.hide();
            this.toastr.success('success', 'Login Successfully')
          } else {
            this.router.navigate(['login']);
            localStorage.removeItem("currentUser");
            this.spinner.hide();
            this.toastr.success('', 'Your account is under review, Please contact at support@vaistat.com')
          }

        } else {
          this.toastr.error('', data.message);
          this.spinner.hide();
          return;
        }
      })

    } else {
      this.toastr.error('error', "Something wrong");
      this.spinner.hide();
      return;
    }


  }
  resendEmailOtp() {
    var localData = JSON.parse(localStorage.getItem("currentUser"));
    if (localData) {
      this.spinner.show();
      this.services.resendEmailOtp(localData._id).pipe().subscribe(data => {
        if (data.code == 200) {
          this.spinner.hide();
          this.toastr.success('success', 'Otp sent on your registered email');
          return;

        } else {
          this.toastr.error('error', data.message);
          this.spinner.hide();
          return;
        }
      })

    } else {
      this.toastr.error('error', "Something wrong");
      this.spinner.hide();
      return;
    }


  }

  resendPhoneOtp() {
    var localData = JSON.parse(localStorage.getItem("currentUser"));
    if (localData) {
      this.spinner.show();
      this.services.resendPhoneOtp(localData._id).pipe().subscribe(data => {
        if (data.code == 200) {
          this.spinner.hide();
          this.toastr.success('success', 'Otp sent on your registered phone number');
          return;

        } else {
          this.toastr.error('error', data.message);
          this.spinner.hide();
          return;

        }
      })

    } else {
      this.toastr.error('error', "Something wrong");
      this.spinner.hide();
      return;
    }

  }
  // startTimer() {
  //   this.interval = setInterval(() => {
  //     console.log("TImer interval")
  //     if(this.timeLeft > 0) {
  //       this.timeLeft--;
  //     } else {
  //       // this.timeLeft = 60;
  //       clearInterval(this.interval);
  //     }
  //   },1000)
  // }

  switchLang(lang: string) {
    this.spinner.show();
    localStorage.setItem('language', JSON.stringify(lang));
    this.ts.use(lang);
    this.selectedLanguage = lang;
    this.spinner.hide();
    // this.navItems = [];
  }

  changeCookieStatus() {
    this.cookieStatus = true;
    localStorage.setItem('cookiesStatus', JSON.stringify('true'));

  }
  openEmailModal() {
    this.email_for_edit = this.userEmail;
    this.editEmailModal.show();
  }
  changeEmail() {
    var localData = JSON.parse(localStorage.getItem("currentUser"));
    if (localData && this.user_id) {

      if (!this.email_for_edit) {
        this.toastr.error('error', "Enter email");
        return;
      }
      if (this.email_for_edit) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var mail = re.test(String(this.email_for_edit).toLowerCase());
        if (mail == false) {
          this.toastr.error('', 'Email not valid');
          return;
        }
      }
      this.spinner.show();
      this.services.editEmailVerification(this.user_id, this.email_for_edit).pipe().subscribe(data => {
        if (data.code == 200) {
          localStorage.setItem('currentUser', JSON.stringify(data.result));
          this.editEmailModal.hide();
          this.spinner.hide();
          this.toastr.success('success', 'Verification code sent on email');
          this.router.navigateByUrl('/login', { skipLocationChange: true }).then(() => {
            this.router.navigate(['verification']);
          });
          // this.ngOnInit();
          return;

        } else {
          this.editEmailModal.hide();
          this.toastr.error('error', data.message);
          this.spinner.hide();
          return;
        }
      })
    } else {
      this.editEmailModal.hide();
      this.toastr.error('error', "Something wrong");
      this.spinner.hide();
      return;

    }
  }

  openPhoneModal() {
    var localData = JSON.parse(localStorage.getItem("currentUser"));
    console.log("localData.mobile_no:", localData.mobile_no)
    this.phonetext = localData.mobile_no;
    // this.phoneForm.controls['phone'].setValue(localData.mobile_no);
    this.editPhoneModal.show();
  }

  cancelPhoneModal() {
    this.editPhoneModal.hide();
  }
  changePhone() {
    console.log("changePhone", this.iban_no, this.country_code)

    var localData = JSON.parse(localStorage.getItem("currentUser"));
    if (localData && this.user_id) {
      if (!this.country_code) {
        this.toastr.error("", "Please select country code")
        return;

      }
      if (!this.phonetext) {
        this.toastr.error("", "Please Enter Phone Number")
        return;
      }
      if (this.phonetext.length != 12) {
        this.toastr.error('', 'Phone Number must 10 digits!');
        return;
      }
      this.spinner.show();
      this.services.editPhoneVerifivation(this.user_id, this.phonetext, this.country_code, this.country_flag_name.toLowerCase()).pipe().subscribe(data => {
        if (data.code == 200) {
          localStorage.setItem('currentUser', JSON.stringify(data.result));
          this.editPhoneModal.hide();
          this.spinner.hide();
          this.toastr.success('success', 'Verification code sent on phone number');
          this.router.navigateByUrl('/login', { skipLocationChange: true }).then(() => {
            this.router.navigate(['verification']);
          });

          return;
        } else {
          this.editPhoneModal.hide();
          this.toastr.error('error', data.message);
          this.spinner.hide();
          return;
        }
      })


    } else {
      this.editPhoneModal.hide();
      this.toastr.error('error', "Something wrong");
      this.spinner.hide();
      return;
    }


  }


  onChange1(val) {
    console.log(val, "valOnChange");

    if (val) {
      this.country_code = val.dialCode;
      this.country_flag_name = val.countryCode;


      console.log("this.country_code:", this.country_code)
      // var num = val.replace(/ /g,'');
      if (val.number) {
        var num = val.number.replace(/ /g, '');
      } else {
        var num = val.replace(/ /g, '');
      }
      // console.log(typeof  val.number);
      //  const self = this;
      let chIbn = num.split('-').join('');
      if (chIbn.length > 0) {
        // chIbn = chIbn.match(new RegExp('.{1,3}', 'g')).join('-');
        chIbn = chIbn.match(/\d{3}(?=\d{2,3})|\d+/g).join("-");
      }
      console.log("chIbn", chIbn);
      this.iban_no = chIbn;
      this.phonetext = chIbn;


      //  this.phoneForm.controls['phone'].setValue(chIbn,{emitEvent:false});

      //  return false;
    }


  }

  ngOnDestroy() {
    console.log("destroy")
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.countDown) {
      this.countDown.unsubscribe();
    }

    // this.countDown = null;
  }

}

@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
}
