import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[intOnly]'
})
export class IntOnlyDirective {
    @Input() maxValue: number;
    @Input() minValue: number;

    constructor(private elementRef: ElementRef) { }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        const isNumberKey = /^[0-9]$/.test(event.key);
        const isBackspace = event.key === 'Backspace';
        const enteredValue = this.getValue();
        const newValue = this.getNewValue(event, enteredValue);
        if (!isNumberKey && !isBackspace) {
            event.preventDefault();
        }



        if (!isNumberKey && isBackspace) {
            return;
        }

        if (newValue === '' && isNumberKey) {
            return;
        }
        if (this.minValue && Number(newValue) < Number(this.minValue)) {
            event.preventDefault();
        }

        if (this.maxValue && Number(newValue) > Number(this.maxValue)) {
            event.preventDefault();
        }
    }

    private getValue(): string {
        return this.elementRef.nativeElement.value;
    }

    private getNewValue(event: KeyboardEvent, enteredValue: string): string {
        const key = event.key;
        const selectionStart = this.elementRef.nativeElement.selectionStart;
        const selectionEnd = this.elementRef.nativeElement.selectionEnd;
        const currentValue = this.getValue();
        const startValue = currentValue.substring(0, selectionStart);
        const endValue = currentValue.substring(selectionEnd);
        const newValue = startValue + key + endValue;

        return newValue;
    }


}