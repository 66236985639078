import { Component, OnInit, TemplateRef, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";
@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit {
  content: TemplateRef<any>;
  header: TemplateRef<any>;
  config;
  action: string = "CLIENTS.save";
  cancel: string = "JOBS.cancel";
  hideActionBtn: boolean = false;
  cancelImageSrc: string = "./assets/img/new-ui/cancel.svg";
  constructor(
    private bsModalRef: BsModalRef,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    if (this.config) {
      Object.keys(this.config).forEach((key) => {
        this.config[key] = this.translate.instant(this.config[key]);
      });
      if (!this.config.action) {
        this.config.action = this.translate.instant(this.action)
      }
      if (!this.config.cancel) {
        this.config.cancel = this.translate.instant(this.cancel)
      }
    }


  }

  onClick() {
    this.bsModalRef.content.initialState.onClick();
  }
  onClose() {
    this.bsModalRef.hide();
  }
}
