<ng-template #dynamicContent>
  <app-button
    [imageSrc]="'./assets/img/new-ui/save.svg'"
    [buttonText]="'EDITPROFILE.save' | translate"
    (buttonClick)="saveLocation()"
  ></app-button>
</ng-template>
<div class="my-2">
  <div class="row mb-3">
    <div class="col-md-5">
      <div class="">
        <input
          type="text"
          ngx-google-places-autocomplete
          [options]="options"
          #placesRef="ngx-places"
          (onAddressChange)="handleAddressChange($event)"
          [(ngModel)]="address"
          class="animated-search"
          placeholder="{{ 'LOCATION.search_one_location' | translate }}"
        />
      </div>
    </div>
    <div *ngIf="activeAlias" class="d-flex gap-1 col-lg-7">
      <div class="d-flex align-items-center">
        <label class="col-form-label font-weight-bold mr-2">{{
          "LOCATION.alias" | translate
        }}</label>
        <p-dropdown
          placeholder="{{ 'LOCATION.select_alias' | translate }}"
          [options]="aliasList"
          [(ngModel)]="alias"
          optionLabel="{{ lang === 'fr' ? 'aliasFr' : 'aliasEn' }}"
        >
        </p-dropdown>
      </div>

      <div *ngIf="alias?.aliasEn === 'Other'" class="d-flex align-items-center">
        <div class="col-5 font-weight-bold" for="">
          {{ "LOCATION.alias_english" | translate }}
        </div>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="otherAliasEn"
          placeholder="{{ 'LOCATION.enter_alias' | translate }}"
        />
      </div>
      <div *ngIf="alias?.aliasEn === 'Other'" class="d-flex align-items-center">
        <div class="col-5 font-weight-bold" for="">
          {{ "LOCATION.alias_french" | translate }}
        </div>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="otherAliasFr"
          placeholder="{{ 'LOCATION.enter_alias' | translate }}"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 text-center mb-2">
      <ng-container *ngIf="locationData?.formatted_address">
        <small>
          <span class="text-muted"
            >{{ "LOCATION.location_selected" | translate }} :</span
          >
          {{ locationData?.formatted_address }}
        </small>
      </ng-container>
    </div>
    <div *ngIf="latitudeMarker" class="col-md-3 text-center mb-2">
      <small>
        <span class="text-muted">{{ "LOCATION.latitude" | translate }} :</span>
        {{ latitudeMarker }}
      </small>
    </div>
    <div *ngIf="longitudeMarker" class="col-md-3 text-center mb-2">
      <small>
        <span class="text-muted">{{ "LOCATION.longitude" | translate }} :</span>
        {{ longitudeMarker }}
      </small>
    </div>
    <div class="col-12">
      <app-google-map
        [zoom]="zoom"
        [draggable]="true"
        [markers]="markersInfo"
        [center]="center"
        (mapClick)="setValueNewAddress($event)"
        (dragEnd)="setValueNewAddress($event)"
      ></app-google-map>
      <!--      <agm-map
        [zoom]="zoom"
        (mapClick)="setValueNewAddress($event)"
        [latitude]="latitudeMap"
        [longitude]="longitudeMap"
      >
        <agm-marker
          [title]="address"
          [latitude]="latitudeMarker"
          [longitude]="longitudeMarker"
          (dragEnd)="setValueNewAddress($event)"
          [markerDraggable]="true"
        ></agm-marker>
      </agm-map> -->
    </div>
  </div>
</div>
