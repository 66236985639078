<div class="row" style="width: 100%; margin: auto">
  <div
    class="tracking"
    [ngClass]="
      (pendingObj | json) != '{}'
        ? 'order-tracking completed'
        : 'order-tracking'
    "
  >
    <span class="is-complete"></span>
    <p>
      {{ "JOBDETAILS.pending_status" | translate }}
      <br />
      <span *ngIf="(pendingObj | json) != '{}' && pendingObj?.dateTime">{{
        commonMethod.modifyDateTime(pendingObj?.dateTime)
      }}</span>
    </p>
  </div>
  <div
    *ngIf="(cancelledObj | json) == '{}'"
    class="tracking"
    [ngClass]="
      (acceptedObj | json) != '{}'
        ? 'order-tracking completed'
        : 'order-tracking'
    "
  >
    <span class="is-complete"></span>
    <p>
      {{ "JOBDETAILS.accepted_status" | translate }}
      <br />
      <span *ngIf="(acceptedObj | json) != '{}' && acceptedObj?.dateTime">{{
        commonMethod.modifyDateTime(acceptedObj?.dateTime)
      }}</span>
    </p>
  </div>
  <div
    *ngIf="(cancelledObj | json) == '{}'"
    class="tracking"
    [ngClass]="
      (ownershipObj | json) != '{}'
        ? 'order-tracking completed'
        : 'order-tracking'
    "
  >
    <span class="is-complete"></span>
    <p>
      {{ "JOBDETAILS.ownership_status" | translate }} <br />
      <span *ngIf="(ownershipObj | json) != '{}' && ownershipObj?.dateTime">{{
        commonMethod.modifyDateTime(ownershipObj?.dateTime)
      }}</span>
    </p>
  </div>

  <div
    *ngIf="(transferredObj | json) != '{}'"
    class="tracking"
    [ngClass]="
      (transferredObj | json) != '{}'
        ? 'order-tracking completed'
        : 'order-tracking'
    "
  >
    <span class="is-complete"></span>
    <p>
      {{ "JOBDETAILS.transferred_status" | translate }} <br />
      <span
        *ngIf="(transferredObj | json) != '{}' && transferredObj?.dateTime"
        >{{ commonMethod.modifyDateTime(transferredObj?.dateTime) }}</span
      >
    </p>
    <p *ngIf="(transferredObj | json) != '{}' && transferredObj?.dateTime">
      {{ "JOBDETAILS.by" | translate }} : {{ transferDriverName }}
    </p>
  </div>

  <div
    *ngIf="(cancelledObj | json) != '{}'"
    [ngClass]="
      (cancelledObj | json) != '{}'
        ? 'order-tracking completed'
        : 'order-tracking'
    "
  >
    <span class="is-complete"></span>
    <p>
      {{ "JOBDETAILS.cancelled_by_pharmacy_status" | translate }}
      <br />
      <span *ngIf="(cancelledObj | json) != '{}' && cancelledObj?.dateTime">{{
        commonMethod.modifyDateTime(cancelledObj?.dateTime)
      }}</span>
    </p>
  </div>
  <div
    *ngIf="(returnObj | json) != '{}'"
    [ngClass]="
      (returnObj | json) != '{}' ? 'order-tracking completed' : 'order-tracking'
    "
  >
    <span class="is-complete"></span>
    <p>
      {{ "JOBDETAILS.cancelled_by_driver_status" | translate }}
      <br />
      <span *ngIf="(returnObj | json) != '{}' && returnObj?.dateTime">{{
        commonMethod.modifyDateTime(returnObj?.dateTime)
      }}</span>
      <a
        *ngIf="job_details?.cancel_note_driver"
        (click)="openNoteModal(job_details?.cancel_note_driver)"
        class="reason-link"
        >{{ "JOBDETAILS.note" | translate }}</a
      >
    </p>
  </div>

  <div
    *ngIf="(notDeliveredObj | json) != '{}'"
    class="tracking"
    [ngClass]="
      (notDeliveredObj | json) != '{}'
        ? 'order-tracking completed'
        : 'order-tracking'
    "
  >
    <span class="is-complete"></span>
    <p *ngIf="(notDeliveredObj | json) != '{}' && notDeliveredObj?.dateTime">
      {{ "JOBDETAILS.not_delivered_status" | translate }}
      <br />
      <span>{{ commonMethod.modifyDateTime(notDeliveredObj?.dateTime) }}</span>
    </p>
    <p
      *ngIf="
        (notDeliveredObj | json) != '{}' &&
        notDeliveredObj?.dateTime &&
        notDeliveredReason
      "
    >
      <strong>{{ "JOBDETAILS.reason_note" | translate }}:</strong>

      <a (click)="reasonModal.show()" class="reason-link">{{
        "JOBDETAILS.reason_not_deliver" | translate
      }}</a>
    </p>
  </div>
  <div
    *ngIf="
      (cancelledObj | json) == '{}' &&
      (notDeliveredObj | json) == '{}' &&
      (returnObj | json) == '{}'
    "
    class="tracking"
    [ngClass]="
      (completedObj | json) != '{}'
        ? 'order-tracking completed'
        : 'order-tracking'
    "
  >
    <span class="is-complete"></span>
    <p>
      {{ "JOBDETAILS.completed_status" | translate }} <br />
      <span *ngIf="(completedObj | json) != '{}' && completedObj?.dateTime">{{
        commonMethod.modifyDateTime(completedObj?.dateTime)
      }}</span>

      <a
        *ngIf="job_details?.dropoff_note"
        (click)="openNoteModal(job_details?.dropoff_note)"
        class="reason-link"
        >{{ "JOBDETAILS.note" | translate }}</a
      >
    </p>
  </div>
</div>

<div
  bsModal
  #noteModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-md add-client-modal"
    role="document"
    style="max-width: 390px"
  >
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title-absolute">
          <!-- <button class="btn btn-default">{{ 'JOBS.collected_amount1' | translate }}</button> -->
          <button class="btn btn-default">
            {{ "JOBDETAILS.note" | translate }}
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 collection-modal">
            <div class="form-group">
              <textarea
                style="
                  font-size: 14px;
                  font-weight: 400;
                  background: none !important;
                "
                class="form-control"
                rows="5"
                id="comment"
                [(ngModel)]="viewNoteModal"
                disabled
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" style="margin: auto">
        <button
          type="button"
          class="btn btn-secondary add-client-btn"
          (click)="noteModal.hide()"
        >
          {{ "JOBS.ok" | translate }}
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>

<div
  bsModal
  #reasonModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-md add-client-modal"
    role="document"
    style="max-width: 390px"
  >
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title-absolute">
          <!-- <button class="btn btn-default">{{ 'JOBS.collected_amount1' | translate }}</button> -->
          <button class="btn btn-default">
            {{ "JOBDETAILS.reason_not_deliver" | translate }}
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 collection-modal">
            <div class="form-group">
              <h4 style="text-align: center">{{ notDeliveredReason }}</h4>
              <textarea
                *ngIf="notDeliveredNote"
                style="
                  font-size: 14px;
                  font-weight: 400;
                  background: none !important;
                "
                class="form-control"
                rows="5"
                id="comment"
                [(ngModel)]="notDeliveredNote"
                disabled
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" style="margin: auto">
        <button
          type="button"
          class="btn btn-secondary add-client-btn"
          (click)="reasonModal.hide()"
        >
          {{ "JOBS.ok" | translate }}
        </button>

        <!-- <button type="button" class="btn btn-secondary add-client-btn"
          (click)="updateChequeCollection()">{{'JOBS.update' | translate}}</button> -->
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
