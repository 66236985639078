<ng-template #dynamicContent>
  <div class="d-flex col-6 gap-1">
    <div class="flex-grow-1">
      <form>
        <input
          type="text"
          name="search"
          placeholder="{{ 'JOBS.search_customer' | translate }}"
          class="animated-search"
          (ngModelChange)="searchJobs($event)"
          [(ngModel)]="searchEvent"
          [ngModelOptions]="{ standalone: true }"
        />
      </form>
    </div>
    <div>
      <app-button
        [imageSrc]="'./assets/img/new-ui/dowload_as_pdf.svg'"
        [buttonText]="'REPORT.print' | translate"
        (buttonClick)="getPdf()"
      ></app-button>
    </div>
  </div>
</ng-template>
<div class="mt-3">
  <app-custom-calendar-datepickers
    [(startDate)]="startDate"
    [(endDate)]="endDate"
    [(fromDate)]="fromDate"
    [(toDate)]="toDate"
    [(pageNumber)]="pageNumber"
    (getData)="getAllCancelledJobs()"
  ></app-custom-calendar-datepickers>
  <app-table
    [theadTemplate]="theadTemplate"
    [tbodyTemplate]="tbodyTemplate"
    [totalItems]="allCancelledJobsCount"
    (onPageChange)="onPageChange($event)"
    [currentPage]="pageNumber"
    [noFoundTemplate]="noFoundTemplate"
  ></app-table>
  <ng-template #theadTemplate>
    <tr>
      <th>{{ "JOBS.job_id" | translate }}</th>
      <th>{{ "JOBS.client_name" | translate }}</th>
      <th>{{ "JOBS.address" | translate }}</th>
      <th>{{ "JOBS.initial" | translate }}</th>
      <th>{{ "JOBS.due_date" | translate }}</th>
      <th>{{ "JOBS.amount" | translate }}</th>
    </tr>
  </ng-template>

  <ng-template #tbodyTemplate>
    <tbody>
      <tr
        *ngFor="
          let cancelledJob of allCancelledJobs
            | paginate
              : {
                  itemsPerPage: 10,
                  currentPage: pageNumber,
                  totalItems: allCancelledJobsCount
                }
        "
        (click)="
          jobDetails(
            cancelledJob._id,
            jobDetailsBodyTemplate,
            jobDetailsHeaderTemplate
          )
        "
      >
        <td>{{ cancelledJob.booking_code }}</td>

        <td *ngIf="cancelledJob.customer_type == '2'; else notDeliverElse">
          {{
            cancelledJob.customer_f_name
              ? cancelledJob.customer_f_name +
                " " +
                cancelledJob.customer_l_name
              : cancelledJob.customer_name
          }}
        </td>
        <ng-template #notDeliverElse>
          <td>
            <span>{{
              cancelledJob.establishment_name
                ? cancelledJob.establishment_name
                : cancelledJob.customer_name
            }}</span>
          </td>
        </ng-template>

        <td *ngIf="cancelledJob.job_type == '1'">
          {{ commonMethods.sortAddress(cancelledJob.drop_off_location) }}
        </td>
        <td *ngIf="cancelledJob.job_type == '2'">
          {{ commonMethods.sortAddress(cancelledJob.pick_up_location) }}
        </td>

        <td
          (click)="jobDetails(cancelledJob._id)"
          *ngIf="cancelledJob.job_create_initials; else initialsElse"
        >
          {{ cancelledJob.job_create_initials }}
        </td>
        <ng-template #initialsElse>
          <td>N/A</td>
        </ng-template>

        <td>{{ commonMethods.modifyDate(cancelledJob.job_date) }}</td>

        <td>
          <span class="amount"
            >${{ cancelledJob.job_amount | number : "1.2-2" }}</span
          >
        </td>
      </tr>
    </tbody>
  </ng-template>
  <ng-template #noFoundTemplate>
    <div class="no-jobs-found">
      <p
        class="no-item text-center text-danger mb-0"
        *ngIf="!allCancelledJobs.length"
      >
        {{ "JOBS.no_jobs_found" | translate }}
      </p>
    </div>
  </ng-template>
</div>

<ng-template #jobDetailsHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3">
    <ng-container
      *ngTemplateOutlet="jobDetailsComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #jobDetailsBodyTemplate>
  <app-job-details [job_id]="job_id"></app-job-details>
</ng-template>
