<link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
<div class="card mt-3">
  <div class="card-body">
    <div class="row">
        <div class="col-lg-6">
          <div class="scp-breadcrumb">
            <ul class="breadcrumb">
                <li class="b-jobs"><a>{{'CATEGORY.create' | translate }}</a></li>
            </ul>
        </div>
        </div>
          
    </div>


    <div class="row profileedit-form" [formGroup]="createCategoryForm">
        <div class="col-md-2"></div>
        <div class="col-md-8">

            <div class="row">
                <div class="col-md-3"><label>{{'CATEGORY.category_nameen' | translate }}</label></div>
                <div class="col-md-6">
                 <div class="form-group">
                   <input type="text" formControlName="name" class="form-control" placeholder="{{ 'CATEGORY.placeholname_en' | translate }}">
                   
                 </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3"><label>{{'CATEGORY.category_namefr' | translate }}</label></div>
                <div class="col-md-6">
                 <div class="form-group">
                   <input type="text" formControlName="nameFr" class="form-control" placeholder="{{ 'CATEGORY.placeholname_fr' | translate }}">
                   
                 </div>
                </div>
            </div>
            <div class="row">
              <div class="col-md-3"><label>{{'CATEGORY.files' | translate }}</label></div>
              <div class="col-md-6">
                <div class="form-group">
                  <div class="fileUpload blue-btn btn width100">
                    <span>{{'IMPORTJOBS.select_file' | translate }}</span>
                    <input type="file" class="uploadlogo" name="file"  accept="image/png,image/jpeg" (change)="onFileSelect($event)">
                  </div>
              </div>
              </div>
            </div>


            <div class="row">
                <ul class="submit-cncl-btns">
                 <li><button class="btn btn-default btn2"  (click)="addNewCategory()">{{ 'CATEGORY.category_create' | translate }}</button></li>
                
                </ul>
            </div>
            
        </div>
    </div>
    
    
  </div>

</div>
