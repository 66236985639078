<div
  class="app-body body-bg"
  style="
    background-image: url(assets/img/lg-bg-min.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  "
>
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="text-right pt-2">
        <label
          ><strong><i class="fa fa-globe"></i> Language :</strong></label
        >
        <select #langSelect (change)="switchLang(langSelect.value)">
          <option
            *ngFor="let lang of ts.getLangs()"
            [value]="lang"
            [selected]="lang === ts.currentLang"
          >
            <span *ngIf="lang == 'en'"> English </span>
            <span *ngIf="lang == 'fr'"> Français </span>
          </option>
        </select>
      </div>
      <div class="row mt-02">
        <div class="col-md-3"></div>
        <div class="col-md-6 card forgot-password">
          <div class="logo-box text-center">
            <img
              class="navbar-brand-full ng-star-inserted"
              src="assets/img/vaistatlogo-2.png"
              width="89"
              height="25"
              alt="CoreUI Logo"
            />
          </div>
          <div>
            <h2 class="main-heading-forgot text-center">
              {{ "FORGOTPASSWORD.forgott_pass" | translate }}
            </h2>
            <p class="text-center">
              {{ "FORGOTPASSWORD.forgot_comment" | translate }}
            </p>
            <form
              [formGroup]="forgotForm"
              (ngSubmit)="onSubmit()"
              action="/action_page.php"
            >
              <div class="form-group mt-3">
                <label for="text">{{
                  "FORGOTPASSWORD.email" | translate
                }}</label>
                <input
                  type="text"
                  formControlName="email"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                />
                <div
                  *ngIf="submitted && f.email.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.email.errors.required">
                    {{ "FORGOTPASSWORD.email_req" | translate }}
                  </div>
                  <div *ngIf="f.email.errors.email">
                    {{ "FORGOTPASSWORD.valid_email" | translate }}
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-success mt-3">
                {{ "FORGOTPASSWORD.submit" | translate }}
              </button>
            </form>
            <p class="mt-5 text-center">
              {{ "FORGOTPASSWORD.login_comm" | translate }}
              <a routerLink="/login">{{
                "FORGOTPASSWORD.try_login" | translate
              }}</a>
            </p>
            <div *ngIf="selectedLanguage == 'fr'" class="privacy-policies">
              <a href="https://apiuser.vaistat.com/privacyFr" target="_blank">{{
                "LOGIN.terms" | translate
              }}</a>
            </div>
            <div *ngIf="selectedLanguage == 'en'" class="privacy-policies">
              <a href="https://apiuser.vaistat.com/privacyEn" target="_blank">{{
                "LOGIN.terms" | translate
              }}</a>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </main>
</div>

<!-- <ngx-spinner
              bdColor="rgba(51,51,51,0.8)"
              size="medium"
              color="#fff"
              type="ball-spin-clockwise"
            >
              <p style="font-size: 20px; color: white">Loading...</p>
            </ngx-spinner> -->

<div *ngIf="cookieStatus == false" class="cookie-banner">
  <p>
    {{ "cookie.message" | translate }}
    <a
      *ngIf="selectedLanguage == 'fr'"
      href="https://apiuser.vaistat.com/privacyFr"
      target="_blank"
      >Politique de confidentialité</a
    >
    <a
      *ngIf="selectedLanguage == 'en'"
      href="https://apiuser.vaistat.com/privacyEn"
      target="_blank"
      >Privacy policy</a
    >
  </p>
  <button type="button" class="btn btn-warning" (click)="changeCookieStatus()">
    {{ "cookie.allow" | translate }}
  </button>
</div>
