<ng-template #dynamicContent>
  <div class="d-flex gap-1">
    <app-button
      [imageSrc]="'./assets/img/new-ui/cancel.svg'"
      [buttonText]="'CHANGEPASSWORD.cancel' | translate"
      (buttonClick)="reset()"
    ></app-button>
    <app-button
      [imageSrc]="'./assets/img/new-ui/save.svg'"
      [buttonText]="'CHANGEPASSWORD.save' | translate"
      (buttonClick)="onSubmit()"
    ></app-button>
  </div>
</ng-template>
<div class="mt-3">
  <div [formGroup]="chanePassForm">
    <div class="row flex-column gap-1 col-md-10 px-0">
      <div class="d-flex align-items-center">
        <div class="col-2 font-weight-bold pr-0">
          {{ "CHANGEPASSWORD.old_password" | translate }}
        </div>
        <div class="d-flex align-items-center gap-1 col-10 pl-0">
          <div class="col-5 col-lg-3 px-0 position-relative">
            <input
              [type]="showOldPassword ? 'text' : 'password'"
              formControlName="oldPass"
              class="form-control"
              placeholder="{{ 'CHANGEPASSWORD.old_password' | translate }}"
              [ngClass]="{ 'is-invalid': submitted && f.oldPass.errors }"
            />
            <app-toggle-icon [(isActive)]="showOldPassword"></app-toggle-icon>
          </div>
          <div *ngIf="submitted && f.oldPass.errors" class="invalid-feedback">
            <div *ngIf="f.oldPass.errors.required">
              Old password is required
            </div>
            <div *ngIf="f.oldPass.errors.minlength">
              Password must be at least 6 characters
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="col-2 font-weight-bold pr-0">
          {{ "CHANGEPASSWORD.new_password" | translate }}
        </div>
        <div class="d-flex align-items-center gap-1 col-10 pl-0">
          <div class="col-5 col-lg-3 px-0 position-relative">
            <input
              [type]="showNewPassword ? 'text' : 'password'"
              formControlName="newPass"
              class="form-control"
              placeholder="{{ 'CHANGEPASSWORD.new_password' | translate }}"
              [ngClass]="{ 'is-invalid': submitted && f.newPass.errors }"
            />
            <app-toggle-icon [(isActive)]="showNewPassword"></app-toggle-icon>
          </div>
          <div *ngIf="submitted && f.newPass.errors" class="invalid-feedback">
            <div *ngIf="f.newPass.errors.required">
              New password is required
            </div>
            <div *ngIf="f.newPass.errors.minlength">
              Password must be at least 6 characters
            </div>
            <div *ngIf="f.newPass.errors.maxlength">
              Password must not exceeds 12 characters
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="col-2 font-weight-bold pr-0">
          {{ "CHANGEPASSWORD.confirm_password" | translate }}
        </div>
        <div class="d-flex align-items-center gap-1 col-10 pl-0">
          <div class="col-5 col-lg-3 px-0 position-relative">
            <input
              [type]="showConfirmPassword ? 'text' : 'password'"
              formControlName="confirmPass"
              [ngClass]="{ 'is-invalid': submitted && f.confirmPass.errors }"
              class="form-control"
              placeholder="{{ 'CHANGEPASSWORD.confirm_password' | translate }}"
            />
            <app-toggle-icon
              [(isActive)]="showConfirmPassword"
            ></app-toggle-icon>
          </div>
          <div
            *ngIf="submitted && f.confirmPass.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.confirmPass.errors.required">
              Confirm Password is Required
            </div>
            <div *ngIf="f.confirmPass.errors.mustMatch">
              Passwords must match
            </div>
          </div>
        </div>
      </div>

      <!--      <div class="row">
        <ul class="submit-cncl-btns">
          <li>
            <button
              class="btn btn-default btn2"
              style="width: auto"
              (click)="onSubmit()"
            >
              {{ "CHANGEPASSWORD.update_password" | translate }}
            </button>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</div>
