<link
  href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
  rel="stylesheet"
/>
<div class="card mt-3">
  <div class="card-body">
    <div class="row">
      <ul class="breadcrumb-list">
        <li>
          <a routerLink="/subscription-plans" class="Back-btn"
            ><i class="fa fa-long-arrow-left mr-2" aria-hidden="true"></i
            >{{ "SUBSCRIPTION.back" | translate }}</a
          >
        </li>
        <li class="n-job ml-5">
          <a routerLink="/subscription-plans"
            >{{ "SUBSCRIPTION.Subscription" | translate }}
          </a>
        </li>
        <li class="n-job">
          <a
            ><i
              class="fa fa-chevron-right"
              aria-hidden="true"
              style="font-size: 9px"
            ></i
          ></a>
        </li>
        <li class="n-job">
          <a>{{ "SUBSCRIPTION.payment" | translate }}</a>
        </li>
      </ul>
    </div>

    <div class="row subscriptions-plans">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body" style="padding: 20px 0 0 0">
            <p *ngIf="selectedlanguage == 'fr'; else english">
              {{ selectedPlan?.plan_name_french }}
            </p>
            <ng-template #english>
              <p>{{ selectedPlan?.plan_name }}</p>
            </ng-template>
            <div class="underline"></div>
            <h4>
              ${{ selectedPlan?.plans.amount / 100 }} +
              {{ "SUBSCRIPTION.taxes" | translate }}
            </h4>
            <div class="bottom-footer">
              <button
                class="btn btn-default"
                style="color: white; background-color: #4dbd74"
              >
                {{ "SUBSCRIPTION.selected_plan" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9 payment-subscription">
        <h3>{{ "SUBSCRIPTION.total_amount" | translate }}</h3>
        <h5>
          ${{ selectedPlan?.plans.amount / 100 }} +
          {{ "SUBSCRIPTION.taxes" | translate }}
        </h5>
        <button
          class="btn btn-default"
          (click)="createUpdateUserSubscription()"
        >
          {{ "SUBSCRIPTION.pay" | translate }}
        </button>
      </div>
    </div>

    <hr />

    <div class="card accordians" style="padding: 10px 20px">
      <div *ngIf="selectedlanguage == 'en'; else frenchPolicy" class="col-12">
        <div class="card-body" style="padding: 0; text-align: center">
          <p>TERMS AND POLICY</p>
        </div>
        <br />
        <ul class="list-unstyled">
          <li>
            The subscription is billed in advance. You will receive an invoice
            for each delivery made in public mode.
          </li>
          <li>
            The invoice amount will be debited from the credit card on file.
          </li>
          <li>The start of billing will be on the date of subscription.</li>
          <li>
            A delivery billed in public mode corresponds to any complete order
            by the delivery person via the application.
          </li>
          <li>
            The service is renewed automatically at the end of your
            subscription.
          </li>
          <li>
            You may discontinue the service upon written notice at least 10 days
            before the end of your subscription cycle by email.
          </li>
          <li>
            In order to prepare your accesses as well as the other essential
            elements for a successful implementation, you agree to provide us
            with a deadline of up to 24 hours following your subscription.
          </li>
          <li>
            Vaistat Solutions Inc. reserves the right to modify all of the terms
            and conditions herein upon 30 days notice.
          </li>
        </ul>
        <h4
          class="subs-heading"
          style="border-top: 1px solid #ccc; background-color: white"
        >
          THANK YOU FOR YOUR TRUST, VAISTAT SOLUTIONS INC.
        </h4>
      </div>
      <ng-template #frenchPolicy>
        <div *ngIf="selectedlanguage == 'fr'" class="col-12">
          <div class="card-body" style="padding: 0; text-align: center">
            <p>TERMES ET CONDITIONS</p>
          </div>
          <br />
          <ul class="list-unstyled">
            <li>
              L’abonnement est facturé à l’avance. Vous recevrez une facture
              pour chaque livraison effectuée en mode public.
            </li>
            <li>
              Le montant de la facture sera prélevé à la carte de crédit inscrit
              au dossier.
            </li>
            <li>Le début de la facturation sera à la date de l'inscription.</li>
            <li>
              Une livraison facturée en mode public correspond à toute commande
              complétée par le livreur via l’application.
            </li>
            <li>
              Le service est renouvelé automatiquement à la fin de votre
              abonnement.
            </li>
            <li>
              Vous pouvez interrompre le service sur avis écrit d’au moins 10
              jours avant la fin du cycle de votre abonnement par courriel.
            </li>
            <li>
              Afin de préparer vos accès ainsi que les autres éléments
              essentiels à une implantation réussie, vous acceptez de nous
              fournir un délais allant jusqu'a 24 heures suivant votre
              abonnement.
            </li>
            <li>
              Solutions Vaistat Inc. se réserve le droit de modifier, l’ensemble
              des termes et conditions de la présente sur préavis de 30 jours.
            </li>
          </ul>
          <h4
            class="subs-heading"
            style="border-top: 1px solid #ccc; background-color: white"
          >
            SOLUTIONS VAISTAT INC. VOUS REMERCIE DE VOTRE CONFIANCE
          </h4>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<!-------modal------->
<div
  bsModal
  #congratulationModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md add-client-modal" role="document">
    <div class="modal-content invoice-detail-modal">
      <div class="modal-body">
        <div class="text-center mt-3 mb-5">
          <img src="assets/img/graphic-img.png" style="width: 300px" />
        </div>
        <h4
          style="
            text-align: center;
            text-transform: uppercase;
            font-weight: 600;
          "
        >
          {{ "SUBSCRIPTION.congrats" | translate }} !
        </h4>
        <p style="color: #000000; opacity: 0.48; text-align: center">
          {{ "SUBSCRIPTION.success_membership_plan" | translate }}
        </p>
      </div>
      <div class="modal-footer" style="margin: auto">
        <button
          type="button"
          routerLink="/subscription-details"
          class="btn btn-secondary add-client-btn mb-3"
          (click)="congratulationModal.hide()"
        >
          {{ "SUBSCRIPTION.ok" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
