<div class="row justify-content-between scp-breadcrumb mb-4">
  <div class="b-jobs">
    <a>{{ "jobs" | translate }} </a>
  </div>
  <div class="d-flex gap-1 flex-wrap">
    <div>
      <div class="form-group">
        <button (click)="changeRange()" class="btn btn-dark">
          {{ "today" | translate }}
        </button>
      </div>
    </div>
    <div>
      <input
        class="box-m-in point date-picker"
        [(ngModel)]="Date"
        ngbDatepicker
        #start="ngbDatepicker"
        [minDate]="{ year: 1900, month: 1, day: 1 }"
        #buttonEl
        (ngModelChange)="onDateChange($event)"
        (click)="start.toggle()"
        firstDayOfWeek="7"
      />
    </div>
    <form style="min-width: 230px">
      <input
        type="text"
        placeholder="{{ 'JOBS.search_job' | translate }}"
        class="animated-search"
        (ngModelChange)="onSearch($event)"
        [(ngModel)]="searchEvent"
        [ngModelOptions]="{ standalone: true }"
      />
    </form>
    <app-button
      [imageSrc]="'./assets/img/new-ui/reset.svg'"
      [buttonText]="'JOBS.reset' | translate"
      (buttonClick)="reset()"
    ></app-button>
  </div>
  <ng-container *ngIf="selectedTabIndex === 'new-jobs'">
    <ng-container
      *ngTemplateOutlet="newJobRequestsComponent?.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === 'active-jobs'">
    <ng-container
      *ngTemplateOutlet="activeJobsComponent?.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === 'completed-jobs'">
    <ng-container
      *ngTemplateOutlet="completedJobsComponent?.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === 'not-delivered-jobs'">
    <ng-container
      *ngTemplateOutlet="notDeliveredJobsComponent?.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === 'recursive-jobs'">
    <ng-container
      *ngTemplateOutlet="recursiveJobsComponent?.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === 'charged-account'">
    <ng-container
      *ngTemplateOutlet="chargedAccountJobsComponent?.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
</div>

<div>
  <!-- Display jobs information cards -->
  <div class="row justify-content-between mb-2 px-0">
    <div class="col-md-3 pr-0">
      <div
        class="card dashboard-cards point"
        [ngClass]="{
          'dashboard-cards-active': selectedTabIndex === 'new-jobs'
        }"
        (click)="goToJobsList('new-jobs')"
      >
        <div class="card-body col-md-12 p-2">
          <div class="col-md status">
            <h3>{{ newJobs.count || 0 }}</h3>
            <p>{{ "DASHBOARD.new_job_request" | translate }}</p>

            <!-- Display PULIC and PRIVATE details for new jobs -->
            <div class="mt-2">
              <p class="mt-0">
                {{ "DASHBOARD.JOB.public" | translate | titlecase }} :
                <span>{{ newJobs?.publicJobsData?.am_count || 0 }}</span> AM |
                <span>{{ newJobs?.publicJobsData?.pm_count || 0 }}</span> PM |
                <span>{{ newJobs?.publicJobsData?.night_count || 0 }}</span>
                Night
              </p>
              <p class="mt-2">
                {{ "DASHBOARD.JOB.private" | translate | titlecase }} :
                <span>{{ newJobs?.privateJobsData?.am_count || 0 }}</span> AM |
                <span>{{ newJobs?.privateJobsData?.pm_count || 0 }}</span> PM |
                <span>{{ newJobs?.privateJobsData?.night_count || 0 }}</span>
                Night
              </p>
            </div>
            <!-- ./Display PULIC and PRIVATE details for new jobs -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 pr-0">
      <div
        class="card dashboard-cards point"
        [ngClass]="{
          'dashboard-cards-active': selectedTabIndex === 'active-jobs'
        }"
        (click)="goToJobsList('active-jobs')"
      >
        <div class="card-body col-md-12 p-2">
          <div class="col-md status">
            <h3>{{ activeJobs.count || 0 }}</h3>
            <p>{{ "DASHBOARD.active_jobs" | translate }}</p>

            <!-- Display PULIC and PRIVATE details for active jobs -->
            <div class="mt-2">
              <p class="mt-0">
                {{ "DASHBOARD.JOB.public" | translate | titlecase }} :
                <span>{{ activeJobs?.publicJobsData?.am_count || 0 }}</span> AM
                |
                <span>{{ activeJobs?.publicJobsData?.pm_count || 0 }}</span> PM
                |
                <span>{{ activeJobs?.publicJobsData?.night_count || 0 }}</span>
                Night
              </p>
              <p class="mt-2">
                {{ "DASHBOARD.JOB.private" | translate | titlecase }} :
                <span>{{ activeJobs?.privateJobsData?.am_count || 0 }}</span> AM
                |
                <span>{{ activeJobs?.privateJobsData?.pm_count || 0 }}</span> PM
                |
                <span>{{ activeJobs?.privateJobsData?.night_count || 0 }}</span>
                Night
              </p>
            </div>
            <!-- ./Display PULIC and PRIVATE details for active jobs -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 pr-0">
      <div
        class="card dashboard-cards point"
        [ngClass]="{
          'dashboard-cards-active': selectedTabIndex === 'completed-jobs'
        }"
        (click)="goToJobsList('completed-jobs')"
      >
        <div class="card-body col-md-12 p-2">
          <div class="col-md status">
            <h3>{{ completedJobs.count || 0 }}</h3>
            <p>{{ "DASHBOARD.completed_jobs" | translate }}</p>

            <!-- Display PULIC and PRIVATE details -->
            <div class="mt-2">
              <p class="mt-0">
                {{ "DASHBOARD.JOB.public" | translate | titlecase }} :
                <span>{{ completedJobs?.publicJobsData?.am_count || 0 }}</span>
                AM |
                <span>{{ completedJobs?.publicJobsData?.pm_count || 0 }}</span>
                PM |
                <span>{{
                  completedJobs?.publicJobsData?.night_count || 0
                }}</span>
                Night
              </p>
              <p class="mt-2">
                {{ "DASHBOARD.JOB.private" | translate | titlecase }} :
                <span>{{ completedJobs?.privateJobsData?.am_count || 0 }}</span>
                AM |
                <span>{{ completedJobs?.privateJobsData?.pm_count || 0 }}</span>
                PM |
                <span>{{
                  completedJobs?.privateJobsData?.night_count || 0
                }}</span>
                Night
              </p>
            </div>
            <!-- ./Display PULIC and PRIVATE details -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 pr-0">
      <div class="card dashboard-cards point">
        <div class="card-body col-md-12 p-2">
          <div class="col-md status">
            <h3>
              ${{
                newJobs?.publicJobsData?.total_expenses.amount +
                  newJobs?.privateJobsData?.total_expenses.amount || 0
                  | number : "1.2-2"
              }}
            </h3>
            <p>{{ "DASHBOARD.total_expenses" | translate }}</p>

            <!-- Display PULIC and PRIVATE details for total expenses -->
            <div class="mt-2">
              <p class="mt-0">
                {{ "DASHBOARD.JOB.public" | translate | titlecase }} :
                <span
                  >${{
                    newJobs?.publicJobsData?.total_expenses.am_amount || 0
                      | number : "1.2-2"
                  }}</span
                >
                AM |
                <span
                  >${{
                    newJobs?.publicJobsData?.total_expenses.pm_amount || 0
                      | number : "1.2-2"
                  }}
                </span>
                PM |
                <span
                  >${{
                    newJobs?.publicJobsData?.total_expenses.night_amount || 0
                      | number : "1.2-2"
                  }}
                </span>
                Night
              </p>
              <p class="mt-2">
                {{ "DASHBOARD.JOB.private" | translate | titlecase }} :
                <span
                  >${{
                    newJobs?.privateJobsData?.total_expenses.am_amount || 0
                      | number : "1.2-2"
                  }}</span
                >
                AM |
                <span
                  >${{
                    newJobs?.privateJobsData?.total_expenses.pm_amount || 0
                      | number : "1.2-2"
                  }}</span
                >
                PM |
                <span
                  >${{
                    newJobs?.privateJobsData?.total_expenses.night_amount || 0
                      | number : "1.2-2"
                  }}</span
                >
                Night
              </p>
            </div>
            <!-- ./Display PULIC and PRIVATE details for total expenses -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./Display jobs information cards -->
  <tabset>
    <tab
      (selectTab)="onTabChange($event)"
      id="new-jobs"
      [heading]="
        ('new_job_request' | translate) +
        ' (' +
        newJobRequestsComponent?.jobsListComponent?.jobs_count +
        ')'
      "
    >
      <app-new-job-requests></app-new-job-requests>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      id="active-jobs"
      [heading]="
        ('active_jobs' | translate) +
        ' (' +
        activeJobsComponent?.jobsListComponent?.jobs_count +
        ')'
      "
    >
      <app-active-jobs></app-active-jobs>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      id="completed-jobs"
      [heading]="
        ('completed_jobs' | translate) +
        ' (' +
        completedJobsComponent?.jobsListComponent?.jobs_count +
        ')'
      "
    >
      <app-completed-jobs></app-completed-jobs>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      id="not-delivered-jobs"
      [heading]="
        ('not_delivered' | translate) +
        ' (' +
        notDeliveredJobsComponent?.jobsListComponent?.jobs_count +
        ')'
      "
    >
      <app-not-delivered-jobs></app-not-delivered-jobs>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      id="recursive-jobs"
      _jobs
      [heading]="
        ('recursive_jobs' | translate) +
        ' (' +
        recursiveJobsComponent?.jobsListComponent?.jobs_count +
        ')'
      "
    >
      <app-recursive-jobs></app-recursive-jobs>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      id="charged-account"
      _jobs
      [heading]="
        ('charged_account' | translate) +
        ' (' +
        chargedAccountJobsComponent?.jobsListComponent?.jobs_count +
        ')'
      "
    >
      <app-charged-account-jobs></app-charged-account-jobs>
    </tab>
  </tabset>
</div>
