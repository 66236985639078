import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    Router
} from '@angular/router';
import {
    NgxSpinnerService
} from 'ngx-spinner';
import {
    ToastrService
} from 'ngx-toastr';
import {
    DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
    ServicesService
} from '../../services.service';
import { CommonMethods } from '../_helpers/_common-methods';
@Component({
    selector: 'app-tag-summery-details',
    templateUrl: './tag-summery-details.component.html',
    styleUrls: ['./tag-summery-details.component.scss']
})
export class TagSummeryDetailsComponent implements OnInit, OnChanges {
    @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
    pageNumber = 1;
    searchEvent = '';
    allJobs = [];
    allJobsCount = 0
    userData: any;
    @Input() tag_type: any;
    @Input() startDate: any;
    @Input() endDate: any;

    constructor(
        public toastr: ToastrService,
        private services: ServicesService,
        public router: Router,
        private spinner: NgxSpinnerService,
        public logoutFunction: DefaultLayoutComponent,
        private commonMethods: CommonMethods // used on html file
    ) {

    }

    ngOnInit() {
        this.userData = JSON.parse(localStorage.getItem("currentUser"));
    }


    ngOnChanges(changes: SimpleChanges) {
        const { tag_type } = changes;
        if (tag_type && tag_type.currentValue) {
            this.getAllJobsListAccTags();
        }

    }
    //GET ALL JOBS ACCORDING TO TAG SELECTION API FUNCTION
    getAllJobsListAccTags() {
        this.spinner.show();
        this.services.getJobsAccToTagsSelection(this.userData._id, this.tag_type, this.pageNumber, this.searchEvent, this.startDate, this.endDate).pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            console.log(data, 'all Tags data');
            if (data.code == 200) {
                this.allJobs = data.result;
                this.allJobsCount = data.count;
                this.spinner.hide();
                return;
            } else {
                this.spinner.hide();
                this.allJobs = [];
                this.allJobsCount = 0;
                return;
            }
        });
    }

    //ON PAGE CHANGE EVENT
    onPageChange(event) {
        this.pageNumber = event;
        this.getAllJobsListAccTags();
    }


    //ON JOBS SEARCH
    searchJobs(event) {
        this.pageNumber = 1;
        this.searchEvent = event ? event : '';
        // this.startDate = "";
        // this.endDate = "";
        // this.fromDate = null;
        // this.toDate = null;

        if (event.length == 0) {
            return this.getAllJobsListAccTags();
        }
        if (this.searchEvent.length > 2) {
            this.getAllJobsListAccTags();
        }
    }

}