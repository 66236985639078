import {
   Component,
   Input,
   OnInit,
   TemplateRef,
   ViewChild
} from '@angular/core';
import {
   UntypedFormBuilder,
   UntypedFormControl,
   UntypedFormGroup,
   Validators
} from '@angular/forms';
import {
   Router
} from '@angular/router';
import {
   NgbDateParserFormatter, NgbDatepickerConfig,
   NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';
import 'moment-timezone';
import {
   IDropdownSettings
} from 'ng-multiselect-dropdown';
import {
   BsModalRef,
   BsModalService,
   ModalDirective,
} from 'ngx-bootstrap/modal';
import { TabsetComponent } from "ngx-bootstrap/tabs";
import {
   NgxSpinnerService
} from "ngx-spinner";
import {
   ToastrService
} from 'ngx-toastr';
import { CustomerType } from '../../../app/utils/Enums';
import { environment } from '../../../environments/environment';
import { Tag, initTag } from '../../models/Tag';
import {
   ServicesService
} from '../../services.service';
import { ParameterService } from '../../services/admin-api/parameter.service';
import { Address } from '../../services/interfaces';
import { LocationAgmComponent } from '../../shared/component/location-agm/location-agm.component';
import { ModalComponent } from "../../shared/component/modal/modal.component";
import { JOB } from '../../shared/helpers/enums';
import { CommonMethods } from '../_helpers/_common-methods';
import { EditJobComponent } from '../edit-job/edit-job.component';
moment.tz.setDefault("UTC");

@Component({
   selector: 'app-create-job',
   templateUrl: './create-job.component.html',
   styleUrls: ['./create-job.component.scss']
})
export class CreateJobComponent implements OnInit {
   @Input() parentModalRef: BsModalRef;
   @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
   @ViewChild(EditJobComponent) editJobComponent: EditJobComponent;
   socket: SocketIOClient.Socket;
   modalBody: string;
   timer: any;
   timer1: any;
   @ViewChild('jobDuplicacyModal') public jobDuplicacyModal: ModalDirective;
   @ViewChild("checkboxes") checkboxes;
   @ViewChild('editUrgentJobModal') public editUrgentJobModal: ModalDirective;
   @ViewChild('editNormalJobModal') public editNormalJobModal: ModalDirective;
   @ViewChild('subscriptionModal') public subscriptionModal: ModalDirective;
   @ViewChild('cardModal') public cardModal: ModalDirective;
   @ViewChild('addJobTagModal') public addJobTagModal: ModalDirective;
   @ViewChild(LocationAgmComponent) locationAgmComponent: LocationAgmComponent;
   @ViewChild('locationHeaderTemplate') locationHeaderTemplate: TemplateRef<any>;
   @ViewChild('locationBodyTemplate') locationBodyTemplate: TemplateRef<any>;
   bsModalRef: BsModalRef;
   @ViewChild(TabsetComponent) tabset: TabsetComponent;
   selectedTabIndex = 'tab1';
   public language = "en";
   public dog = true;
   public cat = true;
   p1: number = 1;
   public type = "0";
   public eventRemove: any;
   public iban_no = ""

   public dial_code: any;
   public options: any;
   location1: string;
   dpDayPicker: any;
   lat: any;
   long: any;

   customer_name: string;
   customer_note: string;
   customer_parent: string;
   customer_phone: string;
   note: string;
   customer_type = 1;
   delivery_type = '2';
   customer_type_delivery = CustomerType.RESIDENCE;
   customer_type_pickup = CustomerType.RESIDENCE;
   sLat_long = [];
   eLat_long = [];
   distance = 0;
   normalCharges = 0;
   riceNormalChanges = 0;
   urgentCharges = 0;
   taxTps = 0;
   overallTax = 0;
   user_id: any;
   ride_fare = 0;

   job_from = '1';
   job_id: any;
   find_driver_delivery = '1';
   find_driver_pickup = '1';
   item_price: number;
   public jobDate: any;

   selectedCard: any;
   public formType = '1';
   jobForm: UntypedFormGroup;

   submitted = false;

   public driversList: any;
   public endDate: any;
   public selectedDate: any;
   selectedDay: string = '';
   selectedDriverId = "";
   public jobFor = '1'; // 1 for public 2 for private
   public modalHeading = "Finding Nearest Cab For You";
   public modalTitle = "We’ll get back to you shortly"
   public isPrivate = "2";
   public drivers = [];
   public sel: any;
   public driversForPickup = [];
   public job_tags = [];
   public tags: any = [];
   tempArr: any = {
      "brands": []
   };
   public tax: any;
   public processing_fee: any;
   public customers = [];
   public test = 0;
   public cust_name: any;
   public ride_fare_without_tax = 0;
   public driver_charges_normal = 0;
   public driver_charges_urgent = 0;
   public urgent_charge = 0;
   public normal_charge = 0;

   original_driver_charges_normal = 0;
   original_driver_charges_urgent = 0;
   public other_charges = 0;
   public timeZone: any;
   public pharmacy: any;
   adminTax = 0;
   adminProcessingFee = 0;
   weekendCharge = 0;
   nightCharge = 0;
   normalNightCharge = 0;
   urgentNightCharge = 0;
   jobTypeSelection: Number = 1;
   disableTags = true;
   dateForJob: any;
   weekend_charge_normal = 0;
   weekend_charge_urgent = 0
   timeForAutoJob: any;
   dateForAutoJob: any;
   job_recurring_type = 1;
   readonlyInputs = true;
   selectedValue = '8';
   dateValue = '0';
   dailyJobType = 1; // 1 for daily, 2 for mon-sat, 3 mon-fri

   dropdownList = [];
   weekDays = [];
   dropdownSettings: IDropdownSettings;
   establishments = [];
   establish_name: any;
   isDisableItemPrice = 1; // 1 for enable input 2 for diable input
   dropdownSettings1: IDropdownSettings;
   dropdownSettings2: IDropdownSettings; // FOR SELECT CUSTOMERS
   selected_drivers = [];
   stateProcessingFees = 0;
   state_tax_id: any;
   minuteStep = 10;
   testTag = false;
   username: any;
   customerTypeSelection = 1;
   multipleArray = [];
   multiple_customers: any;
   multiple_customers_id = [];
   establishment_id: any;
   residenceAllClients = [];
   multiple_customers_info: any[];
   customer_est_id = null;
   openOptionalAddress = false;
   optional_location: any;
   selected_type = '0';
   residenceAllClient = [];
   to_be_received_by_type = '0';
   establishmentTagSelection = '0';
   lastTypeSelected = '0';
   cust_l_name: any;
   cust_l_name_pickup: any;
   recursiveEndDateDelivery: any;
   recursiveEndDatePickup: any;
   job_type_selection = '1';
   isContinuousJob = false;
   isRoundTrip = false;
   roundTripFee: number = 0;
   locationData = {}
   customer_id = "";

   //NEW KEYS
   totalTagsPrices = 0;
   dropdownSettingsDispatch: IDropdownSettings;

   assignedDriversByDispatch = []; // driver ids assigned by dispatch
   selected_drivers_dispatch = [];// selected driver for job by dispatch
   selected_drivers_dispatch_username = [];

   // dispatchCompanyId: any;
   isDispatchSelected = false;

   connectedDispatches = [];
   selectedDispatchForJob = [];
   stateTaxObj = {};
   packageObj = { package: '0', small: '0', large: '0', extraLarge: '0', label: '0/0', weight: '0' };
   jobTimingObj = {
      type: 0,
      startTime: { hour: 9, minute: 30 },
      endTime: { hour: 12, minute: 30 },
      nameEn: "",
      nameFr: ""

   }
   attachedDocument = { url: "", docName: "" };
   establishment_phone = "";
   duplicate_job_id = "";

   //Todo order type
   orderType = '';
   // todo state
   isNormal = true;
   isUrgent = false;
   //*Pharmacy Profile
   private pharmacyProfile: any;

   customerAddresses = [];
   public selectedAddress: UntypedFormControl = new UntypedFormControl(null, []);

   hourUrgent = '';
   public parent_job: string = ''

   public tag: Tag = initTag();
   public addJobTagForm: UntypedFormGroup;
   public submittedAddJobTagForm = false;

   selectedFile: File = null;
   constructor(
      private formBuilder: UntypedFormBuilder,
      public toastr: ToastrService,
      private services: ServicesService,
      public router: Router,
      private spinner: NgxSpinnerService,
      public config: NgbDatepickerConfig,
      public ngbDateParserFormatter: NgbDateParserFormatter,

      private parameterService: ParameterService,

      private commonMethods: CommonMethods,
      private bsModalService: BsModalService,
      private translate: TranslateService,


   ) {

   }


   public handleAddressChange(address, isSelectNormal = false) {

      if (!isSelectNormal) {
         this.location1 = address.formatted_address;
         this.lat = address.geometry.location.lat();
         this.long = address.geometry.location.lng();
      } else {
         if (address?.value?.address === 'New address') {
            this.location1 = null;
            this.lat = null;
            this.long = null;
            this.jobForm.controls['location1'].setValue(null);
            this.locationData = {}


            this.openModalSelectLocation();


            return;
         }

         this.location1 = address?.value?.landmark;
         this.lat = address?.value?.latitude;
         this.long = address?.value?.longitude;
         this.jobForm.controls['location1'].setValue(address?.value?.landmark);
         this.jobForm.controls['location1'].disable();
      }

      var localData1 = JSON.parse(localStorage.getItem("currentUser"));
      this.sLat_long = localData1.lat_long;
      this.eLat_long = [this.lat, this.long];
      this.spinner.show();
      var date = this.dateForJob ? this.dateForJob : '';

      this.job_type_selection = this.selectedTabIndex == 'tab2' ? '2' : '1';

      this.services.getDistancePrice(this.sLat_long, this.eLat_long, date, localData1.location, this.location1, this.job_type_selection, this.orderType).pipe().subscribe(data => {
         if (data.code == 400) {
            this.spinner.hide();
            this.toastr.error('', data.message);
            this.commonMethods.logout();
            return;
         }
         if (data.code == 200) {
            this.roundTripFee = data.roundTripFee;
            //Todo: code implement for map
            this.locationData = {
               lat: this.lat,
               lng: this.long,
               address: this.location1,
            }
            if (isSelectNormal === false) {
               this.bsModalRef.hide(); // close modal
            }
            this.jobForm.controls['location1'].setValue(this.location1);
            // finish code implement for map

            if (this.jobTypeSelection == 1) { //only for public price visible 
               this.tax = data.result.tax;
               this.adminTax = data.result.adminTax;
               this.state_tax_id = data.result.state_tax_id;
               this.stateTaxObj = data.result.stateTaxObj;

               this.stateProcessingFees = data.result.stateProcessingFees;

               this.processing_fee = data.result.processing_fee;
               this.distance = data.result.distance;
               this.normalCharges = data.result.normalPrice; // normal charges
               this.riceNormalChanges = data.result.ride_charges_normal;// price base tax
               this.urgentCharges = data.result.urgent_price; // urgent charges
               this.overallTax = data.result.overallTax; // total tax
               this.driver_charges_normal = data.result.ride_charges_normal;
               this.driver_charges_urgent = data.result.ride_charges_urgent;
               this.normalNightCharge = 0;
               this.urgentNightCharge = 0;
               this.weekend_charge_normal = data.result.weekend_charge_normal;
               this.weekend_charge_urgent = data.result.weekend_charge_urgent;



               this.original_driver_charges_normal = this.driver_charges_normal;
               this.original_driver_charges_urgent = this.driver_charges_urgent;

               var nightTag = this.tags.map(function (e) {
                  return e.tag_type;
               }).indexOf('12');
               if (nightTag > -1) {
                  var tagg = this.tags[nightTag];
                  if (this.eLat_long && this.eLat_long.length && this.location1 && this.normalCharges && this.urgentCharges) {
                     // can deduct weekend chage from base prices i.e normal /urgent        
                     var driverChargeNormal = Number(this.driver_charges_normal) - Number(this.weekend_charge_normal);
                     var driverChargeUrgent = Number(this.driver_charges_urgent) - Number(this.weekend_charge_urgent);

                     this.driver_charges_normal = Math.round(((Number(this.driver_charges_normal) - Number(this.weekend_charge_normal)) * Number(this.nightCharge)) * 100) / 100;

                     this.driver_charges_urgent = Math.round(((Number(this.driver_charges_urgent) - Number(this.weekend_charge_urgent)) * Number(this.nightCharge)) * 100) / 100;


                     var totalTaxProcessing = Number(this.adminTax) + Number(this.adminProcessingFee);

                     var normalTax = Math.round(((this.driver_charges_normal + Number(this.weekend_charge_normal)) / 100 * totalTaxProcessing) * 100) / 100;
                     var urgentTax = Math.round(((this.driver_charges_urgent + Number(this.weekend_charge_urgent)) / 100 * totalTaxProcessing) * 100) / 100;

                     this.normalCharges = Math.round((this.driver_charges_normal + Number(this.weekend_charge_normal) + normalTax) * 100) / 100;
                     this.urgentCharges = Math.round((this.driver_charges_urgent + Number(this.weekend_charge_urgent) + urgentTax) * 100) / 100;

                     this.taxTps = Math.round(this.driver_charges_normal * 5) / 100;

                     this.normalNightCharge = Math.round((this.driver_charges_normal - driverChargeNormal) * 100) / 100;
                     this.urgentNightCharge = Math.round((this.driver_charges_urgent - driverChargeUrgent) * 100) / 100;


                     this.driver_charges_normal = Math.round((this.driver_charges_normal + Number(this.weekend_charge_normal)) * 100) / 100;
                     this.driver_charges_urgent = Math.round((this.driver_charges_urgent + Number(this.weekend_charge_urgent)) * 100) / 100;
                  }
               }

               if (this.eLat_long && this.eLat_long.length && this.location1 && this.normalCharges && this.urgentCharges) {

                  if (this.totalTagsPrices > 0) {
                     this.driver_charges_normal = Math.round(((Number(this.driver_charges_normal) - Number(this.weekend_charge_normal)) + Number(this.totalTagsPrices)) * 100) / 100;

                     this.driver_charges_urgent = Math.round(((Number(this.driver_charges_urgent) - Number(this.weekend_charge_urgent)) + Number(this.totalTagsPrices)) * 100) / 100;




                     var totalTaxProcessing = Number(this.adminTax) + Number(this.adminProcessingFee);
                     var normalTax = Math.round(((this.driver_charges_normal + Number(this.weekend_charge_normal)) / 100 * totalTaxProcessing) * 100) / 100;
                     var urgentTax = Math.round(((this.driver_charges_urgent + Number(this.weekend_charge_urgent)) / 100 * totalTaxProcessing) * 100) / 100;

                     this.normalCharges = Math.round((this.driver_charges_normal + Number(this.weekend_charge_normal) + normalTax) * 100) / 100;
                     this.urgentCharges = Math.round((this.driver_charges_urgent + Number(this.weekend_charge_urgent) + urgentTax) * 100) / 100;

                     this.taxTps = Math.round(this.driver_charges_normal * 5) / 100;

                     this.driver_charges_normal = Math.round((this.driver_charges_normal + Number(this.weekend_charge_normal)) * 100) / 100;
                     this.driver_charges_urgent = Math.round((this.driver_charges_urgent + Number(this.weekend_charge_urgent)) * 100) / 100;

                  }
               }

               this.calculateRoundTrip();
            }
            this.tax = 0;
            this.processing_fee = 0;
            this.distance = data.result.distance;
            this.disableTags = false;


            this.spinner.hide();
         } else {
            this.locationData = {};
            this.toastr.error('error', data.message);
            this.location1 = "";
            this.distance = 0;
            this.normalCharges = 0;

            this.taxTps = 0;
            this.urgentCharges = 0;
            this.overallTax = 0;
            this.taxTps = 0;
            this.driver_charges_normal = 0;
            this.driver_charges_urgent = 0;
            this.normalNightCharge = 0;
            this.urgentNightCharge = 0;

            this.ngOnInit();
            this.spinner.hide();
         }

      });

      // Do some stuff
   }


   filter(event) {
      this.p1 = 1
      this.to_be_received_by_type = event.target.value;

   }

   filterRecurringJob(event) {

      this.job_recurring_type = event.target.value;

   }

   dailyJobTypeChange(event) {

      this.dailyJobType = event.target.value;

   }

   // function for calculate distance price for existing customer for delivery job
   public customerDelivery(lat_long, location, dateOfJob) {

      this.location1 = location;
      // var localData1 = JSON.parse(localStorage.getItem("currentUser"));
      // this.sLat_long = localData1.lat_long;
      this.sLat_long = this.pharmacy_latLong;
      this.eLat_long = lat_long;
      this.job_type_selection = this.selectedTabIndex == 'tab2' ? '2' : '1';
      this.spinner.show();
      this.services.getDistancePrice(this.sLat_long, this.eLat_long, dateOfJob, this.pharmacy_address, this.location1, this.job_type_selection, this.orderType).pipe().subscribe(data => {

         if (data.code == 400) {
            this.spinner.hide();
            this.toastr.error('', data.message);
            this.commonMethods.logout();
            return;
         }

         if (data.code == 200) {

            this.roundTripFee = data.roundTripFee;
            if (this.jobTypeSelection == 1) {
               this.adminTax = data.result.adminTax;
               this.state_tax_id = data.result.state_tax_id;
               this.stateTaxObj = data.result.stateTaxObj;

               this.stateProcessingFees = data.result.stateProcessingFees;
               this.tax = data.result.tax;
               this.processing_fee = data.result.processing_fee;
               this.distance = data.result.distance;
               this.normalCharges = data.result.normalPrice; // normal charges
               this.urgentCharges = data.result.urgent_price; // urgent charges
               this.riceNormalChanges = data.result.ride_charges_normal;// price base tax
               this.overallTax = data.result.overallTax; // total tax
               this.driver_charges_normal = data.result.ride_charges_normal;
               this.driver_charges_urgent = data.result.ride_charges_urgent;
               this.normalNightCharge = 0;
               this.urgentNightCharge = 0;
               this.weekend_charge_normal = data.result.weekend_charge_normal;
               this.weekend_charge_urgent = data.result.weekend_charge_urgent;

               this.original_driver_charges_normal = this.driver_charges_normal;
               this.original_driver_charges_urgent = this.driver_charges_urgent;


               var nightTag = this.tags.map(function (e) {
                  return e.tag_type;
               }).indexOf('12');

               if (nightTag > -1) {

                  var tagg = this.tags[nightTag];
                  if (this.eLat_long && this.eLat_long.length && this.location1 && this.normalCharges && this.urgentCharges) {

                     var driverChargeNormal = Number(this.driver_charges_normal) - Number(this.weekend_charge_normal);
                     var driverChargeUrgent = Number(this.driver_charges_urgent) - Number(this.weekend_charge_urgent);

                     this.driver_charges_normal = Math.round(((Number(this.driver_charges_normal) - Number(this.weekend_charge_normal)) * Number(this.nightCharge)) * 100) / 100;



                     this.driver_charges_urgent = Math.round(((Number(this.driver_charges_urgent) - Number(this.weekend_charge_urgent)) * Number(this.nightCharge)) * 100) / 100;


                     var totalTaxProcessing = Number(this.adminTax) + Number(this.adminProcessingFee);

                     var normalTax = Math.round(((this.driver_charges_normal + Number(this.weekend_charge_normal)) / 100 * totalTaxProcessing) * 100) / 100;
                     var urgentTax = Math.round(((this.driver_charges_urgent + Number(this.weekend_charge_urgent)) / 100 * totalTaxProcessing) * 100) / 100;

                     this.normalCharges = Math.round((this.driver_charges_normal + Number(this.weekend_charge_normal) + normalTax) * 100) / 100;
                     this.urgentCharges = Math.round((this.driver_charges_urgent + Number(this.weekend_charge_urgent) + urgentTax) * 100) / 100;

                     this.taxTps = Math.round(this.driver_charges_normal * 5) / 100;

                     this.normalNightCharge = Math.round((this.driver_charges_normal - driverChargeNormal) * 100) / 100;
                     this.urgentNightCharge = Math.round((this.driver_charges_urgent - driverChargeUrgent) * 100) / 100;


                     this.driver_charges_normal = Math.round((this.driver_charges_normal + Number(this.weekend_charge_normal)) * 100) / 100;
                     this.driver_charges_urgent = Math.round((this.driver_charges_urgent + Number(this.weekend_charge_urgent)) * 100) / 100;


                  }
               }
               if (this.eLat_long && this.eLat_long.length && this.location1 && this.normalCharges && this.urgentCharges) {

                  if (this.totalTagsPrices > 0) {

                     this.driver_charges_normal = Math.round(((Number(this.driver_charges_normal) - Number(this.weekend_charge_normal)) + Number(this.totalTagsPrices)) * 100) / 100;

                     this.driver_charges_urgent = Math.round(((Number(this.driver_charges_urgent) - Number(this.weekend_charge_urgent)) + Number(this.totalTagsPrices)) * 100) / 100;

                     var totalTaxProcessing = Number(this.adminTax) + Number(this.adminProcessingFee);
                     var normalTax = Math.round(((this.driver_charges_normal + Number(this.weekend_charge_normal)) / 100 * totalTaxProcessing) * 100) / 100;
                     var urgentTax = Math.round(((this.driver_charges_urgent + Number(this.weekend_charge_urgent)) / 100 * totalTaxProcessing) * 100) / 100;

                     this.normalCharges = Math.round((this.driver_charges_normal + Number(this.weekend_charge_normal) + normalTax) * 100) / 100;
                     this.urgentCharges = Math.round((this.driver_charges_urgent + Number(this.weekend_charge_urgent) + urgentTax) * 100) / 100;

                     this.taxTps = Math.round(this.driver_charges_normal * 5) / 100;

                     this.driver_charges_normal = Math.round((this.driver_charges_normal + Number(this.weekend_charge_normal)) * 100) / 100;
                     this.driver_charges_urgent = Math.round((this.driver_charges_urgent + Number(this.weekend_charge_urgent)) * 100) / 100;
                  }
               }
               this.calculateRoundTrip();
            }
            this.disableTags = false;
            this.tax = 0;
            this.processing_fee = 0;
            this.distance = data.result.distance;
            this.spinner.hide();
         } else {
            this.toastr.error('error', data.message);
            this.location1 = "";
            this.cust_name = "";
            this.iban_no = "";
            this.distance = 0;
            this.normalCharges = 0;
            this.urgentCharges = 0;
            this.overallTax = 0;
            this.driver_charges_normal = 0;
            this.driver_charges_urgent = 0;
            this.normalNightCharge = 0;
            this.urgentNightCharge = 0;

            this.ngOnInit();
            this.spinner.hide();
         }


      });

      // Do some stuff
   }

   calculateRoundTrip() {
      if (this.jobTypeSelection !== 1) {
         return
      }
      var taxPercentage = (this.adminTax + this.adminProcessingFee) / 100;
      const roundTripModifier = this.isRoundTrip ? (1 + this.roundTripFee) : 1;

      this.driver_charges_normal = this.original_driver_charges_normal * roundTripModifier + Number(this.totalTagsPrices) + Number(this.weekend_charge_normal) + Number(this.normalNightCharge);
      this.normalCharges = this.driver_charges_normal * (1 + taxPercentage);

      this.driver_charges_urgent = this.original_driver_charges_urgent * roundTripModifier + Number(this.totalTagsPrices) + Number(this.weekend_charge_urgent) + Number(this.urgentNightCharge);
      this.urgentCharges = this.driver_charges_urgent * (1 + taxPercentage);
   }
   pharmacy_address: any;
   pharmacy_latLong: any;
   pharmacy_name: any;

   ngOnInit() {
      this.spinner.show();

      this.totalTagsPrices = 0;
      this.selectedDispatchForJob = [];
      this.connectedDispatches = [];
      this.packageObj = { package: '0', small: '0', large: '0', extraLarge: '0', label: '0/0', weight: '0' };

      this.jobTimingObj = {
         type: 0,
         startTime: { hour: 9, minute: 30 },
         endTime: { hour: 12, minute: 30 },
         nameEn: "",
         nameFr: ""

      }

      this.attachedDocument = { url: "", docName: "" };
      this.establishment_phone = "";
      this.duplicate_job_id = "";
      this.parameterService.getOneParameter(`time-for-urgent-jobs`).subscribe((data) => {

         const arrayHour = data.parameter.value.split(":");
         const hour = arrayHour[0];
         const minute = arrayHour[1];
         this.hourUrgent = `${hour}:${minute}-${parseInt(hour) + 1}:${minute} `
      });


      this.customer_type_delivery = JSON.parse(localStorage.getItem("customer_type_delivery")) ? JSON.parse(localStorage.getItem("customer_type_delivery")) : 1;
      this.customer_type_pickup = JSON.parse(localStorage.getItem("customer_type_pickup")) ? JSON.parse(localStorage.getItem("customer_type_pickup")) : 1;

      if (this.customer_type_delivery != CustomerType.RESIDENCE && this.customer_type_delivery != CustomerType.CLIENT && this.customer_type_delivery != CustomerType.MEDICAL && this.customer_type_delivery != CustomerType.CPO) {
         this.customer_type_delivery = CustomerType.RESIDENCE;
      }
      if (this.customer_type_pickup != CustomerType.RESIDENCE && this.customer_type_pickup != CustomerType.CLIENT && this.customer_type_pickup != CustomerType.MEDICAL && this.customer_type_pickup != CustomerType.CPO) {
         this.customer_type_pickup = CustomerType.RESIDENCE;
      }

      this.dropdownList = [
         {
            item_id: 0,
            item_text: 'Sunday',
            item_text_french: "Dimanche"
         },
         {
            item_id: 1,
            item_text: 'Monday',
            item_text_french: "Lundi"
         },
         {
            item_id: 2,
            item_text: 'Tuesday',
            item_text_french: "Mardi"
         },
         {
            item_id: 3,
            item_text: 'Wednesday',
            item_text_french: "Mercredi"
         },
         {
            item_id: 4,
            item_text: 'Thursday',
            item_text_french: "Jeudi"
         },
         {
            item_id: 5,
            item_text: 'Friday',
            item_text_french: "Vendredi"
         },
         {
            item_id: 6,
            item_text: 'Saturday',
            item_text_french: "Samedi"
         },
      ];
      this.dropdownSettings = {
         singleSelection: false,
         noDataAvailablePlaceholderText: "N/A",
         idField: 'item_id',
         textField: 'item_text',
         selectAllText: 'Select All',
         unSelectAllText: 'Unselect All',
         itemsShowLimit: 3,
         allowSearchFilter: false,

      };

      this.dropdownSettings1 = {
         singleSelection: false,
         noDataAvailablePlaceholderText: "N/A",
         idField: '_id',
         textField: 'username',
         selectAllText: 'Select All',
         unSelectAllText: 'Unselect All',
         itemsShowLimit: 3,
         allowSearchFilter: true
      };

      this.dropdownSettings2 = {
         singleSelection: false,
         noDataAvailablePlaceholderText: "N/A",
         idField: '_id',
         textField: 'username',
         selectAllText: 'Select All',
         unSelectAllText: 'Unselect All',
         itemsShowLimit: 3,
         allowSearchFilter: true
      };

      this.dropdownSettingsDispatch = {
         singleSelection: false,
         noDataAvailablePlaceholderText: "N/A",
         idField: '_id',
         textField: 'companyName',
         selectAllText: 'Select All',
         unSelectAllText: 'Unselect All',
         itemsShowLimit: 5,
         allowSearchFilter: true,
         // limitSelection : 2
      }


      this.weekDays = [];
      this.dailyJobType = 1;
      this.selectedValue = "8";
      this.dateValue = '0';
      this.job_recurring_type = 1;
      this.weekend_charge_normal = 0;
      this.weekend_charge_urgent = 0

      this.disableTags = true;
      this.normalNightCharge = 0;
      this.urgentNightCharge = 0;

      this.jobTypeSelection = 2;
      this.jobFor = "2";

      this.selectedTabIndex = JSON.parse(localStorage.getItem("formType"));

      if (this.selectedTabIndex != 'tab1' && this.selectedTabIndex != 'tab2') {
         this.selectedTabIndex = 'tab1';
      }
      localStorage.setItem("formType", JSON.stringify(this.selectedTabIndex));

      this.job_type_selection = this.selectedTabIndex == 'tab2' ? '2' : '1';

      this.customer_type = this.job_type_selection == '1' ? this.customer_type_delivery : this.customer_type_pickup;

      this.language = JSON.parse(localStorage.getItem("language"));
      if (!this.language) {
         this.language = "en"
      }
      if (this.language == 'fr') {
         this.dropdownSettings.textField = 'item_text_french';
         this.dropdownSettings.selectAllText = 'Tout sélectionner';
         this.dropdownSettings.unSelectAllText = 'Tout déselectionner';
      } else {
         this.dropdownSettings.textField = 'item_text';
         this.dropdownSettings.selectAllText = "Select All";
         this.dropdownSettings.unSelectAllText = 'Unselect All';
      }
      this.timeZone = moment.tz.guess();
      const current = new Date();
      this.config.minDate = {
         month: current.getMonth() + 1,
         day: current.getDate(),
         year: current.getFullYear(),
      };
      var localData = JSON.parse(localStorage.getItem("currentUser"));
      this.dial_code = localData.country_code;

      this.jobForm = this.formBuilder.group({
         location1: ['', Validators.required],
         customer_name: ['', [Validators.maxLength(30)]],
         customer_L_name: ['', [Validators.maxLength(30)]],
         establishment_name: ['', [Validators.maxLength(30)]],
         customer_email: [''],
         appartment_no: [''],
         door_code: [''],
         note: [''],
         customer_note: [''],
         customer_parent: [''],
         to_be_received_by_type: [''],
         to_be_received_by_name: [''],
         item_price: ['', [Validators.required, Validators.maxLength(6), Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")]],
         jobDate: [{
            year: current.getFullYear(),
            month: current.getMonth() + 1,
            day: current.getDate()
         }, Validators.required],
         initials_delivery: [''],
         establishment_email: [''],


      });

      // Init addJobTagForm
      this.addJobTagForm = this.formBuilder.group({
         tag_code: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(10)]],
         tag_for: ['', [Validators.required, Validators.minLength(2)]],
         tag_for_french: ['', [Validators.required, Validators.minLength(2)]],
      });

      this.iban_no = ''
      this.dateForJob = this.f.jobDate.value.year + '-' + this.f.jobDate.value.month + '-' + this.f.jobDate.value.day;

      //*Get Pharmacy Info
      this.services.getPharmacyProfile(localData._id).pipe().subscribe(data => {

         this.pharmacyProfile = data.result;
         this.pharmacy_address = this.pharmacyProfile.location;
         this.pharmacy_latLong = this.pharmacyProfile.lat_long;
         this.pharmacy_name = this.pharmacyProfile.pharmacy_name;
      });

      //GET SELECTED DRIVERS AND TAXES API START HERE
      this.services.getAllDrivers(localData._id, this.dateForJob, this.customer_type).pipe().subscribe(data => {
         if (data.code == 400) {
            this.spinner.hide();
            this.toastr.error('', data.message);
            this.commonMethods.logout();
            return;
         }
         if (data.code == 200) {

            //  TAG DATA START
            this.job_tags = data.jobTags;
            this.tags = [];
            this.dog = true;
            this.delivery_type = "2";
            this.cat = true;
            this.totalTagsPrices = 0;
            // TAG DATA END

            //DISPATCH DATA START HERE

            this.connectedDispatches = data.connectedDispatches;


            if (this.connectedDispatches.length) {//FOR DISPATCH
               this.jobTypeSelection = 3;

               this.isPrivate = "3";

               this.isDispatchSelected = true;

               this.selectedDispatchForJob = data.pharmacy.preffered_dispatches;

            }

            this.pharmacy = data.pharmacy;
            this.adminTax = data.adminStateTax.totalTax;
            this.state_tax_id = data.adminStateTax._id;
            this.stateTaxObj = data.adminStateTax;

            this.stateProcessingFees = data.adminStateTax.processingFee;
            this.adminProcessingFee = data.priceFromAdmin.processing_fee;
            this.nightCharge = data.priceFromAdmin.night_charge;
            this.weekendCharge = data.priceFromAdmin.weekend_charge;
            // this.userCards = data.cardsData;
            this.selectedCard = data.selected_card;

            this.drivers = data.myDrivers;
            this.username = data.preffered_drivers;
            if (this.username.length) {
               this.onSelectAll1(this.username);
            }

            this.drivers.map((driver) => driver.username = `${driver.fullname} (${driver.username})`);

            this.spinner.hide();

            // check if card is selected or not
            if (!this.selectedCard) {
               this.cardModal.show();

            } else if (data.subscription == false) {
               this.subscriptionModal.show();
            }

         } else {
            this.toastr.error('error11', data.message);
            this.spinner.hide();
         }
      });


      this.user_id = localData._id;

      // for customer first name
      const customerNameControl = this.jobForm.controls["customer_name"];
      customerNameControl.valueChanges.subscribe(value => {
         this.customer_id = '';
         this.cust_name = value;

         if (!value?.location) {
            this.jobForm.controls['location1'].setValue('');
            this.location1 = '';
            this.jobForm.controls['location1'].enable();
         }

         if (!value.establishment_id) {
            this.jobForm.controls['location1'].enable();
            this.multiple_customers_id = [];
            // this.jobForm.controls['customer_L_name'].setValue(value);
            // this.cust_l_name = value.customer_l_name;
            this.jobForm.controls['customer_L_name'].setValue('');
            this.jobForm.controls['customer_email'].setValue('');
            this.iban_no = '';
         }

         if (value.customer_name || value.customer_f_name) {
            this.jobForm.controls['customer_email'].setValue(value.email);
            this.jobForm.controls['appartment_no'].setValue(value.appartment_no);
            this.jobForm.controls['door_code'].setValue(value.door_code);
            this.jobForm.controls['customer_note'].setValue(value.customer_note);
            this.jobForm.controls['customer_parent'].setValue(value.customer_parent);

            //this.jobForm.controls['to_be_received_by_type'].setValue(value.to_be_received_by_type);
            this.jobForm.controls['to_be_received_by_name'].setValue(value.to_be_received_by_name);

            // this.jobForm.controls['customer_L_name'].setValue(value.customer_l_name);
            this.jobForm.controls['customer_L_name'].setValue(value);


            this.cust_name = value.customer_f_name ? value.customer_f_name : value.customer_name;
            // this.cust_l_name = value.customer_l_name;
            this.iban_no = value.phone_no;
            this.multiple_customers_id.push(value._id);
            if (value.establishment_id) {
               this.jobForm.controls['location1'].disable();
               this.customer_est_id = value.establishment_id ? value.establishment_id : null;
            }
            if (value.location && value.lat_long.length && this.customer_type == 2) {
               this.customer_id = value?._id
               this.jobForm.controls['location1'].setValue(value.location);
               this.customerDelivery(value.lat_long, value.location, this.dateForJob);

               this.services.getAddressesClient(value._id).subscribe((data: any) => {
                  this.customerAddresses = [{ default: false, address: 'New address', address_fr: 'Nouvelle adresse' }, ...data?.directions];
                  const addressDefault = data?.directions.find((address) => address?.default);
                  if (addressDefault) {
                     this.selectedAddress.setValue(addressDefault);
                     this.jobForm.controls['location1'].disable();
                  }
               })

               this.locationData = {
                  lat: value.lat_long[0],
                  lng: value.lat_long[1],
                  address: value.location
               }
            }
         }

      });



      //SEARCH ESTABLISHMENT NAME 
      const establishmentNameControl = this.jobForm.controls["establishment_name"];
      establishmentNameControl.valueChanges.subscribe(value => {
         this.customer_id = '';
         this.residenceAllClient = [];
         this.residenceAllClients = [];
         this.establish_name = value;
         if (value.establishment_name) {
            this.establish_name = value.establishment_name;
            this.establishment_id = value._id;

            value.establishment_email ? this.jobForm.controls['establishment_email'].setValue(value.establishment_email) : this.jobForm.controls['establishment_email'].setValue("");

            value.establishment_phone ? this.establishment_phone = value.establishment_phone : this.establishment_phone = "";

            if (value.location && value.lat_long.length) {
               this.jobForm.controls['location1'].setValue(value.location);
               this.jobForm.controls['customer_note'].setValue(value.customer_note);
               this.jobForm.controls['customer_parent'].setValue(value.customer_parent);
               this.jobForm.controls['to_be_received_by_name'].setValue(value.to_be_received_by_name);
               this.customerDelivery(value.lat_long, value.location, this.dateForJob);

               this.jobForm.controls['location1'].enable();
               this.locationData = {
                  lat: value.lat_long[0],
                  lng: value.lat_long[1],
                  address: value.location
               }
            }

            if (this.customerTypeSelection == 2) {
               this.search_customer('');
            }
         }
         if (!value.establishment_name && value.trim() === "") {
            establishmentNameControl.setErrors({
               required: true
            });
         }
      });



      const itemPriceControl = this.jobForm.controls["item_price"];
      itemPriceControl.valueChanges.subscribe(value => {
         if (value.trim() === "") {
            itemPriceControl.setErrors({
               required: true
            })
         }
      })

      this.timeForAutoJob = {
         hour: 9,
         minute: 30
      };

   }

   ngAfterViewInit() {
      this.tabset.tabs[parseInt(this.job_type_selection) - 1].active = true;
   }
   get f() {
      return this.jobForm.controls;
   }

   createJob() {

      let recursiveEndDate = null
      if (!this.isContinuousJob) {
         recursiveEndDate = this.recursiveEndDateDelivery ? this.ngbDateParserFormatter.format(this.recursiveEndDateDelivery) : '';
      }

      this.cust_l_name = typeof this.f.customer_L_name.value == "object" ? this.f.customer_L_name.value.customer_l_name.toUpperCase() : this.f.customer_L_name.value.toUpperCase();

      let selectedDate = this.ngbDateParserFormatter.format(this.f.jobDate.value);
      let timeZone = moment.tz.guess();

      const format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;

      this.submitted = true;
      if (this.pharmacy.payment_due == true) {
         this.toastr.error('', 'Please pay pending dues');
         return;
      }

      if (this.customer_type != 2) {// FOR ESTABLISHMENTS
         if (!this.establish_name) {
            this.language == "fr" ? this.toastr.error('', "Nom de l'établissement requis!") : this.toastr.error('', 'Establishment name required!');
            return;
         }
         if (this.establish_name) {
            let testing = format.test(this.establish_name);
            if (testing == true) {
               this.toastr.error('', 'Establishment name not valid!');
               return;
            }
         }

         if (this.f.customer_note.value) {
            let testing = format.test(this.f.customer_note.value);
            if (testing == true) {
               this.toastr.error('', 'note not valid!');
               return;
            }
         }

         if (this.f.to_be_received_by_name.value) {
            let testing = format.test(this.f.to_be_received_by_name.value);
            if (testing == true) {
               this.toastr.error('', 'Establishment name not valid!');
               return;
            }
         }

         if (this.establish_name.length > 30) {
            this.toastr.error('', 'Establishment name too long!');
            return;
         }

         if (this.establishment_phone && this.establishment_phone.length != 12) {
            this.toastr.error('', 'Establishment phone number must be 10 digits!');
            return;

         }

         if (this.customerTypeSelection != 2 && this.establishmentTagSelection == '0') {
            if (this.cust_name || this.iban_no || this.cust_l_name) {

               if (!this.cust_name) {
                  this.language == "fr" ? this.toastr.error('', 'Prénom du client requis!') : this.toastr.error('', 'Customer first name required!');
                  return;
               }

               let testing = format.test(this.cust_name);
               if (testing == true) {
                  this.toastr.error('', 'Customer first name not valid!');
                  return;
               }
               if (this.cust_name.length > 30) {
                  this.toastr.error('', 'Customer first name too long!');
                  return;
               }
               if (!this.cust_l_name) {// customer last name
                  this.toastr.error('', 'Customer last name required!');
                  return;
               }
               if (!this.iban_no) {
                  this.language == 'fr' ? this.toastr.error('', 'Numéro de téléphone client requis!') : this.toastr.error('', 'Customer phone number required!');
                  return;

               }

               if (this.iban_no.length != 12) {
                  this.toastr.error('', 'Customer phone number must 10 digits!');
                  return;
               }

            }
         }
      }

      if (this.customer_type == 2) {// IF CLIENT SELECTED
         if (!this.cust_name) {
            this.language == "fr" ? this.toastr.error('', 'Prénom du client requis!') : this.toastr.error('', 'Customer first name required!');
            return;
         }

         let testing = format.test(this.cust_name);
         if (testing == true) {
            this.toastr.error('', 'Customer first name not valid!');
            return;
         }
         if (this.cust_name.length > 30) {
            this.toastr.error('', 'Customer first name too long!');
            return;
         }
         if (!this.cust_l_name) {// customer last name
            this.toastr.error('', 'Customer last name required!');
            return;
         }

         if (!this.iban_no) {
            this.language == 'fr' ? this.toastr.error('', 'Numéro de téléphone client requis!') : this.toastr.error('', 'Customer phone number required!');
            return;

         }
         if (this.iban_no.length != 12) {
            this.toastr.error('', 'Customer phone number must 10 digits!');
            return;
         }

      }

      if (!this.location1) {
         this.toastr.error('', 'Please enter location');
         return;
      }

      if (this.customerTypeSelection == 2 && !this.multiple_customers_id.length) {
         return this.toastr.error('', 'Please select one client');
      }


      let emailValidPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (this.f.establishment_email.value) {
         let emailIsValid = emailValidPattern.test(String(this.f.establishment_email.value).toLowerCase());
         if (emailIsValid == false) {
            this.toastr.error('', 'Establishment email not valid');
            return;

         }

      }

      if (this.f.customer_email.value) {
         let emailIsValid = emailValidPattern.test(String(this.f.customer_email.value).toLowerCase());
         if (emailIsValid == false) {
            this.toastr.error('', 'Customer email not valid');
            return;
         }
      }

      if (this.job_type_selection == '2' || this.customer_type == 4) {// CPO SELECTED
         this.jobForm.controls['item_price'].setValue('0');
      }

      // stop here if form is invalid
      if (this.customerTypeSelection != 2 && this.establishmentTagSelection == '0') {
         if (this.jobForm.invalid) {

            if (this.job_type_selection == '1' && !this.f.item_price.value) {
               this.toastr.error('', 'Please enter item price');
               return;
            }
            if (this.job_type_selection == '1' && this.f.item_price.errors) {
               this.toastr.error('', 'Please enter valid item price');
               return;
            }
            if (!selectedDate) {
               this.toastr.error('', 'Please select job date ');
               return;
            }
            return;
         }
      }


      if (this.job_type_selection == '1' && !this.f.item_price.value) {

         this.toastr.error('', 'Please enter item price');
         return;
      }
      if (!selectedDate) {
         this.toastr.error('', 'Please select job date ');
         return;
      }
      // var timingTagStatus = false;
      // for (var tag of this.tags) {
      //    if (tag.tag_type == "9" || tag.tag_type == "10" || tag.tag_type == "11" || tag.tag_type == "12") {
      //       timingTagStatus = true;
      //    }
      // }

      // if (timingTagStatus == false && this.jobFor == '1') { // if public selected
      //    this.toastr.error('', 'Please select a job timing tag');
      //    return;
      // }

      if (!this.checkedTimingTags()) {
         this.toastr.error('', this.translate.instant("CREATEJOB.missing_timing_tag"));
         return;
      }

      if (!this.job_recurring_type) {
         this.toastr.error('', 'Please select job recurring type');
         return;
      }
      if (this.job_recurring_type == 2 || this.job_recurring_type == 3 || this.job_recurring_type == 4 || this.job_recurring_type == 5 || this.job_recurring_type == 6) {
         if (!recursiveEndDate && !this.isContinuousJob) {
            this.toastr.error('', 'Please select job recurring end date');
            return;

         }
         if (!this.timeForAutoJob) {
            this.toastr.error('', 'Please select job recurring timing');
            return;

         }
         var dailyDate = selectedDate;
         var dtime = {
            hours: this.timeForAutoJob.hour.toString(),
            min: this.timeForAutoJob.minute.toString()
         };
         let maxDateRecursive = moment(selectedDate, 'YYYY/MM/DD').add(3, 'months').format('YYYY/MM/DD');
         if (!this.isContinuousJob) {
            if (moment(recursiveEndDate).isAfter(maxDateRecursive)) {
               this.toastr.error('', 'Recursive end date must within 3 months');
               return;
            }
            if (moment(recursiveEndDate).isSameOrBefore(selectedDate)) {
               this.toastr.error('', "Recursive end date must after job date");
               return;
            }
         }

      }

      if (this.job_recurring_type == 3 && this.weekDays.length == 0) {
         this.toastr.error('', 'Please select day for weekely recurring');
         return;
      }

      var daysArray = [];
      if (this.weekDays.length) {
         for (var day of this.weekDays) {
            if (day.item_id == 1) {
               var mon = {
                  item_id: day.item_id,
                  item_text: "Monday",
                  item_text_french: "Lundi"
               };
               daysArray.push(mon);
            } else if (day.item_id == 2) {
               var tue = {
                  item_id: day.item_id,
                  item_text: "Tuesday",
                  item_text_french: "Mardi"
               };
               daysArray.push(tue);
            } else if (day.item_id == 3) {
               var wed = {
                  item_id: day.item_id,
                  item_text: "Wednesday",
                  item_text_french: "Mercredi"
               };
               daysArray.push(wed);
            } else if (day.item_id == 4) {
               var thu = {
                  item_id: day.item_id,
                  item_text: "Thursday",
                  item_text_french: "Jeudi"
               };
               daysArray.push(thu);
            } else if (day.item_id == 5) {
               var fri = {
                  item_id: day.item_id,
                  item_text: "Friday",
                  item_text_french: "Vendredi"
               };
               daysArray.push(fri);
            } else if (day.item_id == 6) {
               var sat = {
                  item_id: day.item_id,
                  item_text: "Saturday",
                  item_text_french: "Samedi"
               };
               daysArray.push(sat);
            } else if (day.item_id == 0) {
               var sun = {
                  item_id: day.item_id,
                  item_text: "Sunday",
                  item_text_french: "Dimanche"
               };
               daysArray.push(sun);
            }

         }

      }
      var weekDayz = daysArray;

      var night_charge = 0;
      var weekendCharge = 0;
      if (this.delivery_type == '1') {
         this.ride_fare = this.urgentCharges;
         this.ride_fare_without_tax = this.driver_charges_urgent;
         this.other_charges = this.normalCharges;
         night_charge = this.urgentNightCharge;
         weekendCharge = this.weekend_charge_urgent;
      } else if (this.delivery_type == '2') {
         this.ride_fare = this.normalCharges;
         this.ride_fare_without_tax = this.driver_charges_normal
         this.other_charges = this.urgentCharges;
         night_charge = this.normalNightCharge;
         weekendCharge = this.weekend_charge_normal;
      } else {
         this.toastr.error('error', 'something wrong');
         return;
      }
      if (!this.selectedCard) {
         this.toastr.error('error', 'Please add a card');
         return;
      }
      if (!this.jobFor) {
         this.toastr.error('error', 'Please select public or private');
         return;
      }
      var job_type = "1";
      // var driverId = "";
      var selectedDispatchForJobIds = [];
      if (this.jobFor == "2" && !this.isDispatchSelected) {// PRIVATE
         if (!this.selected_drivers.length) {
            return this.toastr.error('', 'Please select a driver');
         } else {
            if (this.selected_drivers.length > 8) {
               return this.toastr.error('', `You can't select more than 8 drivers`);
            }

         }
         this.selectedDispatchForJob = [];
         selectedDispatchForJobIds = [];


      } else if (this.jobFor == "2" && this.isDispatchSelected) {// DISPATCH
         if (!this.selectedDispatchForJob.length) {
            return this.toastr.error('', 'Please select a dispatch');
         }
         if (this.selectedDispatchForJob.length > 1) {
            return this.toastr.error('', `You can't select more than 1 dispatch`);

         }

         this.selected_drivers = [];
         this.selectedDispatchForJob.map(element => selectedDispatchForJobIds.push(element._id));


      } else {

         this.selected_drivers = [];
         this.selectedDispatchForJob = [];
         selectedDispatchForJobIds = [];
      }
      //LOOP TO ADD OBJECT INSTED OF HIS ID
      this.multiple_customers = this.multiple_customers_id;
      this.multiple_customers_info = [];
      for (var client of this.multiple_customers_id) {
         for (const clientObj of this.customers) {
            if (client == clientObj._id) {
               this.multiple_customers_info.push(clientObj);
            }
         }
      }


      var monthlyDate = moment(selectedDate, 'YYYY/MM/DD').add(1, 'months').format('YYYY-MM-DD');
      if (this.jobTypeSelection == 2 || this.jobTypeSelection == 3) {// FOR PRIVATE/ DISPATCH JOBS TAGSPRICES MUST 0
         this.totalTagsPrices = 0;
      }

      if (this.jobTimingObj.type == 3) {// BETWEEN SELCECTED
         if (this.jobTimingObj.startTime.hour > this.jobTimingObj.endTime.hour || (this.jobTimingObj.startTime.hour == this.jobTimingObj.endTime.hour && this.jobTimingObj.startTime.minute > this.jobTimingObj.endTime.minute)) {
            return this.toastr.error('', `Invalid time range`);

         }

      }

      const customer_email_aux = this.customer_type == 2 && !this.f.customer_email.value ? 'default@customer.com' : this.f.customer_email.value;
      const establishment_email_aux = this.customer_type != 2 && !this.f.establishment_email.value ? 'default@establishment.com' : this.f.establishment_email.value;

      this.spinner.show();

      const data = {
         customer_email_aux,
         establishment_email_aux,
         job_type, selectedDate,
         timeZone, night_charge,
         weekendCharge,
         dailyDate,
         dtime,
         weekDayz,
         monthlyDate,
         recursiveEndDate,
         selectedDispatchForJobIds,
      }

      this.services.search_parent_jobs(this.f.customer_parent.value, this.f.location1.value,).pipe().subscribe(d => {

         if (d.code == 200) {
            if (d.result.parentJobs.length) {
               if (confirm('parent jobs found')) {
                  this.parent_job = d.result.parentJobs[0]._id
               }
            }
            else if (d.result.brotherJobs.length) {
               if (confirm('brother jobs found')) {
                  this.parent_job = d.result.brotherJobs[0].parent_job
               }
            }
            else if (d.result.childJobs.length) {
               if (confirm('child jobs found')) {
                  this.parent_job = d.result.childJobs[0].parent_job
               }
            }
            else this.parent_job = ''
         }

         if (this.job_type_selection == '1') {
            this.createDeliveryJob(data);
         } else {
            this.createPickupJob(data);
         }

         return;
      });


   }


   createDeliveryJob(data) {
      this.services.createJobPharmacy(this.user_id,
         this.pharmacyProfile.location,
         this.location1.trim(),
         this.cust_name ? this.cust_name.trim() : '',
         this.iban_no,
         this.f.note.value.trim(),
         this.f.customer_note.value,
         this.f.customer_parent.value,
         this.to_be_received_by_type,
         this.f.to_be_received_by_name.value,
         this.delivery_type,
         this.customer_type,
         this.pharmacy_latLong,
         this.eLat_long,
         this.overallTax.toString(),
         this.ride_fare.toString(),
         this.distance.toString(),
         this.job_from,
         this.f.item_price.value.trim(),
         data.job_type,
         data.selectedDate,
         this.tags,
         this.tax.toString(),
         this.processing_fee.toString(),
         this.ride_fare_without_tax.toString(),
         data.customer_email_aux,
         this.f.appartment_no.value,
         this.f.door_code.value,
         this.dial_code,
         this.other_charges,
         data.timeZone,
         data.night_charge,
         data.weekendCharge,
         this.job_recurring_type,
         data.dailyDate,
         data.dtime,
         data.weekDayz,
         data.monthlyDate,
         this.dailyJobType,
         this.cust_name ? this.cust_name.trim() : '',
         this.cust_l_name.trim(),
         this.establish_name ? this.establish_name.trim() : '',
         this.selected_drivers,
         this.stateProcessingFees,
         this.adminTax,
         this.state_tax_id,
         this.f.initials_delivery.value,
         this.multiple_customers,
         this.customerTypeSelection,
         this.multiple_customers_info,
         this.establishmentTagSelection,
         data.recursiveEndDate,
         this.isPrivate,
         this.totalTagsPrices,
         null,
         data.selectedDispatchForJobIds,
         this.stateTaxObj,
         data.establishment_email_aux,
         this.packageObj,
         this.jobTimingObj,
         this.attachedDocument,
         this.establishment_phone,
         this.isContinuousJob,
         this.isRoundTrip,
         this.parent_job).pipe().subscribe(data => {
            if (data.code == 400) {
               this.spinner.hide();
               this.toastr.error('', data.message);
               this.commonMethods.logout();
               return;

            }
            if (data.code == 402) {
               this.spinner.hide();
               this.subscriptionModal.show();
               return;
            }
            if (data.code == 200) {
               if (this.selectedFile) {
                  this.uploadFile(data.result);
               }
               this.spinner.hide();
               this.parentModalRef?.hide();
               this.toastr.success("", "Job created successfully");
               return;

            } else {
               this.spinner.hide();

               this.toastr.error('error', data.message);
               return;
            }
         });
   }

   createPickupJob(data) {
      this.services.createPickupJob(this.user_id,
         this.location1.trim(),
         this.pharmacyProfile.location,
         this.cust_name ? this.cust_name.trim() : '',
         this.iban_no,
         this.f.note.value.trim(),
         this.delivery_type,
         this.customer_type,
         this.eLat_long,
         this.pharmacy_latLong,
         this.overallTax.toString(),
         this.ride_fare.toString(),
         this.distance.toString(),
         this.job_from,
         data.job_type,
         data.selectedDate,
         this.tags,
         this.tax.toString(),
         this.processing_fee.toString(),
         this.ride_fare_without_tax.toString(),
         data.customer_email_aux,
         this.f.appartment_no.value,
         this.f.door_code.value,
         this.f.customer_note.value,
         this.f.customer_parent.value,
         this.to_be_received_by_type,
         this.f.to_be_received_by_name.value,
         this.dial_code,
         this.other_charges,
         data.timeZone,
         data.night_charge,
         data.weekendCharge,
         this.job_recurring_type,
         data.dailyDate,
         data.dtime,
         data.weekDayz,
         data.monthlyDate,
         this.dailyJobType,
         this.cust_name ? this.cust_name.trim() : '',
         this.cust_l_name.trim(),
         this.establish_name ? this.establish_name.trim() : '',
         this.selected_drivers,
         this.stateProcessingFees,
         this.adminTax,
         this.state_tax_id,
         this.f.initials_delivery.value,
         this.multiple_customers,
         this.customerTypeSelection,
         this.multiple_customers_info,
         this.establishmentTagSelection,
         data.recursiveEndDate,
         this.isPrivate,
         this.totalTagsPrices,
         null,
         data.selectedDispatchForJobIds,
         this.stateTaxObj,
         data.establishment_email_aux,
         this.packageObj,
         this.jobTimingObj,
         this.attachedDocument,
         this.establishment_phone,
         this.isContinuousJob,
         this.isRoundTrip,
         this.parent_job).pipe().subscribe(data => {
            if (data.code == 400) {
               this.spinner.hide();
               this.toastr.error('', data.message);


               this.commonMethods.logout();
               return;
            }
            if (data.code == 402) {
               this.spinner.hide();
               this.subscriptionModal.show();
               return;
            }
            if (data.code == 200) {
               if (this.selectedFile) {
                  this.uploadFile(data.result);
               }
               this.spinner.hide();
               this.parentModalRef?.hide();
               this.toastr.success("", "Job created successfully");
               return;

            } else {
               this.spinner.hide();
               this.toastr.error('error', data.message);
               return;
            }
         });

   }


   fetchNews(event) {
      this.formType = event.id

      localStorage.setItem('formType', JSON.stringify(this.formType));
      this.job_type_selection = this.formType == 'tab2' ? '2' : '1';

      this.refresh()

   }



   selectChangeHandler(event: any) {
      //update the ui
      this.selectedDriverId = event.target.value;
      if (this.selectedDriverId == "Select driver") {
         this.selectedDriverId = "";
      }
   }


   selectJobFor(type) {
      if (this.eLat_long && this.eLat_long.length && this.location1 && type == '1') {
         this.customerDelivery(this.eLat_long, this.location1, this.dateForJob)
      }
      if (type == "1") {// FOR PUBLIC
         this.isPrivate = "";
         this.isDispatchSelected = false;
         this.jobTypeSelection = parseInt(type);
         this.jobFor = type
      }
      if (type == "2") { // FOR PRIVATE
         this.isPrivate = "2";
         this.tax = 0;
         this.processing_fee = 0;
         this.normalCharges = 0; // normal charges
         this.riceNormalChanges = 0;
         this.urgentCharges = 0; // urgent charges
         this.overallTax = 0; // total tax
         this.driver_charges_normal = 0;
         this.driver_charges_urgent = 0;
         this.taxTps = 0;
         this.normalNightCharge = 0;
         this.urgentNightCharge = 0;
         this.weekend_charge_normal = 0;
         this.weekend_charge_urgent = 0;
         this.jobTypeSelection = parseInt(type);
         this.jobFor = type
         this.isDispatchSelected = false;
      }

      if (type == "3") {// TYPE 3 FOR SELECT DISPATCH COMPANY
         this.isPrivate = "3";
         this.tax = 0;
         this.processing_fee = 0;
         this.normalCharges = 0; // normal charges
         this.riceNormalChanges = 0;
         this.urgentCharges = 0; // urgent charges
         this.overallTax = 0; // total tax
         this.driver_charges_normal = 0;
         this.driver_charges_urgent = 0;
         this.taxTps = 0;
         this.normalNightCharge = 0;
         this.urgentNightCharge = 0;
         this.weekend_charge_normal = 0;
         this.weekend_charge_urgent = 0;
         this.jobTypeSelection = parseInt(type);
         this.jobFor = '2';
         this.isDispatchSelected = true;
      }

   }




   forPermanent(type) {

      this.isPrivate = type
   }



   onChangeCategory(event, tag: any) { // Use appropriate model type instead of any

      if (tag.running == false) { // if tag selected
         // this.selected_type = tag.tag_type;

         if (tag.tag_type == "3") {// for URGENT TAG
            this.dog = false;
            this.delivery_type = "1";
         }
         tag.default_key = "2";
         if (tag.tag_type == "9") {// MORNING
            var pos1 = this.tags.map(function (e) {
               return e.tag_type;
            }).indexOf('10');
            var pos2 = this.tags.map(function (e) {
               return e.tag_type;
            }).indexOf('11');
            var pos3 = this.tags.map(function (e) {
               return e.tag_type;
            }).indexOf('12');
            if (pos1 > -1) {
               this.tags[pos1].running = true; // makes unselected
               this.tags[pos1].default_key = "1";
               this.tags.splice(pos1, 1);
            }
            if (pos2 > -1) {
               this.tags[pos2].running = true; // makes unselected
               this.tags[pos2].default_key = "1";
               this.tags.splice(pos2, 1);
            }
            if (pos3 > -1) {
               this.tags[pos3].running = true; // makes unselected
               this.tags[pos3].default_key = "1";
               this.tags.splice(pos3, 1);
            }
            if (this.eLat_long && this.eLat_long.length && this.location1) {

               this.customerDelivery(this.eLat_long, this.location1, this.dateForJob)
            }
         }
         if (tag.tag_type == "10") {// AFTERNOON
            var pos1 = this.tags.map(function (e) {
               return e.tag_type;
            }).indexOf('9');
            var pos2 = this.tags.map(function (e) {
               return e.tag_type;
            }).indexOf('11');
            var pos3 = this.tags.map(function (e) {
               return e.tag_type;
            }).indexOf('12');
            if (pos1 > -1) {
               this.tags[pos1].running = true; // makes unselected
               this.tags[pos1].default_key = "1";
               this.tags.splice(pos1, 1);
            }
            if (pos2 > -1) {
               this.tags[pos2].running = true; // makes unselected
               this.tags[pos2].default_key = "1";
               this.tags.splice(pos2, 1);
            }
            if (pos3 > -1) {
               this.tags[pos3].running = true; // makes unselected
               this.tags[pos3].default_key = "1";
               this.tags.splice(pos3, 1);
            }
            if (this.eLat_long && this.eLat_long.length && this.location1) {
               this.customerDelivery(this.eLat_long, this.location1, this.dateForJob)
            }
         }
         if (tag.tag_type == "11") {// EVENING 
            var pos1 = this.tags.map(function (e) {
               return e.tag_type;
            }).indexOf('9');
            var pos2 = this.tags.map(function (e) {
               return e.tag_type;
            }).indexOf('10');
            var pos3 = this.tags.map(function (e) {
               return e.tag_type;
            }).indexOf('12');
            if (pos1 > -1) {
               this.tags[pos1].running = true; // makes unselected
               this.tags[pos1].default_key = "1";
               this.tags.splice(pos1, 1);
            }
            if (pos2 > -1) {
               this.tags[pos2].running = true; // makes unselected
               this.tags[pos2].default_key = "1";
               this.tags.splice(pos2, 1);
            }
            if (pos3 > -1) {
               this.tags[pos3].running = true; // makes unselected
               this.tags[pos3].default_key = "1";
               this.tags.splice(pos3, 1);
            }
            if (this.eLat_long && this.eLat_long.length && this.location1) {
               this.customerDelivery(this.eLat_long, this.location1, this.dateForJob)
            }

         }
         if (tag.tag_type == "12") { // night tag select
            var pos1 = this.tags.map(function (e) {
               return e.tag_type;
            }).indexOf('9');//MORNING
            var pos2 = this.tags.map(function (e) {
               return e.tag_type;
            }).indexOf('10');//AFTERNOON
            var pos3 = this.tags.map(function (e) {
               return e.tag_type;
            }).indexOf('11');//EVENING
            if (pos1 > -1) {
               this.tags[pos1].running = true; // makes unselected
               this.tags[pos1].default_key = "1";
               this.tags.splice(pos1, 1);
            }
            if (pos2 > -1) {
               this.tags[pos2].running = true; // makes unselected
               this.tags[pos2].default_key = "1";
               this.tags.splice(pos2, 1);
            }
            if (pos3 > -1) {
               this.tags[pos3].running = true; // makes unselected
               this.tags[pos3].default_key = "1";
               this.tags.splice(pos3, 1);
            }

            if (this.eLat_long && this.eLat_long.length && this.location1 && this.normalCharges && this.urgentCharges) {
               this.normalNightCharge = Math.round((this.original_driver_charges_normal * this.nightCharge - this.original_driver_charges_normal) * 100) / 100;

               this.urgentNightCharge = Math.round((this.original_driver_charges_normal * this.nightCharge - this.original_driver_charges_normal) * 100) / 100;

               this.calculateRoundTrip();
            }
         }
         if (tag.tag_price && (tag.tag_type != '9' || tag.tag_type != '10' || tag.tag_type != '11' || tag.tag_type != '12')) {// ADD TAGS PRICE

            if (this.eLat_long && this.eLat_long.length && this.location1 && this.normalCharges && this.urgentCharges) {

               this.driver_charges_normal = Math.round(((Number(this.driver_charges_normal) - Number(this.weekend_charge_normal)) + Number(tag.tag_price)) * 100) / 100;



               this.driver_charges_urgent = Math.round(((Number(this.driver_charges_urgent) - Number(this.weekend_charge_urgent)) + Number(tag.tag_price)) * 100) / 100;


               var totalTaxProcessing = Number(this.adminTax) + Number(this.adminProcessingFee);
               var normalTax = Math.round(((this.driver_charges_normal + Number(this.weekend_charge_normal)) / 100 * totalTaxProcessing) * 100) / 100;
               var urgentTax = Math.round(((this.driver_charges_urgent + Number(this.weekend_charge_urgent)) / 100 * totalTaxProcessing) * 100) / 100;

               this.normalCharges = Math.round((this.driver_charges_normal + Number(this.weekend_charge_normal) + normalTax) * 100) / 100;
               this.urgentCharges = Math.round((this.driver_charges_urgent + Number(this.weekend_charge_urgent) + urgentTax) * 100) / 100;

               this.taxTps = Number(this.driver_charges_normal * 5) / 100;

               this.driver_charges_normal = Math.round((this.driver_charges_normal + Number(this.weekend_charge_normal)) * 100) / 100;
               this.driver_charges_urgent = Math.round((this.driver_charges_urgent + Number(this.weekend_charge_urgent)) * 100) / 100;

            }

         }
         if (tag.tag_price) {
            this.totalTagsPrices += tag.tag_price;
         }
         this.tags.push(tag);
      }
      if (tag.running == true) { // if tag unselected
         var index = this.tags.indexOf(tag);

         if (index > -1) {
            tag.default_key = "1";
            if (tag.tag_price) {
               this.totalTagsPrices -= tag.tag_price;
            }
            this.tags.splice(index, 1);
            if (tag.tag_type == "3") {

               this.dog = true;
               this.delivery_type = "2";
            }
            if (tag.tag_type == "12" || (tag.tag_price && (tag.tag_type != '9' || tag.tag_type != '10' || tag.tag_type != '11' || tag.tag_type != '12'))) { // night unselected or other than timing tags with having price
               if (this.eLat_long && this.eLat_long.length && this.location1 && this.jobTypeSelection == 1) {

                  this.customerDelivery(this.eLat_long, this.location1, this.dateForJob)
               }

            }

         }
      }


   }


   onSelectTag(type, type1) {

      if (type == this.lastTypeSelected) {
         return console.log(`last type selected ${type}`);
      }
      this.lastTypeSelected = type;
      if (type == '16') {
         this.establishmentTagSelection = '1';
      } else if (type == '17') {
         this.establishmentTagSelection = '2';
      } else {
         this.establishmentTagSelection = '0';
      }

      this.selected_type = type;
      for (const tag of this.job_tags) {
         if (type == tag.tag_type) {//TAG SELECTED
            if (tag.tag_price) {// ADD TAG PRICE
               this.totalTagsPrices += tag.tag_price;
            }
            tag.running = false;
            this.tags.push(tag);
         }
      }
      if (Array.isArray(type1)) {
         type1.forEach(element => {
            this.unselectTag(element)
         })
      } else {
         this.unselectTag(type1)
      }


      this.residenceAllClient = [];

      this.residenceAllClient = [];
      this.jobForm.controls['customer_L_name'].setValue('');
      this.jobForm.controls['customer_name'].setValue('');
      this.jobForm.controls['customer_email'].setValue('');
      this.jobForm.controls['appartment_no'].setValue('');
      this.jobForm.controls['door_code'].setValue('');
      this.jobForm.controls['customer_note'].setValue('');
      this.jobForm.controls['customer_parent'].setValue('');
      this.jobForm.controls['to_be_received_by_name'].setValue('');

      this.iban_no = '';
      let selectedTag = this.tags.find(obj => obj.tag_type == type);
      if (this.eLat_long && this.eLat_long.length && this.location1 && selectedTag.tag_price && this.jobTypeSelection == 1) {
         this.customerDelivery(this.eLat_long, this.location1, this.dateForJob)
      }

   }


   unselectTag(tag_type) {
      let obj = this.tags.find(obj => obj.tag_type == tag_type);

      if (obj) {
         if (obj.tag_price) {// REMOVE TAG PRICE
            this.totalTagsPrices -= obj.tag_price;
         }
         obj.running = true;// TAG UNSELECTED
         var index = this.tags.indexOf(obj);
         obj.default_key = "1";
         this.tags.splice(index, 1);
      }
   }



   deliver_type(type) {
      this.delivery_type = type
      if (type == '2') { // if type normal
         let obj = this.tags.find(obj => obj.tag_type == '3');

         if (obj) {
            obj.running = true;
            var index = this.tags.indexOf(obj);

            obj.default_key = "1";
            this.tags.splice(index, 1);

         }
      } else {
         let obj = this.tags.find(obj => obj.tag_type == '3');
         if (!obj) {
            let newObj = this.job_tags.find(obj => obj.tag_type == '3');
            if (newObj) {
               newObj.running = false;
               newObj.default_key = "2";
               this.tags.push(newObj);
            }
         }
      }

   }


   //SEARCH CUSTOMERS FOR DELIVERY
   search_customer(event) {
      if (event.length == '0') {

         this.cust_name = "";
      }
      var localData = JSON.parse(localStorage.getItem("currentUser"));

      var customer_type = JSON.parse(localStorage.getItem("formType"));
      if (customer_type == "tab2") {
         var customer_type = JSON.parse(localStorage.getItem("customer_type_pickup"));

      } else {
         var customer_type = JSON.parse(localStorage.getItem("customer_type_delivery"));
      }

      if (!this.establishment_id && this.customerTypeSelection == 2) {
         return this.toastr.error('', 'Please enter establishment name');
      }

      if (event.length <= 0 && this.customerTypeSelection != 2) {
         return console.log('less then 2');
      }

      this.services.searchCustomer(localData._id, event, this.establishment_id, customer_type).pipe().subscribe(data => {
         if (data.code == 400) {
            this.spinner.hide();
            this.toastr.error('', data.message);
            this.commonMethods.logout();
            return;
         }

         if (data.code == 200) {
            this.customers = data.result;
            if (this.customers.length == 0) this.customerAddresses = [];

            if (this.customerTypeSelection == 2) {
               this.residenceAllClients = data.result;
               // for (const client of this.residenceAllClients) {
               //    client.username = `${client.customer_f_name} ${client.customer_l_name} (${client.country_code} ${client.phone_no})`
               // }
               this.residenceAllClients.map((client) => client.username = `${client.customer_f_name} ${client.customer_l_name} (${client.country_code} ${client.phone_no})`);

            }
            this.spinner.hide();
         } else {
            this.toastr.error('error', data.message);
            this.spinner.hide();
            return;
         }
      });


   }


   //SEARCH ESTABLISHMENT FOR DELIVERY
   searchEstablishmentDelivery(event) {
      var localData = JSON.parse(localStorage.getItem("currentUser"));

      this.multiple_customers_id = [];

      if (event.length <= 0) {
         return console.log('less then 2 event');
      }

      this.services.searchEstablishment(localData._id, event, this.customer_type, this.customerTypeSelection).pipe().subscribe(data => {
         if (data.code == 400) {
            this.spinner.hide();
            this.toastr.error('', data.message);
            this.commonMethods.logout();
            return;
         }
         if (data.code == 200) {
            this.establishments = data.result;
            this.spinner.hide();
         } else {
            this.toastr.error('error', data.message);
            this.spinner.hide();
            return;
         }
      });
   }


   //REFRESH COMPONENT
   refresh() {
      this.locationData = {};
      this.jobDuplicacyModal.hide();
      this.isRoundTrip = false;

      this.ngOnInit()

      this.changeCustomerTypeSelection("1")
      this.onSelectTag('0', [16, 17])

      this.isDisableItemPrice = 2;
      this.disableItemPrice()

      this.location1 = "";
      this.cust_name = "";
      this.iban_no = "";
      this.distance = 0;
      this.normalCharges = 0;
      this.urgentCharges = 0;
      this.overallTax = 0;
      this.driver_charges_normal = 0;
      this.driver_charges_urgent = 0;
      this.normalNightCharge = 0;
      this.urgentNightCharge = 0;

      this.type = "0";
   }


   openEditUrgentJobModal() {
      this.urgent_charge = this.urgentCharges;
      this.editUrgentJobModal.show();
   }

   //SAVE MODEL FOR EDIT PRICE OF JOB RIDE PRICE
   saveUrgentJob() {
      if (this.urgent_charge != 0 && !Number(this.urgent_charge)) {
         this.toastr.error('', 'Entered amount not valid!');
         return;
      }
      var format = /^[0-9]+(.[0-9]{0,2})?$/

      if (this.urgent_charge.toString().match(format)) {
         var taxPercentage = this.adminTax + this.adminProcessingFee;
         var enteredAmount = Number(this.urgent_charge);
         if (this.jobTypeSelection == 1 && this.jobFor == '1' && enteredAmount < Number(this.urgentCharges)) {
            this.toastr.error('error', "Amount can't be decreased for public job");
            return;
         }

         var withoutWeekendCharge = enteredAmount;
         if (this.f.jobDate.value) {
            var fullDate = this.f.jobDate.value.year + '-' + this.f.jobDate.value.month + '-' + this.f.jobDate.value.day;
            var mTime = moment(fullDate, "YYYY-MM-DD");
            var selectedDate = moment(mTime);
            var day = selectedDate.day();
            if (day == 0) {
               enteredAmount = enteredAmount * this.weekendCharge;
               this.weekend_charge_urgent = enteredAmount - withoutWeekendCharge;

            }
         }
         var totalTax = enteredAmount / 100 * taxPercentage;
         var amountWithTax = enteredAmount + totalTax;


         this.driver_charges_urgent = enteredAmount;
         this.urgentCharges = Math.round(amountWithTax * 100) / 100;


         var nightTag = this.tags.map(function (e) {
            return e.tag_type;
         }).indexOf('12');
         if (nightTag > -1) {
            this.driver_charges_urgent = Math.round(((Number(this.driver_charges_urgent) - Number(this.weekend_charge_urgent)) * Number(this.nightCharge)) * 100) / 100;
            var urgentTax = Math.round(((this.driver_charges_urgent + Number(this.weekend_charge_urgent)) / 100 * taxPercentage) * 100) / 100;
            this.urgentCharges = Math.round((this.driver_charges_urgent + this.weekend_charge_urgent + urgentTax) * 100) / 100;

            this.urgentNightCharge = Math.round((this.driver_charges_urgent - withoutWeekendCharge) * 100) / 100;

            this.driver_charges_urgent = Math.round((this.driver_charges_urgent + Number(this.weekend_charge_urgent)) * 100) / 100;
         }

         this.editUrgentJobModal.hide();

      } else {
         this.toastr.error('', 'Entered amount not valid!');
         return;
      }
   }

   openEditNormalJobModal() {
      this.normal_charge = this.normalCharges;


      this.editNormalJobModal.show();

   }


   //MODEL TO EDIT RIDE PRICE URGENT
   saveNormalJob() {
      if (this.normal_charge != 0 && !Number(this.normal_charge)) {

         this.toastr.error('', 'Entered amount not valid!');
         return;
      }
      var format = /^[0-9]+(.[0-9]{0,2})?$/;
      if (this.normal_charge.toString().match(format)) {
         var taxPercentage = this.adminTax + this.adminProcessingFee;
         var enteredAmount = Number(this.normal_charge);
         if (this.jobTypeSelection == 1 && this.jobFor == '1' && enteredAmount < Number(this.normalCharges)) {
            this.toastr.error('error', "Amount can't be decreased for public job");
            return;
         }
         var withoutWeekendCharge = enteredAmount;
         if (this.f.jobDate.value) {
            var fullDate = this.f.jobDate.value.year + '-' + this.f.jobDate.value.month + '-' + this.f.jobDate.value.day;
            var mTime = moment(fullDate, "YYYY-MM-DD");
            var selectedDate = moment(mTime);
            var day = selectedDate.day();
            if (day == 0) {
               enteredAmount = enteredAmount * this.weekendCharge;
               this.weekend_charge_normal = enteredAmount - withoutWeekendCharge;

            }
         }
         var totalTax = enteredAmount / 100 * taxPercentage;
         var amountWithTax = enteredAmount + totalTax;

         this.driver_charges_normal = enteredAmount;
         this.normalCharges = Math.round(amountWithTax * 100) / 100;
         this.taxTps = Number(this.driver_charges_normal * 5) / 100;

         var nightTag = this.tags.map(function (e) {
            return e.tag_type;
         }).indexOf('12');
         if (nightTag > -1) {
            this.driver_charges_normal = Math.round(((Number(this.driver_charges_normal) - Number(this.weekend_charge_normal)) * Number(this.nightCharge)) * 100) / 100;

            var normalTax = Math.round(((this.driver_charges_normal + Number(this.weekend_charge_normal)) / 100 * taxPercentage) * 100) / 100;
            this.normalCharges = Math.round((this.driver_charges_normal + Number(this.weekend_charge_normal) + normalTax) * 100) / 100;
            this.normalNightCharge = Math.round((this.driver_charges_normal - withoutWeekendCharge) * 100) / 100;

            this.taxTps = Math.round(this.driver_charges_normal * 5) / 100;

            this.driver_charges_normal = Math.round((this.driver_charges_normal + Number(this.weekend_charge_normal)) * 100) / 100;

         }
         this.editNormalJobModal.hide();

      } else {
         this.toastr.error('', 'Entered amount not valid!');
         return;
      }
   }

   mychange(val) {

      const self = this;
      let chIbn = val.split('-').join('');
      if (chIbn.length > 0) {
         chIbn = chIbn.match(/\d{3}(?=\d{2,3})|\d+/g).join("-");
      }

      this.iban_no = chIbn;
   }

   subscribeRedirect() {
      this.subscriptionModal.hide();
      this.parentModalRef?.hide()
      this.router.navigate(['subscription-plans']);

   }

   cardsRedirect() {
      this.cardModal.hide();
      this.parentModalRef?.hide()
      this.router.navigate(['profile']);


   }

   onDateSelected(event) {
      this.spinner.show();
      var fullDate = event.year + '-' + event.month + '-' + event.day;
      this.dateForJob = fullDate
      this.services.getTimingJobTags(fullDate, this.user_id, this.customer_type).pipe().subscribe(data => {

         if (data.code == 200) {
            this.job_tags = data.jobTags;
            this.tags = [];

            this.dog = true;
            this.delivery_type = "2";
            this.cat = true;
            this.totalTagsPrices = 0;
            if (this.eLat_long && this.eLat_long.length && this.location1) {
               this.customerDelivery(this.eLat_long, this.location1, fullDate)
            } else {
               this.spinner.hide();
            }
            return;

         } else {

            this.toastr.error('error', data.message);
            this.spinner.hide();
            return;
         }
      })
   }


   public onItemSelect1(item: any) {
      this.selected_drivers.push(item._id)
   }

   onItemSelectDispatch(item: any) {

      setTimeout(() => {
         // console.log("selectedDispatchForJob:", this.selectedDispatchForJob);

      }, 100)
      return;


   }


   public onDeSelect1(item: any) {
      let index = this.selected_drivers.indexOf(item._id);
      this.selected_drivers.splice(index, 1);
   }

   onDeSelectDispatch(item: any) {

      setTimeout(() => {
         // console.log("selectedDispatchForJob:", this.selectedDispatchForJob);

      }, 100)
      return;


   }


   public onSelectAll1(items: any) {
      this.selected_drivers = [];
      for (const data of items) {
         this.selected_drivers.push(data._id);
         data.username = `${data.fullname} (${data.username})`;
      }
   }

   onSelectAllDispatch(items: any) {

      setTimeout(() => {
         // console.log("selectedDispatchForJob:", this.selectedDispatchForJob);

      }, 100)

      return;

   }





   public onDeSelectAll1(items: any) {

      this.selected_drivers = [];
   }

   onDeSelectAllDispatch(items: any) {
      setTimeout(() => {
         // console.log("selectedDispatchForJob:", this.selectedDispatchForJob);

      }, 100)

      return;
      // this.selected_drivers_dispatch = [];
   }

   //SELECT UNSELECT CLIENT
   public onItemSelect2(item: any) {
      this.multiple_customers_id.push(item._id)
   }

   public onDeSelect2(item: any) {
      var carIndex = this.multiple_customers_id.indexOf(item._id);
      this.multiple_customers_id.splice(carIndex, 1);
   }

   public onSelectAll2(items: any) {

      this.multiple_customers_id = [];
      for (const data of items) {
         this.multiple_customers_id.push(data._id)
      }
   }

   public onDeSelectAll2(items: any) {

      this.multiple_customers_id = [];
   }


   public onSelectAll(items: any) {

   }

   public onDeSelectAll(items: any) {

   }

   changeCustomerTypeDelivery(type) {

      if (this.job_type_selection === "1") {
         this.customer_type_delivery = this.customer_type = type;
         localStorage.setItem('customer_type_delivery', JSON.stringify(this.customer_type_delivery));

      } else {
         this.customer_type_pickup = this.customer_type = type;
         localStorage.setItem('customer_type_pickup', JSON.stringify(this.customer_type_pickup));
      }
      this.customerAddresses = [];
      this.refresh()


   }


   disableItemPrice() {

      if (this.isDisableItemPrice == 1) {

         this.jobForm.controls['item_price'].setValue('0');
         this.jobForm.controls['item_price'].disable();
         this.isDisableItemPrice = 2;

      } else {
         this.jobForm.controls['item_price'].enable();
         this.isDisableItemPrice = 1;
         this.jobForm.controls['item_price'].setValue('');

      }

   }


   //CHANGE TYPE SELECTION  OF RESIDANCE
   changeCustomerTypeSelection(type) {

      this.customerTypeSelection = type;
      if (this.customerTypeSelection == 2) {
         this.jobForm.controls['item_price'].setValue('0');
      } else {
         this.jobForm.controls['item_price'].setValue('');
      }
      this.location1 = '';

      this.jobForm.controls['location1'].enable();
      this.multiple_customers_id = [];
      this.jobForm.controls['customer_L_name'].setValue('');
      this.jobForm.controls['location1'].setValue('');
      this.jobForm.controls['customer_name'].setValue('');
      this.jobForm.controls['establishment_name'].setValue('');
      this.jobForm.controls['customer_email'].setValue('');
      this.jobForm.controls['customer_note'].setValue('');
      this.jobForm.controls['customer_parent'].setValue('');
      this.jobForm.controls['to_be_received_by_name'].setValue('');
      this.iban_no = '';
      this.locationData = {};

   }



   addOptionalAddress(type) {

      if (type == 1) {
         this.openOptionalAddress = true;
      } else {
         this.openOptionalAddress = false;
      }
   }


   //onselect function start here
   onSelect(value) {

      this.customer_name = value;
      this.cust_name = value;
      if (!value.establishment_id) {
         this.jobForm.controls['location1'].enable();
         this.multiple_customers_id = [];
         this.jobForm.controls['customer_L_name'].setValue('');
         this.jobForm.controls['customer_email'].setValue('');
         this.iban_no = '';
      }

      if (value.customer_name || value.customer_f_name) {
         this.jobForm.controls['customer_email'].setValue(value.email);
         this.jobForm.controls['appartment_no'].setValue(value.appartment_no);
         this.jobForm.controls['door_code'].setValue(value.door_code);
         this.jobForm.controls['customer_note'].setValue(value.customer_note);
         this.jobForm.controls['customer_parent'].setValue(value.customer_note);
         this.jobForm.controls['to_be_received_by_name'].setValue(value.to_be_received_by_name);
         this.jobForm.controls['customer_L_name'].setValue(value.customer_l_name);

         this.cust_name = value.customer_f_name ? value.customer_f_name : value.customer_name;
         // this.cust_l_name = value.customer_l_name;
         this.iban_no = value.phone_no;
         this.multiple_customers_id.push(value._id);
         if (value.establishment_id) {
            this.jobForm.controls['location1'].disable();
            this.customer_est_id = value.establishment_id ? value.establishment_id : null;
         }
         if (value.location && value.lat_long.length && this.customer_type == 2) {
            this.jobForm.controls['location1'].setValue(value.location);
            this.customerDelivery(value.lat_long, value.location, this.dateForJob);
         }
      }

   }


   setLastNameDelivery(value) {

      this.cust_name = value;
      this.jobForm.controls['customer_name'].setValue(value);

      if (!value.establishment_id) {
         this.jobForm.controls['location1'].enable();
         this.multiple_customers_id = [];
         this.jobForm.controls['customer_L_name'].setValue('');
         this.jobForm.controls['customer_email'].setValue('');
         this.iban_no = '';
         // this.jobForm.controls['customer_L_name'].setValue(value);

      }

      if (value.customer_name || value.customer_f_name) {
         this.jobForm.controls['customer_email'].setValue(value.email);
         this.jobForm.controls['appartment_no'].setValue(value.appartment_no);
         this.jobForm.controls['door_code'].setValue(value.door_code);
         this.jobForm.controls['customer_note'].setValue(value.customer_notes);
         this.jobForm.controls['customer_parent'].setValue(value.customer_parent);
         this.jobForm.controls['to_be_received_by_name'].setValue(value.to_be_received_by_name);

         this.jobForm.controls['customer_L_name'].setValue(value);


         this.cust_name = value.customer_f_name ? value.customer_f_name : value.customer_name;

         this.iban_no = value.phone_no;
         this.multiple_customers_id.push(value._id);
         if (value.establishment_id) {
            this.jobForm.controls['location1'].disable();
            this.customer_est_id = value.establishment_id ? value.establishment_id : null;
         }
         if (value.location && value.lat_long.length && this.customer_type == 2) {
            this.jobForm.controls['location1'].setValue(value.location);
            this.customerDelivery(value.lat_long, value.location, this.dateForJob);
         }
      }


   }

   onRecursiveEndDate(event) {



   }

   packageChange(event) {
      if (event) {
         this.packageObj.label = `${event}/${event}`;

      } else {
         this.packageObj.label = `0/0`;
      }
   }

   async onFileSelect(event) {

      if (event.target.files.length > 0) {
         const selectedFile = event.target.files[0];
         let size = selectedFile.size / 1000000;
         if (size > 10) {
            this.toastr.error('', "file size must be less than 10MB");
            this.spinner.hide();
            return;
         }

         this.attachedDocument.docName = selectedFile.name;

         this.selectedFile = selectedFile;

      }

   }

   async uploadFile(jobId: string) {

      if (!this.selectedFile) {
         this.toastr.error('error', "file not found");
         this.spinner.hide();
         return;
      }
      this.spinner.show();


      try {
         const uploadData = await this.services.uploadFile({ file: this.selectedFile, _id: jobId, fileName: "attachment_document", containerName: environment.AZURE_STORAGE_PHARMACY_CONTAINER_NAME }).toPromise();

         this.attachedDocument.url = uploadData?.url;
         if (this.attachedDocument.url && this.attachedDocument.url.length) {
            await this.services.updateAttachedDocumentUrl({ _id: jobId, url: this.attachedDocument.url, docName: "attachment_document" }).toPromise();
            this.spinner.hide();
         }
      } catch (error) {
         this.spinner.hide();
         this.attachedDocument.url = "";
         this.attachedDocument.docName = "";
         this.toastr.error('', "Something went wrong while uploading document.");

      }




   }

   cancelDocument() {
      this.attachedDocument.url = "";
      this.attachedDocument.docName = "";

   }

   establishmentPhoneChange(value) {
      let chIbn = value.split('-').join('');
      if (chIbn.length > 0) {
         chIbn = chIbn.match(/\d{3}(?=\d{2,3})|\d+/g).join("-");
      }
      this.establishment_phone = chIbn;
   }


   onSelectClient(event) {
      this.services.checkJobDuplicacy(this.user_id, this.job_type_selection, event._id, null).pipe().subscribe(data => {

         if (data.code == 200) {
            this.duplicate_job_id = data.result._id;

            this.jobDuplicacyModal.show();

         } else {
            this.duplicate_job_id = "";
         }
         return;
      });

   }

   onSelectEstablishment(event) {
      this.services.checkJobDuplicacy(this.user_id, this.job_type_selection, null, event._id).pipe().subscribe(data => {
         if (data.code == 200) {
            this.duplicate_job_id = data.result._id;
            this.jobDuplicacyModal.show();

         } else {
            this.duplicate_job_id = "";
         }
         return;
      });

   }

   navigateToEditJob(content, header) {
      if (this.duplicate_job_id) {
         this.parentModalRef?.hide()
         this.jobDuplicacyModal.hide()

         const initialState = {
            content: content,
            header: header


         };
         this.bsModalRef = this.bsModalService.show(ModalComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            class: "modal-xl modal-dialog-centered",
         });
      }

   }

   nameDisabled() {

      if (this.to_be_received_by_type === "0") {
         //  this.f.to_be_received_by_name.disabled;
         return true;
      } else {
         // console.log("esta dentro del no");
         return false;
      }

   }

   onChangeIsContinuous() {
      this.isContinuousJob = !this.isContinuousJob;
      this.recursiveEndDateDelivery = null;
      this.recursiveEndDatePickup = null;
   }

   onChangeIsRoundTrip() {
      this.isRoundTrip = !this.isRoundTrip;
      this.calculateRoundTrip();
   }

   timerOnChange(newTime) {
      console.log("timerChange")
      const arrayHour = this.hourUrgent.split(":");
      const hour = arrayHour[0];
      const minute = arrayHour[1];
      if (this.jobTimingObj.type === 1) {
         const hour = newTime.hour;
         const minute = newTime.minute;
         this.validHour(hour, minute, `dog`);

      }

   }
   timerOnChange2(newTime) {

      console.log("timerChange2")
      if (this.jobTimingObj.type === 3) {
         const arrayHour = this.hourUrgent.split(":");
         const hour = newTime.hour;
         const minute = newTime.minute;
         this.validHour(hour, minute, `dog`);

      }

   }

   validHour(hour, minute, type) {
      this.parameterService.getValidHour({ hour, minute }).subscribe((data) => {
         if (type === `dog`) {
            this.dog = !data.isAllowed
         } else if (type === `cat`) {
            this.cat = !data.isAllowed
         }
      })
   }

   mapWithAlias: boolean = false;
   openModalSelectLocation() {

      if (this.customer_id) {
         this.mapWithAlias = true;
      } else {
         this.mapWithAlias = false;
      }

      const initialState = {
         content: this.locationBodyTemplate,
         header: this.locationHeaderTemplate


      };
      this.bsModalRef = this.bsModalService.show(ModalComponent, {
         initialState,
         animated: true,
         backdrop: "static",
         class: "modal-xl modal-dialog-centered",
      });
   }

   async onChangeLocationMap(dataAddress: any, modal: NgbModalRef) {

      if (this.mapWithAlias && this.customer_id) {
         this.spinner.show();
         const resultSaveAddress: { result: boolean, data?: any } = await this.saveCustomerAddress(dataAddress, this.customer_id)
         this.spinner.hide();

         if (resultSaveAddress.result) {
            this.customerAddresses = [...this.customerAddresses, resultSaveAddress?.data]
            this.selectedAddress.setValue(resultSaveAddress?.data)

            this.toastr.success('', this.language == 'fr' ? "Adresse enregistrée avec succès." : "Address saved successfully.");
         } else {

            this.toastr.error('', this.language == 'fr' ? "Quelque chose s'est mal passé lors de l'enregistrement de l'adresse." : "Something went wrong while saving address.");
            return;
         }
      }

      // Todo: job_type_selection : 1=delivery, 2=pickup
      // Todo: customer_type is delivery: 2=client, !2=establishment
      // Todo: customer_type1 is pickup: 2=client, !2=establishment


      this.handleAddressChange(dataAddress, false)

   }

   saveCustomerAddress(address, customer_id): Promise<{ result: boolean, data?: any }> {
      let city = null;
      let zipcode = null;

      address?.address_components.forEach(data => {
         if (data.types[0] == "locality") {
            city = data.long_name;
         }
         if (data.types[0] == "postal_code") {
            zipcode = data.long_name;
         }
      });

      const ADDRESS_DATA: Address = {
         latitude: address?.geometry.location.lat(),
         longitude: address?.geometry.location.lng(),
         address: address?.aliasEn || '-',
         address_fr: address?.aliasFr || '-',
         landmark: address?.formatted_address,
         city: city,
         zipcode: zipcode,
         default: false
      }
      return new Promise((resolve, reject) => {
         this.services.addNewAddressClient(customer_id, ADDRESS_DATA).subscribe(
            (res: any) => {
               if (res?.code === 200) {
                  resolve({ result: true, data: res?.direction })
               } else {
                  reject({ result: false })
               }
            }, (err: any) => {
               reject(false)
            }
         );
      })
   }

   checkedTimingTags(): boolean {
      let timingTagStatus = false;
      for (const tag of this.tags) {
         if (
            tag.tag_type == JOB.JOB_TAG.MORNING ||
            tag.tag_type == JOB.JOB_TAG.AFTERNOON ||
            tag.tag_type == JOB.JOB_TAG.EVENING ||
            tag.tag_type == JOB.JOB_TAG.NIGHT
         ) {
            timingTagStatus = true;
         }
      }
      return timingTagStatus;
   }

   showAddJobTagModal() {
      this.tag.isDisabled = !this.tag.isDisabled
      this.addJobTagModal.show();
   }

   addJobTag() {

      this.submittedAddJobTagForm = true;

      if (!(this.addJobTagForm.status === "VALID")) {
         this.toastr.error('error', "You complete required informations to add new tag");
         return;
      }

      this.tag.user_id = this.user_id;

      this.spinner.show();
      this.services.addJobTag(this.tag).pipe().subscribe(data => {
         if (data.code == 200) {
            // Update displayed tags list
            const newTag = data.result;
            if (!newTag.isDisabled) {

               this.job_tags.push(newTag);

               newTag.running = false
               this.onChangeCategory(null, newTag)
            }

            this.addJobTagForm.reset();
            this.addJobTagModal.hide();

            this.spinner.hide();
            this.toastr.success('success', this.translate.instant('TAG.add_new_tag_success'));
         } else {
            this.spinner.hide();
            this.toastr.error('', data.message);
         }
      });
   }

   cancelAddJobTag() {
      this.addJobTagModal.hide();
   }


}