<div
  class="app-body body-bg"
  style="
    background-image: url(assets/img/lg-bg-min.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  "
>
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="text-right pt-2">
        <label
          ><strong><i class="fa fa-globe"></i> Language :</strong></label
        >
        <select #langSelect (change)="switchLang(langSelect.value)">
          <option
            *ngFor="let lang of ts.getLangs()"
            [value]="lang"
            [selected]="lang === ts.currentLang"
          >
            <span *ngIf="lang == 'en'"> English </span>
            <span *ngIf="lang == 'fr'"> Français </span>
          </option>
        </select>
      </div>
      <div class="row mt-02">
        <div class="col-md-3"></div>
        <div class="col-md-6 card forgot-password">
          <div class="logo-box text-center">
            <img
              class="navbar-brand-full ng-star-inserted"
              src="assets/img/vaistatlogo-2.png"
              width="89"
              height="25"
              alt="CoreUI Logo"
            />
          </div>
          <div>
            <h2 class="main-heading-forgot text-center">
              {{ "VERIFICATION.verification" | translate }}
            </h2>
            <p class="text-center">
              {{ "VERIFICATION.verification_comment" | translate }}
            </p>
            <form
              [formGroup]="verifyForm"
              (ngSubmit)="onSubmit()"
              action="/action_page.php"
            >
              <div class="form-group mt-3">
                <label for="text" style="position: relative"
                  >{{ "VERIFICATION.email_otp_head" | translate }}
                  <strong>{{ userEmail }}</strong>

                  <span class="edit-mail-cont"
                    ><i
                      class="fa fa-pencil"
                      aria-hidden="true"
                      (click)="openEmailModal()"
                    ></i
                  ></span>
                </label>
                <input
                  type="text"
                  formControlName="email_otp"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.email_otp.errors }"
                  minlength="4"
                  maxlength="4"
                  placeholder="{{ 'VERIFICATION.enter_email_otp' | translate }}"
                />
              </div>
              <div
                *ngIf="counter <= 0"
                class="resend-btn text-center"
                (click)="resendEmailOtp()"
              >
                <a
                  ><i class="fa fa-repeat mr-2" aria-hidden="true"></i
                  >{{ "VERIFICATION.resend_email_otp" | translate }}</a
                >
              </div>
              <!-- <div class="resend-btn" (click)="resendEmailOtp()"><a>Resend email otp</a></div> -->
              <div class="form-group mt-3" style="position: relative">
                <label for="text"
                  >{{ "VERIFICATION.phone_otp_head" | translate }}
                  <strong>{{ userPhoneNumber }}</strong>
                  <span class="edit-mail-cont1"
                    ><i
                      class="fa fa-pencil"
                      aria-hidden="true"
                      (click)="openPhoneModal()"
                    ></i
                  ></span>
                </label>

                <input
                  type="text"
                  formControlName="phone_otp"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.phone_otp.errors }"
                  minlength="4"
                  maxlength="4"
                  placeholder="{{ 'VERIFICATION.enter_phone_otp' | translate }}"
                />
              </div>
              <div
                *ngIf="counter <= 0"
                class="resend-btn text-center"
                (click)="resendPhoneOtp()"
              >
                <a
                  ><i class="fa fa-repeat mr-2" aria-hidden="true"></i
                  >{{ "VERIFICATION.resend_phone_otp" | translate }}</a
                >
              </div>
              <!-- <p *ngIf="timeLeft > 0" class="resend-btn text-center">{{timeLeft}} Seconds...</p> -->
              <p *ngIf="counter > 0" class="resend-btn text-center">
                {{ counter | formatTime }}
              </p>

              <button type="submit" class="btn btn-success mt-3">
                {{ "VERIFICATION.submit" | translate }}
              </button>
            </form>
            <p class="mt-5 text-center">
              <a routerLink="/login">{{
                "FORGOTPASSWORD.try_login" | translate
              }}</a>
            </p>
            <div *ngIf="selectedLanguage == 'fr'" class="privacy-policies">
              <a href="https://apiuser.vaistat.com/privacyFr" target="_blank">{{
                "LOGIN.terms" | translate
              }}</a>
            </div>
            <div *ngIf="selectedLanguage == 'en'" class="privacy-policies">
              <a href="https://apiuser.vaistat.com/privacyEn" target="_blank">{{
                "LOGIN.terms" | translate
              }}</a>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </main>
</div>

<!-- <ngx-spinner
              bdColor="rgba(51,51,51,0.8)"
              size="medium"
              color="#fff"
              type="ball-spin-clockwise"
            >
              <p style="font-size: 20px; color: white">Loading...</p>
            </ngx-spinner> -->

<div *ngIf="cookieStatus == false" class="cookie-banner">
  <p>
    {{ "cookie.message" | translate }}
    <a
      *ngIf="selectedLanguage == 'fr'"
      href="https://apiuser.vaistat.com/privacyFr"
      target="_blank"
      >Politique de confidentialité</a
    >
    <a
      *ngIf="selectedLanguage == 'en'"
      href="https://apiuser.vaistat.com/privacyEn"
      target="_blank"
      >Privacy policy</a
    >
  </p>
  <button type="button" class="btn btn-warning" (click)="changeCookieStatus()">
    {{ "cookie.allow" | translate }}
  </button>
</div>
<!-- Email edit modal -->
<div
  bsModal
  #editEmailModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-success" role="document">
    <div class="modal-content">
      <div class="modal-header bg-success">
        <h4 class="modal-title">{{ "VERIFICATION.edit_email" | translate }}</h4>
        <button
          type="button"
          class="close"
          (click)="editEmailModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form action="/action_page.php">
          <div class="form-group">
            <label for="pwd"
              ><strong
                >{{ "VERIFICATION.edit_email" | translate }}:</strong
              ></label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="email_for_edit"
              [ngModelOptions]="{ standalone: true }"
              placeholder="{{ 'VERIFICATION.enter_email' | translate }}"
              maxlength="50"
            />
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="editEmailModal.hide()"
        >
          {{ "CREATEJOB.cancel" | translate }}
        </button>
        <button type="button" class="btn btn-success" (click)="changeEmail()">
          {{ "CREATEJOB.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Phone number edit modal start -->
<div
  bsModal
  #editPhoneModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-success" role="document">
    <div class="modal-content">
      <div class="modal-header bg-success">
        <h4 class="modal-title">{{ "VERIFICATION.edit_phone" | translate }}</h4>
        <button
          type="button"
          class="close"
          (click)="cancelPhoneModal()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form
          action="/action_page.php"
          class="verification-flag-box"
          [formGroup]="phoneForm"
        >
          <!-- <div class="form-group"> -->
          <label for="pwd"
            ><strong
              >{{ "VERIFICATION.edit_phone" | translate }}:</strong
            ></label
          >
          <!-- <input type="text" class="form-control"  [(ngModel)]="phone_for_edit" [ngModelOptions]="{standalone: true}" placeholder="enter phone number"  maxlength="50"> -->
          <ngx-intl-tel-input
            class="form-control remove-input country-field input-phone"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="false"
            [enablePlaceholder]="false"
            [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]"
            [selectFirstCountry]="true"
            [maxLength]="12"
            [phoneValidation]="true"
            [separateDialCode]="separateDialCode"
            [(ngModel)]="phonetext"
            (ngModelChange)="onChange1($event)"
            formControlName="phone"
          >
          </ngx-intl-tel-input>
          <!-- <input type="text" class="form-control"  [(ngModel)]="phone_for_edit" [ngModelOptions]="{standalone: true}" placeholder="enter phone number"  maxlength="50"> -->

          <!-- </div> -->
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="cancelPhoneModal()"
        >
          {{ "CREATEJOB.cancel" | translate }}
        </button>
        <button type="button" class="btn btn-success" (click)="changePhone()">
          {{ "CREATEJOB.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- phone number edit modal end -->
