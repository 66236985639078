export interface jobsCountByTagData {
    count: number,
    publicJobsData: {
        am_count: number,
        pm_count: number,
        night_count: number,
        total_expenses: {
            amount: number,
            am_amount: number,
            pm_amount: number,
            night_amount: number
        }
    },
    privateJobsData: {
        am_count: number,
        pm_count: number,
        night_count: number,
        total_expenses: {
            amount: number,
            am_amount: number,
            pm_amount: number,
            night_amount: number
        }
    }
}

export function initJobsCountByTagData(): jobsCountByTagData {
    return {
        count: 0,
        publicJobsData: {
            am_count: 0,
            pm_count: 0,
            night_count: 0,
            total_expenses: {
                amount: 0,
                am_amount: 0,
                pm_amount: 0,
                night_amount: 0
            }
        },
        privateJobsData: {
            am_count: 0,
            pm_count: 0,
            night_count: 0,
            total_expenses: {
                amount: 0,
                am_amount: 0,
                pm_amount: 0,
                night_amount: 0
            }
        }
    }
}