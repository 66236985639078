
import { AsyncPipe, DecimalPipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgSelectModule } from '@ng-select/ng-select';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgOtpInputModule } from 'ng-otp-input';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxPaginationModule } from 'ngx-pagination';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TagModule } from 'primeng/tag';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './containers';
import { DateConfig } from './date-config';
import { MessagingService } from './messaging.service';
import { SocketioService } from './socketio.service';
import { TokenInterceptorService } from './token-interceptor.service';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { SignContractComponent } from './views/sign-contract/sign-contract.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
// const config: SocketIoConfig = { url: 'http://52.221.203.100:3008', options: {} };

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import { AppAsideModule, AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule } from '@coreui/angular';
// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { ChartsModule } from 'ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { JobsComponent } from './views/jobs/jobs.component';
import { Jobs2Component } from './views/jobs2/jobs.component';

import { CreateJobComponent } from './views/create-job/create-job.component';

import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { EditProfileComponent } from './views/edit-profile/edit-profile.component';
import { InvoiceComponent } from './views/invoice/invoice.component';
import { ManageCardComponent } from './views/manage-card/manage-card.component';
import { ManageJobTagsComponent } from './views/manage-job-tags/manage-job-tags.component';
import { TransactionsComponent } from './views/transactions/transactions.component';

import { NgxCsvParserModule } from 'ngx-csv-parser';
import { DropdownModule } from 'primeng/dropdown';
import { UpdateProfileModalComponent } from './components/update-profile-modal/update-profile-modal.component';
import { LockScreenComponent } from './core/components/lock-screen/lock-screen.component';
import { PageLockScreenComponent } from './core/pages/page-lock-screen/page-lock-screen.component';
import { PusherServiceService } from './pusher-service.service';
import { RealtimeService } from './services/realtime/realtime.service';
import { SharedModule } from './shared/shared.module';
import { BrandsCreateComponent } from './views/brands-create/brands-create.component';
import { BrandsUpdateComponent } from './views/brands-update/brands-update.component';
import { BrandsComponent } from './views/brands/brands.component';
import { CancelledOrdersComponent } from './views/cancelled-orders/cancelled-orders.component';
import { CategoryCreateComponent } from './views/category-create/category-create.component';
import { CategoryUpdateComponent } from './views/category-update/category-update.component';
import { CategoryComponent } from './views/category/category.component';
import { ChargedAccountJobsComponent } from './views/charged-account-jobs/charged-account-jobs.component';
import { ChargedOrdersComponent } from './views/charged-orders/charged-orders.component';
import { ClientComponent } from './views/client/client.component';
import { CreateUpdateAddressModalComponent } from './views/client/create-update-address-modal/create-update-address-modal.component';
import { DeleteAddressModalComponent } from './views/client/delete-address-modal/delete-address-modal.component';
import { ListClientAddressesModalComponent } from './views/client/list-client-addresses-modal/list-client-addresses-modal.component';
import { CompletedJobsComponent } from './views/completed-jobs/completed-jobs.component';
import { JobRecursiveComponent } from './views/components/job-recursive/job-recursive.component';
import { ListJobsComponent } from './views/components/list-jobs/list-jobs.component';
import { NotesComponent } from './views/components/notes/notes.component';
import { PagitanionJobsComponent } from './views/components/pagitanion-jobs/pagitanion-jobs.component';
import { CourierSummaryDetailsComponent } from './views/courier-summary-details/courier-summary-details.component';
import { CouriersSummaryComponent } from './views/couriers-summary/couriers-summary.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { DriversMapComponent } from './views/dashboard/drivers-map/drivers-map.component';
import { JobStatisticsComponent } from './views/dashboard/job-statistics/job-statistics.component';
import { DeliveredOrdersComponent } from './views/delivered-orders/delivered-orders.component';
import { DispatchComponent } from './views/dispatch/dispatch.component';
import { DriverDetailsComponent } from './views/driver-details/driver-details.component';
import { EditJobComponent } from './views/edit-job/edit-job.component';
import { EstablishmentCustomerInfoComponent } from './views/establishment-customer-info/establishment-customer-info.component';
import { ConvertRecursiveJobToV2ModalComponent } from './views/extend-recursive-jobs/convert-recursive-job-to-v2-modal/convert-recursive-job-to-v2-modal.component';
import { DeleteRecursiveJobModalComponent } from './views/extend-recursive-jobs/delete-recursive-job-modal/delete-recursive-job-modal.component';
import { EditRecursiveJobModalComponent } from './views/extend-recursive-jobs/edit-recursive-job-modal/edit-recursive-job-modal.component';
import { ExtendRecursiveJobsComponent } from './views/extend-recursive-jobs/extend-recursive-jobs.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { GlobalSummaryDetailsComponent } from './views/global-summary-details/global-summary-details.component';
import { GlobalSummaryComponent } from './views/global-summary/global-summary.component';
import { ImportJobsComponent } from './views/import-jobs/import-jobs.component';
import { JobsListComponent } from './views/jobs-list/jobs-list.component';
import { MedicalCenterComponent } from './views/medical-center/medical-center.component';
import { NewJobRequestsComponent } from './views/new-job-requests/new-job-requests.component';
import { NotDeliveredJobsComponent } from './views/not-delivered-jobs/not-delivered-jobs.component';
import { OrderDetailComponent } from './views/order-detail/order-detail.component';
import { OrdersComponent } from './views/orders/orders.component';
import { OthersComponent } from './views/others/others.component';
import { AnswerModalComponent } from './views/prescription-details/answer-modal/answer-modal.component';
import { CommentsSectionComponent } from './views/prescription-details/comments-section/comments-section.component';
import { PrescriptionDetailsComponent } from './views/prescription-details/prescription-details.component';
import { PrescriptionsComponent } from './views/prescriptions/prescriptions.component';
import { PrivateDriversComponent } from './views/private-drivers/private-drivers.component';
import { ProductCreateComponent } from './views/product-create/product-create.component';
import { ProductUpdateComponent } from './views/product-update/product-update.component';
import { ProductComponent } from './views/product/product.component';
import { ProductsComponent } from './views/products/products.component';
import { RecursiveJobsComponent } from './views/recursive-jobs/recursive-jobs.component';
import { ResidenceComponent } from './views/residence/residence.component';
import { SubscriptionPaymentComponent } from './views/subscription-payment/subscription-payment.component';
import { SubscriptionPlansComponent } from './views/subscription-plans/subscription-plans.component';
import { SubscriptionComponent } from './views/subscription/subscription.component';
import { TagSummeryDetailsComponent } from './views/tag-summery-details/tag-summery-details.component';
import { TagsSummaryComponent } from './views/tags-summary/tags-summary.component';
import { TermsPolicyComponent } from './views/terms-policy/terms-policy.component';
import { TotalExpensesListComponent } from './views/total-expenses-list/total-expenses-list.component';
import { FormatTimePipe, VerifyPharmacyComponent } from './views/verify-pharmacy/verify-pharmacy.component';

import { GoogleMapsModule } from '@angular/google-maps';
import { PageModalLoginComponent } from './core/pages/page-modal-login/page-modal-login.component';
import { ActiveJobsComponent } from './views/active-jobs/active-jobs.component';
import { CalendarComponent } from './views/calendar/calendar.component';
import { ClientsComponent } from './views/clients/clients.component';
import { CreateDriverComponent } from './views/create-driver/create-driver.component';
import { CreateJobPickupTaskComponent } from './views/create-job/create-job-pickup-task/create-job-pickup-task.component';
import { ProfileComponent } from './views/profile/profile.component';
import { ReportsComponent } from './views/reports/reports.component';

@NgModule({
  imports: [
    GoogleMapsModule,
    DropdownModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    HttpClientModule,
    FullCalendarModule,
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    NgxUiLoaderModule,
    NgOtpInputModule,
    ToastrModule.forRoot({
      timeOut: 2000,
    }),
    NgxSpinnerModule,
    NgxPaginationModule,
    ImageCropperModule,
    AutoCompleteModule,
    TagModule,
    NgbModule,
    NgxIntlTelInputModule,
    NgxCsvParserModule,
    NgMultiSelectDropDownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    PopoverModule.forRoot()
    // NgSelectModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    SignContractComponent,
    // JobDetailsComponent,
    JobsComponent,
    Jobs2Component,
    CreateJobComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    ManageCardComponent,
    ManageJobTagsComponent,
    TransactionsComponent,
    InvoiceComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    EditJobComponent,
    SubscriptionComponent,
    SubscriptionPlansComponent,
    DriverDetailsComponent,
    VerifyPharmacyComponent,
    FormatTimePipe,
    NewJobRequestsComponent,
    ActiveJobsComponent,
    JobsListComponent,
    CompletedJobsComponent,
    NotDeliveredJobsComponent,
    RecursiveJobsComponent,
    ChargedAccountJobsComponent,
    GlobalSummaryComponent,
    CouriersSummaryComponent,
    DeliveredOrdersComponent,
    CancelledOrdersComponent,
    TagsSummaryComponent,
    CourierSummaryDetailsComponent,
    TagSummeryDetailsComponent,
    SubscriptionPaymentComponent,
    ResidenceComponent,
    ClientComponent,
    MedicalCenterComponent,
    OthersComponent,
    EstablishmentCustomerInfoComponent,
    GlobalSummaryDetailsComponent,
    ImportJobsComponent,
    TotalExpensesListComponent,
    TermsPolicyComponent,
    PrivateDriversComponent,
    DispatchComponent,
    ExtendRecursiveJobsComponent,
    ChargedOrdersComponent,
    JobRecursiveComponent,
    NotesComponent,
    ListJobsComponent,
    BrandsComponent,
    BrandsCreateComponent,
    BrandsUpdateComponent,
    CategoryComponent,
    CategoryCreateComponent,
    CategoryUpdateComponent,
    ProductsComponent,
    ProductCreateComponent,
    ProductUpdateComponent,
    ProductComponent,
    OrdersComponent,
    OrderDetailComponent,
    PagitanionJobsComponent,
    UpdateProfileModalComponent,
    PrescriptionsComponent,
    PrescriptionDetailsComponent,
    AnswerModalComponent,
    CommentsSectionComponent,
    CalendarComponent,
    // CreateJobPickupComponent,
    CreateJobPickupTaskComponent,
    // ComentComponent,
    // ComentPageComponent,
    // ComentMessageComponent
    EditRecursiveJobModalComponent,
    DeleteRecursiveJobModalComponent,
    ConvertRecursiveJobToV2ModalComponent,
    JobStatisticsComponent,
    DriversMapComponent,
    ListClientAddressesModalComponent,
    CreateUpdateAddressModalComponent,
    DeleteAddressModalComponent,
    PageLockScreenComponent,
    LockScreenComponent,
    PageModalLoginComponent,
    ProfileComponent,
    ClientsComponent,

    ReportsComponent,
    CreateDriverComponent
  ],

  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }, { provide: NgbDateParserFormatter, useClass: DateConfig },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true
  },
    SocketioService,
    MessagingService,
    AsyncPipe,
    PusherServiceService,
    RealtimeService,
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

