<div class="row justify-content-between scp-breadcrumb mb-4">
  <div class="b-jobs">
    <a>{{ "clients" | translate }} </a>
  </div>

  <ng-container *ngIf="selectedTabIndex === residence">
    <ng-container
      *ngTemplateOutlet="residenceComponent?.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === client">
    <ng-container
      *ngTemplateOutlet="clientComponent?.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === medicalCenter">
    <ng-container
      *ngTemplateOutlet="medicalCenterComponent.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === others">
    <ng-container
      *ngTemplateOutlet="othersComponent.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
</div>

<div>
  <tabset>
    <tab
      (selectTab)="onTabChange($event)"
      [id]="residence"
      [heading]="'residence' | translate"
    >
      <app-residence></app-residence>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      [id]="client"
      [heading]="'client' | translate"
    >
      <app-client></app-client>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      [id]="medicalCenter"
      [heading]="'medical_center' | translate"
    >
      <app-medical-center></app-medical-center>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      [id]="others"
      [heading]="'other' | translate"
    >
      <app-others></app-others>
    </tab>
  </tabset>
</div>
