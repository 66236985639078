import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import {
  Router
} from '@angular/router';

import {
  BsModalRef,
  BsModalService,
  ModalDirective
} from 'ngx-bootstrap/modal';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';
import { ModalComponent } from "../../shared/component/modal/modal.component";

@Component({
  selector: 'app-manage-card',
  templateUrl: './manage-card.component.html',
  styleUrls: ['./manage-card.component.scss']
})
export class ManageCardComponent implements OnInit {
  @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
  @ViewChild('successModal2') public successModal2: ModalDirective;

  @ViewChild('addNewCardTemplate') addNewCardTemplate: TemplateRef<any>;


  user_id: any;
  userCards = [];
  selectedCard: any;
  card_number: any;
  card_number_part1: string
  card_number_part2: string
  card_number_part3: string
  card_number_part4: string
  expiry_month: any;
  expiry_year: any;
  cvc_code: any;
  card_id: any;
  creditCardNumber: string;
  cardForm: UntypedFormGroup;
  pageNumber = 1;
  cardsRedirect: any;
  card_holder: any;
  isPrimary = false;
  bsModalRef: BsModalRef;
  showCvc: boolean = false;

  constructor(
    public toastr: ToastrService,
    private services: ServicesService,
    private formBuilder: UntypedFormBuilder,
    public router: Router,
    private spinner: NgxSpinnerService,
    public logoutFunction: DefaultLayoutComponent,
    private modalService: BsModalService,) { }

  ngOnInit() {
    this.spinner.show();
    var localData = JSON.parse(localStorage.getItem("currentUser"));
    this.user_id = localData._id;
    this.services.getUserCards(this.user_id).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        // console.log("data:", data);
        this.userCards = data.result;
        this.selectedCard = data.selected_card;
        // console.log("this.selectedCard", this.selectedCard)
        this.spinner.hide();
        return;

      } else {
        this.toastr.error('error', data.message);
        this.spinner.hide();
        return;
      }
    })
  }

  openAddNewCardModal() {
    this.card_number = "";
    this.card_holder = "";
    this.expiry_month = "";
    this.expiry_year = "";
    this.cvc_code = "";
    this.isPrimary = false;
    this.card_number_part1 = "";
    this.card_number_part2 = "";
    this.card_number_part3 = "";
    this.card_number_part4 = "";
    const initialState = {
      content: this.addNewCardTemplate,
      config: {
        title: "CARD.add_new_card",

      },
      onClick: this.addCard.bind(this),

    };
    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-sd modal-dialog-centered",
    });


  }

  //SELECT CARD FUNCTION START HERE
  selectCard(card_id) {
    console.log('radioFun');
    //IF CARD ALREADY PRIMARY
    if (this.selectedCard == card_id) {
      return
    }
    this.spinner.show();
    this.services.selectCard(this.user_id, card_id).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.selectedCard = data.result.selected_card;
        this.ngOnInit();
        this.toastr.success('success', "Card selected successfully");
        this.spinner.hide();
        return;

      } else {
        this.toastr.error('error', data.message);
        this.spinner.hide();
        return;
      }
    })
  }


  //CHANGE CARD NUMBER ACCORDINGLY
  onChange1(val) {
    if (val) {
      const self = this;
      let chIbn = val.split('-').join('');
      if (chIbn.length > 0) {
        chIbn = chIbn.match(/\d{3}(?=\d{2,3})|\d+/g).join("-");
      }
      console.log(chIbn);
      this.card_number = chIbn;
    }
  }

  //ADD NEW CARD FUNCTION START HERE
  addCard() {
    if (!this.card_number || this.card_number.trim() === "") {
      this.toastr.error('error', "Please enter card number");
      return;
    } else if (this.card_number.length < 18 || this.card_number.length > 19) {
      this.toastr.error('error', "Card number must be 15 or 16 digits");
      return;
    } else if (!this.card_holder || this.card_holder.trim() === "") {
      this.toastr.error('error', "Please enter card holder name");
      return
    } else if (!this.expiry_month || this.expiry_month.trim() === "") {
      this.toastr.error('error', "Please enter expiry month");
      return;
    } else if (!this.expiry_year || this.expiry_year.trim() === "") {
      this.toastr.error('error', "Please enter expiry year");
      return;
    } else if (!this.cvc_code || this.cvc_code.trim() === "") {
      this.toastr.error('error', "Please enter security code");
      return;
    }
    console.log(this.card_number, this.expiry_month, this.expiry_year, this.cvc_code, this.card_holder, this.isPrimary);

    this.spinner.show();
    this.services.addCard(this.user_id, this.card_number, this.cvc_code, this.expiry_month, this.expiry_year, this.card_holder, this.isPrimary).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.ngOnInit();


        this.spinner.hide();
        this.bsModalRef.hide();
        this.toastr.success("success", "Card added successfully");
        return;
      } else {
        this.toastr.error('error', data.message);
        this.spinner.hide();
        return;
      }
    });

  }

  //DELETE CARD MODEL FUNCTION
  deleteCardModal(card_id) {
    this.card_id = card_id
    this.successModal2.show();
    console.log(card_id)
  }

  //DELETE CARD FUNCTION START HERE
  deleteCard() {
    this.spinner.show();
    this.services.deleteCard(this.user_id, this.card_id).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.successModal2.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.spinner.hide();
        this.card_id = "";
        this.successModal2.hide();
        this.toastr.success("success", "Card deleted successfully");
        this.ngOnInit();
        return;

      } else {
        this.toastr.error('error', data.message);
        this.successModal2.hide();
        this.spinner.show();
        return;
      }
    });

  }



  cancel_modal() {
    this.card_id = "";
    this.successModal2.hide();
    return;

  }


  cc_format() {
    const combinedValue = this.card_number_part1 + this.card_number_part2 + this.card_number_part3 + this.card_number_part4;
    const matches = combinedValue.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length > 0) {
      this.card_number = parts.join(' ');
    } else {
      this.card_number = combinedValue;
    }
  }
  test(type) {
    console.log(type)

  }
  fetchNews(event) {
    console.log(event)
  }

  showToFixedNumber(data) {
    var s = data.toString()
    while (s.length < 2)
      s = '0' + s;
    return s;
  };

}