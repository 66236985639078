export const environment = {
  production: false,
  environmentName: 'dev',
  apiUrl: "https://pharmacy.dev.api.vaistat.com/api/v5",
  apiShop: "https://vaistat-dev-pharma-shop-ms.azurewebsites.net/",
  apiApp: "https://vaistat-dev-pharma-app-ms.azurewebsites.net/",
  locationUrl: "https://vaistat-dev-pharma-dispatch-ms.azurewebsites.net/",
  socketUrl: "",
  firebase: {
    apiKey: "AIzaSyDN2aHm1yGwhjJaDrYtsWb1CK2YjYrDTSA",
    authDomain: "vaistatpushnotification.firebaseapp.com",
    projectId: "vaistatpushnotification",
    storageBucket: "vaistatpushnotification.appspot.com",
    messagingSenderId: "841405736423",
    appId: "1:841405736423:web:de98e0729f7b0842028942",
    measurementId: "G-8G91WJ6RRZ",
  },
  PUSHER_KEY: "95514cc6e54413b115d6",
  CHANNEL_UPDATE_JOBS: "channel_62e811d36d84633158cd83de",
  CHANNEL_USER: "logout-pharmacy",
  CHANNEL_LOCATION_DRIVERS: "job_640a09e897484e028b5d048a",
  CHANNEL_CHANGE_STATUS_JOB: "dev-pharmacy",
  CHANNEL_CHANGE_LOCATION_DRIVER: "pharmacy-drivers",
  CHANNEL_JOB_CHANGED: "pharmacy_jobs",
  AZURE_STORAGE_PHARMACY_CONTAINER_NAME: "pharmacy-images",

};
