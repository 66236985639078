export interface Tag {
    _id?: string,
    user_id: string,
    tag_code: string,
    tag_for: string,
    tag_for_french: string,
    tag_price: number,
    running: boolean,
    tag_image?: string
    isDisabled?: boolean
}

export function initTag(): Tag {
    return {
        _id: "",
        user_id: "",
        tag_code: "",
        tag_for: "",
        tag_for_french: "",
        tag_price: 0,
        running: true,
        tag_image: "",
        isDisabled: false
    }
}