import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { JobsListComponent } from '../jobs-list/jobs-list.component';
const FileSaver = require('file-saver');
@Component({
    selector: 'app-not-delivered-jobs',
    templateUrl: './not-delivered-jobs.component.html',
    styleUrls: ['./not-delivered-jobs.component.scss']
})
export class NotDeliveredJobsComponent implements OnInit, AfterViewInit {
    @ViewChild(JobsListComponent) jobsListComponent: JobsListComponent;
    dynamicContentTemplate: TemplateRef<any>;

    constructor(

    ) {

    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.dynamicContentTemplate = this.jobsListComponent.dynamicContentTemplate;

    }





}