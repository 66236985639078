import { Component, OnInit } from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  NgxSpinnerService
} from 'ngx-spinner';
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
const FileSaver = require('file-saver');

@Component({
  selector: 'app-total-expenses-list',
  templateUrl: './total-expenses-list.component.html',
  styleUrls: ['./total-expenses-list.component.scss']
})
export class TotalExpensesListComponent implements OnInit {
  Date: any;
  searchWithDate: any;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  newdate: any;
  pageNumber = 1;
  searchEvent = '';
  startDate: string;
  endDate: string;
  allCompletedJobsCount = 0;
  allCompletedJobs = [];
  userData: any;
  dateRangeSelected = [];
  srcPdf : any;

  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    private spinner: NgxSpinnerService,
    public calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) { }

  ngOnInit() {
    setTimeout(() => {
        //FOR SHOW DATE RANGE DATA IN DEFAULT
        this.srcPdf = "";
        this.fromDate = this.calendar.getPrev(this.calendar.getToday());
        const lastWeek = moment().subtract(7, 'days').calendar();
        if (lastWeek) {
            const splitWeek = lastWeek.split('/');
            this.fromDate.day = parseInt(splitWeek[1]);
            this.fromDate.month = parseInt(splitWeek[0])
            this.fromDate.year = parseInt(splitWeek[2])
        }
        this.toDate = this.calendar.getToday();

        //IF DATE EXIST IN DETAIL PAGE
        this.dateRangeSelected = JSON.parse(localStorage.getItem("dateRangeSelected"));
        if (this.dateRangeSelected.length) {
            this.fromDate = this.dateRangeSelected[0];
            this.toDate = this.dateRangeSelected[1];
        }

        //CHECK SEARCHNAME AND PAGE NUMBER EXIST
        // var selectedData = JSON.parse(localStorage.getItem("userSearch"));
        var updatedPage = JSON.parse(localStorage.getItem("pageNumber"));

        if (updatedPage) {
            // this.searchEvent = selectedData;
            this.pageNumber = updatedPage;
        }

        this.startDate = `${this.fromDate.year}/${this.fromDate.month}/${this.fromDate.day}`;
        this.endDate = `${this.toDate.year}/${this.toDate.month}/${this.toDate.day}`;
        console.log(this.startDate, this.endDate);

        this.userData = JSON.parse(localStorage.getItem("currentUser"));
        this.getTotalExpenses();
    }, 50);
}

//DATE RANGE SELECTION FUNCTION
onDateSelection(date: NgbDate) {
  if (!this.fromDate && !this.toDate) {
      console.log('entr in first cond');
      this.fromDate = date;
  } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      console.log('enter in second cond');
      this.toDate = date;
  } else {
      this.toDate = null;
      this.fromDate = date;
  }

  if (this.fromDate && this.toDate) {

      this.startDate = `${this.fromDate.year}/${this.fromDate.month}/${this.fromDate.day}`;
      this.endDate = `${this.toDate.year}/${this.toDate.month}/${this.toDate.day}`;
      console.log(this.startDate, this.endDate);
      localStorage.setItem('dateRangeSelected', JSON.stringify([]));

      this.pageNumber = 1;
      this.searchEvent = '';
      this.getTotalExpenses();
  }
}

isHovered(date: NgbDate) {
  return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
}

isInside(date: NgbDate) {
  return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
}

isRange(date: NgbDate) {
  return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
}

validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
  const parsed = this.formatter.parse(input);
  return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
}

 //API CALLED HERE
 getTotalExpenses() {
  this.spinner.show();
  this.services.getTotalExpenses(this.userData._id, this.pageNumber, this.startDate, this.endDate).pipe().subscribe(data => {
    console.log("DATA:",data)
      if (data.code == 400) {
          this.spinner.hide();
          this.toastr.error('', data.message);
          this.logoutFunction.logout();
          return;
      }
      if (data.code == 200) {
          this.allCompletedJobs = data.result;
          this.allCompletedJobsCount = data.count;
          this.spinner.hide();
          return
      } else {
          this.allCompletedJobs = [];
          this.allCompletedJobsCount = 0;
          this.spinner.hide();
          return;
      }
  });
}

//ON PAGE CHANGE EVENT
onPageChange(event) {
  this.pageNumber = event;
  this.getTotalExpenses();
}


//ON JOBS SEARCH
// searchJobs(event) {
//   this.pageNumber = 1;
//   this.searchEvent = event ? event : '';
//   if (event.length == 0) {
//       localStorage.setItem('userSearch', JSON.stringify(''));
//       //return this.ngOnInit();
//       return this.getTotalExpenses();
//   }
//   if (this.searchEvent.length > 2) {
//       this.getTotalExpenses();
//   }
// }

//MODIFY DATE FUNCTION
modifyDate(str) {
  const mydate = str.split('T')[0]
  const convert = mydate.split('-')
  return convert[0] + '/' + convert[1] + '/' + convert[2]
}


//SORT ADDRESS FUNCTION START HERE 
sortAddress(address) {
  const address1 = address.split(',');
  if (address1.length > 4) {
      return address1[0] + ',' + address1[1] + ',' + address1[2] + ',' + address1[3];
  } else {
      var str = '';
      for (const data of address1) {
          str += data + ',';
      }
      return str.slice(0, -1)
  }
}

//RESET PAGE FUNCTION
reset_page() {
  this.pageNumber = 1;
  this.searchEvent = '';
  this.startDate = "";
  this.endDate = "";
  this.ngOnInit();
}

//JOB DETAILS FUNCTION STAR HERE
jobDetails(job_id) {
  localStorage.setItem('job_id', JSON.stringify(job_id));
  this.dateRangeSelected = [this.fromDate, this.toDate];
  localStorage.setItem('back_type', JSON.stringify("10"));
  localStorage.setItem('pageNumber', JSON.stringify(this.pageNumber));
  // localStorage.setItem('userSearch', JSON.stringify(this.searchEvent));
  localStorage.setItem('dateRangeSelected', JSON.stringify(this.dateRangeSelected));

  this.router.navigate(['/job-details']);
}

back(){
  localStorage.setItem('selected_date', JSON.stringify(""));
  localStorage.setItem('pageNumber', JSON.stringify('1'));
  localStorage.setItem('userSearch', JSON.stringify(""));
  localStorage.setItem('dateRangeSelected', JSON.stringify([]));
  localStorage.setItem('allJobsPaymentIds', JSON.stringify([]));

  this.router.navigate(['/dashboard']);

}

getPdf(){
  if(!this.startDate){
    this.toastr.error('error','Please enter start date');
    return false;
  } else if(!this.endDate){
    this.toastr.error('error','Please enter end date');
    return false;
  }
  this.spinner.show();
  this.services.getExpensesPdf(this.userData._id,this.startDate,this.endDate,'1').pipe().subscribe(data=>{
      if (data.code == 400) {
          this.spinner.hide();
          this.toastr.error('', data.message);
          this.logoutFunction.logout();
          return;
      }
      if (data.code == 200) {
        console.log("PDFDATA:",data);
        this.srcPdf =  data.pdfUrl;
        // alert(this.srcPdf)
        this.downloadPdf();
        this.spinner.hide();
        return
      } else {
        this.toastr.error('', data.message);
        this.spinner.hide();
        return;
    }

  })
  
}


downloadPdf() {
  let date = new Date().toDateString(); 
  var currentDate = this.formatDate(date);
  var pdfDate = this.modifyPdfDate(currentDate)
   const pdfUrl = this.srcPdf;
   const pdfName = 'Pharmacy_'+pdfDate+'.pdf';
   FileSaver.saveAs(pdfUrl, pdfName);
 }

  modifyPdfDate(str){
    const mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    const a = new Date(str.split('T')[0]);
    const month_ = a.getMonth();
    const date_ = a.getDate();
    const year_ = a.getFullYear();
    return (mS[month_] + ' ' + date_ + ',' + year_);
    }

   formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [year, month, day].join('-');
  }

}
