<div class="modal-body">
  <div class="row flex-column p-2">
    <app-custom-calendar-datepickers
      [(startDate)]="startDate"
      [(endDate)]="endDate"
      [(fromDate)]="fromDate"
      [(toDate)]="toDate"
      [(pageNumber)]="pageNumber"
      (getData)="getAll()"
    ></app-custom-calendar-datepickers>
    <div class="d-flex align-items-center gap-1">
      <select
        class="h-45 select-sort"
        [value]="job_status"
        (change)="filter($event)"
      >
        <option value="">{{ "INVOICES.all" | translate }}</option>
        <option value="P">{{ "JOBS.pending" | translate }}</option>
        <option value="A">{{ "JOBS.active" | translate }}</option>
        <option value="C">{{ "JOBS.completed" | translate }}</option>
        <option value="CN">
          {{ "JOBS.cancelled" | translate }}
        </option>
        <option value="N">
          {{ "JOBS.not_delivered" | translate }}
        </option>
        <option value="expired">
          {{ "JOBS.expired" | translate }}
        </option>
      </select>
      <app-button
        [buttonText]="'BUTTONS.show' | translate"
        [imageSrc]="'./assets/img/new-ui/dowload_as_pdf.svg'"
        [buttonText]="'REPORT.print' | translate"
        *ngIf="dataAll.length"
        (click)="generatePdf()"
      ></app-button>
    </div>

    <div class="mt-3">
      <app-table
        [theadTemplate]="theadTemplate"
        [tbodyTemplate]="tbodyTemplate"
        [totalItems]="totalItems"
        (onPageChange)="onPageChange($event)"
        [currentPage]="pageNumber"
        [noFoundTemplate]="noFoundTemplate"
      ></app-table>
    </div>
    <ng-template #theadTemplate>
      <tr>
        <th>{{ "JOBS.job_id" | translate }}</th>
        <th>{{ "JOBS.address" | translate }}</th>
        <th>{{ "JOBS.delivery_date" | translate }}</th>
        <th>{{ "JOBS.status" | translate }}</th>
        <th>{{ "JOBS.amount" | translate }}</th>
        <th>{{ "JOBS.driver_name" | translate }}</th>
      </tr>
    </ng-template>
    <ng-template #tbodyTemplate>
      <tbody>
        <tr
          *ngFor="
            let activeJobsData of dataAll
              | paginate
                : {
                    itemsPerPage: 10,
                    currentPage: pageNumber,
                    totalItems: totalItems
                  }
          "
        >
          <td>{{ activeJobsData.booking_code }}</td>
          <td>{{ sortAddress(activeJobsData.drop_off_location) }}</td>
          <td>{{ modifyDate(activeJobsData.job_date) }}</td>
          <td>
            <button
              *ngIf="activeJobsData.job_status == 'C'"
              class="stats-complete"
            >
              {{ "JOBS.completed" | translate }}
            </button>

            <button
              *ngIf="
                activeJobsData.isExpired == false &&
                activeJobsData.job_status == 'P'
              "
              class="stats-complete"
              style="background: #ffe4c8; color: #ff8b17"
            >
              {{ "JOBS.pending" | translate }}
            </button>
            <button
              *ngIf="
                activeJobsData.isExpired == false &&
                activeJobsData.job_status == 'A'
              "
              class="stats-active"
            >
              {{ "JOBS.active" | translate }}
            </button>
            <button
              *ngIf="
                activeJobsData.isExpired == true &&
                activeJobsData.job_status != 'C' &&
                activeJobsData.job_status != 'N' &&
                activeJobsData.job_status != 'CN'
              "
              class="stats-complete"
              style="background: #efe2e2; color: #ff010d"
            >
              {{ "JOBS.expired" | translate }}
            </button>

            <button
              *ngIf="activeJobsData.job_status == 'CN'"
              class="stats-complete"
              style="background: #efe2e2; color: #ff010d"
            >
              {{ "JOBS.cancelled" | translate }}
            </button>

            <div *ngIf="activeJobsData.isRecursiveJob">
              <button class="stats-recursive text-danger">
                <i class="fa fa-circle mr-2" aria-hidden="true"></i
                >{{ "JOBS.recursive" | translate }}
              </button>
            </div>
          </td>
          <td>
            <span class="amount"
              >${{ activeJobsData.job_amount | number : "1.2-2" }}</span
            >
          </td>
          <td *ngIf="activeJobsData.driver_id; else driverElse">
            {{ activeJobsData.driver_id.username }}
          </td>
          <ng-template #driverElse>
            <td>N/A</td>
          </ng-template>
        </tr>
      </tbody>
    </ng-template>

    <ng-template #noFoundTemplate>
      <div class="no-jobs-found">
        <p class="no-item text-center text-danger mb-0" *ngIf="!dataAll.length">
          {{ "JOBS.no_jobs" | translate }}
        </p>
      </div>
    </ng-template>
  </div>
</div>
<div class="modal-footer" style="margin: auto; margin-right: 0">
  <button
    type="button"
    (click)="toogle()"
    class="btn btn-secondary client-cancel-btn"
  >
    {{ "JOBS.cancel" | translate }}
  </button>
</div>
