import {
    Component
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import {
    Router
} from '@angular/router';
import {
    NgbDateStruct,
    NgbDatepickerConfig
} from '@ng-bootstrap/ng-bootstrap';
import {
    TranslateService
} from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment-timezone';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import {
    NgxSpinnerService
} from "ngx-spinner";
import {
    ToastrService
} from 'ngx-toastr';
import SignaturePad from 'signature_pad';
import { DefaultLayoutComponent } from '../../containers/default-layout/default-layout.component';
import { Contract, initContract } from '../../models/Contract';
import { Plan } from '../../models/Plan';
import {
    ServicesService
} from '../../services.service';
import { isNull } from '../../utils/funs';

@Component({
    selector: 'sign-contract',
    templateUrl: 'sign-contract.component.html',
    styleUrls: ['./sign-contract.component.scss']
})

export class SignContractComponent {

    public currentUser: any;
    public currentSubscription: any;
    public selectedLanguage: string;
    public stripeCustomerId: string;
    public language: string;
    public pharmacyName: string;
    public pharmacyCreatedAt: string;

    public selectedPlanId: string | null;
    public pharmacyOfficeAddress: string;
    public pharmacyLegalName: string;
    public pharmacyFinancialEmail: string;
    public pharmacyOwnerName: string;
    public pharmacyLicenceNumber: string;
    public licenceExpiryDate: string;
    signatureDate: NgbDateStruct = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1, // Months are zero-based
        day: new Date().getDate()
    };
    minDate: NgbDateStruct = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    public contract: Contract;
    public plans: Plan;
    public signaturePad: SignaturePad;

    cookieStatus = true;
    contractForm: FormGroup;
    submitted = false;

    constructor(
        private formBuilder: FormBuilder,
        public toastr: ToastrService,
        private services: ServicesService,
        public router: Router,
        private spinner: NgxSpinnerService,
        public ts: TranslateService,
        public config: NgbDatepickerConfig,
        public logoutFunction: DefaultLayoutComponent,
    ) {
        ts.addLangs(['en', 'fr']);
        ts.setDefaultLang('en');

        const browserLang = ts.getBrowserLang();
        ts.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    }

    ngOnInit(): void {

        this.currentSubscription = null;
        this.contract = initContract();
        this.cookieStatus = JSON.parse(localStorage.getItem("cookiesStatus")) ? JSON.parse(localStorage.getItem("cookiesStatus")) : false;

        let language = JSON.parse(localStorage.getItem("language"));
        if (!language) {
            language = "en"
        }
        this.selectedLanguage = language;
        localStorage.setItem('language', JSON.stringify(language));
        this.ts.use(language);

        // Get current user data
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

        if (this.currentUser) {
            this.stripeCustomerId = this.currentUser.stripe_customerId
            this.pharmacyName = this.currentUser.pharmacy_name;
            this.pharmacyCreatedAt = moment().format('YYYY/MM/DD');
            this.contract = this.currentUser.contract;

            this.pharmacyLegalName = this.currentUser.pharmacy_name;
            this.pharmacyFinancialEmail = this.currentUser.email;
            this.pharmacyOfficeAddress = this.currentUser.location;
            // If currentUser has already signed the contract
            if (
                (typeof this.currentUser.contract == 'object') &&
                (Object.keys(this.currentUser.contract).length > 0)
            ) {
                const contract = this.currentUser.contract;


                if (contract.selectedPlanId && contract.isSigned) {
                    // Check if the user has a valid and active subscription
                    let isCurrentSubscriptionActive = false;
                    this.spinner.show();
                    this.services.getCurrentSubscription(this.currentUser._id).pipe().subscribe(data => {
                        if (data.code == 200) {
                            this.currentSubscription = data.result;

                            if (this.currentSubscription) {
                                const currentPeriodEnd = moment().unix();
                                if (
                                    !this.currentSubscription.isCancel &&
                                    !this.currentSubscription.delete &&
                                    this.currentSubscription.active &&
                                    (this.currentSubscription.subscription.current_period_end > currentPeriodEnd) // subscription is not expired
                                ) {
                                    isCurrentSubscriptionActive = true;
                                }
                            }

                            if (isCurrentSubscriptionActive) {
                                // If user has an active subscription, redirect to the dashboard
                                this.goToDashdoard();

                            } else {
                                // Redirect to payment page, user has already selected a plan but the payment process is not done
                                this.selectedPlanId = contract.selectedPlanId;
                                this.goToPaymentPage();
                            }
                            this.spinner.hide();
                            return
                        } else {
                            this.currentSubscription = null;
                            this.spinner.hide();
                            this.toastr.error('error', data.message);
                            return
                        }
                    });

                }
            }


        }

        // Get all subscriptiion plans
        this.getAllSubscriptionPlans(this.currentUser._id);

        this.contractForm = this.formBuilder.group({
            pharmacyOfficeAddress: ['', [Validators.required]],
            pharmacyLegalName: ['', [Validators.required, Validators.minLength(4)]],
            pharmacyFinancialEmail: ['', [Validators.required, Validators.email]],
            pharmacyOwnerName: ['', [Validators.required, Validators.pattern("[a-zA-Z ]*"), Validators.minLength(4)]],
            pharmacyLicenceNumber: ['', [Validators.required, Validators.pattern(""), Validators.minLength(2)]],
            licenceExpiryDate: ['', [Validators.required, Validators.minLength(10)]],
            signatureDate: ['', [Validators.required, Validators.minLength(10)]],
        });

        // Init the signature pad
        const canvas = document.querySelector('#signature-pad') as HTMLCanvasElement;
        this.signaturePad = new SignaturePad(canvas);

        // Set a toaster message before displaying service contract
        this.toastr.info('info', 'You must choose a subscription plan, sign the service contract and make payment in order to use the application');

    }

    public handleAddressChange(address: Address): void {
        this.pharmacyOfficeAddress = address.formatted_address;
    }

    private getAllSubscriptionPlans(userId: string): void {
        this.spinner.show();
        this.services.getAllSubscriptionPlans(userId).pipe().subscribe(data => {
            if (data.code == 200) {
                this.plans = data.result;
                this.spinner.hide();
                return
            } else {
                this.spinner.hide();
                this.toastr.error('error', data.message);
            }
        });
    }

    public clearSignaturePad(): void {
        this.signaturePad.clear();
    }

    public onSubmit(): void {

        this.submitted = true;

        const signatureImageBase64 = this.signaturePad.toDataURL();

        const data = {
            userId: this.currentUser._id,
            pharmacyName: this.currentUser.pharmacy_name,
            pharmacyCreatedAt: this.pharmacyCreatedAt,
            contract: {
                ... this.contract,
                selectedPlanId: this.selectedPlanId,
                pharmacyOfficeAddress: this.pharmacyOfficeAddress,
                pharmacyLegalName: this.pharmacyLegalName,
                pharmacyFinancialEmail: this.pharmacyFinancialEmail,
                pharmacyOwnerName: this.pharmacyOwnerName,
                pharmacyLicence: {
                    licenceNumber: this.pharmacyLicenceNumber,
                    expiryDate: moment(this.licenceExpiryDate).format('YYYY-MM-DD')
                },
                signatureDate: moment(this.signatureDate).format('YYYY-MM-DD')
            },
            signatureImageBase64: signatureImageBase64,
            selectedLanguage: this.selectedLanguage
        }

        this.spinner.show();
        this.services.savePharmacyContract(data).pipe().subscribe(data => {
            if (data.code == 200) {
                this.spinner.hide();
                this.currentUser = data.result;
                localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
                // Redirect to subscription-payment page
                this.goToPaymentPage();
                this.toastr.success('success', `Contract saved successfully. You must proceed to payment in order to finalize the process`);

            } else {
                this.spinner.hide();
                this.toastr.error('', data.message);
                return;
            }
        })
    }

    public selectPlan(planId: string): void {
        this.selectedPlanId = planId;
    }

    public isSelectedPlan(): boolean {
        return isNull(this.selectedPlanId);
    }

    public isSignaturePadEmpty(): boolean {
        return this.signaturePad.isEmpty();
    }

    public goToPaymentPage() {
        localStorage.setItem('selectedPlanId', JSON.stringify(this.selectedPlanId));
        this.router.navigate(['subscription-payment']);
        this.toastr.info('info', 'You must finalize the subscription payment process to use the application services');
        return;
    }
    public goToDashdoard() {
        this.router.navigate(['dashboard']);
        this.toastr.success('success', 'Login Successfully');
        return;
    }

    switchLang(language: string) {
        this.spinner.show();
        localStorage.setItem('language', JSON.stringify(language));
        this.ts.use(language);
        this.selectedLanguage = language;

        this.services
            .changeLanguage(this.currentUser._id, language)
            .pipe()
            .subscribe((data) => {
                if (data.code == 400) {
                    this.spinner.hide();
                    this.logout();
                    return;
                }
                if (data.code == 200) {
                    this.currentUser.language = language;

                    localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
                } else {

                    this.toastr.error("error", data.message);
                }
                this.spinner.hide();
            });

    }

    // getter for contractForm fields
    get f() {
        return this.contractForm.controls;
    }

    logout() {
        this.logoutFunction.logout();
    }
}