<div
  class="app-body body-bg"
  style="
    background-image: url(assets/img/lg-bg-min.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  "
>
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="text-right pt-2">
        <label
          ><strong><i class="fa fa-globe"></i> Language :</strong>
        </label>
        <select #langSelect (change)="switchLang(langSelect.value)">
          <option
            *ngFor="let lang of ts.getLangs()"
            [value]="lang"
            [selected]="lang === ts.currentLang"
          >
            <span *ngIf="lang == 'en'"> English </span>
            <span *ngIf="lang == 'fr'"> Français </span>
          </option>
        </select>
      </div>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 login-box">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <div class="logo-box text-center">
                  <img
                    class="navbar-brand-full ng-star-inserted"
                    src="assets/img/vaistatlogo-2.png"
                    width="150"
                    alt="CoreUI Logo"
                  />
                </div>
                <h2 class="main-heading-forgot text-center">
                  {{ "LOGIN.pharmacy_login" | translate }}
                </h2>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                  <div class="form-group mt-3">
                    <label for="text">{{ "LOGIN.email" | translate }}</label>
                    <input
                      type="text"
                      formControlName="email"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                    />
                    <div
                      *ngIf="submitted && f.email.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.email.errors.required">
                        {{ "LOGIN.email_req" | translate }}
                      </div>
                      <div *ngIf="f.email.errors.email">
                        {{ "LOGIN.valid_email" | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="password">{{
                      "LOGIN.password" | translate
                    }}</label>
                    <input
                      type="password"
                      formControlName="password"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && f.password.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && f.password.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.password.errors.required">
                        {{ "LOGIN.password_req" | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <button class="btn btn-success mt-3">
                      {{ "LOGIN.login" | translate }}
                    </button>
                    <p>
                      {{ "LOGIN.do_have_acc" | translate
                      }}<a routerLink="/register" class="btn btn-link">{{
                        "LOGIN.register" | translate
                      }}</a>
                    </p>
                    <p>
                      <a routerLink="/forgot-password" class="btn btn-link">{{
                        "LOGIN.forgot_pass" | translate
                      }}</a>
                    </p>
                    <div
                      *ngIf="selectedLanguage == 'fr'"
                      class="privacy-policies"
                    >
                      <a
                        href="https://apiuser.vaistat.com/privacyFr"
                        target="_blank"
                        >{{ "LOGIN.terms" | translate }}</a
                      >
                    </div>
                    <div
                      *ngIf="selectedLanguage == 'en'"
                      class="privacy-policies"
                    >
                      <a
                        href="https://apiuser.vaistat.com/privacyEn"
                        target="_blank"
                        >{{ "LOGIN.terms" | translate }}</a
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <ngx-spinner
              bdColor="rgba(51,51,51,0.5)"
              size="medium"
              color="#fff"
              type="ball-clip-rotate"
              [fullScreen]="true"
            ></ngx-spinner>
          </div>
        </div>
        <!--end col-8-->
        <div class="col-md-3"></div>
      </div>
    </div>
  </main>
</div>
<div *ngIf="cookieStatus == false" class="cookie-banner">
  <p>
    {{ "cookie.message" | translate }}
    <a
      *ngIf="selectedLanguage == 'fr'"
      href="https://apiuser.vaistat.com/privacyFr"
      target="_blank"
      >Politique de confidentialité</a
    >
    <a
      *ngIf="selectedLanguage == 'en'"
      href="https://apiuser.vaistat.com/privacyEn"
      target="_blank"
      >Privacy policy</a
    >
  </p>
  <button type="button" class="btn btn-warning" (click)="changeCookieStatus()">
    {{ "cookie.allow" | translate }}
  </button>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.5)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate"
  [fullScreen]="true"
></ngx-spinner>

<!-- <ng-template #pageModalLogin let-modal>
  <app-page-modal-login
    [modal]="modal"
    (close)="modal.close()"
  ></app-page-modal-login>
</ng-template> -->
<ng-template #content let-modal>
  <app-page-modal-login></app-page-modal-login>
</ng-template>
