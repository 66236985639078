<div class="mb-3">
  <div class="row mb-3">
    <div class="col-md-2 d-flex align-items-center font-weight-bold">
      {{ "DASHBOARD.select_driver" | translate }}
    </div>
    <div class="col-md-3">
      <p-dropdown
        placeholder="{{ 'DASHBOARD.select_driver' | translate }}"
        [options]="allDrivers"
        [ngModel]="driverSelected"
        optionLabel="name"
        (onChange)="onChangeDriverSelect($event)"
      >
      </p-dropdown>
    </div>
  </div>
  <div class="d-flex flex-wrap mb-1">
    <app-color-legend
      [domain]="[
        'JOBS.pending' | translate,
        'JOBS.accepted' | translate,
        'JOBS.active' | translate,
        'JOBS.completed' | translate,
        'JOBS.not_delivered' | translate
      ]"
      [range]="['#ff8b17', '#caaf15', '#1455d9', '#0bce1f', '#a8a0a0']"
    ></app-color-legend>
    <app-button
      [buttonText]="'DASHBOARD.reset_zoom' | translate"
      (click)="resetZoom()"
    ></app-button>
  </div>

  <app-google-map
    [markers]="markersInfo"
    icon="assets/img/carGrey.png"
  ></app-google-map>
</div>
