import { Component, Input } from "@angular/core";
import { JOB } from "../../helpers/enums";
@Component({
  selector: "app-single-job-info-window-content",
  templateUrl: "./single-job-info-window-content.component.html",
  styleUrls: ["./single-job-info-window-content.component.scss"],
})
export class SingleJobInfoWindowContentComponent {
  @Input() data: any;

  getStatusClass(status: string): string {
    switch (status) {
      case JOB.JOB_STATUS.ACTIVE:
        return "stats-active";
      case JOB.JOB_STATUS.PENDING:
        return "stats-pending";
      case JOB.JOB_STATUS.COMPLETED:
        return "stats-complete";
      case JOB.JOB_STATUS.NOT_DELIVERED:
        return "stats-notcomplete";
      case "ACCEPTED":
        return "stats-accepted";
      default:
        return "";
    }
  }
}
