<ng-template #dynamicContent>
  <div class="d-flex col-6 gap-1">
    <div class="flex-grow-1">
      <form>
        <input
          type="text"
          name="search"
          placeholder="{{ 'JOBS.search_customer' | translate }}"
          class="animated-search"
          (ngModelChange)="searchJobs($event)"
          [(ngModel)]="searchEvent"
          [ngModelOptions]="{ standalone: true }"
        />
      </form>
    </div>
    <div>
      <app-button
        [imageSrc]="'./assets/img/new-ui/dowload_as_pdf.svg'"
        [buttonText]="'REPORT.print' | translate"
        (buttonClick)="getPdf()"
      ></app-button>
    </div>
  </div>
</ng-template>
<div class="mt-3">
  <app-custom-calendar-datepickers
    [(startDate)]="startDate"
    [(endDate)]="endDate"
    [(fromDate)]="fromDate"
    [(toDate)]="toDate"
    (getData)="getChargedPublicJobs()"
    [(pageNumber)]="pageNumber"
  ></app-custom-calendar-datepickers>

  <app-table
    [theadTemplate]="theadTemplate"
    [tbodyTemplate]="tbodyTemplate"
    [totalItems]="allCompletedJobsCount"
    (onPageChange)="onPageChange($event)"
    [currentPage]="pageNumber"
    [noFoundTemplate]="noFoundTemplate"
  ></app-table>
  <ng-template #theadTemplate>
    <tr>
      <th>{{ "JOBS.job_id" | translate }}</th>
      <th>{{ "JOBS.client_name" | translate }}</th>
      <th>{{ "JOBS.address" | translate }}</th>
      <th>{{ "JOBS.initial" | translate }}</th>
      <th>{{ "JOBS.due_date" | translate }}</th>
      <th>{{ "JOBS.status" | translate }}</th>
      <th>{{ "JOBS.amount" | translate }}</th>
      <th>{{ "REPORT.collection" | translate }}</th>
      <th>{{ "REPORT.charged" | translate }}</th>
      <th>{{ "JOBS.driver_name" | translate }}</th>
    </tr>
  </ng-template>

  <ng-template #tbodyTemplate>
    <tbody>
      <tr
        *ngFor="
          let completedJob of allCompletedJobs
            | paginate
              : {
                  itemsPerPage: 10,
                  currentPage: pageNumber,
                  totalItems: allCompletedJobsCount
                }
        "
        (click)="
          jobDetails(
            completedJob._id,
            jobDetailsBodyTemplate,
            jobDetailsHeaderTemplate
          )
        "
      >
        <td>{{ completedJob.booking_code }}</td>

        <td *ngIf="completedJob.customer_type == '2'; else notDeliverElse">
          {{
            completedJob.customer_f_name
              ? completedJob.customer_f_name +
                " " +
                completedJob.customer_l_name
              : completedJob.customer_name
          }}
        </td>
        <ng-template #notDeliverElse>
          <td>
            <span>{{
              completedJob.establishment_name
                ? completedJob.establishment_name
                : completedJob.customer_name
            }}</span>
          </td>
        </ng-template>

        <td *ngIf="completedJob.job_type == '1'">
          {{ commonMethods.sortAddress(completedJob.drop_off_location) }}
        </td>

        <td *ngIf="completedJob.job_type == '2'">
          {{ commonMethods.sortAddress(completedJob.pick_up_location) }}
        </td>

        <td *ngIf="completedJob.job_create_initials; else initialsElse">
          {{ completedJob.job_create_initials }}
        </td>
        <ng-template #initialsElse>
          <td>N/A</td>
        </ng-template>

        <td>{{ commonMethods.modifyDate(completedJob.job_date) }}</td>

        <td *ngIf="completedJob.job_status == 'C'">
          <button class="stats-complete">
            {{ "JOBS.completed" | translate }}
          </button>
        </td>

        <td *ngIf="completedJob.job_status == 'N'">
          <button class="stats-notcomplete">N/D</button>
        </td>

        <td>
          <span class="amount"
            >${{ completedJob.job_amount | number : "1.2-2" }}</span
          >
          <p
            *ngIf="
              completedJob.job_status == 'C' &&
              completedJob.customer_payment_id != null &&
              completedJob.customer_payment_id.payment_method == '2'
            "
            class="cash"
          >
            {{ "JOBS.cash" | translate }}
          </p>
          <p
            *ngIf="
              completedJob.job_status == 'C' &&
              completedJob.customer_payment_id != null &&
              completedJob.customer_payment_id.payment_method == '1'
            "
            class="cash"
          >
            {{ "JOBS.card" | translate }}
          </p>
          <p
            *ngIf="
              completedJob.job_status == 'C' &&
              completedJob.customer_payment_id != null &&
              completedJob.customer_payment_id.payment_method == '3'
            "
            class="cash"
          >
            {{ "JOBS.cheque" | translate }}
          </p>
        </td>

        <td>
          <!-- card amount -->
          <ul class="table-action">
            <li
              *ngIf="
                completedJob.job_status == 'C' &&
                completedJob.customer_payment_id &&
                completedJob.customer_payment_id.payment_method == '1'
              "
            >
              <span class="amount">N/A</span>
            </li>
          </ul>

          <!-- cash amount -->
          <ul
            class="table-action"
            *ngIf="
              completedJob.job_status == 'C' &&
              completedJob.collected_amount_pharmacy !=
                completedJob.job_amount &&
              completedJob.customer_payment_id != null &&
              completedJob.customer_payment_id.payment_method == '2' &&
              completedJob.customer_payment_id.paid == false
            "
          >
            <li>
              <span class="amount"
                >${{
                  completedJob.collected_amount_pharmacy | number : "1.2-2"
                }}</span
              >
            </li>
            <!-- <li (click)="openCollectedAmountModel(completedJob)"><i class="fa fa-pencil" aria-hidden="true"
          style="cursor: pointer;"></i>
      </li> -->
          </ul>

          <ul
            class="table-action"
            *ngIf="
              (completedJob.collected_amount_pharmacy ==
                completedJob.job_amount &&
                completedJob.job_amount != 0) ||
              (completedJob.customer_payment_id &&
                (completedJob.customer_payment_id.payment_method == '2' ||
                  completedJob.customer_payment_id.payment_method == '4') &&
                completedJob.customer_payment_id.paid == true)
            "
          >
            <li>
              <span class="amount"
                >${{
                  completedJob.collected_amount_pharmacy | number : "1.2-2"
                }}</span
              >
            </li>
            <li *ngIf="completedJob.job_status == 'C'">
              <i
                class="fa fa-check"
                aria-hidden="true"
                style="cursor: pointer; color: green"
              ></i>
            </li>
          </ul>

          <!-- cheque amount -->
          <ul
            class="table-action"
            *ngIf="
              completedJob.job_status == 'C' &&
              completedJob.customer_payment_id &&
              completedJob.customer_payment_id.payment_method == '3' &&
              completedJob.cheque_collected_from_driver == false
            "
          >
            <li><span class="amount">N/C</span></li>
            <!-- <li (click)="openChequeModal(completedJob)"><i class="fa fa-pencil" aria-hidden="true"
          style="cursor: pointer;"></i>
      </li> -->
          </ul>

          <ul
            class="table-action"
            *ngIf="
              completedJob.job_status == 'C' &&
              completedJob.customer_payment_id &&
              completedJob.customer_payment_id.payment_method == '3' &&
              completedJob.cheque_collected_from_driver == true
            "
          >
            <li>
              <span class="amount" style="color: #000000">{{
                "JOBS.collected" | translate
              }}</span>
            </li>
          </ul>

          <!-- cheque amount -->
          <ul
            class="table-action"
            *ngIf="
              completedJob.job_status != 'C' ||
              !completedJob.customer_payment_id
            "
          >
            <li><span class="amount">N/A</span></li>
          </ul>
        </td>

        <td style="font-weight: 800">
          ${{ completedJob.ride_fare | number : "1.2-2" }}
        </td>

        <td *ngIf="completedJob.driver_id; else driverElse">
          {{ completedJob.driver_id.username }}
        </td>
        <ng-template #driverElse>
          <td>N/A</td>
        </ng-template>
      </tr>
    </tbody>
  </ng-template>
  <ng-template #noFoundTemplate>
    <div class="no-jobs-found">
      <p
        class="no-item text-center text-danger mb-0"
        *ngIf="!allCompletedJobs.length"
      >
        {{ "JOBS.no_jobs_found" | translate }}
      </p>
    </div>
  </ng-template>
</div>

<ng-template #jobDetailsHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3">
    <ng-container
      *ngTemplateOutlet="jobDetailsComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #jobDetailsBodyTemplate>
  <app-job-details [job_id]="job_id"></app-job-details>
</ng-template>
