<app-header
  [navbarBrandRouterLink]="['/dashboard']"
  [fixed]="true"
  [navbarBrandFull]="false"
  [navbarBrandMinimized]="false"
  [sidebarToggler]="false"
  [asideMenuToggler]="false"
>
  <ul class="nav navbar-nav ml-auto">
    <li class="create-jobs" data-toggle="modal">
      <button
        type="button"
        class="btn btn-outline-success app-success-button"
        (click)="createJob(createJobBodyTemplate, createJobHeaderTemplate)"
      >
        <span>
          <img src="assets/img/new-ui/create_job.svg" width="20px;" class="" />
        </span>
        <strong>{{ "HOME.create_job" | translate }}</strong>
      </button>
    </li>
    <li class="mob-create-job">
      <button
        class="btn btn-success"
        (click)="createJob(createJobBodyTemplate, createJobHeaderTemplate)"
      >
        <i class="fa fa-suitcase"></i>
      </button>
    </li>
    <!--startnotification-->
    <div class="btn-group" dropdown [autoClose]="true" #notificationBlock>
      <button
        dropdownToggle
        type="button"
        class="dropdown-toggle remove-after drop-margin ff app-default-button"
        (click)="readAllnotifiactions()"
      >
        <a class="notification">
          <span>
            <!-- <i class="icon-bell notification-bell"></i> -->
            <img
              [src]="
                notificationBlock.classList.contains('show')
                  ? 'assets/img/new-ui/notification_red.svg'
                  : 'assets/img/new-ui/notification.svg'
              "
              width="20px"
            />
          </span>
          <span *ngIf="notiCount != 0" class="badge">{{ notiCount }}</span>
        </a>
      </button>

      <ul *dropdownMenu class="dropdown-menu notification-list" role="menu">
        <!-- <li class="notification-header"><a>Notifications</a></li> -->
        <h5 *ngIf="notifications.length == 0" class="mb-0 text-center">
          No notifications
        </h5>
        <div class="all-notifications">
          <li *ngFor="let noti of notifications">
            <a>
              <div
                class="media point"
                (click)="
                  redirect(
                    noti,
                    jobDetailsBodyTemplate,
                    jobDetailsHeaderTemplate
                  )
                "
              >
                <!-- <img
                  *ngIf="noti?.driver_id && !noti?.driver_id.profile_img"
                  class="mr-2"
                  src="https://wystap.s3.amazonaws.com/5e4e39ad3fc0b4796a8fef87cod07daagfr6ww6b2cp56k"
                />
                <img
                  *ngIf="noti?.driver_id && noti?.driver_id.profile_img"
                  class="mr-2"
                  [src]="noti?.driver_id.profile_img"
                /> -->
                <img
                  *ngIf="noti?.driver_id && !noti?.driver_id.profile_img"
                  class="mr-2"
                  src="assets/img/new-ui/avatar_without_image_notification.svg"
                />
                <img
                  *ngIf="noti?.driver_id && noti?.driver_id.profile_img"
                  class="mr-2"
                  [src]="noti?.driver_id.profile_img"
                />
                <div class="media-body">
                  <h5 *ngIf="noti?.driver_id" class="mt-0">
                    {{ noti?.driver_id.fullname }}
                  </h5>
                  <p *ngIf="selectedLanguage == 'en'">{{ noti.message }}</p>
                  <p *ngIf="selectedLanguage == 'fr'">
                    {{ noti.french_message }}
                  </p>
                  <p class="mt-2 notification-date">
                    {{ noti.createdAt | date : "EEE yyyy/MM/dd H:mm" }}
                  </p>
                </div>
              </div>
            </a>
          </li>
        </div>
      </ul>
    </div>
    <!---end notification-->
    <!-- <ng-container *ngIf="guies$ | async as guies">
      <div class="slect-language mobile-hide" style="margin: 0px 0px 0px 5px">
        <select
          class="select-header"
          #guieSelect
          (change)="changeGuide(guieSelect.value)"
        >
          <option value="-">
            <span>{{ "HOME.user_guide" | translate }}</span>
          </option>
          <option *ngFor="let guie of guies" [value]="guie.value" [selected]="">
            <span> {{ guie.label }} </span>
          </option>
        </select>
      </div>
    </ng-container> -->
    <!-- <ng-container *ngIf="termsAndConditions$ | async as termsAndPolices">
      <div class="slect-language mobile-hide" style="margin: 0px 10px">
        <select
          class="select-header"
          #termsSelect
          (change)="onChangeTC(termsSelect.value)"
        >
          <option value="-">
            <span>{{ "HOME.terms_and_policy" | translate }}</span>
          </option>
          <option
            *ngFor="let term of termsAndPolices"
            [value]="term.value"
            [selected]=""
          >
            <span> {{ term.label }} </span>
          </option>
        </select>
      </div>
    </ng-container> -->
    <div class="select-language">
      <select
        class="select-header app-default-button"
        #langSelect
        (change)="switchLang(langSelect.value)"
      >
        <option
          *ngFor="let lang of ts.getLangs()"
          [value]="lang"
          [selected]="lang === ts.currentLang"
        >
          <span *ngIf="lang == 'en'"> En </span>
          <span *ngIf="lang == 'fr'"> Fr </span>
        </option>
      </select>
    </div>

    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a
        class="nav-link"
        data-toggle="dropdown"
        href="#"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        dropdownToggle
        (click)="(false)"
      >
        <!-- <img
          *ngIf="!profile_img"
          src="assets/img/user.png"
          class="img-avatar nav-profile"
          alt="profile image"
        />
        <img
          *ngIf="profile_img"
          [src]="profile_img"
          class="img-avatar"
          alt="profile image"
        /> -->
        <span class="header-user-name ml-3 mr-2">{{ pharmacy_name }}</span>
        <img src="assets/img/new-ui/arrow_profile.svg" />
      </a>
      <div
        class="dropdown-menu dropdown-menu-right"
        *dropdownMenu
        aria-labelledby="simple-dropdown"
      >
        <!-- <div class="dropdown-header text-center">
          <strong>{{ "HOME.account" | translate }}</strong>
        </div> -->
        <a class="dropdown-item point" (click)="profile_nav()"
          ><img src="assets/img/new-ui/profile_dark.svg" class="mr-1" />
          {{ "HOME.profile" | translate }}</a
        >
        <a class="dropdown-item point" (click)="logout()"
          ><img src="assets/img/new-ui/logout.svg" class="mr-1" />
          {{ "HOME.logout" | translate }}</a
        >
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'lg'">
    <div class="m-auto" style="max-width: 170px">
      <a class="navbar-brand" href="#/dashboard">
        <img
          class="navbar-brand-full ng-star-inserted"
          src="assets/img/vaistatlogo-2.png"
          alt="Solutions Vaistat Inc."
        />
      </a>
    </div>
    <app-sidebar-nav
      (click)="updateDate()"
      [navItems]="navItems"
      [perfectScrollbar]
      [disabled]="sidebarMinimized"
    ></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->

  <main class="main" style="background-color: white">
    <!-- Breadcrumb -->

    <div
      bsModal
      #primaryModal="bs-modal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-primary" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Job request</h4>
            <button
              type="button"
              class="close"
              (click)="primaryModal.hide()"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>New Job Request&hellip;</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="declineJob()"
            >
              Decline
            </button>
            <button type="button" class="btn btn-primary" (click)="acceptJob()">
              Accept
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
    <ngx-spinner
      bdColor="rgba(51,51,51,0.5)"
      size="medium"
      color="#fff"
      type="ball-clip-rotate"
      [fullScreen]="true"
    ></ngx-spinner>
    <div class="container-fluid mt-5">
      <router-outlet></router-outlet>
    </div>
    <!-- /.container-fluid -->
  </main>
</div>
<div *ngIf="cookieStatus == false" class="cookie-banner">
  <p>
    {{ "cookie.message" | translate }}
    <a
      *ngIf="selectedLanguage == 'fr'"
      href="https://apiuser.vaistat.com/privacyFr"
      target="_blank"
      >Politique de confidentialité</a
    >
    <a
      *ngIf="selectedLanguage == 'en'"
      href="https://apiuser.vaistat.com/privacyEn"
      target="_blank"
      >Privacy policy</a
    >
  </p>
  <button type="button" class="btn btn-warning" (click)="changeCookieStatus()">
    {{ "cookie.allow" | translate }}
  </button>
</div>
<app-footer>
  <div>
    <span> &copy; {{ getCurrentYear }} Vaistat.</span>&nbsp;&nbsp;
    <a routerLink="/terms-policy">{{ footer_text }}</a>
  </div>
</app-footer>

<ng-template #createJobHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3">
    <ng-container
      *ngTemplateOutlet="createJobComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #createJobBodyTemplate>
  <app-create-job [parentModalRef]="bsModalRef"></app-create-job>
</ng-template>

<ng-template #jobDetailsHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3">
    <ng-container
      *ngTemplateOutlet="jobDetailsComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #jobDetailsBodyTemplate>
  <app-job-details [job_id]="jobDetailsId"></app-job-details>
</ng-template>
