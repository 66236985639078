import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService {


    // Observable string sources
    private componentMethodCallSource = new Subject<any>();

    // Observable string streams
    componentMethodCalled$ = this.componentMethodCallSource.asObservable();

    // Service message commands
    callComponentMethod() {
        this.componentMethodCallSource.next();
    }

    public permission: Permission;
    constructor() {
        this.permission = this.isSupported() ? 'default' : 'denied';

    }
    public isSupported(): boolean {
        return 'Notification' in window;
    }
    requestPermission(): void {
        let self = this;
        if ('Notification' in window) {
            Notification.requestPermission(function (status) {
                return self.permission = status;
            });
        }
    }

    showCustomNotification(data, onclick, url = null) {
        let options = {
            body: data.body,
            icon: "assets/img/vaistatlogo-2.png",
            badge: "assets/img/vaistatlogo-2.png",
            sticky: true,
            url: data.url,
        };
        let _notify = new Notification(data.title, options);
        _notify.onclick = () => {
            if (options.url) window.open(options.url, '_self');
            onclick();
            _notify.close();
        };
    }


    create(title: string, options?: PushNotification): any {
        let self = this;
        return new Observable(function (obs) {
            if (!('Notification' in window)) {
                console.log('Notifications are not available in this environment');
                obs.complete();
            }
            console.log(self.permission, 'self.permissionself.permission');
            if (self.permission !== 'granted') {
                console.log("The user hasn't granted you permission to send push notifications");
                obs.complete();
            }
            let _notify = new Notification(title, options);
            _notify.onshow = function (e) {
                return obs.next({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onclick = function (e) {
                return obs.next({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onerror = function (e) {
                return obs.error({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onclose = function () {
                return obs.complete();
            };
        });
    }
    generateNotification(item): void {
        let self = this;
        let options = {
            body: item.notification.body,
            icon: "assets/img/vaistatlogo-2.png",
            badge: "assets/img/vaistatlogo-2.png",
            sticky: true
        };



        let notify = self.create(item.notification.title, options).subscribe();


    }


}

export declare type Permission = 'denied' | 'granted' | 'default';
export interface PushNotification {
    badge?: string;
    body?: string;
    icon?: string;
    tag?: string;
    data?: any;
    renotify?: boolean;
    silent?: boolean;
    sound?: string;
    noscreen?: boolean;
    sticky?: boolean;
    dir?: 'auto' | 'ltr' | 'rtl';
    lang?: string;
    vibrate?: number[];
}