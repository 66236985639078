<ng-template #dynamicContent>
  <div *ngIf="showEditView" class="d-flex gap-1">
    <app-button
      [imageSrc]="'./assets/img/new-ui/reset.svg'"
      [buttonText]="'CREATEJOB.cancel' | translate"
      (buttonClick)="refresh()"
    ></app-button>
    <app-button
      [imageSrc]="'./assets/img/new-ui/save.svg'"
      [buttonText]="'EDITPROFILE.save_changes' | translate"
      (buttonClick)="edit_profile()"
    ></app-button>
  </div>
  <div *ngIf="!showEditView" class="d-flex gap-1">
    <app-button
      [imageSrc]="'./assets/img/new-ui/edit_profile.svg'"
      [buttonText]="'edit_profile' | translate"
      (buttonClick)="editDispatchProfile('1')"
    ></app-button>
    <app-button
      [imageSrc]="'./assets/img/new-ui/generate_secret_code.svg'"
      [buttonText]="'EDITPROFILE.generate_code_btn' | translate"
      (buttonClick)="openSecretCodeModal()"
    ></app-button>
  </div>
</ng-template>
<div class="mt-3">
  <div class="sub-title">
    <span class="font-weight-normal">
      {{ "EDITPROFILE.pharmacy_id" | translate }}:
    </span>
    {{ pharmacy_id }}
  </div>

  <div [formGroup]="editProfileForm">
    <div class="row flex-column gap-1 col-md-10 px-0">
      <div class="row justify-content-center col-md-8">
        <div
          class="circle"
          [ngClass]="{
            add_image_background: !profile_img
          }"
        >
          <img
            *ngIf="profile_img"
            class="preview-img"
            [src]="profile_img"
            alt="Preview Image"
            width="150"
            height="150"
          />
          <img
            *ngIf="!profile_img"
            class="add_image"
            src="assets/img/new-ui/add_image.svg"
            alt="Preview Image"
          />

          <input
            class="browse-input"
            type="file"
            (input)="fileChangeEvent($event)"
            (click)="$event.target.value = null"
          />
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="col-2 font-weight-bold pr-0">
          {{ "EDITPROFILE.pharmacy_name" | translate }}
        </div>
        <div class="d-flex align-items-center gap-1 col-10 pl-0">
          <input
            type="text"
            formControlName="pharmacy_name"
            class="form-control col-6"
            placeholder="{{ 'EDITPROFILE.pharmacy_name' | translate }}"
            [ngClass]="{
              'is-invalid': submitted && f.pharmacy_name.errors
            }"
            disabled
          />
          <div
            *ngIf="submitted && f.pharmacy_name.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.pharmacy_name.errors.required">
              Pharmacy name is required
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div class="col-2 font-weight-bold pr-0">
          {{ "EDITPROFILE.email_address" | translate }}
        </div>
        <div class="d-flex align-items-center gap-1 col-10 pl-0">
          <input
            type="email"
            formControlName="email"
            class="form-control col-6"
            placeholder="ak.seth@gmail.com"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            disabled
          />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">
              Pharmacy email is required
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div class="col-2 font-weight-bold pr-0">
          {{ "EDITPROFILE.phone_no" | translate }}
        </div>
        <div class="d-flex align-items-center gap-1 col-10 pl-0">
          <input
            type="text"
            formControlName="mobile_no"
            class="form-control col-6"
          />
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div class="col-2 font-weight-bold pr-0">
          {{ "EDITPROFILE.landline_number" | translate }}
        </div>
        <div class="d-flex align-items-center gap-1 col-10 pl-0">
          <input
            type="text"
            formControlName="pharmacy_landline_num2"
            class="form-control col-6"
            placeholder=""
            [ngClass]="{
              'is-invalid': submitted && f.pharmacy_landline_num2.errors
            }"
            maxlength="12"
          />
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div class="col-2 font-weight-bold pr-0">
          {{ "EDITPROFILE.fax_country_code" | translate }}
        </div>
        <div class="d-flex align-items-center gap-1 col-10 pl-0">
          <input
            type="text"
            formControlName="fax_country_code"
            class="form-control col-6"
            placeholder=""
            [ngClass]="{
              'is-invalid': submitted && f.fax_country_code.errors
            }"
            maxlength="12"
          />
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div class="col-2 font-weight-bold pr-0">
          {{ "EDITPROFILE.fax_number" | translate }}
        </div>
        <div class="d-flex align-items-center gap-1 col-10 pl-0">
          <input
            type="text"
            formControlName="fax_number2"
            class="form-control col-6"
            placeholder=""
            maxlength="12"
          />
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div class="col-2 font-weight-bold pr-0">
          {{ "EDITPROFILE.apartment_suite" | translate }}
        </div>
        <div class="d-flex align-items-center gap-1 col-10 pl-0">
          <input
            type="text"
            formControlName="apartment_suite"
            class="form-control col-6"
            placeholder="{{ 'EDITPROFILE.apartment_suite' | translate }}"
            [ngClass]="{
              'is-invalid': submitted && f.apartment_suite.errors
            }"
            disabled
          />
          <div
            *ngIf="submitted && f.apartment_suite.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.apartment_suite.errors.required">
              Pharmacy name is required
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div class="col-2 font-weight-bold pr-0">
          {{ "EDITPROFILE.suite_number" | translate }}
        </div>
        <div class="d-flex align-items-center gap-1 col-10 pl-0">
          <input
            type="text"
            formControlName="suite_number"
            class="form-control col-6"
            placeholder="{{ 'EDITPROFILE.suite_number' | translate }}"
            [ngClass]="{ 'is-invalid': submitted && f.suite_number.errors }"
            disabled
          />
          <div
            *ngIf="submitted && f.suite_number.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.suite_number.errors.required">
              suite number is required
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div class="col-2 font-weight-bold pr-0">
          {{ "EDITPROFILE.pharmacy_location" | translate }}
        </div>
        <div class="d-flex align-items-center gap-1 col-10 pl-0">
          <input
            type="text"
            formControlName="pharmacy_location"
            class="form-control col-6"
            placeholder="{{ 'EDITPROFILE.pharmacy_location' | translate }}"
            [ngClass]="{
              'is-invalid': submitted && f.pharmacy_location.errors,
              'icon-no-drop': editLocation
            }"
            readonly
            (click)="
              openModalSelectLocation(
                locationBodyTemplate,
                locationHeaderTemplate
              )
            "
          />
          <div
            *ngIf="submitted && f.pharmacy_location.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.pharmacy_location.errors.required">
              Pharmacy location is required
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div class="col-2 font-weight-bold pr-0">
          {{ "EDITPROFILE.email_notifications" | translate }}
        </div>
        <div class="d-flex align-items-center gap-1 col-10 pl-0">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              [checked]="emailNotifications"
              class="custom-control-input"
              id="customCheck"
              name="example1"
              (change)="onChangeEmailNotification($event.target.checked)"
            />
            <label class="custom-control-label" for="customCheck"></label>
          </div>
        </div>
      </div>

      <!-- Display pharmacy business hours -->
      <div *ngIf="business_hours?.length > 0" class="mb-5 col-md-7">
        <div>
          <label class="font-weight-bold"
            >{{ "BUSINESS_HOURS.entity_business_hours" | translate }}
          </label>
          <span
            ><i
              class="fa fa-clock-o text-danger ml-2 mr-2"
              aria-hidden="true"
            ></i
          ></span>
        </div>
        <hr class="my-1" />
        <div
          class="mb-1"
          style="font-size: 15px"
          *ngFor="let item of business_hours"
        >
          {{ item }}
        </div>
        <hr class="my-1" />
      </div>
      <!-- ./ Display pharmacy business hours  -->
    </div>
  </div>
</div>

<div
  bsModal
  #otpModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-success modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          {{ "EDITPROFILE.verification" | translate }}
        </h4>
        <button
          type="button"
          class="close"
          (click)="otpModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form action="/action_page.php">
          <div class="">
            <label for="pwd"
              ><strong>{{
                "EDITPROFILE.enter_verification" | translate
              }}</strong></label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="otp"
              [ngModelOptions]="{ standalone: true }"
              maxlength="4"
              placeholder="{{ 'EDITPROFILE.enter_verification' | translate }}"
            />
          </div>
          <div
            *ngIf="counter <= 0"
            class="resend-btn text-center"
            (click)="resendOtp()"
          >
            <a
              ><i class="fa fa-repeat mr-2" aria-hidden="true"></i
              >{{ "VERIFICATION.resend_phone_otp" | translate }}</a
            >
          </div>

          <p *ngIf="counter > 0" class="resend-btn text-center">
            {{ counter | formatTime }}
          </p>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="otpModal.hide()"
        >
          {{ "EDITPROFILE.cancel" | translate }}
        </button>

        <button
          type="button"
          class="btn btn-secondary text-center"
          (click)="verifyOtp()"
        >
          {{ "EDITPROFILE.submit" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #content>
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="1 / 1"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
</ng-template>

<ng-template #secretCodeTemplate>
  <div class="text-center">
    <p>
      {{ "EDITPROFILE.modal_comment" | translate }}
    </p>
    <div class="row justify-content-center">
      <div class="secret-code">
        <h3 class="mr-3">{{ secretCode }}</h3>
        <img
          style="cursor: pointer"
          src="./assets/img/new-ui/copy_to_clipboard.svg"
          (click)="copyCode()"
        />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #locationHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3 justify-content-between">
    <h5 class="modal-title">{{ "LOCATION.select_location" | translate }}</h5>
    <ng-container
      *ngTemplateOutlet="locationAgmComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #locationBodyTemplate>
  <div class="mx-3">
    <app-location-agm
      [item]="locationData"
      (getLocationData)="handleAddressChange($event, modal)"
    ></app-location-agm>
  </div>
</ng-template>
