import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../../../services.service';

@Component({
  selector: 'app-coment-message',
  templateUrl: './coment-message.component.html',
  styleUrls: ['./coment-message.component.scss']
})
export class ComentMessageComponent implements OnInit {
  comments: any[] = [''];
  value: any;
  @Input() id = '';
  constructor(
    private services: ServicesService,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService,
  ) {
  }
  ngOnInit(): void {
    this.getPrescriptionComments(this.id);
    this.deleteComments(`63e426c714b5df3018fcb00b`)
  }

  getPrescriptionComments(id) {
    this.spinner.show();
    this.services.mensajeCambio.subscribe((data) => {
      this.comments = [...data.comments];
      this.scrollToBottom('chat-sala')
    });
    this.services.getCommentsByIdJobs(id).pipe().subscribe(data => {
      this.spinner.hide();
      this.comments = [...data.comments];

      this.scrollToBottom('chat-sala')
    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });
  }
  scrollToBottom(id) {
    if (this.comments.length > 0) {
      var div = document.getElementById(id);
      if (div) {

        div.scrollTop = div.scrollHeight - div.clientHeight;
      }

    }


  }
  deleteComments(idComment: string) {
    console.log(`delete`)
    this.services.deleteCommentByIdJobs(this.id, idComment).pipe().subscribe(data => {
      console.log(`deleteagain`)
      this.getPrescriptionComments(this.id);
    });
  }

}
