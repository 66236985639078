  <!-- <div class="row text-center p-2">
 	
 </div> -->

  <div class="card w-100 mt-5 ddp-35 p-4">
  	<h2 class="mr-auto ml-auto">{{ 'DRIVERDETAILS.driver_details' | translate }}</h2>

<!-- <div class="row mb-2 point"  (click)="back_DriverPage()">
    <div _ngcontent-cxu-c1=""><a _ngcontent-cxu-c1="" class="back-btn-icon"><i _ngcontent-cxu-c1="" aria-hidden="true" class="fa fa-arrow-left"></i></a></div>
</div> -->
        <div class="row">

          <div class="col-md-3"  *ngIf="!driver_details?.profile_img"><div class="dd-img-set"><img class="navbar-brand-full driver-d-img" src="assets/img/avatars/defaultimg.jpg"  alt=""></div></div>
          <div class="col-md-3"  *ngIf="driver_details?.profile_img"><div class="dd-img-set"><img class="navbar-brand-full driver-d-img point" [src]="driver_details.profile_img"alt="" (click)="imageSelection(driver_details.profile_img)"></div></div>
          <!-- <div class="col-md-8"><h5 class="d-driver-name">{{driver_details?.fullname}}</h5><p class="dd-mail">{{driver_details?.email}}</p> -->


            <div class="col-md-6">
              
              <h5 class="d-driver-name">{{driver_details?.fullname}}</h5>
              <p class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.email' | translate }} :</strong></span> <span>{{driver_details?.email}}</span></p>
              <p class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.phone_no' | translate }} :  </strong> </span><span>{{driver_details?.country_code}} {{driver_details?.mobile_no}}</span></p>
               <p *ngIf="driver_details?.app_info.app_version && driver_details?.app_info.device_name;else versionNA" class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.current_version' | translate }} :  </strong> </span><span>{{driver_details?.app_info.app_version}} - {{driver_details?.app_info.device_name}}</span></p>

                <p *ngIf="driver_details?.app_info.active_on" class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.activeOn' | translate }} : </strong></span><span>{{modifyDateTime(driver_details?.app_info.active_on)}}</span></p>

                 <p *ngIf="!driver_details?.app_info.active_on" class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.activeOn' | translate }} : </strong></span><span>N/A</span></p>

               <ng-template #versionNA> <p class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.current_version' | translate }} : </strong> </span> N/A</p>
               </ng-template>

                 <!--  <ng-template #activeNA> <p class="dd-mail"><span><strong>{{'DRIVERDETAILS.activeOn' | translate } : </strong> </span> N/A</p>
               </ng-template> -->
       <div class="row pr-4">
          <div class="col-md-9 mt-3">
              <h4 class="mt-3">{{ 'DRIVERDETAILS.vehicle_info' | translate }}</h4>
              <!-- <p *ngIf="!driver_details?.vehicle.driver_type" class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.driver_type' | translate }} :  </strong> </span><span>N/A</span></p> -->

               <p *ngIf="driver_details?.vehicle.driver_type && driver_details?.vehicle.driver_type == '1'" class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.driver_type' | translate }} :  </strong> </span><span>Taxi Driver</span></p>

                 <p *ngIf="driver_details?.vehicle.driver_type && driver_details?.vehicle.driver_type == '2'" class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.driver_type' | translate }} :  </strong> </span><span>Uber Driver</span></p>
                  <p *ngIf="driver_details?.vehicle.driver_type && driver_details?.vehicle.driver_type == '3'" class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.driver_type' | translate }} :  </strong> </span><span>Qualified Driver</span></p>

                  <p *ngIf="driver_details?.vehicle.permit_no" class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.permit_no' | translate }} :  </strong> </span><span>{{driver_details?.vehicle.permit_no}}</span></p>



              <p *ngIf="!driver_details?.vehicle.vehicle_type" style="margin-left: 70px;">N/A</p>
              <p *ngIf="driver_details?.vehicle.vehicle_type" class="dd-mail"><strong>{{ 'DRIVERDETAILS.vehicle_type' | translate }} :</strong>
                 <span *ngIf="driver_details?.vehicle.vehicle_type == '1'" >Car</span>
                <span *ngIf="driver_details?.vehicle.vehicle_type == '2'">Scooter</span>
                 <span *ngIf="driver_details?.vehicle.vehicle_type == '3'">Bicycle</span>
              </p>
              <div *ngIf="driver_details?.vehicle.vehicle_type == '1'">
                <p *ngIf="driver_details?.vehicle.fuel_type" class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.vehicle_fuel_type' | translate }} :</strong></span>
                 <span  *ngIf="driver_details?.vehicle.fuel_type == '1'">Hybrid</span>
                  <span  *ngIf="driver_details?.vehicle.fuel_type == '2'">Electric</span>
                   <span  *ngIf="driver_details?.vehicle.fuel_type == '3'">Gas</span>
                </p>

                <p *ngIf="driver_details?.vehicle.vehicle_brand_name" class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.vehicle_brand_name' | translate }} :</strong></span> <span>{{driver_details?.vehicle.vehicle_brand_name}}</span></p>

              <p *ngIf="driver_details?.vehicle.vehicle_model" class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.vehicle_model' | translate }} :  </strong> </span><span>{{driver_details?.vehicle.vehicle_model}}</span></p>

               <p *ngIf="driver_details?.vehicle.year_of_model" class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.year_model' | translate }} :  </strong> </span><span>{{driver_details?.vehicle.year_of_model}}</span></p>

                <p *ngIf="driver_details?.vehicle.vehicle_license_no" class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.vehicle_license_no' | translate }} :  </strong> </span><span>{{driver_details?.vehicle.vehicle_license_no}}</span></p>

               
                 </div>

                 <div *ngIf="driver_details?.vehicle.vehicle_type == '2'">
                     <p *ngIf="driver_details?.vehicle.fuel_type" class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.vehicle_fuel_type' | translate }} :</strong></span>
                      <span  *ngIf="driver_details?.vehicle.fuel_type == '1'">Electric</span>
                       <span  *ngIf="driver_details?.vehicle.fuel_type == '2'">Gas</span>
                    </p>
                   
                 </div>
                  <div *ngIf="driver_details?.vehicle.vehicle_type == '3'">
                     <p *ngIf="driver_details?.vehicle.fuel_type" class="dd-mail"><span><strong>{{ 'DRIVERDETAILS.vehicle_fuel_type' | translate }} :</strong></span>
                      <span  *ngIf="driver_details?.vehicle.fuel_type == '1'">Normal</span>
                       <span  *ngIf="driver_details?.vehicle.fuel_type == '2'">Electric</span>
                    </p>
                   
                 </div>
            
          </div>
         
         
        
        </div>
          
          </div>
          <div class="col-md-3">

             <div class="l-head text-center text-bold-800"><p><strong>{{ 'DRIVERDETAILS.vehicle_img' | translate }}</strong></p></div>
 
                <div class="vehicleImg mr-auto ml-auto">
                  <p *ngIf="!driver_details?.vehicle.vehicle_image" class="vehicleImg text-center">{{ 'DRIVERDETAILS.not_uploaded' | translate }}</p>
              
                <img *ngIf="driver_details?.vehicle.vehicle_image" class="w-100 point" [src]="driver_details?.vehicle.vehicle_image" (click)="imageSelection(driver_details?.vehicle.vehicle_image)"/>
              </div>
            
   
          	
          </div>
        </div>
  <!--       <div class="row">
        	<div class="col-md-3">
        		
        	</div>
        	<div class="col-md-6 pt-3">

               <div class="l-head text-center"><p><strong>{{ 'DRIVERDETAILS.comments' | translate }}</strong></p></div>
             
               <ul class="commt-list">
                 <li *ngIf="allComments.length == 0">
                   <p class="text-center">{{ 'DRIVERDETAILS.no_comments' | translate }}</p>
                </li>
                <li *ngFor="let comment of latestComments">               
                  <p>
                   {{comment.comment}}
                  </p>
                  <span class="datetime"><i class="fa fa-clock-o"></i>&nbsp;
                   {{comment.createdAt  | date: 'MM/dd/yyyy HH:mm'}}
                </span>
                </li>
                    
               </ul>

                 <button *ngIf="allComments.length > 3" class="btn btn-success cmtmore" (click)="commentModal.show()">{{ 'DRIVERDETAILS.more' | translate }}</button>
            
          </div>
          <div class="col-md-3">
        		
        	</div>
        </div> -->

   
    </div>
         <!-- <ngx-spinner
                  bdColor="rgba(51,51,51,0.8)"
                  size="medium"
                  color="#fff"
                  type="ball-spin-clockwise"
                >
                  <p style="font-size: 20px; color: white">Loading...</p>
                </ngx-spinner> -->

 <div bsModal #commentModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-warning" role="document">
            <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">{{ 'DRIVERDETAILS.comments' | translate }}</h4>
              <button type="button" class="close" (click)="commentModal.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
            </div>
            
            <div class="modal-body comntPopBdy">
              
              <ul class="commt-list">
                <li *ngIf="allComments.length == 0">
                   <p class="text-center">{{ 'DRIVERDETAILS.no_comments' | translate }}</p>
                </li>
                <li *ngFor="let comment of allComments">               
                  <p>
                  {{comment.comment}}
                  </p>
                  <span class="datetime"><i class="fa fa-clock-o"></i>&nbsp;
                  {{comment.createdAt  | date: 'MM/dd/yyyy HH:mm'}}
                </span>
                </li>            
                 
               </ul>
          
            </div>
              <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="commentModal.hide()">{{ 'DRIVERDETAILS.cancel' | translate }}</button>
            </div>
          </div>
          </div>
        </div>

        <div bsModal #successModal11="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-default" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <!-- <h4 class="modal-title">Confirmation</h4> -->
                <button type="button" class="close" (click)="successModal11.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
               <div class="col-12 text-center">
                   <img class="img-fluid img-c rounded" [src]="selected_image">
               </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="successModal11.hide()">{{ 'DRIVERDETAILS.cancel' | translate }}</button>
               <!--  <button type="button" class="btn btn-success" (click)="active_driver_modal()" >Activate</button> -->
              </div>
            </div><!-- /.modal-content -->
          </div><!-- /.modal-dialog -->
     </div><!-- /.modal -->