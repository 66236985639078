import {
    HttpClient
} from '@angular/common/http';
import {
    Component
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {
    Router
} from '@angular/router';
import {
    TranslateService
} from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment-timezone';
import {
    CountryISO, SearchCountryField
} from 'ngx-intl-tel-input';
import {
    NgxSpinnerService
} from "ngx-spinner";
import {
    ToastrService
} from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import {
    AuthService
} from '../../auth.service';
moment.tz.setDefault("UTC");

@Component({
    selector: 'app-dashboard',
    templateUrl: 'register.component.html'
})
export class RegisterComponent {

    public iban_no = ""
    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    preferredCountries: CountryISO[] = [CountryISO.Canada, CountryISO.India, CountryISO.UnitedStates];
    public phone: any;
    pharmacy_landline_num = "";

    changePreferredCountries() {
        this.preferredCountries = [CountryISO.India, CountryISO.Canada];
    }


    public country_code: any;
    public options: any;
    public password: string;
    public r_password: string;
    public email: string;
    public phone_no: string;
    public pharmacy_name: string;
    public location1: string;
    public lat: any;
    public long: any;
    public country: any;
    public country_shortname: any;
    public state: any;
    public state_shortname: any;
    public city: any;
    public city_shortname: any;
    public cityStatus = false;
    public countryStatus = false;
    public stateStatus = false;
    public postalcode: any;
    public postal_codestatus = false;
    public country_flag_name = "ca";
    // public business_hours: any = {};
    public google_place_id = "";
    public googleApiResult: any = false;
    public profile_img = "";

    public countryList = [{
        "code": "CA",
        "code3": "CAN",
        "name": "Canada",
        "number": "124"
    },
    {
        "code": "US",
        "code3": "USA",
        "name": "United States of America",
        "number": "840"
    },
    ];
    public selectedLanguage = "en";
    cookieStatus = true;

    public pharmacyNameOptions = {
        componentRestrictions: {
            country: "ca"
        },
        types: ["pharmacy", "drugstore", "hospital", "health"]
    }

    onChange1(val) {
        if (val) {
            this.country_code = val.dialCode;
            this.country_flag_name = val.countryCode.toLowerCase();
            var num = val.number.replace(/ /g, '');
            const self = this;
            let chIbn = num.split('-').join('');
            if (chIbn.length > 0) {
                chIbn = chIbn.match(/\d{3}(?=\d{2,3})|\d+/g).join("-");
            }
            this.iban_no = chIbn;
        }


    }


    //function for cange landline number pattern 
    onChange2(val) {
        if (val) {
            var num = val.replace(/ /g, '');
            let chIbn = num.split('-').join('');
            if (chIbn.length > 0) {
                chIbn = chIbn.match(/\d{3}(?=\d{2,3})|\d+/g).join("-");
            }
            this.pharmacy_landline_num = chIbn;
        }
    }



    public handleAddressChange(address) {
        this.cityStatus = false;
        this.stateStatus = false;
        this.countryStatus = false;
        this.postal_codestatus = false;
        this.location1 = address.formatted_address
        this.lat = address.geometry.location.lat();
        this.long = address.geometry.location.lng();
        var data;
        for (data of address.address_components) {
            if (data.types[0] == "locality") {
                this.city = data.long_name;
                this.city_shortname = data.short_name;
                this.cityStatus = true;
            }

            if (data.types[0] == "administrative_area_level_1") {
                this.state = data.long_name;
                this.state_shortname = data.short_name;
                this.stateStatus = true;
            }
            if (data.types[0] == "country") {
                this.country = data.long_name;
                this.country_shortname = data.short_name;
                this.countryStatus = true;
            }
            if (data.types[0] == "postal_code") {
                this.postalcode = data.long_name;
                this.postal_codestatus = true;
            }

        }
        this.google_place_id = address.place_id;
        // this.business_hours = this.formatBusinessHours(address.current_opening_hours.weekday_text)
        // console.log('laat:', this.lat, 'loog:', this.long);
        // console.log("country:", this.country, "country_shortname:", this.country_shortname, "state:", this.state, "state_shortname:", this.state_shortname, "city:", this.city, "city_shortname:", this.city_shortname, "postalcode:", this.postalcode, "cityStatus:", this.cityStatus, "stateStatus", this.stateStatus, "countryStatus", this.countryStatus, "postal_codestatus", this.postal_codestatus);

        // Do some stuff
    }

    public handleNameChange(googleApiResult) {
        console.log(googleApiResult)
        this.googleApiResult = googleApiResult
        // Set pharmacy_name
        if (googleApiResult.name) {
            this.registerForm.controls.pharmacy_name.setValue(googleApiResult.name)
        }
    }
    private setInputElementValue(elementId, value) {
        const element = document.querySelector(`#${elementId}`) as HTMLInputElement;
        if (element) {
            element.value = value;
            element.dispatchEvent(new Event("change", { bubbles: true, cancelable: true }));
        }
    }
    public handleFillingForm(event) {
        console.log("handleFillForm");
        if (this.googleApiResult.formatted_address) {
            this.location1 = this.googleApiResult.formatted_address;
            const locationControl = this.registerForm.get("location1");
            locationControl?.setValue(this.googleApiResult.formatted_address);
        }


        if (this.googleApiResult.international_phone_number) {
            const formattedPhoneNumber = this.googleApiResult.international_phone_number.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            this.setInputElementValue("phone", formattedPhoneNumber);
            this.iban_no = formattedPhoneNumber;
        }

        if (this.googleApiResult.icon) {
            this.profile_img = this.googleApiResult.icon;
        }
        // Call handleAddressChange to set all inputs
        this.handleAddressChange(this.googleApiResult)
    }

    private formatBusinessHours(weekday_text: []) {
        let formatted_business_hours = {};
        let sun, mon, tue, wed, thu, fri, sat = {}
        if (weekday_text) {
            weekday_text.forEach((day_text: String) => {
                if (day_text.includes('Sunday:')) {
                    const hours_text = day_text.split('Sunday:')[1].trim();
                    if (hours_text.toLowerCase() === "closed") {
                        sun = hours_text;
                    } else {
                        const parts = hours_text.split(" – ");
                        // Extract the "from" and "to" parts
                        const from = parts[0];
                        const to = parts[1];
                        sun = {
                            from: from,
                            to: to
                        }
                    }
                }
                if (day_text.includes('Monday:')) {
                    const hours_text = day_text.split('Monday:')[1].trim()
                    if (hours_text.toLowerCase() === "closed") {
                        mon = hours_text;
                    } else {
                        const parts = hours_text.split(" – ");
                        // Extract the "from" and "to" parts
                        const from = parts[0];
                        const to = parts[1];
                        mon = {
                            from: from,
                            to: to
                        }
                    }
                }
                if (day_text.includes('Tuesday:')) {
                    const hours_text = day_text.split('Tuesday:')[1].trim();
                    if (hours_text.toLowerCase() === "closed") {
                        tue = hours_text;
                    } else {
                        const parts = hours_text.split(" – ");
                        // Extract the "from" and "to" parts
                        const from = parts[0];
                        const to = parts[1];
                        tue = {
                            from: from,
                            to: to
                        }
                    }
                }
                if (day_text.includes('Wednesday:')) {
                    const hours_text = day_text.split('Wednesday:')[1].trim();
                    if (hours_text.toLowerCase() === "closed") {
                        wed = hours_text;
                    } else {
                        const parts = hours_text.split(" – ");
                        // Extract the "from" and "to" parts
                        const from = parts[0];
                        const to = parts[1];
                        wed = {
                            from: from,
                            to: to
                        }
                    }
                }
                if (day_text.includes('Thursday:')) {
                    const hours_text = day_text.split('Thursday:')[1].trim();
                    if (hours_text.toLowerCase() === "closed") {
                        thu = hours_text;
                    } else {
                        const parts = hours_text.split(" – ");
                        // Extract the "from" and "to" parts
                        const from = parts[0];
                        const to = parts[1];
                        thu = {
                            from: from,
                            to: to
                        }
                    }
                }
                if (day_text.includes('Friday:')) {
                    const hours_text = day_text.split('Friday:')[1].trim();
                    if (hours_text.toLowerCase() === "closed") {
                        fri = hours_text;
                    } else {
                        const parts = hours_text.split(" – ");
                        // Extract the "from" and "to" parts
                        const from = parts[0];
                        const to = parts[1];
                        fri = {
                            from: from,
                            to: to
                        }
                    }
                }
                if (day_text.includes('Saturday:')) {
                    const hours_text = day_text.split('Saturday:')[1].trim();
                    if (hours_text.toLowerCase() === "closed") {
                        sat = hours_text;
                    } else {
                        const parts = hours_text.split(" – ");
                        // Extract the "from" and "to" parts
                        const from = parts[0];
                        const to = parts[1];
                        sat = {
                            from: from,
                            to: to
                        }
                    }
                }
            })
            formatted_business_hours = {
                sun: sun,
                mon: mon,
                tue: tue,
                wed: wed,
                thu: thu,
                fri: fri,
                sat: sat
            }

        }
        return formatted_business_hours;
    }

    registerForm: UntypedFormGroup;
    submitted = false;
    constructor(
        private formBuilder: UntypedFormBuilder,
        public toastr: ToastrService,
        private services: AuthService,
        private http: HttpClient,
        public router: Router,
        private spinner: NgxSpinnerService,
        public ts: TranslateService
    ) {
        ts.addLangs(['en', 'fr']);
        ts.setDefaultLang('en');

        const browserLang = ts.getBrowserLang();
        ts.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    }

    ngOnInit() {
        if (environment.production) {
            this.addScript();
            this.addNoScript();
        }


        this.cookieStatus = JSON.parse(localStorage.getItem("cookiesStatus")) ? JSON.parse(localStorage.getItem("cookiesStatus")) : false;

        var lang = JSON.parse(localStorage.getItem("language"));
        if (!lang) {
            lang = "en"
        }
        this.selectedLanguage = lang;
        localStorage.setItem('language', JSON.stringify(lang));
        this.ts.use(lang);

        if (this.services.isLoggedIn()) {
            this.router.navigate(['/dashboard']);
        }

        this.registerForm = this.formBuilder.group({
            pharmacy_name: ['', [Validators.required, Validators.pattern(""), Validators.minLength(6)]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(12)]],
            r_password: [''],
            location1: ['', Validators.required],
            fax_country_code: [''],
            fax_number: [''],
            apartment_suite: [''],
            suite_number: [''],
        });

        const pharmacyNameControl = this.registerForm.controls["pharmacy_name"];
        pharmacyNameControl.valueChanges.subscribe(value => {
            if (value.trim() === "") {
                pharmacyNameControl.setErrors({
                    required: true
                })
            }
        })

        const emailControl = this.registerForm.controls["email"];
        emailControl.valueChanges.subscribe(value => {
            if (value.trim() === "") {
                emailControl.setErrors({
                    required: true
                })
            }
        })

        const passwordControl = this.registerForm.controls["password"];
        passwordControl.valueChanges.subscribe(value => {
            if (value.trim() === "") {
                passwordControl.setErrors({
                    required: true
                })
            }
        })


        const locationControl = this.registerForm.controls["location1"];
        locationControl.valueChanges.subscribe(value => {
            if (value.trim() === "") {
                locationControl.setErrors({
                    required: true
                })
            }
        })
    }
    addScript() {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "./header-script.js";
        script.id = "script";
        document.head.appendChild(script);
    }
    addNoScript() {
        const noscript = document.createElement("noscript");
        const iframe = document.createElement("iframe");
        iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-W6VM776";
        iframe.height = "0";
        iframe.width = "0";
        iframe.style.display = "none";
        iframe.style.visibility = "hidden";
        iframe.id = "iframe"
        // noscript.innerHTML = iframe;
        noscript.id = "noscript";
        document.body.appendChild(iframe);
    }
    cleanScript() {
        if (environment.production) {
            document.querySelector("script").remove();
            document.querySelector("iframe").remove();
        }

        // document.removeChild()
    }
    // convenience getter for easy access to form fields
    get f() {
        return this.registerForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        if (this.registerForm.controls.r_password.value != this.registerForm.controls.password.value) {
            return;
        }
        if (this.cityStatus == false) {
            this.city_shortname = "";
            this.city = "";
        }
        if (this.countryStatus == false) {
            this.country_shortname = "";
            this.country = "";
        }
        if (this.stateStatus == false) {
            this.state_shortname = "";
            this.state = "";
        }
        if (this.postal_codestatus == false) {
            this.postalcode = "";
        }
        if (!this.location1) {
            this.toastr.error("", "Please Enter Location")
            return

        }
        if (!this.iban_no) {
            this.toastr.error("", "Please Enter Phone Number")
            return
        }
        if (this.iban_no.length != 12) {
            this.toastr.error('', 'Phone Number must 10 digits!');
            return;
        }

        var timeZone = moment.tz.guess();
        this.spinner.show();
        this.services.register(this.f.email.value, this.f.password.value.trim(), this.iban_no, this.f.pharmacy_name.value.trim(), this.location1.trim(), [this.lat, this.long], this.country_shortname, this.country, this.state_shortname, this.state, this.city_shortname, this.city, this.postalcode, this.country_code, timeZone, this.country_flag_name, this.pharmacy_landline_num,
            this.f.fax_country_code.value, this.f.fax_number.value, this.f.apartment_suite.value, this.f.suite_number.value, this.google_place_id, this.profile_img
        ).pipe().subscribe(data => {
            if (data.code == 200) {
                this.spinner.hide();
                localStorage.setItem('currentUser', JSON.stringify(data.result));
                localStorage.setItem('newUser', JSON.stringify(1));
                if (data.result.email_verified == false || data.result.mobile_verified == false) {
                    this.router.navigate(['verification']);
                } else {
                    this.router.navigate(['dashboard']);
                    this.toastr.success('success', 'Registered Successfully');
                }
                localStorage.setItem('currentSubscriptionId', JSON.stringify({}));

            } else {
                this.spinner.hide();
                this.toastr.error('error', data.message);
                return;

            }

        });

    }

    mychange(val) {
        const self = this;
        let chIbn = val.split('-').join('');
        if (chIbn.length > 0) {
            // chIbn = chIbn.match(new RegExp('.{1,3}', 'g')).join('-');
            chIbn = chIbn.match(/\d{3}(?=\d{2,3})|\d+/g).join("-");
        }
        this.iban_no = chIbn;
        // this.jobForm2.controls['customer_phone2'].setValue(this.iban_no);
    }




    onChange(event) {
        console.log("event:", event);
    }

    switchLang(lang: string) {
        this.spinner.show();
        localStorage.setItem('language', JSON.stringify(lang));
        this.ts.use(lang);
        this.selectedLanguage = lang;
        this.spinner.hide();
        // this.navItems = [];
    }

    changeCookieStatus() {
        this.cookieStatus = true;
        localStorage.setItem('cookiesStatus', JSON.stringify('true'));

    }
    ngOnDestroy() {
        this.cleanScript();
    }
}