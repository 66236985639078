import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss']
})
export class DatepickerRangeComponent implements OnInit {
  hoveredDate: NgbDate | null = null;
  startDate: any;
  endDate: any;
  model2: string;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  daterange: any = {};
  configData: any = {};
  @Input()
  set config(configData) {
    this.configData = configData;
    this.onSelectDate();
  }
  @Output() onDateRangeSelection: EventEmitter<any> = new EventEmitter();

  constructor(
    private calendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    public formatter: NgbDateParserFormatter
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), "d", 10);
  }

  ngOnInit(): void { }
  onSelectDate() {
    if (this.configData.startDate && this.configData.endDate) {
      const splitFrom = this.configData.startDate.split("/");
      const splitToDate = this.configData.endDate.split("/");
      this.fromDate.day = parseInt(splitFrom[2]);
      this.fromDate.month = parseInt(splitFrom[1]);
      this.fromDate.year = parseInt(splitFrom[0]);
      this.toDate.day = parseInt(splitToDate[2]);
      this.toDate.month = parseInt(splitToDate[1]);
      this.toDate.year = parseInt(splitToDate[0]);
    }
    // this.fromDate.day
  }
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.fromDate && this.toDate) {
      this.startDate = `${this.fromDate.year}/${this.fromDate.month}/${this.fromDate.day}`;
      this.endDate = `${this.toDate.year}/${this.toDate.month}/${this.toDate.day}`;
      let dateOutput: any = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      this.onDateRangeSelection.emit(dateOutput);
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }


}
