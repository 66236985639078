<link
  href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
  rel="stylesheet"
/>
<div class="card mt-3">
  <div class="card-body">
    <div class="row">
      <div class="col-lg-7">
        <div class="scp-breadcrumb-inner">
          <ul class="inner-breadcrumb">
            <li class="back-btn-arrow" style="cursor: pointer">
              <a (click)="back()" class="back-arrow"
                ><i
                  class="fa fa-arrow-left mr-2"
                  aria-hidden="true"
                  style="color: #3eaa79"
                ></i
                >Back</a
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="col-lg-3 text-right">
        <div
          *ngIf="job_details?.ownershipApprovedByPharmacy == false && job_details?.job_status == 'P' && job_details?.isAccepted == true && job_details?.isExpired == false && job_details?.ride_fare > 0 && job_details?.jobFor == '1'">

          <button style="color: #fff;
					background-color: #0fc303;
					border: 1px solid #0fc303;
					outline: none;
					box-shadow: none;
					font-size: 14px;
					font-weight: 500;
					padding: 5px 5px;
					border-radius: 5px;" (click)="approveOwnershipModal.show()">{{ 'JOBDETAILS.approve_ownership' | translate
						}}</button>
        </div>

      </div> -->
      <!-- *PDF <div class="col-lg-2 text-right">
        <a class="download-pdf point" (click)="getPdf()"><i class="fa fa-download" aria-hidden="true"></i></a>
      </div> -->
    </div>
    <div class="row">
      <div class="col-xl-9">
        <div class="row">
          <div class="col-md-6 align-self-center">
            <h4 class="driver-job-id">
              Order Number # {{ data?.code ? data?.code : "N/A" }}
            </h4>
          </div>
          <div class="col-md-6 align-self-center">
            <button
              class="btn btn-outline-success btn-sm"
              (click)="onAnswerModal(answerModal)"
              [disabled]="!isAttempted"
            >
              <i class="fa fa-medkit"></i> Attend prescription
            </button>
            <button
              class="btn btn btn-outline-success btn-sm"
              (click)="downloadFile()"
            >
              <i class="fa fa-download"></i> Download prescription
            </button>
            <button
              class="btn btn btn-outline-success btn-sm"
              (click)="openCommentModal()"
            >
              <i class="fa fa-comments"></i> Add comments
            </button>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-lg-4">
            <div class="card fixed-height">
              <div class="card-body">
                <p class="create-date mb-0">Order Info</p>
                <table class="job-info-table">
                  <tr>
                    <td><span class="create-on">CREATED DATE</span></td>
                    <td>
                      <span class="create-date">
                        {{ modifyDateTime(data?.createAt) }}</span
                      >
                    </td>
                  </tr>

                  <tr>
                    <td><span class="create-on">STATUS</span></td>
                    <td>
                      <span class="create-date">{{
                        data?.status ? data?.status : "N/A"
                      }}</span>
                    </td>
                  </tr>

                  <tr>
                    <td><span class="create-on">TOTAL</span></td>
                    <td>
                      <span class="create-date">{{
                        data?.total ? data?.total : "N/A"
                      }}</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="card fixed-height">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-5">
                    <p class="create-date mb-0">Adress Info</p>
                    <div class="media" style="margin-top: 10px">
                      <i
                        class="fa fa-map-marker mr-2 location-icon"
                        aria-hidden="true"
                      ></i>
                      <div class="media-body">
                        <h4
                          class="create-on"
                          style="margin-bottom: 3px; position: relative"
                        >
                          LOCATION FROM
                        </h4>
                        <p class="create-date">
                          {{
                            data?.address?.landmark
                              ? data?.address?.landmark
                              : "N/A"
                          }}
                        </p>

                        <h4
                          class="create-on"
                          style="margin-bottom: 3px; position: relative"
                        >
                          ORIGIN
                        </h4>
                        <p class="create-date">
                          {{ data?.origin ? data?.origin : "N/A" }}
                        </p>

                        <h4
                          class="create-on"
                          style="margin-bottom: 3px; position: relative"
                        >
                          CITY
                        </h4>
                        <p class="create-date">
                          {{
                            data?.address?.city ? data?.address?.city : "N/A"
                          }}
                        </p>

                        <h4
                          class="create-on"
                          style="margin-bottom: 3px; position: relative"
                        >
                          ZIP CODE
                        </h4>
                        <p class="create-date">
                          {{
                            data?.address?.zipcode
                              ? data?.address?.zipcode
                              : "N/A"
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-1">
                    <div class="v-line"></div>
                  </div>
                  <div class="col-lg-6">
                    <div class="media" style="margin-top: 27px">
                      <div class="media-body">
                        <h4
                          class="create-on"
                          style="margin-bottom: 3px; position: relative"
                        >
                          Pharmacy Note
                        </h4>

                        <app-comments-section
                          [comments]="comments"
                        ></app-comments-section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 customers-box">
        <br /><br />
        <div class="card">
          <div class="card-body customer-informatio-box">
            <p class="create-date">Customer Info</p>
            <div>
              <h4 class="customer-name">
                {{
                  data?.customer?.customer_name
                    ? data?.customer?.customer_name
                    : "N/A"
                }}
              </h4>
              <div class="media">
                <i
                  class="fa fa-envelope mr-3"
                  aria-hidden="true"
                  style="font-size: 15px"
                ></i>
                <div class="media-body">
                  <p class="create-date mb-0" style="margin-top: 3px">
                    {{ data?.customer?.email ? data?.customer?.email : "N/A" }}
                  </p>
                </div>
              </div>
              <div class="media">
                <i
                  class="fa fa-phone mr-3"
                  aria-hidden="true"
                  style="font-size: 18px"
                ></i>
                <div class="media-body">
                  <p class="create-date mb-0" style="margin-top: 3px">
                    {{
                      data?.customer?.country_code
                        ? data?.customer?.country_code
                        : ""
                    }}
                    {{
                      data?.customer?.phone_no
                        ? data?.customer?.phone_no
                        : "N/A"
                    }}
                  </p>
                </div>
              </div>
              <div class="media">
                <i
                  class="fa fa-map-marker mr-3"
                  aria-hidden="true"
                  style="font-size: 18px"
                ></i>
                <div class="media-body">
                  <p class="create-date mb-0" style="margin-top: 3px">
                    {{
                      data?.customer?.location
                        ? data?.customer?.location
                        : "N/A"
                    }}
                  </p>
                </div>
              </div>
              <div class="media">
                <i class="mr-3" aria-hidden="true" style="font-size: 18px"></i>
                <div class="media-body">
                  <table class="customer-apart-info">
                    <tr>
                      <td><span class="spn-inner1">Apartment No.</span></td>
                      <td>
                        <span class="spn-inner2">{{
                          data?.customer?.appartment_no
                            ? data?.customer?.appartment_no
                            : "N/A"
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><span class="spn-inner1">Door Code</span></td>

                      <td>
                        <span class="spn-inner2">{{
                          data?.customer?.door_code
                            ? data?.customer?.door_code
                            : "N/A"
                        }}</span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- TODO:VALIDACION -->
      <ng-container *ngIf="data !== 'undefined'; else tableNull">
        <div class="table-responsive">
          <table class="table table-borderless jobs-table">
            <thead>
              <tr style="align-items: center">
                <th>NAME PRODUCT</th>

                <th style="text-align: center">QUANTITY</th>
                <th style="text-align: center">PRICE</th>
                <th style="text-align: center">STATUS</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let order of data.details">
                <td>
                  {{
                    order?.productDetails?.name
                      ? order?.productDetails?.name
                      : "N/A"
                  }}
                </td>

                <td style="text-align: center">
                  {{ order?.amount ? order?.amount : "N/A" }}
                </td>

                <td style="text-align: center">
                  {{ order?.unitPrice ? order?.unitPrice : "N/A" }}
                </td>
                <td style="text-align: center" *ngIf="order.statusD == 1">
                  {{ "PRODUCTS.active" | translate }}
                </td>

                <td *ngIf="order.statusD == 0">
                  {{ "PRODUCTS.inactive" | translate }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
      <ng-template #tableNull> </ng-template>
    </div>
  </div>
</div>

<div
  bsModal
  #commentModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">New Comment</h4>
        <button
          type="button"
          class="close"
          (click)="cancelCommentModal()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div style="margin: 13px">
            <app-comments-section [comments]="comments"></app-comments-section>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="comment">New Comment</label>
              <textarea
                class="form-control"
                placeholder="Enter a comment ..."
                id="comment"
                rows="3"
                [(ngModel)]="newComment"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="cancelCommentModal()"
        >
          {{ "SUBSCRIPTION.back" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="confirmCommentModal()"
        >
          {{ "CLIENTS.send" | translate }}
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>

<ng-template #answerModal let-modal>
  <app-answer-modal
    [products]="productDetails"
    [item]="modalItem"
    [modal]="modal"
    (reloadTable)="reload()"
  >
  </app-answer-modal>
</ng-template>
