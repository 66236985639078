import { HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServicesService } from './services.service';


@Injectable({
	providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

	constructor(public toastr: ToastrService, private router: Router, private modalService: NgbModal,
		private spinner: NgxSpinnerService,
		private services: ServicesService,
	) { }

	intercept(req, next) {
		var jwtToken = '';
		var url = window.location.href
		var myurl = url.split('%')
		if (myurl.length > 1) {
			var token = myurl[0].split('?')
			var jwtToken = token[1];
		}
		else {
			var user = JSON.parse(localStorage.getItem('currentUser'));
			if (user) {
				jwtToken = user.token ? user.token : '';
			}
		}


		let tokenizedReq = req.clone({
			setHeaders: {
				Authorization: 'Bearer ' + jwtToken
			}
		})
		return next.handle(tokenizedReq).pipe(
			catchError((e: HttpErrorResponse) => {
				return this._throwError(e);
			})
		)
	}
	private _throwError(err: HttpErrorResponse) {
		console.log({ err });

		if (err.status) {

			if (err.status === 401) {
				this.toastr.warning("", `There is an active session`);
				// this.openModal(`PageLockScreenComponent`);
				this.spinner.hide();
				this.logout()
				localStorage.removeItem("DispatchUser");
				this.router.navigateByUrl("/login");
			}
			if (err.status === 400) {
				// this.openModal(`PageModalLoginComponent`);

				// this.toastr.error("error", `input your credential again`);
				// this.openModal();
				// this.logout()
				// localStorage.removeItem("DispatchUser");
				// this.router.navigateByUrl("/login");
			}
		}
		return throwError(err);
	}

	openModal(component: String) {
		this.modalService.open(component, { centered: true, size: 'md', backdrop: 'static' });

	}
	// openModal() {
	// 	// this.modalService.open(PageLockScreenComponent, { centered: true, size: 'sm', backdrop: 'static' });
	// 	this.modalService.open(PageModalLoginComponent, { centered: true, size: 'sm', backdrop: 'static' });
	//   }
	logout() {
		let localData = JSON.parse(localStorage.getItem("currentUser"));
		this.router.navigate(["login"]);
		localStorage.removeItem("currentUser");
		localStorage.removeItem("job_id");
		localStorage.removeItem("tab");
		localStorage.setItem("currentSubscriptionId", JSON.stringify({}));
		// localStorage.clear();
		if (localData && localData._id) {
			this.services
				.logout(localData._id)
				.pipe()
				.subscribe((data) => {
					console.log({ data })
					if (data.code == 200) {
						return;
					} else {
						this.toastr.error("error", data.message);
						return;
					}
				});
		}
	}
}
