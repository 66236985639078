import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    Router
} from '@angular/router';
import {
    ModalDirective
} from 'ngx-bootstrap/modal';
import {
    NgxSpinnerService
} from 'ngx-spinner';
import {
    ToastrService
} from 'ngx-toastr';
import {
    DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
    ServicesService
} from '../../services.service';

@Component({
    selector: 'app-subscription-payment',
    templateUrl: './subscription-payment.component.html',
    styleUrls: ['./subscription-payment.component.scss']
})
export class SubscriptionPaymentComponent implements OnInit {
    @ViewChild('congratulationModal') public congratulationModal: ModalDirective;
    updateCollectedAmmount: any;
    selectedlanguage = 'en';
    userData: any;
    selectedPlanId: any;
    selectedPlan: any;
    userCards = [];
    plan_id: any;
    currentSubscription: any;
    cvvCode: any;
    constructor(
        public toastr: ToastrService,
        private services: ServicesService,
        public router: Router,
        private spinner: NgxSpinnerService,
        public logoutFunction: DefaultLayoutComponent
    ) { }

    ngOnInit() {
        var language = JSON.parse(localStorage.getItem("language"));
        this.userData = JSON.parse(localStorage.getItem("currentUser"));

        this.selectedPlanId = JSON.parse(localStorage.getItem("selectedPlanId"));
        if (!this.selectedPlanId) {
            sessionStorage.setItem("profile_tab_index", "subscription");
            return this.router.navigate(['profile']);
        }

        if (language == 'fr') {
            this.selectedlanguage = 'fr';
        } else {
            this.selectedlanguage = 'en';
        }

        this.getSinglePlan();
        this.getUserAllCards();
    }

    //GET SINGLE PLAN DETAILS API START HERE
    getSinglePlan() {
        this.spinner.show();
        this.services.getSelectedPlan(this.selectedPlanId).pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            if (data.code == 200) {
                this.spinner.hide();
                this.selectedPlan = data.result;
                this.plan_id = this.selectedPlan.plans.id;
            } else {
                this.spinner.hide();
                return this.toastr.error('', 'No subscription plan found!');
            }
        });
    }

    //GET USER ALL CARDS FUNCTION START HERE
    getUserAllCards() {
        this.services.getUserCards(this.userData._id).pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            if (data.code == 200) {
                //console.log("data:", data);
                this.userCards = data.result;
                this.spinner.hide();
                return;

            } else {
                this.toastr.error('error', data.message);
                this.spinner.hide();
                return;
            }
        });
    }

    //GET SELECTED CARD DETAILS 
    selectedCardDetails(index) {
        this.selectedCardDetails = this.userCards[index];
        //console.log(this.selectedCardDetails, 'this data');
    }


    //THIS FUNCTION NOT IN USE
    createUpdateUserSubscription() {
        if (this.userCards.length == 0) {// navigate to add card section
            sessionStorage.setItem("profile_tab_index", "manage-card");
            this.router.navigate(['/profile']);
            return;
        }

        if (!this.plan_id) {
            return this.toastr.error('', 'Plan not selected');
        }

        this.spinner.show();
        this.currentSubscription = JSON.parse(localStorage.getItem('currentSubscriptionId'));

        if (this.currentSubscription && this.currentSubscription.subscription_id) { // update subscription
            console.log('enter in update subscription');
            this.services.updateSubscription(this.userData._id, this.currentSubscription.subscription_id, this.plan_id).pipe().subscribe(data => {
                if (data.code == 400) {
                    this.spinner.hide();
                    this.toastr.error('', data.message);
                    this.logoutFunction.logout();
                    return;
                }
                if (data.code == 200) {
                    this.ngOnInit();
                    this.spinner.hide();
                    this.congratulationModal.show();
                    this.toastr.success('', 'Subscription Plan Changed Successfully');
                    localStorage.setItem('currentSubscriptionId', JSON.stringify({}));
                    return;
                } else {
                    this.spinner.hide();
                    this.toastr.error('error', data.message);
                    return;
                }
            });
        } else { // new subscription
            this.services.createSubscription(this.userData._id, this.plan_id, this.userData.stripe_customerId).pipe().subscribe(data => {
                if (data.code == 400) {
                    this.spinner.hide();
                    this.toastr.error('', data.message);
                    this.logoutFunction.logout();
                    return;
                }
                if (data.code == 200) {
                    this.ngOnInit();
                    this.spinner.hide();
                    this.congratulationModal.show();
                    this.toastr.success('', 'Subscription Payment Successfully');
                    localStorage.setItem('currentSubscriptionId', JSON.stringify({}));
                    return;
                } else {
                    this.spinner.hide();
                    this.toastr.error('error', data.message);
                    return;
                }
            });
        }
    }
}