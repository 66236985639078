import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
  ModalDirective
} from 'ngx-bootstrap/modal';
import { CommonMethods } from '../../_helpers/_common-methods';
@Component({
  selector: 'app-stepper-status',
  templateUrl: './stepper-status.component.html',
  styleUrls: ['./stepper-status.component.scss']
})

export class StepperStatusComponent implements OnInit, OnChanges {
  @Input() job_details: any
  pendingObj: any;
  acceptedObj = {};
  ownershipObj = {};
  cancelledObj = {};
  returnObj = {};
  transferredObj = {};
  notDeliveredObj = {};
  completedObj = {};
  transferDriverName = "";
  notDeliveredNote = "";
  notDeliveredReason = "";
  @ViewChild('reasonModal') public reasonModal: ModalDirective;
  checkStatus = '1';
  viewNoteModal = '';
  @ViewChild('noteModal') public noteModal: ModalDirective;
  constructor(public commonMethod: CommonMethods) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    const { job_details } = changes

    if (job_details.currentValue) {
      this.pendingObj = {};
      this.acceptedObj = {};
      this.ownershipObj = {};
      this.cancelledObj = {};
      this.returnObj = {};
      this.transferredObj = {};
      this.notDeliveredObj = {};
      this.completedObj = {};
      this.transferDriverName = "";
      this.notDeliveredNote = "";
      this.notDeliveredReason = "";
      for (const step of this.job_details.stepper_status) {

        if (step.status_type == '1') { //pending
          this.pendingObj = step
        } else if (step.status_type == '2') { //accepted
          this.acceptedObj = step;
        } else if (step.status_type == '3') { //ownershiped
          this.ownershipObj = step;
        } else if (step.status_type == '4') { //cancelled by pharmacy
          this.cancelledObj = step;
        } else if (step.status_type == '6') { //cancel and return by driver
          this.returnObj = step;
        } else if (step.status_type == '7') { //transferred by driver
          this.transferredObj = step;
          var transferDriver = this.job_details.transferDriver_id[this.job_details.transferDriver_id.length - 1];
          this.transferDriverName = `${transferDriver.fullname}(${transferDriver.username})`
        } else if (step.status_type == '8') { //not delivered
          this.notDeliveredObj = step;
          this.notDeliveredNote = this.job_details.commentForNotDelivered;
          this.notDeliveredReason = this.job_details.reasonForNotDelivered;
        } else if (step.status_type == '9') { // completed
          this.completedObj = step;
        }
      }
    }
  }
  openNoteModal(note) {
    this.viewNoteModal = note;
    this.noteModal.show();
  }


}
