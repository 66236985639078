import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MessagingService } from './messaging.service';
import { PushNotificationService } from './push-notification.service';
import { PusherServiceService } from './pusher-service.service';
import { ServicesService } from './services.service';
import { SocketioService } from './socketio.service';
interface typeResponse {
  id: string;
  status: string;
  code: string;
}
@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: `<app-update-profile-modal></app-update-profile-modal><router-outlet></router-outlet>
 `
})


export class AppComponent implements OnInit {
  message;
  baseHref: string;
  data = {
    title: `session expired`,
    message: `return to log`
  }
  constructor(private router: Router,
    private socketService: SocketioService,
    private messagingService: MessagingService,
    private pusher: PusherServiceService,
    private services: ServicesService,
    public toastr: ToastrService,
    private modalService: NgbModal,
    private notifications: PushNotificationService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.baseHref = this.document.location.origin;
  }

  ngOnInit() {
    console.log(' FIREBASE APP_COMPONENT');
    this.eventsNew();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });



  }
  eventsNew() {
    this.notifications.requestPermission();
    const jobChannel = this.pusher.init('channel_62e811d36d84633158cd83de') as unknown as { bind?: any };
    console.log(jobChannel.bind);
    if (jobChannel.bind) {
      jobChannel.bind('new_order', (data: Required<typeResponse>) => {
        if (data.status === 'Order sent') {
          const url = `${this.baseHref}/#/prescriptions/${data.id}`;
          this.notifications.showCustomNotification({
            title: 'New prescription', body: `Order code: ${data.code}`, url,
          },
            () => {
              console.log('emit notificacion')
            });
        }


      });
    }

  }
  logout() {
    let localData = JSON.parse(localStorage.getItem("currentUser"));
    this.router.navigate(["login"]);
    localStorage.removeItem("currentUser");
    localStorage.removeItem("job_id");
    localStorage.removeItem("tab");
    localStorage.setItem("currentSubscriptionId", JSON.stringify({}));
    // localStorage.clear();
    if (localData && localData._id) {
      this.services
        .logout(localData._id)
        .pipe()
        .subscribe((data) => {
          if (data.code == 200) {
            return;
          } else {
            this.toastr.error("error", data.message);
            return;
          }
        });
    }
  }

}
