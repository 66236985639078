import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';
import { ModalComponent } from "../../shared/component/modal/modal.component";
@Component({
  selector: 'app-dispatch',
  templateUrl: './dispatch.component.html',
  styleUrls: ['./dispatch.component.scss']
})
export class DispatchComponent implements OnInit {
  @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
  @ViewChild('deleteDispatchTemplate') deleteDispatchTemplate: TemplateRef<any>;

  user_id: any;
  pageNumber = 1;
  dispatches = [];
  dispatchCount = 0;
  searchEvent = "";
  timerForSearch = null;
  removeDispatchId: any;
  bsModalRef: BsModalRef;
  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.pageNumber = 1;
    let localData = JSON.parse(localStorage.getItem("currentUser"));
    this.user_id = localData._id;
    this.dispatchCount = 0;
    this.dispatches = [];
    this.searchEvent = '';
    this.onPageLoad();
  }
  onPageLoad() {

    this.spinner.show();
    this.services.getConnectedDispatch(this.user_id, this.pageNumber, this.searchEvent).pipe().subscribe(data => {
      console.log("getConnectedDispatch:", data)
      if (data.code == 400) {
        this.spinner.hide();
        this.logoutFunction.logout();
        return;

      }
      if (data.code == 200) {
        this.dispatches = data.result;
        this.dispatchCount = data.count;
        this.spinner.hide();
        return;

      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;

      }
    })

  }

  onPageChange(pageNumber) {
    console.log("dispatch", pageNumber)
    this.pageNumber = pageNumber;
    this.onPageLoad();

  }

  searchDispatch(event) {
    this.pageNumber = 1;
    this.searchEvent = event;
    this.onPageLoad();

  }
  openRemoveDispatchModal(dispatchId) {
    this.removeDispatchId = dispatchId;
    const initialState = {
      content: this.deleteDispatchTemplate,
      config: {
        title: "Confirmation",
        action: "JOBS.yes",
        cancel: "JOBS.no"
      },
      onClick: this.removeDispatch.bind(this),

    };
    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-sm modal-dialog-centered",
    });

  }

  removeDispatch() {
    if (this.removeDispatchId) {
      this.spinner.show();
      this.services.removeDispatch(this.user_id, this.removeDispatchId).pipe().subscribe(data => {
        if (data.code == 200) {
          this.onPageLoad();
          this.bsModalRef.hide();
          this.spinner.hide();
          this.toastr.success('Success', '');
          return;


        } else {
          this.spinner.hide();
          this.toastr.error('', data.message);
          return;

        }
      })

    }


  }

}