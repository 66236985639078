import { Component, Input, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.css"],
})
export class ProgressBarComponent implements OnInit {
  @Input() data: any;
  @Input() class: string = "";
  total: number = 0;
  completed: number = 0;
  behind: number = 0;
  constructor() { }

  ngOnInit(): void { }
  ngOnChanges(changes: SimpleChanges) {
    const { data } = changes;

    if (data.currentValue) {
      this.calculateProgress();
    }
  }

  calculateProgress() {
    this.total = this.data?.length;

    this.completed = this.data?.filter(
      ({ job_status }) => job_status === "C"
    ).length;

    this.behind = this.data?.filter(({ behind }) => behind === true).length;
  }
}
