<ng-template #dynamicContent>
  <div class="flex-grow-1">
    <form>
      <input
        type="text"
        name="search"
        placeholder="{{ 'JOBS.search_customer' | translate }}"
        class="animated-search"
        (ngModelChange)="searchJobs($event)"
        [(ngModel)]="searchEvent"
        [ngModelOptions]="{ standalone: true }"
      />
    </form>
  </div>
</ng-template>
<div class="mt-3">
  <app-table
    [theadTemplate]="theadTemplate"
    [tbodyTemplate]="tbodyTemplate"
    [noFoundTemplate]="noFoundTemplate"
    [totalItems]="allJobsCount"
    (onPageChange)="onPageChange($event)"
    [currentPage]="pageNumber"
  ></app-table>
  <ng-template #theadTemplate>
    <tr>
      <th>{{ "JOBS.job_id" | translate }}</th>
      <th>{{ "JOBS.client_name" | translate }}</th>
      <th>{{ "JOBS.address" | translate }}</th>
      <th>{{ "JOBS.initial" | translate }}</th>
      <th>{{ "JOBS.due_date" | translate }}</th>
      <th>{{ "JOBS.status" | translate }}</th>
      <th>{{ "JOBS.amount" | translate }}</th>
      <th>{{ "JOBS.collected_amount" | translate }}</th>
      <th>{{ "JOBS.driver_name" | translate }}</th>
    </tr>
  </ng-template>

  <ng-template #tbodyTemplate>
    <tbody>
      <tr
        *ngFor="
          let completedJob of allJobs
            | paginate
              : {
                  itemsPerPage: 10,
                  currentPage: pageNumber,
                  totalItems: allJobsCount
                }
        "
      >
        <td>{{ completedJob.booking_code }}</td>
        <td *ngIf="completedJob.customer_type == '2'">
          <span style="font-weight: 800; text-transform: uppercase">{{
            completedJob.customer_l_name
          }}</span>
          {{ completedJob.customer_f_name }}
        </td>
        <td *ngIf="completedJob.customer_type != '2'">
          <span>{{
            completedJob.establishment_name
              ? completedJob.establishment_name
              : completedJob.customer_name
          }}</span>
        </td>
        <td *ngIf="completedJob.job_type == '1'" class="my-location">
          <p class="location-customer">
            {{ commonMethods.sortAddress(completedJob.drop_off_location) }}
          </p>
        </td>

        <td *ngIf="completedJob.job_type == '2'" class="my-location">
          <p class="location-customer">
            {{ commonMethods.sortAddress(completedJob.pick_up_location) }}
          </p>
        </td>

        <td *ngIf="completedJob.job_create_initials; else initialsElse">
          {{ completedJob.job_create_initials }}
        </td>
        <ng-template #initialsElse>
          <td>N/A</td>
        </ng-template>
        <td>{{ commonMethods.modifyDate(completedJob.job_date) }}</td>
        <td>
          <button
            *ngIf="completedJob.job_status == 'N'"
            class="stats-notcomplete"
          >
            N/D
          </button>
          <button *ngIf="completedJob.job_status == 'A'" class="stats-active">
            {{ "JOBS.active" | translate }}
          </button>
          <button *ngIf="completedJob.job_status == 'C'" class="stats-complete">
            {{ "JOBS.completed" | translate }}
          </button>
          <button
            *ngIf="
              completedJob.isExpired == true && completedJob.job_status != 'C'
            "
            class="stats-complete"
            style="background: #efe2e2; color: #ff010d"
          >
            {{ "JOBS.expired" | translate }}
          </button>
          <button
            *ngIf="
              completedJob.job_status == 'CN' && completedJob.isExpired == false
            "
            class="stats-complete"
            style="background: #efe2e2; color: #ff010d"
          >
            {{ "JOBS.cancelled" | translate }}
          </button>
          <div *ngIf="completedJob.isRecursiveJob">
            <button class="stats-recursive text-danger">
              <i class="fa fa-circle mr-2" aria-hidden="true"></i
              >{{ "JOBS.recursive" | translate }}
            </button>
          </div>
          <button
            *ngIf="
              completedJob.job_status == 'P' &&
              completedJob.isAccepted == false &&
              completedJob.isExpired == false
            "
            class="stats-active"
            style="background: #ffe4c8; color: #ff8b17"
          >
            {{ "JOBS.pending" | translate }}
          </button>
          <button
            *ngIf="
              completedJob.job_status == 'P' &&
              completedJob.isAccepted == true &&
              completedJob.isExpired == false
            "
            class="stats-active"
          >
            {{ "JOBS.accepted" | translate }}
          </button>
        </td>
        <td>
          <span class="amount"
            >${{ completedJob.job_amount | number : "1.2-2" }}</span
          >
          <p
            *ngIf="
              completedJob.job_status == 'C' &&
              completedJob.customer_payment_id != null &&
              completedJob.customer_payment_id.payment_method == '2'
            "
            class="cash"
          >
            {{ "JOBS.cash" | translate }}
          </p>
          <p
            *ngIf="
              completedJob.job_status == 'C' &&
              completedJob.customer_payment_id != null &&
              completedJob.customer_payment_id.payment_method == '1'
            "
            class="cash"
          >
            {{ "JOBS.card" | translate }}
          </p>
          <p
            *ngIf="
              completedJob.job_status == 'C' &&
              completedJob.customer_payment_id != null &&
              completedJob.customer_payment_id.payment_method == '3'
            "
            class="cash"
          >
            {{ "JOBS.cheque" | translate }}
          </p>
        </td>
        <td>
          <!-- card amount -->
          <ul class="table-action">
            <li
              *ngIf="
                completedJob.job_status == 'C' &&
                completedJob.customer_payment_id &&
                completedJob.customer_payment_id.payment_method == '1'
              "
            >
              <span class="amount">N/A</span>
            </li>
          </ul>

          <!-- cash amount -->
          <ul
            class="table-action"
            *ngIf="
              completedJob.job_status == 'C' &&
              completedJob.collected_amount_pharmacy !=
                completedJob.job_amount &&
              completedJob.customer_payment_id != null &&
              completedJob.customer_payment_id.payment_method == '2' &&
              completedJob.customer_payment_id.paid == false
            "
          >
            <li>
              <span class="amount"
                >${{
                  completedJob.collected_amount_pharmacy | number : "1.2-2"
                }}</span
              >
            </li>
          </ul>

          <ul
            class="table-action"
            *ngIf="
              (completedJob.collected_amount_pharmacy ==
                completedJob.job_amount &&
                completedJob.job_amount != 0) ||
              (completedJob.customer_payment_id &&
                (completedJob.customer_payment_id.payment_method == '2' ||
                  completedJob.customer_payment_id.payment_method == '4') &&
                completedJob.customer_payment_id.paid == true)
            "
          >
            <li>
              <span class="amount"
                >${{
                  completedJob.collected_amount_pharmacy | number : "1.2-2"
                }}</span
              >
            </li>
            <li *ngIf="completedJob.job_status == 'C'">
              <i
                class="fa fa-check"
                aria-hidden="true"
                style="cursor: pointer; color: green"
              ></i>
            </li>
          </ul>

          <!-- cheque amount -->
          <ul
            class="table-action"
            *ngIf="
              completedJob.job_status == 'C' &&
              completedJob.customer_payment_id &&
              completedJob.customer_payment_id.payment_method == '3' &&
              completedJob.cheque_collected_from_driver == false
            "
          >
            <li><span class="amount">N/C</span></li>
          </ul>

          <ul
            class="table-action"
            *ngIf="
              completedJob.job_status == 'C' &&
              completedJob.customer_payment_id &&
              completedJob.customer_payment_id.payment_method == '3' &&
              completedJob.cheque_collected_from_driver == true
            "
          >
            <li>
              <span class="amount" style="color: #000000">{{
                "JOBS.collected" | translate
              }}</span>
            </li>
          </ul>

          <!-- cheque amount -->
          <ul
            class="table-action"
            *ngIf="
              completedJob.job_status != 'C' ||
              !completedJob.customer_payment_id
            "
          >
            <li><span class="amount">N/A</span></li>
          </ul>
        </td>
        <td *ngIf="completedJob.driver_id; else driverElse">
          {{ completedJob.driver_id.username }}
        </td>
        <ng-template #driverElse>
          <td>N/A</td>
        </ng-template>
      </tr>
    </tbody>
  </ng-template>

  <ng-template #noFoundTemplate>
    <div class="no-jobs-found">
      <p class="no-item text-center text-danger mb-0" *ngIf="!allJobs.length">
        {{ "JOBS.no_jobs_found" | translate }}
      </p>
    </div>
  </ng-template>
</div>
