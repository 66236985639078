import {
  Component,
  OnInit
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  NgxSpinnerService
} from 'ngx-spinner';
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  selectedPlan: any;
  selectedlanguage = 'en';
  userData: any;
  currentSubscription: any;
  unixEndDate: any;
  expireDate: string;
  unixStartDate: any;
  startDate: string;
  subscriptionAmount = 0;
  subscriptionPlanName: any;
  subscriptionPlanStatus: any;

  constructor(
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    private spinner: NgxSpinnerService,
    public logoutFunction: DefaultLayoutComponent
  ) { }

  ngOnInit() {
    this.spinner.show();
    var language = JSON.parse(localStorage.getItem("language"));
    this.userData = JSON.parse(localStorage.getItem("currentUser"));
    if (language == 'fr') {
      this.selectedlanguage = 'fr';
    } else {
      this.selectedlanguage = 'en';
    }
    this.getUserCurrentSubscription();
  }

  //FUNCTION TO GET USER CURRENT SUBSCRIP[TION]
  getUserCurrentSubscription() {

    this.services.getCurrentSubscription(this.userData._id).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        console.log(data, 'this data111111');
        this.currentSubscription = data.result;
        this.subscriptionAmount = this.currentSubscription.subscription.plan.amount / 100;
        this.subscriptionPlanName = data.currentPlan;
        this.subscriptionPlanStatus = this.currentSubscription.subscription.status;

        // end Date
        var endTime = this.currentSubscription.subscription.current_period_end;
        this.unixEndDate = this.currentSubscription.subscription.current_period_end;
        var unixDateTolocal = new Date(endTime * 1000);
        var Formatted_date = unixDateTolocal.getDate() + "-" + (unixDateTolocal.getMonth() + 1) + "-" + unixDateTolocal.getFullYear();
        var enddate = Formatted_date.split('-');
        var getTime = unixDateTolocal.getHours() + ":" + unixDateTolocal.getMinutes();
        this.expireDate = enddate[1] + '-' + enddate[0] + '-' + enddate[2] + ' ' + getTime;
        this.spinner.hide();
        return
      } else {
        this.spinner.hide();
        //this.router.navigate(['subscription-plans']);
      }
    });
  }

  navigateToSubscriptionPlans() {
    this.router.navigate(['subscription-plans']);
  }

  //UPDATE SUBSCRIPTION PLAN
  updateSubscriptionPlan() {
    const currentPlanData = {
      subscription_id: this.currentSubscription._id,
      planId: this.currentSubscription.subscription.plan.id
    }
    //currentSubscriptionId
    localStorage.setItem('currentSubscriptionId', JSON.stringify(currentPlanData));
    this.router.navigate(['subscription-plans']);

  }
}