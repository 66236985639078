import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    Router
} from '@angular/router';
import {
    ModalDirective
} from 'ngx-bootstrap/modal';
import {
    NgxSpinnerService
} from "ngx-spinner";
import {
    ToastrService
} from 'ngx-toastr';
import {
    DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
    ServicesService
} from '../../services.service';
import { JobsListComponent } from '../jobs-list/jobs-list.component';
const FileSaver = require('file-saver');
@Component({
    selector: 'app-new-job-requests',
    templateUrl: './new-job-requests.component.html',
    styleUrls: ['./new-job-requests.component.scss']
})
export class NewJobRequestsComponent implements OnInit, AfterViewInit {
    @ViewChild('successModal1') successModal1: ModalDirective;
    @ViewChild('approveOwnershipModal') public approveOwnershipModal: ModalDirective;
    @ViewChild('sendToDriverNotificationModal') public sendToDriverNotificationModal: ModalDirective;
    @ViewChild('qrCodeConfirmModal') public qrCodeConfirmModal: ModalDirective;
    @ViewChild(JobsListComponent) jobsListComponent: JobsListComponent;
    dynamicContentTemplate: TemplateRef<any>;
    new_jobs = [];
    pageNumber = 1;
    newJobsCount = 0;
    newRequestPageType: string;
    searchEvent = '';
    Date: any;
    searchDate = '';
    cancel_job_id: any;

    user_id: any;
    cancel_recursive_jobs = false;
    is_cancel_job = false;
    isRecursiveJob = false;
    approveJobId: any;
    notificationTitle = '';
    notificationText = '';
    driver_id_notification = '';
    job_id_notification = '';
    qrCodeJobsIds = [];

    constructor(
        public logoutFunction: DefaultLayoutComponent,
        public toastr: ToastrService,
        private services: ServicesService,
        public router: Router,
        private spinner: NgxSpinnerService
    ) { }

    ngAfterViewInit() {
        this.dynamicContentTemplate = this.jobsListComponent.dynamicContentTemplate;

    }
    ngOnInit() {
        this.approveJobId = "";
        this.pageNumber = 1;
        this.searchEvent = '';
        this.qrCodeJobsIds = [];

        //GET USER LOCAL DATA
        var localData = JSON.parse(localStorage.getItem("currentUser"));
        if (!localData) {
            this.spinner.hide();
            this.logoutFunction.logout();
            return;
        }
        this.user_id = localData._id;

    }


    //CANCEL JOB SECTION START HERE
    cancelJobModal(job_id) {
        this.is_cancel_job = false;
        this.cancel_recursive_jobs = false;
        console.log({ job_id })
        if (!this.jobsListComponent?.isSelectedCheck) {
            this.cancel_job_id = job_id._id;
            this.isRecursiveJob = job_id.isRecursiveJob;
            if (!this.isRecursiveJob) {
                this.is_cancel_job = true;
            }
            this.successModal1.show();
        } else {
            this.successModal1.show();

        }


    }
    cancelJobModalMasive() {
        const jobs = this.jobsListComponent?.arrayCheckGroup?.getRawValue().filter(job => job.check);
        this.spinner.show();
        for (const job of jobs) {
            this.services.sendNotification({ job_id: job.job_id }).toPromise().then(async (data) => {
                if (data.code == 400) {
                    this.spinner.hide();
                    this.toastr.error('', data?.message);
                    this.logoutFunction.logout();
                    return;
                }

                if (data.code == 200) {
                    this.toastr.success('Notification sent: ', data?.job?.booking_code);
                    const index = this.jobsListComponent?.arrayCheckGroup?.getRawValue().findIndex(item => item?.job_id === job.job_id);
                    this.jobsListComponent?.arrayCheckGroup?.controls[index].get('sent').setValue(true);
                    this.jobsListComponent?.arrayCheckGroup?.controls[index].get('check').setValue(false);
                } else {
                    this.toastr.error('error', data?.message);
                }
            });
        }
        this.spinner.hide();
    }

    //CANCEL JOB FUNCTION START HERE
    async cancelJob() {
        if (this.is_cancel_job == false && this.cancel_recursive_jobs == false && !this.jobsListComponent?.isSelectedCheck) {
            this.toastr.error('', "Please select option");
            return;
        }
        if (this.jobsListComponent?.isSelectedCheck) {

            const array = this.jobsListComponent?.arrayCheckGroup?.getRawValue().filter(job => job.check);
            const jobs = array.map(x => x.job);
            this.spinner.show();
            // console.log({ jobs });
            for (const job of jobs) {
                this.user_id
                this.cancel_job_id = job._id;
                this.is_cancel_job

                this.isRecursiveJob = job.isRecursiveJob;
                if (!this.isRecursiveJob) {
                    this.is_cancel_job = true;
                }
                // console.log(`MultiJOB`)
                // console.log({ job });

                // console.log(this.user_id, this.cancel_job_id, this.is_cancel_job, this.isRecursiveJob)
                await this.cancelJobMasivePharmacy(this.user_id, job, this.is_cancel_job, this.cancel_recursive_jobs);


            }
            this.jobsListComponent.searchWithDate('0');
            this.spinner.hide();
            return;
        } else {
            // console.log(`ONEJOB`)
            // console.log(this.user_id, this.cancel_job_id, this.is_cancel_job, this.isRecursiveJob)
            this.cancelJobPharmacy(this.user_id, this.cancel_job_id, this.is_cancel_job, this.cancel_recursive_jobs);
        }



    }
    async cancelJobMasivePharmacy(user, job, cancel, recursive) {
        return new Promise((resolve, reject) => {
            this.services.cancel_job_pharmacy(user, job._id, cancel, recursive).pipe().subscribe(data => {
                if (data.code == 400) {
                    this.successModal1.hide();
                    this.toastr.error('', data.message);
                    this.logoutFunction.logout();
                    return resolve({});

                }
                if (data.code == 200) {
                    this.successModal1.hide();
                    // this.ngOnInit();
                    // this.searchWithDate('0');
                    this.toastr.success('Job Cancelled Successfully');
                    const index = this.jobsListComponent?.arrayCheckGroup?.getRawValue().findIndex(item => item?.job_id === job._id);
                    this.jobsListComponent?.arrayCheckGroup?.controls[index].get('sent').setValue(true);
                    this.jobsListComponent?.arrayCheckGroup?.controls[index].get('check').setValue(false);
                    return resolve({});

                } else {
                    this.successModal1.hide();
                    this.toastr.error('error', data.message);
                    return resolve({});

                }

            });

        })

    }
    cancelJobPharmacy(user, jobId, cancel, recursive) {
        this.spinner.show();
        this.services.cancel_job_pharmacy(user, jobId, cancel, recursive).pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.successModal1.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            if (data.code == 200) {
                this.successModal1.hide();
                // this.ngOnInit();
                this.jobsListComponent.searchWithDate('0');
                this.toastr.success('Job Cancelled Successfully');
                this.spinner.hide();
                return;
            } else {
                this.successModal1.hide();
                this.spinner.hide();
                this.toastr.error('error', data.message);
                return;
            }

        });
    }


    openApproveOwnershipModal(job_id) {
        this.approveJobId = job_id;
        this.approveOwnershipModal.show();
    }

    approveJobPharmacy() {
        if (this.approveJobId) {
            this.spinner.show();
            this.services.approveJobOwnership(this.user_id, this.approveJobId).pipe().subscribe(data => {
                if (data.code == 400) {
                    this.spinner.hide();
                    this.approveOwnershipModal.hide();
                    this.toastr.error('', data.message);
                    this.logoutFunction.logout();
                    return;
                }
                if (data.code == 200) {
                    // this.ngOnInit();
                    this.jobsListComponent.searchWithDate('0');
                    this.approveOwnershipModal.hide();
                    this.toastr.success("sucess", "Ownership approved");
                    this.spinner.hide();
                    return;

                } else {
                    this.spinner.hide();
                    this.toastr.error('error', data.message);
                    return;
                }

            })

        }
    }


    selectJobForQrCode(jobId) {
        let findJobId = this.qrCodeJobsIds.find(id => id.toString() === jobId.toString());
        if (findJobId) {
            this.qrCodeJobsIds = this.qrCodeJobsIds.filter(ids => ids != jobId);

        } else {
            this.qrCodeJobsIds.push(jobId);
        }
    }
    generateQrCode() {
        if (this.qrCodeJobsIds.length) {
            this.spinner.show();
            this.services.generateMultipleJobsQrCode(this.user_id, this.qrCodeJobsIds).pipe().subscribe(data => {
                if (data.code == 200) {
                    this.qrCodeJobsIds = [];
                    this.qrCodeConfirmModal.hide();
                    this.jobsListComponent.getJobs();
                    FileSaver.saveAs(data.result, 'multipleJobsQrCode.jpg');
                    this.spinner.hide();
                    return;

                } else {
                    this.spinner.hide();
                    this.toastr.error('error', data.message);
                    return;

                }
            })

        }
    }

    async sendMassiveNotifications() {
        const jobs = this.jobsListComponent?.arrayCheckGroup?.getRawValue().filter(job => job.check);
        this.spinner.show();
        for (const job of jobs) {
            await this.services.sendNotification({ job_id: job.job_id }).toPromise().then(async (data) => {
                if (data.code == 400) {
                    this.spinner.hide();
                    this.toastr.error('', data?.message);
                    this.logoutFunction.logout();
                    return;
                }

                if (data.code == 200) {
                    this.toastr.success('Notification sent: ', data?.job?.booking_code);
                    const index = this.jobsListComponent?.arrayCheckGroup?.getRawValue().findIndex(item => item?.job_id === job.job_id);
                    this.jobsListComponent?.arrayCheckGroup?.controls[index].get('sent').setValue(true);
                    this.jobsListComponent?.arrayCheckGroup?.controls[index].get('check').setValue(false);
                } else {
                    this.toastr.error('error', data?.message);
                }
            });
        }
        this.spinner.hide();
    }

}