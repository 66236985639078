<link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
<div class="card mt-3">
  <div class="card-body">
    <div class="row">
        <div class="col-lg-6">
          <div class="scp-breadcrumb">
            <ul class="breadcrumb">
              <li class="back-btn-arrow" style="cursor: pointer;"><a (click)="back()" class="back-arrow"><i 
                class="fa fa-arrow-left mr-2" aria-hidden="true" style="color:#3EAA79; margin-top: 0px;"></i>Back</a>
              </li>
                <li class="b-jobs"><a>{{'BRANDS.update' | translate }}</a></li>
            </ul>
        </div>
        </div>
          
    </div>


    <div class="row profileedit-form" [formGroup]="editBrandForm">
        <div class="col-md-2"></div>
        <div class="col-md-8">

            <div class="row">
                <div class="col-md-3"><label>{{'BRANDS.brand_name' | translate }}</label></div>
                <div class="col-md-6">
                 <div class="form-group">
                   <input type="text" formControlName="name" [(ngModel)]="name" class="form-control" placeholder="{{ 'BRANDS.brand_name' | translate }}">
                   
                 </div>
                </div>
            </div>

            <div class="row">
              <div class="col-md-3"><label>{{'BRANDS.brand_nameFr' | translate }}</label></div>
              <div class="col-md-6">
               <div class="form-group">
                 <input type="text" formControlName="nameFr" [(ngModel)]="nameFr" class="form-control" placeholder="{{ 'BRANDS.brand_name' | translate }}">
                 
               </div>
              </div>
          </div>
          
          <div class="row">
            <div class="col-md-3"><label>{{'BRANDS.files' | translate }}</label></div>
            <div class="col-md-6">
              <div class="form-group">
                <div class="fileUpload blue-btn btn width100">
                  <span>{{'IMPORTJOBS.select_file' | translate }}</span>
                  <input type="file" class="uploadlogo" formControlName="file"  accept="image/png,image/jpeg" name="myfile" (change)="onFileSelect($event)">
                </div>
            </div>
            </div>
          </div>
          
            <div class="row">
                <ul class="submit-cncl-btns">
                <li><button class="btn btn-default btn1"(click)="back()">{{ 'CREATEJOB.cancel' | translate }}</button></li>
                 <li><button class="btn btn-default btn2"  (click)="brandUpdate()">{{ 'BRANDS.brand_update' | translate }}</button></li>
                
                </ul>
            </div>
            
        </div>
    </div>
    
    
  </div>

</div>