<div *ngIf="config && config.title" class="modal-header">
  <div class="modal-title">
    {{ config.title }}
  </div>
</div>
<div *ngIf="header" class="modal-header">
  <ng-container *ngTemplateOutlet="header"></ng-container>
  <app-button
    [imageSrc]="'./assets/img/new-ui/close.svg'"
    [buttonText]="'CLIENTS.close' | translate"
    (buttonClick)="onClose()"
  ></app-button>
</div>
<div class="modal-body">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>
<div *ngIf="config" class="modal-footer d-flex justify-content-center">
  <app-button
    [imageSrc]="cancelImageSrc"
    [buttonText]="config.cancel"
    (buttonClick)="onClose()"
  ></app-button>
  <app-button
    *ngIf="config?.action && !hideActionBtn"
    [imageSrc]="'./assets/img/new-ui/save.svg'"
    [buttonText]="config.action"
    (buttonClick)="onClick()"
  ></app-button>
</div>
