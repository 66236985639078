import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Router
} from '@angular/router';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';
import {
  MustMatch
} from '../_helpers/must_match_validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
  chanePassForm: UntypedFormGroup;
  submitted = false;
  user_id: any;
  showNewPassword = false;
  showConfirmPassword = false;
  showOldPassword = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    private spinner: NgxSpinnerService,
    public logoutFunction: DefaultLayoutComponent) { }

  ngOnInit() {
    this.submitted = false;

    var localData = JSON.parse(localStorage.getItem("currentUser"));
    this.user_id = localData._id;

    //USER UPDATE PASSWORD FORM
    this.chanePassForm = this.formBuilder.group({
      oldPass: ['', [Validators.required, Validators.minLength(6)]],
      newPass: ['', [Validators.required, Validators.minLength(6)]],
      confirmPass: ['', Validators.required],
    }, {
      validator: MustMatch('newPass', 'confirmPass')
    });
  }

  reset() {
    this.chanePassForm.reset();
    this.submitted = false;
  }
  get f() {
    return this.chanePassForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.chanePassForm.invalid) {
      return;
    }

    this.spinner.show();
    this.services.changePassword(this.user_id, this.f.oldPass.value.trim(), this.f.newPass.value.trim()).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.spinner.hide();
        this.toastr.success('success', 'Password changed successfully');
        this.chanePassForm.reset();
        this.ngOnInit();
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });
  }
}