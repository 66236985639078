<div>
  <div class="d-flex flex-row justify-content-between mt-1">
    <div class="dp-hidden position-absolute">
      <div class="input-group">
        <input
          name="datepicker"
          class="form-control w-100"
          ngbDatepicker
          #datepicker="ngbDatepicker"
          (dateSelect)="onDateSelection($event)"
          [autoClose]="'outside'"
          [displayMonths]="2"
          [dayTemplate]="t"
          outsideDays="hidden"
          [startDate]="fromDate!"
          tabindex="-1"
        />
        <ng-template #t let-date let-focused="focused">
          <span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
          >
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>
    <div class="input-group">
      <input
        #dpFromDate
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="dpFromDate"
        [value]="formatter.format(fromDate)"
        (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
        (click)="datepicker.toggle()"
      />
    </div>

    <span class="m-2"> - </span>

    <div class="input-group">
      <input
        #dpToDate
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="dpToDate"
        [value]="formatter.format(toDate)"
        (input)="toDate = validateInput(toDate, dpToDate.value)"
        (click)="datepicker.toggle()"
      />
    </div>
  </div>
</div>
