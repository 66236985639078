import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from "ngx-bootstrap/tabs";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgxPaginationModule } from 'ngx-pagination';
import { DropdownModule } from 'primeng/dropdown';
import { IntOnlyDirective } from '../directives/int-only-directive';
import { JobDetailsComponent } from '../views/job-details/job-details.component';
import { StepperStatusComponent } from '../views/job-details/stepper-status/stepper-status.component';
import { ButtonComponent } from './component/button/button.component';
import { ColorLegendComponent } from './component/color-legend/color-legend.component';
import { ComentMessageComponent } from './component/coment-message/coment-message.component';
import { CommetComponent } from './component/commet/commet.component';
import { CustomCalendarDatepickersComponent } from './component/custom-calendar-datepickers/custom-calendar-datepickers.component';
import { DatepickerRangeComponent } from './component/datepicker-range/datepicker-range.component';
import { GoogleMapComponent } from './component/google-map/google-map.component';
import { InfoWindowContentComponent } from "./component/info-window-content/info-window-content.component";
import { JobLinkComponent } from './component/job-link/job-link.component';
import { LocationAgmComponent } from './component/location-agm/location-agm.component';
import { LocationPolingComponent } from './component/location-poling/location-poling.component';
import { ModalComponent } from "./component/modal/modal.component";
import { ProgressBarComponent } from './component/progress-bar/progress-bar.component';
import { SingleJobInfoWindowContentComponent } from "./component/single-job-info-window-content/single-job-info-window-content.component";
import { TableComponent } from './component/table/table.component';
import { TimePickerComponent } from './component/time-picker/time-picker.component';
import { ToggleIconComponent } from './component/toggle-icon/toggle-icon.component';
import { ComentPageComponent } from './pages/coment-page/coment-page.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}
@NgModule({
    declarations: [
        ComentPageComponent,
        ComentMessageComponent,
        CommetComponent,
        LocationAgmComponent,
        DatepickerRangeComponent,
        LocationPolingComponent,
        ModalComponent,
        TableComponent,
        ButtonComponent,
        IntOnlyDirective,
        ToggleIconComponent,
        CustomCalendarDatepickersComponent,
        TimePickerComponent,
        InfoWindowContentComponent,
        SingleJobInfoWindowContentComponent,
        GoogleMapComponent,
        JobLinkComponent,
        ProgressBarComponent,
        JobDetailsComponent,
        StepperStatusComponent,
        ColorLegendComponent
    ],
    imports: [

        CommonModule,
        ModalModule,
        NgbDropdownModule,
        NgbModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        GooglePlaceModule,
        DropdownModule,
        FormsModule,
        NgxPaginationModule,
        PopoverModule.forRoot(),
        ReactiveFormsModule,
        TabsModule.forRoot(),
        GoogleMapsModule,
        CollapseModule.forRoot()
    ],
    exports: [
        ComentPageComponent,
        ComentMessageComponent,
        CommetComponent,
        NgbDropdownModule,
        LocationAgmComponent,
        LocationPolingComponent,
        DatepickerRangeComponent,
        ModalComponent,
        TableComponent,
        ButtonComponent,
        ToggleIconComponent,
        CustomCalendarDatepickersComponent,
        TimePickerComponent,
        GoogleMapComponent,
        JobDetailsComponent,
        StepperStatusComponent,
        ColorLegendComponent
    ]
    ,
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }