import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Marker } from '../../../services/interfaces/googleMaps.interface';
import { LocationService } from '../../../services/location.service';
interface markerItem {
  lat: number;
  lng: number;
  address: string;
  aliasEn?: string;
  aliasFr?: string;
}

@Component({
  selector: 'app-location-agm',
  templateUrl: './location-agm.component.html',
  styleUrls: ['./location-agm.component.scss']
})
export class LocationAgmComponent implements OnInit {

  @Output() getLocationData = new EventEmitter<any>();
  @Input() item: markerItem;
  @Input() activeAlias: boolean = false;
  @Input() defaultOtherAddress: boolean = false;
  @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
  geocoder = new google.maps.Geocoder();
  public options: any;
  locationData: any = null;
  address: string;
  latitudeMap = null;
  longitudeMap = null;
  zoom = 17;
  latitudeMarker = null;
  longitudeMarker = null;
  alias: { aliasEn: string, aliasFr: string };
  otherAliasEn: string = '';
  otherAliasFr: string = '';
  aliasList: { aliasEn: string, aliasFr: string }[] = [
    { aliasEn: 'Home', aliasFr: 'Maison' },
    { aliasEn: 'Office', aliasFr: 'Bureau' },
    { aliasEn: 'Relatives', aliasFr: 'Parents' },
    { aliasEn: 'Other', aliasFr: 'Autre' },
  ];
  lang: string = 'en';
  center: google.maps.LatLngLiteral;
  markersInfo: Marker[] = [];
  constructor(
    private toastr: ToastrService,
    private locationService: LocationService,
  ) { }

  ngOnInit(): void {
    this.lang = JSON.parse(localStorage.getItem('language'));
    if (this.item?.lat && this.item?.lng && this.item?.address) {
      this.latitudeMap = this.item.lat;
      this.longitudeMap = this.item.lng;
      this.center = {
        lat: this.latitudeMap,
        lng: this.longitudeMap
      }
      this.setValueInitAddress(this.item?.address);
      //this.setValueNewAddress({ coords: { lat: this.item.lat, lng: this.item.lng } });

      if (this.isOtherAlias(this.item.aliasEn, this.item.aliasFr)) {
        this.otherAliasEn = this.item.aliasEn;
        this.otherAliasFr = this.item.aliasFr;
        this.alias = { aliasEn: 'Other', aliasFr: 'Autre' };
      } else {
        this.alias = { aliasEn: this.item.aliasEn, aliasFr: this.item.aliasFr };
      }

    } else {
      const currentPharmacy = JSON.parse(localStorage.getItem('currentUser'));
      const lat = currentPharmacy?.lat_long[0];
      const lng = currentPharmacy?.lat_long[1];

      this.latitudeMarker = lat ? lat : 45.4389789;
      this.longitudeMarker = lng ? lng : -73.2983331;
      const marker = new google.maps.Marker({ position: { lat: this.latitudeMarker, lng: this.longitudeMarker } });
      this.markersInfo.push({ marker: marker });
      this.latitudeMap = lat ? lat : 45.4389789;
      this.longitudeMap = lng ? lng : -73.2983331;
      this.center = {
        lat: this.latitudeMap,
        lng: this.longitudeMap
      }
    }

    if (this.defaultOtherAddress) {
      this.alias = { aliasEn: 'Other', aliasFr: 'Autre' };
    }

  }

  isOtherAlias(aliasEn: string, aliasFr: string) {
    return this.aliasList.some(item => item.aliasEn === aliasEn && item.aliasFr === aliasFr) ? false : true;
  }

  public handleAddressChange(address: any) {

    this.locationData = address;
    this.address = address.formatted_address;
    this.latitudeMarker = address.geometry.location.lat();
    this.longitudeMarker = address.geometry.location.lng();
    this.markersInfo[0].marker.setPosition({ lat: this.latitudeMarker, lng: this.longitudeMarker });
    this.latitudeMap = address.geometry.location.lat();
    this.longitudeMap = address.geometry.location.lng();
    this.center = {
      lat: this.latitudeMap,
      lng: this.longitudeMap
    }
  }


  saveLocation() {
    if (!this.locationData) {
      this.toastr.error(this.lang === 'en' ? 'Please enter the address' : 'Veuillez saisir l\'adresse');
      return;
    }

    if (this.activeAlias) {
      if (!this.alias) {
        this.toastr.error(this.lang === 'en' ? 'Please select the alias' : 'Veuillez sélectionner l\'alias');
        return;
      } else {
        if (this.alias.aliasEn === 'Other' && !this.otherAliasEn) {
          this.toastr.error(this.lang === 'en' ? 'Please enter the alias in english' : 'Veuillez saisir l\'alias en anglais');
          return;
        }
        if (this.alias.aliasFr === 'Autre' && !this.otherAliasFr) {
          this.toastr.error(this.lang === 'en' ? 'Please enter the alias in french' : 'Veuillez saisir l\'alias en français');
          return;
        }
      }
    }

    this.getLocationData.emit({
      ...this.locationData,
      aliasEn: this.alias?.aliasEn === 'Other' ? this.otherAliasEn : this.alias?.aliasEn,
      aliasFr: this.alias?.aliasFr === 'Autre' ? this.otherAliasFr : this.alias?.aliasFr,
    });
  }

  setValueInitAddress(locationAddress: string) {
    const lang = localStorage.getItem('language').replaceAll('"', '');

    //Todo: get data marker
    this.geocoder.geocode({ 'address': locationAddress }, (results, status) => {
      if (status === 'OK') {
        if (results[0] || results[1]) {
          const address = results[0] ? results[0] : results[1];
          this.latitudeMarker = address.geometry.location.lat();
          this.longitudeMarker = address.geometry.location.lng();
          const marker = new google.maps.Marker({ position: { lat: this.latitudeMarker, lng: this.longitudeMarker }, draggable: true });
          this.markersInfo.push({ marker: marker });
          this.address = address.formatted_address;
          this.locationData = address;
        } else {
          this.toastr.error(lang === 'en' ? 'Location not found' : 'Emplacement introuvable');
        }
      } else {
        this.toastr.error(lang === 'en' ? 'Location not found' : 'Emplacement introuvable');
      }
    }
    );
  }

  setValueNewAddress(event: { lat: number, lng: number }) {
    const lang = localStorage.getItem('language').replaceAll('"', '');

    const lat = event?.lat;
    const lng = event?.lng;

    //Todo: get data marker
    this.geocoder.geocode({ 'location': { lat, lng } }, (results, status) => {
      if (status === 'OK') {
        if (results[0] || results[1]) {
          const address = results[0] ? results[0] : results[1];
          this.latitudeMarker = address.geometry.location.lat();
          this.longitudeMarker = address.geometry.location.lng();
          this.markersInfo[0].marker.setPosition({ lat: this.latitudeMarker, lng: this.longitudeMarker });
          this.address = address.formatted_address;
          this.locationData = address;
        } else {
          this.toastr.error(lang === 'en' ? 'Location not found' : 'Emplacement introuvable');
        }
      } else {
        this.toastr.error(lang === 'en' ? 'Location not found' : 'Emplacement introuvable');
      }
    }
    );
  }

}
