import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  Router
} from '@angular/router';
import 'moment-timezone';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';

@Component({
  selector: 'app-category-create',
  templateUrl: './category-create.component.html',
  styleUrls: ['./category-create.component.scss']
})
export class CategoryCreateComponent implements OnInit {

  createCategoryForm: UntypedFormGroup;
  public language = "en";
  file!: File;
  id: any;


  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    public routern: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {

    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en"
    }

    //ADD NEW RESIDENCE FORM 
    this.createCategoryForm = this.formBuilder.group({
      name: ['', Validators.required],
      nameFr: ['', Validators.required],
      isFeatured: [1, Validators.required]
    });

    var localData = JSON.parse(localStorage.getItem("currentUser"));
    this.id = localData._id;

  }

  onFileSelect(event) {
    this.file = event.target.files[0];


  }

  get f() { return this.createCategoryForm.controls; }

  addNewCategory() {


    if (!this.f.name.value.trim()) {
      return this.toastr.error('', 'Please enter first name');
    }
    else if (!this.f.nameFr.value.trim()) {
      return this.toastr.error('', 'Please enter last name');
    }


    if (this.f.name.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.name.value);
      if (testing == true) {
        return this.toastr.error('', 'name not valid!');
      }
    }
    if (this.f.nameFr.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.nameFr.value);
      if (testing == true) {
        return this.toastr.error('', 'name Fr not valid!');
      }
    }


    const values = this.createCategoryForm.value;

    this.spinner.show();

    this.services.createCategory(values, this.file, this.id).pipe().subscribe(data => {
      this.spinner.hide();
      this.ngOnInit();
      this.routern.navigate(['/category']);
      return this.toastr.success('successfully created Category');


    }, error => {
      this.spinner.hide()
      return this.toastr.error('error', error.error.message);
    });


  }

}
