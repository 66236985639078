<div class="row justify-content-between scp-breadcrumb mb-4">
  <div class="b-jobs">
    <a>{{ "ADDNEWDRIVER.ADD_NEW_DRIVER" | translate }}</a>
  </div>

  <div class="mt-3 col-md-12">
    <form
      class="create-new-driver"
      [formGroup]="createDriverForm"
      enctype="multipart/form-data"
      (ngSubmit)="onSubmit()"
    >
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>{{ "ADDNEWDRIVER.EMAIL" | translate }}</label>
            <input
              type="email"
              formControlName="email"
              [(ngModel)]="driver.email"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">
                {{ "ADDNEWDRIVER.EMAIL_REQUIRED" | translate }}
              </div>
              <div *ngIf="f.email.errors.email">
                {{ "ADDNEWDRIVER.EMAIL_NOT_VALID" | translate }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>{{ "ADDNEWDRIVER.PASSWORD" | translate }}</label>
            <input
              type="password"
              formControlName="password"
              [(ngModel)]="driver.password"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            />
            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.password.errors.required">
                {{ "ADDNEWDRIVER.PASSWORD_REQUIRED" | translate }}
              </div>
              <div *ngIf="f.password.errors.minLength">
                {{ "ADDNEWDRIVER.PASSWORD_MIN_LENGTH" | translate }}
              </div>
              <div *ngIf="f.password.errors.maxLength">
                {{ "ADDNEWDRIVER.PASSWORD_MAX_LENGTH" | translate }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>{{ "ADDNEWDRIVER.CONFIRM_PASSWORD" | translate }}</label>
            <input
              type="password"
              formControlName="r_password"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  submitted && f.r_password.value != f.password.value
              }"
            />
            <div *ngIf="submitted" class="invalid-feedback">
              <div *ngIf="f.password.value != f.r_password.value">
                {{ "ADDNEWDRIVER.PASSWORD_NOT_MATCH" | translate }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>{{ "ADDNEWDRIVER.PHONE_NUMBER" | translate }}</label>
            <ngx-intl-tel-input
              class="form-control remove-input filled-bg px-0"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="false"
              [enablePlaceholder]="false"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.Canada"
              [maxLength]="12"
              [phoneValidation]="true"
              (ngModelChange)="onChange($event)"
              [ngModel]="phoneNumber"
              [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }"
              [ngModelOptions]="{ standalone: true }"
              [separateDialCode]="separateDialCode"
            ></ngx-intl-tel-input>
            <div
              *ngIf="submitted && f.phoneNumber.errors"
              class="invalid-feedback"
            >
              <span *ngIf="f.phoneNumber.errors.required">{{
                "ADDNEWDRIVER.PHONE_NUMBER_REQUIRED" | translate
              }}</span>
              <div *ngIf="f.phoneNumber.errors.minlength">
                {{ "ADDNEWDRIVER.PHONE_NUMBER_LENGTH" | translate }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>{{ "ADDNEWDRIVER.USERNAME" | translate }}</label>
            <input
              type="text"
              formControlName="username"
              [(ngModel)]="driver.username"
              class="form-control"
              placeholder="{{ 'ADDNEWDRIVER.ENTER_USERNAME' | translate }}"
              [ngClass]="{
                'is-invalid': submitted && f.username.errors
              }"
            />
            <div
              *ngIf="submitted && f.username.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.username.errors.required">
                {{ "ADDNEWDRIVER.USERNAME_REQUIRED" | translate }}
              </div>
              <div *ngIf="f.username.errors.pattern">
                {{ "ADDNEWDRIVER.USERNAME_NOT_VALID" | translate }}
              </div>
              <div *ngIf="f.username.errors.minLength">
                {{ "ADDNEWDRIVER.USERNAME_MIN_LENGTH" | translate }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>{{ "ADDNEWDRIVER.FULLNAME" | translate }}</label>
            <input
              type="text"
              formControlName="fullname"
              [(ngModel)]="driver.fullname"
              class="form-control"
              placeholder="{{ 'ADDNEWDRIVER.ENTER_FULLNAME' | translate }}"
              [ngClass]="{
                'is-invalid': submitted && f.fullname.errors
              }"
            />
            <div
              *ngIf="submitted && f.fullname.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.fullname.errors.required">
                {{ "ADDNEWDRIVER.FULLNAME_REQUIRED" | translate }}
              </div>
              <div *ngIf="f.username.errors.pattern">
                {{ "ADDNEWDRIVER.FULLNAME_NOT_VALID" | translate }}
              </div>
              <div *ngIf="f.username.errors.minLength">
                {{ "ADDNEWDRIVER.FULLNAME_MIN_LENGTH" | translate }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>{{ "ADDNEWDRIVER.FIRST_NAME" | translate }}</label>
            <input
              type="text"
              formControlName="first_name"
              [(ngModel)]="driver.first_name"
              class="form-control"
              placeholder="{{ 'ADDNEWDRIVER.ENTER_FIRST_NAME' | translate }}"
              [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"
            />
            <div
              *ngIf="submitted && f.first_name.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.first_name.errors.required">
                {{ "ADDNEWDRIVER.FIRST_NAME_REQUIRED" | translate }}
              </div>
              <div *ngIf="f.first_name.errors.pattern">
                {{ "ADDNEWDRIVER.FIRST_NAME_NOT_VALID" | translate }}
              </div>
              <div *ngIf="f.first_name.errors.minLength">
                {{ "ADDNEWDRIVER.FIRST_NAME_MIN_LENGTH" | translate }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>{{ "ADDNEWDRIVER.LAST_NAME" | translate }}</label>
            <input
              type="text"
              formControlName="last_name"
              [(ngModel)]="driver.last_name"
              class="form-control"
              placeholder="{{ 'ADDNEWDRIVER.ENTER_LAST_NAME' | translate }}"
              [ngClass]="{
                'is-invalid': submitted && f.last_name.errors
              }"
            />
            <div
              *ngIf="submitted && f.last_name.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.last_name.errors.required">
                {{ "ADDNEWDRIVER.LAST_NAME_REQUIRED" | translate }}
              </div>
              <div *ngIf="f.last_name.errors.pattern">
                {{ "ADDNEWDRIVER.LAST_NAME_NOT_VALID" | translate }}
              </div>
              <div *ngIf="f.last_name.errors.minLength">
                {{ "ADDNEWDRIVER.LAST_NAME_MIN_LENGTH" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <p>{{ "ADDNEWDRIVER.DRIVER_INTERNET_PLAN" | translate }}</p>
            <div class="form-check-inline">
              <label class="form-check-label" for="driver_internet_plan_yes">
                <input
                  type="radio"
                  name="driver_internet_plan"
                  class="form-check-input"
                  id="driver_internet_plan_yes"
                  value="Yes"
                  (change)="toggleInputModelValue($event)"
                  [checked]="driver.driver_internet_plan === 'Yes'"
                />
                {{ "JOBS.yes" | translate }}
              </label>
            </div>
            <div class="form-check-inline">
              <label class="form-check-label" for="driver_internet_plan_no">
                <input
                  type="radio"
                  name="driver_internet_plan"
                  class="form-check-input"
                  id="driver_internet_plan_no"
                  value="No"
                  (change)="toggleInputModelValue($event)"
                  [checked]="driver.driver_internet_plan === 'No'"
                />
                {{ "JOBS.no" | translate }}
              </label>
            </div>
          </div>
          <div class="form-group">
            <p>{{ "ADDNEWDRIVER.DRIVER_HAS_VEHICLE" | translate }}</p>
            <div class="form-check-inline">
              <label class="form-check-label">
                <input
                  type="radio"
                  name="driver_has_vehicle"
                  class="form-check-input"
                  value="Yes"
                  (change)="toggleInputModelValue($event)"
                  [checked]="driver.driver_has_vehicle === 'Yes'"
                />
                {{ "JOBS.yes" | translate }}
              </label>
            </div>
            <div class="form-check-inline">
              <label class="form-check-label">
                <input
                  type="radio"
                  name="driver_has_vehicle"
                  class="form-check-input"
                  value="No"
                  (change)="toggleInputModelValue($event)"
                  [checked]="driver.driver_has_vehicle === 'No'"
                />
                {{ "JOBS.no" | translate }}
              </label>
            </div>
          </div>
          <div class="form-group">
            <p>{{ "ADDNEWDRIVER.DRIVER_PHONE_TYPE" | translate }}</p>
            <div class="checkbox form-check-inline">
              <label class="form-check-label">
                <input
                  type="radio"
                  name="driver_phone_type"
                  class="form-check-input"
                  value="A"
                  (change)="toggleInputModelValue($event)"
                  [checked]="driver.driver_phone_type === 'A'"
                />
                A
              </label>
            </div>
            <div class="form-check-inline">
              <label class="form-check-label">
                <input
                  type="radio"
                  name="driver_phone_type"
                  class="form-check-input"
                  value="I"
                  (change)="toggleInputModelValue($event)"
                  [checked]="driver.driver_phone_type === 'I'"
                />
                I
              </label>
            </div>
          </div>
          <div class="form-group">
            <p>{{ "ADDNEWDRIVER.DRIVER_LEGAL_CANADA" | translate }}</p>
            <div class="form-check-inline">
              <label class="form-check-label">
                <input
                  type="radio"
                  name="driver_legal_canada"
                  class="form-check-input"
                  value="Yes"
                  (change)="toggleInputModelValue($event)"
                  [checked]="driver.driver_legal_canada === 'Yes'"
                />
                {{ "JOBS.yes" | translate }}
              </label>
            </div>
            <div class="form-check-inline">
              <label class="form-check-label">
                <input
                  type="radio"
                  name="driver_legal_canada"
                  class="form-check-input"
                  value="No"
                  (change)="toggleInputModelValue($event)"
                  [checked]="driver.driver_legal_canada === 'No'"
                />
                {{ "JOBS.no" | translate }}
              </label>
            </div>
          </div>
          <div class="form-group">
            <p>{{ "ADDNEWDRIVER.FIRST_DELIVERY_EXPERIENCE" | translate }} ?</p>
            <div class="form-check-inline">
              <label class="form-check-label">
                <input
                  type="radio"
                  name="is_first_delivery_experience"
                  class="form-check-input"
                  value="No"
                  (change)="toggleInputModelValue($event)"
                  [checked]="driver.is_first_delivery_experience === 'No'"
                />
                {{ "JOBS.no" | translate }}
              </label>
            </div>
            <div class="form-check-inline">
              <label class="form-check-label">
                <input
                  type="radio"
                  name="is_first_delivery_experience"
                  class="form-check-input"
                  value="Yes"
                  (change)="toggleInputModelValue($event)"
                  [checked]="driver.is_first_delivery_experience === 'Yes'"
                />
                {{ "JOBS.yes" | translate }}
              </label>
            </div>
          </div>

          <div class="form-group">
            <p>{{ "ADDNEWDRIVER.DRIVER_SPEAK_LANGUAGES" | translate }}</p>
            <div class="form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value="English"
                id="English"
                [checked]="driver.driver_speak_languages.includes('English')"
                (change)="toggleLanguage('English')"
              />
              <label class="form-check-label" for="English">{{
                "english" | translate
              }}</label>
            </div>
            <div class="form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value="French"
                id="French"
                [checked]="driver.driver_speak_languages.includes('French')"
                (change)="toggleLanguage('French')"
              />
              <label class="form-check-label" for="French">
                {{ "french" | translate }}
              </label>
            </div>
          </div>

          <div class="form-group">
            <p>{{ "ADDNEWDRIVER.DRIVER_AVAILABILITY" | translate }}</p>
            <div class="form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value="Morning"
                id="Morning"
                [checked]="driver.driver_availability.includes('Morning')"
                (change)="toggleAvailability('Morning')"
              />
              <label class="form-check-label" for="Morning">
                {{ "morning" | translate }}
              </label>
            </div>
            <div class="form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value="Afternoon"
                id="Afternoon"
                [checked]="driver.driver_availability.includes('Afternoon')"
                (change)="toggleAvailability('Afternoon')"
              />
              <label class="form-check-label" for="Afternoon">
                Afternoon
              </label>
            </div>
            <div class="form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value="Evening"
                id="Evening"
                [checked]="driver.driver_availability.includes('Evening')"
                (change)="toggleAvailability('Evening')"
              />
              <label class="form-check-label" for="Evening">
                {{ "evening" | translate }}
              </label>
            </div>
            <div class="form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value="Weekend"
                id="Weekend"
                [checked]="driver.driver_availability.includes('Weekend')"
                (change)="toggleAvailability('Weekend')"
              />
              <label class="form-check-label" for="Weekend">
                {{ "weekend" | translate }}
              </label>
            </div>
            <div class="form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value="Weekdays"
                id="Weekdays"
                [checked]="driver.driver_availability.includes('Weekdays')"
                (change)="toggleAvailability('Weekdays')"
              />
              <label class="form-check-label" for="Weekdays">
                {{ "weekdays" | translate }}
              </label>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <!-- Files upload section -->
          <div class="form-group">
            <label>{{ "ADDNEWDRIVER.PROFILE_IMG" | translate }}</label>
            <input
              type="file"
              formControlName="profile_img"
              name="profile_img"
              class="form-control"
              id="profile_img"
              accept="image/png, image/jpeg, image/jpg"
              (change)="onFileSelected($event)"
            />
          </div>
          <div class="form-group">
            <label>{{ "ADDNEWDRIVER.DRIVER_LICENCE" | translate }}</label>
            <input
              type="file"
              formControlName="driver_license"
              name="driver_license"
              class="form-control"
              id="driver_license"
              accept="image/png, image/jpeg, image/jpg"
              (change)="onFileSelected($event)"
            />
          </div>
          <div class="form-group">
            <label>{{ "ADDNEWDRIVER.INSURANCE" | translate }}</label>
            <input
              type="file"
              formControlName="motor_insurance"
              name="motor_insurance"
              class="form-control"
              id="motor_insurance"
              accept="image/png, image/jpeg, image/jpg"
              (change)="onFileSelected($event)"
            />
          </div>

          <div class="form-group">
            <label>{{ "ADDNEWDRIVER.REG_CERTIFICATE" | translate }}</label>
            <input
              type="file"
              formControlName="registeration_certificate"
              name="registeration_certificate"
              class="form-control"
              id="registeration_certificate"
              accept="image/png, image/jpeg, image/jpg"
              (change)="onFileSelected($event)"
            />
          </div>
          <!-- ./ Files upload section -->
        </div>
        <!-- /.col-md-4 -->
      </div>
      <!-- ./ .row -->
      <hr />
      <!-- Button type submit -->
      <div class="form-group pt-4 d-flex justify-content-end">
        <app-button
          [buttonText]="'ADDNEWDRIVER.create_driver' | translate"
          type="submit"
        >
        </app-button>
        <app-button
          [buttonText]="'CLIENTS.close' | translate"
          (buttonClick)="parentModalRef.hide()"
        >
        </app-button>
      </div>
    </form>
  </div>
</div>

<ng-template #otpTemplate>
  <div class="d-flex justify-content-center">
    <ng-otp-input
      allowNumbersOnly
      (onInputChange)="onOtpChange($event)"
    ></ng-otp-input>
  </div>
</ng-template>
