import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { faHomeAlt } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../environments/environment';
import { PusherServiceService } from '../../../pusher-service.service';
import { ServicesService } from '../../../services.service';
import { DriverMaps } from '../../../services/interfaces';
import { Marker } from '../../../services/interfaces/googleMaps.interface';
import { GoogleMapComponent } from '../../../shared/component/google-map/google-map.component';
import { JOB } from "../../../shared/helpers/enums";
import { CommonMethods } from '../../_helpers/_common-methods';
@Component({
  selector: 'app-drivers-map',
  templateUrl: './drivers-map.component.html',
  styleUrls: ['./drivers-map.component.scss']
})
export class DriversMapComponent implements OnInit, OnDestroy {

  driverSelected: any = {};
  language = "en";
  mapInstance: any;
  allDrivers: any[] = [];
  localData: any;
  driversOnMap: DriverMaps[] = [];
  @Output() refreshIndicators = new EventEmitter<any>();
  markersInfo: Marker[] = [];
  @ViewChild(GoogleMapComponent)
  googleMapComponent: GoogleMapComponent;

  constructor(
    private services: ServicesService,
    private pusherService: PusherServiceService,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    private commonMethod: CommonMethods
  ) {
    translate.addLangs(["en", "fr"]);
    translate.setDefaultLang("en");
  }

  ngOnInit(): void {

    this.localData = JSON.parse(localStorage.getItem("currentUser"));

    this.language = JSON.parse(localStorage.getItem("language"));
    this.driverSelected = { id: '', name: this.language === 'fr' ? 'Tous les livreurs' : 'All Drivers' }

    this.getActiveDrivers();
    this.initPusher();
  }

  ngOnDestroy(): void {
    this.pusherService.removeChannel(environment.CHANNEL_JOB_CHANGED);
    this.pusherService.removeChannel(
      environment.CHANNEL_CHANGE_LOCATION_DRIVER
    );
  }

  initPusher() {
    const jobChannel = this.pusherService.init(
      environment.CHANNEL_JOB_CHANGED
    ) as unknown as { bind?: any };
    if (jobChannel.bind) {
      jobChannel.bind(`pharmacy_jobs_deleted`, (data) => {
        if (data.method === "delete") {
          this.handleJobChannel(data);
        }
      });
      jobChannel.bind(`jobs_changed_${this.localData?._id}`, (data) => {
        if (data.user_id === this.localData?._id) {
          this.handleJobChannel(data);
        }
      });

    }

    const driverChannel = this.pusherService.init(
      environment.CHANNEL_CHANGE_LOCATION_DRIVER
    ) as unknown as { bind?: any };
    if (driverChannel.bind) {
      driverChannel.bind(`new-location-${this.localData?._id}`, (data) => {

        this.refreshLocationMarker(data);
      });
    }
  }

  private handleJobChannel(data: any) {
    this.refreshMap(data);
    this.commonMethod.throttle(() => {
      this.refreshIndicators.emit();
    }, 2000)();
  }

  async getActiveDrivers(driverId: string = "", showSpinner: boolean = true) {
    try {
      if (showSpinner) {
        this.spinner.show();
      }


      const data = await this.services.getActiveDriversJobs({
        driver_id: driverId || this.driverSelected?.id,
      }).toPromise();


      if (driverId) {
        const newDriver = data?.result[0];

        const existingDriver = this.driversOnMap.find(
          ({ _id }) => _id === newDriver._id
        );

        if (!existingDriver) {
          this.driversOnMap.push(newDriver);
          if (this.driverSelected?.id === "") {
            this.addDriverInMap(newDriver);
          }
        }
      } else {
        this.driversOnMap = data?.result;
        this.getDriversOnMap();
      }
      this.updatedDrivers();
    } catch (error) {
      console.log('error:', error);
    } finally {
      this.spinner.hide();
    }
  }

  updatedDrivers() {
    const drivers = this.driversOnMap.filter((item) => item._id && item.isOnline === "1").map((item) => {
      return {
        id: item?._id,
        name: `${item?.fullname} (${item?.username})`
      }
    });
    this.allDrivers = [{ id: '', name: this.language === 'fr' ? 'Tous les livreurs' : 'All Drivers' }, ...drivers];
  }

  async getDriversOnMap() {

    let arrayDrivers = [];
    this.markersInfo = [];
    if (this.driversOnMap.length > 0) {
      arrayDrivers = this.driversOnMap.filter((driver) => driver.lat_long[0] != 0 && driver.lat_long[1] != 0);

    }

    const promises = arrayDrivers.map((driver) => this.addDriverInMap(driver));
    Promise.all(promises).then(() => {
      this.googleMapComponent.calculateBounds(this.markersInfo);
    });

    //================================================================================================
  }

  resetZoom() {
    this.googleMapComponent.calculateBounds(this.markersInfo);
  }
  addDriverInMap(driver: DriverMaps) {

    if (this.driverSelected?.id && this.driverSelected?.id !== driver?._id) return;

    const markerPromises = driver.jobs.map((job) => {
      return new Promise<void>((resolve, reject) => {
        this.createSingleJobMarker(job);
        resolve();
      });
    });

    Promise.all(markerPromises).then(() => {

      const marker = new google.maps.Marker({
        position: { lat: driver.lat_long[0] || 0, lng: driver.lat_long[1] || 0 },
      });

      if (!this.haveActiveJobs(driver) && !this.driverSelected?.id || driver.isOnline === "2") {
        marker.setVisible(false);
      }
      this.markersInfo.push({
        marker: marker,
        data: driver,
      });

    });

  }

  haveActiveJobs(driver: DriverMaps) {
    return driver.jobs.filter((job) => job.job_status === "A").length > 0;
  }

  createSingleJobMarker(job: any) {
    //https://stackoverflow.com/questions/16375077/using-icon-fonts-as-markers-in-google-maps-v3
    const icon = {
      path: faHomeAlt.icon[4] as string,
      fillColor: "#ffe4c8",
      fillOpacity: 3,
      strokeWeight: 0.5,
      strokeColor: "#000000",
      scale: 0.05,
    };

    switch (job.job_status) {
      case JOB.JOB_STATUS.ACTIVE:
        icon.fillColor = "#1455d9";
        break;
      case JOB.JOB_STATUS.PENDING:
        if (job.isAccepted) {
          icon.fillColor = "#caaf15";
          job.job_status = "ACCEPTED";
        } else {
          icon.fillColor = "#ff8b17";
        }
        break;
      case JOB.JOB_STATUS.COMPLETED:
        icon.fillColor = "#0bce1f";
        break;
      case JOB.JOB_STATUS.NOT_DELIVERED:
        icon.fillColor = "#a8a0a0";
        break;
    }

    const marker = new google.maps.Marker({
      position: {
        lat: job.dropoff_lat_long[0],
        lng: job.dropoff_lat_long[1],
      },

      icon: icon,
    });
    // Additional marker customization or handling can be done here
    job.marker = marker;
    return job;
  }

  onChangeDriverSelect(event) {
    this.driverSelected = event?.value;
    this.getActiveDrivers();
  }

  async refreshMap(dataPusher: any) {

    if (dataPusher.method === "delete") {
      this.driversOnMap.map((driver) => {
        // Find the index of the job with the specified _id in the driver's jobs array
        const jobIndexToDelete = driver.jobs.findIndex(
          (job) => job._id === dataPusher._id
        );

        if (jobIndexToDelete !== -1) {
          // If the job with the specified _id exists, remove it from the jobs array
          driver.jobs.splice(jobIndexToDelete, 1);

          this.updateMarker(driver);
        }

        return driver;
      });
    } else if (dataPusher.user_id === this.localData?._id) {
      // delete job from old driver if it was transfered
      this.driversOnMap = this.driversOnMap.map((item) => ({
        ...item,
        jobs: item.jobs.filter((job) => {
          return (
            job._id !== dataPusher?._id ||
            job.driver_id === dataPusher?.driver_id
          );
        }),
      }));
      let driver = this.driversOnMap.find(
        (driver) => driver._id === dataPusher?.driver_id
      );
      dataPusher = this.createSingleJobMarker(dataPusher);

      if (driver) {
        const jobIndex = driver.jobs.findIndex(
          (job) => job._id === dataPusher?._id
        );

        if (jobIndex !== -1) {
          driver.jobs[jobIndex] = dataPusher;
        } else {
          driver.jobs.push(dataPusher);
        }

      } else if (
        dataPusher.job_status === JOB.JOB_STATUS.PENDING
      ) {
        // Add new driver to the map
        await this.getActiveDrivers(dataPusher?.driver_id, false);

        driver = this.driversOnMap.find(
          (driver) => driver._id === dataPusher?.driver_id
        )

      }

      this.updateMarker(driver);
    }
  }


  updateMarker(driver: DriverMaps) {

    const markerIndex = this.markersInfo.findIndex(
      (marker) => marker.data?._id === driver?._id
    );

    if (markerIndex !== -1) {
      const markersInfo = [...this.markersInfo];
      markersInfo[markerIndex].data = driver;

      if (!this.driverSelected?.id) {
        markersInfo[markerIndex].marker.setVisible(
          this.haveActiveJobs(driver) && driver.isOnline === "1" ? true : false
        );
      }

      this.markersInfo = markersInfo;
      this.googleMapComponent.findOverlappingMarkers(this.markersInfo);
    }
  }

  refreshLocationMarker(dataPusher) {
    const markerInfo = this.markersInfo.find(
      (marker) => marker.data?._id === dataPusher?._id
    );

    if (!markerInfo) {
      return;
    }

    markerInfo.data.lat_long = dataPusher.lat_long;
    markerInfo.data.isOnline = dataPusher.isOnline;
    markerInfo.marker.setPosition({
      lat: dataPusher.lat_long[0],
      lng: dataPusher.lat_long[1],
    });

    this.updateMarker(markerInfo.data);
    this.updatedDrivers();
  }

}
