<app-jobs-list
  endpoint="searchCompletedJobs"
  [tableActionTemplate]="tableActionTemplate"
  [extraColumnThTemplate]="extraColumnThTemplate"
  [extraColumnTdTemplate]="extraColumnTdTemplate"
></app-jobs-list>
<ng-template #extraColumnThTemplate>
  <th>{{ "JOBS.collected_amount" | translate }}</th>
</ng-template>
<ng-template #extraColumnTdTemplate let-job>
  <td>
    <!-- card amount -->
    <ul class="table-action">
      <li
        *ngIf="
          job.job_status == 'C' &&
          job.customer_payment_id &&
          job.customer_payment_id.payment_method == '1'
        "
      >
        <span class="amount">N/A</span>
      </li>
    </ul>

    <!-- cash amount -->
    <ul
      class="table-action"
      *ngIf="
        job.job_status == 'C' &&
        job.collected_amount_pharmacy != job.job_amount &&
        job.customer_payment_id != null &&
        job.customer_payment_id.payment_method == '2' &&
        job.customer_payment_id.paid == false
      "
    >
      <li>
        <span class="amount"
          >${{ job.collected_amount_pharmacy | number : "1.2-2" }}</span
        >
      </li>
      <li (click)="openCollectedAmountModel(job)">
        <i class="fa fa-pencil" aria-hidden="true" style="cursor: pointer"></i>
      </li>
    </ul>

    <ul
      class="table-action"
      *ngIf="
        (job.collected_amount_pharmacy == job.job_amount &&
          job.job_amount != 0) ||
        (job.customer_payment_id &&
          (job.customer_payment_id.payment_method == '2' ||
            job.customer_payment_id.payment_method == '4') &&
          job.customer_payment_id.paid == true)
      "
    >
      <li>
        <span class="amount"
          >${{ job.collected_amount_pharmacy | number : "1.2-2" }}</span
        >
      </li>
      <li *ngIf="job.job_status == 'C'">
        <i
          class="fa fa-check"
          aria-hidden="true"
          style="cursor: pointer; color: green"
        ></i>
      </li>
    </ul>

    <!-- cheque amount -->
    <ul
      class="table-action"
      *ngIf="
        job.job_status == 'C' &&
        job.customer_payment_id &&
        job.customer_payment_id.payment_method == '3' &&
        job.cheque_collected_from_driver == false
      "
    >
      <li><span class="amount">N/C</span></li>
      <li (click)="openChequeModal(job)">
        <i class="fa fa-pencil" aria-hidden="true" style="cursor: pointer"></i>
      </li>
    </ul>

    <ul
      class="table-action"
      *ngIf="
        job.job_status == 'C' &&
        job.customer_payment_id &&
        job.customer_payment_id.payment_method == '3' &&
        job.cheque_collected_from_driver == true
      "
    >
      <li>
        <span class="amount" style="color: #000000">{{
          "JOBS.collected" | translate
        }}</span>
      </li>
    </ul>

    <!-- cheque amount -->
    <ul
      class="table-action"
      *ngIf="job.job_status != 'C' || !job.customer_payment_id"
    >
      <li><span class="amount">N/A</span></li>
    </ul>
  </td>
</ng-template>

<ng-template #tableActionTemplate let-job>
  <div class="text-center">
    <ng-container *ngIf="job.job_status === 'C'; else nullTemplate">
      <li (click)="openModalComent(comentModal)">
        <i class="fa fa-comments-o"></i>
      </li>
    </ng-container>
    <ng-template #comentModal let-modal>
      <app-commet [_id]="job._id" [modal]="modal"></app-commet>
    </ng-template>
    <ng-template #nullTemplate> </ng-template>

    <ng-template #comment> </ng-template>
  </div>
</ng-template>

<!-------modal---->
<div
  bsModal
  #successModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md add-client-modal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title-absolute">
          <!-- <button class="btn btn-default">{{ 'JOBS.collected_amount1' | translate }}</button> -->
          <button class="btn btn-default">Collection</button>
        </div>
        <button
          type="button"
          class="close"
          (click)="successModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body collection-modal">
        <div class="row">
          <div class="col-md-6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                type="text"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                class="form-control"
                placeholder="{{ 'JOBS.amount' | translate }}"
                [(ngModel)]="collected_amount"
                maxlength="6"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="collected_amount_initial"
                maxlength="10"
                placeholder="{{ 'JOBS.initial' | translate }}"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <textarea
                class="form-control"
                rows="5"
                id="comment"
                placeholder="{{ 'JOBS.note' | translate }}"
                maxlength="100"
                (input)="onSearchChange($event.target.value)"
                [(ngModel)]="collected_amount_note"
              ></textarea>
              <p class="textarea-num">{{ collected_amount_note_count }}/100</p>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <ul class="collection-modal-list mb-0">
            <li>
              <p>Mark as collected</p>
            </li>
            <li>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="mark_cash_collected"
                  id="customCheck4"
                  name="example1"
                />
                <label class="custom-control-label" for="customCheck4"></label>
              </div>
            </li>
          </ul>
          <p *ngIf="mark_cash_collected" style="color: red; text-align: center">
            * Mark as collected will not be able to edit again
          </p>
        </div>
      </div>
      <div class="modal-footer" style="margin: auto">
        <button
          type="button"
          class="btn btn-secondary client-cancel-btn"
          (click)="successModal.hide()"
        >
          {{ "JOBS.cancel" | translate }}
        </button>

        <button
          type="button"
          class="btn btn-secondary add-client-btn"
          (click)="updateCollectedAmmount()"
        >
          {{ "JOBS.update" | translate }}
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->

<!-------modal for cheque---->
<div
  bsModal
  #chequeModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-md add-client-modal"
    role="document"
    style="max-width: 390px"
  >
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title-absolute">
          <!-- <button class="btn btn-default">{{ 'JOBS.collected_amount1' | translate }}</button> -->
          <button class="btn btn-default">Collection</button>
        </div>
        <!-- <button type="button" class="close" (click)="chequeModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <ul class="collection-modal-list">
              <li>
                <p>Cheque Collected</p>
              </li>
              <li>
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    [(ngModel)]="cheque_collected_from_driver"
                    id="customCheck3"
                    name="example2"
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck3"
                  ></label>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-md-6 collection-modal">
            <div class="form-group w-100">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="cheque_amount_initials"
                maxlength="10"
                placeholder="{{ 'JOBS.initial' | translate }}"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 collection-modal">
            <div class="form-group">
              <textarea
                class="form-control"
                rows="5"
                id="comment"
                placeholder="{{ 'JOBS.note' | translate }}"
                maxlength="100"
                (input)="onSearchChange($event.target.value)"
                [(ngModel)]="collected_amount_note"
              ></textarea>
              <p class="textarea-num">{{ collected_amount_note_count }}/100</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" style="margin: auto">
        <button
          type="button"
          class="btn btn-secondary client-cancel-btn"
          (click)="chequeModal.hide()"
        >
          {{ "JOBS.cancel" | translate }}
        </button>

        <button
          type="button"
          class="btn btn-secondary add-client-btn"
          (click)="updateChequeCollection()"
        >
          {{ "JOBS.update" | translate }}
        </button>
      </div>
    </div>
  </div>
  <!-- /.modal-content -->
</div>
<!-- /.modal-dialog -->

<!-- modal -->
<ng-template #recreateJobTemplate>
  <div class="calender-custom">
    <input
      class="form-control box-m-in"
      [minDate]="minDate"
      placeholder="Date"
      [(ngModel)]="recreateJobDate"
      ngbDatepicker
      #recreateJobDatePicker="ngbDatepicker"
      [readonly]="true"
      (click)="recreateJobDatePicker.toggle()"
      style="cursor: pointer; display: inline-block; width: 100%"
      firstDayOfWeek="7"
    />
    <i
      class="fa fa-calendar point"
      (click)="recreateJobDatePicker.toggle()"
      aria-hidden="true"
    ></i>
  </div>
  <textarea
    [(ngModel)]="reasonForRecreateJob"
    class="form-control"
    [placeholder]="'JOBS.REASON' | translate"
    rows="4"
  ></textarea>
</ng-template>
