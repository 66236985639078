<ng-template #dynamicContent>
  <div *ngIf="!showDetails; else childrenDynamicContent">
    <app-button
      [imageSrc]="'./assets/img/new-ui/dowload_as_pdf.svg'"
      [buttonText]="'REPORT.print' | translate"
      (buttonClick)="getPdf()"
    ></app-button>
  </div>
</ng-template>
<ng-template #childrenDynamicContent>
  <ng-container
    *ngTemplateOutlet="globalSummaryDetailsComponent?.dynamicContentTemplate"
  >
  </ng-container>
</ng-template>
<div *ngIf="!showDetails; else details" class="mt-3">
  <app-custom-calendar-datepickers
    [(startDate)]="startDate"
    [(endDate)]="endDate"
    [(fromDate)]="fromDate"
    [(toDate)]="toDate"
    (getData)="globalSummaryDetails()"
  ></app-custom-calendar-datepickers>
  <app-table
    [theadTemplate]="theadTemplate"
    [tbodyTemplate]="tbodyTemplate"
  ></app-table>
  <ng-template #theadTemplate>
    <tr>
      <th>{{ "REPORT.payment_method" | translate }}</th>
      <th>{{ "REPORT.number" | translate }}</th>
      <th>{{ "REPORT.request" | translate }}</th>
      <th>{{ "REPORT.collection" | translate }}</th>
    </tr>
  </ng-template>

  <ng-template #tbodyTemplate>
    <tbody>
      <tr (click)="goToTagDetailPage('2', findAllCashPaymentsCount)">
        <td>
          <strong>{{ "REPORT.cash" | translate }}</strong>
        </td>
        <td>{{ findAllCashPaymentsCount }}</td>
        <td>$ {{ cashRequestAmount | number : "1.2-2" }}</td>
        <td>$ {{ findAllCashPaymentsAmount | number : "1.2-2" }}</td>
      </tr>
      <tr (click)="goToTagDetailPage('1', findAllCardPaymentsCount)">
        <td>
          <strong>{{ "REPORT.debit_credit" | translate }}</strong>
        </td>
        <td>{{ findAllCardPaymentsCount }}</td>
        <td>$ {{ cardRequestAmount | number : "1.2-2" }}</td>
        <td>$ {{ findAllCardPaymentsAmount | number : "1.2-2" }}</td>
      </tr>
      <tr (click)="goToTagDetailPage('3', findAllChequePaymentsCount)">
        <td>
          <strong>{{ "REPORT.cheque" | translate }}</strong>
        </td>
        <td>{{ findAllChequePaymentsCount }}</td>
        <td>$ {{ chequeRequestAmount | number : "1.2-2" }}</td>
        <td>$ {{ findAllChequePaymentsAmount | number : "1.2-2" }}</td>
      </tr>
      <tr (click)="goToTagDetailPage('5', findAllTpvdPaymentsCount)">
        <td>
          <strong>{{ "TPV" | translate }}</strong>
        </td>
        <td>{{ findAllTpvdPaymentsCount }}</td>
        <td>$ {{ tpvRequestAmount | number : "1.2-2" }}</td>
        <td>$ {{ findAllTpvPaymentsAmount | number : "1.2-2" }}</td>
      </tr>
      <tr>
        <td>
          <strong>{{ "REPORT.total_jobs" | translate }}</strong>
        </td>
        <td>
          <strong>{{ totalJobs }}</strong>
        </td>
        <td>
          <strong
            >$ {{ totalCollectedRequestedAmount | number : "1.2-2" }}</strong
          >
        </td>
        <td>
          <strong>$ {{ totalCollectedAmount | number : "1.2-2" }}</strong>
        </td>
      </tr>
    </tbody>
  </ng-template>
</div>
<ng-template #details>
  <app-global-summary-details
    #globalSummaryDetails
    [job_id]="allJobsPaymentIds"
    [(show)]="showDetails"
  ></app-global-summary-details>
</ng-template>
