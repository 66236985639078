import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef, BsModalService, } from 'ngx-bootstrap/modal';
import { CountryISO, SearchCountryField } from "ngx-intl-tel-input";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../../services.service';
import { ModalComponent } from "../../shared/component/modal/modal.component";
@Component({
    selector: 'app-create-driver',
    templateUrl: './create-driver.component.html',
    styleUrls: ['./create-driver.component.scss']
})

export class CreateDriverComponent implements OnInit {
    @Input() parentModalRef: BsModalRef;
    bsModalRef: BsModalRef;
    @Output() onCreateDriver: EventEmitter<any> = new EventEmitter();
    @ViewChild('otpTemplate') otpTemplate: TemplateRef<any>;
    public driver_availabilities: string[] = ["Morning", "Afternoon", "Evening", "Weekend", "Weekdays"];
    public profile_img: File = null;
    public driver_license: File = null;
    public motor_insurance: File = null;
    public registeration_certificate: File = null;
    currentUser = JSON.parse(localStorage.getItem("currentUser"));
    public driver = {

        email: "",
        password: "",
        username: "",
        fullname: "",
        first_name: "",
        last_name: "",
        driver_speak_languages: [
            "English",
            "French"
        ],
        driver_availability: [
            "Morning",
            "Afternoon"
        ], //default: driver_availabilities : ["Morning", "Afternoon", "Evening", "Weekend", "Weekdays"],
        driver_internet_plan: "Yes",
        driver_has_vehicle: "Yes",
        driver_phone_type: "A", // driver_phone_type: { type: String, enum: ["A", "I"], default: "I" },
        driver_legal_canada: "Yes",
        is_first_delivery_experience: "No",

        profile_img: "",
        driver_license: "",
        motor_insurance: "",
        registeration_certificate: "",

        profile_img_status: "", //0 for uploaded, 1 for verified, 2 for unverified
        driver_license_status: "", //0 for uploaded, 1 for verified, 2 for unverified
        motor_insurance_status: "", //0 for uploaded, 1 for verified, 2 for unverified
        registeration_certificate_status: "", //0 for uploaded, 1 for verified, 2 for unverified
        countryCode: "",
        phoneNumber: "",
        countryFlagName: "",
        mobileVerified: false
    }

    createDriverForm: FormGroup;
    submitted = false;

    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    phoneNumber = "";
    preferredCountries: CountryISO[] = [
        CountryISO.Canada,
        CountryISO.UnitedStates,
    ];
    countryCode: any;

    countryFlagName = "Canada";
    separateDialCode = true
    otp: any;
    constructor(
        private formBuilder: FormBuilder,
        public toastr: ToastrService,
        private services: ServicesService,
        private http: HttpClient,
        public router: Router,
        private spinner: NgxSpinnerService,
        private modalService: BsModalService,
        private translate: TranslateService
    ) {

        // ON INIT ACTIONS
    }

    ngOnInit() {

        this.createDriverForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(12)]),
            r_password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(12)]),
            username: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z0-9 ]*"), Validators.minLength(4)]),
            fullname: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z0-9 ]*"), Validators.minLength(4)]),
            first_name: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z0-9 ]*"), Validators.minLength(4)]),
            last_name: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z0-9 ]*"), Validators.minLength(4)]),
            profile_img: new FormControl(null),
            driver_license: new FormControl(null),
            motor_insurance: new FormControl(null),
            registeration_certificate: new FormControl(null),
            phoneNumber: new FormControl('', [Validators.required, Validators.minLength(12)]),
            countryCode: new FormControl(''),

        });

    }

    // convenience getter for easy access to form fields
    get f() {
        return this.createDriverForm.controls;
    }

    onFileSelected(event) {
        if (event.target.files[0]) {
            const file = event.target.files[0];
            const acceptedFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
            if (acceptedFileTypes.includes(file.type)) {
                if (event.target.name) {
                    this.driver[event.target.name] = file;

                }
            } else {
                this.toastr.error('error', "Only file type 'image/png', 'image/jpg' and 'image/jpeg' are accepted");
            }
        }
        event.target.classList.remove('is-invalid');

    }

    toggleAvailability(availability: string): void {
        if (this.driver.driver_availability.includes(availability)) {
            this.driver.driver_availability = this.driver.driver_availability.filter(item => item !== availability);
        } else {
            this.driver.driver_availability.push(availability);
        }

    }

    toggleLanguage(language: string): void {
        if (this.driver.driver_speak_languages.includes(language)) {
            this.driver.driver_speak_languages = this.driver.driver_speak_languages.filter(item => item !== language);
        } else {
            this.driver.driver_speak_languages.push(language);
        }

    }

    toggleInputModelValue(event): void {
        if (event.target.name && event.target.value) {
            this.driver[event.target.name] = event.target.value;
        }
    }
    onChange(val) {

        let customNumber = val?.number.split("-").join("") || "";
        if (customNumber.length > 0) {
            customNumber = customNumber.match(/\d{3}(?=\d{2,3})|\d+/g).join("-");


        }
        this.countryCode = val?.dialCode || "";
        this.phoneNumber = customNumber;
        this.countryFlagName = val?.countryCode;
        this.f['phoneNumber'].setValue(customNumber);
        this.f['countryCode'].setValue(this.countryCode);


    }

    onOtpChange(otp) {

        this.otp = otp;
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.createDriverForm.invalid) {
            return;
        }
        if (this.createDriverForm.controls.r_password.value != this.createDriverForm.controls.password.value) {
            return;
        }

        this.driver.phoneNumber = this.phoneNumber;
        this.driver.countryCode = this.countryCode;
        this.driver.countryFlagName = this.countryFlagName;


        this.spinner.show();
        this.services.addDriver({ ...this.driver, pharmacy_id: this.currentUser?._id }).pipe().subscribe(data => {
            if (data.code == 200) {
                this.spinner.hide();
                if (data.otp) {
                    this.toastr.info('', this.translate.instant('ADDNEWDRIVER.INVITE_CONFIRMED'));
                    this.openOtpModal(data.otp);
                    return;
                }

                this.onReset();
                this.toastr.success('success', this.translate.instant('ADDNEWDRIVER.REGISTRATION_SUCCESFULLY'));
                this.parentModalRef.hide();
                this.onCreateDriver.emit();

            } else {
                this.spinner.hide();
                this.toastr.error('error', data.message);
                return;

            }

        });

    }

    onReset(): void {
        this.submitted = false;
        this.createDriverForm.reset();
    }

    openOtpModal(otp) {
        const initialState = {
            content: this.otpTemplate,
            config: {
                title: this.translate.instant('VERIFICATION.enter_phone_otp'),
                action: "VERIFICATION.submit",

            },
            onClick: this.verifyOtp.bind(this, otp),

        };
        this.bsModalRef = this.modalService.show(ModalComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            class: "modal-sm modal-dialog-centered",
        });
    }

    verifyOtp(otp) {

        if (otp === this.otp) {
            this.otp = null;
            this.driver.mobileVerified = true;
            this.bsModalRef.hide();
            this.onSubmit();
        } else {
            this.toastr.error('error', this.translate.instant('VERIFICATION.otp_invalid'));
        }
    }
}