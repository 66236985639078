<ngb-progressbar
  [class]="class"
  [type]="behind > 0 ? 'warning' : 'success'"
  [animated]="true"
  [value]="(completed / total) * 100"
  [striped]="true"
>
  <span class="progress-text"
    >{{ completed }} / {{ total }}
    {{
      behind > 0
        ? "&nbsp;(" +
          behind +
          " " +
          ("DASHBOARD_SUMMARY.BEHIND" | translate) +
          ")"
        : ""
    }}
  </span>
</ngb-progressbar>
