import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PusherServiceService {
  pusher;

  constructor() {
    this.pusher = new Pusher(environment.PUSHER_KEY, {
      cluster: 'us2',
    });
    // Pusher.logToConsole = true;
  }

  public init(channel: string) {
    return this.pusher.subscribe(channel);
  }

  public removeChannel(channel: string) {
    this.pusher.unsubscribe(channel);
  }
}