import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ServicesService } from "../../../services.service";

@Component({
  selector: "app-notes",
  templateUrl: "./notes.component.html",
  styleUrls: ["./notes.component.scss"],
})
export class NotesComponent implements OnInit {
  @Input() idJobs = 0;
  @Output() close: EventEmitter<any> = new EventEmitter();
  noteStore = "";
  dataNote: ResultInfo[] = [];
  dataUser = JSON.parse(localStorage.getItem("currentUser"));
  constructor(
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private services: ServicesService
  ) {
    if (this.idJobs) {
      console.log(this.idJobs);
    }
  }

  ngOnInit(): void {

  }

  toogle() {
    console.log("cerrar");
    this.close.emit(false);
  }

  getAllNotes(id) {
    //getNotesJobId
    this.services
      .getNotesJobId(id)
      .pipe()
      .subscribe((data) => {
        if (data.code == 400) {
          this.spinner.hide();
          this.toastr.error("", data.message);
          //this.logoutFunction.logout();
          return;
        }
        if (data.code == 200) {
          this.dataNote = data.result;
          this.spinner.hide();
          console.log("code 200", this.dataNote);
        } else {
          this.spinner.hide();
          this.toastr.error("error", data.message);
        }
      });
  }

  storeNote() {
    if (this.noteStore === "") {
      this.toastr.error("error", this.translate.instant("JOBS.not_note"));
    } else {
      this.services
        .storeNotesJobId(this.idJobs, this.noteStore, this.dataUser._id)
        .pipe()
        .subscribe((data) => {
          if (data.code == 400) {
            this.spinner.hide();
            this.toastr.error("", data.message);
            //this.logoutFunction.logout();
            return;
          }
          if (data.code == 200) {
            this.spinner.hide();
            this.noteStore = "";
            this.toastr.success("", data.message);

            this.getAllNotes(this.idJobs);
          } else {
            this.spinner.hide();
            this.toastr.error("error", data.message);
          }
        });
    }
  }
}

export interface ResultInfo {
  jobId: string;
  orderId: string;
  note: string;
  userId: string;
  statusD: number;
  _id: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export interface DataObject {
  code: number;
  message: string;
  result: ResultInfo[];
}
