<div class="row justify-content-between scp-breadcrumb mb-4">
  <div class="b-jobs">
    <a>{{ "REPORT.report" | translate }} </a>
  </div>

  <ng-container *ngIf="selectedTabIndex === 'global-summary'">
    <ng-container
      *ngTemplateOutlet="globalSummaryComponent?.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === 'courier-summary'">
    <ng-container
      *ngTemplateOutlet="couriersSummaryComponent?.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === 'delivered-orders'">
    <ng-container
      *ngTemplateOutlet="deliveredOrdersComponent.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === 'charged-orders'">
    <ng-container
      *ngTemplateOutlet="chargedOrdersComponent.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === 'cancelled-orders'">
    <ng-container
      *ngTemplateOutlet="cancelledOrdersComponent?.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedTabIndex === 'tags-summary'">
    <ng-container
      *ngTemplateOutlet="tagsSummaryComponent?.dynamicContentTemplate"
    ></ng-container>
  </ng-container>
</div>

<div>
  <tabset>
    <tab
      (selectTab)="onTabChange($event)"
      id="global-summary"
      [heading]="'REPORT.global_summary' | translate"
    >
      <app-global-summary></app-global-summary>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      id="courier-summary"
      [heading]="'courier_summary' | translate"
    >
      <app-couriers-summary></app-couriers-summary>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      id="delivered-orders"
      [heading]="'delivered_summary' | translate"
    >
      <app-delivered-orders></app-delivered-orders>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      id="charged-orders"
      [heading]="'REPORT.charged_orders' | translate"
    >
      <app-charged-orders></app-charged-orders>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      id="cancelled-orders"
      [heading]="'REPORT.cancelled_orders' | translate"
    >
      <app-cancelled-orders></app-cancelled-orders>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      id="tags-summary"
      [heading]="'tag_summary' | translate"
    >
      <app-tags-summary></app-tags-summary>
    </tab>
  </tabset>
</div>
