<div class="row mt-5" *ngIf="page.length > 0"> 
    <div class="col-2">
       
    </div>
    <div class="col-10">
        <nav aria-label="pagination">
            <ul class="pagination justify-content-end">
                <li  class="page-item m-1">
                    <select class="form-control" [(ngModel)]="countPage" (change)="pageConuts(countPage)">
                        <option>5</option>
                        <option>10</option>
                        <option>20</option>
                        <option>25</option>
                        <option>100</option>
                    </select>
                </li>
                <li class="page-item m-1" *ngFor="let data of page">
                    <button class="btn " [ngClass]=" pageActive ==data.pages ? 'btn-danger' : 'btn-secondary' "
                        type="button" (click)="pageActions(data.pages)">{{data.pages}}</button>
                </li>

            </ul>
        </nav>
    </div>
</div>