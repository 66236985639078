import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-icon',
  templateUrl: './toggle-icon.component.html',
  styleUrls: ['./toggle-icon.component.scss']
})
export class ToggleIconComponent implements OnInit {
  @Input() isActive: boolean;
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() activeIconSrc: string = './assets/img/new-ui/eye_hide.svg';
  @Input() inactiveIconSrc: string = './assets/img/new-ui/eye_show.svg';
  constructor() { }

  ngOnInit(): void {
  }

  toggle() {
    this.isActive = !this.isActive;
    this.isActiveChange.emit(this.isActive);
  }
}
