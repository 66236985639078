<ng-template #dynamicContent>
  <div class="d-flex col-md-6 gap-1">
    <div class="flex-grow-1">
      <form>
        <input
          type="text"
          name="search"
          placeholder="{{ 'CLIENTS.searchDispatch' | translate }}"
          class="animated-search"
          (ngModelChange)="searchDispatch($event)"
          [(ngModel)]="searchEvent"
          [ngModelOptions]="{ standalone: true }"
        />
      </form>
    </div>
    <div>
      <app-button
        [imageSrc]="'./assets/img/new-ui/reset.svg'"
        [buttonText]="'JOBS.reset' | translate"
        (buttonClick)="ngOnInit()"
      ></app-button>
    </div>
  </div>
</ng-template>
<div class="mt-4">
  <app-table
    [theadTemplate]="theadTemplateDispatches"
    [tbodyTemplate]="tbodyTemplateDispatches"
    [noFoundTemplate]="noFoundTemplateDispatches"
    [totalItems]="dispatchCount"
    (onPageChange)="onPageChange($event)"
    [currentPage]="pageNumber"
  ></app-table>
  <ng-template #theadTemplateDispatches>
    <tr>
      <th>{{ "CLIENTS.dispatchName" | translate }}</th>
      <th>{{ "CLIENTS.email" | translate }}</th>
      <th>{{ "CLIENTS.address" | translate }}</th>
      <th>ACTION</th>
    </tr>
  </ng-template>
  <ng-template #tbodyTemplateDispatches>
    <tbody>
      <tr
        *ngFor="
          let dispatch of dispatches
            | paginate
              : {
                  itemsPerPage: 10,
                  currentPage: pageNumber,
                  totalItems: dispatchCount
                }
        "
      >
        <td>
          <div class="media">
            <div
              [ngClass]="dispatch?.companyLogoImg ? 'server-approved-img' : ''"
            >
              <img
                *ngIf="dispatch?.companyLogoImg"
                [src]="dispatch?.companyLogoImg"
                alt="John Doe"
                class="mr-3 rounded-circle"
                style="width: 35px"
              />
            </div>
            <img
              *ngIf="!dispatch?.companyLogoImg"
              src="assets/img/new-ui/avatar_without_image.svg"
              alt="John Doe"
              class="mr-3 rounded-circle"
              style="width: 35px"
            />
            <div class="media-body align-self-center">
              <p class="mb-0">{{ dispatch?.companyName }}</p>
            </div>
          </div>
        </td>
        <td>{{ dispatch?.email ? dispatch?.email : "N/A" }}</td>

        <td>
          {{ dispatch?.companyAddress ? dispatch?.companyAddress : "N/A" }}
        </td>
        <td>
          <ul class="action-icons">
            <li>
              <img
                src="assets/img/new-ui/delete.svg"
                (click)="openRemoveDispatchModal(dispatch._id)"
                ngbTooltip="Remove"
              />
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </ng-template>
  <ng-template #noFoundTemplateDispatches>
    <div class="no-jobs-found">
      <p
        class="no-item text-center text-danger mb-0"
        *ngIf="!dispatches.length"
      >
        {{ "CLIENTS.no_dispatch_found" | translate }}
      </p>
    </div>
  </ng-template>
</div>
<!-- Remove driver modal start-->
<ng-template #deleteDispatchTemplate>
  <div class="text-center">
    {{ "CLIENTS.remove_dispatch_comment" | translate }}
  </div>
</ng-template>

<!-- Remove driver modal end -->
