import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SettingModelInformation } from '../../models/SettingModel';
interface response<T> {
  code: string;
  message: string;
  result: T[];
}
interface responseP extends response<Paramater> { }
interface Paramater {
  name: string;
  value: string;
  description: string;
  statusD: number;
  _id: string;
  createdAt: string;
  updatedAt: string;
  __v: number
}

@Injectable({
  providedIn: 'root'
})
export class ParameterService {
  private baseUri = environment.apiUrl;

  // private baseShop = environment.apiShop;
  // private baseApp = environment.apiApp;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // "Authorization": "Bearer " + JSON.parse(localStorage.getItem("currentUser")).token
    }),


    withCredentials: false,
  };
  constructor(private http: HttpClient) {
  }
  getOneParameter(name: string): Observable<SettingModelInformation> {
    console.log(this.baseUri);
    let queryParameters = new HttpParams();

    queryParameters = queryParameters.append("name", name)
    return this.http.get<SettingModelInformation>(this.baseUri + `/parameters/${name}`,
      { headers: this.httpOptions.headers }).pipe(map(setting => {
        return setting;
      }));
  }
  getValidHour(obj): Observable<any> {
    return this.http.post<any>(this.baseUri + '/parameters/valid/time/urgent/job', {
      ...obj
    }, this.httpOptions);
  }
  getAllGuies(): Observable<any> {
    const lang = JSON.parse(localStorage.getItem('language')) || 'en';
    return this.http.get<any>(this.baseUri + '/parameters/guies').pipe(
      map(data => {
        return data.result
      }),
      map((data) => {
        return data.map(value => {
          if (lang === 'fr') {
            return { "value": value.url, "label": value.language === 'fr' ? `Guide d'utilisation-fr` : `Guide d'utilisation-en` }
          }
          return { "value": value.url, "label": value.language === 'fr' ? `User guide-fr` : `User guide-en` }
        })
      })
    );
  }

  getTermsAndCondition(): Observable<any> {
    const lang = JSON.parse(localStorage.getItem('language')) || 'en';

    return this.http.get<any>(this.baseUri + '/parameters/tc').pipe(
      map(data => {
        return data.result
      }),
      map((data) => {
        return data.map(value => {
          if (lang === 'fr') {
            return { "value": value.url, "label": value.language === 'fr' ? `Termes et conditions-fr` : `Termes et conditions-en` }
          } else {
            return { "value": value.url, "label": value.language === 'fr' ? `Terms and Policy-fr` : `Terms and Policy-en` }
          }
        })
      })
    );
  }

}
