import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  Router
} from '@angular/router';
import * as moment from 'moment';
import 'moment-timezone';
import {
  BsModalRef,
  BsModalService,
  ModalDirective
} from 'ngx-bootstrap/modal';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';
import { ModalComponent } from "../../shared/component/modal/modal.component";
@Component({
  selector: 'app-establishment-customer-info',
  templateUrl: './establishment-customer-info.component.html',
  styleUrls: ['./establishment-customer-info.component.scss']
})
export class EstablishmentCustomerInfoComponent implements OnInit {
  @ViewChild('deleteClientTemplate') deleteClientTemplate: TemplateRef<any>;
  @ViewChild('upsertClientTemplate') upsertClientTemplate: TemplateRef<any>;
  @ViewChild('recurringModel') public recurringModel: ModalDirective;

  @ViewChild('removeModal') public removeModal: ModalDirective;

  allClients = [];
  allClientsCount = 0;
  pageNumber = 1;
  localData: any;
  @Input() establish_cust_type = '1';
  @Input() establish_cust_id: any;
  @Input() show: boolean = false
  @Output() showChange = new EventEmitter<boolean>();
  registerForm: UntypedFormGroup;
  submitted = false;
  residenceLocation: any;
  lat: any;
  long: any;
  lat_long = [];
  iban_no: any;
  phoneNumber: any;
  countryCode: any;
  customer_id: any;
  selectionType: any;
  location = '';
  userSearch = '';
  recursiveJobs = [];
  repeatJob_id: any;
  public language = "en";

  bsModalRef: BsModalRef;
  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en"
    }

    //ADD NEW RESIDENCE FORM 
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: [''],
      email: ['', Validators.email],
      aptNo: [''],
      customer_note: [''],
      customer_parent: [''],
      doorCode: [''],

    });

    this.localData = JSON.parse(localStorage.getItem("currentUser"));

    this.userSearch = '';
    this.getAllClients(this.userSearch, 1);

  }
  //GET ALL CLIENT API START HERE
  getAllClients(searchEvent, pageNumber) {
    this.spinner.show();

    this.services.getAllEstablishmentCustomers(this.localData._id, pageNumber, this.establish_cust_type, searchEvent, this.establish_cust_id).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.allClients = data.result;
        this.allClientsCount = data.count;
        console.log(data, 'allClients request');
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });
  }


  //ON PAGE CHANGE FUNCTION
  onPageChange(page) {
    this.pageNumber = page;
    this.getAllClients(this.userSearch, this.pageNumber);
  }

  //SORT ADDRESS FUNCTION START HERE
  sortAddress(address) {
    const address1 = address.split(',');
    if (address1.length > 5) {
      return address1[0] + ',' + address1[1] + ',' + address1[2] + ',' + address1[3] + ',' + address1[4];
    } else {
      var str = '';
      for (const data of address1) {
        str += data + ',';
      }
      return str.slice(0, -1)
    }
  }

  //SEARCH CLIENT FUNCTION START HERE
  searchClients(event) {
    if (event.length == 0) {
      return this.ngOnInit();
    }
    if (event.length <= 2) {
      return console.log('less then 2');
    }
    this.userSearch = event;
    this.pageNumber = 1;
    this.getAllClients(this.userSearch, this.pageNumber);
  }

  //GO TO BACK PAGE
  back() {
    this.show = false;
    this.showChange.emit(this.show);
  }


  //SEARCH ADDRESS FUNCTION START HERE
  handleAddressChange(address) {
    console.log(address);
    this.residenceLocation = address.formatted_address;
    this.lat = address.geometry.location.lat();
    this.long = address.geometry.location.lng();
    this.lat_long = [this.lat, this.long]
  }

  showClientModal(data = null) {
    this.registerForm.reset();
    this.submitted = false;
    this.phoneNumber = '';
    if (data) {
      this.customer_id = data._id;
      this.phoneNumber = data.phone_no;
      this.countryCode = data.country_code
      this.registerForm.controls['firstName'].setValue(data.customer_f_name);
      this.registerForm.controls['lastName'].setValue(data.customer_l_name);
      this.registerForm.controls['phoneNumber'].setValue(data.phone_no);
      this.registerForm.controls['email'].setValue(data.email);
      this.registerForm.controls['aptNo'].setValue(data.appartment_no);
      this.registerForm.controls['doorCode'].setValue(data.door_code);
      this.registerForm.controls['customer_note'].setValue(data.customer_note);
      this.registerForm.controls['customer_parent'].setValue(data.customer_parent);
    }
    const initialState = {
      content: this.upsertClientTemplate,
      config: {
        title: data ? "CLIENTS.edit_client" : "CLIENTS.add_client",

      },
      onClick: this.upsertClient.bind(this, data),

    };
    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-md modal-dialog-centered",
    });
  }
  upsertClient(data) {

    this.submitted = true;

    if (!this.f.firstName.value?.trim()) {
      return this.toastr.error('', 'Please enter first name');
    }
    else if (!this.f.lastName.value?.trim()) {
      return this.toastr.error('', 'Please enter last name');
    }
    if (this.f.firstName.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.firstName.value);
      if (testing == true) {
        return this.toastr.error('', 'First name not valid!');
      }
    }
    if (this.f.lastName.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.firstName.value);
      if (testing == true) {
        return this.toastr.error('', 'Last name not valid!');
      }
    }

    if (this.f.customer_note.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.customer_note.value);
      if (testing == true) {
        return this.toastr.error('', 'Last name not valid!');
      }

    }

    if (!this.phoneNumber) {
      return this.toastr.error('', 'Please enter phone number');
    }
    if (this.phoneNumber.length != 12) {
      return this.toastr.error('', 'Please enter valid phone number');
    }
    if (this.f.email.value && this.validateEmail(this.f.email.value) == false) {
      return this.toastr.error('', 'Email is not valid!')
    }
    console.log(this.validateEmail(this.f.email.value), 'thissssssssss');
    // stop here if form is invalid
    if (this.registerForm.invalid) {

      return;
    }


    this.spinner.show();
    data ? this.editClient() : this.addNewClient();

  }

  get f() { return this.registerForm.controls; }

  addNewClient() {
    this.services.addNewClient(this.localData._id, this.establish_cust_id, this.f.firstName.value, this.f.lastName.value.toUpperCase(), this.f.email.value, this.localData.country_code, this.phoneNumber, this.f.aptNo.value, this.f.customer_note.value, this.f.doorCode.value, this.establish_cust_type).pipe().subscribe(data => {

      console.log("entro en suscribe", data);
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.userSearch = "";
        this.bsModalRef.hide();
        this.getAllClients(this.userSearch, this.pageNumber);
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });
  }

  //UPDATE MOBILE NUMBER FUNCTION START HERE
  mychange(val) {
    console.log(typeof val)
    const self = this;
    let chIbn = val.split('-').join('');
    if (chIbn.length > 0) {
      // chIbn = chIbn.match(new RegExp('.{1,3}', 'g')).join('-');
      chIbn = chIbn.match(/\d{3}(?=\d{2,3})|\d+/g).join("-");
    }
    console.log(chIbn);
    this.phoneNumber = chIbn;
  }

  //VALIDATE EMAIL FUNCTION
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  //EDIT CLIENT API START HERE
  editClient() {
    this.services.editClient(this.localData._id, this.customer_id, this.f.email.value, this.localData.country_code, this.phoneNumber, this.f.aptNo.value, this.f.doorCode.value, this.f.customer_note.value, this.f.customer_parent.value, this.f.firstName.value, this.f.lastName.value.toUpperCase(), this.establish_cust_type, this.location, this.lat_long).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.bsModalRef.hide();
        this.getAllClients(this.userSearch, this.pageNumber);
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });
  }


  //DELETE CLIENT CONFIRMATION MODEL
  openDeleteModel(id) {
    this.customer_id = id;
    const initialState = {
      content: this.deleteClientTemplate,
      config: {
        title: "Confirmation",
        action: "CREATEJOB.yes",
        cancel: "CREATEJOB.no"
      },
      onClick: this.deleteExistClient.bind(this),

    };
    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-sm modal-dialog-centered",
    });
  }

  deleteExistClient() {
    this.spinner.show();
    this.services.deleteResidenceClient(this.localData._id, this.customer_id).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.bsModalRef.hide();
        this.getAllClients(this.userSearch, this.pageNumber);
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });
  }

  openRecursiveModal(recursiveJobs) {
    this.recursiveJobs = recursiveJobs;
    this.recurringModel.show();

  }

  modifyDate(str, time) {
    const mydate = str.split('T')[0]
    const convert = mydate.split('-')
    var date = convert[1] + '/' + convert[2] + '/' + convert[0];
    var dateTime = moment(date + " " + time.hours + ":" + time.min, 'MM/DD/YYYY HH:mm').format("MM/DD/YYYY HH:mm")
    return dateTime;
  }

  removeModalOpen(id) {
    this.repeatJob_id = id;
    console.log("id:", id)
    this.removeModal.show();
  }


  removeJob() {
    if (this.repeatJob_id) {
      this.spinner.show();
      this.services.removeRecursiveJob(this.repeatJob_id).pipe().subscribe(data => {
        if (data.code == 400) {
          this.spinner.hide();
          this.toastr.error('', data.message);
          this.logoutFunction.logout();
          return;
        }
        if (data.code == 200) {
          this.removeModal.hide();
          this.recurringModel.hide();
          this.getAllClients(this.userSearch, this.pageNumber);
          // this.ngOnInit();
          this.toastr.success('', 'Recursive job removed successfully');
          this.spinner.hide();

        } else {
          this.spinner.hide();
          this.toastr.error('error', data.message);

        }

      });

    }

  }
}