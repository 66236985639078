import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientComponent } from '../client/client.component';
import { MedicalCenterComponent } from '../medical-center/medical-center.component';
import { OthersComponent } from '../others/others.component';
import { ResidenceComponent } from '../residence/residence.component';
@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  selectedTabIndex = '1';
  @ViewChild(ResidenceComponent) residenceComponent: ResidenceComponent;
  @ViewChild(ClientComponent) clientComponent: ClientComponent;
  @ViewChild(MedicalCenterComponent) medicalCenterComponent: MedicalCenterComponent;
  @ViewChild(OthersComponent) othersComponent: OthersComponent;

  constructor() { }
  residence = "1";
  client = "2";
  medicalCenter = "3";
  others = "4";
  ngOnInit(): void {


  }
  onTabChange(event) {
    this.selectedTabIndex = event.id;
  }

}