<div bsModal #notificationProfile="bs-modal" [config]="{ backdrop: 'static' ,keyboard : false}" class="modal fade"
  tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-warning" role="document" style="width: 40%;">
    <div class="modal-content">
      <div class="modal-header" style="background: #50D99A;">
        <h4 class="modal-title" style="text-align: center;">Update profile</h4>
        <button type="button" style="padding: 0.3rem 1rem; border: none;
        background: transparent;outline: none;" class="close" (click)="notificationProfile.hide()" aria-label="Close">
          <span style="color: #FFF; font-size: 20px;" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <strong>
          {{ 'EDITPROFILE.edit_profile_fax' | translate }}
        </strong>
      </div>
      <div class="modal-footer" style="margin: auto;">
        <button type="button" class="btn btn-secondary" (click)="notificationProfile.hide()">
          {{'DASHBOARD.cancel' | translate}}
        </button>
        <a class="btn btn-success" (click)="redirectToUpdateProfile()">
          {{'DASHBOARD.update_profile' | translate}}
        </a>
      </div>
    </div>
  </div>
</div>