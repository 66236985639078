import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class RouteService {
  private currentRoute: string = "";

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }
  getCurrentRoute(): string {
    return this.currentRoute;
  }
  savePreviousRoute() {
    sessionStorage.setItem("previousRoute", this.currentRoute);
  }

  getPreviousRoute(): string {
    return sessionStorage.getItem("previousRoute");
  }

  // Save filter settings to sessionStorage
  saveFilterSettings(data: any) {
    const existingFilterModel = JSON.parse(sessionStorage.getItem("filters") || "{}");

    // Add the new data to the existing filterModel using the spread operator
    const updatedFilterModel = {
      ...existingFilterModel,
      ...data
    };

    // Save the updated filterModel to session storage
    sessionStorage.setItem("filters", JSON.stringify(updatedFilterModel));

  }


  // Load filter settings from sessionStorage
  loadFilterSettings() {
    const savedFilterSettings = sessionStorage.getItem("filters");
    return savedFilterSettings ? JSON.parse(savedFilterSettings) : null;
  }

  // Save pagination settings to sessionStorage
  savePaginationSettings(pageNumber: number) {
    const paginationSettings = { pageNumber };
    sessionStorage.setItem(
      "currentPage",
      JSON.stringify(paginationSettings)
    );
  }

  // Load pagination settings from sessionStorage
  loadPaginationSettings() {
    const savedPaginationSettings = sessionStorage.getItem(
      "currentPage"
    );
    return savedPaginationSettings ? JSON.parse(savedPaginationSettings) : null;
  }

  clearSettings() {
    // Remove saved filter and pagination settings from sessionStorage
    sessionStorage.removeItem("filters");
    // Remove saved filter from sessionStorage
    sessionStorage.removeItem("filters");

  }

  clearPaginationSettings() {
    // Remove saved pagination settings from sessionStorage
    sessionStorage.removeItem("currentPage");
  }
}
