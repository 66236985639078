import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import {
  Router
} from '@angular/router';
import * as moment from 'moment';
import 'moment-timezone';
import {
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';
import { ModalComponent } from "../../shared/component/modal/modal.component";
import { EstablishmentCustomerInfoComponent } from '../establishment-customer-info/establishment-customer-info.component';
@Component({
  selector: 'app-residence',
  templateUrl: './residence.component.html',
  styleUrls: ['./residence.component.scss']
})
export class ResidenceComponent implements OnInit {
  @Input() customer_type: number = 1;
  @Input() delete_message: string = "CLIENTS.delete_residence_Comment";
  @Input() add_title: string = "CLIENTS.add_residence";
  @Input() edit_title: string = "CLIENTS.edit_residence";
  @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
  @ViewChild('upsertResidenceTemplate') upsertResidenceTemplate: TemplateRef<any>;
  @ViewChild('deleteResidenceTemplate') deleteResidenceTemplate: TemplateRef<any>;
  @ViewChild('customerInfo') customerInfo: EstablishmentCustomerInfoComponent;
  establishCustomerType: number;
  establishCustomerId: string;
  allResidence = [];
  allResidenceCount = 0;
  pageNumber = 1;
  registerForm: UntypedFormGroup;
  submitted = false;
  lat: any;
  long: any;
  location: any;
  localData: any;
  lat_long = [];
  residenceLocation: any;
  userSearch = '';
  currentPage: any;
  searchEvent = '';
  recursiveJobs = [];
  repeatJob_id: any;
  public language = "en";
  options: any;
  customer_id: any;
  establishment_id: any;
  establishment_name: any;
  bsModalRef: BsModalRef;
  showDetails: boolean = false;

  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.submitted = false;
    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en"
    }
    this.localData = JSON.parse(localStorage.getItem("currentUser"));

    //ADD NEW RESIDENCE FORM 
    this.registerForm = this.formBuilder.group({
      residenceName: ['', Validators.required],
      residenceLocation: ['', Validators.required],
      establishment_email: ['', [Validators.email]],
      customer_note: [''],
    });

    this.getAllResidenceClients();
  }

  //GET ALL RESIDENCE CLIENT API START HERE
  getAllResidenceClients(searchEvent = "", pageNumber = 1) {
    this.spinner.show();
    this.services.getAllClients(this.localData._id, pageNumber, this.customer_type, searchEvent, '0').pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.allResidence = data.result;
        this.allResidenceCount = data.count;
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });
  }


  //ON PAGE CHANGE FUNCTION
  onPageChange(page) {

    this.pageNumber = page;
    this.getAllResidenceClients(this.searchEvent, this.pageNumber);
  }


  //SORT ADDRESS FUNCTION START HERE 
  sortAddress(address) {
    const address1 = address.split(',');
    if (address1.length > 5) {
      return address1[0] + ',' + address1[1] + ',' + address1[2] + ',' + address1[3] + ',' + address1[4];
    } else {
      var str = '';
      for (const data of address1) {
        str += data + ',';
      }
      return str.slice(0, -1)
    }
  }


  //SEARCH ADDRESS FUNCTION START HERE
  handleAddressChange(address) {
    this.residenceLocation = address.formatted_address;
    this.lat = address.geometry.location.lat();
    this.long = address.geometry.location.lng();
    this.lat_long = [this.lat, this.long]
  }

  get f() { return this.registerForm.controls; }

  //VALIDATE EMAIL FUNCTION
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  showResidenceModal(data) {
    this.registerForm.reset();
    this.registerForm.controls['residenceLocation'].enable();
    if (data) {
      this.registerForm.controls['residenceName'].setValue(data.establishment_name);
      this.registerForm.controls['residenceLocation'].setValue(data.location);
      this.registerForm.controls['residenceLocation'].disable();
      this.registerForm.controls['customer_note'].setValue(data.customer_note);
      this.registerForm.controls['establishment_email'].setValue(data.establishment_email);
      this.establishment_id = data._id;
    }
    const initialState = {
      content: this.upsertResidenceTemplate,
      config: {
        title: data ? this.edit_title : this.add_title,

      },
      onClick: this.upsertResidence.bind(this, data),

    };
    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-md modal-dialog-centered",
    });
  }
  // ADD NEW RESIDENCE FUNCTION
  upsertResidence(data) {
    this.submitted = true;

    if (!this.f.residenceName.value?.trim()) {
      return this.toastr.error('', 'Please enter Residence name');
    }
    else if (!this.f.residenceLocation.value?.trim()) {
      return this.toastr.error('', 'Please enter Residence location');
    }
    else if (this.f.residenceName.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.residenceName.value);
      if (testing == true) {
        return this.toastr.error('', 'Residence name not valid!');
      }
    } else if (this.f.customer_note.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.customer_note.value);
      if (testing == true) {
        return this.toastr.error('', 'Note not valid!');
      }
    }

    if (this.f.establishment_email.value && this.validateEmail(this.f.establishment_email.value) == false) {
      return this.toastr.error('', 'Email is not valid!')
    }

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    const establishment_email_aux = !this.f.establishment_email.value ? 'default@establishment.com' : this.f.establishment_email.value.trim();

    this.spinner.show();
    data ? this.editExistResidence(establishment_email_aux) : this.addNewResidence(establishment_email_aux);

  }

  addNewResidence(establishment_email_aux) {
    this.services.addNewResidence(this.localData._id, this.f.residenceName.value?.trim(), this.f.customer_note.value?.trim(), this.f.customer_parent.value, this.customer_type, this.residenceLocation, this.lat_long, establishment_email_aux).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.submitted = false;
        this.bsModalRef.hide();
        this.pageNumber = 1;
        this.searchEvent = '';
        this.userSearch = "";
        this.getAllResidenceClients(this.searchEvent, this.pageNumber);
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });
  }

  //FUNCTION FOR UPDATE RESIDENCE NAME
  editExistResidence(establishment_email_aux) {

    this.services.editEstablishment(this.localData._id, this.establishment_id, this.f.residenceName.value?.trim(), this.f.customer_note.value?.trim(), this.f.customer_parent.value, this.customer_type, establishment_email_aux).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.submitted = false;
        this.bsModalRef.hide();
        this.getAllResidenceClients(this.searchEvent, this.pageNumber);
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });
  }

  searchResidence(event) {
    this.pageNumber = 1;

    this.searchEvent = event;
    this.getAllResidenceClients(event, this.pageNumber);
  }

  goToCustomerInfo(id, no_of_customer) {
    if (no_of_customer) {
      this.establishCustomerId = id;
      this.showDetails = true

    }
  }


  modifyDate(str, time) {
    const mydate = str.split('T')[0]
    const convert = mydate.split('-')
    var date = convert[1] + '/' + convert[2] + '/' + convert[0];
    var dateTime = moment(date + " " + time.hours + ":" + time.min, 'MM/DD/YYYY HH:mm').format("MM/DD/YYYY HH:mm")
    return dateTime;
  }





  //DELETE CLIENT CONFIRMATION MODEL
  openDeleteModel(id) {
    this.establishment_id = id;

    const initialState = {
      content: this.deleteResidenceTemplate,
      config: {
        title: "Confirmation",
        action: "CREATEJOB.yes",
        cancel: "CREATEJOB.no"
      },
      onClick: this.deleteExistResidence.bind(this),

    };
    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-sm modal-dialog-centered",
    });
  }


  //DELETE EXIST RESIDENCE FUNCTION START HERE
  deleteExistResidence() {
    this.spinner.show();
    this.services.deleteResidence(this.localData._id, this.establishment_id).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.bsModalRef.hide();
        this.getAllResidenceClients(this.searchEvent, this.pageNumber);
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    });
  }

}