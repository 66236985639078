<div class="row justify-content-between scp-breadcrumb mb-4">
  <div class="b-jobs">
    <a>{{ "invoices" | translate }} </a>
  </div>

  <div>
    <app-button
      [imageSrc]="'./assets/img/new-ui/dowload_as_pdf.svg'"
      [buttonText]="'INVOICES.download_as_pdf' | translate"
      (buttonClick)="getPdfByDate()"
    ></app-button>
    <app-button
      *ngIf="pendingAmount"
      [buttonText]="'INVOICES.request_for_payment' | translate"
      (buttonClick)="successModal1.show()"
    ></app-button>
  </div>
</div>
<div class="row justify-content-center">
  <div class="row r-width mb-3">
    <div class="col-12">
      <p class="total-earn text-body">
        {{ "INVOICES.pending_amount" | translate }} : ${{
          pendingAmount | number : "1.2-2"
        }}
      </p>
      <p class="text-success pay-received">
        {{ "INVOICES.last_pay_recive_on" | translate }} :
        {{ lastPaidOn | date : "EEE yyyy/dd/MM H:mm" }}
      </p>
    </div>
    <div class="d-flex col-12 gap-1 flex-wrap">
      <div>
        <form class="form-inline">
          <div class="form-group">
            <div class="">
              <label for="sel1" class="mr-2 pay-sort-cont fw-bold">{{
                "INVOICES.sort_by" | translate
              }}</label>
            </div>
            <select
              class="ml-2 h-45 select-sort"
              id="sel1"
              name="sellist1"
              [(ngModel)]="type"
              [ngModelOptions]="{ standalone: true }"
              (change)="filter($event)"
            >
              <option [value]="0">{{ "INVOICES.all" | translate }}</option>
              <option [value]="1">{{ "INVOICES.paid" | translate }}</option>
              <option [value]="2">{{ "INVOICES.pending" | translate }}</option>
              <option [value]="3">
                {{ "INVOICES.inprocess" | translate }}
              </option>
            </select>
          </div>
        </form>
      </div>

      <app-custom-calendar-datepickers
        [(startDate)]="startDate"
        [(endDate)]="endDate"
        [(fromDate)]="sDate"
        [(toDate)]="eDate"
        (getData)="SearchByDate()"
        [showDefaultDate]="false"
        [pageNumber]="p1"
      ></app-custom-calendar-datepickers>

      <div>
        <app-button
          [imageSrc]="'./assets/img/new-ui/filter_reset.svg'"
          [buttonText]="'INVOICES.reset' | translate"
          (buttonClick)="resetPage()"
        ></app-button>
        <app-button
          [imageSrc]="'./assets/img/new-ui/send_pdf.svg'"
          [buttonText]="'INVOICES.send_pdf' | translate"
          (buttonClick)="sendPdfPharmacy()"
        ></app-button>
      </div>
    </div>
  </div>
</div>
<div>
  <ng-template #dynamicContent>
    <div class="d-flex col-md-6 gap-1">
      <div class="flex-grow-1">
        <form>
          <input
            type="text"
            name="search"
            placeholder="{{ 'CLIENTS.search_driver' | translate }}"
            class="animated-search"
            (ngModelChange)="searchDrivers($event)"
            [(ngModel)]="searchEvent"
            [ngModelOptions]="{ standalone: true }"
          />
        </form>
      </div>

      <div>
        <app-button
          [imageSrc]="'./assets/img/new-ui/reset.svg'"
          [buttonText]="'JOBS.reset' | translate"
          (buttonClick)="ngOnInit()"
        ></app-button>
      </div>
      <div>
        <app-button
          [imageSrc]="'./assets/img/new-ui/connect_driver.svg'"
          [buttonText]="'CLIENTS.connect_driver' | translate"
          (buttonClick)="openRequestModal()"
        ></app-button>
      </div>
    </div>
  </ng-template>

  <div class="mt-3">
    <app-table
      [theadTemplate]="theadTemplateInvoices"
      [tbodyTemplate]="tbodyTemplateInvoices"
      [noFoundTemplate]="noFoundTemplateInvoices"
      [totalItems]="jobsCount"
      (onPageChange)="onPageChange($event)"
      [currentPage]="p1"
    ></app-table>
    <ng-template #theadTemplateInvoices>
      <tr>
        <th scope="col">№</th>
        <th scope="col">{{ "INVOICES.pharmacy" | translate }}</th>
        <th scope="col">{{ "INVOICES.customer_info" | translate }}</th>
        <th scope="col">{{ "INVOICES.destination" | translate }}</th>
        <th scope="col">{{ "INVOICES.payment" | translate }}</th>
      </tr>
    </ng-template>
    <ng-template #tbodyTemplateInvoices>
      <tbody>
        <tr
          *ngFor="
            let payment of invoce_jobs
              | paginate
                : { itemsPerPage: 10, currentPage: p1, totalItems: jobsCount };
            let i = index
          "
          class="vertical-align-top"
        >
          <td data-label="No">{{ i + 1 }}</td>
          <td data-label="Pharmacy Name">
            <div class="media">
              <div class="media-body align-self-center">
                <h5 class="mt-0 mb-0 fw-bold">
                  {{ payment?.pharmacy_id.pharmacy_name }}
                </h5>
                <span class="invoice-dtl-mail fw-bold"
                  >{{ "INVOICES.date" | translate }}:
                </span>
                <span>{{ modifyDate(payment?.job_id.job_date) }}</span>
                <p class="invoice-dtl-cutomer-info">
                  <span class="fw-bold"
                    >{{ "INVOICES.job_code" | translate }}:
                  </span>
                  {{ payment?.job_id.booking_code }}
                </p>
              </div>
            </div>
          </td>
          <td data-label="Customer Name">
            <h5
              *ngIf="payment?.job_id.customer_type == '2'; else nameElse"
              class="mt-0 mb-0 fw-bold"
            >
              {{
                payment?.job_id.customer_f_name
                  ? payment?.job_id.customer_l_name +
                    " " +
                    payment?.job_id.customer_f_name
                  : payment?.job_id.customer_name
              }}
            </h5>
            <ng-template #nameElse>
              <h5 class="mt-0 mb-0">
                {{
                  payment?.job_id.establishment_name
                    ? payment?.job_id.establishment_name
                    : payment?.job_id.customer_name
                }}
              </h5>
            </ng-template>
            <p
              *ngIf="payment?.job_id.job_type == '1'"
              class="invoice-dtl-cutomer-info mb-1"
            >
              <span class="fw-bold"
                >{{ "INVOICES.address" | translate }}:
              </span>
              {{ payment?.job_id.drop_off_location }}
            </p>
            <p
              *ngIf="payment?.job_id.job_type == '2'"
              class="invoice-dtl-cutomer-info mb-1"
            >
              {{ payment?.job_id.pick_up_location }}
            </p>
            <p
              *ngIf="payment?.job_id.customer_email"
              class="invoice-dtl-cutomer-info mb-1"
            >
              <span class="fw-bold">{{ "REPORT.email" | translate }}: </span>
              {{ payment?.job_id.customer_email }}
            </p>
            <p class="invoice-dtl-cutomer-info mb-1">
              <span class="fw-bold">{{ "INVOICES.phone" | translate }}: </span>
              {{ payment?.job_id.customer_country_code }}
              {{ payment?.job_id.customer_phone }}
            </p>
            <p *ngIf="payment?.driver_id" class="invoice-dtl-cutomer-info mb-1">
              <span class="fw-bold">{{ "INVOICES.driver" | translate }}: </span>
              {{ payment?.driver_id.fullname }} ({{
                payment?.driver_id.username
              }})
            </p>
            <p
              *ngIf="!payment?.driver_id"
              class="invoice-dtl-cutomer-info mb-1"
            >
              N/A
            </p>
            <p
              *ngIf="payment?.payment_method == '1'"
              class="invoice-dtl-cutomer-info"
            >
              <span class="fw-bold"
                >{{ "INVOICES.paid_by" | translate }}:
              </span>
              Card
            </p>
            <p
              *ngIf="payment?.payment_method == '2'"
              class="invoice-dtl-cutomer-info"
            >
              <span class="fw-bold"
                >{{ "INVOICES.paid_by" | translate }}:
              </span>
              Cash
            </p>
            <p
              *ngIf="payment?.payment_method == '3'"
              (click)="cheque_img(payment.cheque_img)"
              class="invoice-dtl-cutomer-info"
            >
              <span class="fw-bold"
                >{{ "INVOICES.paid_by" | translate }}:
              </span>
              <span class="cheque">Cheque</span>
            </p>
          </td>
          <td class="dest" data-label="Pickup Location">
            <div class="media">
              <!-- <i class="fa fa-map-marker map-marker-icon" aria-hidden="true"></i> -->
              <div class="media-body align-self-center">
                <p class="mb-2 invoice-dtl-mail">
                  <span class="fw-bold"
                    >{{ "INVOICES.from" | translate }}:
                  </span>
                  {{ payment?.job_id.pick_up_location }}
                </p>
              </div>
            </div>
            <div class="media">
              <!-- <i
              class="fa fa-map-marker map-marker-icon text-danger"
              aria-hidden="true"
            ></i> -->
              <div class="media-body align-self-center">
                <p class="mb-2 invoice-dtl-mail">
                  <span class="fw-bold">{{ "INVOICES.to" | translate }}: </span>
                  {{ payment?.job_id.drop_off_location }}
                </p>
              </div>
            </div>
          </td>
          <td data-label="Amount">
            <p _ngcontent-lkv-c1="" class="driver-transaction-amt mb-0">
              ${{ payment?.job_id.collected_amount | number : "1.2-2" }}
            </p>
            <p>
              {{ "JOBDETAILS.item_price" | translate }}: ${{
                payment?.job_id.job_amount | number : "1.2-2"
              }}
            </p>
            <p
              *ngIf="
                payment?.paid == false &&
                payment?.settled == true &&
                payment?.payment_method != '1'
              "
              class="pending-status-icon"
            >
              <!-- <i
              class="fa fa-clock-o"
              aria-hidden="true"
              style="font-size: 18px; width: 100%"
            ></i
            > -->
              {{ "INVOICES.pending_at_driver" | translate }}
            </p>
            <p
              *ngIf="
                payment?.paid == false &&
                payment?.settled == true &&
                payment?.payment_method == '1'
              "
              class="pending-status-icon"
            >
              <!-- <i
              class="fa fa-clock-o"
              aria-hidden="true"
              style="font-size: 18px; width: 100%"
            ></i> -->
              {{ "INVOICES.ready_for_withdrawl" | translate }}
            </p>

            <p
              *ngIf="payment?.paid == true && payment?.settled == true"
              class="paid-status-icon"
            >
              <!-- <i
              class="fa fa-check-circle-o"
              aria-hidden="true"
              style="font-size: 18px; width: 100%"
            ></i> -->
              {{ "INVOICES.paid" | translate }}
            </p>
            <p
              *ngIf="payment?.paid == false && payment?.settled == false"
              class="process-status-icon"
            >
              <!-- <i
              class="fa fa-hourglass-start"
              aria-hidden="true"
              style="font-size: 18px; width: 100%"
            ></i> -->
              {{ "INVOICES.inprocess" | translate }}
            </p>
          </td>
        </tr>
      </tbody>
    </ng-template>

    <ng-template #noFoundTemplateInvoices>
      <div class="no-jobs-found">
        <p
          class="no-item text-center text-danger mb-0"
          *ngIf="!invoce_jobs.length"
        >
          {{ "INVOICES.no_transactions" | translate }}
        </p>
      </div>
    </ng-template>
  </div>

  <!-------request driver model start here---->
  <ng-template #requestHeaderTemplate>
    <div class="d-flex col-md-5 ml-auto gap-1">
      <div class="flex-grow-1">
        <form>
          <input
            type="text"
            name="search"
            placeholder="{{ 'CLIENTS.search_driver' | translate }}"
            class="animated-search"
            (ngModelChange)="searchIndividualDrivers($event)"
            [ngModel]="searchEventIndividualDrivers"
            [ngModelOptions]="{ standalone: true }"
          />
        </form>
      </div>
      <app-button
        [imageSrc]="'./assets/img/new-ui/reset.svg'"
        [buttonText]="'JOBS.reset' | translate"
        (buttonClick)="resetIndividualModal()"
      ></app-button>
    </div>
  </ng-template>

  <ng-template #requestTemplate>
    <ng-template #theadTemplateIndividualDrivers>
      <tr>
        <th>{{ "CLIENTS.driver_name" | translate }}</th>
        <th>{{ "CLIENTS.email" | translate }}</th>
        <th>{{ "CLIENTS.username" | translate }}</th>
        <th>{{ "CLIENTS.secret_code" | translate }}</th>
        <!-- <th>{{ 'CLIENTS.phone_no' | translate }}</th> -->
        <th>ACTION</th>
      </tr>
    </ng-template>

    <ng-template #tbodyTemplateIndividualDrivers>
      <tbody>
        <tr
          *ngFor="
            let driver of individualDrivers
              | paginate
                : {
                    itemsPerPage: 10,
                    currentPage: pageNumberForIndividualDrivers,
                    totalItems: individualDriversCount,
                    id: 'second'
                  }
          "
        >
          <td>
            <div class="media">
              <div [ngClass]="driver?.profile_img ? 'server-approved-img' : ''">
                <img
                  *ngIf="driver?.profile_img"
                  [src]="driver?.profile_img"
                  alt="John Doe"
                  class="mr-3 rounded-circle"
                  style="width: 35px"
                />
              </div>
              <img
                *ngIf="!driver?.profile_img"
                src="assets/img/new-ui/avatar_without_image.svg"
                alt="John Doe"
                class="mr-3 rounded-circle"
                style="width: 35px"
              />
              <div class="media-body align-self-center">
                <p class="mb-0">{{ driver?.fullname }}</p>
              </div>
            </div>
          </td>
          <td>{{ driver?.email }}</td>
          <td>{{ driver?.username }}</td>
          <td>
            <input
              placeholder="{{ 'CLIENTS.enter_code' | translate }}"
              maxlength="6"
              oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').replace(/(\..*)\./g, '$1');"
              class="form-control"
              [(ngModel)]="driver.secretCode"
              [ngModelOptions]="{ standalone: true }"
            />
          </td>
          <!-- <td>{{driver?.country_code}} {{driver?.mobile_no}}</td> -->

          <td>
            <ul class="action-icons">
              <li>
                <img
                  src="assets/img/new-ui/add.svg"
                  (click)="sendRequestToDriver(driver)"
                  [ngbTooltip]="'CLIENTS.connect' | translate"
                />
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </ng-template>

    <ng-template #noFoundTemplateIndividualDrivers>
      <div class="no-jobs-found">
        <p
          class="no-item text-center text-danger mb-0"
          *ngIf="!individualDrivers.length"
        >
          {{ "CLIENTS.no_drivers" | translate }}
        </p>
      </div>
    </ng-template>
    <div class="sub-title left-sub-title">
      {{ "CLIENTS.search_driver" | translate }}
    </div>
    <app-table
      [theadTemplate]="theadTemplateIndividualDrivers"
      [tbodyTemplate]="tbodyTemplateIndividualDrivers"
      [noFoundTemplate]="noFoundTemplateIndividualDrivers"
      [totalItems]="individualDriversCount"
      (onPageChange)="onIndividualDriversPageChange($event)"
    ></app-table>
  </ng-template>

  <!-- /.request driver model end here -->
</div>

<div
  bsModal
  #successModal1="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-success" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ "INVOICES.confirmation" | translate }}</h4>
        <button
          type="button"
          class="close"
          (click)="successModal1.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ "INVOICES.modal_text" | translate }}</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="successModal1.hide()"
        >
          No
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="request_payment()"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #successModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-default" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          (click)="successModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-12 text-center">
          <img class="img-fluid img-c rounded" [src]="selected_image" />
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="successModal.hide()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
