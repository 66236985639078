<div
  class="app-body body-bg"
  style="
    background-image: url(assets/img/lg-bg-min.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  "
>
  <main class="main d-flex align-items-center">
    <div class="container-fluid">
      <div class="text-right pt-2">
        <label
          ><strong><i class="fa fa-globe"></i> Language :</strong>
        </label>
        <select #langSelect (change)="switchLang(langSelect.value)">
          <option
            *ngFor="let lang of ts.getLangs()"
            [value]="lang"
            [selected]="lang === ts.currentLang"
          >
            <span *ngIf="lang == 'en'"> English </span>
            <span *ngIf="lang == 'fr'"> Français </span>
          </option>
        </select>
      </div>
      <div class="row">
        <!-- <div class="col-md-2"></div> -->
        <div class="col-md-6 register-box">
          <div class="card mx-4">
            <div class="card-body p-4">
              <div class="logo-box text-center">
                <img
                  class="navbar-brand-full ng-star-inserted"
                  src="assets/img/vaistatlogo-2.png"
                  width="150"
                  alt="CoreUI Logo"
                />
              </div>
              <h2 class="main-heading-forgot text-center">
                {{ "REGISTER.pharmacy_register" | translate }}
              </h2>
              <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <label>{{ "REGISTER.pharmacy_name" | translate }}</label>
                  <input
                    ngx-google-places-autocomplete
                    [options]="pharmacyNameOptions"
                    type="text"
                    formControlName="pharmacy_name"
                    class="form-control"
                    id="pharmacy_name"
                    placeholder="Enter pharmacy name first"
                    #placesRef="ngx-places"
                    (onAddressChange)="handleNameChange($event)"
                    [ngClass]="{
                      'is-invalid': submitted && f.pharmacy_name.errors
                    }"
                  />
                  <div
                    *ngIf="submitted && f.pharmacy_name.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.pharmacy_name.errors.required">
                      {{ "REGISTER.name_req" | translate }}
                    </div>
                    <div *ngIf="f.pharmacy_name.errors.pattern">
                      {{ "REGISTER.name_valid" | translate }}
                    </div>
                    <div *ngIf="f.pharmacy_name.errors.minlength">
                      {{ "REGISTER.name_req_char" | translate }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>{{ "REGISTER.email" | translate }}</label>
                  <input
                    type="text"
                    formControlName="email"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                  />
                  <div
                    *ngIf="submitted && f.email.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.email.errors.required">
                      {{ "REGISTER.email_req" | translate }}
                    </div>
                    <div *ngIf="f.email.errors.email">
                      {{ "REGISTER.valid_email" | translate }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>{{ "REGISTER.password" | translate }}</label>
                  <input
                    type="password"
                    formControlName="password"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                  />
                  <div
                    *ngIf="submitted && f.password.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.password.errors.required">
                      {{ "REGISTER.pass_req" | translate }}
                    </div>
                    <div *ngIf="f.password.errors.minlength">
                      {{ "REGISTER.pass_req_start" | translate }}
                    </div>
                    <div *ngIf="f.password.errors.maxlength">
                      {{ "REGISTER.pass_req_end" | translate }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>{{ "REGISTER.repeat_pass" | translate }}</label>
                  <input
                    type="password"
                    formControlName="r_password"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid':
                        submitted && f.r_password.value != f.password.value
                    }"
                  />
                  <div *ngIf="submitted" class="invalid-feedback">
                    <div *ngIf="f.password.value != f.r_password.value">
                      {{ "REGISTER.repeat_pass_req" | translate }}
                    </div>
                  </div>
                </div>
                <div class="form-group"></div>
                <div class="form-group mt-4">
                  <label>{{ "REGISTER.location" | translate }}</label>
                  <input
                    ngx-google-places-autocomplete
                    #placesRef="ngx-places"
                    [options]="options"
                    placeholder="{{ 'REGISTER.enter_location' | translate }}"
                    (onAddressChange)="handleAddressChange($event)"
                    formControlName="location1"
                    class="form-control"
                    type="text"
                    id="location1"
                    value=""
                    [ngClass]="{
                      'is-invalid': submitted && f.location1.errors
                    }"
                  />
                  <div
                    *ngIf="submitted && f.location1.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.location1.errors.required">
                      {{ "REGISTER.location_req" | translate }}
                    </div>
                  </div>
                </div>
                <label>{{ "REGISTER.phone_no" | translate }}</label>
                <ngx-intl-tel-input
                  class="form-control remove-input country-field"
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="false"
                  [enablePlaceholder]="false"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.Canada"
                  [maxLength]="12"
                  [phoneValidation]="true"
                  (ngModelChange)="onChange1($event)"
                  [ngModel]="iban_no"
                  [ngModelOptions]="{ standalone: true }"
                  [separateDialCode]="separateDialCode"
                ></ngx-intl-tel-input>
                <br />
                <div class="form-group">
                  <label>{{ "REGISTER.landline_number" | translate }}</label>
                  <input
                    type="text"
                    class="form-control"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    [ngModel]="pharmacy_landline_num"
                    [ngModelOptions]="{ standalone: true }"
                    (ngModelChange)="onChange2($event)"
                    maxlength="12"
                  />
                </div>
                <div class="form-group">
                  <label>{{ "REGISTER.fax_country_code" | translate }}</label>
                  <input
                    type="number"
                    class="form-control"
                    formControlName="fax_country_code"
                  />
                </div>
                <div class="form-group">
                  <label>{{ "REGISTER.fax_number" | translate }}</label>
                  <input
                    type="number"
                    max="12"
                    class="form-control"
                    formControlName="fax_number"
                  />
                </div>
                <div class="form-group">
                  <label>{{ "REGISTER.apartment_suite" | translate }}</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="apartment_suite"
                  />
                </div>
                <div class="form-group">
                  <label>{{ "REGISTER.suite_number" | translate }}</label>
                  <input
                    type="number"
                    class="form-control"
                    formControlName="suite_number"
                  />
                </div>
                <div class="form-group pt-4">
                  <button type="submit" class="btn btn-block btn-success">
                    <strong>{{ "REGISTER.create_account" | translate }}</strong>
                  </button>
                </div>
              </form>
            </div>
            <div class="form-group text-right col-12">
              <p>
                {{ "REGISTER.already_acc" | translate
                }}<a class="btn btn-link" routerlink="/login" href="#/login">{{
                  "REGISTER.login" | translate
                }}</a>
              </p>
              <div *ngIf="selectedLanguage == 'fr'" class="privacy-policies">
                <a
                  href="https://apiuser.vaistat.com/privacyFr"
                  target="_blank"
                  >{{ "LOGIN.terms" | translate }}</a
                >
              </div>
              <div *ngIf="selectedLanguage == 'en'" class="privacy-policies">
                <a
                  href="https://apiuser.vaistat.com/privacyEn"
                  target="_blank"
                  >{{ "LOGIN.terms" | translate }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <!-- Display Google API selected result details -->
        <div class="col-md-6">
          <div class="card mx-4" *ngIf="googleApiResult">
            <div class="card-body p-4">
              <div
                *ngIf="googleApiResult"
                id="selected-result-details"
                style="font-size: 1.1em"
              >
                <h4>
                  {{ "BUSINESS_HOURS.selected_result_title" | translate }}
                </h4>
                <table class="table table-condensed table-hover">
                  <caption>
                    {{
                      "BUSINESS_HOURS.table_caption_text" | translate
                    }}
                  </caption>
                  <tbody>
                    <tr *ngIf="googleApiResult.icon">
                      <td>
                        <strong
                          >{{ "BUSINESS_HOURS.entity_icon_logo" | translate }} :
                        </strong>
                      </td>
                      <td>
                        <img [src]="googleApiResult.icon" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong
                          >{{ "BUSINESS_HOURS.entity_name" | translate }} :
                        </strong>
                      </td>
                      <td>{{ googleApiResult.name }}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong
                          >{{ "BUSINESS_HOURS.entity_id" | translate }} :
                        </strong>
                      </td>
                      <td>{{ googleApiResult.place_id }}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong
                          >{{
                            "BUSINESS_HOURS.entity_business_status" | translate
                          }}
                          :
                        </strong>
                      </td>
                      <td
                        *ngIf="
                          googleApiResult.business_status === 'OPERATIONAL'
                        "
                      >
                        <span class="badge-success">{{
                          googleApiResult.business_status
                        }}</span>
                      </td>
                      <td
                        *ngIf="
                          googleApiResult.business_status !== 'OPERATIONAL'
                        "
                      >
                        <span class="badge-danger">{{
                          googleApiResult.business_status
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong
                          >{{ "BUSINESS_HOURS.entity_address" | translate }} :
                        </strong>
                      </td>
                      <td>{{ googleApiResult.formatted_address }}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong
                          >{{ "BUSINESS_HOURS.entity_vicinity" | translate }} :
                        </strong>
                      </td>
                      <td>{{ googleApiResult.vicinity }}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong
                          >{{
                            "BUSINESS_HOURS.entity_phone_number" | translate
                          }}
                          :
                        </strong>
                      </td>
                      <td>{{ googleApiResult.international_phone_number }}</td>
                    </tr>
                    <tr *ngIf="googleApiResult.website">
                      <td>
                        <strong
                          >{{ "BUSINESS_HOURS.entity_website" | translate }} :
                        </strong>
                      </td>
                      <td>
                        <a [href]="googleApiResult.website">{{
                          googleApiResult.website
                        }}</a>
                      </td>
                    </tr>
                    <tr
                      *ngIf="googleApiResult.current_opening_hours.weekday_text"
                    >
                      <td>
                        <strong
                          >{{
                            "BUSINESS_HOURS.entity_business_hours" | translate
                          }}
                          :
                        </strong>
                      </td>
                      <td>
                        <table class="table-borderless">
                          <tr
                            *ngFor="
                              let day_text of googleApiResult
                                .current_opening_hours.weekday_text
                            "
                          >
                            <td>&nbsp;</td>
                            <td class=".display-6">{{ day_text }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="use-google-result">
                  <button
                    type="button"
                    class="btn btn-success btn-block"
                    (click)="handleFillingForm($event)"
                  >
                    <strong>{{
                      "BUSINESS_HOURS.button_use_informations" | translate
                    }}</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ./Display Google API selected result details -->
      </div>
    </div>
  </main>
</div>
<div *ngIf="cookieStatus == false" class="cookie-banner">
  <p>
    {{ "cookie.message" | translate }}
    <a
      *ngIf="selectedLanguage == 'fr'"
      href="https://apiuser.vaistat.com/privacyFr"
      target="_blank"
      >Politique de confidentialité</a
    >
    <a
      *ngIf="selectedLanguage == 'en'"
      href="https://apiuser.vaistat.com/privacyEn"
      target="_blank"
      >Privacy policy</a
    >
  </p>
  <button type="button" class="btn btn-warning" (click)="changeCookieStatus()">
    {{ "cookie.allow" | translate }}
  </button>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.5)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate"
  [fullScreen]="true"
></ngx-spinner>
