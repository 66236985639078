import { Component, OnInit, ViewChild } from '@angular/core';
import {
  Router
} from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import { PusherServiceService } from '../../pusher-service.service';
import {
  ServicesService
} from '../../services.service';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  public allOrders = [];
  allOrdersCount = 0;
  currentPage: 0;
  submitted = false;
  page = 1;
  public language = "en";
  sort = 'createAt';
  sortDirection = -1;
  filter = '';
  localData: any;
  customer_id: any;
  pageNumber: any;
  user_id = '';

  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    private pusherService: PusherServiceService,
    public router: Router,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {

    // this.sort = 1;
    this.submitted = false;

    let localData = JSON.parse(localStorage.getItem("currentUser"));




    this.user_id = localData._id;


    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en"
    }

    this.page = 1;
    this.filter = '';

    this.getAllOrder(this.user_id, this.page);
    this.initPusher();

  }

  // ngOnDestroy(): void {
  //   this.pusherService.removeChannel(environment.CHANNEL_CHANGE_STATUS_JOB + '_' + this.user_id);
  // }

  initPusher() {
    const orderChannel = this.pusherService.init(environment.CHANNEL_CHANGE_STATUS_JOB + '_' + this.user_id) as unknown as { bind?: any };
    if (orderChannel.bind) {
      orderChannel.bind('new_order', data => {
        // console.log('event JOB ACTIVED', data);
        this.getAllOrder(this.user_id, 1);
        this.page = 1;
      })


    }

  }

  //SEARCH CLIENTS FUNCTION START HERE
  searchOrders(event) {
    if (event.length == 0) {
      return this.ngOnInit();
    }
    if (event.length <= 0) {
      return console.log('less then 2');
    }

    this.filter = event;
    this.page = 1;
    // this.sort = 1;
    this.getAllOrder(this.user_id, this.page);
  }


  //ON PAGE CHANGE FUNCTION
  onPageChange(event) {
    this.page = event;
    this.getAllOrder(this.user_id, this.page);
  }

  getAllOrder(user_id, page) {

    this.spinner.show();
    this.services.ordersAll(user_id, page).pipe().subscribe(data => {

      this.spinner.hide();
      this.allOrders = data.result;
      this.allOrdersCount = data.count;
      this.pageNumber = data.currentPage

    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });

  }

  @ViewChild('convertModal') public convertModal: ModalDirective;
  jobIdSelected: string = null;
  openConvertToJobModal(idJob: string) {
    this.jobIdSelected = idJob;
    this.convertModal.show();
  }

  convertToJob() {
    const lang = localStorage.getItem('language');
    this.spinner.show();
    this.services.convertOrderToJob(this.jobIdSelected).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.convertModal.hide();
        this.toastr.success('Success', lang == '"fr"' ? 'Commande convertie en job avec succès' : 'Order converted to job successfully');
        this.spinner.hide();

        // this.getAllOrder(this.filter, this.page, this.sort);
        this.redirectEditJob(data?.job?._id);

      } else {
        this.spinner.hide();
        this.toastr.error('Error', data.message);
      }
    })
  }

  redirectEditJob(id: string) {
    localStorage.setItem('edit_job_id', JSON.stringify(id));
    this.router.navigate(['/edit-job']);
  }

  getStatusJob(status: string) {
    const lang = localStorage.getItem('language');
    switch (status) {
      case 'A':
        return lang === '"fr"' ? 'Emploi actif' : 'Job active';
      case 'C':
        return lang === '"fr"' ? 'Emploi complet' : 'Job complete';
      case 'N':
        return lang === '"fr"' ? 'Emploi non livrés' : 'Job not delivered';
      case 'D':
        return lang === '"fr"' ? 'Emploi déclin' : 'Job decline';
      case 'T':
        return lang === '"fr"' ? 'Emploi transfert' : 'Job transfer';
      case 'CN':
        return lang === '"fr"' ? 'Emploi annulé' : 'Job cancel';
      default:
        return lang === '"fr"' ? 'pas de statut' : 'no status';
    }
  }
}
// PR