


import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import 'moment-timezone';
import { TabsetComponent } from "ngx-bootstrap/tabs";
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';
@Component({
  selector: 'app-product-update',
  templateUrl: './product-update.component.html',
  styleUrls: ['./product-update.component.scss']
})
export class ProductUpdateComponent implements OnInit {
  editProductForm: UntypedFormGroup;
  editProductFormTab2: UntypedFormGroup;
  editProductFormTab3: UntypedFormGroup;
  public language = "en";
  file!: File;
  id: '';
  pharmacyId: ''
  nameProduct: any;
  nameFr: any;
  detail: any;
  detailFr: any;
  isFeatured2: any;
  product: [];
  updateImage: boolean;
  brandId: '';
  caregoryId: '';
  prescription: boolean;
  productDet: any[];
  nameDetail: '';
  presentation: '';
  presentationId: '';
  type: '';
  typeId: '';
  price: '';
  quantity: '';
  tax: '';


  brandAll: [];
  allCategory: [];

  allPresentations: [];
  allSizes: [];

  productDetails: any[];

  products: any
  data: any | undefined;

  @ViewChild(TabsetComponent)
  tabset: TabsetComponent;

  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    public routern: Router,
    public routeAct: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder

  ) { }

  ngOnInit(): void {

    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en"
    }


    //ADD NEW RESIDENCE FORM 
    this.editProductForm = this.formBuilder.group({
      brand: ['', Validators.required],
      category: ['', Validators.required],
      name: ['', Validators.required],
      detail: ['', Validators.required],
      prescription: [''],
      active: [1],

    });

    this.editProductFormTab2 = this.formBuilder.group({
      name: ['', Validators.required],
      presentationId: ['', Validators.required],
      quantity: [, Validators.required],
      unitTypeId: ['', Validators.required],
      price: [, Validators.required],
      tax: [, Validators.required],
      imageUrl: ['none'],
      active: ['true'],

    })

    this.editProductFormTab3 = this.formBuilder.group({
      nameFr: ['', Validators.required],
      detailFr: ['', Validators.required],
    })
    this.id = this.routeAct.snapshot.params["id"];
    var localData = JSON.parse(localStorage.getItem("currentProducts"));
    console.log("console de localData", localData);

    this.services.product(this.id).pipe().subscribe((data) => {
      this.data = data[0];
      this.fillGeneralInfo();
      this.fillTranslationInfo();
      this.spinner.hide();

      this.product = data;
      this.productDet = data[0].productDetails
      console.log("console desde Data", data);
      // console.log(this.productDet);


      localStorage.setItem('currentProducts', JSON.stringify(this.id));

      localStorage.setItem('currentFile', JSON.stringify(this.file));

      this.brandId = data.brandId
      this.caregoryId = data.categoryId
      this.nameProduct = data.name;
      this.detail = data.detail;
      this.nameFr = data.nameFr;
      this.detailFr = data.detailFr;
      this.prescription = data.prescription;
      this.file = data.keyS3;
      this.isFeatured2 = data.isFeatured;
      this.id = data._id;
      for (let i = 0; i < this.productDet.length; i++) {
        this.nameDetail = this.productDet[i]['name'];
        // console.log(this.nameDetail);

        this.quantity = this.productDet[i]['quantity'];

        this.price = this.productDet[i]['price'];

        this.tax = this.productDet[i]['tax'];

        this.presentation = this.productDet[i]['presentation']['name'];
        //  console.log(this.presentation);
        this.presentationId = this.productDet[i]['presentation']['_id'];

        //  console.log(this.presentationId);

        this.type = this.productDet[i]['unit_type']['name'];

        this.typeId = this.productDet[i]['unit_type']['_id'];

        // console.log(this.typeId);
      }
      this.fillProductDetails();

    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });



    this.getBrand()
    this.getCategory();
    this.getPresentation();
    this.getSize();

  }
  private fillGeneralInfo() {
    this.editProductForm.controls.name.setValue(this.data.name);
    this.editProductForm.controls.detail.setValue(this.data.detail);
    this.editProductForm.controls.prescription.setValue(this.data.name);
  }
  private fillTranslationInfo() {
    this.editProductFormTab3.controls.nameFr.setValue(this.data.nameFr);
    this.editProductFormTab3.controls.detailFr.setValue(this.data.detailFr);
  }
  private fillProductDetails() {
    this.productDetails = [];
    this.productDet.forEach((det) => {
      const data = {
        '_id': det['_id'],
        'presentationId': det['presentation']['_id'],
        'name': det['name'],
        'quantity': det['quantity'],
        'unitTypeId': det['unit_type']['_id'],
        'price': det['price'],
        'imageUrl': "none",
        'tax': det['tax'],
        'active': true
      };
      this.productDetails.push(data);
    });
    this.products = JSON.stringify(this.productDetails)
  }
  getPresentationName(id: string): string {
    var ans = '';
    this.allPresentations.forEach((e) => {
      if (e['_id'] == id) {
        ans = e['name'];
      }
    });
    return ans;
  }
  getSizeName(id: string): string {
    var ans = '';
    this.allSizes.forEach((e) => {
      if (e['_id'] == id) {
        ans = e['name'];
      }
    });
    return ans;
  }
  getBrand() {

    this.services.brandLis().pipe().subscribe(data => {

      this.spinner.hide();
      this.brandAll = data.entityList;
    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });

  }

  getCategory() {

    this.services.categoryLis().pipe().subscribe(data => {
      this.spinner.hide();
      this.allCategory = data.entityList;
    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });
  }
  getPresentation() {

    this.services.presentationLis().pipe().subscribe(data => {
      this.spinner.hide();
      this.allPresentations = data.entityList;
      console.log(this.allPresentations);
    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });
  }
  getSize() {

    this.services.typeLis().pipe().subscribe(data => {
      this.spinner.hide();
      this.allSizes = data.entityList;
      console.log(this.allSizes);
    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });
  }

  back() {
    this.router.navigate(['/product']);
  }


  get f() { return this.editProductForm.controls; }
  get g() { return this.editProductFormTab2.controls; }
  get d() { return this.editProductFormTab3.controls; }

  addDetail() {
    if (!this.g.name.value.trim()) {
      return this.toastr.error('', 'Please enter first name');
    }
    else if (!this.g.presentationId.value.trim()) {
      return this.toastr.error('', 'Please select Presentation');
    }
    else if (this.g.quantity.value === 0) {
      return this.toastr.error('', 'Please enter Quantity');
    }
    else if (!this.g.unitTypeId.value.trim()) {
      return this.toastr.error('', 'Please select Size');
    }

    else if (this.g.price.value === 0) {
      return this.toastr.error('', 'Please enter Price');
    }

    else if (this.g.tax.value === 0) {
      return this.toastr.error('', 'Please enter Tax');
    }

    if (this.g.name.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.g.name.value);
      if (testing == true) {
        return this.toastr.error('', 'name not valid!');
      }
    }
    if (this.g.presentationId.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.g.presentationId.value);
      if (testing == true) {
        return this.toastr.error('', 'Presentation not valid!');
      }
    }

    if (this.g.quantity.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.g.quantity.value);
      if (testing == true) {
        return this.toastr.error('', 'Quantity not valid!');
      }
    }

    if (this.g.unitTypeId.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.g.unitTypeId.value);
      if (testing == true) {
        return this.toastr.error('', 'Size not valid!');
      }
    }

    if (this.g.price.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.g.price.value);
      if (testing == true) {
        return this.toastr.error('', 'Price not valid!');
      }
    }

    if (this.g.tax.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.g.tax.value);
      if (testing == true) {
        return this.toastr.error('', 'Tax not valid!');
      }
    }

    let detail = this.editProductFormTab2.value;


    //let productDetails: string[] = [];
    this.productDetails.push(detail);



    this.products = JSON.stringify(this.productDetails)
    console.log(this.products);
    return this.toastr.success('detail added successfully');
  }
  onFileSelect(event) {
    console.log("event", event);
    this.file = event.target.files[0];
  }
  updateProduct() {

    if (!this.f.category.value.trim()) {
      return this.toastr.error('', 'Please select Category');
    }
    if (!this.f.brand.value.trim()) {
      return this.toastr.error('', 'Please select Brand');
    }
    if (!this.f.name.value.trim()) {
      return this.toastr.error('', 'Please enter Name');
    }
    if (!this.f.detail.value.trim()) {
      return this.toastr.error('', 'Please enter Detail');
    }

    if (this.f.active.value == 0) {
      return this.toastr.error('', 'Please enter first name');
    }


    if (this.f.category.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.category.value);
      if (testing == true) {
        return this.toastr.error('', 'category not valid!');
      }
    }

    if (this.f.brand.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.brand.value);
      if (testing == true) {
        return this.toastr.error('', 'brand not valid!');
      }
    }
    if (this.f.name.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.name.value);
      if (testing == true) {
        return this.toastr.error('', 'name not valid!');
      }
    }

    if (this.f.detail.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.detail.value);
      if (testing == true) {
        return this.toastr.error('', 'detail not valid!');
      }
    }

    if (this.f.active.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.active.value);
      if (testing == true) {
        return this.toastr.error('', 'active not valid!');
      }
    }



    if (!this.d.nameFr.value.trim()) {
      return this.toastr.error('', 'Please enter first name');
    }
    else if (!this.d.detailFr.value.trim()) {
      return this.toastr.error('', 'Please select Presentation');
    }

    if (this.d.nameFr.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.d.nameFr.value);
      if (testing == true) {
        return this.toastr.error('', 'name not valid!');
      }
    }

    if (this.d.detailFr.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.d.detailFr.value);
      if (testing == true) {
        return this.toastr.error('', 'name not valid!');
      }
    }

    const valuePrincipal = this.editProductForm.value;
    const values = this.editProductFormTab3.value;
    this.spinner.show();
    this.services.updateProduct(this.data._id, values, valuePrincipal, this.products, this.file).pipe().subscribe(data => {
      this.spinner.hide();
      this.ngOnInit();
      this.routern.navigate(['/product']);
      return this.toastr.success('successfully update Product');


    }, error => {
      this.spinner.hide()
      return this.toastr.error('error', error.error.message);
    });

  }
  nextTab(tabId: string) {
    const tab = this.tabset.tabs.find(tab => tab.id === tabId)
    tab.active = true
  }

}