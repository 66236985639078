<link
    href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
    rel="stylesheet">
<div class="card mt-3">
    <div class="card-body">
        <div class="row">
            <div class="col-lg-4">
                <div class="scp-breadcrumb">
                    <ul class="breadcrumb">
                        <li class="b-jobs"><a>{{ 'PRODUCTS.title' | translate }}</a></li>
                    </ul>
                </div>
            </div>


            <div class="col-md-4">
                <form class="search-box-custom">
                    <input type="text" name="search" placeholder="{{ 'SEARCH' | translate }}" class="animated-search"
                        (ngModelChange)="searchProduct($event)" [(ngModel)]="filter"
                        [ngModelOptions]="{standalone: true}" />
                </form>
            </div>

            <div class="col-md-4">
                <ul class="invoice-list">
                    <li>
                        <a routerLink="/product-create">
                            <button class="add-invoice-btn" id="openRighSideBar">
                                {{ "PRODUCTS.add_new" | translate }}
                            </button>
                        </a>
                    </li>
                </ul>
            </div>

        </div>

        <div class="row">
            <div class="table-responsive">
                <table class="table table-borderless jobs-table">
                    <thead>
                        <tr style="align-items: center;">
                            <th>{{'PRODUCTS.nameen_table' | translate }}</th>
                            <th>{{'PRODUCTS.namefr_table' | translate }}</th>
                            <th>{{'PRODUCTS.descriptionen_table' | translate }}</th>
                            <th>{{'PRODUCTS.descriptionfr_table' | translate }}</th>
                            <th>{{'PRODUCTS.prescription' | translate }}</th>
                            <th>{{'PRODUCTS.status_table' | translate }}</th>
                            <th style="width: 6%; text-align: center;">{{'PRODUCTS.stock_table' | translate }}</th>
                            <th style="width: 2%; text-align: center;">{{'PRODUCTS.action_table' | translate }}</th>

                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            *ngFor="let product of allProduct | paginate: { itemsPerPage: 10,currentPage: page,totalItems:allProductCount}">

                            <td routerLink="/products/{{product._id}}">
                                {{product.name}}
                            </td>

                            <td routerLink="/products/{{product._id}}">
                                {{product.nameFr}}
                            </td>

                            <td routerLink="/products/{{product._id}}">
                                {{product.detail}}
                            </td>

                            <td routerLink="/products/{{product._id}}">
                                {{product.detailFr}}
                            </td>

                            <td routerLink="/products/{{product._id}}" style="text-align: center;"
                                *ngIf="product.prescription">
                                {{ 'PRODUCTS.yes' | translate }}
                            </td>

                            <td *ngIf="!product.prescription" routerLink="/products/{{product._id}}">
                                {{ 'PRODUCTS.not' | translate }}
                            </td>

                            <td style="text-align: center;" *ngIf="product.active"
                                routerLink="/products/{{product._id}}">
                                {{ 'PRODUCTS.active' | translate }}
                            </td>

                            <td *ngIf="!product.active" routerLink="/products/{{product._id}}">
                                {{ 'PRODUCTS.inactive' | translate }}
                            </td>


                            <td style="text-align: center;" *ngIf="product.statusD == 1"
                                routerLink="/products/{{product._id}}">
                                {{ 'PRODUCTS.stock' | translate }}
                            </td>

                            <td *ngIf="product.statusD == 0" routerLink="/products/{{product._id}}">
                                {{ 'PRODUCTS.not' | translate }}
                            </td>


                            <td>
                                <ul class="brands-actions-btn">
                                    <li>
                                        <a><i routerLink="/product-update/{{product._id}}"
                                                class="fa fa-edit text-danger" ngbTooltip="Edit Invoice"
                                                id="openRightSideBarToEdit" aria-hidden="true"></i></a>
                                    </li>

                                    <li>
                                        <a>
                                            <i class="fa fa-trash text-danger" ngbTooltip="Delete"
                                                (click)="productDelete(product._id)" aria-hidden="true">
                                            </i>
                                        </a>
                                    </li>
                                </ul>
                            </td>
                        </tr>


                    </tbody>

                </table>

                <div class="row f-right" style="margin-right: 40px;">
                    <pagination-controls *ngIf="allProduct.length" (pageChange)="onPageChange($event)">
                    </pagination-controls>
                </div>

            </div>

            <!-------delete modal---->
            <div bsModal #deleteProduct="bs-modal" class="modal fade" [config]="{ backdrop: 'static' ,keyboard : false}"
                tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-md add-client-modal" role="document">
                    <div class="modal-content" style="width:90%">
                        <div class="modal-header">
                            <div class="modal-title-absolute">
                                <button class="btn btn-default">CONFIRMATION</button>
                            </div>
                            <button type="button" class="close" (click)="deleteProduct.hide()" aria-label="Close"> <span
                                    aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body">

                            <div class="c-info col-md-12">
                                <p style="font-size: 12px;">{{'PRODUCTS.delete_Comment' | translate}}</p>
                            </div>

                        </div>
                        <div class="modal-footer" style="margin: auto; margin-right: 0;">
                            <button type="button" class="btn btn-secondary client-cancel-btn"
                                (click)="deleteProduct.hide()">{{'CREATEJOB.no' | translate}}</button>
                            <button type="button" class="btn btn-secondary add-client-btn" style="width: 80px;"
                                (click)="deleteExistProduct()">{{'CREATEJOB.yes' | translate}}</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
        </div>

    </div>
</div>