<ng-template #dynamicContent>
  <div *ngIf="find_driver_delivery == '1'" class="d-flex gap-1 ml-auto">
    <app-button
      [imageSrc]="'./assets/img/new-ui/reset.svg'"
      [buttonText]="'CREATEJOB.reset' | translate"
      (buttonClick)="refresh()"
    ></app-button>
    <app-button
      [imageSrc]="'./assets/img/new-ui/save.svg'"
      [buttonText]="
        job_type_selection == '1'
          ? ('CREATEJOB.delivery_request' | translate)
          : ('CREATEJOB.pickup_request' | translate)
      "
      (click)="createJob()"
    ></app-button>
  </div>
</ng-template>
<div class="mt-3">
  <!-- Nav tabs -->
  <!-- <tabset> -->
  <ng-template #contentTemplate>
    <div>
      <form [formGroup]="jobForm">
        <div class="row">
          <div class="col-xl-6 col-sm-12 float-left border-right">
            <div class="padding-all">
              <div class="c-info">
                <h5 class="font-weight-bold">
                  {{ "CREATEJOB.customer_type" | translate }}
                </h5>
                <div class="main-box">
                  <div class="radio-box custom-radio-width">
                    <input
                      id="radio-1"
                      type="radio"
                      name="radio-01"
                      [value]="1"
                      [(ngModel)]="customer_type"
                      [ngModelOptions]="{ standalone: true }"
                      (click)="changeCustomerTypeDelivery(1)"
                    />
                    <label for="radio-1" class="c-j-label">
                      <span> {{ "CREATEJOB.hospital" | translate }}</span>
                    </label>
                    <input
                      id="radio-2"
                      type="radio"
                      name="radio-01"
                      [value]="2"
                      [(ngModel)]="customer_type"
                      [ngModelOptions]="{ standalone: true }"
                      (click)="changeCustomerTypeDelivery(2)"
                    />
                    <label for="radio-2" class="c-j-label">
                      <span>{{ "CREATEJOB.patient" | translate }}</span>
                    </label>
                    <input
                      id="radio-3"
                      type="radio"
                      name="radio-01"
                      [value]="3"
                      [(ngModel)]="customer_type"
                      [ngModelOptions]="{ standalone: true }"
                      (click)="changeCustomerTypeDelivery(3)"
                    />
                    <label for="radio-3" class="c-j-label">
                      <span> {{ "CREATEJOB.doctor" | translate }} </span>
                    </label>

                    <input
                      id="radio-4"
                      type="radio"
                      name="radio-01"
                      [value]="4"
                      [(ngModel)]="customer_type"
                      [ngModelOptions]="{ standalone: true }"
                      (click)="changeCustomerTypeDelivery(4)"
                    />
                    <label for="radio-4" class="c-j-label">
                      <span> Cpo</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="c-info" style="margin-top: 5px">
                <div class="d-flex gap-1">
                  <p>{{ "CREATEJOB.customer_info" | translate }}</p>
                  <div *ngIf="customer_type == '1'" class="custom-radio-width">
                    <input
                      id="customerSingleMultipleDelivery1"
                      name="customerSingleMultipleDelivery"
                      type="radio"
                      [checked]="customerTypeSelection == '1'"
                      (click)="changeCustomerTypeSelection(1)"
                    />
                    <label
                      class="c-j-label"
                      for="customerSingleMultipleDelivery1"
                      >{{ "CREATEJOB.single" | translate }}</label
                    >
                    <input
                      id="customerSingleMultipleDelivery2"
                      name="customerSingleMultipleDelivery"
                      type="radio"
                      (click)="changeCustomerTypeSelection(2)"
                      [checked]="customerTypeSelection == '2'"
                    />
                    <label
                      class="c-j-label"
                      for="customerSingleMultipleDelivery2"
                      >{{ "CREATEJOB.multiple" | translate }}</label
                    >
                  </div>
                </div>

                <div
                  *ngIf="customer_type != '2'"
                  class="mt-3 row"
                  style="position: relative"
                >
                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="animated-inputs">
                        <div class="form-group w-100 mb-0">
                          <p-autoComplete
                            formControlName="establishment_name"
                            [suggestions]="establishments"
                            (completeMethod)="
                              searchEstablishmentDelivery($event.query)
                            "
                            (onSelect)="onSelectEstablishment($event)"
                            field="establishment_name"
                            [size]="30"
                            [minLength]="1"
                            placeholder="{{
                              'CREATEJOB.establish_name' | translate
                            }}"
                            [ngClass]="{
                              'is-invalid':
                                submitted && f.establishment_name.errors
                            }"
                            class="auto-in auto-cust"
                          >
                            <ng-template let-customer_name pTemplate="item">
                              <div>
                                {{ customer_name.establishment_name }}
                              </div>
                            </ng-template>
                          </p-autoComplete>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="animated-inputs">
                        <div class="form-group w-100 mb-0">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="{{
                              'CREATEJOB.establishment_email' | translate
                            }}"
                            formControlName="establishment_email"
                            maxlength="40"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="animated-inputs">
                        <div class="form-group w-100 mb-0">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text">{{
                                dial_code
                              }}</span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{
                                'CREATEJOB.establishment_phone' | translate
                              }}"
                              maxlength="12"
                              (ngModelChange)="establishmentPhoneChange($event)"
                              [ngModel]="establishment_phone"
                              [ngModelOptions]="{ standalone: true }"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex" *ngIf="customer_type != '2'">
                  <div class="d-flex gap-1">
                    <p>
                      {{
                        customer_type == "4"
                          ? ("CREATEJOB.person_responsible" | translate)
                          : ("CREATEJOB.information" | translate)
                      }}
                    </p>

                    <div class="custom-radio-width">
                      <input
                        id="receptionMedicareDeliveryNo"
                        name="receptionMedicareDelivery"
                        type="radio"
                        (click)="onSelectTag('0', [16, 17])"
                        [checked]="selected_type == '0'"
                      />
                      <label
                        class="c-j-label"
                        for="receptionMedicareDeliveryNo"
                        >{{ "CREATEJOB.no" | translate }}</label
                      >
                      <input
                        id="receptionMedicareDelivery1"
                        name="receptionMedicareDelivery"
                        type="radio"
                        checked
                        (click)="onSelectTag(16, 17)"
                        [checked]="selected_type == '16'"
                      />
                      <label
                        class="c-j-label"
                        for="receptionMedicareDelivery1"
                        >{{ "CREATEJOB.reception" | translate }}</label
                      >

                      <span *ngIf="customer_type != '4'">
                        <input
                          id="receptionMedicareDelivery2"
                          name="receptionMedicareDelivery"
                          type="radio"
                          (click)="onSelectTag(17, 16)"
                          [checked]="selected_type == '17'"
                        />
                        <label
                          class="c-j-label"
                          for="receptionMedicareDelivery2"
                          >{{ "CREATEJOB.medicare" | translate }}</label
                        >
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="row"
                  *ngIf="
                    customerTypeSelection == '1' &&
                    establishmentTagSelection == '0'
                  "
                >
                  <div class="col-lg-6">
                    <div class="form-group">
                      <div class="animated-inputs">
                        <p-autoComplete
                          formControlName="customer_name"
                          [suggestions]="customers"
                          (completeMethod)="search_customer($event.query)"
                          (onSelect)="onSelectClient($event)"
                          field="customer_f_name"
                          [size]="30"
                          [minLength]="1"
                          placeholder="{{ 'CREATEJOB.firstname' | translate }}"
                          [ngClass]="{
                            'is-invalid': submitted && f.customer_name.errors
                          }"
                          class="auto-in auto-cust"
                        >
                          <ng-template let-customer_name pTemplate="item">
                            <div>
                              {{
                                customer_name.customer_f_name
                                  ? customer_name.customer_f_name
                                  : customer_name.customer_name
                              }}
                              {{ customer_name.customer_l_name }}, ({{
                                customer_name.country_code
                              }}
                              {{ customer_name.phone_no }})
                            </div>
                          </ng-template>
                        </p-autoComplete>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <div class="animated-inputs">
                        <p-autoComplete
                          formControlName="customer_L_name"
                          [suggestions]="customers"
                          (completeMethod)="search_customer($event.query)"
                          (onSelect)="onSelectClient($event)"
                          field="customer_l_name"
                          [size]="30"
                          [minLength]="1"
                          placeholder="{{ 'CREATEJOB.lastname' | translate }}"
                          [ngClass]="{
                            'is-invalid': submitted && f.customer_L_name.errors
                          }"
                          class="auto-in auto-cust"
                        >
                          <ng-template let-customer_name pTemplate="item">
                            <div (click)="setLastNameDelivery(customer_name)">
                              {{
                                customer_name.customer_f_name
                                  ? customer_name.customer_f_name
                                  : customer_name.customer_name
                              }}
                              {{ customer_name.customer_l_name }}, ({{
                                customer_name.country_code
                              }}
                              {{ customer_name.phone_no }})
                            </div>
                          </ng-template>
                        </p-autoComplete>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="animated-inputs">
                        <div class="form-group w-100">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text">{{
                                dial_code
                              }}</span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{
                                'CREATEJOB.phone_no' | translate
                              }}"
                              (ngModelChange)="mychange($event)"
                              [ngModel]="iban_no"
                              [ngModelOptions]="{ standalone: true }"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                              maxlength="12"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="animated-inputs">
                        <div class="form-group w-100">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="{{
                              'CREATEJOB.customer_email' | translate
                            }}"
                            formControlName="customer_email"
                            maxlength="40"
                          />
                          <div
                            *ngIf="submitted && f.customer_email.errors"
                            class="invalid-feedback"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row mb-3"
                  *ngIf="customer_type == '2' && customerAddresses.length"
                >
                  <div class="col-md-2 d-flex align-items-center">
                    <div class="font-weight-bold">
                      {{ "CREATEJOB.address" | translate }}
                    </div>
                  </div>
                  <div class="col-md-10">
                    <p-dropdown
                      placeholder="{{
                        'CREATEJOB.customer_location' | translate
                      }}"
                      [options]="customerAddresses"
                      [formControl]="selectedAddress"
                      optionLabel="{{
                        language === 'fr' ? 'address_fr' : 'address'
                      }}"
                      (onChange)="
                        handleAddressChange($event, true, modalLocation)
                      "
                    >
                    </p-dropdown>
                  </div>
                </div>

                <!-- optional address section start here -->
                <div
                  class="row animated-pd"
                  style="position: relative"
                  *ngIf="customer_type == 2 && openOptionalAddress == true"
                >
                  <div class="form-group w-100">
                    <div class="animated-inputs">
                      <div class="form-group w-100 mb-0">
                        <input
                          ngx-google-places-autocomplete
                          [options]="options"
                          placeholder="Customer Location(optional)"
                          #placesRef="ngx-places"
                          (onAddressChange)="handleAddressChange($event)"
                          [ngModel]="optional_location"
                          [ngModelOptions]="{ standalone: true }"
                          class="form-control"
                          [ngClass]="{
                            'is-invalid': submitted && f.location1.errors
                          }"
                        />
                        <div
                          *ngIf="submitted && f.location1.errors"
                          class="invalid-feedback"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row animated-pd" style="position: relative">
                <div class="form-group w-100">
                  <div class="animated-inputs">
                    <div class="">
                      <input
                        type="text"
                        formControlName="location1"
                        class="form-control location-input"
                        placeholder="{{
                          'CREATEJOB.customer_location' | translate
                        }}"
                        [ngClass]="{
                          'is-invalid': submitted && f.location1.errors
                        }"
                        readonly
                        (click)="openModalSelectLocation()"
                      />
                      <div
                        *ngIf="submitted && f.location1.errors"
                        class="invalid-feedback"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="customerTypeSelection == 1">
                <div *ngIf="establishmentTagSelection == '0'">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <div class="animated-inputs">
                          <div class="form-group w-100">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{
                                'CREATEJOB.appartment_no' | translate
                              }}"
                              formControlName="appartment_no"
                              maxlength="20"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <div class="animated-inputs">
                          <div class="form-group w-100">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{
                                'CREATEJOB.door_code' | translate
                              }}"
                              formControlName="door_code"
                              maxlength="20"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group w-100">
                      <div class="animated-inputs">
                        <div class="form-group w-100">
                          <textarea
                            id="textarea"
                            rows="1"
                            class="form-control"
                            placeholder="{{ 'CREATEJOB.note' | translate }}"
                            formControlName="note"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row animated-pd" style="position: relative">
                  <div class="form-group w-100 mb-0">
                    <div class="animated-inputs">
                      <div
                        class="form-group w-100"
                        style="border: 2px solid red; border-radius: 0.25rem"
                      >
                        <textarea
                          id="textarea"
                          rows="1"
                          class="form-control"
                          placeholder="{{ 'CREATEJOB.note_custo' | translate }}"
                          formControlName="customer_note"
                        ></textarea>
                        <input
                          type="hidden"
                          formControlName="customer_parent"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <p class="sub-title">
                      {{
                        job_type_selection == "1"
                          ? ("CREATEJOB.to_be_received_by" | translate)
                          : ("CREATEJOB.received_from" | translate)
                      }}
                    </p>
                  </div>
                  <div class="col-12 d-flex gap-1">
                    <div class="">
                      <div class="form-group">
                        <div class="animated-inputs">
                          <div class="form-group w-100">
                            <select
                              name="to_be_received_by_type"
                              style="height: 35px; border-radius: 0.25rem"
                              [(ngModel)]="type"
                              [ngModelOptions]="{ standalone: true }"
                              (change)="filter($event)"
                            >
                              <option [value]="0" selected>Patient</option>
                              <option [value]="1">Spouse/Partner</option>
                              <option [value]="2">Nurse</option>
                              <option [value]="3">Desk/Reception</option>
                              <option [value]="4">Friend</option>
                              <option [value]="5">Parent(Father/Mother)</option>
                              <option [value]="6">Child</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="">
                      <div class="form-group w-100">
                        <div class="animated-inputs">
                          <div class="form-group w-100">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'Name' | translate }}"
                              formControlName="to_be_received_by_name"
                              maxlength="20"
                              [disabled]="nameDisabled()"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="job_type_selection == '1' && customer_type != 4"
                      class="row"
                    >
                      <div class="col-md-5 pr-0">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="{{
                              'CREATEJOB.item_price' | translate
                            }}"
                            formControlName="item_price"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                            [ngClass]="{
                              'is-invalid': submitted && f.item_price.errors
                            }"
                          />
                          <div
                            *ngIf="submitted && f.item_price.errors"
                            class="invalid-feedback"
                          ></div>
                        </div>
                      </div>
                      <div class="col-md-7 text-center">
                        <span
                          class="custom-checkbox item-price-charge checkbox-style-1"
                        >
                          <input
                            type="checkbox"
                            name="Charge_account"
                            id="51"
                            [checked]="jobForm.controls.item_price.enabled"
                            (click)="disableItemPrice()"
                          />
                          <span
                            class="box"
                            [ngStyle]="{ 'background-color': '#46BF88' }"
                            style="margin-right: 0"
                            >{{
                              "CREATEJOB.charge_to_account" | translate
                            }}</span
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="customerTypeSelection == '2'">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="my-custom-dropdown">
                        <ng-multiselect-dropdown
                          placeholder="{{
                            'CREATEJOB.select_customers' | translate
                          }}"
                          [(ngModel)]="residenceAllClient"
                          [ngModelOptions]="{ standalone: true }"
                          [settings]="dropdownSettings2"
                          [data]="residenceAllClients"
                          (onSelect)="onItemSelect2($event)"
                          (onDeSelect)="onDeSelect2($event)"
                          (onSelectAll)="onSelectAll2($event)"
                          (onDeSelectAll)="onDeSelectAll2($event)"
                          style="width: 90%; background-color: white"
                        >
                        </ng-multiselect-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- for job recurring -->
              <div class="c-info mb-3">
                <div class="row flex-column gap-1">
                  <div class="col-12">
                    <p>{{ "CREATEJOB.job_recurring" | translate }}</p>
                  </div>
                  <div class="col-12">
                    <div class="d-flex gap-1 flex-wrap">
                      <div class="main-box">
                        <div class="animated-inputs">
                          <select
                            style="height: 35px; border-radius: 0.25rem"
                            [(ngModel)]="job_recurring_type"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="filterRecurringJob($event)"
                          >
                            <option [value]="1" selected>
                              {{ "CREATEJOB.one_time" | translate }}
                            </option>
                            <option [value]="2">
                              {{ "CREATEJOB.daily" | translate }}
                            </option>
                            <option [value]="3">
                              {{ "CREATEJOB.weekely" | translate }}
                            </option>
                            <option [value]="4">
                              {{ "CREATEJOB.monthly" | translate }}
                            </option>
                            <option [value]="5">
                              {{ "CREATEJOB.biweekly" | translate }}
                            </option>
                            <option [value]="6">
                              {{ "CREATEJOB.every-4-week" | translate }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div
                        *ngIf="eLat_long.length && location1"
                        class="d-flex align-items-center"
                      >
                        <div class="mr-2 font-weight-bold">
                          {{ "CREATEJOB.job_date" | translate }}
                        </div>
                        <input
                          class="form-control point date-picker"
                          placeholder="{{
                            'CREATEJOB.select_job_date' | translate
                          }}"
                          formControlName="jobDate"
                          ngbDatepicker
                          #end="ngbDatepicker"
                          #buttonEl
                          (click)="end.toggle()"
                          [readonly]="true"
                          [ngClass]="{
                            'is-invalid': submitted && f.jobDate.errors
                          }"
                          (ngModelChange)="onDateSelected($event)"
                          firstDayOfWeek="7"
                        />
                        <div
                          *ngIf="submitted && f.jobDate.errors"
                          class="invalid-feedback"
                        ></div>
                      </div>
                      <div
                        *ngIf="job_recurring_type == 2"
                        class="d-flex align-items-center"
                      >
                        <div class="mr-2 font-weight-bold">
                          {{ "CREATEJOB.period" | translate }}
                        </div>
                        <div class="animated-inputs">
                          <select
                            style="height: 35px; border-radius: 0.25rem"
                            [(ngModel)]="dailyJobType"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="dailyJobTypeChange($event)"
                          >
                            <option [value]="1" selected>
                              {{ "CREATEJOB.everyday" | translate }}
                            </option>
                            <option [value]="2">
                              {{ "CREATEJOB.mon_sat" | translate }}
                            </option>
                            <option [value]="3">
                              {{ "CREATEJOB.mon_fri" | translate }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div *ngIf="job_recurring_type == 3" class="">
                        <form class="form-inline">
                          <div class="">
                            <ng-multiselect-dropdown
                              placeholder="{{
                                'CREATEJOB.select_weekdays' | translate
                              }}"
                              [(ngModel)]="weekDays"
                              [ngModelOptions]="{ standalone: true }"
                              [settings]="dropdownSettings"
                              [data]="dropdownList"
                              (onSelect)="onItemSelect($event)"
                              (onSelectAll)="onSelectAll($event)"
                              (onDeSelect)="onDeSelect($event)"
                              (onDeSelectAll)="onDeSelectAll($event)"
                              style="background-color: white"
                            >
                            </ng-multiselect-dropdown>
                          </div>
                        </form>
                      </div>
                      <div
                        class="d-flex align-items-center"
                        *ngIf="job_recurring_type !== 1"
                      >
                        <div class="mr-2 font-weight-bold">
                          {{ "CREATEJOB.time" | translate }}
                        </div>
                        <app-time-picker [time]="timeForAutoJob">
                        </app-time-picker>
                      </div>
                    </div>
                  </div>
                  <!-- Select end date for recursive jobs -->
                  <div class="col-12 d-flex" *ngIf="job_recurring_type !== 1">
                    <div class="text-center">
                      <span
                        class="custom-checkbox item-price-charge checkbox-style-1"
                      >
                        <input
                          type="checkbox"
                          (click)="onChangeIsContinuous()"
                          [checked]="isContinuousJob"
                        />
                        <span
                          class="box"
                          [ngStyle]="{ 'background-color': '#46BF88' }"
                          style="margin-right: 0"
                          >{{
                            !isContinuousJob
                              ? ("CREATEJOB.ending_date_on" | translate)
                              : ("CREATEJOB.ending_date_off" | translate)
                          }}</span
                        >
                      </span>
                    </div>
                    <div
                      *ngIf="!isContinuousJob"
                      class="d-flex align-items-center"
                    >
                      <div class="col-6 font-weight-bold pr-1">
                        {{ "CREATEJOB.recurring_end_date" | translate }}
                      </div>

                      <input
                        class="form-control point date-picker"
                        style="width: 180px"
                        placeholder="{{
                          'CREATEJOB.recurring_end_date' | translate
                        }}"
                        [(ngModel)]="recursiveEndDateDelivery"
                        [ngModelOptions]="{ standalone: true }"
                        ngbDatepicker
                        #end="ngbDatepicker"
                        #buttonEl
                        (click)="end.toggle()"
                        [readonly]="true"
                        (ngModelChange)="onRecursiveEndDate($event)"
                        firstDayOfWeek="7"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- end job recurring -->

              <!-- TIMING SELECTION START -->

              <div class="c-info">
                <p>{{ "CREATEJOB.timing" | translate }}</p>
                <div class="main-box d-flex gap-1">
                  <div class="radio-box custom-radio-width">
                    <input
                      id="timing-before"
                      type="radio"
                      name="timingSelectionDelivery"
                      [value]="1"
                      [(ngModel)]="jobTimingObj.type"
                      [ngModelOptions]="{ standalone: true }"
                      (click)="
                        jobTimingObj.type = 1;
                        jobTimingObj.nameEn = 'Before';
                        jobTimingObj.nameFr = 'Avant'
                      "
                    />
                    <label for="timing-before" class="c-j-label">{{
                      "CREATEJOB.before" | translate
                    }}</label>

                    <input
                      id="timing-after"
                      type="radio"
                      name="timingSelectionDelivery"
                      [value]="2"
                      [(ngModel)]="jobTimingObj.type"
                      [ngModelOptions]="{ standalone: true }"
                      (click)="
                        jobTimingObj.type = 2;
                        jobTimingObj.nameEn = 'After';
                        jobTimingObj.nameFr = 'Après'
                      "
                    />
                    <label for="timing-after" class="c-j-label">{{
                      "CREATEJOB.after" | translate
                    }}</label>

                    <input
                      id="timing-between"
                      type="radio"
                      name="timingSelectionDelivery"
                      [value]="3"
                      [(ngModel)]="jobTimingObj.type"
                      [ngModelOptions]="{ standalone: true }"
                      (click)="
                        jobTimingObj.type = 3;
                        jobTimingObj.nameEn = 'Between';
                        jobTimingObj.nameFr = 'Entre'
                      "
                    />
                    <label for="timing-between" class="c-j-label">{{
                      "CREATEJOB.between" | translate
                    }}</label>
                  </div>
                  <div
                    *ngIf="jobTimingObj.type"
                    style="display: flex; align-items: center"
                  >
                    <div class="mr-2 font-weight-bold">
                      {{ "CREATEJOB.time_set" | translate }}
                    </div>
                    <!-- todo -->
                    <app-time-picker
                      [time]="jobTimingObj.startTime"
                      (onChange)="timerOnChange($event)"
                    ></app-time-picker>

                    <p
                      *ngIf="jobTimingObj.type == 3"
                      class="mx-3 font-weight-bold"
                    >
                      -
                    </p>
                    <!-- todo -->
                    <app-time-picker
                      *ngIf="jobTimingObj.type == 3"
                      [time]="jobTimingObj.endTime"
                      (onChange)="timerOnChange2($event)"
                    ></app-time-picker>
                  </div>
                </div>
              </div>

              <!-- TIMING SELECTION END -->
            </div>
          </div>
          <!---end col-md-6-->
          <div class="col-xl-6 col-sm-12 float-left c-j-col">
            <div class="padding-all">
              <h5 class="font-weight-bold mb-4">
                {{ "JOBDETAILS.job_details" | translate }}
              </h5>
              <div
                *ngIf="job_tags.length && disableTags == false"
                class="c-info"
                [ngClass]="{
                  'invalid-timing-tags': submitted && !checkedTimingTags()
                }"
              >
                <p>{{ "TAG.add_new_tag" | translate }}</p>
                <p class="text-right">
                  <app-button
                    [imageSrc]="'./assets/img/new-ui/plus.svg'"
                    [buttonText]="'TAG.add_new_tag' | translate"
                    (buttonClick)="showAddJobTagModal()"
                  ></app-button>
                </p>
                <div class="multiChoice my-3">
                  <span
                    *ngFor="let tag of job_tags"
                    class="jobTag custom-checkbox"
                  >
                    <input
                      type="checkbox"
                      [checked]="tag.running"
                      [(ngModel)]="tag.running"
                      [ngModelOptions]="{ standalone: true }"
                      name="{{ tag.tag_type }}"
                      id="{{ tag.tag_type }}"
                      (change)="onChangeCategory($event, tag)"
                      [disabled]="
                        disableTags ||
                        tag.tag_type == '16' ||
                        tag.tag_type == '17'
                      "
                    />

                    <span
                      class="box"
                      [ngStyle]="{ 'background-color': '#46BF88' }"
                      >{{
                        language == "en" ? tag.tag_for : tag.tag_for_french
                      }}</span
                    >
                  </span>
                </div>
              </div>

              <div
                *ngIf="customer_type == '4'"
                class="d-flex gap-1 package flex-wrap my-3"
              >
                <div class="d-flex align-items-center">
                  <div class="font-weight-bold mr-2">
                    {{ "CREATEJOB.packages" | translate }}
                  </div>
                  <input
                    type="text"
                    class="input-no form-control"
                    [(ngModel)]="packageObj.package"
                    [ngModelOptions]="{ standalone: true }"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                    (ngModelChange)="packageChange($event)"
                    maxlength="5"
                  />
                </div>

                <div class="d-flex align-items-center">
                  <div class="font-weight-bold mr-2">S</div>
                  <input
                    type="text"
                    class="input-no form-control"
                    [(ngModel)]="packageObj.small"
                    [ngModelOptions]="{ standalone: true }"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                    maxlength="5"
                  />
                </div>

                <div class="d-flex align-items-center">
                  <div class="font-weight-bold mr-2">L</div>
                  <input
                    type="text"
                    class="input-no form-control"
                    [(ngModel)]="packageObj.large"
                    [ngModelOptions]="{ standalone: true }"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                    maxlength="5"
                  />
                </div>

                <div class="d-flex align-items-center">
                  <div class="font-weight-bold mr-2">XL</div>
                  <input
                    type="text"
                    class="input-no form-control"
                    [(ngModel)]="packageObj.extraLarge"
                    [ngModelOptions]="{ standalone: true }"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1'); "
                    maxlength="5"
                  />
                </div>

                <div class="d-flex align-items-center">
                  <div class="font-weight-bold mr-2">
                    {{ "CREATEJOB.labels" | translate }}
                  </div>
                  <input
                    disabled
                    type="text"
                    class="input-no form-control"
                    [(ngModel)]="packageObj.label"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>

                <div class="d-flex align-items-center">
                  <div class="font-weight-bold mr-2">
                    {{ "CREATEJOB.weight" | translate }}
                  </div>
                  <input
                    type="text"
                    class="input-no form-control"
                    [(ngModel)]="packageObj.weight"
                    [ngModelOptions]="{ standalone: true }"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    maxlength="8"
                  />
                </div>
              </div>

              <div class="c-info">
                <p>
                  {{ "CREATEJOB.available_delivery_type" | translate }}
                </p>
                <div class="main-box d-type delivery-type-box">
                  <div class="d-flex flex-wrap">
                    <div class="pl-0 pr-1 col-lg-6 d-flex position-relative">
                      <input
                        id="radio-6"
                        type="radio"
                        name="radio-02"
                        (click)="deliver_type('2')"
                        [value]="isNormal"
                        [(ngModel)]="dog"
                        [ngModelOptions]="{ standalone: true }"
                      />
                      <label for="radio-6" class="flex-grow-1 urgent-delivery">
                        <!-- todo available delivery type -->
                        <div class="media pr-2">
                          <img
                            src="/assets/img/new-ui/normal_delivery_inactive.svg"
                            alt=""
                            class="mr-3 truck-icon"
                            style="width: 40px"
                          />
                          <div class="media-body">
                            <div class="u-deliv">
                              <p class="sub-title sub-title-14">
                                {{ "CREATEJOB.normal_delivery" | translate }}
                              </p>
                              <p>
                                {{ "CREATEJOB.normal_comment" | translate }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex u-deliv">
                          <p class="col-md-3">
                            <span
                              class="font-weight-bold"
                              style="font-size: 14px"
                              >${{
                                driver_charges_normal | number : "1.2-2"
                              }}</span
                            ><span style="width: 55px">Price</span>
                          </p>

                          <p class="col-md-3">
                            <span
                              class="font-weight-bold"
                              style="font-size: 14px"
                            >
                              ${{
                                (driver_charges_normal * 5) / 100
                                  | number : "1.2-2"
                              }}</span
                            ><span style="width: 55px">TPS</span>
                          </p>
                          <p class="col-md-3">
                            <span
                              class="font-weight-bold"
                              style="font-size: 14px"
                            >
                              ${{
                                (driver_charges_normal * 9.975) / 100
                                  | number : "1.2-2"
                              }}</span
                            ><span style="width: 55px">{{
                              "CREATEJOB.tax_gst" | translate
                            }}</span>
                          </p>

                          <p class="col-md-3">
                            <span
                              class="font-weight-bold"
                              style="font-size: 14px"
                            >
                              ${{ normalCharges | number : "1.2-2" }}</span
                            ><span style="width: 55px">Total</span>
                          </p>
                        </div>
                      </label>
                      <div class="edit-button">
                        <i
                          *ngIf="eLat_long.length && location1"
                          class="edit-delivery"
                          (click)="openEditNormalJobModal()"
                          style="font-size: 18px; cursor: pointer"
                          class="fa fa-pencil"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    <div class="pr-0 pl-1 col-lg-6 d-flex position-relative">
                      <input
                        id="radio-5"
                        type="radio"
                        name="radio-02"
                        [value]="isUrgent"
                        [(ngModel)]="dog"
                        [ngModelOptions]="{ standalone: true }"
                        (click)="deliver_type('1')"
                      />
                      <label for="radio-5" class="flex-grow-1 urgent-delivery">
                        <div class="media pr-2">
                          <img
                            src="/assets/img/new-ui/urgent_delivery_inactive.svg"
                            class="mr-3 urgent-del truck-icon"
                            style="width: 50px"
                          />
                          <div class="media-body">
                            <div class="u-deliv">
                              <p class="sub-title sub-title-14">
                                {{ "CREATEJOB.urgent_delivery" | translate }}
                              </p>
                              <p class="">
                                {{ hourUrgent
                                }}{{ "CREATEJOB.urgent_comment" | translate }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex u-deliv">
                          <p class="col-md-3">
                            <span
                              class="font-weight-bold"
                              style="font-size: 14px"
                              >${{
                                driver_charges_urgent | number : "1.2-2"
                              }}</span
                            ><span style="width: 55px">Price</span>
                          </p>

                          <p class="col-md-3">
                            <span
                              class="font-weight-bold"
                              style="font-size: 14px"
                            >
                              ${{
                                (driver_charges_urgent * 5) / 100
                                  | number : "1.2-2"
                              }}</span
                            ><span style="width: 55px">TPS</span>
                          </p>
                          <p class="col-md-3">
                            <span
                              class="font-weight-bold"
                              style="font-size: 14px"
                            >
                              ${{
                                (driver_charges_urgent * 9.975) / 100
                                  | number : "1.2-2"
                              }}</span
                            ><span style="width: 55px">{{
                              "CREATEJOB.tax_gst" | translate
                            }}</span>
                          </p>

                          <p class="col-md-3">
                            <span
                              class="font-weight-bold"
                              style="font-size: 14px"
                            >
                              ${{ urgentCharges | number : "1.2-2" }}</span
                            ><span style="width: 55px">Total</span>
                          </p>
                        </div>
                      </label>
                      <div class="edit-button">
                        <i
                          *ngIf="eLat_long.length && location1"
                          class="edit-delivery1"
                          (click)="openEditUrgentJobModal()"
                          style="font-size: 18px; cursor: pointer"
                          class="fa fa-pencil"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p class="est-pric" style="color: #23282c">
                {{ "CREATEJOB.estimate_distance" | translate }}
                <span class="font-weight-bold"> {{ distance }} KM</span>
              </p>
              <div class="d-flex gap-1 align-items-center">
                <div class="font-weight-bold" style="font-size: 14px">
                  {{ "CREATEJOB.job_for" | translate }}
                </div>

                <div class="radio-box custom-radio-width">
                  <input
                    id="customRadio1"
                    type="radio"
                    name="customRadio"
                    [value]="1"
                    [(ngModel)]="jobTypeSelection"
                    [ngModelOptions]="{ standalone: true }"
                    (click)="selectJobFor('1')"
                  />
                  <label class="c-j-label" for="customRadio1">{{
                    "CREATEJOB.public" | translate
                  }}</label>
                  <input
                    id="customRadio2"
                    type="radio"
                    name="customRadio"
                    [value]="2"
                    [(ngModel)]="jobTypeSelection"
                    [ngModelOptions]="{ standalone: true }"
                    (click)="selectJobFor('2')"
                  />
                  <label class="c-j-label" for="customRadio2">{{
                    "CREATEJOB.private" | translate
                  }}</label>
                  <input
                    *ngIf="connectedDispatches.length"
                    id="customRadio3"
                    type="radio"
                    name="customRadio"
                    [value]="3"
                    [(ngModel)]="jobTypeSelection"
                    [ngModelOptions]="{ standalone: true }"
                    (click)="selectJobFor('3')"
                  />
                  <label
                    *ngIf="connectedDispatches.length"
                    class="c-j-label"
                    for="customRadio3"
                    >{{ "CREATEJOB.dispatch_company" | translate }}</label
                  >
                </div>
              </div>

              <div *ngIf="jobFor == '2' && !isDispatchSelected">
                <div class="row mt-3 align-items-center">
                  <div class="col-md-3">
                    <p class="font-weight-bold">
                      {{ "CREATEJOB.driver_for_this_job" | translate }}
                    </p>
                  </div>
                  <div class="col-md-9">
                    <div class="form-group">
                      <div class="my-custom-dropdown">
                        <ng-multiselect-dropdown
                          placeholder="{{
                            'CREATEJOB.driver_for_this_job' | translate
                          }}"
                          [settings]="dropdownSettings1"
                          [data]="drivers"
                          [(ngModel)]="username"
                          [ngModelOptions]="{ standalone: true }"
                          (onSelect)="onItemSelect1($event)"
                          (onDeSelect)="onDeSelect1($event)"
                          (onSelectAll)="onSelectAll1($event)"
                          (onDeSelectAll)="onDeSelectAll1($event)"
                          style="width: 90%; background-color: white"
                        >
                        </ng-multiselect-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-md-7">
                    <p class="font-weight-bold">
                      {{ "CREATEJOB.public_after_min" | translate }}
                    </p>
                  </div>
                  <div class="col-md-5">
                    <ul class="job-for-list">
                      <li>
                        <div
                          class="custom-control custom-radio my-custom-label"
                        >
                          <input
                            type="radio"
                            class="custom-control-input"
                            id="isPrivateTypeDelivery1"
                            name="isPrivateTypeDelivery"
                            (click)="forPermanent('1')"
                          />
                          <label
                            class="custom-control-label"
                            for="isPrivateTypeDelivery1"
                            >{{ "CREATEJOB.yes" | translate }}</label
                          >
                        </div>
                      </li>
                      <li>
                        <div
                          class="custom-control custom-radio my-custom-label"
                        >
                          <input
                            type="radio"
                            id="isPrivateTypeDelivery2"
                            name="isPrivateTypeDelivery"
                            (click)="forPermanent('2')"
                            class="custom-control-input"
                            checked
                          />
                          <label
                            class="custom-control-label"
                            for="isPrivateTypeDelivery2"
                            >{{ "CREATEJOB.no" | translate }}</label
                          >
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- FOR DISPATCH DRIVERS START-->

              <div *ngIf="jobFor == '2' && isDispatchSelected">
                <div class="row mt-3 align-items-center">
                  <div class="col-md-2">
                    <p class="font-weight-bold">
                      {{ "CREATEJOB.select_dispatch" | translate }}
                    </p>
                  </div>
                  <div class="col-md-10">
                    <div class="form-group">
                      <div class="my-custom-dropdown">
                        <ng-multiselect-dropdown
                          placeholder="{{
                            'CREATEJOB.select_dispatch' | translate
                          }}"
                          [settings]="dropdownSettingsDispatch"
                          [data]="connectedDispatches"
                          [(ngModel)]="selectedDispatchForJob"
                          [ngModelOptions]="{ standalone: true }"
                          (onSelect)="onItemSelectDispatch($event)"
                          (onDeSelect)="onDeSelectDispatch($event)"
                          (onSelectAll)="onSelectAllDispatch($event)"
                          (onDeSelectAll)="onDeSelectAllDispatch($event)"
                          style="width: 90%; background-color: white"
                        >
                        </ng-multiselect-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- FOR DISPATCH DRIVERS END -->

              <!-- initials input -->
              <div class="row mt-3">
                <div class="col-md-12">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{ 'CREATEJOB.job_initials' | translate }}"
                      formControlName="initials_delivery"
                      [ngClass]="{
                        'is-invalid': submitted && f.initials_delivery.errors
                      }"
                      maxlength="10"
                    />
                    <div
                      *ngIf="submitted && f.initials_delivery.errors"
                      class="invalid-feedback"
                    ></div>
                  </div>
                </div>
              </div>

              <div class="customfileupload filecstm-btn mb-3">
                <div class="d-flex align-items-center gap-1">
                  <div class="fileUpload">
                    <p>{{ "CREATEJOB.attach_doc" | translate }}</p>
                    <input
                      type="file"
                      class="uploadlogo"
                      accept="application/pdf,image/png, image/jpeg,image/jpg,.doc,.docx "
                      name="myfile"
                      (change)="onFileSelect($event)"
                    />
                  </div>
                  <div *ngIf="attachedDocument.docName" class="upload-label">
                    {{ attachedDocument.docName }}
                    <span class="ml-1 point" (click)="cancelDocument()">
                      <img src="assets/img/new-ui/close_attach_document.svg" />
                    </span>
                  </div>
                </div>
              </div>
              <div class="d-flex text-center">
                <span
                  class="reverse-checkbox-color custom-checkbox item-price-charge checkbox-style-1"
                >
                  <input
                    type="checkbox"
                    (click)="onChangeIsRoundTrip()"
                    [checked]="isRoundTrip"
                  />
                  <span
                    class="box"
                    [ngStyle]="{ 'background-color': '#2a2f3b' }"
                    style="margin-right: 0"
                    >{{
                      isRoundTrip
                        ? ("CREATEJOB.round_trip" | translate)
                        : ("CREATEJOB.one_way" | translate)
                    }}</span
                  >
                </span>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <app-button
                  [imageSrc]="'./assets/img/new-ui/save.svg'"
                  [buttonText]="
                    job_type_selection == '1'
                      ? ('CREATEJOB.delivery_request' | translate)
                      : ('CREATEJOB.pickup_request' | translate)
                  "
                  (click)="createJob()"
                ></app-button>
              </div>
            </div>
          </div>
          <!---end col-md-6-->
        </div>
        <!--end row-->
      </form>
    </div>
  </ng-template>
  <tabset>
    <tab
      customClass="delivery"
      (selectTab)="fetchNews($event)"
      id="tab1"
      [heading]="'CREATEJOB.delivery_job' | translate"
    >
      <ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
    </tab>
    <tab
      customClass="Pickup pickk-up-tab"
      (selectTab)="fetchNews($event)"
      id="tab2"
      [heading]="'CREATEJOB.pickup_job' | translate"
    >
      <ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
    </tab>
  </tabset>

  <!-- /.modal-content -->
</div>

<div
  bsModal
  #editUrgentJobModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-success" role="document">
    <div class="modal-content">
      <div class="modal-header bg-success">
        <h4 class="modal-title">
          {{ "CREATEJOB.edit_urgent_job" | translate }}
        </h4>
        <button
          type="button"
          class="close"
          (click)="editUrgentJobModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form action="/action_page.php">
          <div class="form-group">
            <label for="pwd"
              ><strong>{{ "CREATEJOB.enter_amount" | translate }}:</strong>
            </label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="urgent_charge"
              [ngModelOptions]="{ standalone: true }"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              placeholder="{{ 'CREATEJOB.enter_amount' | translate }}"
              maxlength="6"
            />
          </div>
        </form>
        <p class="text-danger">
          * {{ "CREATEJOB.changing_price_comment" | translate }}
        </p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="editUrgentJobModal.hide()"
        >
          {{ "CREATEJOB.cancel" | translate }}
        </button>
        <button type="button" class="btn btn-success" (click)="saveUrgentJob()">
          {{ "CREATEJOB.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<div
  bsModal
  #editNormalJobModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-success" role="document">
    <div class="modal-content">
      <div class="modal-header bg-success">
        <h4 class="modal-title">
          {{ "CREATEJOB.edit_normal_job" | translate }}
        </h4>
        <button
          type="button"
          class="close"
          (click)="editNormalJobModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form action="/action_page.php">
          <div class="form-group">
            <label for="pwd"
              ><strong>{{ "CREATEJOB.enter_amount" | translate }}:</strong>
            </label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="normal_charge"
              [ngModelOptions]="{ standalone: true }"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              placeholder="{{ 'CREATEJOB.enter_amount' | translate }}"
              maxlength="6"
            />
          </div>
        </form>
        <p class="text-danger">
          * {{ "CREATEJOB.changing_price_comment" | translate }}
        </p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="editNormalJobModal.hide()"
        >
          {{ "CREATEJOB.cancel" | translate }}
        </button>
        <button type="button" class="btn btn-success" (click)="saveNormalJob()">
          {{ "CREATEJOB.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #subscriptionModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-warning" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ "CREATEJOB.alert" | translate }}</h4>
        <button
          type="button"
          class="close"
          (click)="subscriptionModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <strong>{{ "CREATEJOB.subscription_comment" | translate }}</strong>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="subscriptionModal.hide()"
        >
          {{ "CREATEJOB.cancel" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="subscribeRedirect()"
        >
          {{ "CREATEJOB.view_plans" | translate }}
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
<div
  bsModal
  #cardModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-warning" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ "CREATEJOB.alert" | translate }}</h4>
        <button
          type="button"
          class="close"
          (click)="cardModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <strong>{{ "CREATEJOB.add_card_comment" | translate }}</strong>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="cardModal.hide()"
        >
          {{ "CREATEJOB.cancel" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="cardsRedirect()"
        >
          {{ "CREATEJOB.add_card" | translate }}
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->

<div
  bsModal
  #jobDuplicacyModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-warning" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ "CREATEJOB.alert" | translate }}</h4>
        <button
          type="button"
          class="close"
          (click)="jobDuplicacyModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <strong *ngIf="job_type_selection == '1'">{{
          "CREATEJOB.duplicate_job_comment_delivery" | translate
        }}</strong>
        <strong *ngIf="job_type_selection == '2'">{{
          "CREATEJOB.duplicate_job_comment_pickup" | translate
        }}</strong>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="jobDuplicacyModal.hide()"
        >
          {{ "CREATEJOB.proceed" | translate }}
        </button>

        <button
          *ngIf="job_type_selection == '1'"
          type="button"
          class="btn btn-secondary"
          (click)="
            navigateToEditJob(editJobBodyTemplate, editJobHeaderTemplate)
          "
        >
          {{ "CREATEJOB.edit_previous_delivery" | translate }}
        </button>

        <button
          *ngIf="job_type_selection == '2'"
          type="button"
          class="btn btn-secondary"
          (click)="navigateToEditJob()"
        >
          {{ "CREATEJOB.edit_previous_pickup" | translate }}
        </button>

        <button type="button" class="btn btn-secondary" (click)="refresh()">
          {{ "CREATEJOB.cancel" | translate }}
        </button>
        <!-- <button type="button" class="btn btn-secondary"
          >Proceed</button> -->
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>

<div
  bsModal
  #addJobTagModal="bs-modal"
  class="modal fade addJobTagModal"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          {{ "TAG.add_new_tag" | translate }}
        </h4>
        <button
          type="button"
          class="close"
          (click)="addJobTagModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="addJobTagForm" (onSubmit)="addJobTag()">
          <table class="table table-sm">
            <tbody>
              <tr>
                <td class="font-weight-bold">
                  {{ "TAG.tag_name_english" | translate }}
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    name="tag_for"
                    formControlName="tag_for"
                    placeholder="{{ 'TAG.tag_name_english' | translate }}"
                    [(ngModel)]="tag.tag_for"
                    [ngClass]="{
                      'is-invalid':
                        submittedAddJobTagForm &&
                        addJobTagForm.controls.tag_for.errors
                    }"
                  />
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  {{ "TAG.tag_name_french" | translate }}
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    name="tag_for_french"
                    formControlName="tag_for_french"
                    placeholder="{{ 'TAG.tag_name_french' | translate }}"
                    [(ngModel)]="tag.tag_for_french"
                    [ngClass]="{
                      'is-invalid':
                        submittedAddJobTagForm &&
                        addJobTagForm.controls.tag_for_french.errors
                    }"
                  />
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  {{ "TAG.tag_code" | translate }}
                </td>
                <td>
                  <input
                    type="color"
                    class="form-control"
                    name="tag_code"
                    style="max-width: 6em; padding: 1px"
                    formControlName="tag_code"
                    [(ngModel)]="tag.tag_code"
                    [ngClass]="{
                      'is-invalid':
                        submittedAddJobTagForm &&
                        addJobTagForm.controls.tag_code.errors
                    }"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div class="modal-footer">
        <app-button
          [imageSrc]="'./assets/img/new-ui/cancel.svg'"
          [buttonText]="'TAG.cancel' | translate"
          (buttonClick)="cancelAddJobTag()"
        ></app-button>
        <app-button
          [imageSrc]="'./assets/img/new-ui/save.svg'"
          [buttonText]="'TAG.save' | translate"
          (buttonClick)="addJobTag()"
        ></app-button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>

<ng-template #locationHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3 justify-content-between">
    <h5 class="modal-title">{{ "LOCATION.select_location" | translate }}</h5>
    <ng-container
      *ngTemplateOutlet="locationAgmComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #locationBodyTemplate>
  <div class="mx-3">
    <app-location-agm
      [item]="locationData"
      (getLocationData)="onChangeLocationMap($event)"
      [activeAlias]="mapWithAlias"
      [defaultOtherAddress]="true"
    ></app-location-agm>
  </div>
</ng-template>

<ng-template #editJobHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3">
    <ng-container
      *ngTemplateOutlet="editJobComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #editJobBodyTemplate>
  <app-edit-job
    [job_id]="duplicate_job_id"
    [parentModalRef]="bsModalRef"
  ></app-edit-job>
</ng-template>
