<div class="row justify-content-between scp-breadcrumb mb-4">
  <div class="b-jobs">
    <a>{{ "IMPORTJOBS.import_jobs_comment" | translate }} </a>
  </div>

  <div class="d-flex col-md-6 gap-1">
    <div class="flex-grow-1">
      <form [formGroup]="uploadForm">
        <div *ngIf="!csvRecords.length" class="customfileupload">
          <div class="fileUpload blue-btn btn width100">
            <span>{{ "IMPORTJOBS.select_file" | translate }}</span>
            <input
              type="file"
              class="uploadlogo"
              accept=".csv"
              name="myfile"
              (change)="onFileSelect($event)"
            />
          </div>
          <label *ngIf="fileInputLabel; else noFile">{{
            fileInputLabel
          }}</label>
          <ng-template #noFile>
            <label>{{ "IMPORTJOBS.no_choosen" | translate }}</label>
          </ng-template>
        </div>
      </form>
    </div>
    <div
      *ngIf="csvRecords.controls.length || arrayJobsSuccess.length > 0"
      class="d-flex align-items-end justify-content-center"
    >
      <span>
        {{ "IMPORTJOBS.total_jobs" | translate }} :
        <b> {{ totalRecordsLoaded }} </b>
      </span>
      <ng-container
        *ngIf="arrayJobsSuccess.length > 0 || arrayJobsError.length > 0"
      >
        <span class="ml-3 text-success">
          {{ "IMPORTJOBS.success" | translate }}
          <b> {{ arrayJobsSuccess.length }} </b>
        </span>
        <span class="ml-3 text-danger">
          {{ "IMPORTJOBS.error" | translate }}
          <b> {{ arrayJobsError.length }} </b>
        </span>
      </ng-container>
    </div>
    <div
      *ngIf="
        (arrayJobsSuccess.length &&
          arrayJobsError.length &&
          uploadForm.get('uploadFile').value) ||
        !arrayJobsSuccess.length
      "
      style="margin-bottom: 0.5rem"
    ></div>
    <div
      *ngIf="uploadForm.get('uploadFile').value"
      class="d-flex flex-row-reverse"
    >
      <app-button
        [imageSrc]="'./assets/img/new-ui/cancel.svg'"
        [buttonText]="'IMPORTJOBS.cancel' | translate"
        (buttonClick)="cancel()"
      ></app-button>
      <app-button
        [imageSrc]="'./assets/img/new-ui/import_jobs.svg'"
        [buttonText]="'IMPORTJOBS.import_jobs' | translate"
        (buttonClick)="onSubmit()"
      ></app-button>
    </div>
    <div>
      <app-button
        *ngIf="!csvRecords.length"
        [imageSrc]="'./assets/img/new-ui/download_csv.svg'"
        [buttonText]="'IMPORTJOBS.download_template' | translate"
        (buttonClick)="downloadTemplate()"
      ></app-button>
    </div>
  </div>
</div>
<div class="card mt-3">
  <div class="card-body">
    <div>
      <div>
        <form [formGroup]="formGroup">
          <div class="scroolTable">
            <!-- <div class="row" formArrayName="rowsJobs" *ngFor="let row of csvRecords.controls; let i = index"
            >
              <div [formGroupName]="i">
                <div>
                  <input type="text" class="form-control" formControlName="JobType">
                  <input type="text" class="form-control" formControlName="CustomerFirstName">
                  <input type="text" class="form-control" formControlName="CustomerLastName">
                </div>
              </div>

            </div> -->
            <table
              *ngIf="csvRecords.getRawValue().length"
              class="responsive-table table table-border table-striped table-import-jobs"
            >
              <thead class="bg-light">
                <tr>
                  <th>N°</th>
                  <th>{{ "IMPORTJOBS.JobType" | translate }}</th>
                  <th>{{ "IMPORTJOBS.CustomerFirstName" | translate }}</th>
                  <th>{{ "IMPORTJOBS.CustomerLastName" | translate }}</th>
                  <th>{{ "IMPORTJOBS.CustomerStreetAddress" | translate }}</th>
                  <th>{{ "IMPORTJOBS.CustomerCity" | translate }}</th>
                  <th>{{ "IMPORTJOBS.CustomerState" | translate }}</th>
                  <th>{{ "IMPORTJOBS.CustomerCountry" | translate }}</th>
                  <th>{{ "IMPORTJOBS.CustomerZipCode" | translate }}</th>
                  <th>{{ "IMPORTJOBS.CustomerPhone" | translate }}</th>
                  <th>{{ "IMPORTJOBS.CustomerEmail" | translate }}</th>
                  <th>{{ "IMPORTJOBS.AppartmentNo" | translate }}</th>
                  <th>{{ "IMPORTJOBS.DoorCode" | translate }}</th>
                  <th>{{ "IMPORTJOBS.Note" | translate }}</th>
                  <th>{{ "IMPORTJOBS.ItemPrice" | translate }}</th>
                  <th>{{ "IMPORTJOBS.DeliveryType" | translate }}</th>
                  <th>{{ "IMPORTJOBS.JobFor" | translate }}</th>
                  <th>{{ "IMPORTJOBS.DriversUsername" | translate }}</th>
                  <th>{{ "IMPORTJOBS.JobDate" | translate }}</th>
                  <th>{{ "IMPORTJOBS.EmployeeInitials" | translate }}</th>
                  <th>{{ "IMPORTJOBS.JobTags" | translate }}</th>
                  <th>{{ "IMPORTJOBS.TimingTags" | translate }}</th>
                  <th>{{ "IMPORTJOBS.Actions" | translate }}</th>
                </tr>
              </thead>

              <tbody style="overflow: auto">
                <tr
                  class="tr-job"
                  [ngClass]="{
                    'row-invalid':
                      submitted && controls.rowsJobs.controls[i].invalid
                  }"
                  formArrayName="rowsJobs"
                  *ngFor="let row of csvRecords.controls; let i = index"
                >
                  <ng-container [formGroupName]="i">
                    <td class="pt-3">{{ i + 1 }}</td>
                    <td class="pt-3">
                      <select
                        class="form-control-sm"
                        formControlName="JobType"
                        [ngClass]="{
                          'is-invalid-two':
                            submitted &&
                            controls.rowsJobs.controls[i].controls.JobType
                              .errors
                        }"
                      >
                        <option value="delivery">Delivery</option>
                        <option value="pickup">Pickup</option>
                      </select>
                      <!-- <input type="text" class="form-control-sm" formControlName="JobType"
                        [ngClass]="{ 'is-invalid-two': submitted && controls.rowsJobs.controls[i].controls.JobType.errors }"> -->
                      <div
                        *ngIf="
                          submitted &&
                          controls.rowsJobs.controls[i].controls.JobType.errors
                        "
                        class="text-danger"
                      >
                        <small
                          *ngIf="
                            controls.rowsJobs.controls[i].controls.JobType
                              .errors.required
                          "
                        >
                          {{ "IMPORTJOBS.field_required" | translate }}
                        </small>
                      </div>
                    </td>
                    <td class="pt-3">
                      <input
                        type="text"
                        class="form-control-sm"
                        formControlName="CustomerFirstName"
                        [ngClass]="{
                          'is-invalid-two':
                            submitted &&
                            controls.rowsJobs.controls[i].controls
                              .CustomerFirstName.errors
                        }"
                      />
                      <div
                        *ngIf="
                          submitted &&
                          controls.rowsJobs.controls[i].controls
                            .CustomerFirstName.errors
                        "
                        class="text-danger"
                      >
                        <small
                          *ngIf="
                            controls.rowsJobs.controls[i].controls
                              .CustomerFirstName.errors.required
                          "
                        >
                          {{ "IMPORTJOBS.field_required" | translate }}
                        </small>
                      </div>
                    </td>
                    <td class="pt-3">
                      <input
                        type="text"
                        class="form-control-sm"
                        formControlName="CustomerLastName"
                        [ngClass]="{
                          'is-invalid-two':
                            submitted &&
                            controls.rowsJobs.controls[i].controls
                              .CustomerLastName.errors
                        }"
                      />
                      <div
                        *ngIf="
                          submitted &&
                          controls.rowsJobs.controls[i].controls
                            .CustomerLastName.errors
                        "
                        class="text-danger"
                      >
                        <small
                          *ngIf="
                            controls.rowsJobs.controls[i].controls
                              .CustomerLastName.errors.required
                          "
                        >
                          {{ "IMPORTJOBS.field_required" | translate }}
                        </small>
                      </div>
                    </td>
                    <td class="pt-3">
                      <input
                        type="text"
                        class="form-control-sm"
                        formControlName="CustomerStreetAddress"
                        style="width: 200px !important"
                        [ngClass]="{
                          'is-invalid-two':
                            submitted &&
                            controls.rowsJobs.controls[i].controls
                              .CustomerStreetAddress.errors
                        }"
                      />
                      <div
                        *ngIf="
                          submitted &&
                          controls.rowsJobs.controls[i].controls
                            .CustomerStreetAddress.errors
                        "
                        class="text-danger"
                      >
                        <small
                          *ngIf="
                            controls.rowsJobs.controls[i].controls
                              .CustomerStreetAddress.errors.required
                          "
                        >
                          {{ "IMPORTJOBS.field_required" | translate }}
                        </small>
                      </div>
                    </td>
                    <td class="pt-3">
                      <input
                        type="text"
                        class="form-control-sm"
                        formControlName="CustomerCity"
                        [ngClass]="{
                          'is-invalid-two':
                            submitted &&
                            controls.rowsJobs.controls[i].controls.CustomerCity
                              .errors
                        }"
                      />
                      <div
                        *ngIf="
                          submitted &&
                          controls.rowsJobs.controls[i].controls.CustomerCity
                            .errors
                        "
                        class="text-danger"
                      >
                        <small
                          *ngIf="
                            controls.rowsJobs.controls[i].controls.CustomerCity
                              .errors.required
                          "
                        >
                          {{ "IMPORTJOBS.field_required" | translate }}
                        </small>
                      </div>
                    </td>
                    <td class="pt-3">
                      <input
                        type="text"
                        class="form-control-sm"
                        formControlName="CustomerState"
                        [ngClass]="{
                          'is-invalid-two':
                            submitted &&
                            controls.rowsJobs.controls[i].controls.CustomerState
                              .errors
                        }"
                      />
                      <div
                        *ngIf="
                          submitted &&
                          controls.rowsJobs.controls[i].controls.CustomerState
                            .errors
                        "
                        class="text-danger"
                      >
                        <small
                          *ngIf="
                            controls.rowsJobs.controls[i].controls.CustomerState
                              .errors.required
                          "
                        >
                          {{ "IMPORTJOBS.field_required" | translate }}
                        </small>
                      </div>
                    </td>
                    <td class="pt-3">
                      <input
                        type="text"
                        class="form-control-sm"
                        formControlName="CustomerCountry"
                        [ngClass]="{
                          'is-invalid-two':
                            submitted &&
                            controls.rowsJobs.controls[i].controls
                              .CustomerCountry.errors
                        }"
                      />
                      <div
                        *ngIf="
                          submitted &&
                          controls.rowsJobs.controls[i].controls.CustomerCountry
                            .errors
                        "
                        class="text-danger"
                      >
                        <small
                          *ngIf="
                            controls.rowsJobs.controls[i].controls
                              .CustomerCountry.errors.required
                          "
                        >
                          {{ "IMPORTJOBS.field_required" | translate }}
                        </small>
                      </div>
                    </td>
                    <td class="pt-3">
                      <input
                        type="text"
                        class="form-control-sm"
                        formControlName="CustomerZipCode"
                      />
                    </td>
                    <td class="pt-3">
                      <input
                        type="text"
                        class="form-control-sm"
                        formControlName="CustomerPhone"
                        [ngClass]="{
                          'is-invalid-two':
                            submitted &&
                            controls.rowsJobs.controls[i].controls.CustomerPhone
                              .errors
                        }"
                      />
                      <div
                        *ngIf="
                          submitted &&
                          controls.rowsJobs.controls[i].controls.CustomerPhone
                            .errors
                        "
                        class="text-danger"
                      >
                        <small
                          *ngIf="
                            controls.rowsJobs.controls[i].controls.CustomerPhone
                              .errors.required
                          "
                        >
                          {{ "IMPORTJOBS.field_required" | translate }}
                        </small>
                        <small
                          *ngIf="
                            controls.rowsJobs.controls[i].controls.CustomerPhone
                              .errors.minlength ||
                            controls.rowsJobs.controls[i].controls.CustomerPhone
                              .errors.maxlength
                          "
                        >
                          {{ "IMPORTJOBS.equal_digits" | translate }}
                        </small>
                      </div>
                    </td>
                    <td class="pt-3">
                      <input
                        type="email"
                        class="form-control-sm"
                        formControlName="CustomerEmail"
                        [ngClass]="{
                          'is-invalid-two':
                            submitted &&
                            controls.rowsJobs.controls[i].controls.CustomerEmail
                              .errors
                        }"
                      />
                      <div
                        *ngIf="
                          submitted &&
                          controls.rowsJobs.controls[i].controls.CustomerEmail
                            .errors
                        "
                        class="text-danger"
                      >
                        <small
                          *ngIf="
                            controls.rowsJobs.controls[i].controls.CustomerEmail
                              .errors.required
                          "
                        >
                          {{ "IMPORTJOBS.field_required" | translate }}
                        </small>
                        <!-- error email -->
                        <small
                          *ngIf="
                            controls.rowsJobs.controls[i].controls.CustomerEmail
                              .errors.email
                          "
                        >
                          Email invalid
                        </small>
                      </div>
                    </td>
                    <td class="pt-3">
                      <input
                        type="text"
                        class="form-control-sm"
                        formControlName="AppartmentNo"
                      />
                    </td>
                    <td class="pt-3">
                      <input
                        type="text"
                        class="form-control-sm"
                        formControlName="DoorCode"
                      />
                    </td>
                    <td class="pt-3">
                      <input
                        type="text"
                        class="form-control-sm"
                        formControlName="Note"
                      />
                    </td>
                    <td class="pt-3">
                      <input
                        type="number"
                        class="form-control-sm"
                        formControlName="ItemPrice"
                        [ngClass]="{
                          'is-invalid-two':
                            submitted &&
                            controls.rowsJobs.controls[i].controls.ItemPrice
                              .errors
                        }"
                      />
                      <div
                        *ngIf="
                          submitted &&
                          controls.rowsJobs.controls[i].controls.ItemPrice
                            .errors
                        "
                        class="text-danger"
                      >
                        <small
                          *ngIf="
                            controls.rowsJobs.controls[i].controls.ItemPrice
                              .errors.required
                          "
                        >
                          {{ "IMPORTJOBS.field_required" | translate }}
                        </small>
                      </div>
                    </td>
                    <td class="pt-3">
                      <select
                        class="form-control-sm"
                        formControlName="DeliveryType"
                        [ngClass]="{
                          'is-invalid-two':
                            submitted &&
                            controls.rowsJobs.controls[i].controls.DeliveryType
                              .errors
                        }"
                      >
                        <option value="normal">Normal</option>
                        <option value="urgent">Urgent</option>
                      </select>
                      <!-- <input type="text" class="form-control-sm" formControlName="DeliveryType"
                        [ngClass]="{ 'is-invalid-two': submitted && controls.rowsJobs.controls[i].controls.DeliveryType.errors }"> -->
                      <div
                        *ngIf="
                          submitted &&
                          controls.rowsJobs.controls[i].controls.DeliveryType
                            .errors
                        "
                        class="text-danger"
                      >
                        <small
                          *ngIf="
                            controls.rowsJobs.controls[i].controls.DeliveryType
                              .errors.required
                          "
                        >
                          {{ "IMPORTJOBS.field_required" | translate }}
                        </small>
                      </div>
                    </td>
                    <td class="pt-3">
                      <select
                        class="form-control-sm"
                        formControlName="JobFor"
                        [ngClass]="{
                          'is-invalid-two':
                            submitted &&
                            controls.rowsJobs.controls[i].controls.JobFor.errors
                        }"
                      >
                        <option value="public">public</option>
                        <option value="private">private</option>
                        <option value="dispatch">dispatch</option>
                      </select>
                      <div
                        *ngIf="
                          submitted &&
                          controls.rowsJobs.controls[i].controls.JobFor.errors
                        "
                        class="text-danger"
                      >
                        <small
                          *ngIf="
                            controls.rowsJobs.controls[i].controls.JobFor.errors
                              .required
                          "
                        >
                          Field required
                        </small>
                      </div>
                    </td>
                    <td class="pt-3">
                      <input
                        type="text"
                        class="form-control-sm"
                        formControlName="DriversUsername"
                      />
                    </td>
                    <td class="pt-3">
                      <input
                        type="date"
                        class="form-control-sm"
                        formControlName="JobDate"
                        style="width: 115px"
                        [ngClass]="{
                          'is-invalid-two':
                            submitted &&
                            controls.rowsJobs.controls[i].controls.JobDate
                              .errors
                        }"
                      />
                      <div
                        *ngIf="
                          submitted &&
                          controls.rowsJobs.controls[i].controls.JobDate.errors
                        "
                        class="text-danger"
                      >
                        <small
                          *ngIf="
                            controls.rowsJobs.controls[i].controls.JobDate
                              .errors.required
                          "
                        >
                          Field required
                        </small>
                      </div>
                    </td>
                    <td class="pt-3">
                      <input
                        type="text"
                        class="form-control-sm"
                        formControlName="EmployeeInitials"
                      />
                    </td>
                    <td class="pt-3">
                      <input
                        type="text"
                        class="form-control-sm"
                        formControlName="JobTags"
                      />
                    </td>
                    <td class="pt-3">
                      <select
                        class="form-control-sm"
                        formControlName="TimingTags"
                        [ngClass]="{
                          'is-invalid-two':
                            submitted &&
                            controls.rowsJobs.controls[i].controls.TimingTags
                              .errors
                        }"
                      >
                        <option value="morning">morning</option>
                        <option value="afternoon">afternoon</option>
                        <option value="evening">evening</option>
                        <option value="night">night</option>
                      </select>
                      <!-- <input type="text" class="form-control-sm" formControlName="TimingTags"
                        [ngClass]="{ 'is-invalid-two': submitted && controls.rowsJobs.controls[i].controls.TimingTags.errors }"> -->
                      <div
                        *ngIf="
                          submitted &&
                          controls.rowsJobs.controls[i].controls.TimingTags
                            .errors
                        "
                        class="text-danger"
                      >
                        <small
                          *ngIf="
                            controls.rowsJobs.controls[i].controls.TimingTags
                              .errors.required
                          "
                        >
                          Field required
                        </small>
                      </div>
                    </td>
                    <td class="pt-3">
                      <button
                        class="btn btn-danger btn-sm"
                        (click)="onDeleteRow(i)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>

    <br />

    <div *ngIf="arrayJobsSuccess?.length" class="my-3">
      <small>
        <h6
          class="py-2 px-3"
          style="
            background-color: rgb(208, 248, 224);
            color: darkgreen;
            border-radius: 8px;
          "
        >
          <b>{{ "IMPORTJOBS.jobs_created_successfully" | translate }}</b>
        </h6>
      </small>
      <table class="table table-bordered table-striped table-responsive">
        <thead>
          <tr>
            <th></th>
            <th>N°</th>
            <th>{{ "IMPORTJOBS.JobType" | translate }}</th>
            <th>{{ "IMPORTJOBS.CustomerFirstName" | translate }}</th>
            <th>{{ "IMPORTJOBS.CustomerLastName" | translate }}</th>
            <th>{{ "IMPORTJOBS.CustomerStreetAddress" | translate }}</th>
            <th>{{ "IMPORTJOBS.CustomerCity" | translate }}</th>
            <th>{{ "IMPORTJOBS.CustomerState" | translate }}</th>
            <th>{{ "IMPORTJOBS.CustomerCountry" | translate }}</th>
            <th>{{ "IMPORTJOBS.CustomerZipCode" | translate }}</th>
            <th>{{ "IMPORTJOBS.CustomerPhone" | translate }}</th>
            <th>{{ "IMPORTJOBS.CustomerEmail" | translate }}</th>
            <th>{{ "IMPORTJOBS.AppartmentNo" | translate }}</th>
            <th>{{ "IMPORTJOBS.DoorCode" | translate }}</th>
            <th>{{ "IMPORTJOBS.Note" | translate }}</th>
            <th>{{ "IMPORTJOBS.ItemPrice" | translate }}</th>
            <th>{{ "IMPORTJOBS.DeliveryType" | translate }}</th>
            <th>{{ "IMPORTJOBS.JobFor" | translate }}</th>
            <th>{{ "IMPORTJOBS.DriversUsername" | translate }}</th>
            <th>{{ "IMPORTJOBS.JobDate" | translate }}</th>
            <th>{{ "IMPORTJOBS.EmployeeInitials" | translate }}</th>
            <th>{{ "IMPORTJOBS.JobTags" | translate }}</th>
            <th>{{ "IMPORTJOBS.TimingTags" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let job of arrayJobsSuccess; let i = index">
            <td class="text-center">
              <!-- icon check -->
              <i class="fa fa-check text-success" aria-hidden="true"></i>
            </td>
            <td>{{ i + 1 }}</td>
            <td>{{ job?.JobType }}</td>
            <td>{{ job?.CustomerFirstName }}</td>
            <td>{{ job?.CustomerLastName }}</td>
            <td>{{ job?.CustomerStreetAddress }}</td>
            <td>{{ job?.CustomerCity }}</td>
            <td>{{ job?.CustomerState }}</td>
            <td>{{ job?.CustomerCountry }}</td>
            <td>{{ job?.CustomerZipCode }}</td>
            <td>{{ job?.CustomerPhone }}</td>
            <td>{{ job?.CustomerEmail }}</td>
            <td>{{ job?.AppartmentNo }}</td>
            <td>{{ job?.DoorCode }}</td>
            <td>{{ job?.Note }}</td>
            <td>{{ job?.ItemPrice }}</td>
            <td>{{ job?.DeliveryType }}</td>
            <td>{{ job?.JobFor }}</td>
            <td>{{ job?.DriversUsername }}</td>
            <td>{{ job?.JobDate }}</td>
            <td>{{ job?.EmployeeInitials }}</td>
            <td>{{ job?.JobTags }}</td>
            <td>{{ job?.TimingTags }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- DATA ANALYZE MODAL -->
<!-- <div bsModal #csvAnalyzeModal="bs-modal" class="modal fade modal-lg"  [config]="{ backdrop: 'static' ,keyboard : false}"  tabindex="-1" role="dialog" aria-labelledby="PriceModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header bg-success">
          <h5 class="modal-title" id="exampleModalLabel">Confirm Location</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="csvAnalyzeModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form >
                <div class="scroolTable">
                    <table class="responsive-table table table-border table-striped " >
                        
                            <thead class="bg-light">
                          <tr>
                          
                          <th>First Name</th>
                           <th>Last Name</th>
                           <th>Phone No.</th>
                          <th>Old Location</th>
                          <th>New Location</th>
                         
                        </tr>
                        </thead>
                            
                    <tbody style="max-height:200px; overflow: auto;" >
                      <tr *ngFor="let job of allJobsArray  let i = index">
                          
                        <td class="pt-3"><strong>{{job.CustomerFirstName}}</strong></td>
                         <td class="pt-3"><strong>{{job.CustomerLastName}}</strong></td>
                         <td class="pt-3"><strong>{{job.CustomerPhone}}</strong></td>
                        <td class="pt-3"><strong>{{job.customerOldLocation}}</strong>></td>
                        <td class="pt-3"><strong>{{job.customerNewLocation}}</strong>></td>
                      </tr>						    
                    </tbody>
                    
                  </table>
                </div>
     </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="csvAnalyzeModal.hide()">Close</button>
          <button type="button" class="btn btn-success" (click)="editPrice()">Save</button>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- DATA ANALYZE MODAL -->

<!-- <div bsModal #csvAnalyzeModal="bs-modal" [config]="{ backdrop: 'static' ,keyboard : false}"
  class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header bg-warning">
        <h5 class="modal-title" id="exampleModalLabel">{{ 'CREATEJOB.confirm_address' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="csvAnalyzeModal.hide(); cancel();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="scroolTable">
            <table class="responsive-table table table-border table-striped ">

              <thead class="bg-light">
                <tr>

                  <th>{{ 'CREATEJOB.firstname' | translate }}</th>
                  <th>{{ 'CREATEJOB.lastname' | translate }}</th>
                  <th>{{ 'CREATEJOB.phone_no' | translate }}</th>
                  <th>{{ 'CREATEJOB.old_address' | translate }}</th>
                  <th>{{ 'CREATEJOB.new_address' | translate }}</th>

                </tr>
              </thead>

              <tbody style="max-height:200px; overflow: auto;">
                <tr *ngFor="let job of allJobsArray  let i = index">

                  <td *ngIf="job.customerOldLocation && job.customerNewLocation" class="pt-3">
                    <strong>{{job.CustomerFirstName}}</strong>
                  </td>
                  <td *ngIf="job.customerOldLocation && job.customerNewLocation" class="pt-3">
                    <strong>{{job.CustomerLastName}}</strong>
                  </td>
                  <td *ngIf="job.customerOldLocation && job.customerNewLocation" class="pt-3">
                    <strong>{{job.CustomerPhone}}</strong>
                  </td>
                  <td *ngIf="job.customerOldLocation && job.customerNewLocation" class="pt-3"
                    (click)="jobDetailsChange(i,job,'old')">
                    <div class="custom-control custom-radio my-custom-label">
                      <input id="{{i}}_customerOldLocation" type="radio" name="{{i}}_location_selection" checked
                        class="custom-control-input" />
                      <label class="custom-control-label"
                        for="{{i}}_customerOldLocation">{{job.customerOldLocation}}</label>
                    </div>

                  </td>
                  <td *ngIf="job.customerOldLocation && job.customerNewLocation" class="pt-3"
                    (click)="jobDetailsChange(i,job,'new')">
                    <div class="custom-control custom-radio my-custom-label">
                      <input id="{{i}}_customerNewLocation" type="radio" name="{{i}}_location_selection"
                        class="custom-control-input" />
                      <label class="custom-control-label"
                        for="{{i}}_customerNewLocation">{{job.customerNewLocation}}</label>
                    </div>
                  </td>

                </tr>

              </tbody>
            </table>
          </div>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal"
            (click)="csvAnalyzeModal.hide(); cancel();">{{'IMPORTJOBS.cancel' | translate }}</button>
          <button type="button" class="btn btn-success" (click)="importJobs()">{{ 'CREATEJOB.proceed' | translate
            }}</button>
        </div>
      </div>
    </div>
  </div>
</div> -->
