import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import 'moment-timezone';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';

@Component({
  selector: 'app-category-update',
  templateUrl: './category-update.component.html',
  styleUrls: ['./category-update.component.scss']
})
export class CategoryUpdateComponent implements OnInit {
  editcategoryForm: UntypedFormGroup;
  public language = "en";
  file!: File;
  id: '';
  pharmacyId: ''
  name: any;
  nameFr: any;
  isFeatured2: any;
  brand: [];
  updateImage: boolean;


  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    public routern: Router,
    public routeAct: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en"
    }

    this.editcategoryForm = this.formBuilder.group({

      name: ['', [Validators.required]],
      nameFr: ['', [Validators.required]],
      isFeatured: [1]

    });
    // Id de la farmacia
    var localDataUser = JSON.parse(localStorage.getItem("currentUser"));
    this.pharmacyId = localDataUser._id;

    this.id = this.routeAct.snapshot.params["id"];

    var localData = JSON.parse(localStorage.getItem("currentCategory"));
    console.log("console de localData", localData);

    this.services.getCategoryId(this.id).pipe().subscribe((data) => {

      this.spinner.hide();

      this.brand = data;


      localStorage.setItem('currentCategory', JSON.stringify(this.id));
      localStorage.setItem('currentFile', JSON.stringify(this.file));
      this.name = data.name;
      this.nameFr = data.nameFr;
      this.file = data.keyS3;
      this.isFeatured2 = data.isFeatured;
      this.id = data._id;
      console.log("Consola de variable data", data);
    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });

    //TEMPLATE Category FORM 

    this.editcategoryForm.controls['name'].setValue(localData.name);
    this.editcategoryForm.controls['nameFr'].setValue(localData.nameFr);
  }


  back() {
    this.router.navigate(['/category']);
  }

  onFileSelect(event) {
    console.log(event);
    /*
    let files;
    
    const reader = new FileReader();
    
    reader.onload = () => {
      files = reader.result;
      if(files != undefined ){
        this.updateImage = true;
        reader.readAsText(event.target.files[0]);
      }else {
        
        this.updateImage = false ;
      }
      console.log(files);
      
    };
    
    console.log(files)
    */
  }

  get f() { return this.editcategoryForm.controls; }


  categoryUpdate() {

    console.log(this.updateImage);
    const values = this.editcategoryForm.value;


    console.log(values);
    console.log(this.id);


    if (!this.f.name.value.trim()) {
      return this.toastr.error('', 'Please enter first name');
    }
    else if (!this.f.nameFr.value.trim()) {
      return this.toastr.error('', 'Please enter last name');
    }



    if (this.f.name.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.name.value);
      if (testing == true) {
        return this.toastr.error('', 'name not valid!');
      }
    }
    if (this.f.nameFr.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.nameFr.value);
      if (testing == true) {
        return this.toastr.error('', 'name Fr not valid!');
      }

      if (this.editcategoryForm.invalid) {
        return;
      }
    }

    console.log(this.file);
    this.spinner.show();


    this.services.updateCategory(this.id, values, this.brand, this.pharmacyId).pipe().subscribe(data => {
      console.log(data)
      this.spinner.hide();
      this.ngOnInit();
      this.routern.navigate(['/category']);
      return this.toastr.success('successfully update Category');

    }, error => {
      this.spinner.hide()
      return this.toastr.error('error', error.error.message);
    });



  }


}
