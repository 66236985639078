import { Component, OnInit } from '@angular/core';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
   selector: 'app-job-recursive',
   templateUrl: './job-recursive.component.html',
   styleUrls: ['./job-recursive.component.scss']
})
export class JobRecursiveComponent implements OnInit {
   adminTax = 0;
   adminProcessingFee = 0;
   weekendCharge = 0;
   nightCharge = 0;
   normalNightCharge = 0;
   urgentNightCharge = 0;
   normalNightCharge1 = 0;
   urgentNightCharge1 = 0;
   jobTypeSelection: Number = 1;
   jobTypeSelection1: Number = 1;
   disableTags = true;
   disableTags1 = true;
   dateForJob: any;
   dateForJob1: any;
   weekend_charge_normal = 0;
   weekend_charge_urgent = 0
   weekend_charge1_normal = 0;
   weekend_charge1_urgent = 0;
   timeForAutoJob: any;
   dateForAutoJob: any;
   job_recurring_type = 1;
   readonlyInputs = true;
   selectedValue = '8';
   dateValue = '0';
   dailyJobType = 1; // 1 for daily, 2 for mon-sat, 3 mon-fri
   timeForAutoJob1: any;
   job_recurring_type1 = 1;
   selectedValue1 = '8';
   dateValue1 = '0';
   dailyJobType1 = 1;
   dropdownList = [];
   weekDays = [];
   weekDays1 = [];
   dropdownSettings: IDropdownSettings;
   establishments = [];
   establishments1 = [];
   establish_name: any;
   establish_name1: any;
   isDisableItemPrice = 1; // 1 for enable input 2 for diable input
   dropdownSettings1: IDropdownSettings;
   dropdownSettings2: IDropdownSettings; // FOR SELECT CUSTOMERS
   selected_drivers = [];
   stateProcessingFees = 0;
   state_tax_id: any;
   minuteStep = 10;
   testTag = false;
   username: any;
   customerTypeSelection = 1;
   multipleArray = [];
   multiple_customers: any;
   multiple_customers_id = [];
   establishment_id: any;
   residenceAllClients = [];
   multiple_customers_info: any[];
   customer_est_id = null;
   openOptionalAddress = false;
   optional_location: any;
   selected_type = '0';
   residenceAllClient = [];
   to_be_received_by_type = '0';
   establishmentTagSelection = '0';
   lastTypeSelected = '0';
   cust_l_name: any;
   cust_l_name_pickup: any;
   recursiveEndDateDelivery: any;
   recursiveEndDatePickup: any;
   job_type_selection = '1';

   //NEW KEYS
   totalTagsPrices = 0;
   dropdownSettingsDispatch: IDropdownSettings;

   assignedDriversByDispatch = []; // driver ids assigned by dispatch
   selected_drivers_dispatch = [];// selected driver for job by dispatch
   selected_drivers_dispatch_username = [];

   // dispatchCompanyId: any;
   isDispatchSelected = false;

   connectedDispatches = [];
   selectedDispatchForJob = [];
   stateTaxObj = {};
   packageObj = { package: '0', small: '0', large: '0', extraLarge: '0', label: '0/0', weight: '0' };
   jobTimingObj = {
      type: 0,
      startTime: { hour: 9, minute: 30 },
      endTime: { hour: 12, minute: 30 },
      nameEn: "",
      nameFr: "",

   }
   attachedDocument = { url: "", docName: "" };
   establishment_phone = "";
   duplicate_job_id = "";

   public timeZone: any;
   public pharmacy: any;

   customer_type = 1;
   delivery_type = '2';
   customer_type1 = 1;
   delivery_type1 = '2';

   location1: string;
   public dial_code: any;
   public options: any;
   public jobFor1 = '1';
   public jobFor = '1';
   public language = "en";

   sLat_long = [];
   eLat_long = [];

   sLat_long1 = [];
   eLat_long1 = [];
   constructor(public config: NgbDatepickerConfig) { }

   ngOnInit(): void {
      this.totalTagsPrices = 0;
      this.selectedDispatchForJob = [];
      this.connectedDispatches = [];
      this.packageObj = { package: '0', small: '0', large: '0', extraLarge: '0', label: '0/0', weight: '0' };

      this.jobTimingObj = {
         type: 0,
         startTime: { hour: 9, minute: 30 },
         endTime: { hour: 12, minute: 30 },
         nameEn: "",
         nameFr: ""

      }

      this.attachedDocument = { url: "", docName: "" };
      this.establishment_phone = "";
      this.duplicate_job_id = "";


      this.customer_type = JSON.parse(localStorage.getItem("customer_type_delivery")) ? JSON.parse(localStorage.getItem("customer_type_delivery")) : 1;
      this.customer_type1 = JSON.parse(localStorage.getItem("customer_type_pickup")) ? JSON.parse(localStorage.getItem("customer_type_pickup")) : 1;
      if (this.customer_type != 1 && this.customer_type != 2 && this.customer_type != 3 && this.customer_type != 4) {
         this.customer_type = 1;
      }
      if (this.customer_type1 != 1 && this.customer_type1 != 2 && this.customer_type1 != 3 && this.customer_type1 != 4) {
         this.customer_type1 = 1;
      }

      this.dropdownList = [{
         item_id: 1,
         item_text: moment().weekday(1),
         item_text_french: "Lundi"
      },
      {
         item_id: 2,
         item_text: 'Tuesday',
         item_text_french: "Mardi"
      },
      {
         item_id: 3,
         item_text: 'Wednesday',
         item_text_french: "Mercredi"
      },
      {
         item_id: 4,
         item_text: 'Thursday',
         item_text_french: "Jeudi"
      },
      {
         item_id: 5,
         item_text: 'Friday',
         item_text_french: "Vendredi"
      },
      {
         item_id: 6,
         item_text: 'Saturday',
         item_text_french: "Samedi"
      },
      {
         item_id: 0,
         item_text: 'Sunday',
         item_text_french: "Dimanche"
      }
      ];
      this.dropdownSettings = {
         singleSelection: false,
         noDataAvailablePlaceholderText: "N/A",
         idField: 'item_id',
         textField: 'item_text',
         selectAllText: 'Select All',
         unSelectAllText: 'Unselect All',
         itemsShowLimit: 3,
         allowSearchFilter: false,

      };

      this.dropdownSettings1 = {
         singleSelection: false,
         noDataAvailablePlaceholderText: "N/A",
         idField: '_id',
         textField: 'username',
         selectAllText: 'Select All',
         unSelectAllText: 'Unselect All',
         itemsShowLimit: 3,
         allowSearchFilter: true
      };

      this.dropdownSettings2 = {
         singleSelection: false,
         noDataAvailablePlaceholderText: "N/A",
         idField: '_id',
         textField: 'username',
         selectAllText: 'Select All',
         unSelectAllText: 'Unselect All',
         itemsShowLimit: 3,
         allowSearchFilter: true
      };

      this.dropdownSettingsDispatch = {
         singleSelection: false,
         noDataAvailablePlaceholderText: "N/A",
         idField: '_id',
         textField: 'companyName',
         selectAllText: 'Select All',
         unSelectAllText: 'Unselect All',
         itemsShowLimit: 5,
         allowSearchFilter: true,
         // limitSelection : 2
      }


      this.weekDays = [];
      this.weekDays1 = [];
      this.job_recurring_type1 = 1;
      this.dailyJobType1 = 1;
      this.dailyJobType = 1;
      this.selectedValue = "8";
      this.dateValue = '0';
      this.job_recurring_type = 1;
      this.weekend_charge_normal = 0;
      this.weekend_charge_urgent = 0
      this.weekend_charge1_normal = 0;
      this.weekend_charge1_urgent = 0;

      this.disableTags = true;
      this.normalNightCharge = 0;
      this.urgentNightCharge = 0;
      this.normalNightCharge1 = 0;
      this.urgentNightCharge1 = 0;

      this.jobTypeSelection1 = 2;
      this.jobTypeSelection = 2;
      this.jobFor = "2";
      this.jobFor1 = "2";



      this.language = JSON.parse(localStorage.getItem("language"));
      if (!this.language) {
         this.language = "en"
      }
      if (this.language == 'fr') {
         this.dropdownSettings.textField = 'item_text_french';
         this.dropdownSettings.selectAllText = 'Tout sélectionner';
         this.dropdownSettings.unSelectAllText = 'Tout déselectionner';
      } else {
         this.dropdownSettings.textField = 'item_text';
         this.dropdownSettings.selectAllText = "Select All";
         this.dropdownSettings.unSelectAllText = 'Unselect All';
      }
      this.timeZone = moment.tz.guess();
      const current = new Date();
      this.config.minDate = {
         month: current.getMonth() + 1,
         day: current.getDate(),
         year: current.getFullYear(),
      };
      var localData = JSON.parse(localStorage.getItem("currentUser"));
      this.dial_code = localData.country_code;
   }

}
