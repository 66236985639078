import {
  Component,
  OnInit
} from '@angular/core';
const FileSaver = require('file-saver');

@Component({
  selector: 'app-terms-policy',
  templateUrl: './terms-policy.component.html',
  styleUrls: ['./terms-policy.component.scss']
})
export class TermsPolicyComponent implements OnInit {
  selectedlanguage = 'en';
  commonMethods: any;
  srcPdf: any;
  getPdf: any;
  sDate: any;
  toastr: any;
  eDate: any;
  startDate: string;
  endDate: string;
  spinner: any;
  services: any;
  logoutFunction: any;

  constructor() { }

  ngOnInit() {
    var language = JSON.parse(localStorage.getItem("language"));
    if (language == 'fr') {
      this.selectedlanguage = 'fr';
    } else {
      this.selectedlanguage = 'en';
    }


  }

  //FUNCTION TO DOWNLOAD PDF
  downloadPdf() {
    let date = new Date().toDateString();
    var currentDate = this.formatDate(date);
    var pdfDate = this.modifyPdfDate(currentDate)
    const pdfUrl = this.srcPdf;
    const pdfName = 'Pharmacy_' + pdfDate + '.pdf';
    FileSaver.saveAs(pdfUrl, pdfName);
  }
  formatDate(_date: string) {
    throw new Error('Method not implemented.');
  }
  modifyPdfDate(_currentDate: any) {
    throw new Error('Method not implemented.');
  }
}
