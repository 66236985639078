<form class="d-flex mb-3 flex-wrap gap-1">
  <div class="d-flex align-items-center">
    <button
      (click)="changeRange(dateTab.Today)"
      [ngClass]="{
        'btn btn-dark': range === dateTab.Today,
        'btn btn-secondary': range !== dateTab.Today
      }"
    >
      {{ "JOBS.today" | translate }}
    </button>
    <button
      (click)="changeRange(dateTab.Week)"
      [ngClass]="{
        'btn btn-dark': range === dateTab.Week,
        'btn btn-secondary': range !== dateTab.Week
      }"
    >
      {{ "JOBS.this_week" | translate }}
    </button>
    <button
      (click)="changeRange(dateTab.Month)"
      [ngClass]="{
        'btn btn-dark': range === dateTab.Month,
        'btn btn-secondary': range !== dateTab.Month
      }"
    >
      {{ "JOBS.this_month" | translate }}
    </button>
  </div>
  <div class="d-flex align-items-center flex-wrap" style="gap: 5px">
    <div class="font-weight-bold">{{ "REPORT.date_period" | translate }}</div>
    <div class="">
      <div class="dp-hidden position-absolute">
        <div class="input-group">
          <input
            name="datepicker"
            class="form-control"
            ngbDatepicker
            #datepicker="ngbDatepicker"
            [autoClose]="'outside'"
            (dateSelect)="onDateSelection($event)"
            [dayTemplate]="t"
            [displayMonths]="2"
            outsideDays="hidden"
            [startDate]="fromDate!"
            tabindex="-1"
            [minDate]="{ year: 2015, month: 1, day: 1 }"
          />
          <ng-template #t let-date let-focused="focused">
            <span
              class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null"
            >
              {{ date.day }}
            </span>
          </ng-template>
        </div>
      </div>
      <div class="input-group">
        <input
          #dpFromDate
          (click)="datepicker.toggle()"
          class="form-control"
          placeholder="{{ 'INVOICES.start_date' | translate }}"
          name="dpFromDate"
          [value]="formatter.format(fromDate)"
          (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
        />
        <img
          src="./assets/img/new-ui/calendar_field.svg"
          (click)="datepicker.toggle()"
        />
      </div>
    </div>
    -
    <div>
      <div class="input-group">
        <input
          #dpToDate
          (click)="datepicker.toggle()"
          class="form-control"
          placeholder="{{ 'INVOICES.end_date' | translate }}"
          name="dpToDate"
          [value]="formatter.format(toDate)"
          (input)="toDate = validateInput(toDate, dpToDate.value)"
        />
        <img
          src="./assets/img/new-ui/calendar_field.svg"
          (click)="datepicker.toggle()"
        />
      </div>
    </div>
  </div>
</form>
