import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagitanion-jobs',
  templateUrl: './pagitanion-jobs.component.html',
  styleUrls: ['./pagitanion-jobs.component.scss']
})
export class PagitanionJobsComponent implements OnInit {
  page = [];
  pageActive = 1;
  @Input() countPage = 5;
  @Output() paginatenews: EventEmitter<any> = new EventEmitter();
  @Output() paginatresult: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  paginations(count) {
    this.page=[];
    if (count > 0) {
      for (let i = 1; i <= count; i++) {
        this.page.push({ pages: i });
      }
    }
  }
  pageActions(pages) {
    this.pageActive = pages;
    console.log(this.pageActive);
    this.paginatenews.emit(pages);
  }
  pageConuts(pages){

    this.paginatresult.emit(pages);
  }
}
