
<div class="transaction mt-5 mb-5">
    <div class="row">
       <div class="col-md-1"></div>
       <div class="col-md-10 card trans-padding">



        <div class="row border-bottom pt-3 pb-3">
            
             <!--  <div class="col-md-5">
                <form>
                    <div class="input-group mb-3 search-trans-box box-free">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                            <i class="fa fa-search text-success" aria-hidden="true"></i> 
                        </div>
                      </div>
                      <input type="text" class="form-control search-box "  (change)="searchTransiction($event)" placeholder="Search" >
                    </div>
                  </form>
                  </div> -->
                   <div class="col-sm-6 mb-3">
                      <!-- <span class="sort-txt mt-2">Start</span> -->
                      
                      <input class="form-control box-m-in mb-2" placeholder="{{ 'TRANSACTIONS.start_date' | translate }}" [(ngModel)]="sDate" ngbDatepicker
                      #start="ngbDatepicker" [readonly]="true" [minDate]="{year: 1900, month: 1, day: 1}" #buttonEl (click)="start.toggle()" style="cursor: pointer; display: inline-block; width: 100%"/>  
                      <!-- <label><strong>{{ 'TRANSACTIONS.start_date' | translate }}</strong></label> -->
                   </div>
                    <div class="col-sm-6 mb-3">
                       <!-- <span class="sort-txt mt-2">From</span> -->
                       
                        <input class="form-control box-m-in mb-2" placeholder="{{ 'TRANSACTIONS.end_date' | translate }}" [(ngModel)]="eDate"  ngbDatepicker
                         #end="ngbDatepicker" [readonly]="true" [minDate]="{year: 1900, month: 1, day: 1}" #buttonEl (click)="end.toggle()" style="cursor: pointer; display: inline-block; width: 100%"/>  
                         <!-- <label><strong>{{ 'TRANSACTIONS.end_date' | translate }}</strong></label> -->
                    </div>
                    <div class="col-sm-12 justify-content-md-center d-md-flex">
                        <span class="srcbtnn">
                            <button type="button" class="btn btn-success" (click)="SearchByDate()" ><strong>{{ 'TRANSACTIONS.search' | translate }}</strong></button>
                        </span>
                        <span class="srcbtnnv btnclass">
                         <button type="button" class="btn btn-success" (click)="resetPage()"><strong>{{ 'TRANSACTIONS.reset' | translate }}</strong></button>
                        </span>

                        <span class="srcbtnnv btnclass ">
                         <button type="button" class="btn btn-danger" (click)="sendTransactionsPdf()"><i class="fa fa-file-pdf-o mr-2" aria-hidden="true" style="font-size: 15px;" ></i><strong>{{ 'INVOICES.send_pdf' | translate }}</strong></button>
                        </span>
                         <span class="srcbtnnv btnclass ">
                         <button type="button" class="btn btn-danger" (click)="getPdfByDate()"><i class="fa fa-download" aria-hidden="true"></i><strong> Pdf</strong></button>
                        </span>
                        <!--  <span class="srcbtnnv btnclass ">
                         <button type="button" class="btn btn-danger"><i class="fa fa-file-pdf-o mr-2" aria-hidden="true" style="font-size: 15px;" ></i><strong>{{ 'INVOICES.downloadPdf' | translate }}</strong></button>
                        </span> -->
                      <!--   <button class="btn btn-danger"><i class="fa fa-download" aria-hidden="true"></i> Pdf</button> -->
                       
                      
                   </div>

            </div>

 <div>
            <p class="no-item  text-center text-danger" *ngIf="transactions.length == 0">{{ 'TRANSACTIONS.no_transactions' | translate }}</p>
        </div>
          <div class="row border-bottom pb-3 pt-3" *ngFor="let transaction of transactions | paginate:{ itemsPerPage: 10,currentPage: p1,totalItems:transactionsCount}">
           
               <div class="col-md-10" >
                  <!--  <p class="trans-date">{{transaction.createdAt | date: 'EEE MM/dd/yyyy'}}</p> -->

                  <p class="trans-date">{{modifyDate(transaction?.job_id.job_date)}}</p>
                   <p class="trans-code">{{transaction?.job_id.booking_code}}</p>
                   <p *ngIf="transaction?.driver_id" class="pharma-name">{{transaction?.driver_id.fullname}}({{transaction?.driver_id.username}})</p>
                   <p  *ngIf="!transaction?.driver_id" class="pharma-name">N/A</p>
               </div>
               <div class="col-md-2 text-right align-self-center">
                  <p class="trans-amt text-danger">{{transaction?.job_id.ride_fare | number : '1.2-2'}} <span class="">Dt</span></p>
               </div>
           </div>



         <!--   <div class="row border-bottom pb-3 pt-3">
            <div class="col-md-10">
                <p class="trans-date">22 Mar 2020</p>
                <p class="trans-code">UPI/008312356789/UPI john</p>
                <p class="pharma-name">Jonathan Brike</p>
            </div>
            <div class="col-md-2 text-right align-self-center">
               <p class="trans-amt text-danger">200 <span class="">Dr</span></p>
            </div>
        </div> -->

       <!--  <div class="row border-bottom pb-3 pt-3">
            <div class="col-md-10">
                <p class="trans-date">20 Mar 2020</p>
                <p class="trans-code">UPI/008312356789/UPI Ivan</p>
                <p class="pharma-name">Ivan Pharmacy</p>
            </div>
            <div class="col-md-2 text-right align-self-center">
               <p class="trans-amt text-success">407 <span class="">Cr</span></p>
            </div>
        </div> -->

      <!--   <div class="row border-bottom pb-3 pt-3">
            <div class="col-md-10">
                <p class="trans-date">25 Mar 2020</p>
                <p class="trans-code">UPI/008312356789/UPI john</p>
                <p class="pharma-name">Jonathan Brike</p>
            </div>
            <div class="col-md-2 text-right align-self-center">
               <p class="trans-amt text-success">407 <span class="">Cr</span></p>
            </div>
        </div> -->
<!-- 
        <div class="row border-bottom pb-3 pt-3">
            <div class="col-md-10">
                <p class="trans-date">22 Mar 2020</p>
                <p class="trans-code">UPI/008312356789/UPI Ivan</p>
                <p class="pharma-name">Ivan Pharmacy</p>
            </div>
            <div class="col-md-2 text-right align-self-center">
               <p class="trans-amt text-danger">200 <span class="">Dr</span></p>
            </div>
        </div> -->

       <!--  <div class="row border-bottom pb-3 pt-3">
            <div class="col-md-10">
                <p class="trans-date">22 Mar 2020</p>
                <p class="trans-code">UPI/008312356789/UPI john</p>
                <p class="pharma-name">Jonathan Brike</p>
            </div>
            <div class="col-md-2 text-right align-self-center">
               <p class="trans-amt text-danger">200 <span class="">Dr</span></p>
            </div>
        </div> -->

       </div>
       <div class="col-md-1"></div>
    </div>
</div>
<div *ngIf="pagetype == '1'">
<pagination-controls *ngIf="transactions.length" (pageChange)="onPageChange($event)"></pagination-controls>
</div>
<div *ngIf="pagetype == '2'">
<pagination-controls *ngIf="transactions.length" (pageChange)="onPageChangeforDate($event)"></pagination-controls>
</div>

         <!-- <ngx-spinner
                  bdColor="rgba(51,51,51,0.8)"
                  size="medium"
                  color="#fff"
                  type="ball-spin-clockwise"
                >
                  <p style="font-size: 20px; color: white">Loading...</p>
                </ngx-spinner> -->



  

