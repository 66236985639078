<div class="modal-header">
  <h4 class="modal-title">Attend prescription</h4>
  <button
    type="button"
    class="close"
    (click)="modal.close()"
    aria-label="Close"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <div class="row">
    <div class="col-12 d-flex justify-content-center items-align-center">
      <button
        class="btn px-3"
        style="padding: 5px 50px !important"
        [class]="
          resultAttend === 'accept' ? 'btn-success' : 'btn-outline-secondary'
        "
        (click)="onChangeAnswer('accept')"
        rippleEffect
      >
        Accept
      </button>
      <button
        class="btn px-3"
        style="padding: 5px 50px !important"
        [class]="
          resultAttend === 'reject' ? 'btn-danger' : 'btn-outline-secondary'
        "
        (click)="onChangeAnswer('reject')"
        rippleEffect
      >
        Reject
      </button>
    </div>
    <div class="col-12">
      <hr />
    </div>
    <div class="col-12 my-3" *ngIf="resultAttend === 'accept'">
      <h5>List medicaments</h5>
      <div
        class="justify-content-center items-align-center row profileedit-form"
        [formGroup]="formGroup"
      >
        <div>
          <table>
            <thead>
              <tr style="align-items: center">
                <th>{{ 'PRODUCTS.product_name' | translate }}</th>
                <th style="width: 150px">
                  {{ 'PRODUCTS.product_quantity' | translate }}
                </th>
                <th style="width: 100px; text-align: center"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let detail of productDetails">
                <td>
                  <div class="form-group">
                    {{ detail.name }}
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    {{ detail.amount }}
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <ul class="action-icons" style="text-align: center">
                      <li>
                        <i
                          class="fa fa-trash"
                          aria-hidden="true"
                          style="color: #e10e0e; font-size: 15px"
                          ngbTooltip="Delete"
                          (click)="removeDetail(detail)"
                        ></i>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group">
                    <input
                      class="form-control"
                      list="datalistOptions"
                      id="exampleDataList"
                      formControlName="productDetailId"
                      placeholder="Search..."
                    />
                    <datalist id="datalistOptions">
                      <option
                        selected="true"
                        *ngFor="let product of products"
                        [value]="product.name"
                      >
                        {{ product.name }}
                      </option>
                    </datalist>
                    <!-- <select
                      name=""
                      class="form-control select-detail"
                      formControlName="productDetailId"
                    >
                      <option
                        selected="true"
                        *ngFor="let product of products"
                        value="{{ product._id }}"
                      >
                        {{ getName(product) }}
                      </option>
                    </select> -->
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <input
                      type="number"
                      formControlName="amount"
                      class="form-control number-detail"
                      placeholder="{{
                        'PRODUCTS.placeholquantity' | translate
                      }}"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-group">
        <input
          type="text"
          formControlName="details"
          class="form-control number-detail"
          placeholder="{{ 'PRODUCTS.details' | translate }}"
        />
      </div>
      <div>
        <button
          class="btn btn-outline-secondary"
          (click)="addDetail()"
          rippleEffect
        >
          Add medicament
        </button>
      </div>
    </div>

    <div class="col-12 my-3" *ngIf="resultAttend === 'reject'">Add Comment</div>
    <div class="col-12">
      <div class="form-group">
        <label for="comment">Comment</label>
        <textarea
          class="form-control"
          placeholder="Enter a comment ..."
          id="comment"
          rows="3"
          [(ngModel)]="comment"
        ></textarea>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="modal.close()"
    rippleEffect
  >
    Cancelar
  </button>
  <button
    type="submit"
    [disabled]="loading"
    (click)="returnData()"
    class="btn btn-success"
    rippleEffect
  >
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    {{ loading ? 'Sending' : 'Send' }}
  </button>
</div>
