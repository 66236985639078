("use strict");
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  
  lang = localStorage.getItem("language");
  private language = new BehaviorSubject<string>(this.lang == '"fr"' ? "fr" : "en" );

  getLanguage() {
    return this.language.asObservable();
  }

  setLanguage(language: string) {
    this.language.next(language);
  }
 
}
