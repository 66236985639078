import {
    Component,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    Router
} from '@angular/router';
import {
    NgbDate
} from '@ng-bootstrap/ng-bootstrap';
import {
    NgxSpinnerService
} from 'ngx-spinner';
import {
    ToastrService
} from 'ngx-toastr';
import {
    DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
    ServicesService
} from '../../services.service';
import { CommonMethods } from '../_helpers/_common-methods';
import { CourierSummaryDetailsComponent } from '../courier-summary-details/courier-summary-details.component';
const FileSaver = require('file-saver');
@Component({
    selector: 'app-couriers-summary',
    templateUrl: './couriers-summary.component.html',
    styleUrls: ['./couriers-summary.component.scss']
})
export class CouriersSummaryComponent implements OnInit {
    @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
    @ViewChild('CourierSummaryDetails') CourierSummaryDetailsComponent: CourierSummaryDetailsComponent;

    fromDate: NgbDate;
    toDate: NgbDate | null = null;

    userData: any;
    pageNumber = 1;
    searchEvent = '';
    startDate = '';
    endDate = '';
    allDriversDetails = [];
    allDriversDetailsCount = 0;
    srcPdf: any;
    showDetails: boolean = false;
    driver_id: string

    constructor(
        public logoutFunction: DefaultLayoutComponent,
        public toastr: ToastrService,
        private services: ServicesService,
        public router: Router,
        private spinner: NgxSpinnerService,
        private commonMethods: CommonMethods,
    ) { }

    ngOnInit() {
        this.userData = JSON.parse(localStorage.getItem("currentUser"));
    }


    //API CALLED HERE
    getAllCompletedJobs() {
        this.spinner.show();
        this.services.courierSummeryDetails(this.userData._id, this.pageNumber, this.searchEvent, this.startDate, this.endDate).pipe().subscribe(data => {
            console.log("data:", data);
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            // console.log(data, 'couriers data');
            if (data.code == 200) {
                this.allDriversDetails = data.result;
                this.allDriversDetailsCount = data.findAllDriversCount[0].drivers_count;
                this.spinner.hide();
                return
            } else {
                this.allDriversDetails = [];
                this.allDriversDetailsCount = 0;
                this.spinner.hide();
                return;
            }
        });
    }


    //ON PAGE CHANGE EVENT
    onPageChange(event) {
        this.pageNumber = event;
        this.getAllCompletedJobs();
    }


    //FUNCTION FOR GO TO DETAIL PAGE
    goToCourierDetailPage(driver_id) {
        this.driver_id = driver_id;
        this.showDetails = true
    }

    // GET PDF 
    getPdf() {
        if (!this.startDate) {
            this.toastr.error('error', 'Please enter start date');
            return false;
        } else if (!this.endDate) {
            this.toastr.error('error', 'Please enter end date');
            return false;
        }
        this.spinner.show();
        this.services.getCourierSummaryPdf(this.userData._id, this.startDate, this.endDate, '1').pipe().subscribe(data => {

            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            if (data.code == 200) {
                const bufferDataArray = new Uint8Array(data.pdf.data).buffer;
                let pdfBlob = new Blob([bufferDataArray], { type: 'application/pdf' });

                this.downloadPdf(pdfBlob);
                this.spinner.hide();
                return
            } else {
                this.toastr.error('', data.message);
                this.spinner.hide();
                return;
            }

        })

    }

    downloadPdf(pdfBlob) {
        let date = new Date().toDateString();
        var currentDate = this.commonMethods.formatDate(date);
        var pdfDate = this.commonMethods.modifyPdfDate(currentDate)

        const pdfName = 'Pharmacy_Courier_Summary' + pdfDate + '.pdf';
        FileSaver.saveAs(pdfBlob, pdfName);
    }

}