import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    Router
} from '@angular/router';
import {
    NgbModal, NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import {
    ModalDirective
} from 'ngx-bootstrap/modal';
import {
    NgxSpinnerService
} from "ngx-spinner";
import {
    ToastrService
} from 'ngx-toastr';
import {
    DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
    ServicesService
} from '../../services.service';
import { JobsListComponent } from "../jobs-list/jobs-list.component";
const FileSaver = require('file-saver');
@Component({
    selector: 'app-completed-jobs',
    templateUrl: './completed-jobs.component.html',
    styleUrls: ['./completed-jobs.component.scss']
})
export class CompletedJobsComponent implements OnInit, AfterViewInit {
    @ViewChild('successModal') successModal: ModalDirective;
    @ViewChild('chequeModal') chequeModal: ModalDirective;
    @ViewChild(JobsListComponent) jobsListComponent: JobsListComponent;
    dynamicContentTemplate: TemplateRef<any>;

    collected_amount = 0;
    job_id: any;
    job_amount: any;
    collected_amount_note = '';
    collected_amount_initial: any;
    collected_amount_note_count = 0;
    cheque_note_count = 0;
    cheque_note = '';
    cheque_amount_initials: any;
    cheque_collected_from_driver = false;
    mark_cash_collected = false;


    constructor(
        public logoutFunction: DefaultLayoutComponent,
        public toastr: ToastrService,
        private services: ServicesService,
        public router: Router,
        private spinner: NgxSpinnerService,
        private modalService: NgbModal,

    ) {

    }

    ngAfterViewInit() {
        this.dynamicContentTemplate = this.jobsListComponent.dynamicContentTemplate;

    }


    ngOnInit() {

    }

    openModalComent(modal: NgbModalRef) {
        this.modalService.open(modal, { centered: true, backdrop: 'static' }).result.then((result) => {
            if (result) {
                //   this.getPaymentPharmacyList();
                // console.log(`nice`)
                // todo list 
            }
        });
    }

    openCollectedAmountModel(data) {
        this.job_id = data._id;
        this.job_amount = data.job_amount;
        this.collected_amount = data.collected_amount_pharmacy;
        this.collected_amount_note = data.collection_note_pharmacy;
        this.collected_amount_note_count = data.collection_note_pharmacy.length;
        this.collected_amount_initial = data.collected_amount_initial
        this.successModal.show();
    }


    updateCollectedAmmount() {
        console.log(this.collected_amount, typeof this.collected_amount)
        if (!this.collected_amount.toString()) {
            return this.toastr.error('error', 'Please enter amount');
        } else if (!this.collected_amount_initial || this.collected_amount_initial.trim() === "") {
            return this.toastr.error('error', 'Please enter initials');
        }


        this.spinner.show();
        this.services.updatePharmacyCollectedAmount(this.job_id, this.collected_amount, this.collected_amount_note, this.collected_amount_initial, this.mark_cash_collected).pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            if (data.code == 200) {
                // this.ngOnInit();
                this.jobsListComponent.getJobs();
                this.spinner.hide();
                this.successModal.hide();
                return;
            } else {
                this.spinner.hide();
                this.toastr.error('error', data.message);
                return;
            }
        });
    }


    onSearchChange(data) {
        console.log(this.collected_amount_note.length, 'this');
        this.collected_amount_note_count = this.collected_amount_note.length
    }


    //COLLECT CHEQUE FUNCTIONALITY START HERE
    openChequeModal(data) {
        this.job_id = data._id;
        this.cheque_note = data.cheque_note;
        this.cheque_amount_initials = data.cheque_amount_initials;
        this.chequeModal.show();
    }

    updateChequeCollection() {

        if (!this.cheque_collected_from_driver) {
            return this.toastr.error('error', 'Please Collect Cheque');
        } else if (!this.cheque_amount_initials || this.cheque_amount_initials.trim() === "") {
            return this.toastr.error('error', 'Please Enter Initials');
        }

        this.spinner.show();
        this.services.updatePharmacyChequeAmount(this.job_id, this.cheque_collected_from_driver, this.collected_amount_note, this.cheque_amount_initials).pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }

            if (data.code == 200) {
                this.jobsListComponent.getJobs();
                this.spinner.hide();
                this.chequeModal.hide();
                this.cheque_collected_from_driver = false;
                return;
            } else {
                this.spinner.hide();
                this.toastr.error('error', data.message);
                return;
            }
        });
    }

}