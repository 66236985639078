('use strict');
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from "ngx-toastr";
import { ServicesService } from "../../services.service";
@Injectable({
  providedIn: 'root'
})


export class CommonMethods {
  language: string = 'en';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private services: ServicesService,
    private toastr: ToastrService
  ) { }


  /**
   * FUNCTION TO MODIFY THE DATE
   * @param str 
   * @returns 
   */
  modifyPdfDate(str) {
    const mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    const a = new Date(str.split('T')[0]);
    const month_ = a.getMonth();
    const date_ = a.getDate();
    const year_ = a.getFullYear();
    return (mS[month_] + ' ' + date_ + ',' + year_);
  }


  /**
   * FUNCTION TO CHANGE DATE FORMAT
   * @param date 
   * @returns 
   */
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }



  /*****************
   * LOGOUT FUNCTION
   * ***************/
  logout() {
    let localData = JSON.parse(localStorage.getItem("currentUser"));
    this.router.navigate(["login"]);
    localStorage.removeItem("currentUser");
    localStorage.removeItem("job_id");
    localStorage.removeItem("tab");
    localStorage.setItem("currentSubscriptionId", JSON.stringify({}));
    // localStorage.clear();
    if (localData && localData._id) {
      this.services
        .logout(localData._id)
        .pipe()
        .subscribe((data) => {
          if (data.code == 200) {
            return;
          } else {
            this.toastr.error("error", data.message);
            return;
          }
        });
    }
  }

  //SWITCH LANGUAGE FUNCTION
  switchLang(lang: string) {
    localStorage.setItem('language', JSON.stringify(lang));
    this.translate.use(lang);
    this.language = lang;
  }


  //SORT ADDRESS FUNCTION
  sortAddress(address) {
    let splitAddress = address.split(',');
    if (splitAddress.length > 4) {
      return splitAddress[0] + ',' + splitAddress[1] + ',' + splitAddress[2] + ',' + splitAddress[3];
    } else {
      var str = '';
      for (const data of splitAddress) {
        str += data + ',';
      }
      return str.slice(0, -1)
    }
  }

  //MODIFY DATE 
  modifyDate(str) {
    let date = str.split('T')[0];
    const convert = date.split('-');
    return convert[0] + '/' + convert[1] + '/' + convert[2]
  }



  //FUNCTION TO MODIFY DATE TIME
  modifyDateTime(str) {
    if (str) {
      const mydate = str.split('T')[0];
      var time = str.split('T')[1];
      var splTime = time.split(':');
      const convert = mydate.split('-');
      return convert[2] + '/' + convert[1] + '/' + convert[0] + '  ' + splTime[0] + ":" + splTime[1];
    }
  }


  //DATE FORMAT CONVERTER 
  dateFormatConverter(date) {
    const unixDateToLocal = new Date(date * 1000);
    let Formatted_date = unixDateToLocal.getDate() + "-" + (unixDateToLocal.getMonth() + 1) + "-" + unixDateToLocal.getFullYear()
    const endDate = Formatted_date.split('-');
    return `${endDate[2]}/${endDate[1]}/${endDate[0]}`;
  }


  acceptOnlyTodayJobs(date) {
    const jobDate = date.split('T')[0];
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    return moment(jobDate).isSame(todayDate);
  }


  /**
   *  GENERATE INVOICE CODE FUNCTIONS
   */
  async jobCode(data) {
    let d = data + 1;
    let s = d.toString()
    while (s.length < 3)
      s = '0' + s;
    return s;
  };


  validateEmail(emailField: string) {
    let reg = /^([A-Za-z0-9_\-\+\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(emailField) == false ? false : true;
  }



  //FUNCTION TO MODIFY DATE TIME
  modifyTime(str) {
    let time = str.split('T')[1];
    let splTime = time.split(':');
    return splTime[0] + ":" + splTime[1];
  }

  checkUrgentTag(tags: any) {
    if (tags?.length) {
      const b = tags.find(x => x.tag_for == 'Urgent');
      if (b) {
        return true;
      }
    }
    return false;
  }

  formatTime(time: { hour: number, minute: number }): string {
    const hour = ('0' + time.hour).slice(-2);
    const minute = ('0' + time.minute).slice(-2);
    return `${hour}:${minute}`;
  }

  throttle(callback, delay) {
    let throttled = false;

    return function () {
      if (!throttled) {
        throttled = true;

        callback();
        setTimeout(() => {
          throttled = false;
        }, delay);
      }
    };
  }
}