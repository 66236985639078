<ng-template #dynamicContent>
  <div class="d-flex col-12 px-0 justify-content-end">
    <div class="d-flex col-6">
      <div class="flex-grow-1">
        <form>
          <input
            type="text"
            name="search"
            placeholder="{{ 'JOBS.search_customer' | translate }}"
            class="animated-search"
            (ngModelChange)="searchJobs($event)"
            [(ngModel)]="searchEvent"
            [ngModelOptions]="{ standalone: true }"
          />
        </form>
      </div>
    </div>
  </div>
</ng-template>
<div class="mt-3">
  <div class="d-flex gap-1">
    <app-button
      [imageSrc]="'./assets/img/new-ui/close.svg'"
      [buttonText]="'CLIENTS.back_to_list' | translate"
      (buttonClick)="BackPage()"
    ></app-button>
    <app-custom-calendar-datepickers
      [(startDate)]="startDate"
      [(endDate)]="endDate"
      [(fromDate)]="fromDate"
      [(toDate)]="toDate"
      (getData)="getAllCompletedJobs()"
      [(pageNumber)]="pageNumber"
    ></app-custom-calendar-datepickers>
  </div>

  <app-table
    [theadTemplate]="theadTemplate"
    [tbodyTemplate]="tbodyTemplate"
    [totalItems]="driverAllJobsCount"
    (onPageChange)="onPageChange($event)"
    [currentPage]="pageNumber"
  ></app-table>
  <ng-template #theadTemplate>
    <tr>
      <th>{{ "JOBS.job_id" | translate }}</th>
      <th>{{ "JOBS.client_name" | translate }}</th>
      <th>{{ "JOBS.address" | translate }}</th>
      <th>{{ "JOBS.initial" | translate }}</th>
      <th>{{ "JOBS.due_date" | translate }}</th>
      <th>{{ "JOBS.status" | translate }}</th>
      <th>{{ "JOBS.amount" | translate }}</th>
      <th>{{ "JOBS.collected_amount" | translate }}</th>
    </tr>
  </ng-template>

  <ng-template #tbodyTemplate>
    <tbody>
      <tr
        *ngFor="
          let completedJob of driverAllJobs
            | paginate
              : {
                  itemsPerPage: 10,
                  currentPage: pageNumber,
                  totalItems: driverAllJobsCount
                }
        "
      >
        <td>{{ completedJob.booking_code }}</td>
        <td *ngIf="completedJob.customer_type == '2'">
          <span style="font-weight: 800; text-transform: uppercase">{{
            completedJob.customer_l_name
          }}</span>
          {{ completedJob.customer_f_name }}
        </td>
        <td *ngIf="completedJob.customer_type != '2'">
          <span>{{
            completedJob.establishment_name
              ? completedJob.establishment_name
              : completedJob.customer_name
          }}</span>
        </td>

        <td *ngIf="completedJob.job_type == '1'" class="my-location">
          <p class="location-customer">
            {{ commonMethods.sortAddress(completedJob.drop_off_location) }}
          </p>
        </td>

        <td *ngIf="completedJob.job_type == '2'" class="my-location">
          <p class="location-customer">
            {{ commonMethods.sortAddress(completedJob.pick_up_location) }}
          </p>
        </td>

        <td
          (click)="jobDetails(completedJob._id)"
          *ngIf="completedJob.job_create_initials; else initialsElse"
        >
          {{ completedJob.job_create_initials }}
        </td>
        <ng-template #initialsElse>
          <td>N/A</td>
        </ng-template>
        <td>{{ commonMethods.modifyDate(completedJob.job_date) }}</td>
        <td>
          <button class="stats-active" *ngIf="completedJob.job_status == 'A'">
            {{ "JOBS.active" | translate }}
          </button>
          <button
            *ngIf="completedJob.job_status == 'N'"
            class="stats-notcomplete"
          >
            N/D
          </button>
          <button *ngIf="completedJob.job_status == 'C'" class="stats-complete">
            {{ "JOBS.completed" | translate }}
          </button>
          <button
            *ngIf="
              completedJob.isExpired == true && completedJob.job_status != 'C'
            "
            class="stats-complete"
            style="background: #efe2e2; color: #ff010d"
          >
            {{ "JOBS.expired" | translate }}
          </button>
          <button
            *ngIf="
              completedJob.job_status == 'CN' && completedJob.isExpired == false
            "
            class="stats-complete"
            style="background: #efe2e2; color: #ff010d"
          >
            {{ "JOBS.cancelled" | translate }}
          </button>
          <div *ngIf="completedJob.isRecursiveJob">
            <button class="stats-recursive text-danger">
              <i class="fa fa-circle mr-2" aria-hidden="true"></i
              >{{ "JOBS.recursive" | translate }}
            </button>
          </div>
        </td>
        <td>
          <span class="amount"
            >${{ completedJob.job_amount | number : "1.2-2" }}</span
          >
          <p
            *ngIf="
              completedJob.job_status == 'C' &&
              completedJob.customer_payment_id != null &&
              completedJob.customer_payment_id.payment_method == '2'
            "
            class="cash"
          >
            {{ "JOBS.cash" | translate }}
          </p>
          <p
            *ngIf="
              completedJob.job_status == 'C' &&
              completedJob.customer_payment_id != null &&
              completedJob.customer_payment_id.payment_method == '1'
            "
            class="cash"
          >
            {{ "JOBS.card" | translate }}
          </p>
          <p
            *ngIf="
              completedJob.job_status == 'C' &&
              completedJob.customer_payment_id != null &&
              completedJob.customer_payment_id.payment_method == '3'
            "
            class="cash"
          >
            {{ "JOBS.cheque" | translate }}
          </p>
        </td>
        <td>
          <strong
            >${{ completedJob.collected_amount | number : "1.2-2" }}</strong
          >
        </td>
      </tr>
    </tbody>
  </ng-template>
  <ng-template #noFoundTemplate>
    <div class="no-jobs-found">
      <p
        class="no-item text-center text-danger mb-0"
        *ngIf="!driverAllJobs.length"
      >
        {{ "JOBS.no_jobs_found" | translate }}
      </p>
    </div>
  </ng-template>
</div>
