<div class="">
  <main class="main d-flex align-items-center">
    <div class="container-fluid">
      <div class="text-right pt-2">
        <label
          ><strong><i class="fa fa-globe"></i> Language :</strong>
        </label>
        <select #langSelect (change)="switchLang(langSelect.value)">
          <option
            *ngFor="let lang of ts.getLangs()"
            [value]="lang"
            [selected]="lang === ts.currentLang"
          >
            <span *ngIf="lang == 'en'"> English </span>
            <span *ngIf="lang == 'fr'"> Français </span>
          </option>
        </select>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="service-contract">
            <form [formGroup]="contractForm" (ngSubmit)="onSubmit()">
              <header>
                <div class="logo">
                  <img
                    src="../../../assets/img/vaistatlogo-2.png"
                    alt="Vaistat logo"
                  />
                </div>
              </header>

              <main>
                <div class="separator">
                  <hr />
                </div>
                <div class="title">
                  <table>
                    <tr>
                      <td class="td-min-width text-bold">
                        {{ "CONTRACT.subject" | translate }} :
                      </td>
                      <td class="text-bold">
                        {{ "CONTRACT.title" | translate }}
                      </td>
                    </tr>
                  </table>
                  <div class="separator">
                    <hr />
                  </div>
                  <table>
                    <tr>
                      <td class="td-min-width text-bold">
                        {{ "CONTRACT.recipient" | translate }} :
                      </td>
                      <td>{{ pharmacyName }}</td>
                    </tr>
                    <tr>
                      <td class="td-min-width text-bold">
                        {{ "CONTRACT.sender" | translate }} :
                      </td>
                      <td>Solutions Vaistat Inc.</td>
                    </tr>
                    <tr>
                      <td class="td-min-width text-bold">Date :</td>
                      <td>{{ pharmacyCreatedAt }}</td>
                    </tr>
                  </table>
                  <div class="separator">
                    <hr />
                  </div>
                </div>

                <section id="section1">
                  <div class="title">
                    <h2>{{ "CONTRACT.section1.title" | translate }}</h2>
                  </div>
                  <div class="content">
                    <p>
                      {{ "CONTRACT.section1.paragraph1" | translate }}
                    </p>
                    <p>
                      {{ "CONTRACT.section1.paragraph2" | translate }}
                    </p>
                    <p>
                      {{ "CONTRACT.section1.paragraph3" | translate }}
                    </p>
                    <p>
                      {{ "CONTRACT.section1.paragraph4" | translate }}
                    </p>
                    <p>
                      {{ "CONTRACT.section1.paragraph5" | translate }}
                    </p>
                  </div>
                </section>

                <section id="section2" class="section2">
                  <div class="title">
                    <h2>{{ "CONTRACT.section2.title" | translate }}</h2>
                  </div>
                  <div class="content">
                    <ul>
                      <li>
                        &nbsp; {{ "CONTRACT.section2.paragraph1" | translate }}
                      </li>
                      <li>
                        &nbsp; {{ "CONTRACT.section2.paragraph2" | translate }}
                      </li>
                      <li class="text-bold">
                        &nbsp; {{ "CONTRACT.section2.paragraph3" | translate }}
                      </li>
                      <li>
                        &nbsp; {{ "CONTRACT.section2.paragraph4" | translate }}
                      </li>
                      <li class="text-bold">
                        &nbsp; {{ "CONTRACT.section2.paragraph5" | translate }}
                      </li>
                      <li class="text-bold">
                        &nbsp; {{ "CONTRACT.section2.paragraph6" | translate }}
                      </li>
                    </ul>
                  </div>
                </section>

                <section id="section3">
                  <h3 class="text-center" style="font-size: 1.4em">
                    {{ "CONTRACT.section3.title1" | translate }}
                  </h3>
                  <div class="title">
                    <h2>
                      {{ "CONTRACT.section3.title2" | translate }}
                    </h2>
                  </div>
                  <div class="content">
                    <p>
                      {{ "CONTRACT.section3.paragraph1" | translate }}
                    </p>
                  </div>
                </section>

                <section id="section4" class="section4">
                  <div class="title">
                    <h2>{{ "CONTRACT.section4.title" | translate }}</h2>
                  </div>
                  <div class="content">
                    <p>{{ "CONTRACT.section4.paragraph1" | translate }} :</p>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            {{ "CONTRACT.vai_plan" | translate }}
                          </th>
                          <th class="text-center bg-table-culumn">
                            {{ "CONTRACT.section4.plan1" | translate }}
                          </th>
                          <th class="text-center bg-table-culumn">
                            {{ "CONTRACT.section4.plan2" | translate }}
                          </th>
                          <th
                            class="text-center bg-table-culumn position-relative"
                          >
                            {{ "CONTRACT.section4.plan3" | translate }}
                            <span
                              class="ml-1 text-bold small position-absolute"
                              style="top: 0"
                              >1</span
                            >
                          </th>
                          <th class="text-center bg-table-culumn">
                            {{ "CONTRACT.section4.plan4" | translate }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {{ "CONTRACT.section4.monthly_price" | translate }}
                          </td>
                          <td class="text-center bg-table-culumn">64.98 $</td>
                          <td class="text-center bg-table-culumn">94.97 $</td>
                          <td class="text-center bg-table-culumn">154.99 $</td>
                          <td class="text-center bg-table-culumn">119.99 $</td>
                        </tr>
                        <tr>
                          <td>
                            {{
                              "CONTRACT.section4.delivery_pickup_per_month"
                                | translate
                            }}
                          </td>
                          <td class="text-center bg-table-culumn">
                            {{ "CONTRACT.section4.unlimited" | translate }}
                          </td>
                          <td class="text-center bg-table-culumn">0-500</td>
                          <td class="text-center bg-table-culumn">500-600</td>
                          <td class="text-center bg-table-culumn">2000</td>
                        </tr>
                        <tr>
                          <td>
                            {{
                              "CONTRACT.section4.additional_delivery_and_pickup"
                                | translate
                            }}
                          </td>
                          <td class="text-center bg-table-culumn">N/A</td>
                          <td class="text-center bg-table-culumn">0.08 $</td>
                          <td class="text-center bg-table-culumn">0.08 $</td>
                          <td class="text-center bg-table-culumn">0.08 $</td>
                        </tr>
                        <tr>
                          <td>
                            {{
                              "CONTRACT.section4.pharmacy_access_branches"
                                | translate
                            }}
                          </td>
                          <td class="text-center bg-table-culumn">1</td>
                          <td class="text-center bg-table-culumn">1</td>
                          <td class="text-center bg-table-culumn">1</td>
                          <td class="text-center bg-table-culumn">1+</td>
                        </tr>
                        <tr>
                          <td>
                            {{
                              "CONTRACT.section4.private_delivery_access"
                                | translate
                            }}
                          </td>
                          <td class="text-center bg-table-culumn">N/A</td>
                          <td class="text-center bg-table-culumn">
                            {{ "CONTRACT.section4.unlimited" | translate }}
                          </td>
                          <td class="text-center bg-table-culumn">
                            {{ "CONTRACT.section4.unlimited" | translate }}
                          </td>
                          <td class="text-center bg-table-culumn"></td>
                        </tr>
                        <tr>
                          <td>
                            {{
                              "CONTRACT.section4.technical_support" | translate
                            }}
                          </td>
                          <td class="text-center bg-table-culumn">X</td>
                          <td class="text-center bg-table-culumn">X</td>
                          <td class="text-center bg-table-culumn">X</td>
                          <td class="text-center bg-table-culumn"></td>
                        </tr>
                        <tr>
                          <td>
                            {{
                              "CONTRACT.section4.contactless_payment"
                                | translate
                            }}
                          </td>
                          <td class="text-center bg-table-culumn">X</td>
                          <td class="text-center bg-table-culumn">X</td>
                          <td class="text-center bg-table-culumn">X</td>
                          <td class="text-center bg-table-culumn"></td>
                        </tr>
                        <tr>
                          <td>
                            {{
                              "CONTRACT.section4.validation_document_by_the_driver"
                                | translate
                            }}
                          </td>
                          <td class="text-center bg-table-culumn">X</td>
                          <td class="text-center bg-table-culumn">X</td>
                          <td class="text-center bg-table-culumn">X</td>
                          <td class="text-center bg-table-culumn"></td>
                        </tr>
                        <tr>
                          <td>
                            {{
                              "CONTRACT.section4.dispatch_access" | translate
                            }}
                          </td>
                          <td class="text-center bg-table-culumn"></td>
                          <td class="text-center bg-table-culumn">X</td>
                          <td class="text-center bg-table-culumn">X</td>
                          <td class="text-center bg-table-culumn"></td>
                        </tr>
                        <tr>
                          <td>
                            {{
                              "CONTRACT.section4.patient_application"
                                | translate
                            }}
                          </td>
                          <td class="text-center bg-table-culumn"></td>
                          <td class="text-center bg-table-culumn"></td>
                          <td class="text-center bg-table-culumn">X</td>
                          <td class="text-center bg-table-culumn"></td>
                        </tr>
                        <tr>
                          <td>
                            {{
                              "CONTRACT.section4.online_business" | translate
                            }}
                          </td>
                          <td class="text-center bg-table-culumn"></td>
                          <td class="text-center bg-table-culumn"></td>
                          <td class="text-center bg-table-culumn">X</td>
                          <td class="text-center bg-table-culumn"></td>
                        </tr>
                        <tr>
                          <td>
                            {{
                              "CONTRACT.section4.api_integration" | translate
                            }}
                          </td>
                          <td class="text-center bg-table-culumn"></td>
                          <td class="text-center bg-table-culumn"></td>
                          <td class="text-center bg-table-culumn">X</td>
                          <td class="text-center bg-table-culumn"></td>
                        </tr>
                      </tbody>
                    </table>
                    <ul>
                      <li>
                        * &nbsp; {{ "CONTRACT.section4.note1" | translate }}
                      </li>
                      <li>
                        * &nbsp; {{ "CONTRACT.section4.note2" | translate }}
                      </li>
                      <li>
                        * &nbsp; {{ "CONTRACT.section4.note3" | translate }}
                      </li>
                      <li>
                        <span class="small">1</span> &nbsp;
                        {{
                          "CONTRACT.section4.plan_currently_unavailable"
                            | translate
                        }}
                      </li>
                    </ul>
                  </div>
                </section>
                <section id="section5" class="section5">
                  <div class="content">
                    <table>
                      <thead>
                        <tr>
                          <th class="text-center bg-table-culumn">
                            {{
                              "CONTRACT.section5.details_of_nfc_card_processing"
                                | translate
                            }}
                          </th>
                          <th class="text-center bg-table-culumn">
                            {{ "SUBSCRIPTION.price" | translate }}
                          </th>
                          <th class="text-center bg-table-culumn">
                            {{
                              "CONTRACT.section5.details_of_nfc_debit_card_processing"
                                | translate
                            }}
                          </th>
                          <th class="text-center bg-table-culumn">
                            {{ "SUBSCRIPTION.price" | translate }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center bg-table-culumn">
                            {{ "CONTRACT.section5.currency" | translate }}
                          </td>
                          <td class="text-center bg-table-culumn">CAD</td>
                          <td class="text-center bg-table-culumn">
                            {{ "CONTRACT.section5.currency" | translate }}
                          </td>
                          <td class="text-center bg-table-culumn">CAD</td>
                        </tr>
                        <tr>
                          <td class="text-center bg-table-culumn">
                            {{ "CONTRACT.section5.type_of_fee" | translate }}
                          </td>
                          <td class="text-center bg-table-culumn">
                            {{ "CONTRACT.section5.fixed_rate" | translate }}
                          </td>
                          <td class="text-center bg-table-culumn">
                            {{ "CONTRACT.section5.type_of_fee" | translate }}
                          </td>
                          <td class="text-center bg-table-culumn">
                            {{ "CONTRACT.section5.fixed_rate" | translate }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center bg-table-culumn">
                            {{ "CONTRACT.section5.discount_fee" | translate }}
                            Visa
                          </td>
                          <td class="text-center bg-table-culumn">3 %</td>
                          <td class="text-center bg-table-culumn">
                            {{
                              "CONTRACT.section5.transaction_fee" | translate
                            }}
                          </td>
                          <td class="text-center bg-table-culumn">0.30 $</td>
                        </tr>
                        <tr>
                          <td class="text-center bg-table-culumn">
                            {{ "CONTRACT.section5.discount_fee" | translate }}
                            Amex
                          </td>
                          <td class="text-center bg-table-culumn">3.1 %</td>
                          <td class="text-center bg-table-culumn">
                            {{ "CONTRACT.section5.refund_fee" | translate }}
                          </td>
                          <td class="text-center bg-table-culumn">10.00$</td>
                        </tr>
                        <tr>
                          <td class="text-center bg-table-culumn">
                            {{
                              "CONTRACT.section5.transaction_fee" | translate
                            }}
                          </td>
                          <td class="text-center bg-table-culumn">0.30 $</td>
                          <td class="text-center bg-table-culumn">
                            {{
                              "CONTRACT.section5.monthly_account_fee"
                                | translate
                            }}
                          </td>
                          <td class="text-center bg-table-culumn">
                            {{ "CONTRACT.section5.included" | translate }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center bg-table-culumn">
                            {{ "CONTRACT.section5.refund_fee" | translate }}
                          </td>
                          <td class="text-center bg-table-culumn">10.00 $</td>
                          <td class="text-center bg-table-culumn"></td>
                          <td class="text-center bg-table-culumn"></td>
                        </tr>
                        <tr>
                          <td class="text-center bg-table-culumn">
                            {{ "CONTRACT.section5.recovery_fee" | translate }}
                          </td>
                          <td class="text-center bg-table-culumn">10.00 $</td>
                          <td class="text-center bg-table-culumn"></td>
                          <td class="text-center bg-table-culumn"></td>
                        </tr>
                        <tr>
                          <td class="text-center bg-table-culumn">
                            {{
                              "CONTRACT.section5.cancellation_fee" | translate
                            }}
                          </td>
                          <td class="text-center bg-table-culumn">10.00 $</td>
                          <td class="text-center bg-table-culumn"></td>
                          <td class="text-center bg-table-culumn"></td>
                        </tr>
                        <tr>
                          <td class="text-center bg-table-culumn">
                            {{
                              "CONTRACT.section5.monthly_account_fee"
                                | translate
                            }}
                          </td>
                          <td class="text-center bg-table-culumn">
                            {{ "CONTRACT.section5.included" | translate }}
                          </td>
                          <td class="text-center bg-table-culumn"></td>
                          <td class="text-center bg-table-culumn"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>

                <section id="section6">
                  <div class="title">
                    <h2>{{ "CONTRACT.section6.title" | translate }}</h2>
                  </div>
                  <div class="content">
                    <p>
                      <span class="text-bold">{{
                        "CONTRACT.section6.paragraph1.bold_text" | translate
                      }}</span>
                      {{ "CONTRACT.section6.paragraph1.normal_text" | translate
                      }}<br />
                      <span class="text-bold">{{
                        "CONTRACT.section6.paragraph2.bold_text" | translate
                      }}</span>
                      {{ "CONTRACT.section6.paragraph2.normal_text" | translate
                      }}<br />
                      <span class="text-bold">{{
                        "CONTRACT.section6.paragraph3.bold_text" | translate
                      }}</span>
                      {{ "CONTRACT.section6.paragraph3.normal_text" | translate
                      }}<br />
                      <span class="text-bold">{{
                        "CONTRACT.section6.paragraph4" | translate
                      }}</span>
                      <br />
                      {{
                        "CONTRACT.section6.paragraph5.normal_text1" | translate
                      }}
                      <span class="text-bold">{{
                        "CONTRACT.section6.paragraph5.bold_text" | translate
                      }}</span>
                      {{
                        "CONTRACT.section6.paragraph5.normal_text2" | translate
                      }}<br />
                      {{
                        "CONTRACT.section6.paragraph6.normal_text1" | translate
                      }}
                      <span class="text-bold">{{
                        "CONTRACT.section6.paragraph6.bold_text" | translate
                      }}</span>
                      {{
                        "CONTRACT.section6.paragraph6.normal_text2" | translate
                      }}
                    </p>
                    <p>
                      <span class="text-bold">{{
                        "CONTRACT.section6.paragraph7.bold_text" | translate
                      }}</span>
                      {{
                        "CONTRACT.section6.paragraph7.normal_text" | translate
                      }}
                    </p>
                  </div>
                </section>

                <section id="section7">
                  <div class="title">
                    <h2>{{ "CONTRACT.section7.title" | translate }}</h2>
                  </div>
                  <div class="content">
                    <p>
                      {{ "CONTRACT.section7.paragraph1.text1" | translate }} :
                      <br />
                      <span style="margin-left: 40px; line-height: 20px">
                        - &nbsp;
                        {{
                          "CONTRACT.section7.paragraph1.text2" | translate
                        }}</span
                      >
                    </p>
                    <p>
                      {{
                        "CONTRACT.section7.paragraph2.normal_text1" | translate
                      }}
                      <span class="text-bold">{{
                        "CONTRACT.section7.paragraph2.bold_text" | translate
                      }}</span>
                      {{
                        "CONTRACT.section7.paragraph2.normal_text2" | translate
                      }}
                    </p>
                    <p>
                      {{ "CONTRACT.section7.paragraph3" | translate }}
                    </p>
                    <p class="text-bold">
                      {{ "CONTRACT.section7.paragraph4" | translate }}
                    </p>
                  </div>
                </section>

                <section id="section8">
                  <div class="title">
                    <h2>{{ "CONTRACT.section8.title" | translate }}</h2>
                  </div>
                  <div class="content">
                    <div style="margin: 20px 0">
                      <h3
                        style="
                          text-align: center;
                          font-weight: bold;
                          font-size: 1.2em;
                        "
                      >
                        {{ "CONTRACT.section8.paragraph1" | translate }}
                      </h3>
                    </div>
                    <div
                      class="subscription-plans"
                      [ngClass]="{ 'is-invalid': submitted && !selectedPlanId }"
                    >
                      <h3 class="text-bold">
                        {{ "CONTRACT.vai_plan" | translate }} :
                      </h3>

                      <div class="plans">
                        <label *ngFor="let plan of plans; let i = index">
                          <!-- *ngIf="selectedlanguage == 'fr';else english" -->
                          <span>
                            &nbsp;
                            {{ plan.metadata?.new_plan }}
                            {{ "CONTRACT.section8.at" | translate }}
                            {{ plan.unit_amount / 100 }}$
                          </span>
                          <input
                            type="radio"
                            id="{{ plan.id }}"
                            name="plan"
                            [value]="plan.id"
                            (change)="selectPlan(plan.id)"
                          />
                        </label>
                      </div>
                    </div>
                    <div class="inputs-text">
                      <label>
                        <span class="text-bold"
                          >{{ "CONTRACT.section8.branch_address" | translate }}
                          :
                        </span>
                        <input
                          ngx-google-places-autocomplete
                          #placesRef="ngx-places"
                          name="pharmacyOfficeAddress"
                          (onAddressChange)="handleAddressChange($event)"
                          type="text"
                          formControlName="pharmacyOfficeAddress"
                          [(ngModel)]="pharmacyOfficeAddress"
                          placeholder="{{
                            'CONTRACT.section8.to_be_completed' | translate
                          }}"
                          [ngClass]="{
                            'is-invalid':
                              submitted && f.pharmacyOfficeAddress.errors
                          }"
                        />
                      </label>
                      <label>
                        <span class="text-bold"
                          >{{ "CONTRACT.section8.invoice_to" | translate }} :
                        </span>
                        <input
                          type="text"
                          name="pharmacyLegalName"
                          formControlName="pharmacyLegalName"
                          [(ngModel)]="pharmacyLegalName"
                          placeholder="{{
                            'CONTRACT.section8.to_be_completed_by_the_pharmacy'
                              | translate
                          }}"
                          [ngClass]="{
                            'is-invalid':
                              submitted && f.pharmacyLegalName.errors
                          }"
                        />
                      </label>
                      <label>
                        <span class="text-bold"
                          >{{ "CONTRACT.section8.billing_email" | translate }} :
                        </span>
                        <input
                          type="email"
                          name="pharmacyFinancialEmail"
                          formControlName="pharmacyFinancialEmail"
                          [(ngModel)]="pharmacyFinancialEmail"
                          placeholder="{{
                            'CONTRACT.section8.email_payment_information'
                              | translate
                          }}"
                          [ngClass]="{
                            'is-invalid':
                              submitted && f.pharmacyFinancialEmail.errors
                          }"
                        />
                      </label>
                      <label>
                        <span class="text-bold"
                          >{{
                            "CONTRACT.section8.name_of_authorized_signatory_in_block_letters"
                              | translate
                          }}
                          :
                        </span>
                        <input
                          type="text"
                          name="pharmacyOwnerName"
                          formControlName="pharmacyOwnerName"
                          [(ngModel)]="pharmacyOwnerName"
                          placeholder="{{
                            'CONTRACT.section8.name_pharmacist_owner'
                              | translate
                          }}"
                          [ngClass]="{
                            'is-invalid':
                              submitted && f.pharmacyOwnerName.errors
                          }"
                        />
                      </label>
                      <label>
                        <span class="text-bold"
                          >{{ "CONTRACT.section8.license" | translate }} :
                        </span>
                        <input
                          type="text"
                          name="pharmacyLicenceNumber"
                          formControlName="pharmacyLicenceNumber"
                          [(ngModel)]="pharmacyLicenceNumber"
                          placeholder="{{
                            'CONTRACT.section8.license_number_of_pharmacist_owner'
                              | translate
                          }}"
                          [ngClass]="{
                            'is-invalid':
                              submitted && f.pharmacyLicenceNumber.errors
                          }"
                        />
                      </label>
                      <label>
                        <span class="text-bold"
                          >{{
                            "CONTRACT.section8.license_expiration_date"
                              | translate
                          }}
                          :
                        </span>
                        <input
                          type="text"
                          name="licenceExpiryDate"
                          formControlName="licenceExpiryDate"
                          [readonly]="true"
                          [(ngModel)]="licenceExpiryDate"
                          class="date-picker"
                          ngbDatepicker
                          #start="ngbDatepicker"
                          (click)="start.toggle()"
                          firstDayOfWeek="7"
                          [ngClass]="{
                            'is-invalid':
                              submitted && f.licenceExpiryDate.errors
                          }"
                          style="min-width: 140px !important"
                        />
                      </label>
                    </div>

                    <div class="signature">
                      <h3 class="legend text-bold" style="font-size: 1.2em">
                        {{ "CONTRACT.signature.paragraph1" | translate }}
                      </h3>
                      <table style="border: none">
                        <tbody>
                          <tr style="border: none">
                            <td style="border: none">
                              <label for="signature-pad" class="text-bold"
                                >Signature : &nbsp;</label
                              >
                              <canvas
                                id="signature-pad"
                                [ngClass]="{
                                  'is-invalid':
                                    submitted && isSignaturePadEmpty()
                                }"
                                style="
                                  height: 130px;
                                  width: 300px;
                                  background-color: rgba(0, 0, 0, 0);
                                  border: 1px solid rgba(0, 0, 0, 0.2);
                                "
                              >
                              </canvas>
                            </td>
                            <td
                              style="
                                border: none;
                                vertical-align: bottom;
                                text-align: right;
                              "
                            >
                              <label>
                                <span class="text-bold">Date : &nbsp;</span>
                                <input
                                  type="text"
                                  name="signatureDate"
                                  formControlName="signatureDate"
                                  [readonly]="true"
                                  [(ngModel)]="signatureDate"
                                  class="date-picker"
                                  ngbDatepicker
                                  #end="ngbDatepicker"
                                  [minDate]="minDate"
                                  (click)="end.toggle()"
                                  firstDayOfWeek="7"
                                  [ngClass]="{
                                    'is-invalid':
                                      submitted && f.signatureDate.errors
                                  }"
                                  style="
                                    min-width: 140px !important;
                                    background-color: rgba(0, 0, 0, 0.05);
                                  "
                                />
                              </label>
                            </td>
                          </tr>
                          <tr style="border: none">
                            <td style="border: none">
                              <button
                                type="button"
                                (click)="clearSignaturePad()"
                                id="clearSignaturePad"
                                class="btn btn-dark btn-sm"
                                style="
                                  display: block;
                                  margin-left: 75px;
                                  margin-top: -6px;
                                "
                              >
                                {{
                                  "CONTRACT.signature.clear_the_signature_area"
                                    | translate
                                }}
                              </button>
                            </td>
                            <td style="border: none">&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="actions">
                      <button
                        type="submit"
                        id="saveSignaturePad"
                        class="btn btn-success"
                      >
                        <img
                          src="../../../assets/img/new-ui/save.svg"
                          style="max-height: 15px; width: auto"
                        />
                        {{ "TAG.save" | translate }}
                      </button>
                      <button
                        (click)="logout()"
                        type="button"
                        class="btn btn-dark pull-right"
                      >
                        {{ "TAG.cancel" | translate }}
                      </button>
                    </div>
                  </div>
                </section>
              </main>

              <footer style="width: 100%">
                <div
                  style="
                    width: 100%;
                    height: 30px;
                    margin: 0;
                    margin-top: 10px;
                    padding: 8px 0 14px 0;
                    border: 0;
                    border-top: 2px solid rgba(0, 0, 0, 0.4);
                    text-align: center;
                    opacity: 0.6;
                  "
                >
                  <p
                    style="
                      padding: 0;
                      margin: 0;
                      font-size: 14px;
                      font-weight: bold;
                      color: #4bcc91;
                    "
                  >
                    Solutions Vaistat Inc. (SVI) 202-2494 Boul. Henri-bourassa
                    Est, Montreal Québec H2T 1B9
                  </p>
                </div>
              </footer>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.5)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate"
  [fullScreen]="true"
></ngx-spinner>
