<div class="job-status">
  <label class="font-weight-bold mr-2">{{
    "CLIENTS.driver_name" | translate
  }}</label>

  <label style="font-size: 14px; font-weight: 400">{{ data?.fullname }}</label
  ><br />
  <label class="font-weight-bold">
    {{ "DASHBOARD.COMPLETED/TOTAL" | translate }}</label
  >
  <app-progress-bar
    class="mb-3 position-relative"
    [data]="jobs"
  ></app-progress-bar>
  <app-button
    [buttonText]="'DASHBOARD.jobs' | translate"
    (buttonClick)="isCollapsed = !isCollapsed"
  ></app-button>

  <div id="collapseBasic" class="mt-3" [collapse]="isCollapsed">
    <tabset>
      <ng-container *ngFor="let status of statuses">
        <tab [heading]="getTranslatedHeading(status)">
          <ng-container *ngFor="let job of jobGroups[status]">
            <app-job-link [job]="job"></app-job-link>
          </ng-container>
        </tab>
      </ng-container>
    </tabset>
  </div>

  <hr />
  <label class="font-weight-bold">{{ "DASHBOARD.status" | translate }}</label
  >: <button class="stats-active">Active</button><br />
  <label class="font-weight-bold">Lat_Long:</label>: {{ data?.lat_long[0] }},
  {{ data?.lat_long[1] }}
</div>
