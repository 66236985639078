import {
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    Router
} from '@angular/router';
import moment from 'moment';
import {
    BsModalRef,
    BsModalService,
    ModalDirective
} from 'ngx-bootstrap/modal';
import {
    NgxSpinnerService
} from "ngx-spinner";
import {
    ToastrService
} from 'ngx-toastr';
import {
    DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import { Job } from '../../models/Job/Job';
import {
    ServicesService
} from '../../services.service';
import { Marker } from '../../services/interfaces/googleMaps.interface';
import { RouteService } from '../../services/route.service';
import { ModalComponent } from "../../shared/component/modal/modal.component";
import { JOB } from '../../shared/helpers/enums';
const FileSaver = require('file-saver');
@Component({
    selector: 'app-job-details',
    templateUrl: './job-details.component.html',
    styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit {
    @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
    @ViewChild("stepperStatusHistoryTemplate")
    stepperStatusHistoryTemplate: TemplateRef<any>;
    @ViewChild("pickupInfoWindowContent") pickupInfoWindowContent: TemplateRef<any>;
    @ViewChild("dropInfoWindowContent") dropInfoWindowContent: TemplateRef<any>;
    @ViewChild("driverInfoWindowContent") driverInfoWindowContent: TemplateRef<any>;
    bsModalRef: BsModalRef;
    public language = "en";
    @Input() job_id: any;
    public job_details: any;
    public user_id: any;
    public selected_image: any;
    public pickuplat: any;
    public pickuplong: any;
    public droplat: any;
    public droplong: any;

    public labelOptions = {
        color: '#228B22',
        fontFamily: '',
        fontSize: '14px',
        fontWeight: 'bold',

    }
    jobHistory: Job[]
    nameElse: any;
    multipleCustomers = [];
    establishmentTagSelection = '0';


    @ViewChild('successModal') public successModal: ModalDirective;
    @ViewChild('successModal1') public successModal1: ModalDirective;
    @ViewChild('reasonModal') public reasonModal: ModalDirective;
    checkStatus = '1';
    viewNoteModal = '';
    @ViewChild('noteModal') public noteModal: ModalDirective;

    @ViewChild('addnewcustomer') public addnewcustomer: ModalDirective;

    @ViewChild('approveOwnershipModal') public approveOwnershipModal: ModalDirective;
    @ViewChild(JobDetailsComponent) jobDetailsComponent: JobDetailsComponent
    transferredFromJobId: string
    markersInfo: Marker[] = [];
    center: google.maps.LatLngLiteral;

    constructor(
        public toastr: ToastrService,
        private services: ServicesService,
        public router: Router,
        private spinner: NgxSpinnerService,
        public logoutFunction: DefaultLayoutComponent,
        private modalService: BsModalService,
        private routeService: RouteService) { }

    ngOnInit() {

        this.spinner.show();
        var localData = JSON.parse(localStorage.getItem("currentUser"));
        if (!localData) {
            this.spinner.hide();
            this.logoutFunction.logout();
            return;
        }
        this.user_id = localData._id;

        this.language = JSON.parse(localStorage.getItem("language"));
        if (!this.language) {
            this.language = "en"
        }
        this.checkStatus = JSON.parse(localStorage.getItem("back_type"));

        if (this.job_id) {
            this.getJobDetails();
        } else {
            this.toastr.error('error', 'not found');
        }
    }


    //FUNCTION TO GET JOB DETAILS DATA

    getJobDetails() {

        this.services.getSingleJobDetails(this.job_id).subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            if (data.code == 200) {
                this.job_details = data.result;
                this.jobHistory = []
                this.viewNoteModal = this.job_details.note;
                this.pickuplat = this.job_details.pickup_lat_long[0];
                this.pickuplong = this.job_details.pickup_lat_long[1];
                this.droplat = this.job_details.dropoff_lat_long[0];
                this.droplong = this.job_details.dropoff_lat_long[1];
                this.establishmentTagSelection = this.job_details.establishmentTagSelection;
                if (data.result.old_job_reference.jobId) {
                    this.jobHistory = this.extractAllOldJobReferenceJobIds(data.result.old_job_reference);
                }

                const pickupMarker = new google.maps.Marker({
                    position: { lat: this.pickuplat, lng: this.pickuplong },
                    icon: "./assets/img/pickup.png"
                });


                const dropMarker = new google.maps.Marker({
                    position: { lat: this.droplat, lng: this.droplong },
                    icon: "./assets/img/delivery.png",
                });
                const bounds = new google.maps.LatLngBounds();
                bounds.extend(pickupMarker.getPosition());
                bounds.extend(dropMarker.getPosition());
                const centerLatLng = bounds.getCenter();

                this.center = { lat: centerLatLng.lat(), lng: centerLatLng.lng() };
                this.markersInfo.push({
                    marker: pickupMarker, data: this.pickupInfoWindowContent,
                }, { marker: dropMarker, data: this.dropInfoWindowContent });

                if (this.job_details?.isAccepted == true &&
                    this.job_details?.isExpired == false &&
                    (this.job_details?.job_status == JOB.JOB_STATUS.PENDING ||
                        this.job_details?.job_status == JOB.JOB_STATUS.ACTIVE)) {
                    const driverMarker = new google.maps.Marker({
                        position: { lat: this.job_details?.driver_id?.lat_long[1], lng: this.job_details?.driver_id?.lat_long[0] },
                        icon: "./assets/img/car.png",
                    });
                    this.markersInfo.push({
                        marker: driverMarker, data: this.driverInfoWindowContent
                    });
                }
                this.spinner.hide();
            } else {
                this.spinner.hide();
                this.toastr.error('error', data.message);
                return;
            }
        });
    }

    //BACK TO PREVIOUS PAGE ACC TO JOB TYPE
    back() {
        const route = this.routeService.getPreviousRoute();
        this.router.navigate([route]);

    }

    imageSelection(image) {
        this.selected_image = image;
        this.successModal.show();
    }

    //MODIFY DATE FUNCTION
    modifyDate(str) {
        if (str && str != null) {
            const mydate = str.split('T')[0]
            const convert = mydate.split('-')
            //return convert[1] + '/' + convert[2] + '/' + convert[0]
            return convert[0] + '/' + convert[1] + '/' + convert[2]
        }

    }

    //RIDE PAYMENT FUNCTION START HERE
    ride_payment() {
        var localData = JSON.parse(localStorage.getItem("currentUser"));
        this.spinner.show();
        this.services.ride_payment(localData._id, this.job_id).pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            if (data.code == 200) {
                this.successModal1.hide();
                this.ngOnInit();
                this.toastr.success("sucess", "Payment Successful");
                this.spinner.hide();
                return;

            } else {
                this.successModal1.hide();
                this.spinner.hide();
                this.toastr.error('error', data.message);
                return;
            }
        });
    }

    //FUNCTION TO MODIFY DATE TIME
    modifyDateTime(str) {
        if (str) {
            const mydate = str.split('T')[0];
            var time = str.split('T')[1];
            var splTime = time.split(':');
            const convert = mydate.split('-');
            //return convert[1] + '/' + convert[2] + '/' + convert[0] + '  ' + splTime[0] + ":" + splTime[1];
            return convert[2] + '/' + convert[1] + '/' + convert[0] + '  ' + splTime[0] + ":" + splTime[1];
        }
    }

    openReasonModel() {
        this.reasonModal.show();
    }
    openMultipleCustomersModal(customers) {
        this.multipleCustomers = customers;
        if (this.multipleCustomers.length) {
            this.addnewcustomer.show();
        }

    }

    approveOwnership() {
        if (this.user_id && this.job_id) {
            this.spinner.show();
            this.services.approveJobOwnership(this.user_id, this.job_id).pipe().subscribe(data => {
                if (data.code == 400) {
                    this.spinner.hide();
                    this.approveOwnershipModal.hide();
                    this.toastr.error('', data.message);
                    this.logoutFunction.logout();
                    return;
                }
                if (data.code == 200) {
                    this.ngOnInit();
                    this.approveOwnershipModal.hide();
                    this.toastr.success("sucess", "Ownership approved");
                    this.spinner.hide();
                    return;

                } else {
                    this.spinner.hide();
                    this.toastr.error('error', data.message);
                    return;
                }

            })

        }

    }

    openNoteModal(note) {
        this.viewNoteModal = note;
        this.noteModal.show();
    }
    prescriptionDownload() {
        FileSaver.saveAs(this.selected_image, "prescription.jpg");
    }

    getPdf() {
        if (this.user_id && this.job_id) {
            this.spinner.show();

            console.log("Test")
            this.services.getJobDetailsPdfNew(this.user_id, this.job_id).pipe().subscribe(data => {
                if (data.code == 400) {
                    this.spinner.hide();
                    this.toastr.error('', data.message);
                    this.logoutFunction.logout();
                    return;
                }
                if (data.code == 200) {

                    const bufferDataArray = new Uint8Array(data.pdf.data).buffer;
                    let pdfBlob = new Blob([bufferDataArray], { type: 'application/pdf' });
                    FileSaver.saveAs(pdfBlob, 'jobDetails.pdf');

                    this.spinner.hide();
                    return;

                } else {
                    this.spinner.hide();
                    this.toastr.error('', data.message);
                    return;

                }

            })

        }

    }

    downloadCustomerPdf(customer_id) {
        this.spinner.show();
        this.services.getCustomerDetailsPdf(this.user_id, this.job_id, customer_id).pipe().subscribe(data => {
            if (data.code == 200) {
                FileSaver.saveAs(data.pdfUrl, 'ResidenceCustomer.pdf');
                this.spinner.hide();
                return;

            } else {
                this.spinner.hide();
                this.toastr.error('', data.message);
                return;

            }

        })

    }

    formatDate(date: string) {
        return moment(date).format('DD/MM/YYYY');
    }

    formatDateTime(date: string) {
        return moment(date).format('DD/MM/YYYY HH:mm');
    }

    refreshJobDetails(jobId) {
        this.job_id = jobId;
        localStorage.setItem("job_id", JSON.stringify(jobId));
        this.getJobDetails();
    }

    extractAllOldJobReferenceJobIds(data: any, jobIds: Job[] = []): Job[] {

        jobIds.unshift(data.jobId ? data.jobId : data);

        if (data.jobId?.old_job_reference) {

            this.extractAllOldJobReferenceJobIds(data.jobId.old_job_reference.jobId, jobIds);
        }

        return jobIds;
    }

    openStepperHistoryModal() {
        const initialState = {
            content: this.stepperStatusHistoryTemplate,
            hideActionBtn: true,
            config: {
                title: "JOBS.HISTORY_OF_JOB",
                cancel: "JOBS.ok"
            },

        };
        this.bsModalRef = this.modalService.show(ModalComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            class: "modal-lg modal-dialog-centered",
        });
    }

    openTransferredFromModal(job_id, content, header) {
        this.transferredFromJobId = job_id
        const initialState = {
            content: content,
            header: header,
        };

        this.bsModalRef = this.modalService.show(ModalComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            class: `modal-xxl modal-dialog-centered`,
        });

    }
}