import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DefaultLayoutComponent } from '../../containers';
import { ServicesService } from '../../services.service';

@Component({
  selector: 'app-prescription-details',
  templateUrl: './prescription-details.component.html',
  styleUrls: ['./prescription-details.component.scss']
})
export class PrescriptionDetailsComponent implements OnInit {
  @ViewChild('commentModal') commentModal: ModalDirective;

  public data: any;
  public comments: any;
  public commentList = [];
  private id: any;
  public isAttempted: boolean;
  public message: any;
  public products = [];
  public newComment = '';
  public productDetails = [];
  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    public routeAct: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,

  ) { }

  ngOnInit() {
    this.id = this.routeAct.snapshot.params["id"];
    this.getAllComments()

  }
  getAllComments() {
    this.getPrescriptionDetails(this.id);
    this.getPrescriptionComments(this.id);
    this.getAllProduct();
  }
  getPrescriptionDetails(id) {
    this.spinner.show();
    this.services.orderDetailAll(id).pipe().subscribe(data => {
      this.data = data;
      //console.log(this.data.customer);
      this.spinner.hide();

      this.isAttempted = true;

      if (data.status == 'Order rejected' || data.status == 'Order confirmed') {
        this.isAttempted = false;
      }

    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });
  }

  getAllProduct() {
    this.spinner.show();
    this.services.productAllList().pipe().subscribe((data: any[]) => {
      if (!data) return;
      this.spinner.hide();
      this.products = data;
      this.products.forEach((v) => {
        this.productDetails = this.productDetails.concat(v.productDetails);
      });
    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });
  }

  getPrescriptionComments(id) {
    this.spinner.show();
    this.services.getOrderComments(id).pipe().subscribe(data => {
      this.comments = data.filter(v => v?.message);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });
  }
  rejectOrder() {

    const data = {
      status: 'Order rejected'
    }
    this.services.updatePrescriptionStatus(this.id, data).subscribe(data => {
      this.toastr.success('Success', 'Order rejected');
      this.getPrescriptionDetails(this.id);
    }, error => {
      console.log('error update :', error);
    });
  }

  acceptOrder(details) {
    const data = {
      status: 'Order confirmed',
      details: details,
    }
    this.services.updatePrescriptionStatus(this.id, data).subscribe(data => {
      this.toastr.success('Success', 'Order confirmed');
      this.getPrescriptionDetails(this.id);
    }, error => {
      console.log('error update :', error);
    });
  }
  addComment(message) {
    if (!message) return;
    const data = {
      message: message
    }
    this.services.addOrderComment(this.id, data).subscribe(data => {
      this.getAllComments()
    }, error => {
      console.log('error update :', error);
    });


  }
  back() {
    this.router.navigate(['/prescriptions']);
  }

  downloadFile() {
    window.open(this.data?.urlS3, '_blank');
  }

  openCommentModal() {
    this.commentModal.show();
  }
  confirmCommentModal() {
    this.addComment(this.newComment);
    // this.getAllComments()
    // this.getPrescriptionComments(this.id);
    this.newComment = '';
    this.commentModal.hide();
  }
  cancelCommentModal() {
    this.commentModal.hide();
  }

  modifyDateTime(str) {
    if (str) {
      const mydate = str.split('T')[0];
      var time = str.split('T')[1];
      var splTime = time.split(':');
      const convert = mydate.split('-');
      //return convert[1] + '/' + convert[2] + '/' + convert[0] + '  ' + splTime[0] + ":" + splTime[1];
      return convert[0] + '/' + convert[1] + '/' + convert[2] + '  ' + splTime[0] + ":" + splTime[1];
    }
  }
  onAnswerModal(modal) {
    this.modalService.open(modal, { backdrop: 'static' }).result.then((result) => {
      if (result) {
        if (result.status == 'reject') {
          this.rejectOrder();
        }
        else {
          this.acceptOrder(result.details);
        }
        this.addComment(result.comment)
      }
    });
  }

}
