import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  Observable
} from 'rxjs';
import {
  map
} from 'rxjs/operators';
import {
  environment
} from '../environments/environment';

export interface LoginResponse {
  code: number;
  message: string;
  result: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUri = environment.apiUrl;

  // private headers = new HttpHeaders().set('Content-Type', 'application/json');
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    withCredentials: false,
  };

  constructor(private myRoute: Router, private http: HttpClient) { }

  //PHARMACY LOGIN API FUNCTION 
  login(email, password, devices, logoutDevices = null): Observable<LoginResponse> {
    console.log(this.baseUri);
    console.log(email, password, devices, logoutDevices);
    return this.http.post<LoginResponse>(this.baseUri + '/users/pharmacyLogin', {
      email,
      password,
      devices,
      logoutDevices
    }, this.httpOptions).pipe(map(user => {
      return user;
    }));
  }

  // FUNCTION FOR REGISTER PHARMACY
  register(email, password, mobile_no, pharmacy_name, location, [lat, long], country_shortname, country_longname, state_shortname, state_longname, city_shortname, city_longname, postcode, country_code, timeZone, country_flag_name, pharmacy_landline_num,
    fax_country_code, fax_number, apartment_suite, suite_number, google_place_id, profile_img): Observable<any> {
    return this.http.post<any>(this.baseUri + '/users/pharmacySignup', {
      email,
      password,
      mobile_no,
      pharmacy_name,
      location,
      lat_long: [lat, long],
      country: {
        "short_name": country_shortname,
        "long_name": country_longname
      },
      state: {
        "short_name": state_shortname,
        "long_name": state_longname
      },
      city: {
        "short_name": city_shortname,
        "long_name": city_longname
      },
      postcode,
      country_code,
      timeZone,
      country_flag_name,
      pharmacy_landline_num,
      fax_country_code,
      fax_number,
      apartment_suite,
      suite_number,
      google_place_id,
      profile_img
    }, this.httpOptions);
  }

  //PHARMACY LOGIN FROM ADMIN SIDE API FUNCTION 
  pharmacyLoginByAdmin(token): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(this.baseUri + '/admin/pharmacyLoginByAdmin', {
      token
    }, this.httpOptions).pipe(map(user => {
      return user;
    }));
  }

  //FORGOT PASSWORD FUNCTION 
  forgot_password(email): Observable<any> {
    return this.http.post<any>(this.baseUri + '/users/forgotPasswordPharmacy', {
      email
    }, this.httpOptions)
  }


  //SEND TOKEN FUNCTION
  sendToken(token: string) {
    localStorage.setItem("LoggedInUser", token)
  }

  //GET USER TOKE FUNCTION
  getToken() {
    var user = JSON.parse(localStorage.getItem("currentUser"));
    if (user && user.email_verified && user.mobile_verified) {
      return localStorage.getItem("currentUser");
    } else {
      return null;
    }
  }

  //CHECK USER IS LOGIN OR NOT 
  isLoggedIn() {
    return this.getToken() !== null;
  }

  //USER LOGOUT FUNCTION 
  logout() {
    localStorage.removeItem("currentUser");
    localStorage.setItem('currentSubscriptionId', JSON.stringify({}));
    this.myRoute.navigate(["login"]);
  }
}