<div class="table-responsive">
  <table class="table jobs-table">
    <thead>
      <ng-container *ngTemplateOutlet="theadTemplate"></ng-container>
    </thead>

    <ng-container *ngTemplateOutlet="tbodyTemplate"></ng-container>
  </table>
  <ng-container *ngTemplateOutlet="noFoundTemplate"></ng-container>
</div>

<div class="d-flex f-right gap-1">
  <pagination-template
    #p="paginationApi"
    *ngIf="totalItems > 0"
    (pageChange)="handlePageChange($event)"
  >
    <div class="custom-pagination">
      <div class="mr-2">
        ({{ "TABLE.records" | translate }} {{ currentPageRange }}
        {{ "TABLE.of" | translate }} {{ totalItems }})
      </div>
      <div>
        {{ "TABLE.page" | translate }} {{ currentPage }}
        {{ "TABLE.from" | translate }} {{ lastPage }}
      </div>
      <button
        class="btn bg-dark"
        (click)="p.setCurrent(1)"
        [disabled]="currentPage === 1 || currentPage === ''"
      >
        <img src="assets/img/new-ui/left_arrow_end.svg" alt="previous_end" />
      </button>
      <div class="pagination-previous">
        <button
          class="btn bg-dark"
          (click)="p.setCurrent(currentPage - 1)"
          [disabled]="currentPage === 1 || currentPage === ''"
        >
          <img src="assets/img/new-ui/left_arrow.svg" alt="previous" />
        </button>
      </div>

      <input
        style="width: 60px"
        class="border rounded"
        type="text"
        [(ngModel)]="currentPage"
        (ngModelChange)="handlePageChange($event)"
        [maxValue]="lastPage"
        [minValue]="1"
        intOnly
      />

      <div class="pagination-next">
        <button
          class="btn bg-dark"
          (click)="p.setCurrent(currentPage + 1)"
          [disabled]="currentPage === lastPage || currentPage === ''"
        >
          <img src="assets/img/new-ui/arrow_right.svg" alt="next" />
        </button>
      </div>
      <button
        (click)="p.setCurrent(p.setCurrent(lastPage))"
        class="btn bg-dark"
        [disabled]="currentPage === lastPage || currentPage === ''"
      >
        <img src="assets/img/new-ui/arrow_right_end.svg" alt="next_end" />
      </button>
    </div>
  </pagination-template>
</div>
