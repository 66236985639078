import {
    Component,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { JobsListComponent } from '../jobs-list/jobs-list.component';
const FileSaver = require('file-saver');

@Component({
    selector: 'app-charged-account-jobs',
    templateUrl: './charged-account-jobs.component.html',
    styleUrls: ['./charged-account-jobs.component.scss']
})
export class ChargedAccountJobsComponent implements OnInit {
    @ViewChild(JobsListComponent) jobsListComponent: JobsListComponent;
    dynamicContentTemplate: TemplateRef<any>;

    constructor(

    ) {

    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.dynamicContentTemplate = this.jobsListComponent.dynamicContentTemplate;

    }

}