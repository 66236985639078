import {
    HttpClient
} from '@angular/common/http';
import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    Router
} from '@angular/router';
import {
    NgbDatepickerConfig
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {
    ModalDirective
} from 'ngx-bootstrap/modal';
import {
    NgxSpinnerService
} from "ngx-spinner";
import {
    ToastrService
} from 'ngx-toastr';
import {
    DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
    ServicesService
} from '../../services.service';
import { DateTab } from '../../utils/Enums';
const FileSaver = require('file-saver');

@Component({
    selector: 'app-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
    @ViewChild('successModal1') public successModal1: ModalDirective;
    @ViewChild('successModal') public successModal: ModalDirective;


    p1: number = 1;
    public invoce_jobs = [];
    public jobsCount: any;
    public invoice_id: any;
    public lastPaidOn: any;
    public pendingAmount: any;
    public type = "0";
    public sDate: any;
    public eDate: any;
    startDate: string;
    endDate: string;
    user_id: any;
    //   public pageType: any;
    public selected_image: any;
    srcPdf: any;
    dataState = 'START';
    dateTab = DateTab;
    range: DateTab = DateTab.Today

    constructor(
        public toastr: ToastrService,
        private services: ServicesService,
        private http: HttpClient,
        public router: Router,
        private spinner: NgxSpinnerService,
        public config: NgbDatepickerConfig,
        public logoutFunction: DefaultLayoutComponent) { }

    ngOnInit() {
        this.dataState = 'START';
        this.p1 = 1;
        this.srcPdf = "";
        this.sDate = '';
        this.eDate = '';
        this.startDate = '';
        this.endDate = '';
        this.type = '0';
        this.spinner.show();
        let localData = JSON.parse(localStorage.getItem("currentUser"));
        this.user_id = localData._id;
        this.lastPaidOn = localData.last_paid_on;
        //   this.services.getInvoiceTransactions(localData._id, this.p1, this.type).pipe().subscribe((data) => {
        this.onPageLoad();

    }

    onPageLoad() {
        this.dataState = 'START';
        this.services.getInvoiceForPharmacy(this.user_id, this.p1, this.startDate, this.endDate, this.type, this.dataState).pipe().subscribe((data) => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            if (data.code == 200) {
                console.log("data:", data);
                this.invoce_jobs = data.result;
                this.lastPaidOn = data.pharmacy.last_paid_on;
                this.jobsCount = data.transactionsCount;
                this.pendingAmount = data.UnpaidAmount;
                //this.pageType = data.type;
                this.spinner.hide();
                return

            } else {
                this.spinner.hide();
                this.toastr.error('error', data.message);
                return;
            }
        });

    }

    afterPageLoad() {
        this.dataState = 'END';
        this.services.getInvoiceForPharmacy(this.user_id, this.p1, this.startDate, this.endDate, this.type, this.dataState).pipe().subscribe((data) => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            if (data.code == 200) {
                console.log("data:", data);
                this.invoce_jobs = data.result;
                this.jobsCount = data.transactionsCount;
                this.spinner.hide();
                return

            } else {
                this.spinner.hide();
                this.toastr.error('error', data.message);
                return;
            }
        });

    }



    //FUNCTION FOR FILTER DATA
    filter(event) {
        this.spinner.show();
        this.p1 = 1
        this.type = event.target.value;
        this.afterPageLoad();
        //   if (event.target.value == "0") {
        //       this.ngOnInit();
        //       return
        //   } else {
        //   this.services.getInvoiceTransactions(localData._id, this.p1, this.type).pipe().subscribe((data) => {
        //     this.services.getInvoiceForPharmacy(this.user_id, this.p1,this.startDate,this.endDate, this.type).pipe().subscribe((data) => {
        //       if (data.code == 400) {
        //           this.spinner.hide();
        //           this.toastr.error('', data.message);
        //           this.logoutFunction.logout();
        //           return;
        //       }
        //       if (data.code == 200) {
        //           console.log("data:", data);
        //           this.invoce_jobs = data.result;
        //           this.lastPaidOn = data.pharmacy.last_paid_on;
        //           this.jobsCount = data.transactionsCount;
        //           this.pendingAmount = data.UnpaidAmount;
        //         //   this.pageType = data.type;
        //           this.spinner.hide();
        //           return
        //       } else {
        //           this.spinner.hide();
        //           this.toastr.error('error', data.message);
        //           return;
        //       }
        //   });
        //   }
    }

    //FUNCTION TO GET DATA ON PAGE CHANGE
    onPageChange(p1) {
        this.spinner.show();
        this.p1 = p1;
        this.afterPageLoad();
        //   this.services.getInvoiceTransactions(localData._id, this.p1, this.type).pipe().subscribe((data) => {
        //     this.services.getInvoiceForPharmacy(this.user_id, this.p1,this.startDate,this.endDate, this.type).pipe().subscribe((data) => {
        //       if (data.code == 400) {
        //           this.spinner.hide();
        //           this.toastr.error('', data.message);
        //           this.logoutFunction.logout();
        //           return;
        //       }
        //       if (data.code == 200) {
        //           console.log("data:", data);
        //           this.invoce_jobs = data.result;
        //           this.jobsCount = data.transactionsCount;
        //           this.pendingAmount = data.UnpaidAmount;
        //         //   this.pageType = data.type;
        //           this.spinner.hide();

        //       } else {
        //           this.spinner.hide();
        //           this.toastr.error('error', data.message);
        //           return;
        //       }
        //   });

    }


    //FUNCTION TO GO INVOICE DETAIL PAGE
    invoice_detail(job) {
        localStorage.setItem('invoice_job', JSON.stringify(job));
        this.spinner.show();
        this.router.navigate(['/invoice-detail']);

    }

    //FUNCTION TO REQUEST PAYMENT
    request_payment() {
        this.spinner.show();
        this.services.request_for_payment(this.user_id).pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            if (data.code == 200) {
                this.successModal1.hide();
                this.toastr.success('', "Payment Request Send Successfully");
                this.spinner.hide();
                return;

            } else {
                this.successModal1.hide()
                this.spinner.hide();
                this.toastr.error('error', data.message);
                return;
            }
        })
    }


    //FUNCTION FOR REFRESH PAGE
    resetPage() {
        this.router.navigateByUrl('/changePassword', {
            skipLocationChange: true
        }).then(() => {
            this.router.navigate(['invoice']);
        });
    }


    //search by date function start here
    SearchByDate() {
        if (!this.sDate) {
            this.toastr.error('error', 'Please enter start date');
            return false;
        } else if (!this.eDate) {
            this.toastr.error('error', 'Please enter end date');
            return false;
        }
        //   this.startDate = this.sDate.year + '/' + this.sDate.month + '/' + this.sDate.day;
        //   this.endDate = this.eDate.year + '/' + this.eDate.month + '/' + this.eDate.day;
        this.startDate = this.sDate.month + '/' + this.sDate.day + '/' + this.sDate.year;
        this.endDate = this.eDate.month + '/' + this.eDate.day + '/' + this.eDate.year;
        if (moment(this.startDate) > moment(this.endDate)) {
            this.toastr.error('error', 'Please enter start date less then end date');
            return false;
        }
        this.p1 = 1;
        // this.transitionSearchByDate();
        this.spinner.show();
        this.afterPageLoad();
    }


    //FUNCTION TO GENERATE PDF ACCORDING TO DATE  
    getPdfByDate() {
        if (!this.sDate) {
            this.toastr.error('error', 'Please enter start date');
            return false;
        } else if (!this.eDate) {
            this.toastr.error('error', 'Please enter end date');
            return false;
        }
        this.startDate = this.sDate.month + '/' + this.sDate.day + '/' + this.sDate.year;
        this.endDate = this.eDate.month + '/' + this.eDate.day + '/' + this.eDate.year;
        if (moment(this.startDate) > moment(this.endDate)) {
            this.toastr.error('error', 'Please enter start date less then end date');
            return false;
        }
        this.spinner.show();
        this.services.sendPdfPharmacy(this.user_id, this.startDate, this.endDate, '1').pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            if (data.code == 200) {
                const bufferDataArray = new Uint8Array(data.pdf.data).buffer;
                let pdfBlob = new Blob([bufferDataArray], { type: 'application/pdf' });

                this.downloadPdf(pdfBlob);
                this.spinner.hide();
            } else {
                this.spinner.hide();
                this.toastr.error('error', data.message);
                return;
            }
        })
    }

    changeRange(range: DateTab) {
        this.range = range;
        switch (range) {
            case DateTab.Today:
                this.startDate = moment().startOf('day').format("MM/DD/YYYY");
                break;
            case DateTab.Week:
                this.startDate = moment().startOf('week').format("MM/DD/YYYY");
                break;
            case DateTab.Month:
                this.startDate = moment().startOf('month').format("MM/DD/YYYY");
                break;
        }
        this.spinner.show();
        this.p1 = 1
        this.afterPageLoad();
    }

    //FUNCTION TO DOWNLOAD PDF
    downloadPdf(pdfBlob) {
        let date = new Date().toDateString();
        var currentDate = this.formatDate(date);
        var pdfDate = this.modifyPdfDate(currentDate)

        const pdfName = 'Pharmacy_' + pdfDate + '.pdf';
        FileSaver.saveAs(pdfBlob, pdfName);
    }

    //FUNCTION TO MODIFY PDF DATE
    modifyPdfDate(str) {
        const mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        const a = new Date(str.split('T')[0]);
        const month_ = a.getMonth();
        const date_ = a.getDate();
        const year_ = a.getFullYear();
        return (mS[month_] + ' ' + date_ + ',' + year_);
    }


    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }


    //FUNCTION TO SEND PDF TO PHARMACY
    sendPdfPharmacy() {
        if (!this.sDate) {
            this.toastr.error('error', 'Please enter start date');
            return false;
        } else if (!this.eDate) {
            this.toastr.error('error', 'Please enter end date');
            return false;
        }
        this.startDate = this.sDate.month + '/' + this.sDate.day + '/' + this.sDate.year;
        this.endDate = this.eDate.month + '/' + this.eDate.day + '/' + this.eDate.year;
        if (moment(this.startDate) > moment(this.endDate)) {
            this.toastr.error('error', 'Please enter start date less then end date');
            return false;
        }
        this.spinner.show();
        this.services.sendPdfPharmacy(this.user_id, this.startDate, this.endDate, '2').pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            if (data.code == 200) {
                this.spinner.hide();
                this.toastr.success('Pdf Sent On Your Registered Email');
                return;

            } else {
                this.spinner.hide();
                this.toastr.error('error', data.message);
                return;
            }
        })
    }

    //FUNCTION TO GET PAGE CHANGE DATA ACC TO SELECTED DATE
    onPageChangeforDate(p1) {
        this.spinner.show();
        this.p1 = p1;
        this.transitionSearchByDate();
    }

    //FUNCTION FOR TRANSITION SEARCH BY DATE
    transitionSearchByDate() {
        this.spinner.show();
        //   this.services.invoice_search_byDate(this.user_id, this.startDate, this.endDate, this.p1).pipe().subscribe((data) => {
        this.services.getInvoiceForPharmacy(this.user_id, this.p1, this.startDate, this.endDate, this.type, 'END').pipe().subscribe((data) => {
            console.log(data)
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            if (data.code == 200) {
                this.invoce_jobs = data.result;
                //this.pageType = data.type;
                this.jobsCount = data.count;
                this.spinner.hide();
            } else {
                this.spinner.hide();
                this.toastr.error('error', data.message);
                return;
            }
        });
    }


    cheque_img(image) {
        this.selected_image = image;
        this.successModal.show();
        console.log("chew")
    }

    //FUNCTION TO MODIFY DATE
    modifyDate(str) {
        const mydate = str.split('T')[0]
        const convert = mydate.split('-')
        //return convert[1] + '/' + convert[2] + '/' + convert[0]
        return convert[0] + '/' + convert[1] + '/' + convert[2]
    }
}