import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  BsModalRef,
  BsModalService
} from 'ngx-bootstrap/modal';
import { JobDetailsComponent } from "../../../views/job-details/job-details.component";
import { ModalComponent } from "../modal/modal.component";
@Component({
  selector: "app-job-link",
  templateUrl: "./job-link.component.html",
  styleUrls: ["./job-link.component.scss"],
})
export class JobLinkComponent implements OnInit {
  @Input() job: any;
  @ViewChild(JobDetailsComponent) jobDetailsComponent: JobDetailsComponent;
  bsModalRef: BsModalRef;

  constructor(private router: Router,
    private modalService: BsModalService,) { }

  ngOnInit(): void { }


  showJobModel(content, header, event) {

    event.preventDefault();
    const initialState = {
      content: content,
      header: header,
    };

    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: `modal-xxl modal-dialog-centered`,
    });

  }
}
