import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ResidenceComponent } from '../residence/residence.component';
@Component({
  selector: 'app-medical-center',
  templateUrl: './medical-center.component.html',
  styleUrls: ['./medical-center.component.scss']
})
export class MedicalCenterComponent implements OnInit, AfterViewInit {
  @ViewChild('residenceComponent', { static: false }) residenceComponent: ResidenceComponent;
  dynamicContentTemplate: TemplateRef<any>;
  constructor() { }

  ngAfterViewInit() {
    this.dynamicContentTemplate = this.residenceComponent.dynamicContentTemplate;

  }
  ngOnInit() {
  }

}
