import { Component, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { TabsetComponent } from "ngx-bootstrap/tabs";
import { Subscription } from 'rxjs';
import { environment } from "../../../environments/environment";
import { initJobsCountByTagData, jobsCountByTagData } from '../../models/jobsCountByTagData';
import { PusherServiceService } from '../../pusher-service.service';
import { SharedDataService } from '../../shared-data.service';
import { CommonMethods } from '../_helpers/_common-methods';
import { ActiveJobsComponent } from '../active-jobs/active-jobs.component';
import { ChargedAccountJobsComponent } from '../charged-account-jobs/charged-account-jobs.component';
import { CompletedJobsComponent } from '../completed-jobs/completed-jobs.component';
import { JobsListComponent } from '../jobs-list/jobs-list.component';
import { NewJobRequestsComponent } from '../new-job-requests/new-job-requests.component';
import { NotDeliveredJobsComponent } from '../not-delivered-jobs/not-delivered-jobs.component';
import { RecursiveJobsComponent } from '../recursive-jobs/recursive-jobs.component';
@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  selectedTabIndex = 'new-jobs';
  @ViewChild(JobsListComponent) jobsListComponent: JobsListComponent;
  @ViewChild(NewJobRequestsComponent) newJobRequestsComponent: NewJobRequestsComponent;
  @ViewChild(ActiveJobsComponent) activeJobsComponent: ActiveJobsComponent;
  @ViewChild(CompletedJobsComponent) completedJobsComponent: CompletedJobsComponent;
  @ViewChild(NotDeliveredJobsComponent) notDeliveredJobsComponent: NotDeliveredJobsComponent;
  @ViewChild(RecursiveJobsComponent) recursiveJobsComponent: RecursiveJobsComponent;
  @ViewChild(ChargedAccountJobsComponent) chargedAccountJobsComponent: ChargedAccountJobsComponent;
  @ViewChild(TabsetComponent) tabset: TabsetComponent;
  newJobs: jobsCountByTagData = initJobsCountByTagData();
  activeJobs: jobsCountByTagData = initJobsCountByTagData();
  completedJobs: jobsCountByTagData = initJobsCountByTagData();
  private sharedNewJobsSubscription: Subscription;
  private sharedActiveJobsSubscription: Subscription;
  private sharedCompletedJobsSubscription: Subscription;

  searchEvent = "";
  searchDate = '';
  Date: any;
  currentUser: any


  constructor(private SharedDataService: SharedDataService, private commonMethods: CommonMethods,
    private pusherService: PusherServiceService,) {
    this.sharedNewJobsSubscription = this.SharedDataService.getSharedNewJobsData().subscribe(newJobsData => {
      this.newJobs = newJobsData;
    });
    this.sharedActiveJobsSubscription = this.SharedDataService.getSharedActiveJobsData().subscribe(activeJobsData => {
      this.activeJobs = activeJobsData;
    });
    this.sharedCompletedJobsSubscription = this.SharedDataService.getSharedCompletedJobsData().subscribe(completedJobsData => {
      this.completedJobs = completedJobsData;
    });
  }

  ngAfterViewInit(): void {

    const tab_index = sessionStorage.getItem("jobs_tab_index");
    if (tab_index) {
      this.changeTab(tab_index);
      sessionStorage.removeItem("jobs_tab_index");
    }

  }
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.searchEvent = "";

    setTimeout(() => {
      var selectedDate = JSON.parse(localStorage.getItem("selected_date"));
      if (selectedDate) {
        var current = new Date(selectedDate);
        this.Date = {
          year: current.getFullYear(),
          month: current.getMonth() + 1,
          day: current.getDate()
        };
      } else {
        var current = new Date();
        this.Date = {
          year: current.getFullYear(),
          month: current.getMonth() + 1,
          day: current.getDate()
        }
      }
      this.activeJobsComponent.jobsListComponent.searchWithDate('0')

      this.chargedAccountJobsComponent.jobsListComponent.searchWithDate('0')
      this.completedJobsComponent.jobsListComponent.searchWithDate('0')
      this.activeJobsComponent.jobsListComponent.searchWithDate('0')
      this.newJobRequestsComponent.jobsListComponent.searchWithDate('0')
      this.notDeliveredJobsComponent.jobsListComponent.searchWithDate('0')
      this.recursiveJobsComponent.jobsListComponent.searchWithDate('0')

    }, 50);
    this.initPusher();
  }

  initPusher() {
    const jobChannel = this.pusherService.init(
      environment.CHANNEL_JOB_CHANGED
    ) as unknown as { bind?: any };
    if (jobChannel.bind) {

      jobChannel.bind(`pharmacy_jobs_deleted`, (data) => {
        if (data.method === "delete") {
          this.handleJobChannel();
        }
      });
      jobChannel.bind(`jobs_changed_${this.currentUser?._id}`, (data) => {

        if (data.user_id === this.currentUser?._id) {
          this.handleJobChannel();
        }
      });
    }

  }

  private handleJobChannel() {
    this.commonMethods.throttle(() => {
      this.chargedAccountJobsComponent.jobsListComponent.getJobs(false)
      this.completedJobsComponent.jobsListComponent.getJobs(false)
      this.activeJobsComponent.jobsListComponent.getJobs(false)
      this.newJobRequestsComponent.jobsListComponent.getJobs(false)
      this.notDeliveredJobsComponent.jobsListComponent.getJobs(false)
      this.recursiveJobsComponent.jobsListComponent.getJobs(false)
    }, 4000)(); // Throttle limit set to 4000ms

  }

  onTabChange(event) {
    this.selectedTabIndex = event.id;
  }

  changeTab(id) {
    const tab = this.tabset.tabs.find(t => t.id === id);
    if (tab) {
      tab.active = true;
    }
  }

  goToJobsList(id) {
    this.selectedTabIndex = id;
    this.changeTab(id);
  }
  ngOnDestroy() {
    this.sharedNewJobsSubscription.unsubscribe();
    this.sharedActiveJobsSubscription.unsubscribe();
    this.sharedCompletedJobsSubscription.unsubscribe();
    this.pusherService.removeChannel(environment.CHANNEL_JOB_CHANGED);

  }


  onSearch(keyword: string) {

    this.searchEvent = keyword;
    if (this.searchEvent.length > 2 || keyword == '') {
      this.completedJobsComponent.jobsListComponent.onSearch(keyword)
      this.activeJobsComponent.jobsListComponent.onSearch(keyword)
      this.newJobRequestsComponent.jobsListComponent.onSearch(keyword)
      this.notDeliveredJobsComponent.jobsListComponent.onSearch(keyword)
      this.recursiveJobsComponent.jobsListComponent.onSearch(keyword)
      this.chargedAccountJobsComponent.jobsListComponent.onSearch(keyword)
    }
  }

  changeRange() {
    this.searchDate = moment().local().format("MM/DD/YYYY");

    let d = new Date(this.searchDate)
    this.Date = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.searchWithDate()
  }

  onDateChange(event) {
    this.searchDate = event.month + '/' + event.day + '/' + event.year;

    this.searchWithDate()
  }

  searchWithDate() {
    this.chargedAccountJobsComponent.jobsListComponent.onDateChange(this.searchDate)
    this.completedJobsComponent.jobsListComponent.onDateChange(this.searchDate)
    this.activeJobsComponent.jobsListComponent.onDateChange(this.searchDate)
    this.newJobRequestsComponent.jobsListComponent.onDateChange(this.searchDate)
    this.notDeliveredJobsComponent.jobsListComponent.onDateChange(this.searchDate)
    this.recursiveJobsComponent.jobsListComponent.onDateChange(this.searchDate)
  }
  reset() {
    this.ngOnInit()
    this.chargedAccountJobsComponent.jobsListComponent.ngOnInit()
    this.completedJobsComponent.jobsListComponent.ngOnInit()
    this.activeJobsComponent.jobsListComponent.ngOnInit()
    this.newJobRequestsComponent.jobsListComponent.ngOnInit()
    this.notDeliveredJobsComponent.jobsListComponent.ngOnInit()
    this.recursiveJobsComponent.jobsListComponent.ngOnInit()
  }


}