<ng-template #dynamicContent>
  <div class="justify-content-end">
    <app-button
      [imageSrc]="'./assets/img/new-ui/save.svg'"
      [buttonText]="'ADDRESS.add_new_direction' | translate"
      (buttonClick)="upsertAddress(addressBodyTemplate, addressHeaderTemplate)"
    ></app-button>
  </div>
</ng-template>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="left-sub-title mb-3">
        <strong>{{ client?.customer_l_name }} </strong
        >{{ client?.customer_f_name }} :
        {{ "ADDRESS.address_list" | translate }}
      </div>

      <app-table
        [theadTemplate]="theadTemplate"
        [tbodyTemplate]="tbodyTemplate"
      ></app-table>
      <ng-template #theadTemplate>
        <tr>
          <th>N°</th>
          <th>{{ "ADDRESS.alias_en" | translate }}</th>
          <th>{{ "ADDRESS.alias_fr" | translate }}</th>
          <th>{{ "ADDRESS.landmark" | translate }}</th>
          <th>{{ "ADDRESS.city" | translate }}</th>
          <th>{{ "ADDRESS.postal_code" | translate }}</th>
          <th>{{ "ADDRESS.default" | translate }}</th>
          <th>{{ "ADDRESS.actions" | translate }}</th>
        </tr>
      </ng-template>

      <ng-template #tbodyTemplate>
        <tbody>
          <tr *ngFor="let address of addresses; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ address?.address }}</td>
            <td>{{ address?.address_fr }}</td>
            <td>{{ address?.landmark }}</td>
            <td>{{ address?.city }}</td>
            <td>{{ address?.zipcode }}</td>
            <td>
              <div
                class="custom-control custom-checkbox d-flex align-items-center"
              >
                <div>
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    style="width: 20px"
                    id="customCheck{{ i }}"
                    [checked]="address?.default"
                    (click)="onChangeDefaultAddress(address)"
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck{{ i }}"
                  ></label>
                </div>
              </div>
            </td>
            <td>
              <ul class="action-icons">
                <li>
                  <img
                    src="assets/img/new-ui/edit.svg"
                    (click)="
                      upsertAddress(
                        addressBodyTemplate,
                        addressHeaderTemplate,
                        address
                      )
                    "
                    ngbTooltip="Edit"
                  />
                </li>
                <li *ngIf="!address?.default">
                  <img
                    src="assets/img/new-ui/delete.svg"
                    (click)="
                      openDeleteAddressModal(deleteAdressTemplate, address)
                    "
                    ngbTooltip="Delete"
                  />
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </ng-template>

      <ng-template #noFoundTemplate>
        <div class="no-jobs-found">
          <p
            class="no-item text-center text-danger mb-0"
            *ngIf="!addresses.length"
          >
            {{ "CLIENTS.no_client_found" | translate }}
          </p>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #addressHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3 justify-content-between">
    <h5 class="modal-title">{{ "ADDRESS.new_address" | translate }}</h5>
    <ng-container
      *ngTemplateOutlet="locationAgmComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #addressBodyTemplate>
  <app-location-agm
    [activeAlias]="true"
    [item]="selectedAddress"
    (getLocationData)="saveUpdateAddress($event)"
  ></app-location-agm>
</ng-template>

<ng-template #deleteAdressTemplate>
  <div class="text-center">
    {{ "ADDRESS.delete_address_message" | translate }}
  </div>
</ng-template>
