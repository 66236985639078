import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { DateTab } from '../../../utils/Enums';
@Component({
  selector: 'app-custom-calendar-datepickers',
  templateUrl: './custom-calendar-datepickers.component.html',
  styleUrls: ['./custom-calendar-datepickers.component.scss']
})
export class CustomCalendarDatepickersComponent implements OnInit {
  @Input() showDefaultDate: boolean = true;
  @Input() startDate: string;
  @Input() pageNumber: number;
  @Output() pageNumberChange = new EventEmitter<number>();
  @Input() endDate: string;
  @Output() getData = new EventEmitter<any>();
  @Output() startDateChange = new EventEmitter<string>();
  @Output() endDateChange = new EventEmitter<string>();
  hoveredDate: NgbDate | null = null;
  @Input() fromDate: NgbDate;
  @Input() toDate: NgbDate | null = null;
  @Output() fromDateChange = new EventEmitter<NgbDate>();
  @Output() toDateChange = new EventEmitter<NgbDate>();
  dateRangeSelected = [];
  dateTab = DateTab;
  range: DateTab;

  constructor(public calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
  ) { }

  ngOnInit(): void {
    //FOR SHOW DATE RANGE DATA IN DEFAULT
    if (!this.fromDate && !this.toDate && this.showDefaultDate) {


      this.initDateRange();
      this.emitDate()
      this.emitData();
    }
  }

  initDateRange() {
    this.fromDate = this.calendar.getPrev(this.calendar.getToday());
    const lastWeek = moment().subtract(7, 'days').calendar();
    if (lastWeek) {
      const splitWeek = lastWeek.split('/');
      this.fromDate.day = parseInt(splitWeek[1]);
      this.fromDate.month = parseInt(splitWeek[0])
      this.fromDate.year = parseInt(splitWeek[2])
    }
    this.toDate = this.calendar.getToday();
  }
  emitData() {
    this.startDate = `${this.fromDate.year}/${this.fromDate.month}/${this.fromDate.day}`;
    this.startDateChange.emit(this.startDate);
    this.endDate = `${this.toDate.year}/${this.toDate.month}/${this.toDate.day}`;
    this.endDateChange.emit(this.endDate);
    this.pageNumberChange.emit(1);
    this.getData.emit();
  }

  emitDate() {
    this.fromDateChange.emit(this.fromDate);
    this.toDateChange.emit(this.toDate);
  }
  //DATE RANGE SELECTION FUNCTION
  onDateSelection(date: NgbDate) {

    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.emitDate();
    if (this.fromDate && this.toDate) {
      this.emitData();

      switch (true) {
        case this.fromDate.equals(this.calendar.getToday()) && this.toDate.equals(this.calendar.getToday()):
          this.range = DateTab.Today;
          break;
        case this.fromDate.equals(this.momentToNgbDate(moment().startOf('week'))) && this.toDate.equals(this.momentToNgbDate(moment().endOf('week'))):
          this.range = DateTab.Week;
          break;
        case this.fromDate.equals(this.momentToNgbDate(moment().startOf('month'))) && this.toDate.equals(this.momentToNgbDate(moment().endOf('month'))):
          this.range = DateTab.Month;
          break;
        default:
          this.range = null;
      }


    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {

    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  changeRange(range: DateTab) {
    this.range = range;
    switch (range) {
      case DateTab.Today:
        this.fromDate = this.calendar.getToday();
        this.toDate = this.calendar.getToday();
        break;
      case DateTab.Week:
        this.fromDate = this.momentToNgbDate(moment().startOf('week'));
        this.toDate = this.momentToNgbDate(moment().endOf('week'));
        break;
      case DateTab.Month:
        this.fromDate = this.momentToNgbDate(moment().startOf('month'));
        this.toDate = this.momentToNgbDate(moment().endOf('month'));

        break;
    }

    this.emitDate();
    this.emitData();

  }

  momentToNgbDate(momentDate: moment.Moment): NgbDate {
    return new NgbDate(
      momentDate.year(),
      momentDate.month() + 1, // NgbDate month is 1-based
      momentDate.date()
    );
  }
}