export const JOB = {
    DELIVERY_TYPE: {
        URGENT_DELIVERY: "1",
        NORMAL_DELIVERY: "2",
        NEXT_DAY_DELIVERY: "3",
    },
    JOB_STATUS: {
        PENDING: "P",
        ACTIVE: "A",
        COMPLETED: "C",
        NOT_DELIVERED: "N",
        DECLINE: "D",
        TRANSFER: "T",
        CANCEL: "CN",
    },
    JOB_TYPE: {
        DELIVERY: "1",
        PICKUP: "2",
    },
    JOB_FOR: {
        PUBLIC: "1",
        PRIVATE: "2",
        DISPATCH: "3",
    },
    JOB_TAG: {
        URGENT: "3",
        MORNING: "9",
        AFTERNOON: "10",
        EVENING: "11",
        NIGHT: "12",
    },
};  