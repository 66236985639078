import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from "@ngx-translate/core";
import {
  BsModalRef,
  BsModalService
} from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../../../services.service';
import { Address } from "../../../services/interfaces";
import { LocationAgmComponent } from '../../../shared/component/location-agm/location-agm.component';
import { ModalComponent } from "../../../shared/component/modal/modal.component";
interface markerItem {
  _id?: string;
  lat: number;
  lng: number;
  address: string;
  aliasEn?: string;
  aliasFr?: string;
}

@Component({
  selector: 'app-list-client-addresses-modal',
  templateUrl: './list-client-addresses-modal.component.html',
  styleUrls: ['./list-client-addresses-modal.component.scss']
})

export class ListClientAddressesModalComponent implements OnInit {
  @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
  @ViewChild(LocationAgmComponent) locationAgmComponent: LocationAgmComponent;
  @Input() client: any;
  @Input() modal: NgbModalRef;
  @Output() refreshTableClients = new EventEmitter<boolean>();
  selectedAddress: markerItem = null;
  addresses: Address[] = [];
  idAddressDelete: string = null;
  clientId: string = null;
  bsModalRef: BsModalRef;
  constructor(
    private modalService: NgbModal,
    private services: ServicesService,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private bsModalService: BsModalService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.clientId = this.client?._id;
    this.getAddressClient(this.clientId);
  }

  getAddressClient(clientId: string) {
    this.spinner.show();
    this.services.getAddressesClient(clientId).subscribe(
      (res: any) => {
        if (res?.code === 200) {
          this.addresses = res?.directions;
          this.spinner.hide();
        } else {
          this.toast.error(res?.message, 'Error');
          this.spinner.hide();
        }
      },
      (err: any) => {
        this.toast.error(err?.message, 'Error');
        this.spinner.hide();
      }
    );
  }

  upsertAddress(content, header, address: Address) {

    address ? this.selectedAddress = {
      _id: address?._id,
      lat: address?.latitude,
      lng: address?.longitude,
      address: address?.landmark,
      aliasEn: address?.address,
      aliasFr: address?.address_fr
    } : this.selectedAddress = null;

    const initialState = {
      content: content,
      header: header,
    };
    const modalClass = address ? "modal-xxl" : "modal-xl";
    this.bsModalRef = this.bsModalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: `${modalClass} modal-dialog-centered`,
    });
  }

  saveUpdateAddress(address: any) {

    let city = null;
    let zipcode = null;

    address?.address_components.forEach(data => {
      if (data.types[0] == "locality") {
        city = data.long_name;
      }
      if (data.types[0] == "postal_code") {
        zipcode = data.long_name;
      }
    });

    const ADDRESS_DATA: Address = {
      latitude: address?.geometry.location.lat(),
      longitude: address?.geometry.location.lng(),
      address: address?.aliasEn || '-',
      address_fr: address?.aliasFr || '-',
      landmark: address?.formatted_address,
      city: city,
      zipcode: zipcode,
      default: false
    }

    if (!this.selectedAddress) {
      this.saveNewAddress(ADDRESS_DATA);
    }
    else {
      this.updateAddress(ADDRESS_DATA);
    }
  }

  saveNewAddress(dataAddress: Address) {

    this.spinner.show();
    this.services.addNewAddressClient(this.clientId, dataAddress).subscribe(
      (res: any) => {
        if (res?.code === 200) {
          this.toast.success(this.translate.instant('ADDRESS.add_address_success'));
          this.spinner.hide();
          this.bsModalRef.hide();
          this.getAddressClient(this.clientId);
        } else {
          this.toast.error(res?.message, 'Error');
          this.spinner.hide();
        }
      }, (err: any) => {
        this.toast.success(this.translate.instant('ADDRESS.add_address_error'));
        this.spinner.hide();
      }
    );

  }

  updateAddress(address: Address) {
    delete address.default;

    this.spinner.show();
    this.services.updateAddressClient(this.clientId, this.selectedAddress?._id, address).subscribe(
      (res: any) => {
        if (res?.code === 200) {
          this.toast.success(this.translate.instant('ADDRESS.edit_address_success'));
          this.spinner.hide();
          this.getAddressClient(this.clientId);
          this.bsModalRef.hide();
        } else {
          this.toast.error(res?.message, 'Error');
          this.spinner.hide();
        }
      }, (err: any) => {
        this.toast.error(this.translate.instant('ADDRESS.edit_address_error'));
        this.spinner.hide();
      }
    );

  }

  onChangeDefaultAddress(address: Address) {
    this.spinner.show();
    this.services.updateDefaultAddressClient(this.clientId, address?._id, { default: true }).subscribe(
      (res: any) => {
        if (res?.code === 200) {
          this.toast.success(res?.message, 'Success');
          this.spinner.hide();
          this.getAddressClient(this.clientId);
          this.refreshTableClients.emit();
        } else {
          this.toast.error(res?.message, 'Error');
          this.spinner.hide();
        }
      },
      (err: any) => {
        this.toast.error(err?.message, 'Error');
        this.spinner.hide();
      }
    );
  }

  openDeleteAddressModal(deleteAdressTemplate, address: Address) {
    this.idAddressDelete = address?._id;

    const initialState = {
      content: deleteAdressTemplate,
      config: {
        title: "Confirmation",
        action: "CREATEJOB.yes",
        cancel: "CREATEJOB.no"
      },
      onClick: this.deleteAddress.bind(this),

    };
    this.bsModalRef = this.bsModalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-sm modal-dialog-centered",
    });

  }

  deleteAddress() {

    this.spinner.show();
    this.services.deleteAddressClient(this.clientId, this.idAddressDelete).subscribe(
      (res: any) => {
        this.bsModalRef.hide();
        this.toast.success(this.translate.instant("ADDRESS.delete_address_success"));
        this.getAddressClient(this.clientId);
        this.spinner.hide();
      },
      (err: any) => {
        this.toast.error(this.translate.instant("ADDRESS.delete_address_error"));
        this.spinner.hide();
      }
    );
  }

}
