import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    NgxSpinnerService
} from "ngx-spinner";
import {
    ServicesService
} from '../../services.service';
import {
    ToastrService
} from 'ngx-toastr';
import {
    Router
} from '@angular/router';
import {
    ModalDirective
} from 'ngx-bootstrap/modal';
import {
    DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';

@Component({
    selector: 'app-subscription-plans',
    templateUrl: './subscription-plans.component.html',
    styleUrls: ['./subscription-plans.component.scss']
})
export class SubscriptionPlansComponent implements OnInit {
    @ViewChild('successModal1') successModal1: ModalDirective;
    @ViewChild('successModal2') successModal2: ModalDirective;

    user_id: any;
    plans = [];
    currentSubscription: any;
    stripe_customerId: any;
    expireDate: any;
    startDate: any;
    plan_id: any;
    unixStartDate: any;
    unixEndDate: any;
    selectedlanguage = 'en';
    selectPlanId: any;

    constructor(
        public toastr: ToastrService,
        private services: ServicesService,
        public router: Router,
        private spinner: NgxSpinnerService,
        public logoutFunction: DefaultLayoutComponent) {}

    ngOnInit() {
        var language = JSON.parse(localStorage.getItem("language"));
        this.selectPlanId = JSON.parse(localStorage.getItem("currentSubscriptionId"));

        if (language == 'fr') {
            this.selectedlanguage = 'fr';
        } else {
            this.selectedlanguage = 'en';
        }
        this.plan_id = "";
        this.expireDate = "";
        this.startDate = "";
        var localData = JSON.parse(localStorage.getItem("currentUser"));
        
        this.user_id = localData._id;
        this.stripe_customerId = localData.stripe_customerId
        this.spinner.show();
        this.services.getAllSubscriptionPlans(this.user_id).pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            if (data.code == 200) {
                //console.log("Plansdata:", data);
                this.plans = data.result;
                this.spinner.hide();
                return
            } else {
                this.spinner.hide();
                this.toastr.error('error', data.message);
            }
        });
    }

    //SELECT PLAN FUNCTION START HERE
    selectPlanData(data) {
        this.plan_id = data;
    }
     
    //THIS FUNCTION NOT IN USE
    selectPlan() {
        return
        this.successModal2.hide();
        if (this.plan_id) {
            this.spinner.show();
            if (this.currentSubscription && this.currentSubscription._id) { // update subscription
                this.services.updateSubscription(this.user_id, this.currentSubscription._id, this.plan_id).pipe().subscribe(data => {
                    if (data.code == 400) {
                        this.spinner.hide();
                        this.toastr.error('', data.message);
                        this.logoutFunction.logout();
                        return;
                    }
                    if (data.code == 200) {
                        this.ngOnInit();
                        this.spinner.hide();
                        this.toastr.success('', 'Subscription Plan Changed Successfully');
                        return;
                    } else {
                        this.spinner.hide();
                        this.toastr.error('error', data.message);
                        return;
                    }
                });
            } else { // new subscription
                this.services.createSubscription(this.user_id, this.plan_id, this.stripe_customerId).pipe().subscribe(data => {
                    if (data.code == 400) {
                        this.spinner.hide();
                        this.toastr.error('', data.message);
                        this.logoutFunction.logout();
                        return;
                    }
                    if (data.code == 200) {
                        this.ngOnInit();
                        this.spinner.hide();
                        this.toastr.success('', 'Subscription Payment Successfully');
                        return;
                    } else {
                        this.spinner.hide();
                        this.toastr.error('error', data.message);
                        return;
                    }
                });
            }
        }
    }

    //THIS MODEL NOT IN USE
    cancelModal() {
        this.successModal1.show();
        return;
    }

    
    cancelSubscription() {
        this.successModal1.hide();
        this.spinner.show();
        this.services.cancelSubscription(this.user_id, this.currentSubscription._id).pipe().subscribe(data => {
            if (data.code == 200) {
                this.ngOnInit();
                this.toastr.success('', 'Subscription cancelled Successfully');
                return;
            } else {
                this.spinner.hide();
                this.toastr.error('error', data.message);
                return;
            }
        })
    }

    goToPaymentPage(){
        localStorage.setItem('selectedPlanId',JSON.stringify(this.plan_id));
        this.router.navigate(['subscription-payment']);
    }

}