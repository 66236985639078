<div class="mt-2">
  <div class="d-flex align-items-center">
    <div class="d-flex align-items-end">
      <button
        (click)="onChangeFrecuency('day')"
        [class]="'btn btn-sm mr-1'"
        [ngClass]="typeFrecuency === 'day' ? 'btn-dark' : 'btn-light'"
      >
        <!-- {{ 'REPORT.DAY'  }} -->
        {{ "JOBS.day" | translate }}
      </button>
      <button
        (click)="onChangeFrecuency('month')"
        [class]="'btn btn-sm mr-1'"
        [ngClass]="typeFrecuency === 'month' ? 'btn-dark' : 'btn-light'"
      >
        <!-- {{ 'REPORT.MONTH'  }} -->
        {{ "JOBS.month" | translate }}
      </button>
      <button
        (click)="onChangeFrecuency('year')"
        [class]="'btn btn-sm'"
        [ngClass]="typeFrecuency === 'year' ? 'btn-dark' : 'btn-light'"
      >
        <!-- {{ 'REPORT.YEAR'  }} -->
        {{ "JOBS.year" | translate }}
      </button>
    </div>
    <div class="mx-3">
      <input
        type="checkbox"
        [ngModel]="isActiveCustomFilter"
        class="custom-control-input"
        (change)="isActiveCustomFilter = !isActiveCustomFilter; getStatics()"
        id="custom-filter-switch"
      />
      <label class="btn btn-dark custom-date-btn" for="custom-filter-switch"
        ><img
          src="../../../../assets/img/new-ui/calendar_button.svg"
          alt="calendar-button"
        /><span class="custom-date-text">{{
          "JOBS.custom_date" | translate
        }}</span></label
      >
    </div>
    <!-- <div class="col-3"></div> -->
    <div *ngIf="isActiveCustomFilter && typeFrecuency === 'day'">
      <app-datepicker-range
        [config]="dateRangeOption"
        (onDateRangeSelection)="selectedDate($event)"
      ></app-datepicker-range>
    </div>

    <div
      class="d-flex flex-row align-items-center stats-calendar"
      *ngIf="isActiveCustomFilter && typeFrecuency === 'month'"
    >
      <div class="input-group">
        <input
          class="form-control"
          [(ngModel)]="startMonth"
          type="month"
          (change)="getStatics()"
        />
      </div>
      <span class="m-2"> - </span>
      <div class="input-group">
        <input
          class="form-control"
          [(ngModel)]="endMonth"
          type="month"
          (change)="getStatics()"
        />
      </div>
    </div>

    <div
      class="d-flex flex-row align-items-center stats-calendar"
      *ngIf="isActiveCustomFilter && typeFrecuency === 'year'"
    >
      <select
        class="form-control"
        [(ngModel)]="startYear"
        (change)="getStatics()"
      >
        <option *ngFor="let item of anioList" [value]="item">{{ item }}</option>
      </select>
      <span class="m-2"> - </span>
      <select
        class="form-control"
        [(ngModel)]="endYear"
        (change)="getStatics()"
      >
        <option *ngFor="let item of anioList" [value]="item">{{ item }}</option>
      </select>
    </div>
  </div>
  <!-- <div class="d-flex align-items-center"></div> -->

  <div
    style="width: 100%"
    class="d-flex justify-content-center items-align-center"
  >
    <div style="width: 100%">
      <canvas
        baseChart
        [chartType]="'bar'"
        [legend]="true"
        [datasets]="chartData"
        [labels]="chartLabels"
        [options]="chartOptions"
      >
      </canvas>
    </div>
  </div>
</div>
