<input
  [readonly]="readonlyInputs"
  type="text"
  [value]="commonMethods.formatTime(time)"
  [ngbPopover]="timePickerContent"
  class="form-control point time-picker position-relative"
  placement="bottom"
  [autoClose]="false"
/>

<ng-template #timePickerContent>
  <ngb-timepicker
    [(ngModel)]="time"
    [ngModelOptions]="{ standalone: true }"
    [readonlyInputs]="readonlyInputs"
    [minuteStep]="minuteStep"
    (ngModelChange)="change()"
  >
  </ngb-timepicker>
</ng-template>
