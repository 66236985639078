import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonMethods } from '../../../views/_helpers/_common-methods';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
  @Input() minuteStep: number = 10
  @Input() readonlyInputs: boolean = true
  @Input() time: {
    hour: 9,
    minute: 30
  };
  @Output() timeChange = new EventEmitter<{}>();
  @Output() onChange = new EventEmitter<any>();
  constructor(
    public commonMethods: CommonMethods // used in html file
  ) { }

  ngOnInit(): void {
  }

  change(): void {
    this.onChange ? this.onChange.emit(this.time)
      : this.timeChange.emit(this.time)
  }
}
