<ng-template #dynamicContent>
  <div *ngIf="!showDetails; else childrenDynamicContent">
    <app-button
      [imageSrc]="'./assets/img/new-ui/dowload_as_pdf.svg'"
      [buttonText]="'REPORT.print' | translate"
      (buttonClick)="getPdf()"
    ></app-button>
  </div>
</ng-template>
<ng-template #childrenDynamicContent>
  <ng-container
    *ngTemplateOutlet="globalSummaryDetailsComponent?.dynamicContentTemplate"
  >
  </ng-container>
</ng-template>
<div *ngIf="!showDetails; else details" class="mt-3">
  <app-custom-calendar-datepickers
    [(startDate)]="startDate"
    [(endDate)]="endDate"
    [(fromDate)]="fromDate"
    [(toDate)]="toDate"
    (getData)="getAllCompletedJobs()"
    [(searchEvent)]="searchEvent"
    [(pageNumber)]="pageNumber"
  ></app-custom-calendar-datepickers>
  <app-table
    [theadTemplate]="theadTemplate"
    [tbodyTemplate]="tbodyTemplate"
    [totalItems]="allDriversDetailsCount"
    (onPageChange)="onPageChange($event)"
    [currentPage]="pageNumber"
    [noFoundTemplate]="noFoundTemplate"
  ></app-table>
  <ng-template #theadTemplate>
    <tr>
      <th>{{ "JOBS.driver_name" | translate }}</th>
      <th>{{ "REPORT.phone_number" | translate }}</th>
      <th>{{ "REPORT.email" | translate }}</th>
      <th>{{ "REPORT.no_of_jobs_done" | translate }}</th>
      <th>{{ "REPORT.amount" | translate }}</th>
      <th>{{ "REPORT.collection" | translate }}</th>
    </tr>
  </ng-template>

  <ng-template #tbodyTemplate>
    <tbody>
      <tr
        *ngFor="
          let driverDetails of allDriversDetails
            | paginate
              : {
                  itemsPerPage: 10,
                  currentPage: pageNumber,
                  totalItems: allDriversDetailsCount
                }
        "
        (click)="goToCourierDetailPage(driverDetails?._id?._id)"
      >
        <td *ngIf="driverDetails?._id?.first_name; else driverElse">
          <strong>{{ driverDetails?._id?.first_name }}</strong>
          {{ driverDetails?._id?.last_name }}
        </td>
        <ng-template #driverElse>
          <td>{{ driverDetails?._id?.fullname }}</td>
        </ng-template>

        <td>
          {{ driverDetails?._id?.country_code
          }}{{ driverDetails?._id?.mobile_no }}
        </td>
        <td>{{ driverDetails?._id?.email }}</td>
        <td>{{ driverDetails?.count }}</td>
        <td>
          <strong>$ {{ driverDetails?.job_amount | number : "1.2-2" }}</strong>
        </td>
        <td>
          <strong
            >$ {{ driverDetails?.collected_amount | number : "1.2-2" }}</strong
          >
        </td>
      </tr>
    </tbody>
  </ng-template>
  <ng-template #noFoundTemplate>
    <div class="no-jobs-found">
      <p
        class="no-item text-center text-danger mb-0"
        *ngIf="!allDriversDetails.length"
      >
        {{ "JOBS.no_jobs_found" | translate }}
      </p>
    </div>
  </ng-template>
</div>
<ng-template #details>
  <app-courier-summary-details
    #globalSummaryDetails
    [driver_id]="driver_id"
    [(show)]="showDetails"
  ></app-courier-summary-details>
</ng-template>
