<color-legend
  class="custom"
  width="100%"
  [titleText]="titleText"
  [scaleType]="scaleType"
  [markType]="markType"
  [domain]="domain"
  [range]="range"
  [hidden]="hidden"
>
</color-legend>
