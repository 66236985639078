<div class="card mt-3 calendar">
  <div class="card-body">
    <div class="row">
      <div class="col-lg-4">
        <div class="scp-breadcrumb">
          <ul class="breadcrumb">
            <li class="b-jobs">
              <a>{{ "HOME.calendar_menu" | translate }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <full-calendar #calendar [options]="calendarOptions">
          <ng-template #eventContent let-arg>
            <b>{{ arg.timeText }}</b>
            <i style="font-size: 15px">{{ arg.event.title }}</i>
          </ng-template>
        </full-calendar>
      </div>
    </div>
  </div>
</div>
<ng-template #jobDetailsHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3">
    <ng-container
      *ngTemplateOutlet="jobDetailsComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #jobDetailsBodyTemplate>
  <app-job-details [job_id]="job_id"></app-job-details>
</ng-template>
