import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { ServicesService } from '../services.service';

@Injectable({
  providedIn: 'root'
})
export class ShopModuleGuard implements CanActivate {
  user_id = "";
  shopModule = false;

  constructor(
    private auth: AuthService,
    private myRoute: Router,
    private services: ServicesService,
  ) {
    var localData2 = JSON.parse(localStorage.getItem("currentUser"));
    this.user_id = localData2._id;
    this.services
      .getPharmacyProfile(this.user_id)
      .pipe()
      .subscribe((data) => {
        this.shopModule = data.result.shopEnabled;
      });
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.auth.isLoggedIn()) {
      console.log('islogeedin', this.auth.isLoggedIn())
      if (this.shopModule) {
        return true;
      }
      this.myRoute.navigate(["dashboard"]);
      return false;
    } else {
      console.log('islogeedinNOT')
      this.myRoute.navigate(["login"]);
      return false;
    }
  }
}
