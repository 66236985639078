import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { JobsListComponent } from '../jobs-list/jobs-list.component';
const FileSaver = require('file-saver');

@Component({
    selector: 'app-active-jobs',
    templateUrl: './active-jobs.component.html',
    styleUrls: ['./active-jobs.component.scss']
})
export class ActiveJobsComponent implements OnInit, AfterViewInit {
    @ViewChild(JobsListComponent) jobsListComponent: JobsListComponent;
    dynamicContentTemplate: TemplateRef<any>;

    constructor(

    ) { }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.dynamicContentTemplate = this.jobsListComponent.dynamicContentTemplate;
    }

}
