import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  Router
} from '@angular/router';
import 'moment-timezone';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';

@Component({
  selector: 'app-brands-create',
  templateUrl: './brands-create.component.html',
  styleUrls: ['./brands-create.component.scss']
})
export class BrandsCreateComponent implements OnInit {
  createBrandForm: UntypedFormGroup;
  public language = "en";
  file!: File;
  submitted = false;

  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {

    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en"
    }

    //ADD NEW RESIDENCE FORM 
    this.createBrandForm = this.formBuilder.group({
      brand_name: ['', Validators.required],
      nameFr: ['', Validators.required],
      isFeatured: [1]
    });

  }

  onFileSelect(event) {
    console.log("event", event);
    this.file = event.target.files[0];

    //  this.createBrandForm.patchValue({
    //     logo: brand_file,
    //  });
    //this.createBrandForm.get('file').setValue(brand_file);
    //   console.log(this.createBrandForm.get('logo'));
    //  this.createBrandForm.get('logo').updateValueAndValidity();

  }


  get f() { return this.createBrandForm.controls; }


  addNewBrand() {

    const values = this.createBrandForm.value;

    this.submitted = true;

    if (!this.f.brand_name.value.trim()) {
      return this.toastr.error('', 'Please enter first name');
    }
    else if (!this.f.nameFr.value.trim()) {
      return this.toastr.error('', 'Please enter last name');
    }


    if (this.file == null) {
      return this.toastr.error('', 'Please Select Image');

    }



    if (this.f.brand_name.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.brand_name.value);
      if (testing == true) {
        return this.toastr.error('', 'name not valid!');
      }
    }
    if (this.f.nameFr.value) {
      var format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
      var testing = format.test(this.f.nameFr.value);
      if (testing == true) {
        return this.toastr.error('', 'name Fr not valid!');
      }
    }






    this.spinner.show();

    this.services.createBrand(values, this.file).pipe().subscribe(data => {
      this.submitted = false;
      this.spinner.hide();
      return this.toastr.success('successfully created brand');
      this.ngOnInit();

    }, error => {
      this.spinner.hide()
      return this.toastr.error('error', error.error.message);
    });

  }

  back() {
    this.router.navigate(['/brands']);
  }

}
