<link
  href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
  rel="stylesheet"
/>
<div class="card mt-3">
  <div class="card-body">
    <div class="row">
      <div class="col-lg-4">
        <div class="scp-breadcrumb">
          <ul class="breadcrumb">
            <li class="back-btn-arrow" style="cursor: pointer">
              <a (click)="back()" class="back-arrow"
                ><i
                  class="fa fa-arrow-left mr-2"
                  aria-hidden="true"
                  style="color: #3eaa79; margin-top: 0px"
                ></i
                >Back</a
              >
            </li>
            <li class="b-jobs">
              <a>{{ "ORDER.detail_title" | translate }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md-8">
        <ul class="invoice-list">
          <li>
            <button
              class="btn btn-danger"
              *ngIf="statusOrder === 'pending'"
              (click)="rejectOrder()"
            >
              {{ "ORDER.reject" | translate }}
            </button>
            <button
              class="btn btn-success mr-1"
              *ngIf="statusOrder === 'pending'"
              (click)="acceptOrder()"
            >
              {{ "ORDER.accept" | translate }}
            </button>

            <button
              class="add-invoice-btn createjobBtn"
              *ngIf="statusOrder == 'accepted'"
              id="openRighSideBar"
              (click)="createSubOrderjob()"
            >
              {{ "ORDER.job" | translate }}
            </button>

            <button class="btn btn-danger" *ngIf="statusOrder == 'rejected'">
              {{ "ORDER.discontinued" | translate }}
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="row driver-detail">
        <div class="col-lg-4">
          <div class="media">
            <div class="media-body align-self-center ml-2">
              <p>{{ "ORDER.num-order" | translate }}</p>

              <h5>{{ orderNum }}</h5>
            </div>
          </div>
        </div>
        <div class="col-lg-8 align-self-center">
          <ul class="btns-gp">
            <li>
              <div class="show-due-date job-details-dates">
                <div class="media" style="background-color: #838d97">
                  <i class="fa fa-phone mr-2" aria-hidden="true"></i>
                  <div class="media-body">
                    <p>{{ "JOBDETAILS.phone_number" | translate }}</p>

                    <h5>{{ phone_no }}</h5>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="show-due-date job-details-dates">
                <div class="media" style="background-color: #838d97">
                  <i class="fa fa-envelope mr-2" aria-hidden="true"></i>
                  <div class="media-body">
                    <p>{{ "JOBDETAILS.email" | translate }}</p>

                    <h5>{{ email }}</h5>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-lg-4">
        <div class="card fixed-height">
          <div class="card-body">
            <p class="create-date mb-0">{{ "ORDER.order_info" | translate }}</p>
            <table class="job-info-table">
              <tr>
                <td>
                  <span class="create-on">{{
                    "JOBDETAILS.created_date" | translate
                  }}</span>
                </td>
                <td>
                  <span class="create-date">
                    {{ modifyDateTime(createAt) }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <span class="create-on">{{
                    "ORDER.name_order" | translate
                  }}</span>
                </td>
                <td>
                  <!-- <ng-template #initialsElse> -->
                  <span class="create-date">{{ customer_name }}</span>
                  <!-- </ng-template> -->
                </td>
              </tr>
              <tr>
                <td>
                  <span class="create-on">{{
                    "ORDER.status" | translate
                  }}</span>
                </td>
                <td>
                  <span class="create-date">{{ statusOrder }}</span>
                </td>
              </tr>

              <tr>
                <td>
                  <span class="create-on">{{
                    "ORDER.total_order" | translate
                  }}</span>
                </td>
                <td>
                  <span class="create-date">{{ totalOrder }}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card fixed-height">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-5">
                <p class="create-date mb-0">
                  {{ "ORDER.order_info" | translate }}
                </p>
                <div class="media" style="margin-top: 10px">
                  <i
                    class="fa fa-map-marker mr-2 location-icon"
                    aria-hidden="true"
                  ></i>
                  <div class="media-body">
                    <h4
                      class="create-on"
                      style="margin-bottom: 3px; position: relative"
                    >
                      {{ "JOBDETAILS.location_from" | translate }}
                    </h4>
                    <p class="create-date">{{ location }}</p>

                    <h4
                      class="create-on"
                      style="margin-bottom: 3px; position: relative"
                    >
                      {{ "ORDER.origin" | translate }}
                    </h4>
                    <p class="create-date">{{ origin }}</p>

                    <h4
                      class="create-on"
                      style="margin-bottom: 3px; position: relative"
                    >
                      {{ "ORDER.appartment_n" | translate }}
                    </h4>
                    <p class="create-date">{{ appartment_n }}</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="v-line"></div>
              </div>
              <div class="col-lg-5">
                <div class="media" style="margin-top: 25px">
                  <div class="media-body">
                    <h4
                      class="create-on"
                      style="margin-bottom: 3px; position: relative"
                    >
                      {{ "ORDER.customer_note" | translate }}
                    </h4>
                    <p class="create-date">
                      {{ customer_note }}
                    </p>

                    <h4
                      class="create-on"
                      style="margin-bottom: 3px; position: relative"
                    >
                      {{ "ORDER.delivery_note" | translate }}
                    </h4>
                    <p class="create-date">{{ delivery_note }}</p>

                    <h4
                      class="create-on"
                      style="margin-bottom: 3px; position: relative"
                    >
                      {{ "ORDER.door_code" | translate }}
                    </h4>
                    <p class="create-date">{{ door_code }}</p>

                    <h4
                      class="create-on"
                      style="margin-bottom: 3px; position: relative"
                    >
                      {{ "ORDER.pickup_note" | translate }}
                    </h4>
                    <p class="create-date">{{ pickup_note }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table table-borderless jobs-table">
          <thead>
            <tr style="align-items: center">
              <th>{{ "ORDER.name" | translate }}</th>
              <th style="text-align: center">
                {{ "ORDER.detail" | translate }}
              </th>
              <th style="text-align: center">
                {{ "ORDER.batch_number" | translate }}
              </th>

              <th style="text-align: center">
                {{ "ORDER.quantity" | translate }}
              </th>
              <th style="text-align: center">
                {{ "ORDER.price" | translate }}
              </th>
              <th style="text-align: center">
                {{ "ORDER.order_status" | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let order of allOrderDetail.products">
              <td style="display: flex">
                <img
                  [src]="order?.product?.image_url"
                  style="width: 60px; height: 60px"
                />

                <p
                  style="
                    display: block;
                    width: 250px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin: auto 10px;
                  "
                  [title]="order.product.product_name"
                >
                  {{ order?.product?.product_name }}
                </p>
              </td>

              <td>
                <p
                  style="
                    text-align: center;
                    display: block;
                    width: 250px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin: auto;
                  "
                  [title]="order.product.description"
                >
                  {{ order.product.description }}
                </p>
              </td>

              <td style="text-align: center">
                {{ order?.product_inventory?.batch_number }}
              </td>

              <td style="text-align: center">
                {{ order?.quantity }}
              </td>

              <td style="text-align: center">
                {{ order?.price }}
              </td>

              <td style="text-align: center">
                {{ statusOrder }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- PR -->
