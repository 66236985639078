<ng-template #dynamicContent>
  <div class="d-flex flex-grow-1">
    <div class="row flex-grow-1 ml-1" style="gap: 5px">
      <div class="show-due-date pr-0 bg-grey rounded">
        <div class="media pr-3 f-right margin-right">
          <img class="mr-3" img src="./assets/img/new-ui/job_code.svg" alt="" />
          <div class="media-body">
            <p>
              {{ job_details?.booking_code }}
            </p>
            <h4>
              {{ "JOBS.job_id" | translate }}
            </h4>
          </div>
        </div>
      </div>
      <div class="show-due-date pr-0 bg-grey rounded">
        <div class="media pr-3 f-right margin-right">
          <img class="mr-3" img src="./assets/img/new-ui/job_type.svg" alt="" />
          <div class="media-body">
            <p
              *ngIf="job_details?.job_type == '1'"
              class="create-date"
              style="
                color: red;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 17px;
              "
            >
              {{ "JOBDETAILS.delivery" | translate }}
            </p>

            <p
              *ngIf="job_details?.job_type == '2'"
              class="create-date"
              style="
                color: red;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 16px;
              "
            >
              {{ "JOBDETAILS.pickup" | translate }}
            </p>
            <h4>
              {{ "JOBDETAILS.job_type" | translate }}
            </h4>
          </div>
        </div>
      </div>

      <div class="show-due-date pr-0 bg-grey rounded">
        <div class="media pr-3 f-right margin-right">
          <img class="mr-3" img src="./assets/img/new-ui/due_date.svg" alt="" />
          <div class="media-body">
            <p *ngIf="job_details?.delivery_type === '3'">
              {{
                job_details?.shipment_day
                  ? formatDate(job_details?.shipment_day)
                  : formatDate(job_details?.job_date)
              }}
            </p>
            <p *ngIf="['1', '2'].includes(job_details?.delivery_type)">
              {{ formatDate(job_details?.job_date) }}
            </p>
            <h4>
              {{ "JOBDETAILS.due_date" | translate }}
            </h4>
          </div>
        </div>
      </div>

      <div class="show-due-date pr-0 bg-grey rounded">
        <div class="media pr-3 f-right margin-right">
          <img class="mr-3" img src="./assets/img/new-ui/status.svg" alt="" />
          <div class="media-body">
            <p>
              <button
                *ngIf="
                  job_details?.job_status == 'P' &&
                  job_details?.isAccepted == false &&
                  job_details?.isExpired == false
                "
                class="stats-active"
                style="background: #ffe4c8; color: #ff8b17"
              >
                {{ "JOBS.pending" | translate }}
              </button>

              <button
                *ngIf="
                  job_details?.job_status == 'P' &&
                  job_details?.isAccepted == true &&
                  job_details?.isExpired == false
                "
                class="stats-active"
              >
                {{ "JOBDETAILS.accepted" | translate }}
              </button>

              <button
                *ngIf="
                  job_details?.job_status == 'A' &&
                  job_details?.isAccepted == true &&
                  job_details?.isExpired == false
                "
                class="stats-active"
              >
                {{ "JOBDETAILS.active" | translate }}
              </button>

              <button
                *ngIf="job_details?.job_status == 'C'"
                class="stats-complete"
              >
                {{ "JOBDETAILS.completed" | translate }}
              </button>

              <button
                *ngIf="
                  job_details?.isExpired == true &&
                  job_details?.job_status != 'C' &&
                  job_details?.job_status != 'N' &&
                  job_details?.job_status != 'CN'
                "
                class="stats-active text-danger"
                style="background: #efe2e2"
              >
                {{ "JOBDETAILS.expired" | translate }}
              </button>

              <button
                *ngIf="job_details?.job_status == 'CN'"
                class="stats-active text-danger"
                style="background: #efe2e2"
              >
                {{ "JOBDETAILS.cancelled" | translate }}
              </button>

              <button
                *ngIf="job_details?.job_status == 'N'"
                class="stats-notcomplete"
              >
                N/D
              </button>
            </p>

            <div *ngIf="job_details?.isRecursiveJob">
              <button class="stats-recursive text-danger">
                <i class="fa fa-circle mr-1" aria-hidden="true"></i
                >{{ "JOBDETAILS.recursive1" | translate }}
              </button>
            </div>
            <h4>
              {{ "JOBS.status" | translate }}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <app-button
      *ngIf="!job_details?.transferToAnotherDispatch?.isTransferred"
      [imageSrc]="'./assets/img/new-ui/dowload_as_pdf.svg'"
      [buttonText]="'INVOICES.downloadPdf' | translate"
      (buttonClick)="getPdf()"
    ></app-button>
    <app-button
      *ngIf="job_details?.oldDispatchJobId"
      [buttonText]="'JOBDETAILS.transferred_from' | translate"
      (buttonClick)="
        openTransferredFromModal(
          job_details?.oldDispatchJobId,
          jobDetailsBodyTemplate,
          jobDetailsHeaderTemplate
        )
      "
    ></app-button>
  </div>
</ng-template>
<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-xl-9">
        <div class="row">
          <div class="col-lg-4">
            <div class="card fixed-height">
              <div class="card-body">
                <p class="create-date mb-0">
                  {{ "JOBDETAILS.job_info" | translate }}
                </p>
                <table class="job-info-table">
                  <tr>
                    <td>
                      <span class="create-on">{{
                        "JOBDETAILS.created_date" | translate
                      }}</span>
                    </td>
                    <td>
                      <span class="create-date">{{
                        formatDateTime(job_details?.jobCreatedAt)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="create-on">{{
                        "JOBDETAILS.initials" | translate
                      }}</span>
                    </td>
                    <td>
                      <span
                        *ngIf="
                          job_details?.job_create_initials;
                          else initialsElse
                        "
                        class="create-date"
                        >{{ job_details?.job_create_initials }}</span
                      >

                      <ng-template #initialsElse>
                        <span class="create-date">N/A</span>
                      </ng-template>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="create-on">{{
                        "JOBDETAILS.job_type" | translate
                      }}</span>
                    </td>
                    <td *ngIf="job_details?.job_type == '1'">
                      <span
                        class="create-date"
                        style="
                          color: red;
                          text-transform: uppercase;
                          font-weight: 700;
                        "
                        >{{ "JOBDETAILS.delivery" | translate }}</span
                      >
                    </td>
                    <td *ngIf="job_details?.job_type == '2'">
                      <span
                        class="create-date"
                        style="
                          color: red;
                          text-transform: uppercase;
                          font-weight: 700;
                        "
                        >{{ "JOBDETAILS.pickup" | translate }}</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="create-on">{{
                        "JOBDETAILS.delivery_type" | translate
                      }}</span>
                    </td>
                    <td *ngIf="job_details?.delivery_type == '1'">
                      <span
                        class="create-date"
                        style="text-transform: uppercase; font-weight: 700"
                        >{{ "JOBDETAILS.urgent" | translate }}</span
                      >
                    </td>
                    <td *ngIf="job_details?.delivery_type == '2'">
                      <span
                        class="create-date"
                        style="text-transform: uppercase; font-weight: 700"
                        >{{ "JOBDETAILS.normal" | translate }}</span
                      >
                    </td>
                    <td *ngIf="job_details?.delivery_type == '3'">
                      <span
                        class="create-date"
                        style="text-transform: uppercase; font-weight: 700"
                        >{{ "JOBDETAILS.next_day" | translate }}</span
                      >
                    </td>
                  </tr>
                  <tr *ngIf="job_details?.delivery_type == '3'">
                    <td>
                      <span class="create-on">{{
                        "JOBDETAILS.pickup_date" | translate
                      }}</span>
                    </td>
                    <td>
                      <span
                        class="create-date"
                        style="text-transform: uppercase; font-weight: 700"
                        >{{
                          job_details?.pickup_date
                            ? formatDate(job_details?.pickup_date)
                            : formatDate(job_details?.job_date)
                        }}</span
                      >
                    </td>
                  </tr>
                  <tr *ngIf="job_details?.delivery_type == '3'">
                    <td>
                      <span class="create-on">{{
                        "JOBDETAILS.shipment_day" | translate
                      }}</span>
                    </td>
                    <td>
                      <span
                        class="create-date"
                        style="text-transform: uppercase; font-weight: 700"
                        >{{
                          job_details?.shipment_day
                            ? formatDate(job_details?.shipment_day)
                            : formatDate(job_details?.job_date)
                        }}</span
                      >
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span class="create-on">{{
                        "JOBDETAILS.note" | translate
                      }}</span>
                    </td>
                    <td *ngIf="job_details?.note; else noteElse">
                      <a
                        (click)="openNoteModal(job_details?.note)"
                        class="reason-link"
                        >{{ "JOBDETAILS.view" | translate }}</a
                      >

                      <i
                        *ngIf="job_details?.job_note_seen_driver"
                        class="fa fa-check"
                        aria-hidden="true"
                        style="color: green"
                      ></i>
                    </td>
                    <ng-template #noteElse>
                      <td class="create-date">N/A</td>
                    </ng-template>
                  </tr>

                  <tr
                    *ngIf="
                      job_details?.attachedDocument &&
                      job_details?.attachedDocument.url
                    "
                  >
                    <td>
                      <span class="create-on">{{
                        "CREATEJOB.attach_doc" | translate
                      }}</span>
                    </td>
                    <td>
                      <!-- <a class="reason-link" (click)="imageSelection(job_details?.attachedDocument.url)">{{ 'JOBDETAILS.view' | translate }}</a> -->

                      <a
                        class="reason-link"
                        href="{{ job_details?.attachedDocument.url }}"
                        target="_blank"
                        >{{ "JOBDETAILS.view" | translate }}</a
                      >
                    </td>
                  </tr>

                  <tr
                    *ngIf="
                      job_details?.jobTimingObj &&
                      job_details?.jobTimingObj.type &&
                      (job_details?.jobTimingObj.type == '1' ||
                        job_details?.jobTimingObj.type == '2' ||
                        job_details?.jobTimingObj.type == '3')
                    "
                  >
                    <td
                      *ngIf="job_details?.jobTimingObj.type == '1'"
                      colspan="2"
                    >
                      <div class="showtime">
                        <h3>{{ "CREATEJOB.before" | translate }}</h3>
                        <span
                          >{{ job_details?.jobTimingObj.startTime.hour }}:{{
                            job_details?.jobTimingObj.startTime.minute
                          }}</span
                        >
                      </div>
                    </td>

                    <td
                      *ngIf="job_details?.jobTimingObj.type == '2'"
                      colspan="2"
                    >
                      <div class="showtime">
                        <h3>{{ "CREATEJOB.after" | translate }}</h3>
                        <span
                          >{{ job_details?.jobTimingObj.startTime.hour }}:{{
                            job_details?.jobTimingObj.startTime.minute
                          }}</span
                        >
                      </div>
                    </td>

                    <td
                      *ngIf="job_details?.jobTimingObj.type == '3'"
                      colspan="2"
                    >
                      <div class="showtime">
                        <h3>{{ "CREATEJOB.between" | translate }}</h3>
                        <span
                          >{{ job_details?.jobTimingObj.startTime.hour }}:{{
                            job_details?.jobTimingObj.startTime.minute
                          }}
                          {{ "CREATEJOB.to" | translate }}
                          {{ job_details?.jobTimingObj.endTime.hour }}:{{
                            job_details?.jobTimingObj.endTime.minute
                          }}</span
                        >
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span class="create-on">{{
                        "JOBDETAILS.job_tags" | translate
                      }}</span>
                    </td>
                    <td></td>
                  </tr>
                </table>

                <div
                  *ngIf="
                    job_details?.job_tags != null &&
                      job_details?.job_tags.length;
                    else jobTagElse
                  "
                  class="job-tag-list"
                >
                  <span
                    *ngFor="let tag of job_details?.job_tags"
                    class="custom-checkbox"
                  >
                    <button
                      *ngIf="language == 'fr'"
                      [style.backgroundColor]="tag.tag_code"
                      class="btn btn-default urgent-btn"
                    >
                      {{ tag.tag_for_french }}
                    </button>
                    <button
                      *ngIf="language == 'en'"
                      [style.backgroundColor]="tag.tag_code"
                      class="btn btn-default urgent-btn"
                    >
                      {{ tag.tag_for }}
                    </button>
                  </span>
                </div>
                <ng-template #jobTagElse>
                  <span class="create-date">N/A</span>
                </ng-template>

                <a
                  *ngIf="job_details?.eyeWitnessNote"
                  (click)="openNoteModal(job_details?.eyeWitnessNote)"
                  class="reason-link"
                  >{{ "JOBDETAILS.note" | translate }}</a
                >

                <div *ngIf="job_details?.old_job_reference?.jobId" class="mt-3">
                  <p>
                    * {{ "JOBS.IS_A_RECREATED_JOB" | translate }}
                    <a
                      style="color: #20a8d8; cursor: pointer"
                      (click)="
                        refreshJobDetails(job_details?.old_job_reference.jobId)
                      "
                      >{{ job_details?.old_job_reference.jobId.booking_code }}
                    </a>
                    <i
                      class="fa fa-info-circle"
                      [ngbTooltip]="tooltipContent"
                      aria-hidden="true"
                    ></i>
                  </p>

                  <ng-template #tooltipContent>
                    <div>
                      {{ "JOBS.REASON" | translate }} :
                      {{ job_details?.old_job_reference.reason }}
                    </div>
                  </ng-template>
                </div>
                <div *ngIf="job_details?.recreatedJobId">
                  <div class="mt-3">
                    <p>
                      * {{ "JOBS.NEW_JOB_REISSUED" | translate }} :
                      <a
                        style="color: #20a8d8; cursor: pointer"
                        (click)="refreshJobDetails(job_details?.recreatedJobId)"
                        >{{ job_details?.recreatedJobId?.booking_code }}
                      </a>
                    </p>

                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="card fixed-height">
              <div class="card-body">
                <div class="row card r-width mt-3">
                  <div class="card-body" style="padding: 0">
                    <div class="hh-grayBox">
                      <button
                        *ngIf="jobHistory?.length > 0"
                        class="btn btn-outline-success mb-4 ml-4"
                        (click)="openStepperHistoryModal()"
                      >
                        {{ "JOBDETAILS.view_history" | translate }}
                      </button>
                      <app-stepper-status
                        [job_details]="job_details"
                      ></app-stepper-status>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-5 bg-grey">
                    <p class="create-date mb-0">
                      {{ "JOBDETAILS.trip_info" | translate }}
                    </p>
                    <div class="media" style="margin-top: 10px">
                      <i
                        class="fa fa-map-marker mr-2 location-icon"
                        aria-hidden="true"
                      ></i>
                      <div class="media-body">
                        <h4
                          class="create-on"
                          style="margin-bottom: 3px; position: relative"
                        >
                          {{ "JOBDETAILS.location_from" | translate }}
                        </h4>
                        <p class="create-date">
                          {{ job_details?.pick_up_location }}
                        </p>
                        <div style="position: relative">
                          <h4
                            class="create-on"
                            style="margin-bottom: 3px; margin-top: 33px"
                          >
                            {{ "JOBDETAILS.prescription_images" | translate }}
                          </h4>
                        </div>

                        <div
                          *ngIf="job_details?.job_prescription.length == 0"
                          class="create-date"
                        >
                          <br />N/A
                        </div>

                        <div
                          [ngClass]="
                            job_details?.job_prescription.length
                              ? 'pres-imgs'
                              : ''
                          "
                        >
                          <div
                            *ngFor="let image of job_details?.job_prescription"
                            class="pickup-image my-pres-img"
                            style="position: relative"
                          >
                            <img
                              style="cursor: pointer"
                              [src]="image"
                              (click)="imageSelection(image)"
                            />
                          </div>
                        </div>

                        <div
                          *ngIf="
                            job_details?.imageForNotDelivered &&
                            job_details?.imageForNotDelivered.length
                          "
                          style="position: relative"
                        >
                          <h4
                            class="create-on"
                            style="margin-bottom: 3px; margin-top: 33px"
                          >
                            {{ "JOBDETAILS.not_delivered_images" | translate }}
                          </h4>
                        </div>

                        <div
                          *ngIf="
                            job_details?.imageForNotDelivered &&
                            job_details?.imageForNotDelivered.length
                          "
                        >
                          <div
                            *ngFor="
                              let image of job_details?.imageForNotDelivered
                            "
                            class="pickup-image my-pres-img"
                            style="position: relative"
                          >
                            <img
                              style="cursor: pointer"
                              [src]="image"
                              (click)="imageSelection(image)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="v-line"></div>
                  </div>
                  <div class="col-lg-5 bg-grey">
                    <div class="media" style="margin-top: 25px">
                      <i
                        class="fa fa-map-marker mr-2 location-icon"
                        aria-hidden="true"
                      ></i>
                      <div class="media-body">
                        <h4 class="create-on" style="margin-bottom: 3px">
                          {{ "JOBDETAILS.location_to" | translate }}
                        </h4>
                        <p class="create-date">
                          {{ job_details?.drop_off_location }}
                        </p>
                        <h4
                          class="create-on"
                          style="margin-bottom: 3px; margin-top: 33px"
                        >
                          {{ "JOBDETAILS.dropoff_time_pic" | translate }}
                        </h4>
                        <div
                          *ngIf="job_details?.dropoff_images.length == 0"
                          class="create-date"
                        >
                          <br />N/A
                        </div>
                        <div
                          *ngFor="let image of job_details?.dropoff_images"
                          class="pickup-image"
                        >
                          <img
                            style="cursor: pointer"
                            [src]="image"
                            (click)="imageSelection(image)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="job-detail-map">
              <!-- <img class="mr-3" img src="assets/img/detail-map.png" class="img-responsive"> -->
              <app-google-map
                [markers]="markersInfo"
                [center]="center"
                [zoom]="12"
                [height]="300"
              ></app-google-map>
              <ng-template #driverInfoWindowContent>
                <strong
                  >{{ job_details?.driver_id?.username }},
                  {{ job_details?.driver_id?.country_code }}
                  {{ job_details?.driver_id?.mobile_no }}</strong
                >
              </ng-template>
              <ng-template #pickupInfoWindowContent>
                <strong>{{ job_details?.pick_up_location }}</strong>
              </ng-template>
              <ng-template #dropInfoWindowContent>
                <strong>{{ job_details?.drop_off_location }}</strong>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 customers-box">
        <div class="card">
          <div class="card-body customer-informatio-box">
            <!-- <p _ngcontent-ldb-c2="" class="create-date">{{ 'JOBDETAILS.establishment_info' | translate }}</p>
						<h4 class="customer-name">
							{{job_details?.establishment_name}}
						</h4> -->
            <p _ngcontent-ldb-c2="" class="create-date">
              {{ "JOBDETAILS.delivery_guy" | translate }}
            </p>
            <div *ngIf="job_details?.customerTypeSelection == '1'">
              <div class="media align-items-center gap-1">
                <div class="driver-detail-img">
                  <img
                    *ngIf="
                      job_details?.driver_id && job_details?.isAccepted == true
                    "
                    [src]="job_details?.driver_id.profile_img"
                  />
                </div>
                <div class="customer-name media-body">
                  {{ job_details?.driver_id?.username || "N/A" }}
                </div>
              </div>

              <div class="media">
                <i
                  class="fa fa-envelope mr-3"
                  aria-hidden="true"
                  style="font-size: 15px"
                ></i>
                <div class="media-body">
                  <h4>
                    {{ job_details?.driver_id?.email }}
                  </h4>
                </div>
              </div>
              <div class="media">
                <i
                  class="fa fa-phone mr-3"
                  aria-hidden="true"
                  style="font-size: 18px"
                ></i>
                <div class="media-body">
                  <p
                    class="create-date mb-0"
                    style="margin-top: 3px"
                    *ngIf="job_details?.customer_phone"
                  >
                    {{ job_details?.driver_id?.country_code }}-{{
                      job_details?.driver_id?.mobile_no
                    }}
                  </p>
                  <p
                    class="create-date mb-0"
                    style="margin-top: 3px"
                    *ngIf="!job_details?.customer_phone"
                  >
                    N/A
                  </p>
                </div>
              </div>
            </div>

            <div *ngIf="job_details?.customerTypeSelection == '2'">
              <p class="create-date" style="color: #060606; font-size: 14px">
                {{ "JOBDETAILS.num_of_customers" | translate }}
              </p>
              <div class="num-of-customer">
                {{ job_details?.multiple_customers_info.length }}
              </div>
              <div class="text-center">
                <button
                  class="btn btn-default"
                  (click)="
                    openMultipleCustomersModal(
                      job_details?.multiple_customers_info
                    )
                  "
                  style="background: #3eaa79; color: #fff; font-size: 13px"
                >
                  {{ "JOBDETAILS.customer_details" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="card" *ngIf="job_details?.customer_type != '2'">
          <div class="card-body">
            <p class="create-date">
              {{ "JOBDETAILS.establishment_info" | translate }}
            </p>
            <h4 class="customer-name">
              {{
                job_details?.establishment_name
                  ? job_details?.establishment_name
                  : "N/A"
              }}
            </h4>
            <div class="media">
              <i
                class="fa fa-map-marker mr-3"
                aria-hidden="true"
                style="font-size: 18px"
              ></i>
              <div class="media-body">
                <p
                  *ngIf="job_details?.job_type == '1'"
                  class="create-date mb-0"
                  style="margin-top: 3px"
                >
                  {{ job_details?.drop_off_location }}
                </p>
                <p
                  *ngIf="job_details?.job_type == '2'"
                  class="create-date mb-0"
                  style="margin-top: 3px"
                >
                  {{ job_details?.pick_up_location }}
                </p>
              </div>
            </div>

            <div class="media">
              <i
                class="fa fa-envelope mr-3"
                aria-hidden="true"
                style="font-size: 15px"
              ></i>
              <div class="media-body">
                <p
                  class="create-date mb-0"
                  style="margin-top: 3px"
                  *ngIf="job_details?.establishment_email; else estbEmailElse"
                >
                  {{ job_details?.establishment_email }}
                </p>
                <ng-template #estbEmailElse>
                  <p class="create-date mb-0" style="margin-top: 3px">N/A</p>
                </ng-template>
              </div>
            </div>
            <div class="media">
              <i
                class="fa fa-phone mr-3"
                aria-hidden="true"
                style="font-size: 18px"
              ></i>
              <div class="media-body">
                <p
                  class="create-date mb-0"
                  style="margin-top: 3px"
                  *ngIf="job_details?.establishment_phone; else estbPhoneElse"
                >
                  {{ job_details?.establishment_country_code }}
                  {{ job_details?.establishment_phone }}
                </p>
                <ng-template #estbPhoneElse>
                  <p class="create-date mb-0" style="margin-top: 3px">N/A</p>
                </ng-template>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body customer-informatio-box">
            <!-- <p _ngcontent-ldb-c2="" class="create-date">{{ 'JOBDETAILS.establishment_info' | translate }}</p>
						<h4 class="customer-name">
							{{job_details?.establishment_name}}
						</h4> -->
            <p _ngcontent-ldb-c2="" class="create-date">
              {{ "JOBDETAILS.customer_info" | translate }}
            </p>
            <div *ngIf="job_details?.customerTypeSelection == '1'">
              <h4 class="customer-name">
                {{
                  job_details?.customer_f_name
                    ? job_details?.customer_f_name +
                      "
								" +
                      job_details?.customer_l_name
                    : job_details?.customer_name
                }}

                <!-- <ng-template #nameElse>
								{{job_details?.establishment_name ? job_details?.establishment_name : job_details?.customer_name}}
							</ng-template> -->
              </h4>
              <div class="media">
                <i
                  class="fa fa-envelope mr-3"
                  aria-hidden="true"
                  style="font-size: 15px"
                ></i>
                <div class="media-body">
                  <p
                    class="create-date mb-0"
                    style="margin-top: 3px"
                    *ngIf="job_details?.customer_email; else emailElse"
                  >
                    {{ job_details?.customer_email }}
                  </p>
                  <ng-template #emailElse>
                    <p class="create-date mb-0" style="margin-top: 3px">N/A</p>
                  </ng-template>
                </div>
              </div>
              <div class="media">
                <i
                  class="fa fa-phone mr-3"
                  aria-hidden="true"
                  style="font-size: 18px"
                ></i>
                <div class="media-body">
                  <p
                    class="create-date mb-0"
                    style="margin-top: 3px"
                    *ngIf="job_details?.customer_phone; else phoneElse"
                  >
                    {{ job_details?.customer_country_code }}
                    {{ job_details?.customer_phone }}
                  </p>
                  <ng-template #phoneElse>
                    <p class="create-date mb-0" style="margin-top: 3px">N/A</p>
                  </ng-template>
                </div>
              </div>
              <div class="media" *ngIf="job_details?.customer_type == '2'">
                <i
                  class="fa fa-map-marker mr-3"
                  aria-hidden="true"
                  style="font-size: 18px"
                ></i>
                <div class="media-body">
                  <p
                    *ngIf="job_details?.job_type == '1'"
                    class="create-date mb-0"
                    style="margin-top: 3px"
                  >
                    {{ job_details?.drop_off_location }}
                  </p>
                  <p
                    *ngIf="job_details?.job_type == '2'"
                    class="create-date mb-0"
                    style="margin-top: 3px"
                  >
                    {{ job_details?.pick_up_location }}
                  </p>
                </div>
              </div>
              <div class="media">
                <i class="mr-3" aria-hidden="true" style="font-size: 18px"></i>
                <div class="media-body">
                  <table class="customer-apart-info">
                    <tr>
                      <td>
                        <span class="spn-inner1"
                          >{{ "JOBDETAILS.appartment_no" | translate }}.</span
                        >
                      </td>
                      <td
                        *ngIf="
                          job_details?.customer_appartment;
                          else apartmentElse
                        "
                      >
                        <span class="spn-inner2">{{
                          job_details?.customer_appartment
                        }}</span>
                      </td>
                      <ng-template #apartmentElse>
                        <td class="create-date">N/A</td>
                      </ng-template>
                    </tr>
                    <tr>
                      <td>
                        <span class="spn-inner1">{{
                          "JOBDETAILS.door_code" | translate
                        }}</span>
                      </td>

                      <td
                        *ngIf="
                          job_details?.customer_door_code;
                          else apartmentElse
                        "
                      >
                        <span class="spn-inner2">{{
                          job_details?.customer_door_code
                        }}</span>
                      </td>

                      <ng-template #apartmentElse>
                        <td class="create-date">N/A</td>
                      </ng-template>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div *ngIf="job_details?.customerTypeSelection == '2'">
              <p class="create-date" style="color: #060606; font-size: 14px">
                {{ "JOBDETAILS.num_of_customers" | translate }}
              </p>
              <div class="num-of-customer">
                {{ job_details?.multiple_customers_info.length }}
              </div>
              <div class="text-center">
                <button
                  class="btn btn-default"
                  (click)="
                    openMultipleCustomersModal(
                      job_details?.multiple_customers_info
                    )
                  "
                  style="background: #3eaa79; color: #fff; font-size: 13px"
                >
                  {{ "JOBDETAILS.customer_details" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="
            job_details?.packageObj &&
            job_details?.packageObj.package &&
            job_details?.packageObj.package != '0'
          "
          class="card"
        >
          <div class="card-body">
            <div>
              <p class="create-date" style="color: #060606; font-size: 14px">
                No. of packages
              </p>
              <div class="num-of-customer">
                {{ job_details?.packageObj.package }}
              </div>
              <!-- <div class="text-center"><button class="btn btn-default"
									 style="background: #3EAA79;
							color: #fff;
							font-size: 13px;">Details</button></div> -->
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body" *ngIf="job_details?.job_type == '1'">
            <p class="create-date">
              {{ "JOBDETAILS.customer_signature" | translate }}
            </p>
            <div class="customer-signature">
              <p *ngIf="!job_details?.customer_signature" class="create-date">
                N/A
              </p>
              <div
                *ngIf="job_details?.customer_signature"
                class="mt-3 cust-sign"
              >
                <img
                  style="cursor: pointer"
                  [src]="job_details?.customer_signature"
                  (click)="imageSelection(job_details?.customer_signature)"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <p class="create-date">
              {{ "JOBDETAILS.order_summery" | translate }}
            </p>
            <p class="create-date">
              {{ "JOBDETAILS.summery_comment" | translate }}
            </p>

            <p *ngIf="job_details?.isRoundTrip" class="font-weight-bold">
              {{ "JOBDETAILS.is_round_trip" | translate }}
            </p>
            <table
              class="customer-apart-info customer-infor-tbl"
              style="margin-left: 0"
            >
              <tr>
                <td>
                  <span class="spn-inner1">{{
                    "JOBDETAILS.ride_fare" | translate
                  }}</span>
                </td>
                <td>
                  <span class="spn-inner2"
                    >${{
                      job_details?.ride_fare +
                        job_details?.processingFee -
                        job_details?.taxes -
                        job_details?.night_charge -
                        job_details?.weekend_charge -
                        job_details?.totalTagsPrices | number : "1.2-2"
                    }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <span class="spn-inner1">{{
                    "JOBDETAILS.tags_prices" | translate
                  }}</span>
                </td>
                <td>
                  <span class="spn-inner2"
                    >${{
                      job_details?.totalTagsPrices | number : "1.2-2"
                    }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <span class="spn-inner1">{{
                    "JOBDETAILS.taxes" | translate
                  }}</span>
                </td>
                <td>
                  <span class="spn-inner2"
                    >${{ job_details?.tax | number : "1.2-2" }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <span class="spn-inner1">{{
                    "JOBDETAILS.night_charges" | translate
                  }}</span>
                </td>
                <td>
                  <span class="spn-inner2"
                    >${{ job_details?.night_charge | number : "1.2-2" }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <span class="spn-inner1">{{
                    "JOBDETAILS.weekend_charges" | translate
                  }}</span>
                </td>
                <td>
                  <span class="spn-inner2"
                    >${{ job_details?.weekend_charge | number : "1.2-2" }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <span class="spn-inner1">{{
                    "JOBDETAILS.total_ride_fare" | translate
                  }}</span>
                </td>
                <td>
                  <span class="spn-inner2"
                    >${{ job_details?.ride_fare | number : "1.2-2" }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <span class="spn-inner1">{{
                    "JOBDETAILS.item_price" | translate
                  }}</span>
                </td>
                <td>
                  <span class="spn-inner2"
                    >${{ job_details?.job_amount | number : "1.2-2" }}</span
                  >
                </td>
              </tr>

              <tr *ngIf="job_details?.ride_payment_failed == true">
                <td>
                  <span class="spn-inner1">{{
                    "JOBDETAILS.Pay_ride_fare" | translate
                  }}</span>
                </td>
                <td>
                  <!-- <span class="spn-inner2">${{job_details?.job_amount | number : '1.2-2'}}</span> -->
                  <a
                    (click)="successModal1.show()"
                    style="
                      float: right;
                      color: red;
                      font-size: 12px;
                      font-weight: 500;
                      cursor: pointer;
                    "
                    >{{ "JOBDETAILS.pay" | translate }}</a
                  >
                </td>
              </tr>

              <!-- <div  *ngIf="job_details?.ride_payment_failed == true" class="text-center"><button class="btn btn-default pay-ride-fare-btn" (click)="successModal1.show()" >Pay ride fare</button></div> -->
            </table>
          </div>
        </div>

        <div class="card" *ngIf="job_details?.customer_payment_id">
          <div class="card-body">
            <p class="create-date">
              {{ "JOBDETAILS.collection_details" | translate }}
            </p>

            <table
              class="customer-apart-info customer-infor-tbl collection-detail"
              style="margin-left: 0"
            >
              <tr>
                <td>
                  <span class="spn-inner1">{{
                    "JOBDETAILS.paid_by" | translate
                  }}</span>
                </td>
                <td class="text-right">
                  <p
                    *ngIf="
                      job_details.customer_payment_id &&
                      job_details.customer_payment_id.payment_method == '2'
                    "
                    class="cash"
                  >
                    {{ "JOBS.cash" | translate }}
                  </p>

                  <p
                    *ngIf="
                      job_details.customer_payment_id &&
                      job_details.customer_payment_id.payment_method == '1'
                    "
                    class="cash"
                  >
                    {{ "JOBS.card" | translate }}
                  </p>

                  <p
                    *ngIf="
                      job_details.customer_payment_id &&
                      job_details.customer_payment_id.payment_method == '3'
                    "
                    class="cash"
                  >
                    {{ "JOBS.cheque" | translate }}
                  </p>

                  <p
                    *ngIf="
                      job_details.customer_payment_id &&
                      job_details.customer_payment_id.payment_method == '5'
                    "
                    class="cash"
                  >
                    {{ "TPV" | translate }}
                  </p>
                </td>
              </tr>

              <tr>
                <td>
                  <span class="spn-inner1">{{
                    "JOBDETAILS.collected_amount" | translate
                  }}</span>
                </td>
                <td class="text-right">
                  <span
                    *ngIf="
                      job_details.customer_payment_id &&
                      job_details.customer_payment_id.payment_method == '2'
                    "
                    class="spn-inner2"
                    >${{
                      job_details?.collected_amount_pharmacy | number : "1.2-2"
                    }}</span
                  >

                  <span
                    *ngIf="
                      job_details.customer_payment_id &&
                      job_details.customer_payment_id.payment_method == '1'
                    "
                    class="spn-inner2"
                    >${{
                      job_details?.collected_amount | number : "1.2-2"
                    }}</span
                  >

                  <span
                    *ngIf="
                      job_details.customer_payment_id &&
                      job_details.customer_payment_id.payment_method == '3'
                    "
                    class="spn-inner2"
                    >${{
                      job_details?.customer_payment_id.cheque_amount
                        | number : "1.2-2"
                    }}</span
                  >

                  <span
                    *ngIf="
                      job_details.customer_payment_id &&
                      job_details.customer_payment_id.payment_method == '5'
                    "
                    class="spn-inner2"
                    >${{
                      job_details?.collected_amount | number : "1.2-2"
                    }}</span
                  >
                </td>
              </tr>

              <tr
                *ngIf="
                  job_details?.collected_amount_pharmacy ||
                  job_details?.cheque_amount_initials
                "
              >
                <td>
                  <span class="spn-inner1">{{
                    "JOBDETAILS.initials" | translate
                  }}</span>
                </td>

                <td class="text-right">
                  <span
                    *ngIf="job_details?.collected_amount_initial"
                    class="spn-inner2"
                    >{{ job_details?.collected_amount_initial }}</span
                  >

                  <span
                    *ngIf="job_details?.cheque_amount_initials"
                    class="spn-inner2"
                    >{{ job_details?.cheque_amount_initials }}</span
                  >

                  <span
                    *ngIf="
                      !job_details?.collected_amount_initial &&
                      !job_details?.cheque_amount_initials
                    "
                    class="spn-inner2"
                    >N/A</span
                  >
                </td>
              </tr>

              <tr *ngIf="job_details.customer_payment_id.payment_method == '3'">
                <td>
                  <span class="spn-inner1">{{
                    "JOBDETAILS.image" | translate
                  }}</span>
                </td>

                <td class="text-right">
                  <!-- <span *ngIf="job_details?.collected_amount_initial"
									class="spn-inner2">{{job_details?.collected_amount_initial}}</span> -->
                  <div class="collection-cheque-img">
                    <img
                      style="cursor: pointer"
                      [src]="job_details?.customer_payment_id.cheque_img"
                      (click)="
                        imageSelection(
                          job_details?.customer_payment_id.cheque_img
                        )
                      "
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <span class="spn-inner1">{{
                    "JOBDETAILS.collected_amount_date" | translate
                  }}</span>
                </td>
                <td class="text-right">
                  <span
                    *ngIf="
                      job_details.job_status == 'C' &&
                      job_details.customer_payment_id &&
                      job_details.customer_payment_id.payment_method == '2'
                    "
                    class="spn-inner2"
                    >{{
                      modifyDateTime(
                        job_details?.collected_amount_date_pharmacy
                      )
                    }}</span
                  >

                  <span
                    *ngIf="
                      job_details.job_status == 'C' &&
                      job_details.customer_payment_id &&
                      job_details.customer_payment_id.payment_method == '2' &&
                      job_details?.collected_amount_pharmacy == '0'
                    "
                    class="spn-inner2"
                    >N/A</span
                  >

                  <span
                    *ngIf="
                      job_details.customer_payment_id &&
                      job_details.customer_payment_id.payment_method == '1'
                    "
                    class="spn-inner2"
                    >{{
                      modifyDateTime(job_details?.customer_payment_id.createdAt)
                    }}</span
                  >

                  <span
                    *ngIf="
                      job_details.customer_payment_id &&
                      job_details.customer_payment_id.payment_method == '3'
                    "
                    class="spn-inner2"
                    >{{
                      modifyDateTime(job_details?.customer_payment_id.createdAt)
                    }}</span
                  >
                  <span
                    *ngIf="
                      job_details.customer_payment_id &&
                      job_details.customer_payment_id.payment_method == '5'
                    "
                    class="spn-inner2"
                    >{{
                      modifyDateTime(job_details?.customer_payment_id.createdAt)
                    }}</span
                  >
                </td>
              </tr>

              <tr
                *ngIf="
                  job_details?.collection_note_pharmacy ||
                  job_details?.cheque_note
                "
              >
                <td>
                  <span class="spn-inner1">{{
                    "JOBDETAILS.note" | translate
                  }}</span>
                </td>
                <td class="text-right">
                  <span class="spn-inner2">{{
                    job_details?.collection_note_pharmacy
                      ? job_details?.collection_note_pharmacy
                      : job_details?.cheque_note
                  }}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #successModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-default" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          (click)="successModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-12 text-center">
          <img class="img-fluid img-c rounded" [src]="selected_image" />
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          (click)="prescriptionDownload()"
        >
          Download
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="successModal.hide()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
<div
  bsModal
  #successModal1="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-success" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button
          type="button"
          class="close"
          (click)="successModal1.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Do You Really Want To Pay ${{
            job_details?.ride_fare | number : "1.2-2"
          }}
          ?
        </p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="successModal1.hide()"
        >
          No
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="ride_payment()"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<!-- show reason for not deliver job -->
<!-------modal for cheque---->

<!-- /.modal -->

<!-- show reason for not deliver job -->
<!-------modal for cheque---->
<div
  bsModal
  #noteModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-md add-client-modal"
    role="document"
    style="max-width: 390px"
  >
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title-absolute">
          <!-- <button class="btn btn-default">{{ 'JOBS.collected_amount1' | translate }}</button> -->
          <button class="btn btn-default">
            {{ "JOBDETAILS.note" | translate }}
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 collection-modal">
            <div class="form-group">
              <textarea
                style="
                  font-size: 14px;
                  font-weight: 400;
                  background: none !important;
                "
                class="form-control"
                rows="5"
                id="comment"
                [(ngModel)]="viewNoteModal"
                disabled
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" style="margin: auto">
        <button
          type="button"
          class="btn btn-secondary add-client-btn"
          (click)="noteModal.hide()"
        >
          {{ "JOBS.ok" | translate }}
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->

<!-------modal---->
<div
  bsModal
  #addnewcustomer="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md add-client-modal modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title-absolute">
          <button class="btn btn-default">
            {{ "JOBDETAILS.customer_info" | translate }}
          </button>
        </div>
        <button
          type="button"
          class="close"
          (click)="addnewcustomer.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive table-height">
          <table class="table table-borderless jobs-table">
            <thead>
              <tr>
                <th>{{ "JOBDETAILS.name" | translate }}</th>
                <th>{{ "JOBDETAILS.phone_no" | translate }}</th>
                <th>{{ "JOBDETAILS.email" | translate }}</th>
                <th>{{ "JOBDETAILS.appartment_no" | translate }}</th>
                <th>{{ "JOBDETAILS.door_code" | translate }}</th>
                <!-- <th>QR Code</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let customer of multipleCustomers">
                <td>
                  {{ customer.customer_f_name }} {{ customer.customer_l_name }}
                </td>
                <td>{{ customer.country_code }} {{ customer.phone_no }}</td>
                <td>{{ customer.email ? customer.email : "N/A" }}</td>
                <td>
                  {{ customer.appartment_no ? customer.appartment_no : "N/A" }}
                </td>
                <td>{{ customer.door_code ? customer.door_code : "N/A" }}</td>

                <!-- <td>
									<button type="button" class="btn btn-danger download-pdf-btn mr-2 qr-btn"  (click)="downloadCustomerPdf(customer._id)"><i
									class="fa fa-download" aria-hidden="true"></i><strong>QR</strong>
								</button>
							</td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="modal-footer" style="margin: auto;">
				<button type="button" class="btn btn-secondary client-cancel-btn" (click)="addnewcard.hide()">{{'CREATEJOB.cancel' | translate}}</button>

				<button type="button" class="btn btn-secondary add-client-btn" (click)="addCard()">{{'CARD.add' | translate}}</button>
			</div> -->
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->

<div
  bsModal
  #approveOwnershipModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md add-client-modal" role="document">
    <div class="modal-content" style="width: 90%">
      <div class="modal-header">
        <div class="modal-title-absolute">
          <button class="btn btn-default">CONFIRMATION</button>
        </div>
        <button
          type="button"
          class="close"
          (click)="approveOwnershipModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="c-info col-md-12">
          <p style="font-size: 12px">
            {{ "JOBDETAILS.approve_ownership_comment" | translate }}
          </p>
        </div>
      </div>
      <div class="modal-footer" style="margin: auto; margin-right: 0">
        <button
          type="button"
          class="btn btn-secondary client-cancel-btn"
          (click)="approveOwnershipModal.hide()"
        >
          {{ "CREATEJOB.no" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary add-client-btn"
          style="width: 80px"
          (click)="approveOwnership()"
        >
          {{ "CREATEJOB.yes" | translate }}
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>

<ng-template #stepperStatusHistoryTemplate>
  <accordion [isAnimated]="true">
    <accordion-group
      [isOpen]="true"
      *ngFor="let job of jobHistory"
      [heading]="job.booking_code"
    >
      <app-stepper-status [job_details]="job"></app-stepper-status>
    </accordion-group>
  </accordion>
</ng-template>

<ng-template #jobDetailsHeaderTemplate>
  <div class="d-flex flex-grow-1 mr-3">
    <ng-container
      *ngTemplateOutlet="jobDetailsComponent?.dynamicContentTemplate"
    ></ng-container>
  </div>
</ng-template>
<ng-template #jobDetailsBodyTemplate>
  <app-job-details [job_id]="transferredFromJobId"></app-job-details>
</ng-template>
