import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  ServicesService
} from '../../services.service';
import { SharedDataService } from '../../shared-data.service';
// import {
//   Router
// } from '@angular/router';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  NgbCalendar,
  NgbDateParserFormatter,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import {
  BsModalRef,
  BsModalService,
  ModalDirective
} from 'ngx-bootstrap/modal';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import { ModalComponent } from "../../shared/component/modal/modal.component";
import { TableComponent } from '../../shared/component/table/table.component';
import { JobType } from '../../utils/Enums';
import { EditJobComponent } from '../edit-job/edit-job.component';
import { JobDetailsComponent } from '../job-details/job-details.component';
const FileSaver = require('file-saver');

@Component({
  selector: 'app-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.scss']
})
export class JobsListComponent implements OnInit {
  @Input() endpoint: string;
  @Input() tableActionTemplate: TemplateRef<any>;
  @Input() extraColumnThTemplate: TemplateRef<any>;
  @Input() extraColumnTdTemplate: TemplateRef<any>;
  @Input() showCheckBox: boolean = false;
  @ViewChild('sendToDriverNotificationModal') public sendToDriverNotificationModal: ModalDirective;
  @ViewChild('notificationsModal') public notificationsModal: ModalDirective;
  @ViewChild('dynamicContentTemplate', { static: false }) dynamicContentTemplate: TemplateRef<any>;
  @ViewChild('requestTemplate') requestTemplate: TemplateRef<any>;
  @ViewChild('requestHeaderTemplate') requestHeaderTemplate: TemplateRef<any>;
  @ViewChild(JobDetailsComponent) jobDetailsComponent: JobDetailsComponent;
  @ViewChild(EditJobComponent) editJobComponent: EditJobComponent;
  @ViewChild(TableComponent) tableComponent: TableComponent;
  @ViewChild("recreateJobTemplate")
  recreateJobTemplate: TemplateRef<any>;
  user_id = "";
  jobs = [];
  driverNotifications = [];
  jobs_count = 0;
  pageNumber = 1;
  searchEvent = "";
  searchDate = '';
  timeCountForSearch = null;
  pageNumberForIndividualJobs = 1;
  individualJobs = [];
  individualJobsCount = 0;
  searchEventIndividualJobs = "";
  timerCountForIndividualSearch = null;
  language = 'en';
  filterBy = "0";
  bsModalRef: BsModalRef;
  bsModalRefNested: BsModalRef;
  notificationTitle = '';
  notificationText = '';
  driver_id_notification = '';
  job_id_notification = '';
  selectedJob = null
  job_id = '';
  reasonForRecreateJob: string = "";
  recreateJobDate: NgbDateStruct;
  formGroup: UntypedFormGroup
  checkedAll: boolean = false;
  driverMessage: any;
  fullSizeImage: string;
  jobType = JobType

  constructor(
    private logoutFunction: DefaultLayoutComponent,
    private toastr: ToastrService,
    private services: ServicesService,
    private SharedDataService: SharedDataService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,

    private calendar: NgbCalendar,
    private dateFormatter: NgbDateParserFormatter,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,

  ) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      checkGroup: new UntypedFormArray([])
    })
    let lang = JSON.parse(localStorage.getItem("language"));
    if (lang && (lang == 'en' || lang == 'fr')) {
      this.language = lang;
    } else {
      this.language = 'en';
    }

    this.filterBy = '0';
    this.searchEvent = "";
    this.searchDate = moment().local().format("MM/DD/YYYY");

    this.timeCountForSearch = null;
    this.pageNumber = 1;
    this.searchEventIndividualJobs = "";
    this.timerCountForIndividualSearch = null;
    this.pageNumberForIndividualJobs = 1;
    let localData = JSON.parse(localStorage.getItem("currentUser"));
    this.user_id = localData._id;

  }

  getJobs(showSpinner: boolean = true) {
    if (showSpinner) {
      this.spinner.show();
    }

    this.services.search_jobs(this.user_id, this.searchEvent, this.pageNumber, this.searchDate, this.endpoint).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.jobs = data.result;
        this.jobs_count = data.count;

        // If call endpoint "searchNewJobs"
        if (this.endpoint === "searchNewJobs") {
          this.SharedDataService.setSharedNewJobsData(data);
        }

        // If call endpoint "searchActiveJobs"
        if (this.endpoint === "searchActiveJobs") {
          this.SharedDataService.setSharedActiveJobsData(data);
        }

        // If call endpoint "searchCompletedJobs"
        if (this.endpoint === "searchCompletedJobs") {
          this.SharedDataService.setSharedCompletedJobsData(data);
        }

        const oldArrayCheckGroup = this.arrayCheckGroup.getRawValue();
        this.arrayCheckGroup.clear();

        this.jobs.forEach(item => {
          const oldArrayCheck = oldArrayCheckGroup.find(job => job.job_id == item._id)

          this.arrayCheckGroup.push(new UntypedFormGroup({
            job_id: new UntypedFormControl(item._id),
            job: new UntypedFormControl(item),
            check: new UntypedFormControl(oldArrayCheck?.check || false),
            sent: new UntypedFormControl(false)
          }))
        });

        this.spinner.hide();
        return;
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    })
  }


  onPageChange(pageNumber) {
    this.pageNumber = pageNumber;
    this.getJobs();
  }

  onDateChange(date) {
    this.filterBy = '0';
    this.pageNumber = 1;
    this.searchDate = date;
    this.getJobs();

  }

  onSearch(event) {
    this.filterBy = '0';
    this.pageNumber = 1;
    this.searchEvent = event;
    this.getJobs();
  }

  searchWithDate(data) {

    this.searchEvent = '';
    this.pageNumber = 1;
    var updatedPage = parseInt(JSON.parse(localStorage.getItem("pageNumber")) || 1);
    var userSearch = JSON.parse(localStorage.getItem("userSearch"));

    if (updatedPage) {
      this.pageNumber = updatedPage;
    }

    if (userSearch) {
      this.searchEvent = "";
    }

    if (data == 1) {
      this.pageNumber = 1;
      this.searchEvent = '';
    }
    this.getJobs();

  }

  //SORT ADDRESS FUNCTION START HERE 
  sortAddress(address) {
    const address1 = address.split(',');
    if (address1.length > 4) {
      return address1[0] + ',' + address1[1] + ',' + address1[2] + ',' + address1[3];
    } else {
      var str = '';
      for (const data of address1) {
        str += data + ',';
      }
      return str.slice(0, -1)
    }
  }

  //MODIFY DATE FUNCTION
  modifyDate(str) {
    const mydate = str.split('T')[0]
    const convert = mydate.split('-')
    return convert[0] + '/' + convert[1] + '/' + convert[2]

  }


  openNotificationModal(driver_id, job_id) {
    this.notificationTitle = "";
    this.notificationText = "";
    this.driver_id_notification = driver_id;
    this.job_id_notification = job_id;
    this.sendToDriverNotificationModal.show();
  }
  openNotificationsModal(job_id) {
    this.services.getJobNotifications(job_id).subscribe(data => {
      this.driverNotifications = data.result
    }, error => {
    })
    this.notificationsModal.show();
  }

  sendNotificationToDriver() {
    if (!this.notificationTitle || !this.notificationText) {
      this.toastr.error('', 'Enter notification fields.');
      return;
    }
    this.spinner.show();
    this.services.sendNotificationToDriver(this.driver_id_notification, this.user_id, this.notificationText, this.notificationTitle, this.job_id_notification).pipe().subscribe(data => {
      if (data.code == 200) {
        this.sendToDriverNotificationModal.hide();
        this.toastr.success("sucess", "");
        this.spinner.hide();
        return;

      } else {
        this.sendToDriverNotificationModal.hide();
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    })

  }

  getPdf(job_id) {
    console.log(job_id);

    if (this.user_id && job_id) {
      this.spinner.show();
      this.services.getJobDetailsPdfNew(this.user_id, job_id).pipe().subscribe(data => {
        if (data.code == 400) {
          this.spinner.hide();
          this.toastr.error('', data.message);
          this.logoutFunction.logout();
          return;
        }

        console.log(data)
        if (data.code == 200) {

          const bufferDataArray = new Uint8Array(data.pdf.data).buffer;
          let pdfBlob = new Blob([bufferDataArray], { type: 'application/pdf' });
          FileSaver.saveAs(pdfBlob, 'jobDetails.pdf');

          this.spinner.hide();
          return;

        } else {
          this.spinner.hide();
          this.toastr.error('', data.message);
          return;

        }

      })
    }

  }

  showJobModel(job_id, content, header) {
    this.job_id = job_id
    const initialState = {
      content: content,
      header: header,
    };

    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: `modal-xxl modal-dialog-centered`,
    });

  }
  editJobModel(job_id, content, header) {

    this.job_id = job_id

    const initialState = {
      content: content,
      header: header,
    };

    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: `modal-xxl modal-dialog-centered`,
    });
  }

  openRecreateJobModal(jobId: string, event: Event) {
    event.stopPropagation();
    this.reasonForRecreateJob = "";
    this.recreateJobDate = this.calendar.getToday();
    const initialState = {
      content: this.recreateJobTemplate,
      config: {
        title: "JOBS.RECREATE_JOB",
        action: "JOBS.RECREATE",
      },
      onClick: this.recreateJob.bind(this, jobId),
    };
    this.spinner.show();
    this.services
      .getNoteForNotDeliveredAndCancelledJob({
        jobId,
        user_id: this.user_id,
      })
      .pipe()
      .subscribe((data) => {
        if (data.code == 200) {
          this.driverMessage = data.result;
          this.bsModalRef = this.modalService.show(ModalComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            class: "modal-md modal-dialog-centered",
          });
        } else {
          this.toastr.error("error", data.message);
        }
        this.spinner.hide();
      });

  }
  recreateJob(jobId: string) {
    if (this.reasonForRecreateJob?.trim().length <= 0) {
      this.toastr.error("", this.translate.instant("JOBS.REASON_REQUIRED"), {
        onActivateTick: true,
      });
    } else {
      const formattedDate = this.dateFormatter.format(this.recreateJobDate);

      this.spinner.show();
      this.services
        .recreateJob({
          jobId: jobId,
          recreateJobDate: formattedDate,
          reason: this.reasonForRecreateJob,
        })
        .pipe()
        .subscribe((data) => {
          if (data.code == 200) {
            this.bsModalRef.hide();
            this.toastr.success(
              "",
              this.translate.instant("JOBS.RECREATE_JOB_SUCCESS", {
                formattedDate,
              })
            );
            this.getJobs();
          } else {
            this.toastr.error("error", data.message);
            this.spinner.hide();
          }
        });
    }
  }

  get arrayCheckGroup() {
    return this.formGroup?.get('checkGroup') as UntypedFormArray;
  }

  checkAll() {
    this.arrayCheckGroup.controls.forEach((element, i) => {
      this.arrayCheckGroup?.controls[i].get('check')?.setValue(this.checkedAll);
    });
  }

  onChangeCheck(event) {
    if (!event.target.checked) {
      this.checkedAll = false;
    } else {
      this.checkedAll = this.arrayCheckGroup.getRawValue().every(job => job.check);
    }
  }


  get isSelectedCheck() {
    return this.arrayCheckGroup.getRawValue().some(job => job.check);
  }

  get isSentCheck() {
    return this.arrayCheckGroup.getRawValue().some(job => job.sent);
  }

  onCleanChecksAndSent() {
    this.arrayCheckGroup.controls.forEach((element, i) => {
      this.arrayCheckGroup.controls[i].get('check').setValue(false);
      this.arrayCheckGroup.controls[i].get('sent').setValue(false);
    });
    this.checkedAll = false;
  }

  openImage(image, content) {
    this.fullSizeImage = image;
    const initialState = {
      content: content,
      hideActionBtn: true,
      cancelImageSrc: null,
      config: {
        cancel: "CLIENTS.close",
      },
    };
    this.bsModalRefNested = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-md modal-dialog-centered",
    });
  }
}
