import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectorRef, Component,


  ElementRef, OnInit,
  ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbCalendar, NgbDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import moment from 'moment';
import {
  BsModalRef,
  BsModalService,
  ModalDirective,
} from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../../environments/environment";
import { DefaultLayoutComponent } from "../../containers/default-layout/default-layout.component";
import { PushNotificationService } from '../../push-notification.service';
import { PusherServiceService } from '../../pusher-service.service';
import { ServicesService } from "../../services.service";
import { ModalComponent } from "../../shared/component/modal/modal.component";
import { JOB } from "../../shared/helpers/enums";
import { NotesComponent } from '../components/notes/notes.component';
import { EditJobComponent } from "../edit-job/edit-job.component";
import { JobDetailsComponent } from "../job-details/job-details.component";
import { ListJobsComponent } from './../components/list-jobs/list-jobs.component';
import { PagitanionJobsComponent } from './../components/pagitanion-jobs/pagitanion-jobs.component';

const FileSaver = require("file-saver");

@Component({
  templateUrl: "dashboard.component.html",
  styleUrls: ["dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  @ViewChild(NotesComponent) appNotes: NotesComponent;
  @ViewChild("approveOwnershipModal")
  public approveOwnershipModal: ModalDirective;
  @ViewChild("sendToDriverNotificationModal")
  public sendToDriverNotificationModal: ModalDirective;
  @ViewChild("recursiveModal") public recursiveModal: ModalDirective;
  @ViewChild("sendNoteModal") public sendNoteModal: ModalDirective;
  @ViewChild("histotyModal") public histotyModal: ModalDirective;

  @ViewChild(ListJobsComponent) appListJobs: ListJobsComponent;
  @ViewChild(PagitanionJobsComponent) paginationsApp: PagitanionJobsComponent;

  columns = [
    { name: 'address', visible: true },
    { name: 'due_date', visible: true },
    { name: 'job_type_delivery', visible: true },
    { name: 'amount', visible: true },
    { name: 'driver_name', visible: true },
    { name: 'job_id', visible: false }]
  countColumns = 8;
  openMap: boolean = true;
  openStatistics: boolean = false;
  keyword = '';
  selectedAll = [];

  activeCheckbox: boolean = false;

  monthButton = false;
  weekButton = false;
  dateValue: any = "";


  statuses: any[];

  loading: boolean = true;

  startDate;
  endDate;
  approveJobId: any;
  activityValues: number[] = [0, 100];
  dayWeek = []
  @ViewChild("buttonEl", { static: true }) buttonEl: NgbDatepicker;

  @ViewChild("mapRef", {
    static: true,
  })
  mapRef: ElementRef;
  @ViewChild("successModal2") successModal2: ModalDirective;

  @ViewChild("policyModal") policyModal: ModalDirective;
  ListJob: any = null;
  paginations = 0
  pageSize = 10;
  bsModalRef: BsModalRef;
  job_id = '';
  @ViewChild(JobDetailsComponent) jobDetailsComponent: JobDetailsComponent;
  @ViewChild(EditJobComponent) editJobComponent: EditJobComponent;
  constructor(
    private services: ServicesService,
    public toastr: ToastrService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    public translate: TranslateService,
    public logoutFunction: DefaultLayoutComponent,
    public socketFunction: DefaultLayoutComponent,
    private calendar: NgbCalendar,
    private notifications: PushNotificationService,
    private cd: ChangeDetectorRef, // private gmapsApi: GoogleMapsAPIWrapper
    private pusherService: PusherServiceService,
    private modalService: BsModalService,
  ) {
    translate.addLangs(["en", "fr"]);
    translate.setDefaultLang("en");
  }
  pharmacyLat: any;
  pharmacyLong: any;
  zoom = 4;
  custom_lat = 28.7306;
  custom_long = 77.7759;

  JobId = 0;

  active_jobs = 0;
  public_active_jobs = 0;
  private_active_jobs = 0;
  total_jobs = 0;
  completed_jobs = 0;
  new_jobs = 0;
  public_new_jobs = 0;
  private_new_jobs = 0;
  avg_revenue = 0;
  public_avg_revenue = 0;
  private_avg_revenue = 0;
  user_id: any;
  active_jobs_data = [];
  activeJobsData = [];
  only_completed_count = 0;
  public_only_completed_count = 0;
  private_only_completed_count = 0;
  drivers = [];
  pharmacyname: any;
  interval: any;
  public marker: any;
  numDeltas = 100;
  delay = 10; //milliseconds
  i = 0;
  deltaLat: any;
  deltaLng: any;
  public position1 = [28.7306, 77.7759];
  public map: any;
  public allMarkers = [];
  pharmacyMarker: any;
  socketIo: any;
  localData: any;
  language = "en";

  notificationTitle = "";
  notificationText = "";
  driver_id_notification = "";
  job_id_notification = "";
  jobFilter = "C";

  ngOnInit() {
    this.dateValue = this.calendar.getToday();
    this.spinner.show();
    this.fechasArray();
    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en";
    }
    this.localData = JSON.parse(localStorage.getItem("currentUser"));
    this.user_id = this.localData._id;
    this.eventsNew();

    this.dataFilter(this.dateValue, this.dateValue);
    this.getJobByCustomer(this.jobFilter);

    this.pharmacyname = this.localData.pharmacy_name;

    this.getJobByGroup();
    this.totalExpenses();
    this.getAllDriversLocations();
    // this.startPusherLocationDrivers() // init realtime

    this.initPusher();

  }

  fechasArray() {
    for (let i = 0; i < 7; i++) {
      this.dayWeek.push({ days: i, date: moment().day(i).format("YYYY-MM-DD"), active: false })
    }
  }

  activeDateButton(days) {
    this.dayWeek.forEach(element => {
      if (element.days === days.days) {
        element.active = !element.active;
        days.active = element.active;
      }
      else {
        element.active = false;
      }
    });
  }
  activeButtonDays(days) {
    this.activeDateButton(days)
    this.monthButton = false;
    this.weekButton = false;
    if (days.active) {
      let dates = moment(days.date);
      this.dateValue = { month: dates.format("MM"), year: dates.format("YYYY"), day: dates.format("DD") }
      this.searchDate();
      this.dateValue = this.calendar.getNext(this.dateValue);
      this.dateValue = this.calendar.getPrev(this.dateValue);
    }

  }

  mapCargar() {
    console.log("this.openMap", this.openMap)
    if (!this.openMap) {
      this.pusherService.removeChannel(environment.CHANNEL_CHANGE_STATUS_JOB + '_' + this.localData?._id);
    }
    // if (this.openMap) {
    //   if (this.localData) {
    //     this.services
    //       .getDriversMaps(this.localData._id)
    //       .pipe()
    //       .subscribe((data) => {
    //         if (data.code == 200) {
    //           if (data.driversInRange.length) {
    //             for (var driver of data.driversInRange) {
    //               for (var marker of this.allMarkers) {
    //                 if (driver._id.toString() == marker.id.toString()) {
    //                   let newPos = [driver.lat_long[0], driver.lat_long[1]];
    //                   this.animatedMove(marker, 0.5, marker.position, newPos);
    //                 }
    //               }
    //             }
    //           }
    //         } else {
    //         }
    //       });
    //   }
    //   this.getAllDriversLocations();
    // }
  }
  openStatics() {
  }
  getAllJobsCount() {
    this.services
      .pharmacyJobs(this.user_id)
      .pipe()
      .subscribe((data) => {
        if (data.code == 400) {
          this.spinner.hide();
          this.toastr.error("", data.message);
          this.logoutFunction.logout();
          return;
        }
        if (data.code == 200) {
          this.spinner.hide();
          this.active_jobs_data = data.activeJobs;
          this.activeJobsData = data.activeJobs;
          this.active_jobs = data.activeJobsCount;
          this.completed_jobs = data.completedJobsCount;
          this.new_jobs = data.newJobsRequestCount;
          this.total_jobs =
            this.active_jobs + this.completed_jobs + this.new_jobs;
          this.only_completed_count = data.only_completed_count;
        } else {
          this.spinner.hide();
          this.toastr.error("error", data.message);
        }
      });
  }

  searchDate() {
    this.spinner.show();
    this.dataFilter(this.dateValue, this.dateValue);
    this.getJobByCustomer(this.jobFilter)
    this.getJobByGroup();
    this.totalExpenses();
  }

  getAllDriversLocations() {
    this.services
      .getDriversMaps(this.localData._id)
      .pipe()
      .subscribe((drivers) => {
        if (drivers.code == 200) {
          this.spinner.hide();
          this.drivers = drivers.driversInRange;
          this.avg_revenue = drivers.pharmacy.total_expense.toFixed("2");

          if (!this.localData.privacy_policy_accept_date) {
            this.policyModal.show();
          } else {
            var newUser = JSON.parse(localStorage.getItem("newUser"));
            if (newUser) {
              this.successModal2.show();
              localStorage.removeItem("newUser");
            }
          }


        } else {
          this.spinner.hide();
          this.toastr.error("error", drivers.message);
        }
      });
  }

  test(labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? Math.abs(Number(labelValue)) / 1.0e9 + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
        ? Math.abs(Number(labelValue)) / 1.0e6 + "M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
          ? Math.abs(Number(labelValue)) / 1.0e3 + "K"
          : Math.abs(Number(labelValue));
  }

  goToJobsList(tabIndex: string = null) {
    if (tabIndex) {
      sessionStorage.setItem("jobs_tab_index", tabIndex);
    }

    this.router.navigate(['/jobs']);
  }


  //FUNCTION FOR SWITCH LANGUAGE
  switchLang(lang: string) {
    this.translate.use(lang);
  }

  //FUNCTION FOR MOVE TO JOBS
  move_to_jobs() {
    this.router.navigate(["/jobs"]);
  }

  //MODIFY DATE FUNCTION
  modifyDate(str) {
    const mydate = str.split("T")[0];
    const convert = mydate.split("-");
    return convert[0] + "/" + convert[1] + "/" + convert[2];
  }

  //NG DESTROY FUNCTION
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.pusherService.removeChannel(environment.CHANNEL_JOB_CHANGED);
  }


  // move marker from position current to moveto in t seconds
  // animatedMove(marker, t, current, moveto) {
  //   var lat = current.lat();
  //   var lng = current.lng();

  //   var deltalat = (moveto[0] - current.lat()) / 100;
  //   var deltalng = (moveto[1] - current.lng()) / 100;

  //   var delay = 10 * t;
  //   for (var i = 0; i < 100; i++) {
  //     (function (ind) {
  //       setTimeout(function () {
  //         var lat = marker.position.lat();
  //         var lng = marker.position.lng();
  //         lat += deltalat;
  //         lng += deltalng;
  //         var latlng = new google.maps.LatLng(lat, lng);
  //         marker.setPosition(latlng);
  //       }, delay * ind);
  //     })(i);
  //   }
  // }

  //FUNCTION TO MODIFY DATE TIME
  modifyDateTime(str) {
    if (str) {
      const mydate = str.split("T")[0];
      var time = str.split("T")[1];
      var splTime = time.split(":");
      const convert = mydate.split("-");
      return (
        convert[0] +
        "/" +
        convert[1] +
        "/" +
        convert[2] +
        "  " +
        splTime[0] +
        ":" +
        splTime[1]
      );
    }
  }

  //FUNCTION FOR REDIRECT TO SUBSCRIPTION PAGE
  subscribeRedirect() {
    this.successModal2.hide();

    sessionStorage.setItem("profile_tab_index", "subscription");
    this.router.navigate(['profile']);
  }

  sortAddress(address) {
    const address1 = address.split(",");
    if (address1.length > 4) {
      return (
        address1[0] + "," + address1[1] + "," + address1[2] + "," + address1[3]
      );
    } else {
      var str = "";
      for (const data of address1) {
        str += data + ",";
      }
      return str.slice(0, -1);
    }
  }

  showJobModel(job_id, content, header) {
    this.job_id = job_id
    const initialState = {
      content: content,
      header: header,
    };

    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: `modal-xxl modal-dialog-centered`,
    });

  }
  editJobModel(job_id, content, header) {

    this.job_id = job_id

    const initialState = {
      content: content,
      header: header,
    };

    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: `modal-xxl modal-dialog-centered`,
    });
  }

  privacy_policy_action(type) {
    this.spinner.show();
    if (type == "1") {
      this.services
        .privacy_policy_accept(this.localData._id)
        .pipe()
        .subscribe((data) => {
          if (data.code == 200) {
            localStorage.setItem("currentUser", JSON.stringify(data.result));
            this.policyModal.hide();
            this.spinner.hide();
            var newUser = JSON.parse(localStorage.getItem("newUser"));
            if (newUser) {
              this.successModal2.show();
              localStorage.removeItem("newUser");
            }
            return;
          } else {
            this.spinner.hide();
            this.policyModal.hide();
            this.toastr.error("error", data.message);
            return;
          }
        });
    } else {
      this.spinner.hide();
      this.logoutFunction.logout();
      return;
    }
  }

  getPdf(job_id2) {
    if (this.user_id && job_id2) {
      this.spinner.show();
      this.services
        .getJobDetailsPdfNew(this.user_id, job_id2)
        .pipe()
        .subscribe((data) => {
          if (data.code == 400) {
            this.spinner.hide();
            this.toastr.error("", data.message);
            this.logoutFunction.logout();
            return;
          }
          if (data.code == 200) {

            const bufferDataArray = new Uint8Array(data.pdf.data).buffer;
            let pdfBlob = new Blob([bufferDataArray], { type: 'application/pdf' });
            FileSaver.saveAs(pdfBlob, 'jobDetails.pdf');

            this.spinner.hide();
            return;
          } else {
            this.spinner.hide();
            this.toastr.error("", data.message);
            return;
          }
        });
    }
  }


  searchWithDate(data) {
    if (data < 2) {
      if (data === 1) {
        if (this.dateValue != "") {

          this.dateValue = this.calendar.getNext(this.dateValue);
        } else {
          this.dateValue = this.calendar.getNext(this.calendar.getToday());
        }
      } else {
        if (this.dateValue != "") {
          this.dateValue = this.calendar.getPrev(this.dateValue);
        } else {
          this.dateValue = this.calendar.getPrev(this.calendar.getToday());
        }
      }
    }
    this.searchDate();

  }

  addSelect(id) {
    let index = this.selectedAll.findIndex((element) => element === id);
    if (index == -1) {
      this.selectedAll.push(id);
      this.cd.detectChanges();
    }
  }

  addSelectAll(event) {
    if (this.activeCheckbox) {
      this.ListJob.forEach((element) => {
        element.jobs.forEach((job) => {
          job.selectStatus = true;
        });
      });
    } else {

      this.ListJob.forEach((element) => {
        element.jobs.forEach((job) => {
          job.selectStatus = false;
        });
      });
    }
  }

  openRecursiveModal(job) {
    this.recursiveModal.show();
  }

  openNotificationModal(driver_id, job_id) {
    this.notificationTitle = "";
    this.notificationText = "";
    this.driver_id_notification = driver_id;
    this.job_id_notification = job_id;
    this.sendToDriverNotificationModal.show();

  }
  sendNotificationToDriver() {
    if (!this.notificationTitle || !this.notificationText) {
      this.toastr.error("", "Enter notification fields.");
      return;
    }
    this.spinner.show();
    this.services
      .sendNotificationToDriver(
        this.driver_id_notification,
        this.user_id,
        this.notificationText,
        this.notificationTitle,
        this.job_id_notification
      )
      .pipe()
      .subscribe((data) => {
        if (data.code == 200) {
          this.sendToDriverNotificationModal.hide();
          this.toastr.success("sucess", "");
          this.spinner.hide();
          return;
        } else {
          this.sendToDriverNotificationModal.hide();
          this.spinner.hide();
          this.toastr.error("error", data.message);
          return;
        }
      });
  }

  openApproveOwnershipModal(job_id) {
    this.approveJobId = job_id;
    this.approveOwnershipModal.show();
  }

  opensendNoteModal(job_id) {
    this.JobId = job_id;
    this.sendNoteModal.show();
    this.appNotes.dataNote = [];
    this.appNotes.getAllNotes(job_id);
  }

  approveJobPharmacy() {
    if (this.approveJobId) {
      this.spinner.show();
      this.services
        .approveJobOwnership(this.user_id, this.approveJobId)
        .pipe()
        .subscribe((data) => {
          if (data.code == 400) {
            this.spinner.hide();
            this.approveOwnershipModal.hide();
            this.toastr.error("", data.message);
            this.logoutFunction.logout();
            return;
          }
          if (data.code == 200) {
            // this.ngOnInit();
            this.searchWithDate("0");
            this.approveOwnershipModal.hide();
            this.toastr.success("sucess", "Ownership approved");
            this.spinner.hide();
            return;
          } else {
            this.spinner.hide();
            this.toastr.error("error", data.message);
            return;
          }
        });
    }
  }

  navigate_editjob(id) {
    localStorage.setItem("edit_job_id", JSON.stringify(id));
    this.router.navigate(["/edit-job"]);
  }

  onMonth() {
    this.weekButton = false;
    this.activeDateButton(10);
    this.spinner.show();
    let startOfMonth = {
      month: moment().startOf('month').format('MM'),
      day: moment().startOf('month').format('DD'), year: moment().startOf('month').format('YYYY')
    }
    let endOfMonth = {
      month: moment().endOf('month').format('MM'),
      day: moment().endOf('month').format('DD'), year: moment().endOf('month').format('YYYY')
    };

    this.dataFilter(startOfMonth, endOfMonth);
    this.getJobByCustomer(this.jobFilter);
    this.getJobByGroup();
    this.totalExpenses();
  }

  getJobByGroup() {
    this.services
      .getGroupJobs(
        this.user_id,
        this.keyword,
        "1",
        this.startDate, this.endDate
      )
      .pipe()
      .subscribe((data) => {
        if (data.code == 400) {
          this.spinner.hide();
          this.toastr.error("", data.message);
          //this.logoutFunction.logout();
          return;
        }
        if (data.code == 200) {

          this.spinner.hide();
          this.new_jobs = 0;
          this.public_new_jobs = 0;
          this.private_new_jobs = 0;
          this.only_completed_count = 0;
          this.public_only_completed_count = 0;
          this.private_only_completed_count = 0;
          this.active_jobs = 0;
          this.public_active_jobs = 0;
          this.private_active_jobs = 0;
          data.result.forEach((element) => {
            if (element.job_status === "P") {
              this.new_jobs = element.count;
              this.public_new_jobs = element.jobForCounts.public;
              this.private_new_jobs = element.jobForCounts.private;
            }
            if (element.job_status === "C") {
              this.only_completed_count = element.count;
              this.public_only_completed_count = element.jobForCounts.public;
              this.private_only_completed_count = element.jobForCounts.private;
            }
            if (element.job_status === "A") {
              this.active_jobs = element.count;
              this.public_active_jobs = element.jobForCounts.public;
              this.private_active_jobs = element.jobForCounts.private;
            }
          });
        } else {
          this.spinner.hide();
          this.toastr.error("error", data.message);
        }
      });
  }
  onClose(events) {
    this.JobId = 0;
    this.sendNoteModal.hide();
  }


  onCloseHistorial() {
    this.JobId = 0;
    this.histotyModal.hide();
    this.appListJobs.customCalendarDatepickersComponent.range = null;
    this.appListJobs.customCalendarDatepickersComponent.fromDate = null;
    this.appListJobs.customCalendarDatepickersComponent.toDate = null;
    this.appListJobs.dataAll = [];
    this.appListJobs.job_status = "";
  }

  openHistorialModal(id) {

    this.appListJobs.id = id;
    this.appListJobs.customCalendarDatepickersComponent.initDateRange();
    this.appListJobs.getAll()

    this.histotyModal.show();
  }

  dataFilter(startDate, endDate) {
    this.startDate = startDate.month + "/" + startDate.day + "/" + startDate.year;
    this.endDate = endDate.month + "/" + endDate.day + "/" + endDate.year;
  }

  getJobByCustomer(jobFilter) {

    this.services
      .groupJobsPharmacy(
        this.user_id,
        this.keyword,
        1, this.startDate, this.endDate, jobFilter, this.pageSize
      )
      .pipe()
      .subscribe((data) => {
        if (data.code == 400) {
          this.spinner.hide();
          this.toastr.error("", data.message);
          return;
        }
        if (data.code == 200) {
          this.ListJob = data.result;
          this.ListJob = this.ListJob.map((item) => ({ ...item, active: false }));
          this.paginations = data.countPage;
          this.paginationsApp.paginations(data.countPage);
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toastr.error("error", data.message);
        }
      });
  }

  hideColumn(data) {
    this.countColumns = 9;
    let ocultas = 0;
    this.columns.forEach(element => {
      if (element.name === data.name) {
        element.visible = !element.visible;
      }
      if (!element.visible) { ocultas++; }
    });
    this.countColumns = this.countColumns - ocultas;
  }

  changeStatus(status) {
    this.spinner.show();
    this.jobFilter = status;
    if (this.monthButton == false) {
      this.getJobByCustomer(status);
    }
    else {
      this.onMonth();
    }
  }

  notificaciones() {
    this.services.getNotifications(this.user_id).pipe().subscribe((dataNoti) => {
      if (dataNoti.code == 200) {
        this.spinner.hide();
      }
    })
  }

  createJob() {
    this.router.navigateByUrl('/changePassword', {
      skipLocationChange: true
    }).then(() => {
      this.router.navigate(['createJob']);
    });
  }

  totalExpenses() {
    this.services
      .getTotalDashboard(
        this.user_id,
        "1",
        this.startDate, this.endDate
      )
      .pipe()
      .subscribe((data) => {
        if (data.code == 400) {
          this.spinner.hide();
          this.toastr.error("", data.message);
          return;
        }
        if (data.code == 200) {
          let total = 0;
          let public_total = 0;
          let private_total = 0;
          if (data.result.length > 0) {
            data.result.forEach(element => {
              const job = element.job_id;
              const transactions = element.transaction;
              transactions.forEach(transaction => {
                total += transaction.amount;

                // Calculate public_avg_revenue and private_avg_revenue
                if (job.jobFor === JOB.JOB_FOR.PUBLIC) {
                  public_total += transaction.amount;
                } else if ((job.jobFor === JOB.JOB_FOR.PRIVATE) || (job.jobFor === JOB.JOB_FOR.DISPATCH)) {
                  private_total += transaction.amount;
                }

              })
            });
          }
          this.avg_revenue = total;
          this.public_avg_revenue = public_total;
          this.private_avg_revenue = private_total;

        } else {
          this.avg_revenue = 0;
          this.public_avg_revenue = 0;
          this.private_avg_revenue = 0;
        }

        this.spinner.hide();
      });
    //getTotalDashboard
  }

  onWeek() {

    this.activeDateButton(10);
    this.monthButton = false;
    this.spinner.show();
    let begin = moment(this.dayWeek[0].date);
    let end = moment(this.dayWeek[6].date);


    let startOfMonth = {
      month: begin.format('MM'),
      day: begin.format('DD'), year: begin.format('YYYY')
    }
    let endOfMonth = {
      month: end.format('MM'),
      day: end.format('DD'), year: end.format('YYYY')
    };

    this.dataFilter(startOfMonth, endOfMonth);
    this.getJobByCustomer(this.jobFilter);
    this.getJobByGroup();
    this.totalExpenses();
  }

  onPaginate(events) {
    this.spinner.show();
    this.getPaginations(events)
  }
  onPaginateResult(events) {
    this.pageSize = events;
    this.spinner.show();
    this.getJobByCustomer(this.jobFilter);
  }

  getPaginations(pages) {
    this.services
      .groupJobsPharmacy(
        this.user_id,
        "",
        pages, this.startDate, this.endDate, this.jobFilter, this.pageSize
      )
      .pipe()
      .subscribe((data) => {
        if (data.code == 400) {
          this.spinner.hide();
          this.toastr.error("", data.message);
          //this.logoutFunction.logout();
          return;
        }
        if (data.code == 200) {
          this.ListJob = data.result;
          this.paginations = data.countPage;
          this.paginationsApp.paginations(data.countPage);
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toastr.error("error", data.message);
        }
      });

  }
  //TODO
  eventsNew() {
    this.notifications.requestPermission();
    const jobChannel = this.pusherService.init(environment.CHANNEL_UPDATE_JOBS) as unknown as { bind?: any };
    if (jobChannel.bind) {
      jobChannel.bind('updateJobStatus', data => {
        this.spinner.show();
        this.jobFilter = "P"
        this.getJobByCustomer(this.jobFilter);
        this.getJobByGroup();
      });
    }
  }

  timeIn = null;
  searchKeyword() {
    clearTimeout(this.timeIn);
    this.timeIn = setTimeout(() => {
      this.changeStatus(this.jobFilter)
    }, 400);

  }

  initPusher() {
    const jobChannel = this.pusherService.init(
      environment.CHANNEL_JOB_CHANGED
    ) as unknown as { bind?: any };
    if (jobChannel.bind) {
      jobChannel.bind(`pharmacy_jobs_deleted`, (data) => {
        if (data.method === "delete") {
          //   this.getJobByGroup();
        }
      });
      jobChannel.bind(`jobs_changed_${this.localData?._id}`, (data) => {
        if (data.user_id === this.localData?._id) {
          //      this.getJobByGroup();
        }
      });

    }


  }


}