<ng-container *ngIf="data">
  <div class="d-flex flex-column gap-1">
    <div>
      <label class="font-weight-bold mr-2">{{
        "CLIENTS.driver_name" | translate
      }}</label>

      <label style="font-size: 14px; font-weight: 400">{{
        data?.fullname
      }}</label>
    </div>
    <div>
      <label class="font-weight-bold">{{
        "DASHBOARD.status" | translate
      }}</label
      >:
      <button [ngClass]="getStatusClass(data?.job?.job_status)">
        {{ "DASHBOARD.JOB." + data?.job?.job_status | translate }}
      </button>
    </div>

    <div>
      <label class="font-weight-bold">{{
        "DASHBOARD.DROP_OFF_LAT_LONG" | translate
      }}</label
      >: {{ data?.job?.dropoff_lat_long[0] }},
      {{ data?.job?.dropoff_lat_long[1] }}
    </div>
    <div>
      <label class="font-weight-bold">{{
        "DASHBOARD.DROP_OFF_LOCATION" | translate
      }}</label
      >:
      {{ data?.job?.drop_off_location }}
    </div>
    <app-job-link [job]="data?.job"></app-job-link>
  </div>
</ng-container>
