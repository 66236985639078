
<link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
<div class="card mt-3">
    <div class="card-body">
        <div class="row">
            <div class="col-lg-4">
                <div class="scp-breadcrumb">
                  <ul class="breadcrumb">
                        <li class="back-btn-arrow" style="cursor: pointer;"><a (click)="back()" class="back-arrow"><i 
                            class="fa fa-arrow-left mr-2" aria-hidden="true" style="color:#3EAA79; margin-top: 0px;"></i>Back</a>
                        </li>
                        <li class="b-jobs"><a>{{ 'PRODUCTS.title' | translate }}</a></li>
                  </ul>
                </div>
            </div>

            <div class="col-md-4">
                <form class="search-box-custom">
                  <input
                    type="text"
                    name="search"
                    placeholder="{{ 'SEARCH' | translate }}"
                    class="animated-search"
                    [ngModel]="filter"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </form>
              </div>
            
              <!--
            <div class="col-md-4">
                <ul class="invoice-list">
                  <li>
                     <button
                      class="add-invoice-btn"
                      id="openRighSideBar"
                    >
                      {{ "PRODUCTS.add_new" | translate }}
                    </button>
                  </li>
                </ul>
              </div>
              --> 

        </div>

        <div class="row">
            <div class="table-responsive">
                <table class="table table-borderless jobs-table" >
                    <thead>
                        <tr style="align-items: center;">
                          <th>{{'PRODUCTS.nameen_table' | translate }}</th>
                          <th style="text-align: center;">{{'PRODUCTS.price_table' | translate }}</th>
                          <th style="text-align: center;">{{'PRODUCTS.size_table' | translate }}</th>
                          <th style="text-align: center;">{{'PRODUCTS.quantity_table' | translate }}</th>
                          <th style="text-align: center;">{{'PRODUCTS.type_table' | translate }}</th>
                          <th style="text-align: center;">{{'PRODUCTS.status_table' | translate }}</th>
                          <th style="width: 6%; text-align: center;">{{'PRODUCTS.stock_table' | translate }}</th>
                          
                          
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let detail of productDetail" >

                            <td >
                                {{nameProduct}}
                            </td>

                           
                            <td style="text-align: center;" *ngFor="let productD of productDetail" >
                                {{productD.price}}
                            </td>

                            <td style="text-align: center;">
                                {{size}}
                            </td>

                            <td style="text-align: center;" *ngFor="let productD of productDetail">
                                {{quantity}}
                            </td>
                            

                            <td >
                                {{type}}
                            </td>


                            <td style="text-align: center;" *ngIf="status" >
                                {{ 'PRODUCTS.active' | translate }}
                            </td>

                            <td *ngIf="!status">
                                {{ 'PRODUCTS.inactive' | translate }}
                            </td>

                            <td style="text-align: center;" *ngIf="stock" >
                                {{ 'PRODUCTS.stock' | translate }}
                            </td>

                            <td *ngIf="!stock">
                                {{ 'PRODUCTS.not' | translate }}
                            </td>


                                
                        
                        </tr>
                        

                    </tbody>

                </table>
            </div>
        </div>

    </div>
</div>


