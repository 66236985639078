import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ServicesService } from "../../../services.service";
import { CustomCalendarDatepickersComponent } from "../../../shared/component/custom-calendar-datepickers/custom-calendar-datepickers.component";
@Component({
  selector: 'app-list-jobs',
  templateUrl: './list-jobs.component.html',
  styleUrls: ['./list-jobs.component.scss']
})
export class ListJobsComponent implements OnInit {
  @Input() idCustomer = 0;
  @Input() idJobs = 0;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild(CustomCalendarDatepickersComponent) customCalendarDatepickersComponent: CustomCalendarDatepickersComponent;
  dataAll: any[] = [];
  totalItems = 0;
  pageNumber = 1;
  cantidad = 15;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  id: string
  pdfData = [];
  startDate: string;
  endDate: string;
  job_status: string = "";
  constructor(
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private services: ServicesService,
    private decimalPipe: DecimalPipe
  ) { }

  ngOnInit(): void {
  }


  toogle() {
    console.log("cerrar");

    this.close.emit(false);


  }

  onPageChange(event) {
    this.pageNumber = event;
    this.getAll();
  }

  filter(event) {
    this.pageNumber = 1;
    this.job_status = event?.target?.value;

    this.getAll();
  }

  generatePdf() {
    this.getAll(true);
  }
  createHistoryPdf() {

    const doc = new jsPDF();

    const columns = [[this.translate.instant('JOBS.job_id'),
    this.translate.instant('JOBS.client_name'),
    this.translate.instant('JOBS.address'),
    this.translate.instant('JOBS.delivery_date'),
    this.translate.instant('JOBDETAILS.job_type'),
    this.translate.instant('JOBS.status'),
    this.translate.instant('JOBS.amount'),
    this.translate.instant('JOBS.driver_name')]];
    const data = [];

    this.pdfData.forEach(element => {
      data.push([element.booking_code,
      element.customer_f_name + " " + element.customer_l_name,
      this.sortAddress(element.drop_off_location),
      this.modifyDate(element.job_date),

      element.job_type === "2" ? this.translate.instant('JOBS.job_type_pickup') : this.translate.instant('JOBS.job_type_delivery'),
      element.isExpired ? this.translate.instant('JOBS.expired') : this.translate.instant(`DASHBOARD.JOB.${element.job_status}`),
      "$" + this.decimalPipe.transform(element.job_amount, '1.2-2'),
      element.driver_id?.username || ''])
    })

    autoTable(doc, {
      theme: 'grid',
      head: columns,
      body: data,
      margin: { bottom: 20 },
      startY: 60,
      styles: {

        fontSize: 8
      },
      didDrawPage: (dataArg) => {
        var pageSize = doc.internal.pageSize;


        // Calculate the center position of the page
        const pageCenterX = (pageSize.width) / 2;


        // Add image to the document
        const imageX = pageCenterX - 12;
        const imageHeight = 15; // Adjust the height as needed

        const imageHeaderWidth = 15; // Adjust the width as needed

        // header
        if (dataArg.pageNumber === 1) {
          doc.setFontSize(11)

          doc.addImage(
            this.pdfData[0]?.user_id?.profile_img,
            'JPG',
            dataArg.settings.margin.left,
            10,
            imageHeaderWidth,
            imageHeight
          );

          doc.text(`${this.pdfData[0]?.user_id?.pharmacy_name}`, dataArg.settings.margin.left, 30);
          const address = `${this.pdfData[0]?.user_id?.location}`;

          const addressArray = address.split(", ");
          doc.text(`${addressArray[0]}`, dataArg.settings.margin.left, 35);
          doc.text(`${addressArray.slice(1).join(", ")}`, dataArg.settings.margin.left, 40);
          doc.setFont('undefined', 'bold');
          doc.setFontSize(12);
          const title = `${this.translate.instant('JOBS.HISTORY_OF_DELIVERY')}`;

          const titleX = pageCenterX - (doc.getTextWidth(`${title}`) / 2);
          doc.text(`${title}`, titleX, 48);

          doc.setFont('undefined', 'normal');
          const customerName = `${this.dataAll[0]?.customer_f_name} ${this.dataAll[0]?.customer_l_name}`;

          const customerNameX = pageCenterX - (doc.getTextWidth(`${customerName}`) / 2);
          doc.text(`${customerName}`, customerNameX, 55);

        }

        doc.setFontSize(10);
        // Footer
        var currentYear = new Date().getFullYear();
        var footerText = `© ${currentYear} Vaistat.`;
        const footTextX = pageCenterX - (doc.getTextWidth(`${footerText}`) / 2);

        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();


        const imageFooterWidth = 25; // Adjust the width as needed

        doc.addImage(
          '../../../../assets/img/vaistatlogo-2.png',
          'PNG',
          imageX,
          pageHeight - 20,
          imageFooterWidth,
          imageHeight
        );


        doc.text(footerText, footTextX, pageHeight - 5);
      }
    });

    doc.save(`${this.translate.instant('JOBS.history')}-${this.dataAll[0]?.customer_f_name}-${this.dataAll[0]?.customer_l_name}.pdf`);
  }


  getAll(generatePdf = false) {

    if (this.id) {
      //getNotesJobId
      this.spinner.show();
      this.services
        .allJobsPharmacy({ user_id: this.id, pageNumber: this.pageNumber, startDate: this.startDate, endDate: this.endDate, generatePdf, job_status: this.job_status })
        .pipe()
        .subscribe((data) => {
          if (data.code == 400) {
            this.spinner.hide();
            this.toastr.error("", data.message);
            //this.logoutFunction.logout();
            return;
          }
          if (data.code == 200) {
            if (generatePdf) {
              this.pdfData = data.result;
              this.createHistoryPdf();
            } else {
              this.dataAll = data.result;
              this.totalItems = data.count;
            }
            this.spinner.hide();

          } else {
            this.spinner.hide();
            this.toastr.error("error", data.message);
          }
        });
    }

  }

  sortAddress(address) {
    const address1 = address.split(",");
    if (address1.length > 4) {
      return (
        address1[0] + "," + address1[1] + "," + address1[2] + "," + address1[3]
      );
    } else {
      var str = "";
      for (const data of address1) {
        str += data + ",";
      }
      return str.slice(0, -1);
    }
  }
  //MODIFY DATE FUNCTION
  modifyDate(str) {
    const mydate = str.split("T")[0];
    const convert = mydate.split("-");
    return convert[0] + "/" + convert[1] + "/" + convert[2];
  }

}
