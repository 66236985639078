import {
    Component,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {
    Router
} from '@angular/router';

import { TranslateService } from "@ngx-translate/core";
import {
    BsModalRef,
    BsModalService,
    ModalDirective
} from 'ngx-bootstrap/modal';
import {
    NgxSpinnerService
} from "ngx-spinner";
import {
    ToastrService
} from 'ngx-toastr';
import {
    DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import { Tag, initTag } from '../../models/Tag';
import {
    ServicesService
} from '../../services.service';
import { ModalComponent } from "../../shared/component/modal/modal.component";

@Component({
    selector: 'app-manage-job-tags',
    templateUrl: './manage-job-tags.component.html',
    styleUrls: ['./manage-job-tags.component.scss']
})
export class ManageJobTagsComponent implements OnInit {
    @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
    @ViewChild('successModal2') public successModal2: ModalDirective;

    @ViewChild('addNewJobTagTemplate') addNewJobTagTemplate: TemplateRef<any>;
    @ViewChild('editJobTagTemplate') editJobTagTemplate: TemplateRef<any>;


    public user_id: string;
    public tag: Tag = initTag();
    public userJobTags: Tag[] = [];
    userJobTagsCount: number = 0;
    pageNumber: number = 1;
    public addNewJobTagForm: UntypedFormGroup;
    public editJobTagForm: UntypedFormGroup;
    public submittedAddNewJobTagForm = false;
    public submittedEditJobTagForm = false;
    public bsModalRef: BsModalRef;

    constructor(
        public toastr: ToastrService,
        private services: ServicesService,
        private formBuilder: UntypedFormBuilder,
        public router: Router,
        private spinner: NgxSpinnerService,
        public logoutFunction: DefaultLayoutComponent,
        private modalService: BsModalService,
        private translate: TranslateService
    ) { }

    ngOnInit() {
        this.spinner.show();
        var localData = JSON.parse(localStorage.getItem("currentUser"));
        this.user_id = localData._id;

        this.getJobTags();
        // Init addNewJobTagForm
        this.addNewJobTagForm = this.formBuilder.group({
            tag_code: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(10)]],
            tag_for: ['', [Validators.required, Validators.minLength(2)]],
            tag_for_french: ['', [Validators.required, Validators.minLength(2)]],

        });

        // Init editJobTagForm
        this.editJobTagForm = this.formBuilder.group({
            tag_code: [this.tag.tag_code, [Validators.required, Validators.minLength(7), Validators.maxLength(10)]],
            tag_for: [this.tag.tag_for, [Validators.required, Validators.minLength(2)]],
            tag_for_french: [this.tag.tag_for_french, [Validators.required, Validators.minLength(2)]],
        });

    }

    getJobTags() {
        this.services.getUserJobTags({ pageNumber: this.pageNumber }).pipe().subscribe(data => {
            if (data.code == 200) {
                this.userJobTags = data.result;
                this.userJobTagsCount = data.count;
                this.spinner.hide();
                return;

            } else {
                this.toastr.error('error', data.message);
                this.spinner.hide();
                return;
            }
        });
    }
    onPageChange(page) {
        this.pageNumber = page;
        this.getJobTags();
    }

    openAddNewJobTagModal() {
        // Reset the form
        this.tag = initTag();

        this.addNewJobTagForm.reset();

        const initialState = {
            content: this.addNewJobTagTemplate,
            config: {
                title: "TAG.add_new_tag",

            },
            onClick: this.addJobTag.bind(this),

        };
        this.bsModalRef = this.modalService.show(ModalComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            class: "modal-sd modal-dialog-centered",
        });
    }

    openEditJobTagModal(tag_id) {
        const tag: Tag = this.userJobTags.find(tag => tag._id === tag_id);
        if (!tag._id) {
            this.toastr.error('error', this.translate.instant('TAG.invalid_tag'));
        }

        this.tag = { ...tag };


        const initialState = {
            content: this.editJobTagTemplate,
            config: {
                title: "TAG.edit_tag",

            },
            onClick: this.editJobTag.bind(this),

        };
        this.bsModalRef = this.modalService.show(ModalComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            class: "modal-sd modal-dialog-centered",
        });
    }

    cancelAddNewJobTag() {
        this.modalService.hide();
    }

    addJobTag() {

        this.submittedAddNewJobTagForm = true;

        if (!(this.addNewJobTagForm.status === "VALID")) {
            this.toastr.error('error', this.translate.instant('TAG.invalid_form_error'));
            return;
        }
        this.tag.user_id = this.user_id;
        this.spinner.show();
        this.services.addJobTag(this.tag).pipe().subscribe(data => {
            if (data.code == 200) {

                this.getJobTags();

                this.modalService.hide();
                this.toastr.success('success', this.translate.instant('TAG.add_new_tag_success'));

                this.submittedAddNewJobTagForm = false;
                this.addNewJobTagForm.reset();
            } else {
                this.spinner.hide();
                this.toastr.error('', data.message);
            }
        });
    }

    editJobTag() {

        this.submittedEditJobTagForm = true;

        if (!(this.editJobTagForm.status === "VALID")) {
            this.toastr.error('error', this.translate.instant('TAG.invalid_form_error'));
            return;
        }


        this.spinner.show();
        this.services.editJobTag(this.tag).pipe().subscribe(data => {
            if (data.code == 200) {

                this.getJobTags();

                this.modalService.hide();
                this.toastr.success('success', this.translate.instant('TAG.edit_tag_success'));

                this.submittedEditJobTagForm = false;
                this.editJobTagForm.reset();
            } else {
                this.spinner.hide();
                this.toastr.error('', data.message);
            }
        });
    }

}