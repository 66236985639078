import { Component, Input } from '@angular/core';
import "color-legend-element";
//https://clhenrick.github.io/color-legend-element/
@Component({
  selector: 'app-color-legend',
  templateUrl: './color-legend.component.html',
  styleUrls: ['./color-legend.component.scss']
})
export class ColorLegendComponent {
  @Input() titleText = '';
  @Input() scaleType: 'categorical' | 'discrete' | 'continuous' | 'threshold' = 'categorical';
  @Input() domain: string[] = [];
  @Input() range: string[] = [];
  @Input() markType: 'rect' | 'circle' | 'line' = 'rect';
  @Input() hidden: boolean = false;


}
