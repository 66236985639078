import {
    AfterViewInit,
    Component,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {
    Router
} from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { CalendarOptions } from '@fullcalendar/core';
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import {
    NgxSpinnerService
} from 'ngx-spinner';
import {
    ToastrService
} from 'ngx-toastr';
import {
    DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
    ServicesService
} from '../../services.service';

import moment from 'moment';
import {
    BsModalRef,
    BsModalService
} from 'ngx-bootstrap/modal';
import { JobType } from '../../../app/utils/Enums';
import { SettingModelInformation } from '../../models/SettingModel';
import { ModalComponent } from "../../shared/component/modal/modal.component";
import { JobDetailsComponent } from '../job-details/job-details.component';

moment.locale(JSON.parse(localStorage.getItem('language')));

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styles: [''],
    styleUrls: ['./calendar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements AfterViewInit {
    newdate: any;
    Date: any;
    searchWithDate: any;
    pageNumber = 1;
    searchEvent = '';
    startDate: string;
    endDate: string;
    allCancelledJobsCount = 0;
    allCancelledJobs = [];
    userData: any;
    dateRangeSelected = [];
    srcPdf: any;
    recursiveLength: number = 0;
    setting: SettingModelInformation;
    events: any = [];
    eventsExpired: any = [];
    calendarOptions: CalendarOptions;
    DEFAULT_DATE_FORMAT: string = "YYYY-MM-DD";
    currentFilter: Number = 0;
    job_id: string
    bsModalRef: BsModalRef;
    @ViewChild('calendar') calendarComponent: FullCalendarComponent;
    @ViewChild(JobDetailsComponent) jobDetailsComponent: JobDetailsComponent;
    @ViewChild('jobDetailsHeaderTemplate', { static: false }) jobDetailsHeaderTemplate: TemplateRef<any>;
    @ViewChild('jobDetailsBodyTemplate', { static: false }) jobDetailsBodyTemplate: TemplateRef<any>;
    constructor(
        public logoutFunction: DefaultLayoutComponent,
        public toastr: ToastrService,
        private services: ServicesService,
        public router: Router,
        private spinner: NgxSpinnerService,
        private modalService: BsModalService
    ) {

        this.setting = {} as SettingModelInformation;
        this.InitCalendarOptions();
    }


    ngAfterViewInit(): void {
        this.getAllJobsForTheCurrentView();
    }
    InitCalendarOptions() {
        this.calendarOptions = {
            plugins: [timeGridPlugin, dayGridPlugin, bootstrapPlugin],
            initialView: 'dayGridMonth',
            timeZone: "UTC",
            themeSystem: 'bootstrap',
            locales: allLocales,
            firstDay: 0,
            locale: JSON.parse(localStorage.getItem('language')),
            customButtons: {
                delivery: {
                    text: JSON.parse(localStorage.getItem('language')) === "en" ? "Delivery" : "Livraison",
                    click: () => this.filterEvents(JobType.DELIVERY)
                },
                pickup: {
                    text: JSON.parse(localStorage.getItem('language')) === "en" ? "Pickup" : "Ramassage",
                    click: () => this.filterEvents(JobType.PICKUP),
                },
                passera: {
                    text: JSON.parse(localStorage.getItem('language')) === "en" ? "Will come" : "Passera",
                    click: () =>
                        this.filterEvents(JobType.PASSERA)
                },
                expired: {
                    text: 'Expiré',
                    click: function () {
                        alert('clicked the custom button!');
                    }
                }
            },
            headerToolbar: {
                left: 'prev,next delivery pickup passera',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            slotDuration: "00:10:00",
            views: {
                listDay: { buttonText: "Liste journalière" },
                listWeek: { buttonText: "Liste hebdomadaire" },
                listMonth: { buttonText: "Liste mensuelle" },
                listYear: { buttonText: "Liste annuelle" },

                timeGridPlugin: {
                    eventMaxStack: 1,
                    eventTextColor: 'white',
                    eventBorderColor: 'gray',

                },
                dayGridMonthPlugin: {
                    eventMaxStack: 6,
                }
            },

            eventOrder: "title",
            eventOrderStrict: true,
            eventDisplay: 'block',
            moreLinkClick: "popover",

            dayMaxEvents: true,

            //eventBackgroundColor : "cyan",
            eventMaxStack: 5,
            eventOverlap: true,
            eventTextColor: 'white',
            eventBorderColor: 'gray',
            navLinks: true,
            scrollTimeReset: false,
            eventClick: this.handleJobClick.bind(this),
            slotMinTime: "06:00:00",

        };


    }
    eventSetColor(type: string) {
        switch (type) {
            case "1": //Delivery
                return "#7eb3f3";
            case "2": //Pickup
                return '#ffffb9';
            case "3": // Passera
                return "##aee6cf";
            case "CN":
                return "#ffa1a1";
        }
    }

    getStatusColor(type: string) {
        switch (type) {
            case "CN":
                return "#ffa1a1";
        }
    }

    getAllJobsForTheCurrentView() {
        this.events = [];
        this.spinner.show();
        var localData = JSON.parse(localStorage.getItem("currentUser"));

        let startDate = moment(this.calendarComponent.getApi().view.currentStart).format(this.DEFAULT_DATE_FORMAT);
        let endDate = moment(this.calendarComponent.getApi().view.currentEnd).format(this.DEFAULT_DATE_FORMAT);

        this.services.getRecursiveJobForCalendarView(localData._id, startDate, endDate).pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                return;
            }
            if (data.code == 200) {
                if (data.result !== null) {

                    this.displayRecursiveJobsInCalendar(data.result)
                    this.calendarOptions.events = this.events;
                }
            } else {
                this.toastr.error('error', data.message);
                return;
            }
            this.spinner.hide();
        });


    }


    handleJobClick(job: any) {
        //JOB DETAILS FUNCTION STAR HERE
        if (job.event.id !== '') {
            this.job_id = job.event.id
            const initialState = {
                content: this.jobDetailsBodyTemplate,
                header: this.jobDetailsHeaderTemplate,
            };

            this.bsModalRef = this.modalService.show(ModalComponent, {
                initialState,
                animated: true,
                backdrop: "static",
                class: `modal-xxl modal-dialog-centered`,
            });

        }
    }

    displayRecursiveJobsInCalendar(result) {

        //remove by default the cancelled and not delivered 
        let filteredEvents: any = result.filter(r => r.job_status !== 'N' && r.job_status !== 'CN');

        if (this.currentFilter !== 0) {
            this.events = [];
            filteredEvents = filteredEvents.filter(r => Number.parseInt(r.job_type) === this.currentFilter);
        }


        this.events = filteredEvents.map((job) => ({
            id: job._id,
            title: displayTitle(job),
            allDay: false,
            start: displayStartOnJobTimingObj(job),
            backgroundColor: this.eventSetColor(job.job_type),
            jobType: Number.parseInt(job.job_type),
        }));
    }


    filterEvents(jobType: JobType) {
        this.currentFilter = jobType;

        let filtered = this.events.filter(x => x.jobType === jobType);

        this.calendarOptions.events = filtered;

    }

}

function displayStartOnJobTimingObj(job: any) {

    let start = "";

    if (job.jobTimingObj) {
        console.log(job.jobTimingObj)
        if (job.jobTimingObj.startTime !== null) {
            start = "T" + formatHour(job.jobTimingObj.startTime.hour) + ":" + job.jobTimingObj.startTime.minute + ":00+00:00";
        } else {
            start = "T09:30:00+00:00"
        }
    }

    return moment(job.job_date).format("YYYY-MM-DD") + start;

}

function formatHour(hour: number) {
    return hour < 10 ? ("0" + hour) : hour;
}

function displayTitle(job: any) {

    return job.establishment_name ? job.establishment_name : job.customer_f_name + " " + job.customer_l_name;

}

