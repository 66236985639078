import { Component, OnInit, ViewChild } from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  ModalDirective
} from 'ngx-bootstrap/modal';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';

import 'moment-timezone';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @ViewChild('deleteProduct') public deleteProduct: ModalDirective;

  public allProduct = [];
  allProductCount = 0;
  currentPage: 0;
  submitted = false;
  page = 1;
  public language = "en";
  sort = 1;
  filter = '';
  localData: any;
  customer_id: any;
  pageNumber: any;

  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    private spinner: NgxSpinnerService,
  ) {

  }

  ngOnInit(): void {

    this.sort = 1;
    this.submitted = false;
    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en"
    }
    this.localData = JSON.parse(localStorage.getItem("currentUser"));

    this.filter = '';
    this.getAllProduct(this.filter, this.page, this.sort);
  }

  //SEARCH CLIENTS FUNCTION START HERE
  searchProduct(event) {
    if (event.length == 0) {
      return this.ngOnInit();
    }
    if (event.length <= 0) {
      return console.log('less then 2');
    }

    //console.log(event);
    this.filter = event;
    this.page = 1;
    this.sort = 1;
    this.getAllProduct(this.filter, this.page, this.sort)
  }
  onPageChange(event) {
    this.page = event;
    console.log(event);
    this.getAllProduct(this.filter, this.page, this.sort);
  }
  getAllProduct(filter, page, sort) {
    this.spinner.show();
    this.services.productAll(filter, page, sort).pipe().subscribe(data => {

      this.spinner.hide();
      this.allProduct = data.entityList;
      this.allProductCount = data.totalRegister;

      console.log(data);
    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });
  }

  productDelete(id) {
    this.customer_id = id;
    this.deleteProduct.show();
  }

  deleteExistProduct() {
    this.spinner.show();
    this.services.deleteProduct(this.customer_id).pipe().subscribe(data => {

      this.spinner.hide();
      this.deleteProduct.hide();
      // this.ngOnInit();
      this.getAllProduct(this.filter, this.page, this.sort)

    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });
  }

}

