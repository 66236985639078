import { Component, OnInit, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { DispatchComponent } from '../dispatch/dispatch.component';
import { EditProfileComponent } from '../edit-profile/edit-profile.component';
import { ManageCardComponent } from '../manage-card/manage-card.component';
import { ManageJobTagsComponent } from '../manage-job-tags/manage-job-tags.component';
import { PrivateDriversComponent } from '../private-drivers/private-drivers.component';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  selectedTabIndex = 'manage-card';
  @ViewChild(DispatchComponent) dispatchComponent: DispatchComponent;
  @ViewChild(PrivateDriversComponent) privateDriversComponent: PrivateDriversComponent;
  @ViewChild(ManageCardComponent) manageCardComponent: ManageCardComponent;
  @ViewChild(ManageJobTagsComponent) manageJobTagsComponent: ManageJobTagsComponent;
  @ViewChild(ChangePasswordComponent) changePasswordComponent: ChangePasswordComponent;
  @ViewChild(EditProfileComponent) editProfileComponent: EditProfileComponent;
  @ViewChild(TabsetComponent) tabset: TabsetComponent;
  constructor() { }

  ngOnInit(): void {
  }

  onTabChange(event) {
    this.selectedTabIndex = event.id;
  }

  ngAfterViewInit(): void {

    const tab_index = sessionStorage.getItem("profile_tab_index");
    if (tab_index) {
      const tab = this.tabset.tabs.find(t => t.id === tab_index);
      if (tab) {
        tab.active = true;
      }
      sessionStorage.removeItem("profile_tab_index");
    }

  }
}
