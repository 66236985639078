import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { initJobsCountByTagData, jobsCountByTagData } from '../app/models/jobsCountByTagData';

@Injectable({
    providedIn: 'root'
})
export class SharedDataService {
    private sharedNewJobsData: BehaviorSubject<jobsCountByTagData> = new BehaviorSubject<jobsCountByTagData>(initJobsCountByTagData());
    private sharedActiveJobsData: BehaviorSubject<jobsCountByTagData> = new BehaviorSubject<jobsCountByTagData>(initJobsCountByTagData());
    private sharedCompletedJobsData: BehaviorSubject<jobsCountByTagData> = new BehaviorSubject<jobsCountByTagData>(initJobsCountByTagData());

    constructor() { }

    setSharedNewJobsData(newJobsData: jobsCountByTagData) {
        this.sharedNewJobsData.next(newJobsData);
    }

    getSharedNewJobsData(): Observable<jobsCountByTagData> {
        return this.sharedNewJobsData.asObservable();
    }

    setSharedActiveJobsData(activeJobsData: jobsCountByTagData) {
        this.sharedActiveJobsData.next(activeJobsData);
    }

    getSharedActiveJobsData(): Observable<jobsCountByTagData> {
        return this.sharedActiveJobsData.asObservable();
    }

    setSharedCompletedJobsData(completedJobsData: jobsCountByTagData) {
        this.sharedCompletedJobsData.next(completedJobsData);
    }

    getSharedCompletedJobsData(): Observable<jobsCountByTagData> {
        return this.sharedCompletedJobsData.asObservable();
    }
}
