import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  ServicesService
} from '../../services.service';
// import {
//   Router
// } from '@angular/router';
import {
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import { ModalComponent } from "../../shared/component/modal/modal.component";
@Component({
  selector: 'app-private-drivers',
  templateUrl: './private-drivers.component.html',
  styleUrls: ['./private-drivers.component.scss']
})
export class PrivateDriversComponent implements OnInit {
  @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
  @ViewChild('requestTemplate') requestTemplate: TemplateRef<any>;
  @ViewChild('requestHeaderTemplate') requestHeaderTemplate: TemplateRef<any>;
  @ViewChild('deleteDriverTemplate') deleteDriverTemplate: TemplateRef<any>;
  @ViewChild('addDriverTemplate') addDriverTemplate: TemplateRef<any>;

  user_id = "";
  drivers = [];
  drivers_count = 0;
  pageNumber = 1;
  searchEvent = "";
  timeCountForSearch = null;
  pageNumberForIndividualDrivers = 1;
  individualDrivers = [];
  individualDriversCount = 0;
  searchEventIndividualDrivers = "";
  timerCountForIndividualSearch = null;
  language = 'en';
  removeDriverId = "";
  filterBy = "0";
  bsModalRef: BsModalRef;

  constructor(
    private logoutFunction: DefaultLayoutComponent,
    private toastr: ToastrService,
    private services: ServicesService,
    // private router: Router,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    let lang = JSON.parse(localStorage.getItem("language"));
    if (lang && (lang == 'en' || lang == 'fr')) {
      this.language = lang;
    } else {
      this.language = 'en';
    }

    // this.drivers = [];
    // this.individualDrivers = [];
    this.filterBy = '0';
    this.searchEvent = "";
    this.timeCountForSearch = null;
    this.pageNumber = 1;
    this.searchEventIndividualDrivers = "";
    this.timerCountForIndividualSearch = null;
    this.pageNumberForIndividualDrivers = 1;


    let localData = JSON.parse(localStorage.getItem("currentUser"));
    this.user_id = localData._id;
    this.onPageLoad();
    // this.getIndividualDrivers();
  }

  onPageLoad() {
    this.spinner.show();
    this.services.getPharmacyPrivateDrivers(this.user_id, this.pageNumber, this.searchEvent, this.filterBy).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        // this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        console.log("data:", data)

        this.drivers = data.result;
        // for(let driver of this.drivers){
        //   let driverExist = driver.linked_pharmacies.find(element => element.pharmacy_id.toString() == this.user_id.toString());
        //   if(driverExist && driverExist.isApproved == true){
        //     driver.driverStatus = this.language == 'fr' ? 'Approuvée' : 'Approved';

        //   }else if(driverExist && driverExist.isApproved ==false){
        //     driver.driverStatus = this.language == 'fr' ? 'En attente' :'Pending';

        //   }else{
        //     driver.driverStatus = 'N/A';

        //   }
        // }

        this.drivers_count = data.count;

        this.spinner.hide();
        return;
      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;
      }
    })
  }


  onPageChange(pageNumber) {
    this.pageNumber = pageNumber;
    this.onPageLoad();

  }

  searchDrivers(event) {
    console.log(event)
    this.filterBy = '0';
    this.pageNumber = 1;
    this.searchEvent = event;
    this.onPageLoad();
  }

  getIndividualDrivers() {
    this.spinner.show();
    this.services.getIndividualDrivers(this.user_id, this.pageNumberForIndividualDrivers, this.searchEventIndividualDrivers).pipe().subscribe(data => {
      console.log("individualDrivers:", data);
      if (data.code == 400) {
        this.spinner.hide();
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.individualDrivers = data.result;
        this.individualDriversCount = data.count;
        this.spinner.hide();
        return;

      } else {
        this.spinner.hide();
        this.toastr.error('error', data.message);
        return;

      }
    })

  }

  searchIndividualDrivers(event) {
    console.log("event:", event)
    // if(!event){
    //   this.individualDrivers = [];
    //   this.individualDriversCount = 0;
    //   return;
    // }
    this.pageNumberForIndividualDrivers = 1;
    if (this.timerCountForIndividualSearch != null) {
      clearTimeout(this.timerCountForIndividualSearch)
    }
    this.timerCountForIndividualSearch = setTimeout(() => {
      this.searchEventIndividualDrivers = event;
      this.getIndividualDrivers();

    }, 1000)

  }

  onIndividualDriversPageChange(pageNumber) {
    this.pageNumberForIndividualDrivers = pageNumber;
    this.getIndividualDrivers();
  }

  openRequestModal() {
    const initialState = {
      content: this.requestTemplate,
      header: this.requestHeaderTemplate,
    };
    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-xl modal-dialog-centered",
    });
  }

  openAddDriverModal() {
    const initialState = {
      content: this.addDriverTemplate,
      title: "Add Driver"
    };
    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-xl modal-dialog-centered",
    });
  }
  resetIndividualModal() {
    this.searchEventIndividualDrivers = "";
    this.timerCountForIndividualSearch = null;
    this.pageNumberForIndividualDrivers = 1;
    this.getIndividualDrivers();

  }
  sendRequestToDriver(driver) {
    console.log("driver:", driver);
    if (!driver.secretCode) {
      this.toastr.error('', 'Enter secret code.');
      return;
    }
    if (driver.secretCode.length != 6) {
      this.toastr.error('', 'Secret code must be 6 characters.');
      return;
    }

    this.spinner.show();
    this.services.sendLinkRequest(this.user_id, driver._id, driver.secretCode).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        this.resetIndividualModal();
        this.ngOnInit();
        this.bsModalRef.hide();
        return;
        // this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.error('', data.message);
        return;

      }
    })

  }

  openRemoveDriverModal(driverId) {
    this.removeDriverId = driverId;
    const initialState = {
      content: this.deleteDriverTemplate,
      config: {
        title: "Confirmation",
        action: "JOBS.yes",
        cancel: "JOBS.no"
      },
      onClick: this.removeDriver.bind(this),

    };
    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: "modal-sm modal-dialog-centered",
    });
  }

  removeDriver() {
    if (this.removeDriverId) {
      this.spinner.show();
      this.services.removePrivateDriver(this.user_id, this.removeDriverId).pipe().subscribe(data => {
        if (data.code == 200) {
          this.onPageLoad();
          this.getIndividualDrivers();
          this.bsModalRef.hide();
          this.spinner.hide();
          this.toastr.success('Success', '');
          return;

        } else {
          this.spinner.hide();
          this.toastr.error('error', data.message);
          return;

        }

      })

    }

  }

  // changeFilter(event){
  //   this.filterBy = event.target.value;
  //   this.searchEvent = "";
  //   this.pageNumber = 1;
  //   this.onPageLoad();

  // }



}
