<link
  href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
  rel="stylesheet"
/>
<div class="card mt-3">
  <div class="card-body">
    <div class="row">
      <div class="col-lg-4">
        <div class="scp-breadcrumb">
          <ul class="breadcrumb">
            <li class="b-jobs">
              <a>{{ "ORDER.title" | translate }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md-4">
        <form class="search-box-custom">
          <input
            type="text"
            name="search"
            placeholder="{{ 'SEARCH' | translate }}"
            class="animated-search"
            (ngModelChange)="searchOrders($event)"
            [(ngModel)]="filter"
            [ngModelOptions]="{ standalone: true }"
          />
        </form>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table table-borderless jobs-table">
          <thead>
            <tr style="align-items: center">
              <th class="text-center">{{ "ORDER.order" | translate }}</th>
              <th class="text-center">{{ "ORDER.client" | translate }}</th>
              <th class="text-center">{{ "ORDER.order_total" | translate }}</th>
              <th class="text-center">{{ "ORDER.order_date" | translate }}</th>
              <th class="text-center">
                {{ "ORDER.order_status" | translate }}
              </th>
              <!-- <th class="text-center">{{ "ORDER.job_id" | translate }}</th> -->
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr
              *ngFor="
                let orders of allOrders
                  | paginate
                    : {
                        itemsPerPage: 10,
                        currentPage: pageNumber,
                        totalItems: allOrdersCount
                      }
              "
            >
              <td
                class="text-center"
                routerLink="/orders-detail/{{ orders._id }}"
              >
                {{ orders.order_no }}
              </td>
              <td
                class="text-center"
                routerLink="/orders-detail/{{ orders._id }}"
              >
                <span
                  *ngIf="
                    orders.customerId?.customer_name;
                    else elseCustomerName
                  "
                >
                  {{ orders?.profile?.first_name }}
                  {{ orders?.profile?.last_name }}
                </span>
                <ng-template #elseCustomerName>
                  {{ orders?.profile?.full_name }}
                </ng-template>
              </td>
              <td
                class="text-center"
                routerLink="/orders-detail/{{ orders._id }}"
              >
                {{ orders.total }}
              </td>
              <td
                class="text-center"
                routerLink="/orders-detail/{{ orders._id }}"
              >
                {{ orders.order_date | date : "shortDate" }}
              </td>
              <td
                class="text-center"
                *ngIf="orders.statusD == 1"
                routerLink="/orders-detail/{{ orders._id }}"
              >
                {{ "PRODUCTS.active" | translate }}
              </td>
              <td
                *ngIf="orders.statusD == 0"
                routerLink="/orders-detail/{{ orders._id }}"
              >
                {{ "PRODUCTS.inactive" | translate }}
              </td>

              <td class="text-center">
                {{ orders.status || "-" }}
              </td>

              <!-- <td class="text-center">
                <button
                  *ngIf="!orders?.jobId; else existJob"
                  class="btn btn-info btn-sm"
                  (click)="openConvertToJobModal(orders._id)"
                >
                  <span>{{ "ORDER.convert_to_job" | translate }}</span>
                  <i class="fa fa-share" aria-hidden="true"> </i>
                </button>

                <ng-template #existJob>
                  <button
                    *ngIf="
                      orders?.jobId?.job_status === 'P';
                      else diferentStatusP
                    "
                    class="btn btn-warning btn-sm"
                    (click)="redirectEditJob(orders?.jobId?._id)"
                  >
                    {{ "ORDER.edit_job" | translate }}
                    <i class="fa fa-pencil" aria-hidden="true"> </i>
                  </button>
                  <ng-template #diferentStatusP>
                    <span class="bg-secondary rounded px-2 py-1">
                      {{ getStatusJob(orders?.status) }}</span
                    >
                  </ng-template>
                </ng-template>
              </td> -->
            </tr>
          </tbody>
        </table>

        <div class="no-jobs-found">
          <p
            class="no-item text-center text-danger mb-0"
            *ngIf="!allOrders.length"
          >
            {{ "CLIENTS.no_client_found" | translate }}
          </p>
        </div>

        <div class="row f-right" style="margin-right: 40px">
          <pagination-controls
            *ngIf="allOrders.length"
            (pageChange)="onPageChange($event)"
          >
            2</pagination-controls
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-------convert modal---->
<!-- <div
  bsModal
  #convertModal="bs-modal"
  class="modal fade"
  [config]="{ backdrop: 'static', keyboard: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md add-client-modal" role="document">
    <div class="modal-content" style="width: 90%">
      <div class="modal-header">
        <div class="modal-title-absolute">
          <button class="btn btn-default">CONFIRMATION</button>
        </div>
        <button
          type="button"
          class="close"
          (click)="convertModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="c-info col-md-12">
          <p style="font-size: 12px">
            {{ "ORDER.comment_convert" | translate }}
          </p>
        </div>
      </div>
      <div class="modal-footer" style="margin: auto; margin-right: 0">
        <button
          type="button"
          class="btn btn-secondary client-cancel-btn"
          (click)="convertModal.hide()"
        >
          {{ "ORDER.cancel" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary add-client-btn"
          (click)="convertToJob()"
        >
          {{ "ORDER.yes" | translate }}
        </button>
      </div>
    </div>
    /.modal-content
  </div>
  /.modal-dialog
</div> -->
