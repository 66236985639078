import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Router } from "@angular/router";
import {
   NgbDateParserFormatter, NgbDatepickerConfig,
   NgbModalRef
} from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import "moment-timezone";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { BsModalRef, BsModalService, ModalDirective } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../../environments/environment";
import { ServicesService } from "../../services.service";
import { ParameterService } from '../../services/admin-api/parameter.service';
import { Address } from "../../services/interfaces";
import { LocationAgmComponent } from "../../shared/component/location-agm/location-agm.component";
import { ModalComponent } from "../../shared/component/modal/modal.component";
import { CommonMethods } from '../_helpers/_common-methods';
@Component({
   selector: "app-edit-job",
   templateUrl: "./edit-job.component.html",
   styleUrls: ["./edit-job.component.scss"],
})
export class EditJobComponent implements OnInit {
   @Input() parentModalRef: BsModalRef;
   @Input() recursive_id: string
   @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
   @ViewChild("successModal1") public successModal1: ModalDirective;
   @ViewChild("successModal2") public successModal2: ModalDirective;
   @ViewChild(LocationAgmComponent) locationAgmComponent: LocationAgmComponent;
   @ViewChild('locationHeaderTemplate') locationHeaderTemplate: TemplateRef<any>;
   @ViewChild('locationBodyTemplate') locationBodyTemplate: TemplateRef<any>;
   bsModalRef: BsModalRef;
   public language = "en";
   public pharmacy_name: any;
   public selected_customer: any;
   @Input() job_id: any;
   public jobDetails: any;
   public customer_name: any;
   public customers = [];
   public iban_no: any;
   public customer_email: any;
   public appartment_no: any;
   public door_code: any;
   public july = true;
   // jobForm: FormGroup;
   public cust_name: any;
   public dial_code: any;
   public location1: any;
   public locationString: any;
   public lat: any;
   public long: any;
   public sLat_long: any;
   public eLat_long: any;
   public tax: any;
   public processing_fee = 0;
   public distance = 0;
   public normalCharges = 0;
   public rideNormalCharges = 0;

   public urgentCharges = 0;
   public overallTax = 0;
   public driver_charges_normal = 0;
   public driver_charges_urgent = 0;
   public note: any;
   public customer_note: any;
   public customer_parent: any;
   public item_price: any;
   public jobDate = {
      month: 4,
      day: 18,
      year: 2018,
   };
   public dog: any;
   public driversList: any;
   public job_tags: any;
   public usedTags = [];
   public usedTagsTypes = [];
   public tags = [];
   public notUsedTags = [];
   public jobFor = "1"; // 1 for public 2 for private
   delivery_type: any;
   ride_fare = 0;
   public ride_fare_without_tax = 0;
   public job_type: any;
   pharmacy_address: any;
   pharmacy_latLong: any;
   user_id: any;
   job_from = "1";
   public urgent_charge = 0;
   public normal_charge = 0;
   public other_charges = 0;
   public sel: any;
   public drivers = [];
   selectedDriverId = "";
   public isPrivate: any;
   public fish: Number; // 1 FOR PUBLIC 2 FOR PRIVATE 3 FOR DISPATCH
   public options: any;
   public customer_type: any;
   adminTax = 0;
   adminProcessingFee = 0;
   normalNightCharge = 0;
   urgentNightCharge = 0;
   weekendCharge = 0;
   nightCharge = 0;
   dateForJob: any;
   weekend_charge_normal = 0;
   weekend_charge_urgent = 0;
   dateChangeStatus = false;
   timeForAutoJob: any;
   dateForAutoJob: any;
   job_recurring_type = 1;
   readonlyInputs = true;
   selectedValue = "8";
   dateValue = "0";
   dailyJobType = 1; // 1 for daily, 2 for mon-sat, 3 mon-fri
   dropdownList = [];
   dropdownSettings: IDropdownSettings;
   weekDays = [];
   customer_L_name: any;
   establishment_name: any;
   establishments = [];
   dropdownSettings1: IDropdownSettings;
   selected_drivers = [];
   isDisableItemPrice: number;
   is_edit = "true";
   is_checked = "false";
   item_price1: any;
   username = [];
   stateProcessingFees: any;
   state_tax_id: any;
   establishment_id: any;
   customerTypeSelection = 1;
   multiple_customers_id = [];
   residenceAllClients = [];
   dropdownSettings2: IDropdownSettings; // FOR SELECT CUSTOMERS
   customerTypeSelection1 = 1;
   establishment_search_id: any;
   multiple_customers_info = [];
   multiple_customers = [];
   residenceAllClient = [];
   establishment_name1 = "";
   customer_name1: any;
   isDisabledInputLocation = false;
   establishmentTagSelection = "0";
   establishmentTagSelection1 = "0";
   selected_type = "0";
   lastTypeSelected = "0";
   minuteStep = 10;
   edit_all_recursive_jobs = false;
   totalTagsPrices = 0;
   // dispatchCompanyId: any;
   isDispatchSelected = false;
   dropdownSettingsDispatch: IDropdownSettings;

   assignedDriversByDispatch = []; // driver ids assigned by dispatch
   selected_drivers_dispatch = []; // selected driver for job by dispatch
   selected_drivers_dispatch_username = [];

   connectedDispatches = [];
   selectedDispatchForJob = [];

   stateTaxObj = {};
   establishment_email = "";
   packageObj = {
      package: "0",
      small: "0",
      large: "0",
      extraLarge: "0",
      label: "0/0",
      weight: "0",
   };
   jobTimingObj = {
      type: 0,
      startTime: { hour: 9, minute: 30 },
      endTime: { hour: 12, minute: 30 },
      nameEn: "",
      nameFr: "",
   };

   attachedDocument = { url: "", docName: "" };
   establishment_phone = "";
   //Todo order type
   orderType = '';
   hourUrgent = '';
   pharmacyId = '';

   public selectedAddress: UntypedFormControl = new UntypedFormControl(null, []);
   customerAddresses = [];
   locationData = {};
   customer_id: string = '';
   selectedAddressAux: any = null;

   recursiveJobType = {}
   isContinuousJob = false;
   recursiveEndDate = null;

   isRoundTrip = false;
   roundTripFee: number = 0;

   original_driver_charges_normal = 0;
   original_driver_charges_urgent = 0;
   selectedFile: File = null;
   constructor(
      // private formBuilder: FormBuilder,
      public toastr: ToastrService,
      private services: ServicesService,
      private http: HttpClient,
      public router: Router,
      private spinner: NgxSpinnerService,
      public config: NgbDatepickerConfig,
      public ngbDateParserFormatter: NgbDateParserFormatter,
      private parameterService: ParameterService,
      private commonMethods: CommonMethods,
      private bsModalService: BsModalService,
   ) { }

   onChangeIsRoundTrip() {
      this.isRoundTrip = !this.isRoundTrip;
      this.calculateRoundTrip();
   }

   calculateRoundTrip() {
      if (this.fish !== 1) {
         return
      }

      var taxPercentage = (this.adminTax + this.adminProcessingFee) / 100;

      const roundTripModifier = this.isRoundTrip ? (1 + this.roundTripFee) : 1;

      this.driver_charges_normal = this.original_driver_charges_normal * roundTripModifier + Number(this.totalTagsPrices) + Number(this.weekend_charge_normal) + Number(this.normalNightCharge);
      this.normalCharges = this.driver_charges_normal * (1 + taxPercentage);

      this.driver_charges_urgent = this.original_driver_charges_urgent * roundTripModifier + Number(this.totalTagsPrices) + Number(this.weekend_charge_urgent) + Number(this.urgentNightCharge);
      this.urgentCharges = this.driver_charges_urgent * (1 + taxPercentage);

   }

   public handleAddressChange(address: any, isSelectNormal = false) {

      if (!isSelectNormal) {
         this.location1 = address.formatted_address;
         this.lat = address.geometry.location.lat();
         this.long = address.geometry.location.lng();
         // this.selectedAddress.setValue({ default: false, address: 'New address', address_fr: 'Nouvelle adresse' });
      } else {
         if (address?.value?.address === 'New address') {
            // this.location1 = null;
            // this.lat = null;
            // this.long = null;
            // this.locationString = null;
            this.locationData = {};


            this.openModalSelectLocation();


            return;
         } else {
            this.selectedAddressAux = { ...this.selectedAddress.value };
         }

         this.location1 = address?.value?.landmark;
         this.lat = address?.value?.latitude;
         this.long = address?.value?.longitude;
         this.locationString = address?.value?.landmark;
      }

      var localData1 = JSON.parse(localStorage.getItem("currentUser"));
      this.sLat_long = localData1.lat_long;
      this.eLat_long = [this.lat, this.long];
      this.spinner.show();
      this.services
         .getDistancePrice(
            this.sLat_long,
            this.eLat_long,
            this.dateForJob,
            localData1.location,
            this.location1,
            this.jobDetails.job_type,
            this.orderType
         )
         .pipe()
         .subscribe((data) => {
            if (data.code == 400) {
               this.spinner.hide();
               this.toastr.error("", data.message);
               this.commonMethods.logout();
               return;
            }
            if (data.code == 200) {
               this.roundTripFee = data.roundTripFee;
               //Todo: code implement for map
               this.locationData = {
                  lat: this.lat,
                  lng: this.long,
                  address: this.location1,
               }
               if (isSelectNormal === false) {
                  this.saveNewCustomerAddress(address);
                  this.bsModalRef.hide(); // close modal
               }
               this.locationString = this.location1;
               // finish code implement for map

               if (this.fish == 1) {
                  // if public is selected
                  this.adminTax = data.result.adminTax;
                  this.state_tax_id = data.result.state_tax_id;
                  this.stateTaxObj = data.result.stateTaxObj;

                  this.stateProcessingFees =
                     data.result.stateProcessingFees;
                  this.tax = data.result.tax;
                  this.processing_fee = data.result.processing_fee;
                  this.distance = data.result.distance;
                  this.normalCharges = data.result.normalPrice; // normal charges
                  this.rideNormalCharges =
                     data.result.ride_charges_normal;
                  this.urgentCharges = data.result.urgent_price; // urgent charges
                  this.overallTax = data.result.overallTax; // total tax
                  this.driver_charges_normal =
                     data.result.ride_charges_normal;
                  this.driver_charges_urgent =
                     data.result.ride_charges_urgent;
                  this.normalNightCharge = 0;
                  this.urgentNightCharge = 0;

                  this.weekend_charge_normal =
                     data.result.weekend_charge_normal;
                  this.weekend_charge_urgent =
                     data.result.weekend_charge_urgent;



                  this.original_driver_charges_normal = this.driver_charges_normal;
                  this.original_driver_charges_urgent = this.driver_charges_urgent;
                  var nightTag = this.tags
                     .map(function (e) {
                        return e.tag_type;
                     })
                     .indexOf("12");
                  if (nightTag > -1) {
                     var tagg = this.tags[nightTag];
                     if (
                        this.eLat_long &&
                        this.eLat_long.length &&
                        this.location1 &&
                        this.normalCharges &&
                        this.urgentCharges
                     ) {
                        this.normalNightCharge = Math.round((this.original_driver_charges_normal * this.nightCharge - this.original_driver_charges_normal) * 100) / 100;

                        this.urgentNightCharge = Math.round((this.original_driver_charges_normal * this.nightCharge - this.original_driver_charges_normal) * 100) / 100;
                     }
                  }
                  if (
                     this.eLat_long &&
                     this.eLat_long.length &&
                     this.location1 &&
                     this.normalCharges &&
                     this.urgentCharges
                  ) {
                     this.totalTagsPrices = 0;
                     for (var tag of this.tags) {
                        if (
                           tag.tag_price &&
                           (tag.tag_type != "9" ||
                              tag.tag_type != "10" ||
                              tag.tag_type != "11" ||
                              tag.tag_type != "12")
                        ) {
                           this.totalTagsPrices += tag.tag_price;
                        }
                     }
                     if (this.totalTagsPrices > 0) {
                        this.driver_charges_normal =
                           Math.round(
                              (Number(this.driver_charges_normal) -
                                 Number(this.weekend_charge_normal) +
                                 Number(this.totalTagsPrices)) *
                              100
                           ) / 100;

                        this.driver_charges_urgent =
                           Math.round(
                              (Number(this.driver_charges_urgent) -
                                 Number(this.weekend_charge_urgent) +
                                 Number(this.totalTagsPrices)) *
                              100
                           ) / 100;

                        var totalTaxProcessing =
                           Number(this.adminTax) +
                           Number(this.adminProcessingFee);
                        var normalTax =
                           Math.round(
                              ((this.driver_charges_normal +
                                 Number(
                                    this.weekend_charge_normal
                                 )) /
                                 100) *
                              totalTaxProcessing *
                              100
                           ) / 100;
                        var urgentTax =
                           Math.round(
                              ((this.driver_charges_urgent +
                                 Number(
                                    this.weekend_charge_urgent
                                 )) /
                                 100) *
                              totalTaxProcessing *
                              100
                           ) / 100;

                        this.normalCharges =
                           Math.round(
                              (this.driver_charges_normal +
                                 Number(this.weekend_charge_normal) +
                                 normalTax) *
                              100
                           ) / 100;
                        this.urgentCharges =
                           Math.round(
                              (this.driver_charges_urgent +
                                 Number(this.weekend_charge_urgent) +
                                 urgentTax) *
                              100
                           ) / 100;

                        this.driver_charges_normal =
                           Math.round(
                              (this.driver_charges_normal +
                                 Number(
                                    this.weekend_charge_normal
                                 )) *
                              100
                           ) / 100;
                        this.driver_charges_urgent =
                           Math.round(
                              (this.driver_charges_urgent +
                                 Number(
                                    this.weekend_charge_urgent
                                 )) *
                              100
                           ) / 100;
                     }
                  }
                  this.calculateRoundTrip();
               } else {
                  this.tax = 0;
                  this.processing_fee = 0;
                  this.distance = data.result.distance;
                  this.normalCharges = 0; // normal charges
                  this.urgentCharges = 0; // urgent charges
                  this.overallTax = 0; // total tax
                  this.driver_charges_normal = 0;
                  this.driver_charges_urgent = 0;
                  this.normalNightCharge = 0;
                  this.urgentNightCharge = 0;

                  this.weekend_charge_normal = 0;
                  this.weekend_charge_urgent = 0;
               }

               this.spinner.hide();
            } else {
               this.toastr.error("error", data.message);
               this.location1 = "";
               this.distance = 0;
               this.normalCharges = 0;
               this.urgentCharges = 0;
               this.overallTax = 0;
               this.driver_charges_normal = 0;
               this.driver_charges_urgent = 0;

               this.ngOnInit();
               this.spinner.hide();
            }
         });

      // Do some stuff
   }
   public addressForNewUser(lat_long, location) {
      var localData1 = JSON.parse(localStorage.getItem("currentUser"));
      this.location1 = location;
      this.locationString = location;
      this.eLat_long = lat_long;
      this.spinner.show();
      this.services
         .getDistancePrice(
            localData1.lat_long,
            this.eLat_long,
            this.dateForJob,
            localData1.location,
            this.location1,
            this.jobDetails.job_type,
            this.orderType
         )
         .pipe()
         .subscribe((data) => {
            if (data.code == 400) {
               this.spinner.hide();
               this.toastr.error("", data.message);
               this.commonMethods.logout();
               return;
            }
            if (data.code == 200) {
               this.roundTripFee = data.roundTripFee;
               if (this.fish == 1) {
                  // if public selected
                  this.tax = data.result.tax;
                  this.adminTax = data.result.adminTax;
                  this.state_tax_id = data.result.state_tax_id;
                  this.stateTaxObj = data.result.stateTaxObj;

                  this.stateProcessingFees =
                     data.result.stateProcessingFees;
                  this.processing_fee = data.result.processing_fee;
                  this.distance = data.result.distance;
                  this.normalCharges = data.result.normalPrice; // normal charges
                  this.rideNormalCharges =
                     data.result.ride_charges_normal;
                  this.urgentCharges = data.result.urgent_price; // urgent charges
                  this.overallTax = data.result.overallTax; // total tax
                  this.driver_charges_normal =
                     data.result.ride_charges_normal;
                  this.driver_charges_urgent =
                     data.result.ride_charges_urgent;
                  this.normalNightCharge = 0;
                  this.urgentNightCharge = 0;
                  this.weekend_charge_normal =
                     data.result.weekend_charge_normal;
                  this.weekend_charge_urgent =
                     data.result.weekend_charge_urgent;


                  this.original_driver_charges_normal = this.driver_charges_normal;
                  this.original_driver_charges_urgent = this.driver_charges_urgent;
                  var nightTag = this.tags
                     .map(function (e) {
                        return e.tag_type;
                     })
                     .indexOf("12");
                  if (nightTag > -1) {
                     var tagg = this.tags[nightTag];
                     if (
                        this.eLat_long &&
                        this.eLat_long.length &&
                        this.location1 &&
                        this.normalCharges &&
                        this.urgentCharges
                     ) {
                        this.normalNightCharge = Math.round((this.original_driver_charges_normal * this.nightCharge - this.original_driver_charges_normal) * 100) / 100;

                        this.urgentNightCharge = Math.round((this.original_driver_charges_normal * this.nightCharge - this.original_driver_charges_normal) * 100) / 100;
                     }
                  }
                  if (
                     this.eLat_long &&
                     this.eLat_long.length &&
                     this.location1 &&
                     this.normalCharges &&
                     this.urgentCharges
                  ) {
                     this.totalTagsPrices = 0;
                     for (var tag of this.tags) {
                        if (
                           tag.tag_price &&
                           (tag.tag_type != "9" ||
                              tag.tag_type != "10" ||
                              tag.tag_type != "11" ||
                              tag.tag_type != "12")
                        ) {
                           this.totalTagsPrices += tag.tag_price;
                        }
                     }
                     if (this.totalTagsPrices > 0) {
                        this.driver_charges_normal =
                           Math.round(
                              (Number(this.driver_charges_normal) -
                                 Number(this.weekend_charge_normal) +
                                 Number(this.totalTagsPrices)) *
                              100
                           ) / 100;

                        this.driver_charges_urgent =
                           Math.round(
                              (Number(this.driver_charges_urgent) -
                                 Number(this.weekend_charge_urgent) +
                                 Number(this.totalTagsPrices)) *
                              100
                           ) / 100;

                        var totalTaxProcessing =
                           Number(this.adminTax) +
                           Number(this.adminProcessingFee);
                        var normalTax =
                           Math.round(
                              ((this.driver_charges_normal +
                                 Number(
                                    this.weekend_charge_normal
                                 )) /
                                 100) *
                              totalTaxProcessing *
                              100
                           ) / 100;
                        var urgentTax =
                           Math.round(
                              ((this.driver_charges_urgent +
                                 Number(
                                    this.weekend_charge_urgent
                                 )) /
                                 100) *
                              totalTaxProcessing *
                              100
                           ) / 100;

                        this.normalCharges =
                           Math.round(
                              (this.driver_charges_normal +
                                 Number(this.weekend_charge_normal) +
                                 normalTax) *
                              100
                           ) / 100;
                        this.urgentCharges =
                           Math.round(
                              (this.driver_charges_urgent +
                                 Number(this.weekend_charge_urgent) +
                                 urgentTax) *
                              100
                           ) / 100;

                        this.driver_charges_normal =
                           Math.round(
                              (this.driver_charges_normal +
                                 Number(
                                    this.weekend_charge_normal
                                 )) *
                              100
                           ) / 100;
                        this.driver_charges_urgent =
                           Math.round(
                              (this.driver_charges_urgent +
                                 Number(
                                    this.weekend_charge_urgent
                                 )) *
                              100
                           ) / 100;
                     }
                  }
                  this.calculateRoundTrip();
               } else {
                  // else public all prices set to 0
                  this.tax = 0;
                  this.processing_fee = 0;
                  this.distance = 0;
                  this.normalCharges = 0; // normal charges
                  this.urgentCharges = 0; // urgent charges
                  this.overallTax = 0; // total tax
                  this.driver_charges_normal = 0;
                  this.driver_charges_urgent = 0;
                  this.normalNightCharge = 0;
                  this.urgentNightCharge = 0;
                  this.weekend_charge_normal = 0;
                  this.weekend_charge_urgent = 0;
               }

               this.spinner.hide();
            } else {
               this.toastr.error("error", data.message);
               this.location1 = "";
               this.distance = 0;
               this.normalCharges = 0;
               this.urgentCharges = 0;
               this.overallTax = 0;
               this.driver_charges_normal = 0;
               this.driver_charges_urgent = 0;

               this.ngOnInit();
               this.spinner.hide();
            }
         });
   }
   public getUrgentPrice(lat_long) {
      var localData1 = JSON.parse(localStorage.getItem("currentUser"));
      // this.spinner.show();
      this.services
         .getDistancePrice(
            localData1.lat_long,
            lat_long,
            this.dateForJob,
            localData1.location,
            this.location1,
            this.jobDetails.job_type,
            this.orderType
         )
         .pipe()
         .subscribe((data) => {
            if (data.code == 400) {
               this.spinner.hide();
               this.toastr.error("", data.message);
               this.commonMethods.logout();
               return;
            }
            if (data.code == 200) {
               this.roundTripFee = data.roundTripFee;

               if (this.fish == 1) {
                  // if public is selected

                  this.tax = data.result.tax;
                  this.processing_fee = data.result.processing_fee;
                  this.adminTax = data.result.adminTax;
                  this.state_tax_id = data.result.state_tax_id;
                  this.stateTaxObj = data.result.stateTaxObj;

                  this.stateProcessingFees =
                     data.result.stateProcessingFees;
                  this.urgentCharges = data.result.urgent_price; // urgent charges
                  this.overallTax = data.result.overallTax; // total tax

                  this.driver_charges_urgent =
                     data.result.ride_charges_urgent;
                  this.weekend_charge_urgent =
                     data.result.weekend_charge_urgent;

                  this.original_driver_charges_normal = data.result.ride_charges_normal

                  this.weekend_charge_normal =
                     data.result.weekend_charge_normal;


                  this.original_driver_charges_urgent = this.driver_charges_urgent;

                  this.urgentNightCharge = 0;
                  var nightTag = this.tags
                     .map(function (e) {
                        return e.tag_type;
                     })
                     .indexOf("12");
                  if (nightTag > -1) {
                     var tagg = this.tags[nightTag];

                     if (
                        this.eLat_long &&
                        this.eLat_long.length &&
                        this.location1 &&
                        this.normalCharges &&
                        this.urgentCharges
                     ) {
                        this.urgentNightCharge = Math.round((this.original_driver_charges_normal * this.nightCharge - this.original_driver_charges_normal) * 100) / 100;
                     }
                  }
                  if (
                     this.eLat_long &&
                     this.eLat_long.length &&
                     this.location1 &&
                     this.normalCharges &&
                     this.urgentCharges
                  ) {
                     this.totalTagsPrices = 0;
                     for (var tag of this.tags) {
                        if (
                           tag.tag_price &&
                           (tag.tag_type != "9" ||
                              tag.tag_type != "10" ||
                              tag.tag_type != "11" ||
                              tag.tag_type != "12")
                        ) {
                           this.totalTagsPrices += tag.tag_price;
                        }
                     }

                     if (this.totalTagsPrices > 0) {
                        this.driver_charges_urgent =
                           Math.round(
                              (Number(this.driver_charges_urgent) -
                                 Number(this.weekend_charge_urgent) +
                                 Number(this.totalTagsPrices)) *
                              100
                           ) / 100;

                        var totalTaxProcessing =
                           Number(this.adminTax) +
                           Number(this.adminProcessingFee);

                        var urgentTax =
                           Math.round(
                              ((this.driver_charges_urgent +
                                 Number(
                                    this.weekend_charge_urgent
                                 )) /
                                 100) *
                              totalTaxProcessing *
                              100
                           ) / 100;

                        this.urgentCharges =
                           Math.round(
                              (this.driver_charges_urgent +
                                 Number(this.weekend_charge_urgent) +
                                 urgentTax) *
                              100
                           ) / 100;

                        this.driver_charges_urgent =
                           Math.round(
                              (this.driver_charges_urgent +
                                 Number(
                                    this.weekend_charge_urgent
                                 )) *
                              100
                           ) / 100;
                     }
                  }
                  this.calculateRoundTrip();
               } else {
                  // else private selected all prices to 0
                  this.tax = 0;
                  this.processing_fee = 0;

                  this.urgentCharges = 0; // urgent charges
                  this.overallTax = 0; // total tax

                  this.driver_charges_urgent = 0;
                  this.weekend_charge_urgent = 0;

                  this.urgentNightCharge = 0;
               }

               this.spinner.hide();
            } else {
               this.toastr.error("error", data.message);
               this.urgentNightCharge = 0;
               this.urgentCharges = 0;
               this.overallTax = 0;
               this.driver_charges_urgent = 0;

               // this.ngOnInit();
               this.spinner.hide();
            }
         });
   }
   public getNormalPrice(lat_long) {
      var localData1 = JSON.parse(localStorage.getItem("currentUser"));
      // this.spinner.show();
      this.services
         .getDistancePrice(
            localData1.lat_long,
            lat_long,
            this.dateForJob,
            localData1.location,
            this.location1,
            this.jobDetails.job_type,
            this.orderType
         )
         .pipe()
         .subscribe((data) => {
            if (data.code == 400) {
               this.spinner.hide();
               this.toastr.error("", data.message);
               this.commonMethods.logout();
               return;
            }
            if (data.code == 200) {
               this.roundTripFee = data.roundTripFee;
               if (this.fish == 1) {
                  // if public selected
                  this.tax = data.result.tax;
                  this.processing_fee = data.result.processing_fee;
                  this.adminTax = data.result.adminTax;
                  this.state_tax_id = data.result.state_tax_id;
                  this.stateTaxObj = data.result.stateTaxObj;

                  this.stateProcessingFees =
                     data.result.stateProcessingFees;
                  this.normalCharges = data.result.normalPrice; // normal charges

                  this.rideNormalCharges =
                     data.result.ride_charges_normal;
                  this.overallTax = data.result.overallTax; // total tax
                  this.driver_charges_normal =
                     data.result.ride_charges_normal;
                  this.weekend_charge_normal =
                     data.result.weekend_charge_normal;

                  this.normalNightCharge = 0;

                  this.original_driver_charges_urgent = data.result.ride_charges_urgent

                  this.weekend_charge_urgent =
                     data.result.weekend_charge_urgent;

                  this.original_driver_charges_normal = this.driver_charges_normal;

                  var nightTag = this.tags
                     .map(function (e) {
                        return e.tag_type;
                     })
                     .indexOf("12");
                  if (nightTag > -1) {
                     var tagg = this.tags[nightTag];
                     if (
                        this.eLat_long &&
                        this.eLat_long.length &&
                        this.location1 &&
                        this.normalCharges &&
                        this.urgentCharges
                     ) {
                        this.normalNightCharge = Math.round((this.original_driver_charges_normal * this.nightCharge - this.original_driver_charges_normal) * 100) / 100;
                     }
                  }
                  if (
                     this.eLat_long &&
                     this.eLat_long.length &&
                     this.location1 &&
                     this.normalCharges &&
                     this.urgentCharges
                  ) {
                     this.totalTagsPrices = 0;
                     for (var tag of this.tags) {
                        if (
                           tag.tag_price &&
                           (tag.tag_type != "9" ||
                              tag.tag_type != "10" ||
                              tag.tag_type != "11" ||
                              tag.tag_type != "12")
                        ) {
                           this.totalTagsPrices += tag.tag_price;
                        }
                     }

                     if (this.totalTagsPrices > 0) {
                        this.driver_charges_normal =
                           Math.round(
                              (Number(this.driver_charges_normal) -
                                 Number(this.weekend_charge_normal) +
                                 Number(this.totalTagsPrices)) *
                              100
                           ) / 100;

                        var totalTaxProcessing =
                           Number(this.adminTax) +
                           Number(this.adminProcessingFee);
                        var normalTax =
                           Math.round(
                              ((this.driver_charges_normal +
                                 Number(
                                    this.weekend_charge_normal
                                 )) /
                                 100) *
                              totalTaxProcessing *
                              100
                           ) / 100;

                        this.normalCharges =
                           Math.round(
                              (this.driver_charges_normal +
                                 Number(this.weekend_charge_normal) +
                                 normalTax) *
                              100
                           ) / 100;

                        this.driver_charges_normal =
                           Math.round(
                              (this.driver_charges_normal +
                                 Number(
                                    this.weekend_charge_normal
                                 )) *
                              100
                           ) / 100;
                     }
                  }
               } else {
                  // else private selected and all prices must 0
                  this.tax = 0;
                  this.processing_fee = 0;

                  this.normalCharges = 0; // normal charges

                  this.overallTax = 0; // total tax
                  this.driver_charges_normal = 0;
                  this.weekend_charge_normal = 0;

                  this.normalNightCharge = 0;
               }

               this.spinner.hide();
            } else {
               this.toastr.error("error", data.message);
               this.normalNightCharge = 0;
               this.normalCharges = 0;
               this.overallTax = 0;
               this.driver_charges_normal = 0;
               // this.ngOnInit();
               this.spinner.hide();
            }
         });
   }

   ngOnInit() {

      this.spinner.show();
      const currentUser = JSON.parse(localStorage.getItem("currentUser"));
      this.pharmacyId = currentUser?._id;

      this.selectedDispatchForJob = [];
      this.connectedDispatches = [];
      this.edit_all_recursive_jobs = false;
      this.packageObj = {
         package: "0",
         small: "0",
         large: "0",
         extraLarge: "0",
         label: "0/0",
         weight: "0",
      };
      // this.parameterService.getOneParameter(`time-for-urgent-jobs`).subscribe((data) => {
      //    // const arrayHour = data.result.value.split(":");
      //    const arrayHour = data.parameter.value.split(":");

      //    const hour = arrayHour[0];
      //    const minute = arrayHour[1];
      //    this.hourUrgent = `${hour}:${minute}-${parseInt(hour) + 1}:${minute} `
      // });

      this.jobTimingObj = {
         type: 0,
         startTime: { hour: 9, minute: 30 },
         endTime: { hour: 12, minute: 30 },
         nameEn: "",
         nameFr: "",
      };

      this.attachedDocument = { url: "", docName: "" };

      this.dropdownList = [
         {
            item_id: 1,
            item_text: "Monday",
            item_text_french: "Lundi",
         },
         {
            item_id: 2,
            item_text: "Tuesday",
            item_text_french: "Mardi",
         },
         {
            item_id: 3,
            item_text: "Wednesday",
            item_text_french: "Mercredi",
         },
         {
            item_id: 4,
            item_text: "Thursday",
            item_text_french: "Jeudi",
         },
         {
            item_id: 5,
            item_text: "Friday",
            item_text_french: "Vendredi",
         },
         {
            item_id: 6,
            item_text: "Saturday",
            item_text_french: "Samedi",
         },
         {
            item_id: 0,
            item_text: "Sunday",
            item_text_french: "Dimanche",
         },
      ];
      this.dropdownSettings = {
         singleSelection: false,
         noDataAvailablePlaceholderText: "N/A",
         idField: "item_id",
         textField: "item_text",
         selectAllText: "Select All",
         unSelectAllText: "UnSelect All",
         itemsShowLimit: 3,
         allowSearchFilter: false,
      };

      this.dropdownSettings1 = {
         singleSelection: false,
         noDataAvailablePlaceholderText: "N/A",
         idField: "_id",
         textField: "username",
         itemsShowLimit: 3,
         selectAllText: "Select All",
         unSelectAllText: "UnSelect All",
         allowSearchFilter: true,
      };

      this.dropdownSettings2 = {
         singleSelection: false,
         noDataAvailablePlaceholderText: "N/A",
         idField: "_id",
         textField: "username",
         selectAllText: "Select All",
         unSelectAllText: "Unselect All",
         itemsShowLimit: 3,
         allowSearchFilter: true,
      };

      this.dropdownSettingsDispatch = {
         singleSelection: false,
         noDataAvailablePlaceholderText: "N/A",
         idField: "_id",
         textField: "companyName",
         selectAllText: "Select All",
         unSelectAllText: "Unselect All",
         itemsShowLimit: 5,
         allowSearchFilter: true,
      };

      this.language = JSON.parse(localStorage.getItem("language"));
      if (!this.language) {
         this.language = "en";
      }
      if (this.language == "fr") {
         this.dropdownSettings.textField = "item_text_french";
         this.dropdownSettings.selectAllText = "Tout sélectionner";
         this.dropdownSettings.unSelectAllText = "Tout déselectionner";
      } else {
         this.dropdownSettings.textField = "item_text";
         this.dropdownSettings.selectAllText = "Select All";
         this.dropdownSettings.unSelectAllText = "Unselect All";
      }
      this.dateChangeStatus = false;
      var date = new Date();
      var ngbDateStruct = {
         day: date.getUTCDay(),
         month: date.getUTCMonth(),
         year: date.getUTCFullYear(),
      };
      const current = new Date();
      this.config.minDate = {
         month: current.getMonth() + 1,
         day: current.getDate(),
         year: current.getFullYear(),
      };
      var localData = JSON.parse(localStorage.getItem("currentUser"));
      this.user_id = localData._id;
      this.pharmacy_address = localData.location;
      this.pharmacy_latLong = localData.lat_long;

      this.dial_code = localData.country_code;
      this.pharmacy_name = localData.pharmacy_name;

      this.recursive_id ? this.getRecursiveJobDetails() : this.getJobDetails()


      this.getDriversClick();
   }
   getJobDetails() {
      this.services
         .getJobDetailsForEditJob(this.job_id, this.user_id)
         .pipe()
         .subscribe((data) => {
            if (data.code == 400) {
               this.spinner.hide();
               this.toastr.error("", data.message);
               this.commonMethods.logout();
               return;
            }
            if (data.code == 200) {

               this.adminTax = data.stateTaxData.totalTax;
               this.state_tax_id = data.stateTaxData._id;
               this.stateTaxObj = data.stateTaxData;

               this.adminProcessingFee =
                  data.priceFromAdmin.processing_fee;
               this.nightCharge = data.priceFromAdmin.night_charge;
               this.weekendCharge = data.priceFromAdmin.weekend_charge;
               this.jobDetails = data.result;
               this.isRoundTrip = this.jobDetails.isRoundTrip;
               this.dateForJob = this.jobDetails.job_date;
               this.customerTypeSelection =
                  this.jobDetails.customerTypeSelection;
               this.establishmentTagSelection =
                  this.jobDetails.establishmentTagSelection;
               this.establishmentTagSelection1 =
                  this.jobDetails.establishmentTagSelection;
               if (this.establishmentTagSelection1 == "1") {
                  this.lastTypeSelected = "16";
               } else if (this.establishmentTagSelection1 == "2") {
                  this.lastTypeSelected = "17";
               }
               this.totalTagsPrices = this.jobDetails.totalTagsPrices
                  ? this.jobDetails.totalTagsPrices
                  : 0;

               if (this.customerTypeSelection == 2) {
                  this.residenceAllClient =
                     data.result.multiple_customers_info;

                  this.residenceAllClient.map(
                     (client) =>
                        (client.username = `${client.customer_f_name} ${client.customer_l_name} (${client.country_code} ${client.phone_no})`)
                  );

                  this.onSelectAll2(this.residenceAllClient);
                  this.getAllCustomersOfSelectedEstablishment(
                     this.user_id,
                     this.jobDetails.establishment_id._id,
                     parseInt(this.jobDetails.customer_type)
                  );
               }

               this.job_tags = data.jobTags;
               this.usedTags = this.jobDetails.job_tags;

               for (var tag of this.job_tags) {
                  for (var usdTag of this.usedTags) {
                     if (tag.tag_type == usdTag.tag_type) {
                        tag.running = false;
                        tag.default_key = "2";
                        this.tags.push(tag);
                        if (tag.tag_type == "16") {
                           this.selected_type = tag.tag_type;
                        } else if (tag.tag_type == "17") {
                           this.selected_type = tag.tag_type;
                        }
                     }
                  }
               }

               if (this.jobDetails.job_type == "1") {
                  //Todo: if job is for delivery
                  this.location1 = this.jobDetails.drop_off_location;
                  this.locationString = this.jobDetails.drop_off_location;
                  this.eLat_long = this.jobDetails.dropoff_lat_long;
               } else {
                  // Todo: if job is for pickup
                  this.location1 = this.jobDetails.pick_up_location;
                  this.locationString = this.jobDetails.pick_up_location;
                  this.eLat_long = this.jobDetails.pickup_lat_long;
               }

               this.locationData = {
                  lat: this.eLat_long[0],
                  lng: this.eLat_long[1],
                  address: this.locationString,
               }

               if (this.jobDetails.customer_type == "2") {
                  this.customer_id = this.jobDetails.customer_id._id;
                  this.getAddressClient(this.jobDetails?.customer_id?._id, this.eLat_long[0], this.eLat_long[1]);
                  this.isDisabledInputLocation = true;
               } else {
                  this.customer_id = '';
                  this.isDisabledInputLocation = false;
               }

               var str = this.jobDetails.job_date;
               var spl = str.split("T");
               var newSpl = spl[0].split("-");
               var year = parseInt(newSpl[0]);
               var month = parseInt(newSpl[1]);
               var day = parseInt(newSpl[2]);
               if (this.jobDetails.delivery_type == "1") {
                  // if urgent
                  this.dog = '1';
                  this.urgentCharges = this.jobDetails.ride_fare;
                  this.tax = Number(this.jobDetails.tax);
                  this.processing_fee = Number(
                     this.jobDetails.processingFee
                  );
                  this.driver_charges_urgent =
                     Number(this.jobDetails.ride_fare_without_tax) +
                     Number(this.jobDetails.processingFee); // ADD PROCESSING FEE IN RIDE CHARGES BCOZ RIDE FARE - PROCESSING FEE FROM API(PROCESSING FEE CHARGES FROM DRIVER SIDE)
                  this.urgentNightCharge = Number(
                     this.jobDetails.night_charge
                  );
                  this.weekend_charge_urgent = Number(
                     this.jobDetails.weekend_charge
                  );
                  this.getNormalPrice(this.eLat_long);
               } else if (this.jobDetails.delivery_type == "2") {
                  this.dog = '2';
                  this.normalCharges = this.jobDetails.ride_fare;
                  this.tax = Number(this.jobDetails.tax);
                  this.processing_fee = Number(
                     this.jobDetails.processingFee
                  );
                  this.driver_charges_normal =
                     Number(this.jobDetails.ride_fare_without_tax) +
                     Number(this.jobDetails.processingFee); // ADD PROCESSING FEE IN RIDE CHARGES BCOZ RIDE FARE - PROCESSING FEE FROM API(PROCESSING FEE CHARGES FROM DRIVER SIDE)
                  this.normalNightCharge = Number(
                     this.jobDetails.night_charge
                  );
                  this.weekend_charge_normal = Number(
                     this.jobDetails.weekend_charge
                  );

                  this.getUrgentPrice(this.eLat_long);
               } else if (this.jobDetails.delivery_type == "3") {
                  this.dog = '3'
               }

               this.jobDate = {
                  month: month,
                  day: day,
                  year: year,
               };

               this.customer_name = this.jobDetails.customer_id;

               this.customer_L_name = this.jobDetails.customer_id;
               this.delivery_type = this.jobDetails.delivery_type;
               this.iban_no = this.jobDetails.customer_phone;
               this.customer_email = this.jobDetails.customer_email;
               this.appartment_no = this.jobDetails.customer_appartment;
               this.door_code = this.jobDetails.customer_door_code;
               this.note = this.jobDetails.note;
               this.customer_note = this.jobDetails.customer_note;
               this.customer_parent = this.jobDetails.customer_parent;
               this.item_price = this.jobDetails.job_amount;
               this.item_price1 = this.jobDetails.job_amount;

               this.connectedDispatches = data.connectedDispatches;

               if (
                  this.jobDetails.jobFor == "2" &&
                  this.jobDetails.selectedDispatchForJob.length
               ) {
                  //DISPATCH SELECTED

                  this.selectedDispatchForJob =
                     this.jobDetails.selectedDispatchForJob;

               } else {
                  this.username = data.result.selected_drivers;
                  if (this.username.length) {
                     this.onSelectAll1(this.username);
                  }
               }

               if (this.item_price == "0") {
                  this.is_edit = "false";
                  this.is_checked = "true";
               }
               this.selected_customer = parseInt(
                  this.jobDetails.customer_type
               );
               this.customer_type = this.selected_customer;

               if (
                  this.jobDetails.packageObj &&
                  this.customer_type == "4"
               ) {
                  this.packageObj = this.jobDetails.packageObj;
               }

               this.jobFor = this.jobDetails.jobFor;
               this.distance = this.jobDetails.estimate_distance;
               this.establishment_email =
                  this.jobDetails.establishment_email;
               this.establishment_phone =
                  this.jobDetails.establishment_phone;

               if (
                  this.jobDetails.jobTimingObj &&
                  this.jobDetails.jobTimingObj.type &&
                  Number(this.jobDetails.jobTimingObj.type)
               ) {
                  this.jobTimingObj = {
                     type: Number(this.jobDetails.jobTimingObj.type),
                     startTime: {
                        hour: Number(
                           this.jobDetails.jobTimingObj.startTime.hour
                        ),
                        minute: Number(
                           this.jobDetails.jobTimingObj.startTime
                              .minute
                        ),
                     },
                     endTime: {
                        hour: Number(
                           this.jobDetails.jobTimingObj.endTime.hour
                        ),
                        minute: Number(
                           this.jobDetails.jobTimingObj.endTime.minute
                        ),
                     },
                     nameEn: this.jobDetails.jobTimingObj.nameEn,
                     nameFr: this.jobDetails.jobTimingObj.nameFr,
                  };
               }

               if (
                  this.jobDetails.attachedDocument &&
                  this.jobDetails.attachedDocument.url &&
                  this.jobDetails.attachedDocument.docName
               ) {
                  this.attachedDocument =
                     this.jobDetails.attachedDocument;
               }

               if (this.customerTypeSelection != 2) {
                  // display first name ,last name
                  // this.customer_L_name = this.jobDetails.customer_l_name;
                  this.customer_L_name = this.jobDetails.customer_id;
                  this.customer_name = this.jobDetails.customer_id;
                  this.establishment_name =
                     this.jobDetails.establishment_id;
                  if (this.establishment_name) {
                     this.establishment_id =
                        this.jobDetails.establishment_id._id;
                     this.establishment_search_id =
                        this.jobDetails.establishment_id._id;
                  }
               } else {
                  this.establishment_name =
                     this.jobDetails.establishment_id;
                  this.establishment_id =
                     this.jobDetails.establishment_id._id;
                  this.establishment_search_id =
                     this.jobDetails.establishment_id._id;
               }
               if (
                  this.jobDetails.jobFor == "2" &&
                  !this.jobDetails.selectedDispatchForJob.length
               ) {
                  // job is private
                  this.fish = 2;
                  this.isPrivate = "2";
               } else if (
                  this.jobDetails.jobFor == "2" &&
                  this.jobDetails.selectedDispatchForJob.length
               ) {
                  // job is Dispatch
                  this.fish = 3;
                  this.isPrivate = "3";
                  this.isDispatchSelected = true;
               } else {
                  // job is public
                  this.fish = 1;
                  this.isPrivate = "";
               }

               this.spinner.hide();
            } else {
               this.spinner.hide();
               this.toastr.error("error", data.message);
               return;
            }
         });
   }
   getRecursiveJobDetails() {

      this.services
         .getDetailsForEditRecursiveV2(this.recursive_id, this.user_id)
         .pipe()
         .subscribe((data) => {
            if (data.code == 400) {
               this.spinner.hide();
               this.toastr.error("", data.message);
               this.commonMethods.logout();
               return;
            }
            if (data.code == 200) {

               this.adminTax = data.stateTaxData.totalTax;
               this.state_tax_id = data.stateTaxData._id;
               this.stateTaxObj = data.stateTaxData;

               this.adminProcessingFee =
                  data.priceFromAdmin.processing_fee;
               this.nightCharge = data.priceFromAdmin.night_charge;
               this.weekendCharge = data.priceFromAdmin.weekend_charge;
               this.jobDetails = data.result?.base_job_values;

               this.isRoundTrip = this.jobDetails.isRoundTrip
               this.multiple_customers_info = [...this.jobDetails?.multiple_customers_info];

               this.recursiveJobType = data?.result?.recursive_job_type
               console.log(this.recursiveJobType)
               this.isContinuousJob = data?.result?.continuous

               if (data?.result?.end_date) {
                  this.recursiveEndDate = {
                     year: +moment(data?.result?.end_date).format("YYYY"),
                     month: +moment(data?.result?.end_date).format("MM"),
                     day: +moment(data?.result?.end_date).format("DD"),
                  }
               } else {
                  this.recursiveEndDate = null
               }

               // this.dateForJob = this.jobDetails.job_date;
               this.dateForJob = moment().format("YYYY-MM-DD");
               this.customerTypeSelection =
                  this.jobDetails.customerTypeSelection;
               this.establishmentTagSelection =
                  this.jobDetails.establishmentTagSelection;
               this.establishmentTagSelection1 =
                  this.jobDetails.establishmentTagSelection;
               if (this.establishmentTagSelection1 == "1") {
                  this.lastTypeSelected = "16";
               } else if (this.establishmentTagSelection1 == "2") {
                  this.lastTypeSelected = "17";
               }
               this.totalTagsPrices = this.jobDetails.totalTagsPrices
                  ? this.jobDetails.totalTagsPrices
                  : 0;

               if (this.customerTypeSelection == 2) {
                  this.residenceAllClient =
                     data.result?.base_job_values?.multiple_customers_info;

                  this.residenceAllClient.map(
                     (client) =>
                        (client.username = `${client.customer_f_name} ${client.customer_l_name} (${client.country_code} ${client.phone_no})`)
                  );

                  this.onSelectAll2(this.residenceAllClient);
                  this.getAllCustomersOfSelectedEstablishment(
                     this.user_id,
                     this.jobDetails.establishment_id._id,
                     parseInt(this.jobDetails.customer_type)
                  );
               }

               this.job_tags = data.jobTags;
               this.usedTags = this.jobDetails.job_tags;

               for (var tag of this.job_tags) {
                  for (var usdTag of this.usedTags) {
                     if (tag.tag_type == usdTag.tag_type) {
                        tag.running = false;
                        tag.default_key = "2";
                        this.tags.push(tag);
                        if (tag.tag_type == "16") {
                           this.selected_type = tag.tag_type;
                        } else if (tag.tag_type == "17") {
                           this.selected_type = tag.tag_type;
                        }
                     }
                  }
               }

               if (this.jobDetails.job_type == "1") {
                  // if job is for delivery
                  this.location1 = this.jobDetails.drop_off_location;
                  this.locationString = this.jobDetails.drop_off_location;
                  this.eLat_long = this.jobDetails.dropoff_lat_long;
               } else {
                  // Todo: if job is for pickup
                  this.location1 = this.jobDetails.pick_up_location;
                  this.locationString = this.jobDetails.pick_up_location;
                  this.eLat_long = this.jobDetails.pickup_lat_long;
               }

               this.locationData = {
                  lat: this.eLat_long[0],
                  lng: this.eLat_long[1],
                  address: this.locationString,
               }

               if (this.jobDetails?.customer_type == "2") {
                  // this.customer_id = this.jobDetails?.customer_id?._id;
                  const customer = this.jobDetails?.multiple_customers_info?.find(
                     (customer) => customer.email == this.jobDetails?.customer_email && customer?.customer_name == this.jobDetails?.customer_name
                  );
                  if (customer) {
                     this.customer_id = customer?._id;
                     this.getAddressClient(customer?._id, this.eLat_long[0], this.eLat_long[1]);
                  }
                  this.isDisabledInputLocation = true;

               } else {
                  this.customer_id = '';
                  this.isDisabledInputLocation = false;
               }

               // var str = this.jobDetails.job_date;
               var str = moment().format("YYYY-MM-DD");
               var spl = str.split("T");
               var newSpl = spl[0].split("-");
               var year = parseInt(newSpl[0]);
               var month = parseInt(newSpl[1]);
               var day = parseInt(newSpl[2]);
               if (this.jobDetails.delivery_type == "1") {
                  // if urgent
                  this.dog = "1";
                  this.urgentCharges = this.jobDetails.ride_fare;
                  this.tax = Number(this.jobDetails.tax);
                  this.processing_fee = Number(
                     this.jobDetails.processingFee
                  );
                  this.driver_charges_urgent =
                     Number(this.jobDetails.ride_fare_without_tax) +
                     Number(this.jobDetails.processingFee); // ADD PROCESSING FEE IN RIDE CHARGES BCOZ RIDE FARE - PROCESSING FEE FROM API(PROCESSING FEE CHARGES FROM DRIVER SIDE)
                  this.urgentNightCharge = Number(
                     this.jobDetails.night_charge
                  );
                  this.weekend_charge_urgent = Number(
                     this.jobDetails.weekend_charge
                  );
                  this.getNormalPrice(this.eLat_long);
               } else {
                  this.dog = "2";
                  this.normalCharges = this.jobDetails.ride_fare;
                  this.tax = Number(this.jobDetails.tax);
                  this.processing_fee = Number(
                     this.jobDetails.processingFee
                  );
                  this.driver_charges_normal =
                     Number(this.jobDetails.ride_fare_without_tax) +
                     Number(this.jobDetails.processingFee); // ADD PROCESSING FEE IN RIDE CHARGES BCOZ RIDE FARE - PROCESSING FEE FROM API(PROCESSING FEE CHARGES FROM DRIVER SIDE)
                  this.normalNightCharge = Number(
                     this.jobDetails.night_charge
                  );
                  this.weekend_charge_normal = Number(
                     this.jobDetails.weekend_charge
                  );
                  this.getUrgentPrice(this.eLat_long);
               }

               this.jobDate = {
                  month: month,
                  day: day,
                  year: year,
               };

               this.customer_name = {
                  customer_f_name: this.jobDetails.customer_f_name,
                  customer_l_name: this.jobDetails.customer_l_name,
                  customer_name: this.jobDetails.customer_name,
               };

               this.customer_L_name = {
                  customer_f_name: this.jobDetails.customer_f_name,
                  customer_l_name: this.jobDetails.customer_l_name,
                  customer_name: this.jobDetails.customer_name,
               }

               this.delivery_type = this.jobDetails.delivery_type;
               this.iban_no = this.jobDetails.customer_phone;
               this.customer_email = this.jobDetails.customer_email;
               this.appartment_no = this.jobDetails.customer_appartment;
               this.door_code = this.jobDetails.customer_door_code;
               this.note = this.jobDetails.note;
               this.item_price = this.jobDetails.job_amount;
               this.item_price1 = this.jobDetails.job_amount;

               this.connectedDispatches = data.connectedDispatches;

               if (
                  (this.jobDetails.jobFor == "2" || this.jobDetails.jobFor == "3") &&
                  this.jobDetails.selectedDispatchForJob.length
               ) {
                  //DISPATCH SELECTED

                  this.selectedDispatchForJob =
                     this.jobDetails.selectedDispatchForJob;
               } else {
                  this.username = data.result?.base_job_values?.selected_drivers;
                  if (this.username.length) {
                     this.onSelectAll1(this.username);
                  }
               }

               if (this.item_price == "0") {
                  this.is_edit = "false";
                  this.is_checked = "true";
               }
               this.selected_customer = parseInt(
                  this.jobDetails.customer_type
               );
               this.customer_type = this.selected_customer;

               if (
                  this.jobDetails.packageObj &&
                  this.customer_type == "4"
               ) {
                  this.packageObj = this.jobDetails.packageObj;
               }

               this.jobFor = this.jobDetails.jobFor;
               this.distance = this.jobDetails.estimate_distance;
               this.establishment_email =
                  this.jobDetails.establishment_email;
               this.establishment_phone =
                  this.jobDetails.establishment_phone;

               if (
                  this.jobDetails.jobTimingObj &&
                  this.jobDetails.jobTimingObj.type &&
                  Number(this.jobDetails.jobTimingObj.type)
               ) {
                  this.jobTimingObj = {
                     type: Number(this.jobDetails.jobTimingObj.type),
                     startTime: {
                        hour: Number(
                           this.jobDetails.jobTimingObj.startTime.hour
                        ),
                        minute: Number(
                           this.jobDetails.jobTimingObj.startTime
                              .minute
                        ),
                     },
                     endTime: {
                        hour: Number(
                           this.jobDetails.jobTimingObj.endTime.hour
                        ),
                        minute: Number(
                           this.jobDetails.jobTimingObj.endTime.minute
                        ),
                     },
                     nameEn: this.jobDetails.jobTimingObj.nameEn,
                     nameFr: this.jobDetails.jobTimingObj.nameFr,
                  };
               }

               if (
                  this.jobDetails.attachedDocument &&
                  this.jobDetails.attachedDocument.url &&
                  this.jobDetails.attachedDocument.docName
               ) {
                  this.attachedDocument =
                     this.jobDetails.attachedDocument;
               }

               if (this.customerTypeSelection != 2) {
                  // display first name ,last name
                  // this.customer_L_name = this.jobDetails.customer_l_name;
                  this.customer_L_name = {
                     customer_f_name: this.jobDetails.customer_f_name,
                     customer_l_name: this.jobDetails.customer_l_name,
                     customer_name: this.jobDetails.customer_name,
                  };
                  this.customer_name = {
                     customer_f_name: this.jobDetails.customer_f_name,
                     customer_l_name: this.jobDetails.customer_l_name,
                     customer_name: this.jobDetails.customer_name,
                  };
                  this.establishment_name =
                     this.jobDetails.establishment_id;
                  if (this.establishment_name) {
                     this.establishment_id =
                        this.jobDetails.establishment_id._id;
                     this.establishment_search_id =
                        this.jobDetails.establishment_id._id;
                  }
               } else {
                  this.establishment_name =
                     this.jobDetails.establishment_id;
                  this.establishment_id =
                     this.jobDetails.establishment_id._id;
                  this.establishment_search_id =
                     this.jobDetails.establishment_id._id;
               }
               if (
                  this.jobDetails.jobFor == "2" &&
                  !this.jobDetails.selectedDispatchForJob.length
               ) {
                  // job is private
                  this.fish = 2;
                  this.isPrivate = "2";
               } else if (
                  (this.jobDetails.jobFor == "3" || this.jobDetails.jobFor == "2") &&
                  this.jobDetails.selectedDispatchForJob.length
               ) {
                  // job is Dispatch
                  this.fish = 3;
                  this.isPrivate = "3";
                  this.isDispatchSelected = true;
               } else {
                  // job is public
                  this.fish = 1;
                  this.isPrivate = "";
               }

               this.spinner.hide();
            } else {
               this.spinner.hide();
               console.log(data); this.toastr.error("error", data.message);
               return;
            }
         }, err => {
            this.spinner.hide();
            this.toastr.error("error", err?.message);
            return;
         });
   }

   //EDIT JOB FUNCTION START HERE
   editJob(type) {
      const selectedDate = this.ngbDateParserFormatter.format(this.jobDate);
      const format = /[^a-zA-Z0-9-_ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;

      if (this.customer_L_name && typeof this.customer_L_name == "object") {
         var last_name = this.customer_L_name.customer_l_name;
      } else {
         var last_name = this.customer_L_name;
      }

      if (this.customer_type != 2) {
         // FOR ESTABLISHMENT
         if (!this.establishment_name) {
            this.toastr.error("", "Establishment name required!");
            return;
         }

         let establishmentName =
            this.establishment_name &&
               typeof this.establishment_name == "object"
               ? this.establishment_name.establishment_name
               : this.establishment_name;

         if (!establishmentName) {
            this.toastr.error("", "Establishment name required!");
            return;
         }

         if (establishmentName) {
            let testing = format.test(establishmentName);
            if (testing == true) {
               this.toastr.error("", "Establishment name not valid!");
               return;
            }
         }

         if (establishmentName.length > 30) {
            this.toastr.error("", "Establishment name too long!");
            return;
         }

         this.establishment_name1 = establishmentName;

         if (
            this.establishment_phone &&
            this.establishment_phone.length != 12
         ) {
            this.toastr.error(
               "",
               "Establishment phone number must be 10 digits!"
            );
            return;
         }

         if (
            this.customerTypeSelection != 2 &&
            this.establishmentTagSelection == "0"
         ) {
            let customerFirstName =
               this.customer_name && typeof this.customer_name == "object"
                  ? this.customer_name.customer_f_name
                  : this.customer_name;

            if (customerFirstName || this.iban_no || last_name) {
               if (!customerFirstName) {
                  this.toastr.error("", "Customer first name required!");
                  return;
               }

               let testing = format.test(customerFirstName);
               if (testing == true) {
                  this.toastr.error("", "Customer first name not valid!");
                  return;
               }

               if (customerFirstName.length > 30) {
                  this.toastr.error("", "Customer first name too long!");
                  return;
               }

               if (!last_name) {
                  this.toastr.error("", "Customer last name required!");
                  return;
               }

               this.customer_name1 = customerFirstName;

               if (!this.iban_no) {
                  this.toastr.error(
                     "",
                     "Customer phone number required!"
                  );
                  return;
               }

               if (this.iban_no.length != 12) {
                  this.toastr.error(
                     "",
                     "Customer phone number must 10 digits!"
                  );
                  return;
               }
            }
         }
      }

      if (this.customer_type == 2) {
         // FOR CLIENT

         if (!this.customer_name) {
            this.toastr.error("", "Customer first name required!");
            return;
         }

         let customerFirstName =
            this.customer_name && typeof this.customer_name == "object"
               ? this.customer_name.customer_f_name
               : this.customer_name;

         if (!customerFirstName) {
            this.toastr.error("", "Customer first name required!");
            return;
         }

         let testing = format.test(customerFirstName);
         if (testing == true) {
            this.toastr.error("", "Customer first name not valid!");
            return;
         }

         if (customerFirstName.length > 30) {
            this.toastr.error("", "Customer first name too long!");
            return;
         }
         if (!last_name) {
            this.toastr.error("", "Customer last name required!");
            return;
         }
         this.customer_name1 = customerFirstName;

         if (!this.iban_no) {
            this.toastr.error("", "Customer phone number required!");
            return;
         }

         if (this.iban_no.length != 12) {
            this.toastr.error("", "Customer phone number must 10 digits!");
            return;
         }
      }

      // if (this.customer_type == 2 && !this.customer_email) {
      //    this.toastr.error('', 'Customer email required!');
      //    return;
      // }

      // if (this.customer_type != 2 && !this.establishment_email) {
      //    this.toastr.error('', 'Establishment email required!');
      //    return;
      // }

      //email
      let emailPattern =
         /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (this.customer_email === '-') {
         this.customer_email = '';
      }

      if (this.customer_email) {
         let emailValid = emailPattern.test(
            String(this.customer_email).toLowerCase()
         );
         if (emailValid == false) {
            this.toastr.error("", "Customer email not valid");
            return;
         }
      }

      if (this.establishment_email === '-') {
         this.establishment_email = '';
      }

      if (this.establishment_email) {
         let emailValid = emailPattern.test(
            String(this.establishment_email).toLowerCase()
         );
         if (emailValid == false) {
            this.toastr.error("", "Establishment email not valid");
            return;
         }
      }

      if (!this.location1 || !this.eLat_long) {
         this.toastr.error("", "Location is required!");
         return;
      }
      if (!this.locationString) {
         this.toastr.error("", "Location is required!");
         return;
      }

      if (this.customer_type == 4) {
         // CPO SELECTED
         this.item_price = "0";
      }

      if (this.item_price) {
         const priz = /^[0-9]*(\.[0-9]{0,2})?$/;
         var tst = priz.test(this.item_price);
         if (tst == false) {
            this.toastr.error("", "Item price not valid!");
            return;
         }
      }

      if (
         this.customerTypeSelection == 2 &&
         !this.multiple_customers_id.length
      ) {
         return this.toastr.error("", "Please select one client");
      }

      if (!selectedDate) {
         this.toastr.error("", "Job date is required!");
         return;
      }
      var timingTagStatus = false;
      for (var tag of this.tags) {
         if (
            tag.tag_type == "9" ||
            tag.tag_type == "10" ||
            tag.tag_type == "11" ||
            tag.tag_type == "12"
         ) {
            timingTagStatus = true;
         }
      }
      if (timingTagStatus == false && this.jobFor == "1") {
         this.toastr.error("", "Please select a job timing tag");
         return;
      }

      if (!this.job_recurring_type) {
         this.toastr.error("", "Please select job recurring type");
         return;
      }
      if (
         (this.job_recurring_type == 2 ||
            this.job_recurring_type == 3 ||
            this.job_recurring_type == 4) &&
         !this.timeForAutoJob
      ) {
         this.toastr.error("", "Please select job recurring timing");
         return;
      }
      if (
         this.job_recurring_type == 2 ||
         this.job_recurring_type == 3 ||
         this.job_recurring_type == 4
      ) {
         var dailyDate = selectedDate;
         var dtime = {
            hours: this.timeForAutoJob.hour.toString(),
            min: this.timeForAutoJob.minute.toString(),
         };
      }
      if (this.job_recurring_type == 3 && this.weekDays.length == 0) {
         this.toastr.error("", "Please select day for weekely recurring");
         return;
      }
      if (this.job_recurring_type == 4 && !selectedDate) {
         this.toastr.error("", "Please choose date for monthly recurring");
         return;
      }

      var daysArray = [];
      if (this.weekDays.length) {
         for (var day of this.weekDays) {
            if (day.item_id == 1) {
               var mon = {
                  item_id: day.item_id,
                  item_text: "Monday",
                  item_text_french: "Lundi",
               };
               daysArray.push(mon);
            } else if (day.item_id == 2) {
               var tue = {
                  item_id: day.item_id,
                  item_text: "Tuesday",
                  item_text_french: "Mardi",
               };
               daysArray.push(tue);
            } else if (day.item_id == 3) {
               var wed = {
                  item_id: day.item_id,
                  item_text: "Wednesday",
                  item_text_french: "Mercredi",
               };
               daysArray.push(wed);
            } else if (day.item_id == 4) {
               var thu = {
                  item_id: day.item_id,
                  item_text: "Thursday",
                  item_text_french: "Jeudi",
               };
               daysArray.push(thu);
            } else if (day.item_id == 5) {
               var fri = {
                  item_id: day.item_id,
                  item_text: "Friday",
                  item_text_french: "Vendredi",
               };
               daysArray.push(fri);
            } else if (day.item_id == 6) {
               var sat = {
                  item_id: day.item_id,
                  item_text: "Saturday",
                  item_text_french: "Samedi",
               };
               daysArray.push(sat);
            } else if (day.item_id == 0) {
               var sun = {
                  item_id: day.item_id,
                  item_text: "Sunday",
                  item_text_french: "Dimanche",
               };
               daysArray.push(sun);
            }
         }
      }
      var weekDayz = daysArray;

      var night_charge = 0;
      var weekendCharge = 0;
      if (this.delivery_type == "1") {
         // for urgrnt delivery
         this.ride_fare = this.urgentCharges;
         this.ride_fare_without_tax = this.driver_charges_urgent;
         night_charge = this.urgentNightCharge;
         weekendCharge = this.weekend_charge_urgent;
         this.other_charges = this.normalCharges;
      } else if (this.delivery_type == "2") {
         // for normal delivery
         this.ride_fare = this.normalCharges;
         this.ride_fare_without_tax = this.driver_charges_normal;
         night_charge = this.normalNightCharge;
         weekendCharge = this.weekend_charge_normal;
         this.other_charges = this.urgentCharges;
      } else if (this.delivery_type == "3") {
         // for next_day delivery
         this.ride_fare = 0;
         this.ride_fare_without_tax = 0;
         night_charge = 0;
         weekendCharge = 0;
         this.other_charges = 0;
      } else {
         this.toastr.error("error", "something wrong");
         return;
      }
      var pickupLocation;
      var dropLocation;
      var pickupLatLong;
      var dropLatlong;

      if (this.jobDetails.job_type == "1") {
         // job for delivery
         pickupLocation = this.pharmacy_address;
         pickupLatLong = this.pharmacy_latLong;
         dropLocation = this.location1;
         dropLatlong = this.eLat_long;
      } else {
         // job for pickup
         pickupLocation = this.location1;
         pickupLatLong = this.eLat_long;
         dropLocation = this.pharmacy_address;
         dropLatlong = this.pharmacy_latLong;
      }

      //LOOP TO ADD OBJECT INSTED OF HIS ID

      this.multiple_customers = this.multiple_customers_id;
      this.multiple_customers_info = [];
      for (var client of this.multiple_customers_id) {
         for (const clientObj of this.residenceAllClients) {
            if (client == clientObj._id) {
               this.multiple_customers_info.push(clientObj);
            }
         }
      }

      var monthlyDate = moment(selectedDate, "YYYY/MM/DD")
         .add(1, "months")
         .format("YYYY-MM-DD");
      var selectedDispatchForJobIds = [];

      // FOR RETRY FUNCTIONALITY START
      if (this.jobFor == "2" || this.jobFor == "3") {
         if (this.jobFor == "2" && !this.isDispatchSelected) {
            if (!this.selected_drivers.length) {
               return this.toastr.error("error", "Please select a driver");
            } else {
               if (this.selected_drivers.length > 3) {
                  return this.toastr.error(
                     "error",
                     `You can't select more than 3 drivers`
                  );
               }
            }

            this.selectedDispatchForJob = [];
            selectedDispatchForJobIds = [];
         } else if ((this.jobFor == "2" || this.jobFor == "3") && this.isDispatchSelected) {
            if (!this.selectedDispatchForJob.length) {
               return this.toastr.error("", "Please select a dispatch");
            }
            if (this.selectedDispatchForJob.length > 1) {
               return this.toastr.error(
                  "",
                  `You can't select more than 1 dispatch`
               );
            }

            this.selected_drivers = [];
            this.selectedDispatchForJob.map((element) =>
               selectedDispatchForJobIds.push(element._id)
            );
         } else {
            this.selected_drivers = [];
            this.selectedDispatchForJob = [];
            selectedDispatchForJobIds = [];
         }
      } else {
         this.selected_drivers = [];
         this.selectedDispatchForJob = [];
         selectedDispatchForJobIds = [];
      }



      if (this.fish == 1 || this.fish == 2) {
         // PUBLIC/PRIVATE
         // this.dispatchCompanyId = null;
         this.selectedDispatchForJob = [];
         selectedDispatchForJobIds = [];
      }

      // FOR RETRY FUNCTIONALITY END

      if (this.fish == 2 || this.fish == 3) {
         // FOR PRIVATE/ DISPATCH JOB TAGSPRICES 0
         this.totalTagsPrices = 0;
      }
      if (this.customer_type != "4") {
         this.packageObj = {
            package: "0",
            small: "0",
            large: "0",
            extraLarge: "0",
            label: "0/0",
            weight: "0",
         };
      }
      if (this.jobTimingObj.type == 3) {
         // BETWEEN SELCECTED
         if (
            this.jobTimingObj.startTime.hour >
            this.jobTimingObj.endTime.hour ||
            (this.jobTimingObj.startTime.hour ==
               this.jobTimingObj.endTime.hour &&
               this.jobTimingObj.startTime.minute >
               this.jobTimingObj.endTime.minute)
         ) {
            return this.toastr.error("", `Invalid time range`);
         }
      }

      const customer_email_aux = this.customer_type == 2 && !this.customer_email ? 'default@customer.com' : this.customer_email;
      const establishment_email_aux = this.customer_type != 2 && !this.establishment_email ? 'default@establishment.com' : this.establishment_email;

      this.spinner.show();

      const data = {
         pickupLocation,
         dropLocation,
         pickupLatLong,
         dropLatlong,
         selectedDate,
         customer_email_aux,
         night_charge,
         weekendCharge,
         dailyDate,
         dtime,
         weekDayz,
         monthlyDate,
         last_name,
         type,
         selectedDispatchForJobIds,
         establishment_email_aux
      }
      if (this.recursive_id) {

         this.editRecursiveManagementJob(data);
      } else {
         this.editNonRecursiveManagementJob(data);
      }

   }
   editNonRecursiveManagementJob(data) {
      this.services
         .edit_job(
            this.user_id,
            this.job_id,
            data.pickupLocation,
            data.dropLocation,
            this.customer_name1,
            this.iban_no,
            this.note,
            this.customer_note,
            this.customer_parent,
            this.delivery_type,
            this.selected_customer,
            data.pickupLatLong,
            data.dropLatlong,
            this.overallTax,
            this.ride_fare.toString(),
            this.distance,
            this.job_from,
            this.item_price,
            this.jobDetails.job_type,
            data.selectedDate,
            this.tags,
            this.tax,
            this.processing_fee,
            this.ride_fare_without_tax.toString(),
            data.customer_email_aux,
            this.appartment_no,
            this.door_code,
            this.dial_code,
            this.other_charges,
            data.night_charge,
            data.weekendCharge,
            this.job_recurring_type,
            data.dailyDate,
            data.dtime,
            data.weekDayz,
            data.monthlyDate,
            this.dailyJobType,
            this.customer_name1,
            data.last_name,
            this.establishment_name1,
            this.selected_drivers,
            this.adminTax,
            this.stateProcessingFees,
            this.state_tax_id,
            this.multiple_customers,
            this.customerTypeSelection,
            this.multiple_customers_info,
            this.establishmentTagSelection,
            this.isPrivate,
            this.edit_all_recursive_jobs,
            this.totalTagsPrices,
            null,
            data.type,
            data.selectedDispatchForJobIds,
            this.stateTaxObj,
            data.establishment_email_aux,
            this.packageObj,
            this.jobTimingObj,
            this.attachedDocument,
            this.establishment_phone,
            this.isRoundTrip
         )
         .pipe()
         .subscribe((data) => {
            if (data.code == 400) {

               this.spinner.hide();
               this.toastr.error("", data.message);
               this.commonMethods.logout();
               return;
            }
            if (data.code == 200) {
               if (this.selectedFile) {
                  this.uploadFile(data.result);
               }
               this.spinner.hide();
               this.parentModalRef.hide();
               this.toastr.success("Success");
               return;
            } else {
               this.spinner.hide();
               this.toastr.error("error", data.message);
               return;
            }
         });
   }
   editRecursiveManagementJob(data) {
      let recursiveEndDate = this.recursiveEndDate ? this.ngbDateParserFormatter.format(this.recursiveEndDate) : '';

      this.services
         .editJobRecursiveV2(
            {
               user_id: this.user_id,
               recursive_id: this.recursive_id,
               continuous: this.isContinuousJob, // falta agregar campo
               recursiveJobEndDate: recursiveEndDate, // falta agregar campo
               pick_up_location: data.pickupLocation,
               drop_off_location: data.dropLocation,
               customer_name: this.customer_name1,
               customer_phone: this.iban_no,
               note: this.note,
               delivery_type: this.delivery_type,
               customer_type: this.selected_customer,
               pickup_lat_long: data.pickupLatLong,
               dropoff_lat_long: data.dropLatlong,
               taxes: this.overallTax,
               ride_fare: this.ride_fare.toString(),
               distance: this.distance,
               job_from: this.job_from,
               item_price: this.item_price,
               job_type: this.jobDetails.job_type,
               job_date: data.selectedDate,
               job_tags: this.tags,
               tax: this.tax,
               processing_fee: this.processing_fee,
               ride_fare_without_tax: this.ride_fare_without_tax.toString(),
               customer_email: data.customer_email_aux,
               customer_appartment: this.appartment_no,
               customer_door_code: this.door_code,
               customer_country_code: this.dial_code,
               other_fare: this.other_charges,
               night_charge: data.night_charge,
               weekend_charge: data.weekendCharge,
               repeatType: this.job_recurring_type,
               // dailyDate : dailyDate, // not obligatory
               // time: dtime, // not obligatory
               day: data.weekDayz,
               monthlyDate: data.monthlyDate,
               dailyType: this.dailyJobType,
               customer_f_name: this.customer_name1,
               customer_l_name: data.last_name,
               establishment_name: this.establishment_name1,
               selected_drivers: this.selected_drivers,
               adminTax: this.adminTax,
               stateProcessingFees: this.stateProcessingFees, // not obligatory
               state_tax_id: this.state_tax_id,
               multiple_customers: this.multiple_customers,
               customerTypeSelection: this.customerTypeSelection,
               multiple_customers_info: this.multiple_customers_info,
               establishmentTagSelection: this.establishmentTagSelection,
               isPrivate: this.isPrivate,
               jobFor: this.isPrivate === '' ? '1' : this.isPrivate,
               // edit_all_recursive_jobs: this.edit_all_recursive_jobs, // not obligatory
               totalTagsPrices: this.totalTagsPrices,
               dispatchCompanyId: null,
               editType: data.type, // not obligatory
               selectedDispatchForJob: data.selectedDispatchForJobIds,
               stateTaxObj: this.stateTaxObj,
               establishment_email: data.establishment_email_aux,
               packageObj: this.packageObj,
               jobTimingObj: this.jobTimingObj,
               attachedDocument: this.attachedDocument,
               establishment_phone: this.establishment_phone,
               isRoundTrip: this.isRoundTrip,
               ...this.recursiveJobType
            }
         )
         .pipe()
         .subscribe((data) => {
            if (data.code == 400) {

               this.spinner.hide();
               this.toastr.error("", data.message);
               this.commonMethods.logout();
               return;
            }
            if (data.code == 200) {
               if (this.selectedFile) {
                  this.uploadFile(data.result._id, true);
               }
               this.spinner.hide();
               this.toastr.success("Success edit job recursive.");
               this.parentModalRef.hide();
               return;
            } else {
               this.spinner.hide();
               console.log(data); this.toastr.error("error", data.message);
               return;
            }
         });
   }

   mychange(val) {
      const self = this;
      let chIbn = val.split("-").join("");
      if (chIbn.length > 0) {
         chIbn = chIbn.match(/\d{3}(?=\d{2,3})|\d+/g).join("-");
      }

      this.iban_no = chIbn;
   }

   onSelect(event) {
      this.customer_id = event?._id;
      this.isDisabledInputLocation = true;

      this.customer_name = event;
      this.iban_no = event.phone_no;
      this.customer_email = event.email;
      this.appartment_no = event.appartment_no;
      this.door_code = event.door_code;
      // this.customer_L_name = event.customer_l_name;
      this.customer_L_name = event;
      this.multiple_customers_id.push(event._id);

      if (event.location && event.lat_long.length) {
         this.addressForNewUser(event.lat_long, event.location);
         this.locationData = {
            lat: event.lat_long[0],
            lng: event.lat_long[1],
            address: event.location,
         }
      } else {
         this.locationData = {};
      }

      if (event.establishment_id && this.customer_type == 2) {
         this.isDisabledInputLocation = true;
      }

      if (this.customer_type == '2') { // type client
         this.getAddressClient(event?._id)
      }

   }

   //ON SELECT JOB TAG
   onSelectTag(type, type1) {
      if (type == this.lastTypeSelected) {
         return console.log(`last type selected ${type}`);
      }
      this.lastTypeSelected = type;
      if (type == '16') {
         this.establishmentTagSelection = '1';
      } else if (type == '17') {
         this.establishmentTagSelection = '2';
      } else {
         this.establishmentTagSelection = '0';
      }

      this.selected_type = type;
      for (const tag of this.job_tags) {
         if (type == tag.tag_type) {
            if (tag.tag_price) {
               // ADD TAG PRICE
               this.totalTagsPrices += tag.tag_price;
            }
            tag.running = false;
            this.tags.push(tag);
         }
      }

      if (Array.isArray(type1)) {
         type1.forEach(element => {
            this.unselectTag(element)
         })
      } else {
         this.unselectTag(type1)
      }

      this.customer_L_name = "";
      this.customer_name = "";
      this.iban_no = "";
      this.customer_email = "";
      this.appartment_no = "";
      this.door_code = "";
      let selectedTag = this.tags.find((obj) => obj.tag_type == type);
      if (
         this.eLat_long &&
         this.eLat_long.length &&
         this.location1 &&
         selectedTag?.tag_price &&
         this.fish == 1
      ) {
         // this.customerDelivery(this.eLat_long, this.location1, this.dateForJob)
         this.addressForNewUser(this.eLat_long, this.location1);
      }
   }

   unselectTag(tag_type) {
      let obj = this.tags.find(obj => obj.tag_type == tag_type);

      if (obj) {
         if (obj.tag_price) {// REMOVE TAG PRICE
            this.totalTagsPrices -= obj.tag_price;
         }
         obj.running = true;// TAG UNSELECTED
         var index = this.tags.indexOf(obj);
         obj.default_key = "1";
         this.tags.splice(index, 1);
      }

   }

   onChangeIsContinuous(event) {
      this.isContinuousJob = !this.isContinuousJob;
      this.recursiveEndDate = null;
   }

   //ON ESTABLISHMENT SELECT
   onSelectEstablish(event) {
      this.customer_id = '';
      this.isDisabledInputLocation = false;

      if (event.location && event.lat_long.length) {
         this.addressForNewUser(event.lat_long, event.location);
         this.locationData = {
            lat: event.lat_long[0],
            lng: event.lat_long[1],
            address: event.location,
         }
      } else {
         this.locationData = {};
      }
      this.establishment_id = event._id;
      this.establishment_search_id = event._id;

      this.establishment_email = event.establishment_email;
      this.establishment_phone = event.establishment_phone;

      this.search_customer("");
   }

   onChangeCategory(event, tag: any) {
      // Use appropriate model type instead of any

      if (tag.running == false) {
         // if tag selected
         if (tag.tag_type == "3") {
            this.dog = '1';
            this.delivery_type = "1";
         }
         tag.default_key = "2";
         if (tag.tag_type == "9") {
            var pos1 = this.tags
               .map(function (e) {
                  return e.tag_type;
               })
               .indexOf("10");
            var pos2 = this.tags
               .map(function (e) {
                  return e.tag_type;
               })
               .indexOf("11");
            var pos3 = this.tags
               .map(function (e) {
                  return e.tag_type;
               })
               .indexOf("12");
            if (pos1 > -1) {
               this.tags[pos1].running = true; // makes unselected
               this.tags[pos1].default_key = "1";
               this.tags.splice(pos1, 1);
            }
            if (pos2 > -1) {
               this.tags[pos2].running = true; // makes unselected
               this.tags[pos2].default_key = "1";
               this.tags.splice(pos2, 1);
            }
            if (pos3 > -1) {
               this.tags[pos3].running = true; // makes unselected
               this.tags[pos3].default_key = "1";
               this.tags.splice(pos3, 1);
            }
            if (this.eLat_long && this.eLat_long.length && this.location1) {
               this.addressForNewUser(this.eLat_long, this.location1);
            }
         }
         if (tag.tag_type == "10") {
            var pos1 = this.tags
               .map(function (e) {
                  return e.tag_type;
               })
               .indexOf("9");
            var pos2 = this.tags
               .map(function (e) {
                  return e.tag_type;
               })
               .indexOf("11");
            var pos3 = this.tags
               .map(function (e) {
                  return e.tag_type;
               })
               .indexOf("12");
            if (pos1 > -1) {
               this.tags[pos1].running = true; // makes unselected
               this.tags[pos1].default_key = "1";
               this.tags.splice(pos1, 1);
            }
            if (pos2 > -1) {
               this.tags[pos2].running = true; // makes unselected
               this.tags[pos2].default_key = "1";
               this.tags.splice(pos2, 1);
            }
            if (pos3 > -1) {
               this.tags[pos3].running = true; // makes unselected
               this.tags[pos3].default_key = "1";
               this.tags.splice(pos3, 1);
            }
            if (this.eLat_long && this.eLat_long.length && this.location1) {
               this.addressForNewUser(this.eLat_long, this.location1);
            }
         }
         if (tag.tag_type == "11") {
            var pos1 = this.tags
               .map(function (e) {
                  return e.tag_type;
               })
               .indexOf("9");
            var pos2 = this.tags
               .map(function (e) {
                  return e.tag_type;
               })
               .indexOf("10");
            var pos3 = this.tags
               .map(function (e) {
                  return e.tag_type;
               })
               .indexOf("12");
            if (pos1 > -1) {
               this.tags[pos1].running = true; // makes unselected
               this.tags[pos1].default_key = "1";
               this.tags.splice(pos1, 1);
            }
            if (pos2 > -1) {
               this.tags[pos2].running = true; // makes unselected
               this.tags[pos2].default_key = "1";
               this.tags.splice(pos2, 1);
            }
            if (pos3 > -1) {
               this.tags[pos3].running = true; // makes unselected
               this.tags[pos3].default_key = "1";
               this.tags.splice(pos3, 1);
            }
            if (this.eLat_long && this.eLat_long.length && this.location1) {
               this.addressForNewUser(this.eLat_long, this.location1);
            }
         }
         if (tag.tag_type == "12") {
            // night tag select
            var pos1 = this.tags
               .map(function (e) {
                  return e.tag_type;
               })
               .indexOf("9");
            var pos2 = this.tags
               .map(function (e) {
                  return e.tag_type;
               })
               .indexOf("10");
            var pos3 = this.tags
               .map(function (e) {
                  return e.tag_type;
               })
               .indexOf("11");
            if (pos1 > -1) {
               this.tags[pos1].running = true; // makes unselected
               this.tags[pos1].default_key = "1";
               this.tags.splice(pos1, 1);
            }
            if (pos2 > -1) {
               this.tags[pos2].running = true; // makes unselected
               this.tags[pos2].default_key = "1";
               this.tags.splice(pos2, 1);
            }
            if (pos3 > -1) {
               this.tags[pos3].running = true; // makes unselected
               this.tags[pos3].default_key = "1";
               this.tags.splice(pos3, 1);
            }

            if (
               this.eLat_long &&
               this.eLat_long.length &&
               this.location1 &&
               this.normalCharges &&
               this.urgentCharges
            ) {
               this.normalNightCharge = Math.round((this.original_driver_charges_normal * this.nightCharge - this.original_driver_charges_normal) * 100) / 100;

               this.urgentNightCharge = Math.round((this.original_driver_charges_normal * this.nightCharge - this.original_driver_charges_normal) * 100) / 100;

               this.calculateRoundTrip();
            }
         }

         if (
            tag.tag_price &&
            (tag.tag_type != "9" ||
               tag.tag_type != "10" ||
               tag.tag_type != "11" ||
               tag.tag_type != "12")
         ) {
            // ADD TAGS PRICE

            if (
               this.eLat_long &&
               this.eLat_long.length &&
               this.location1 &&
               this.normalCharges &&
               this.urgentCharges
            ) {
               this.driver_charges_normal =
                  Math.round(
                     (Number(this.driver_charges_normal) -
                        Number(this.weekend_charge_normal) +
                        Number(tag.tag_price)) *
                     100
                  ) / 100;

               this.driver_charges_urgent =
                  Math.round(
                     (Number(this.driver_charges_urgent) -
                        Number(this.weekend_charge_urgent) +
                        Number(tag.tag_price)) *
                     100
                  ) / 100;

               var totalTaxProcessing =
                  Number(this.adminTax) + Number(this.adminProcessingFee);
               var normalTax =
                  Math.round(
                     ((this.driver_charges_normal +
                        Number(this.weekend_charge_normal)) /
                        100) *
                     totalTaxProcessing *
                     100
                  ) / 100;
               var urgentTax =
                  Math.round(
                     ((this.driver_charges_urgent +
                        Number(this.weekend_charge_urgent)) /
                        100) *
                     totalTaxProcessing *
                     100
                  ) / 100;

               this.normalCharges =
                  Math.round(
                     (this.driver_charges_normal +
                        Number(this.weekend_charge_normal) +
                        normalTax) *
                     100
                  ) / 100;
               this.urgentCharges =
                  Math.round(
                     (this.driver_charges_urgent +
                        Number(this.weekend_charge_urgent) +
                        urgentTax) *
                     100
                  ) / 100;

               this.driver_charges_normal =
                  Math.round(
                     (this.driver_charges_normal +
                        Number(this.weekend_charge_normal)) *
                     100
                  ) / 100;
               this.driver_charges_urgent =
                  Math.round(
                     (this.driver_charges_urgent +
                        Number(this.weekend_charge_urgent)) *
                     100
                  ) / 100;
            }
         }
         if (tag.tag_price) {
            this.totalTagsPrices += tag.tag_price;
         }
         this.tags.push(tag);
      }
      if (tag.running == true) {
         // if tag unselected
         tag.default_key = "1";
         var index = this.tags.indexOf(tag);

         if (index > -1) {
            if (tag.tag_price) {
               this.totalTagsPrices -= tag.tag_price;
            }
            this.tags.splice(index, 1);
            if (tag.tag_type == "3") {
               this.dog = '2';
               this.delivery_type = "2";
            }
            if (
               tag.tag_type == "12" ||
               (tag.tag_price &&
                  (tag.tag_type != "9" ||
                     tag.tag_type != "10" ||
                     tag.tag_type != "11" ||
                     tag.tag_type != "12"))
            ) {
               // night unselected
               if (
                  this.eLat_long &&
                  this.eLat_long.length &&
                  this.location1 &&
                  this.fish == 1
               ) {
                  this.addressForNewUser(this.eLat_long, this.location1);
               }
            }
         }
      }
   }

   deliver_type(type) {
      this.delivery_type = type;
      if (type == "2" || type == "3") {
         // for normal
         let obj = this.tags.find((obj) => obj.tag_type == "3");
         if (obj) {
            obj.running = true;
            var index = this.tags.indexOf(obj);

            obj.default_key = "1";
            this.tags.splice(index, 1);
         }
      } else {
         let obj = this.tags.find((obj) => obj.tag_type == "3");
         if (!obj) {
            let newObj = this.job_tags.find((obj) => obj.tag_type == "3");
            if (newObj) {
               newObj.running = false;
               newObj.default_key = "2";
               this.tags.push(newObj);
            }
         }
      }
      if (this.eLat_long && this.eLat_long.length && this.location1) {
         this.addressForNewUser(this.eLat_long, this.location1);
      }
   }

   selectJobFor(type) {
      if (this.eLat_long && this.eLat_long.length && this.location1) {
         if (this.dateChangeStatus == true) {
            this.addressForNewUser(this.eLat_long, this.location1);
         } else {
            this.addressForNewUser(this.eLat_long, this.location1);
            // this.changeJobFor();
         }
      }

      if (type == "1") {
         // 1 FOR PUBLIC JOB
         this.isPrivate = "";
         this.jobFor = type;
         this.isDispatchSelected = false;
      }
      if (type == "2") {
         // 2 FOR PRIVATE JOB
         this.isPrivate = "2";
         this.jobFor = type;
         this.isDispatchSelected = false;
      }
      if (type == "3") {
         // 3 FOR DISPATCH JOB
         this.isPrivate = "3";
         this.jobFor = "2";
         this.isDispatchSelected = true;
      }
   }

   back() {
      if (this.jobDetails.job_status == "P") {
         this.router.navigate(["/new-jobs"]);
      } else if (this.jobDetails.job_status == "A") {
         this.router.navigate(["/active-jobs"]);
      } else {
         this.router.navigate(["/new-jobs"]);
      }
   }

   openModal1() {
      this.urgent_charge = Math.round(this.urgentCharges * 100) / 100;
      this.successModal1.show();
   }

   save1() {
      if (this.urgent_charge != 0 && !Number(this.urgent_charge)) {
         this.toastr.error("", "Entered amount not valid!");
         return;
      }

      var format = /^[0-9]+(.[0-9]{0,2})?$/;

      if (this.urgent_charge.toString().match(format)) {
         var taxPercentage = this.adminTax + this.adminProcessingFee;
         var enteredAmount = Number(this.urgent_charge);
         if (
            this.fish == 1 &&
            this.jobFor == "1" &&
            enteredAmount < Number(this.urgentCharges)
         ) {
            this.toastr.error(
               "error",
               "Amount can't be decreased for public job"
            );
            return;
         }
         var withoutWeekendCharge = enteredAmount;

         if (this.dateForJob) {
            var fullDate = this.dateForJob;
            var mTime = moment(fullDate, "YYYY-MM-DD");
            var selectedDate = moment(mTime);
            var day = selectedDate.day();
            if (day == 0) {
               enteredAmount = enteredAmount * this.weekendCharge;
               this.weekend_charge_urgent =
                  enteredAmount - withoutWeekendCharge;
            }
         }
         var totalTax = (enteredAmount / 100) * taxPercentage;
         var amountWithTax = enteredAmount + totalTax;

         this.driver_charges_urgent = enteredAmount;

         this.urgentCharges = Math.round(amountWithTax * 100) / 100;
         var nightTag = this.tags
            .map(function (e) {
               return e.tag_type;
            })
            .indexOf("12");
         if (nightTag > -1) {
            this.driver_charges_urgent =
               Math.round(
                  (Number(this.driver_charges_urgent) -
                     Number(this.weekend_charge_urgent)) *
                  Number(this.nightCharge) *
                  100
               ) / 100;
            var urgentTax =
               Math.round(
                  ((this.driver_charges_urgent +
                     Number(this.weekend_charge_urgent)) /
                     100) *
                  taxPercentage *
                  100
               ) / 100;
            this.urgentCharges =
               Math.round(
                  (this.driver_charges_urgent +
                     Number(this.weekend_charge_urgent) +
                     urgentTax) *
                  100
               ) / 100;

            this.urgentNightCharge =
               Math.round(
                  (this.driver_charges_urgent - withoutWeekendCharge) *
                  100
               ) / 100;

            this.driver_charges_urgent =
               Math.round(
                  (this.driver_charges_urgent +
                     Number(this.weekend_charge_urgent)) *
                  100
               ) / 100;
         }
         this.successModal1.hide();
      } else {
         this.toastr.error("", "Entered amount not valid!");
         return;
      }
   }

   openModal2() {
      this.normal_charge = Math.round(this.normalCharges * 100) / 100;
      this.successModal2.show();
   }

   save2() {
      if (this.normal_charge != 0 && !Number(this.normal_charge)) {
         this.toastr.error("", "Entered amount not valid!");
         return;
      }

      var format = /^[0-9]+(.[0-9]{0,2})?$/;
      if (this.normal_charge.toString().match(format)) {
         var taxPercentage = this.adminTax + this.adminProcessingFee;
         var enteredAmount = Number(this.normal_charge);

         if (
            this.fish == 1 &&
            this.jobFor == "1" &&
            enteredAmount < Number(this.normalCharges)
         ) {
            this.toastr.error(
               "error",
               "Amount can't be decreased for public job"
            );
            return;
         }
         var withoutWeekendCharge = enteredAmount;
         if (this.dateForJob) {
            var fullDate = this.dateForJob;
            var mTime = moment(fullDate, "YYYY-MM-DD");
            var selectedDate = moment(mTime);
            var day = selectedDate.day();
            if (day == 0) {
               enteredAmount = enteredAmount * this.weekendCharge;
               this.weekend_charge_normal =
                  enteredAmount - withoutWeekendCharge;
            }
         }
         var totalTax = (enteredAmount / 100) * taxPercentage;
         var amountWithTax = enteredAmount + totalTax;

         this.driver_charges_normal = enteredAmount;
         this.normalCharges = Math.round(amountWithTax * 100) / 100;
         var nightTag = this.tags
            .map(function (e) {
               return e.tag_type;
            })
            .indexOf("12");
         if (nightTag > -1) {
            this.driver_charges_normal =
               Math.round(
                  (Number(this.driver_charges_normal) -
                     Number(this.weekend_charge_normal)) *
                  Number(this.nightCharge) *
                  100
               ) / 100;

            var normalTax =
               Math.round(
                  ((this.driver_charges_normal +
                     Number(this.weekend_charge_normal)) /
                     100) *
                  taxPercentage *
                  100
               ) / 100;
            this.normalCharges =
               Math.round(
                  (this.driver_charges_normal +
                     Number(this.weekend_charge_normal) +
                     normalTax) *
                  100
               ) / 100;
            this.normalNightCharge =
               Math.round(
                  (this.driver_charges_normal - withoutWeekendCharge) *
                  100
               ) / 100;

            this.driver_charges_normal =
               Math.round(
                  (this.driver_charges_normal +
                     Number(this.weekend_charge_normal)) *
                  100
               ) / 100;
         }
         this.successModal2.hide();
      } else {
         this.toastr.error("", "Entered amount not valid!");
         return;
      }
   }

   search(event) {
      this.spinner.show();
      this.services
         .searchDriver(event.query, this.user_id)
         .pipe()
         .subscribe((data) => {
            if (data.code == 400) {
               this.spinner.hide();
               this.toastr.error("", data.message);
               this.commonMethods.logout();
               return;
            }
            if (data.code == 200) {
               this.drivers = data.result;
               this.spinner.hide();
            } else {
               this.toastr.error("error", data.message);
               this.spinner.hide();
               return;
            }
         });
   }

   onDateSelected(event) {
      this.spinner.show();
      var year = event.year;
      var month = event.month;
      var day = event.day;
      var fullDate = year + "-" + month + "-" + day;
      this.dateForJob = fullDate;
      this.spinner.show();
      this.services
         .getTimingJobTags(fullDate, this.user_id, this.customer_type)
         .pipe()
         .subscribe((data) => {
            if (data.code == 200) {
               this.job_tags = data.jobTags;
               this.tags = [];
               if (this.dog != '3') {
                  this.dog = '2';
                  this.delivery_type = "2";
               }
               this.dateChangeStatus = true;
               this.totalTagsPrices = 0;
               if (
                  this.eLat_long &&
                  this.eLat_long.length &&
                  this.location1
               ) {
                  this.addressForNewUser(this.eLat_long, this.location1);
               } else {
                  this.spinner.hide();
               }

               return;
            } else {
               this.toastr.error("error", data.message);
               this.spinner.hide();
               return;
            }
         });
   }

   public onItemSelect(item: any) {
      // console.log(item);
   }

   public onDeSelect(item: any) {
      // console.log(item);
   }

   public onSelectAll(items: any) {
      // console.log(items);
   }
   public onDeSelectAll(items: any) {
      // console.log(items);
   }

   public onItemSelect1(item: any) {
      this.selected_drivers.push(item._id);
   }

   onItemSelectDispatch(item: any) {
      // this.selected_drivers_dispatch.push(item._id)
      setTimeout(() => {
         // console.log("selectedDispatchForJob:", this.selectedDispatchForJob);
      }, 100);
      return;
   }

   public onDeSelect1(item: any) {
      let carIndex = this.selected_drivers.indexOf(item._id);
      this.selected_drivers.splice(carIndex, 1);
   }

   onDeSelectDispatch(item: any) {
      // let index = this.selected_drivers_dispatch.indexOf(item._id);
      // this.selected_drivers_dispatch.splice(index, 1);
      setTimeout(() => {
         // console.log("selectedDispatchForJob:", this.selectedDispatchForJob);
      }, 100);
      return;
   }

   public onSelectAll1(items: any) {
      this.selected_drivers = [];
      for (const data of items) {
         this.selected_drivers.push(data._id);
         data.username = `${data.fullname} (${data.username})`;
      }
   }

   onSelectAllDispatch(items: any) {
      // this.selected_drivers_dispatch = [];
      // for (const data of items) {
      //    this.selected_drivers_dispatch.push(data._id);
      //    data.selected_drivers_dispatch_username = `${data.fullname} (${data.username})`
      // }
      setTimeout(() => {
         // console.log("selectedDispatchForJob:", this.selectedDispatchForJob);
      }, 100);
      return;
   }

   public onDeSelectAll1(items: any) {
      this.selected_drivers = [];
   }

   onDeSelectAllDispatch(items: any) {
      // this.selected_drivers_dispatch = [];
      setTimeout(() => {
         // console.log("selectedDispatchForJob:", this.selectedDispatchForJob);
      }, 100);
      return;
   }

   //SELECT UNSELECT CLIENT
   public onItemSelect2(item: any) {
      this.multiple_customers_id.push(item._id);
   }

   public onDeSelect2(item: any) {
      var carIndex = this.multiple_customers_id.indexOf(item._id);
      this.multiple_customers_id.splice(carIndex, 1);
   }

   public onSelectAll2(items: any) {
      this.multiple_customers_id = [];
      for (const data of items) {
         this.multiple_customers_id.push(data._id);
      }
   }

   public onDeSelectAll2(items: any) {
      this.multiple_customers_id = [];
   }

   //SEARCH ESTABLISHMENT NAME FUNCTION START HERE
   searchEstablishmentDelivery(event) {
      this.customer_id = "";
      this.multiple_customers_id = [];
      this.residenceAllClient = [];
      this.residenceAllClients = [];
      var localData = JSON.parse(localStorage.getItem("currentUser"));
      if (event.length <= 0) {
         this.location1 = "";
         this.locationString = "";
         return console.log("less then 2");
      }
      this.spinner.show();

      this.services
         .searchEstablishment(
            localData._id,
            event,
            this.customer_type,
            this.customerTypeSelection
         )
         .pipe()
         .subscribe((data) => {
            if (data.code == 400) {
               this.spinner.hide();
               this.toastr.error("", data.message);
               this.commonMethods.logout();
               return;
            }
            if (data.code == 200) {
               this.establishments = data.result;
               this.spinner.hide();
            } else {
               this.toastr.error("error", data.message);
               this.spinner.hide();
               return;
            }
         });
   }

   // SEARCH CUSTOMER FUNCTION START HERE
   search_customer(event) {
      this.customer_id = "";

      var localData = JSON.parse(localStorage.getItem("currentUser"));
      this.isDisabledInputLocation = false;

      if (!this.establishment_id && this.customerTypeSelection == 2) {
         return this.toastr.error("", "Please enter establishment name");
      }

      if (event.length <= 0 && this.customerTypeSelection != 2) {
         return console.log("less then 2");
      }

      if (this.customer_type == 2) {
         this.establishment_id = "";
      } else {
         this.establishment_id = this.establishment_search_id;
      }

      this.spinner.show();
      this.services
         .searchCustomer(
            localData._id,
            event,
            this.establishment_id,
            this.customer_type
         )
         .pipe()
         .subscribe((data) => {
            if (data.code == 400) {
               this.spinner.hide();
               this.toastr.error("", data.message);
               this.commonMethods.logout();
               return;
            }
            if (data.code == 200) {
               this.residenceAllClient = [];
               this.residenceAllClients = data.result;
               if (this.residenceAllClients.length == 0) this.customerAddresses = [];

               for (const client of this.residenceAllClients) {
                  client.username = `${client.customer_f_name} ${client.customer_l_name} (${client.country_code} ${client.phone_no})`;
               }

               this.spinner.hide();
               return;
            } else {
               this.toastr.error("error", data.message);
               this.spinner.hide();
               return;
            }
         });
   }

   // GET ALL CUSTOMERS OF SELECTED ESTABLISHMENT
   getAllCustomersOfSelectedEstablishment(
      user_id,
      establishment_id,
      customer_type
   ) {
      this.services
         .searchCustomer(user_id, "", establishment_id, customer_type)
         .pipe()
         .subscribe((data) => {
            if (data.code == 400) {
               this.spinner.hide();
               this.toastr.error("", data.message);
               this.commonMethods.logout();
               return;
            }
            if (data.code == 200) {
               // this.residenceAllClient = [];
               this.residenceAllClients = data.result;
               // for (const client of this.residenceAllClients) {
               //    client.username = `${client.customer_f_name} ${client.customer_l_name} (${client.country_code} ${client.phone_no})`
               // }
               this.residenceAllClients.map(
                  (client) =>
                     (client.username = `${client.customer_f_name} ${client.customer_l_name} (${client.country_code} ${client.phone_no})`)
               );

               this.spinner.hide();
               return;
            } else {
               this.toastr.error("error", data.message);
               this.spinner.hide();
               return;
            }
         });
   }

   //CHANGE CUSTOMER TYPE SELECTION API START HERE
   changeCustomerType(type) {
      this.establishment_name = {};
      this.customer_L_name = "";
      this.customer_name = "";
      this.location1 = "";
      this.locationString = "";
      this.iban_no = "";
      this.customer_email = "";
      this.appartment_no = "";
      this.door_code = "";
      this.customer_type = type;
      this.selected_customer = type;
      this.customerTypeSelection = 1;
      this.selected_type = "0";
      this.establishment_email = "";
      this.establishment_phone = "";

      localStorage.setItem("edit_customer_type", JSON.stringify(type));
      if (this.customerTypeSelection == 1) {
         this.multiple_customers_id = [];
      }

      switch (this.establishmentTagSelection1) {
         case "1":
            var obj = this.tags.find((obj) => obj.tag_type == "16");
            if (obj) {
               obj.running = true;
               var index = this.tags.indexOf(obj);
               obj.default_key = "1";
               this.tags.splice(index, 1);
            }
            break;
         case "2":
            var obj = this.tags.find((obj) => obj.tag_type == "17");
            if (obj) {
               obj.running = true;
               var index = this.tags.indexOf(obj);
               obj.default_key = "1";
               this.tags.splice(index, 1);
            }
            break;

         default:
            break;
      }
      this.establishmentTagSelection = "0";
      this.lastTypeSelected = "0";
      this.packageObj = {
         package: "0",
         small: "0",
         large: "0",
         extraLarge: "0",
         label: "0/0",
         weight: "0",
      };
      this.customerAddresses = [];

      this.isDisabledInputLocation = false;
      this.customer_id = "";
      this.locationData = {};
   }

   getDriversClick() {
      this.services
         .searchAllDrivers(this.user_id)
         .pipe()
         .subscribe((data) => {
            if (data.code == 400) {
               this.spinner.hide();
               this.toastr.error("", data.message);
               this.commonMethods.logout();
               return;
            }
            if (data.code == 200) {
               this.drivers = data.result;

               this.drivers.map(
                  (driver) =>
                     (driver.username = `${driver.fullname} (${driver.username})`)
               );
            } else {
               return;
            }
         });
   }

   disableItemPrice() {
      if (this.isDisableItemPrice == 1) {
         this.is_edit = "false";
         this.isDisableItemPrice = 2;
         this.item_price = this.item_price1;
      } else {
         this.is_edit = "true";
         this.isDisableItemPrice = 1;
         this.item_price = "0";
      }
   }

   //CHANGE TYPE SELECTION  OF RESIDANCE
   changeCustomerTypeSelection(type) {
      this.customerTypeSelection = type;
      this.establishment_name = {};
      if (type == 1) {
         this.multiple_customers_id = [];
      }
      this.customer_L_name = "";
      this.customer_name = "";
      this.location1 = "";
      if (this.customerTypeSelection == 1) {
         this.multiple_customers_id = [];
      }
      this.residenceAllClients = [];
      this.residenceAllClient = [];
      this.location1 = "";
      this.locationString = "";
      this.iban_no = "";
      this.customer_email = "";
      this.appartment_no = "";
      this.door_code = "";
   }

   setLastName(event) {
      this.iban_no = event.phone_no;
      this.customer_email = event.email;
      this.appartment_no = event.appartment_no;
      this.door_code = event.door_code;
      // this.customer_L_name = event.customer_l_name;
      this.customer_name = event;
      this.customer_L_name = event;
      this.multiple_customers_id.push(event._id);

      if (event.location && event.lat_long.length) {
         this.addressForNewUser(event.lat_long, event.location);
      }

      if (event.establishment_id && this.customer_type == 2) {
         this.isDisabledInputLocation = true;
      }

      if (this.customer_type == '2') { // type client
         this.getAddressClient(event?._id)
      }
   }

   packageChange(event) {
      if (event) {
         this.packageObj.label = `${event}/${event}`;
      } else {
         this.packageObj.label = `0/0`;
      }
   }

   async onFileSelect(event) {

      if (event.target.files.length > 0) {
         const selectedFile = event.target.files[0];
         let size = selectedFile.size / 1000000;
         if (size > 10) {
            this.toastr.error('', "file size must be less than 10MB");
            this.spinner.hide();
            return;
         }

         this.attachedDocument.docName = selectedFile.name;

         this.selectedFile = selectedFile;

      }

   }

   async uploadFile(_id: string, isRecursiveManagement: boolean = false) {

      if (!this.selectedFile) {
         this.toastr.error('error', "file not found");
         this.spinner.hide();
         return;
      }
      this.spinner.show();


      try {
         const uploadData = await this.services.uploadFile({ file: this.selectedFile, _id: _id, fileName: "attachment_document", containerName: environment.AZURE_STORAGE_PHARMACY_CONTAINER_NAME }).toPromise();

         this.attachedDocument.url = uploadData?.url;
         if (this.attachedDocument.url && this.attachedDocument.url.length) {
            await this.services.updateAttachedDocumentUrl({ _id: _id, url: this.attachedDocument.url, docName: "attachment_document", isRecursiveManagement: isRecursiveManagement }).toPromise();
            this.spinner.hide();
         }
      } catch (error) {
         this.spinner.hide();
         this.attachedDocument.url = "";
         this.attachedDocument.docName = "";
         this.toastr.error('', "Something went wrong while uploading document.");

      }
   }


   cancelDocument() {
      this.attachedDocument.url = "";
      this.attachedDocument.docName = "";
   }

   establishmentPhoneChange(value) {
      let chIbn = value.split("-").join("");
      if (chIbn.length > 0) {
         chIbn = chIbn.match(/\d{3}(?=\d{2,3})|\d+/g).join("-");
      }
      this.establishment_phone = chIbn;
   }

   timerOnChange(newTime) {
      const arrayHour = this.hourUrgent.split(":");
      const hour = arrayHour[0];
      const minute = arrayHour[1];
      if (this.jobTimingObj.type === 1) {
         const hour = newTime.hour;
         const minute = newTime.minute;
         this.validHour(hour, minute, `dog`);

      }

   }
   timerOnChange2(newTime) {

      if (this.jobTimingObj.type === 3) {
         const arrayHour = this.hourUrgent.split(":");
         const hour = newTime.hour;
         const minute = newTime.minute;
         this.validHour(hour, minute, `dog`);

      }

   }

   validHour(hour, minute, type) {
      this.parameterService.getValidHour({ hour, minute }).subscribe((data) => {
         if (type === `dog`) {
            this.dog = !data.isAllowed ? '2' : '1';
         }
      })
   }

   getAddressClient(clientId: string, lat: number = null, long: number = null) {
      this.services.getAddressesClient(clientId).subscribe((data) => {
         if (data.code == 200) {
            this.customerAddresses = [{ default: false, address: 'New address', address_fr: 'Nouvelle adresse' }, ...data?.directions];
            let addressCustomer = null;
            if (lat && long) {
               addressCustomer = this.customerAddresses.find((address) => address?.latitude === lat && address?.longitude === long);
               if (!addressCustomer) {
                  this.selectedAddress.setValue({ default: false, address: 'New address', address_fr: 'Nouvelle adresse' });
                  // this.isDisabledInputLocation
               }
            } else {
               addressCustomer = this.customerAddresses.find((address) => address?.default);
            }

            if (addressCustomer) {
               this.selectedAddress.setValue(addressCustomer);
               this.selectedAddressAux = { addressCustomer }
               this.locationString = addressCustomer?.landmark;
               this.locationData = {
                  lat: addressCustomer?.latitude,
                  lng: addressCustomer?.longitude,
                  address: addressCustomer?.landmark
               };
            }
         } else {
            console.error('error in get address client');
         }
      }, (err) => {
         this.toastr.error(err?.message || 'Error in customer', 'Error');
      });
   }

   openModalSelectLocation() {
      const initialState = {
         content: this.locationBodyTemplate,
         header: this.locationHeaderTemplate


      };
      this.bsModalRef = this.bsModalService.show(ModalComponent, {
         initialState,
         animated: true,
         backdrop: "static",
         class: "modal-xl modal-dialog-centered",
      });
   }

   get locationdataString() {
      return JSON.stringify(this.locationData);
   }

   async saveNewCustomerAddress(dataAddress: any) {

      if (this.customer_id) {
         this.spinner.show();
         const resultSaveAddress: { result: boolean, data?: any } = await this.saveCustomerAddress(dataAddress, this.customer_id)
         this.spinner.hide();
         if (resultSaveAddress.result) {
            this.customerAddresses = [...this.customerAddresses, resultSaveAddress?.data]
            this.selectedAddress.setValue(resultSaveAddress?.data)
            this.selectedAddressAux = { ...resultSaveAddress?.data }
            this.toastr.success('', this.language == 'fr' ? "Adresse enregistrée avec succès." : "Address saved successfully.");
         } else {
            this.toastr.error('', this.language == 'fr' ? "Quelque chose s'est mal passé lors de l'enregistrement de l'adresse." : "Something went wrong while saving address.");
            return;
         }
      }

      // this.handleAddressChange(dataAddress, false, modal)
   }

   saveCustomerAddress(address, customer_id): Promise<{ result: boolean, data?: any }> {
      let city = null;
      let zipcode = null;

      address?.address_components.forEach(data => {
         if (data.types[0] == "locality") {
            city = data.long_name;
         }
         if (data.types[0] == "postal_code") {
            zipcode = data.long_name;
         }
      });

      const ADDRESS_DATA: Address = {
         latitude: address?.geometry.location.lat(),
         longitude: address?.geometry.location.lng(),
         address: address?.aliasEn || '-',
         address_fr: address?.aliasFr || '-',
         landmark: address?.formatted_address,
         city: city,
         zipcode: zipcode,
         default: false
      }
      return new Promise((resolve, reject) => {
         this.services.addNewAddressClient(customer_id, ADDRESS_DATA).subscribe(
            (res: any) => {
               if (res?.code === 200) {
                  resolve({ result: true, data: res?.direction })
               } else {
                  reject({ result: false })
               }
            }, (err: any) => {
               reject(false)
            }
         );
      })
   }

   modalMapClose(modal: NgbModalRef) {
      if (this.selectedAddress.value?.address === 'New address') {
         this.selectedAddress.setValue(this.selectedAddressAux);
      }
      modal.close();
   }

}




// this.customer_type == 2