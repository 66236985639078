import { Component, OnInit, ViewChild } from '@angular/core';

import { CancelledOrdersComponent } from '../cancelled-orders/cancelled-orders.component';
import { ChargedOrdersComponent } from '../charged-orders/charged-orders.component';
import { CouriersSummaryComponent } from '../couriers-summary/couriers-summary.component';
import { DeliveredOrdersComponent } from '../delivered-orders/delivered-orders.component';
import { GlobalSummaryComponent } from '../global-summary/global-summary.component';
import { TagsSummaryComponent } from '../tags-summary/tags-summary.component';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  selectedTabIndex = 'global-summary';
  @ViewChild(GlobalSummaryComponent) globalSummaryComponent: GlobalSummaryComponent;
  @ViewChild(CouriersSummaryComponent) couriersSummaryComponent: CouriersSummaryComponent;
  @ViewChild(DeliveredOrdersComponent) deliveredOrdersComponent: DeliveredOrdersComponent;
  @ViewChild(ChargedOrdersComponent) chargedOrdersComponent: ChargedOrdersComponent;
  @ViewChild(CancelledOrdersComponent) cancelledOrdersComponent: CancelledOrdersComponent;
  @ViewChild(TagsSummaryComponent) tagsSummaryComponent: CancelledOrdersComponent;

  constructor() { }

  ngOnInit(): void {
  }

  onTabChange(event) {

    this.selectedTabIndex = event.id;

  }

}
