import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
// export class AuthGuard implements CanActivate {
//   canActivate(
//     next: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     return true;
//   }

// }

export class AuthGuard implements CanActivate {


  constructor(private auth: AuthService,
    private myRoute: Router) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.auth.isLoggedIn()) {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));

      // if (!currentUser?.contract?.isSigned && state.url !== '/sign-contract') {

      //   this.myRoute.navigate(["sign-contract"]);

      //   return false;
      // }
      // else if (!currentUser?.isSubscription && currentUser?.contract?.isSigned && state.url !== '/subscription-payment' && state.url !== '/profile') {
      //   this.myRoute.navigate(["subscription-payment"]);

      //   return false;
      // }

      return true;
    } else {
      this.myRoute.navigate(["login"]);
      return false;
    }
  }
}
