<google-map
  [height]="height"
  [width]="width"
  [center]="center"
  [zoom]="zoom"
  (mapMousemove)="move($event)"
  [options]="{
    gestureHandling: 'greedy',
    styles: [{ stylers: [{ saturation: -50 }, { lightness: 20 }] }],

  }"
  (mapClick)="onMapClick($event)"
  (zoomChanged)="onZoomChange()"
>
  <map-marker
    *ngFor="let item of markers"
    #marker="mapMarker"
    (mapDragend)="onDragend($event)"
    [icon]="item.marker?.icon || icon"
    [position]="item.marker?.getPosition()"
    (mapClick)="openInfoWindow(marker, item.data)"
    [visible]="item.marker?.getVisible()"
    [options]="{ draggable: draggable }"
  >
  </map-marker>
  <map-marker-clusterer [imagePath]="markerClustererImagePath" [maxZoom]="20">
    <ng-container *ngFor="let item of markers">
      <ng-container *ngFor="let job of item.data?.jobs">
        <map-marker
          *ngIf="job.job_status !== 'CN' && !job.isExpired"
          #jobMarker="mapMarker"
          [position]="job.marker?.getPosition()"
          [icon]="job.marker?.icon"
          (mapClick)="openInfoWindow(jobMarker, item.data, job)"
        ></map-marker>
      </ng-container>
    </ng-container>
  </map-marker-clusterer>
  <map-info-window>
    <ng-container *ngIf="dynamicContentTemplate; else driverBlock">
      <ng-container *ngTemplateOutlet="dynamicContentTemplate"></ng-container>
    </ng-container>
    <ng-template #driverBlock>
      <ng-container *ngIf="selectedMarkerData; else houseBlock">
        <app-info-window-content
          [data]="selectedMarkerData"
        ></app-info-window-content>
      </ng-container>
    </ng-template>
    <ng-template #houseBlock>
      <app-single-job-info-window-content
        [data]="selectedHouseMakerData"
      ></app-single-job-info-window-content>
    </ng-template>
  </map-info-window>
</google-map>
<div class="lat_long">
  <div><span>Latitude :</span> {{ display?.lat }}</div>
  <div><span>Longitude :</span> {{ display?.lng }}</div>
</div>
