import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  NgbDate
} from '@ng-bootstrap/ng-bootstrap';
import {
  BsModalRef,
  BsModalService
} from 'ngx-bootstrap/modal';
import {
  NgxSpinnerService
} from 'ngx-spinner';
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';
import { ModalComponent } from "../../shared/component/modal/modal.component";
import { CommonMethods } from '../_helpers/_common-methods';
import { JobDetailsComponent } from '../job-details/job-details.component';
const FileSaver = require('file-saver');
@Component({
  selector: 'app-charged-orders',
  templateUrl: './charged-orders.component.html',
  styleUrls: ['./charged-orders.component.scss']
})
export class ChargedOrdersComponent implements OnInit {
  @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
  @ViewChild(JobDetailsComponent) jobDetailsComponent: JobDetailsComponent;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  pageNumber = 1;
  searchEvent = '';
  startDate: string;
  endDate: string;
  allCompletedJobsCount = 0;
  allCompletedJobs = [];
  userData: any;

  srcPdf: any;
  job_id: string
  bsModalRef: BsModalRef;

  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private commonMethods: CommonMethods,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("currentUser"));
  }

  //API CALLED HERE
  getChargedPublicJobs() {
    this.spinner.show();
    this.services.getChargedPublicJobs(this.userData._id, this.pageNumber, this.searchEvent, this.startDate, this.endDate).pipe().subscribe(data => {
      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      console.log(data, 'cancelled data');
      if (data.code == 200) {
        this.allCompletedJobs = data.result;
        this.allCompletedJobsCount = data.count;
        this.spinner.hide();
        return
      } else {
        this.allCompletedJobs = [];
        this.allCompletedJobsCount = 0;
        this.spinner.hide();
        return;
      }
    });
  }

  //ON PAGE CHANGE EVENT
  onPageChange(event) {
    this.pageNumber = event;
    this.getChargedPublicJobs();
  }


  //ON JOBS SEARCH
  searchJobs(event) {
    this.pageNumber = 1;
    this.searchEvent = event ? event : '';
    if (event.length == 0) {
      localStorage.setItem('userSearch', JSON.stringify(''));
      //return this.ngOnInit();
      return this.getChargedPublicJobs();
    }
    if (this.searchEvent.length > 2) {
      this.getChargedPublicJobs();
    }
  }

  //JOB DETAILS FUNCTION STAR HERE
  jobDetails(job_id, content, header) {
    this.job_id = job_id
    const initialState = {
      content: content,
      header: header,
    };

    this.bsModalRef = this.modalService.show(ModalComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      class: `modal-xxl modal-dialog-centered`,
    });

  }

  getPdf() {
    if (!this.startDate) {
      this.toastr.error('error', 'Please enter start date');
      return false;
    } else if (!this.endDate) {
      this.toastr.error('error', 'Please enter end date');
      return false;
    }
    this.spinner.show();
    this.services.getChargedOrderPdf(this.userData._id, this.startDate, this.endDate, '1').pipe().subscribe(data => {

      if (data.code == 400) {
        this.spinner.hide();
        this.toastr.error('', data.message);
        this.logoutFunction.logout();
        return;
      }
      if (data.code == 200) {
        const bufferDataArray = new Uint8Array(data.pdf.data).buffer;
        let pdfBlob = new Blob([bufferDataArray], { type: 'application/pdf' });

        this.downloadPdf(pdfBlob);
        this.spinner.hide();
        return
      } else {
        this.toastr.error('', data.message);
        this.spinner.hide();
        return;
      }

    })

  }

  downloadPdf(pdfBlob) {
    let date = new Date().toDateString();
    var currentDate = this.commonMethods.formatDate(date);
    var pdfDate = this.commonMethods.modifyPdfDate(currentDate)
    const pdfName = 'Pharmacy_Charged_Summary' + pdfDate + '.pdf';
    FileSaver.saveAs(pdfBlob, pdfName);
  }

}