<ng-template #dynamicContent>
  <app-button
    [imageSrc]="'./assets/img/new-ui/add_new_card.svg'"
    [buttonText]="'CARD.add_new_card' | translate"
    (buttonClick)="openAddNewCardModal()"
  ></app-button>
</ng-template>
<div class="mt-3">
  <app-table
    [theadTemplate]="theadTemplate"
    [tbodyTemplate]="tbodyTemplate"
    [noFoundTemplate]="noFoundTemplate"
  ></app-table>
  <ng-template #theadTemplate>
    <tr>
      <th></th>
      <th>{{ "CARD.card_number" | translate }}</th>
      <th>{{ "CARD.card_holder" | translate }}</th>
      <th>{{ "CARD.expiry_date" | translate }}</th>
      <th>{{ "CARD.primary" | translate }}</th>
      <th>ACTION</th>
    </tr>
  </ng-template>
  <ng-template #tbodyTemplate>
    <tbody>
      <tr
        *ngFor="
          let userCard of userCards
            | paginate
              : {
                  itemsPerPage: 10,
                  currentPage: pageNumber,
                  totalItems: userCards.length
                }
        "
      >
        <td *ngIf="userCard.brand == 'Visa'; else cardElse">
          <img
            src="assets/img/visa.png"
            style="width: 42px"
            (click)="selectCard(userCard.id)"
          />
        </td>
        <ng-template #cardElse>
          <td (click)="selectCard(userCard.id)">
            <img src="assets/img/master-card.png" style="width: 42px" />
          </td>
        </ng-template>

        <td (click)="selectCard(userCard.id)">
          ****-****-****-{{ userCard.last4 }}
        </td>

        <td
          *ngIf="userCard.name; else nameElse"
          (click)="selectCard(userCard.id)"
        >
          {{ userCard.name }}
        </td>
        <ng-template #nameElse>
          <td (click)="selectCard(userCard.id)">N/A</td>
        </ng-template>

        <td (click)="selectCard(userCard.id)">
          {{ showToFixedNumber(userCard.exp_month) }}/{{ userCard.exp_year }}
        </td>
        <td (click)="selectCard(userCard.id)">
          <div class="custom-control custom-checkbox mb-3">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customCheck"
              name="example1"
              [checked]="userCard.id == selectedCard ? true : null"
              disabled
            />
            <label class="custom-control-label" for="customCheck"></label>
          </div>
        </td>
        <td>
          <ul class="action-icons">
            <li>
              <img
                src="assets/img/new-ui/delete.svg"
                (click)="deleteCardModal(userCard.id)"
                ngbTooltip="Remove"
              />
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </ng-template>
  <ng-template #noFoundTemplate>
    <div class="no-jobs-found">
      <p class="no-item text-center text-danger mb-0" *ngIf="!userCards.length">
        {{ "CARD.no_card_found" | translate }}
      </p>
    </div>
  </ng-template>
</div>

<!-------modal---->
<ng-template #addNewCardTemplate>
  <p class="sub-title center-sub-title">{{ "CARD.card_info" | translate }}</p>

  <div class="d-flex flex-column gap-1">
    <div class="d-flex align-items-center">
      <label class="col-3 px-0 font-weight-bold">{{
        "CARD.card_number" | translate
      }}</label>

      <div class="form-row col-2 px-0">
        <input
          type="text"
          maxlength="4"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          class="form-control"
          name="card-number-part1"
          id="card-number-part1"
          [(ngModel)]="card_number_part1"
          (ngModelChange)="cc_format($event)"
          class="form-textbox"
          required
        />
        <label for="c-num" class="form-label">XXXX</label>
      </div>
      -
      <div class="form-row col-2 px-0">
        <input
          type="text"
          maxlength="4"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          class="form-control"
          name="card-number-part2"
          id="card-number-part2"
          [(ngModel)]="card_number_part2"
          (ngModelChange)="cc_format($event)"
          class="form-textbox"
          required
        />
        <label for="c-num" class="form-label">XXXX</label>
      </div>
      -
      <div class="form-row col-2 px-0">
        <input
          type="text"
          maxlength="4"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          class="form-control"
          name="card-number-part3"
          id="card-number-part3"
          [(ngModel)]="card_number_part3"
          (ngModelChange)="cc_format($event)"
          class="form-textbox"
          required
        />
        <label for="c-num" class="form-label">XXXX</label>
      </div>
      -
      <div class="form-row col-2 px-0">
        <input
          type="text"
          maxlength="4"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          name="card-number-part4"
          id="card-number-part4"
          [(ngModel)]="card_number_part4"
          (ngModelChange)="cc_format($event)"
          class="form-textbox"
          required
        />
        <label for="c-num" class="form-label">XXXX</label>
      </div>
    </div>

    <div class="d-flex align-items-center">
      <label class="col-3 px-0 font-weight-bold">{{
        "CARD.card_holder1" | translate
      }}</label>
      <div class="col-9 px-0 form-row">
        <input
          type="text"
          id="c-holder"
          data-stripe="card_holder"
          [(ngModel)]="card_holder"
          class="form-textbox"
          required
        />
        <label for="c-holder" class="form-label">{{
          "CARD.card_holder1" | translate
        }}</label>
      </div>
    </div>

    <div class="d-flex align-items-center">
      <label class="col-3 px-0 font-weight-bold">{{
        "CARD.expiry_date" | translate
      }}</label>
      <div class="col-md-4 px-0">
        <div class="form-row">
          <input
            type="text"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            maxlength="2"
            required
            data-stripe="exp_month"
            [(ngModel)]="expiry_month"
            class="form-textbox"
            required
          />
          <label for="date" class="form-label">{{
            "CARD.MM" | translate
          }}</label>
        </div>
      </div>
      <div class="col-1">/</div>

      <div class="col-md-4 px-0">
        <div class="form-row">
          <input
            type="text"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            maxlength="4"
            required
            data-stripe="exp_month"
            name="expYear"
            [(ngModel)]="expiry_year"
            class="form-textbox"
            required
          />
          <label for="date" class="form-label">{{
            "CARD.YY" | translate
          }}</label>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <label class="col-3 px-0 font-weight-bold">{{
        "CARD.CVC" | translate
      }}</label>
      <div class="col-md-9 px-0 form-row">
        <input
          [type]="showCvc ? 'text' : 'password'"
          oninput="this.value = this.value.replace(/[^0-9.]/g,
      '').replace(/(\..*)\./g, '$1');"
          class="form-textbox"
          data-stripe="cvc"
          [(ngModel)]="cvc_code"
          maxlength="4"
          required
        />
        <label for="cvv" class="form-label">{{ "CARD.CVC" | translate }}</label>
        <app-toggle-icon [(isActive)]="showCvc"></app-toggle-icon>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <label class="col-3 px-0 font-weight-bold">{{
        "CARD.primary_control" | translate
      }}</label>
      <div class="col-md-9 px-0">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            [(ngModel)]="isPrimary"
            class="custom-control-input"
            id="customCheck"
            name="example1"
          />
          <label class="custom-control-label" for="customCheck"></label>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- /.modal -->

<!-- CONFIRMATION MODEL     -->
<div
  bsModal
  #successModal2="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-danger" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button
          type="button"
          class="close"
          (click)="cancel_modal()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Do you really want to delete this card ?</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="cancel_modal()"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-danger" (click)="deleteCard()">
          Delete
        </button>
      </div>
    </div>
  </div>
</div>
