export enum DailyType {
    ALL_DAYS = 1,
    MON_SAT = 2,
    MON_FRI = 3
}

export enum RecursiveLabelType {
    INSTANT = 'OT',
    DAiLY = 'D',
    MONTHLY = 'M',
    WEEKLY = 'W',
    BEWEEKLY = 'BW',
    FOURWEEKS = 'FW'
}
export enum RecursiveNumberType {
    INSTANT = 1,
    DAiLY,
    WEEKLY,
    MONTHLY,
    BIWEEKLY,
    FOURWEEKS
}

export enum JobType {
    DELIVERY = 1,
    PICKUP,
    PASSERA
}

export enum CustomerType {
    RESIDENCE = 1,
    CLIENT = 2,
    MEDICAL = 3,
    CPO = 4
}

export enum DeliveryType {

}

export enum JobProviderType {
    PUBLIC = 1,
    PRIVATE,
    DISPATCH_COMPANY
}

export enum DateTab {
    Today = 'Today',
    Week = 'Week',
    Month = 'Month',
}