import { Component, OnInit, ViewChild } from '@angular/core';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ServicesService
} from '../../services.service';
import {
  ToastrService
} from 'ngx-toastr';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  ModalDirective
} from 'ngx-bootstrap/modal';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';

import 'moment-timezone';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  @ViewChild('deleteProduct') public deleteProduct: ModalDirective;

  public product = [];
  productDetail = [];
  allProductCount = 0;
  currentPage: 0;
  submitted = false;
  page = 1;
  public language = "en";
  sort = "0";
  filter = '';
  localData: any;
  customer_id: any;
  pageNumber:any;
  id : '';
  idPresentation: any;
  presentationName: '';
  nameProduct: '';
  nameProductFr: '';
  description: '';
  descriptionFr: '';
  price: '';
  size: '';
  quantity: '';
  type: '';
  status: '';
  stock: '';

  

  constructor(
    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    public routeAct: ActivatedRoute,
    private spinner: NgxSpinnerService,
  ) {
    
   }

  ngOnInit(): void {

    this.sort = '0';
    this.submitted = false;
    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en"
    }

    this.id = this.routeAct.snapshot.params["id"];

    this.page = 1;
    this.filter = '';
    this.getProduct(this.id);
  //  this.getPresent(this.idPresentation);
    
  }

  getProduct(id) {
    this.spinner.show();
    this.services.product(id).pipe().subscribe(data => {
     
        this.spinner.hide();
        this.product = data;
        this.productDetail = data[0].productDetails;
        
        console.log(data);
        console.log(this.productDetail);

        /*

        for(let i=0; i< this.allOrderDetail.length; i++){
          console.log(this.allOrderDetail[i].products[0].name);
          this.nameProduct = this.allOrderDetail[i].products[0].name;
          this.price =  this.allOrderDetail[i].productsDetails[0].price
          this.quantity = this.allOrderDetail[i].productsDetails[0].quantity
          this.detail = this.allOrderDetail[i].products[0].detail;
          console .log(this.price);

        }
        */
        for(let i = 0; i< this.productDetail.length; i++){
          this.nameProduct = this.productDetail[i].name;
          this.price = this.productDetail[i].price
          this.size = this.productDetail[i].unit_type.name;
          this.quantity = this.productDetail[i].quantity;
          this.type = this.productDetail[i].presentation.name;
          this.status = this.productDetail[i].active;
          this.stock = this.productDetail[i].statusD;

        }

        this.idPresentation = data[0].productDetails[0].presentation.name;
    //    console.log(this.idPresentation);



    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });
  }

/*
  getPresent(idPresentation) {
    var localData = JSON.parse(localStorage.getItem("currentId"));
    console.log(localData);
    this.services.getPresentation(localData).pipe().subscribe(result => {
     // console.log(result);
      
    }, 
      error => {
      //  console.log(localData);
        this.spinner.hide();
        return this.toastr.error('error', error.error.message);
    });
  }

  */
  
  back(){
    console.log(this.idPresentation);
    this.router.navigate(['/product']);
  }
}
