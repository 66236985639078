import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { TabsetComponent } from "ngx-bootstrap/tabs";
import { LanguageService } from "../../../services/language.service";

interface Job {
  job_status: string;
}
@Component({
  selector: "app-info-window-content",

  templateUrl: "./info-window-content.component.html",
  styleUrls: ["./info-window-content.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class InfoWindowContentComponent implements OnInit, OnChanges {
  @Input() data: any;
  @ViewChild(TabsetComponent) tabset: TabsetComponent;
  jobGroups: Record<string, Job[]> = {};
  public statuses = ["A", "C", "N", "P"];
  public language: string = "en";
  isCollapsed = true;
  jobs: any[]
  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.language = localStorage.getItem("language")
      ? JSON.parse(localStorage.getItem("language"))
      : "en";
    this.translate.use(this.language);
    this.languageService.getLanguage().subscribe((language) => {
      this.language = language;
      this.cdr.detectChanges();
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    const { data } = changes;

    if (data.currentValue) {
      this.jobs = [...data.currentValue.jobs];
      this.groupJobsByStatus(data.currentValue.jobs);
    }
  }
  private groupJobsByStatus(jobs: Job[]): void {
    for (const status of this.statuses) {
      this.jobGroups[status] = jobs?.filter((job) => job.job_status === status);
    }
  }

  closeInfoWindow(): void {
    this.tabset.tabs[0].active = true;
  }

  getTranslatedHeading(status): string {
    const translatedText = this.translate.instant(
      "DASHBOARD.JOB." + status
    );
    return `${translatedText} (${this.jobGroups[status]?.length})`;
  }
}
