import { Component, OnInit, ViewChild } from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  ModalDirective
} from 'ngx-bootstrap/modal';
import {
  NgxSpinnerService
} from "ngx-spinner";
import {
  ToastrService
} from 'ngx-toastr';
import {
  DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
  ServicesService
} from '../../services.service';

import 'moment-timezone';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  @ViewChild('deleteCategory') public deleteCategory: ModalDirective;

  public allCategory = [];
  allCategoryCount = 0;
  currentPage: 0;
  submitted = false;
  page = 1;
  public language = "en";
  sort = 1;
  filter = '';
  localData: any;
  customer_id: any;
  pageNumber: any;



  constructor(

    public logoutFunction: DefaultLayoutComponent,
    public toastr: ToastrService,
    private services: ServicesService,
    public router: Router,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {

    this.sort = 1;
    this.submitted = false;
    this.language = JSON.parse(localStorage.getItem("language"));
    if (!this.language) {
      this.language = "en"
    }

    this.page = 1;
    this.filter = '';
    this.getAllCategory(this.filter, this.page, this.sort);
  }


  //SEARCH CLIENTS FUNCTION START HERE
  searchCategory(event) {
    if (event.length == 0) {
      return this.ngOnInit();
    }
    if (event.length <= 0) {
      return console.log('less then 2');
    }

    console.log(event);
    this.filter = event;
    this.page = 1;
    this.sort = 1;
    this.getAllCategory(this.filter, this.page, this.sort)
  }

  /*
  //SEARCH CLIENTS FUNCTION START HERE
  searchCategory(event) {
    if (event.length == 0) {
      return this.ngOnInit();
    }
    if (event.length <= 0) {
      return console.log('less then 2');
    }

    console.log(event);
    this.filter = event;
    this.page = 1;
    this.sort = '0';
   // this.brandAll(this.filter, this.page);
  }

  */

  //GET ALL CLIENT API START HERE
  getAllCategory(filter, page, sort) {
    this.spinner.show();
    this.services.categoryAll(filter, page, sort).pipe().subscribe(data => {

      this.spinner.hide();
      this.allCategory = data.entityList;
      this.allCategoryCount = data.totalRegister;
      this.pageNumber = data.currentPage;
      //console.log(data);


    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });

  }

  //ON PAGE CHANGE FUNCTION
  onPageChange(page) {
    this.page = page;
    this.getAllCategory(this.filter, this.page, this.sort);
  }

  //DELETE CLIENT CONFIRMATION MODEL
  openDeleteModel(_id) {
    this.customer_id = _id;
    this.deleteCategory.show();
  }

  deleteExistCategory() {

    this.spinner.show();
    this.services.deleteCategory(this.customer_id).pipe().subscribe(data => {

      this.spinner.hide();
      this.deleteCategory.hide();
      // this.ngOnInit();
      this.getAllCategory(this.filter, this.page, this.sort)

    }, error => {
      this.spinner.hide();
      return this.toastr.error('error', error.error.message);
    });
  }


}
