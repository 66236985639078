import { Component, OnInit, Self, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DefaultLayoutComponent } from '../../containers';
import { PushNotificationService } from '../../push-notification.service';
import { PusherServiceService } from '../../pusher-service.service';
import { ServicesService } from '../../services.service';
import { RealtimeService } from '../../services/realtime/realtime.service';
import { UnsubscribeService } from '../../services/subscription/unsubscribe.service';

@Component({
  selector: 'app-update-profile-modal',
  templateUrl: './update-profile-modal.component.html',
  styleUrls: ['./update-profile-modal.component.scss'],
  providers: [UnsubscribeService]
})
export class UpdateProfileModalComponent implements OnInit {
  @ViewChild('notificationProfile') public notificationProfile: ModalDirective;
  user_id = '';
  localData: any;

  constructor(
    private services: ServicesService,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public logoutFunction: DefaultLayoutComponent,
    private router: Router,
    private pusher: PusherServiceService,
    private notifications: PushNotificationService,
    private realtime: RealtimeService,
    @Self() private unsub: UnsubscribeService,
  ) {

    this.unsub.subs = this.realtime.event$.subscribe({
      next: (e) => {
        if (e?.event == 'new_order') {
          this.pusherNotifications(e);
        }
      }
    });
  }

  ngOnInit(): void {

    this.localData = JSON.parse(localStorage.getItem("currentUser"));
    this.user_id = this.localData?._id;
    if (this.user_id) {
      this.getUpdateProfile();
    }

  }


  public redirectToUpdateProfile() {
    sessionStorage.setItem("profile_tab_index", "edit-profile");
    this.router.navigate(["profile"]);
    this.notificationProfile.hide();

  }

  private pusherNotifications(e) {
    const orderStatusType = ['Order sent', 'Order received', 'Order confirmed', 'Order rejected'];
    if (orderStatusType.includes(e.data.status)) {
      this.notifications.showCustomNotification({
        title: 'Nueva orden', body: 'Se ha creado la orden' + e.data.code
      },
        () => {
          this.router.navigate(['/prescriptions/' + e.data.id]);
        });
    }
  }

  private getUpdateProfile() {
    this.services.updateProfile(this.user_id).pipe().subscribe(data => {
      if (data.code == 404) {
        this.spinner.hide();
        this.notificationProfile.show()
      } else {
        this.spinner.hide();
      }
    });
  }

}
// PR