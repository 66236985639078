import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    Router
} from '@angular/router';
import {
    NgxSpinnerService
} from 'ngx-spinner';
import {
    ToastrService
} from 'ngx-toastr';
import {
    DefaultLayoutComponent
} from '../../containers/default-layout/default-layout.component';
import {
    ServicesService
} from '../../services.service';
import { RouteService } from '../../services/route.service';
import { CommonMethods } from '../_helpers/_common-methods';
@Component({
    selector: 'app-global-summary-details',
    templateUrl: './global-summary-details.component.html',
    styleUrls: ['./global-summary-details.component.scss']
})
export class GlobalSummaryDetailsComponent implements OnInit {
    @ViewChild('dynamicContent', { static: false }) dynamicContentTemplate: TemplateRef<any>;
    pageNumber = 1;
    searchEvent = '';
    allCompletedJobsCount = 0;
    allCompletedJobs = [];
    userData: any;
    @Input() job_id = [];
    @Input() show: boolean = false
    @Output() showChange = new EventEmitter<boolean>();


    constructor(
        public logoutFunction: DefaultLayoutComponent,
        public toastr: ToastrService,
        private services: ServicesService,
        public router: Router,
        private spinner: NgxSpinnerService,
        public commonMethod: CommonMethods,
        private routeService: RouteService
    ) { }

    ngOnInit() {

        this.userData = JSON.parse(localStorage.getItem("currentUser"));

        this.getjobsAccPaymentMethodSelection();
    }



    //API CALLED HERE
    getjobsAccPaymentMethodSelection() {
        this.spinner.show();
        this.services.getjobsAccPaymentMethodSelection(this.userData._id, this.job_id, this.pageNumber, this.searchEvent).pipe().subscribe(data => {
            if (data.code == 400) {
                this.spinner.hide();
                this.toastr.error('', data.message);
                this.logoutFunction.logout();
                return;
            }
            console.log(data, 'cancelled data');
            if (data.code == 200) {
                this.allCompletedJobs = data.result;
                this.allCompletedJobsCount = data.count;
                this.spinner.hide();
                return
            } else {
                this.allCompletedJobs = [];
                this.allCompletedJobsCount = 0;
                this.spinner.hide();
                return;
            }
        });
    }

    //ON PAGE CHANGE EVENT
    onPageChange(event) {
        this.pageNumber = event;
        this.getjobsAccPaymentMethodSelection();
    }

    //SEARCH PAGES
    searchJobs(event) {
        this.pageNumber = 1;
        this.searchEvent = event ? event : '';
        if (event.length == 0) {
            return this.ngOnInit();
        }
        if (this.searchEvent.length > 2) {
            this.getjobsAccPaymentMethodSelection();
        }
    }

    BackPage() {

        this.show = false;
        this.showChange.emit(this.show);
    }
}