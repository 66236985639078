import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps";
import { Marker } from "../../../services/interfaces/googleMaps.interface";
import { InfoWindowContentComponent } from "../info-window-content/info-window-content.component";

@Component({
  selector: "app-google-map",
  templateUrl: "./google-map.component.html",
  styleUrls: ["./google-map.component.scss"],
})
export class GoogleMapComponent implements OnChanges {
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  @ViewChild(InfoWindowContentComponent)
  infoWindowContentComponent: InfoWindowContentComponent;
  @ViewChild(GoogleMap) googleMap: GoogleMap;
  @Input() height: string = "500px";
  @Input() width: string = "100%";
  @Input() center: google.maps.LatLngLiteral = { lat: 24, lng: 12 };
  @Input() zoom = 10;
  display: google.maps.LatLngLiteral;
  @Input() icon: string;
  @Input() draggable = false;
  @Input() markers: Marker[] = [];
  @Output() mapClick = new EventEmitter<any>();
  @Output() dragEnd = new EventEmitter<any>();
  selectedMarkerData: any;
  selectedHouseMakerData: any;
  dynamicContentTemplate: TemplateRef<any>
  markerClustererImagePath =
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m";
  offset = 0.000005;
  ngOnChanges(changes: SimpleChanges) {
    const { markers } = changes;

    if (markers && markers.currentValue) {

      const selectedMarker = markers.currentValue.find(
        (marker) => marker.data?._id === this.selectedMarkerData?._id
      );

      if (selectedMarker && selectedMarker.data) {
        this.selectedMarkerData = { ...selectedMarker.data };
      }
    }
  }
  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    this.center = {
      lat: currentUser?.lat_long[0],
      lng: currentUser?.lat_long[1],
    };
  }

  onZoomChange() {
    this.zoom = this.googleMap.getZoom();
  }


  move(event: google.maps.MapMouseEvent) {
    this.display = event.latLng.toJSON();
  }

  onMapClick(event: google.maps.MapMouseEvent) {
    this.mapClick.emit(event.latLng.toJSON());
  }
  onDragend(event: google.maps.MapMouseEvent) {
    this.dragEnd.emit(event.latLng.toJSON());
  }
  openInfoWindow(marker: MapMarker, data: any, job: any = null) {
    if (!data) return;

    if (job) {
      this.selectedMarkerData = null;
      this.selectedHouseMakerData = { job, fullname: data.fullname };
    }
    else if (data instanceof TemplateRef) {
      this.dynamicContentTemplate = data;
    }
    else {
      this.selectedMarkerData = data;
    }

    this.infoWindow.open(marker);

    this.infoWindow.closeclick.subscribe(() => {
      this.infoWindowContentComponent?.closeInfoWindow();
    });
  }

  areMarkersOverlapping(
    position1: google.maps.LatLng,
    position2: google.maps.LatLng
  ) {
    // Compare the positions of two markers to determine if they are overlapping
    const latDiff = Math.abs(position1.lat() - position2.lat());
    const lngDiff = Math.abs(position1.lng() - position2.lng());
    return latDiff < this.offset && lngDiff < this.offset; // Adjust the threshold as needed
  }

  private getAllJobs(markers: any[]) {
    return markers.map((marker) => marker.data?.jobs?.map((job) => job)).flat();
  }
  calculateBounds(markers: any[]) {
    this.markers = markers;
    const allJobs = this.getAllJobs(markers);

    if (allJobs.length === 0) {
      return;
    }
    this.findOverlappingMarkers(markers);
    const bounds = new google.maps.LatLngBounds();

    for (const item of allJobs) {
      bounds.extend(item.marker.getPosition());
    }
    // include driver marker within bounds when one is selected

    if (this.markers.length === 1 && this.markers[0].data.lat_long.length === 2) {
      bounds.extend(this.markers[0].marker.getPosition());
    }

    this.googleMap.fitBounds(bounds);
    this.center = bounds.getCenter().toJSON();
  }

  findOverlappingMarkers(markers: any[]) {
    const data = this.getAllJobs(markers);
    for (let i = 0; i < data.length; i++) {
      for (let j = i + 1; j < data.length; j++) {
        if (
          this.areMarkersOverlapping(
            data[i].marker.getPosition(),
            data[j].marker.getPosition()
          )
        ) {
          // If markers i and j are overlapping, adjust the position of marker j
          const newPosition = {
            lat: data[j].marker.getPosition().lat() + this.offset,
            lng: data[j].marker.getPosition().lng() + this.offset,
          };
          data[j].marker.setPosition(newPosition);
          this.markers.map((marker) => {
            const index = marker.data.jobs.findIndex(
              (job) => job._id === data[j]._id
            );
            if (index !== -1) {
              marker.data.jobs[index].marker.setPosition(newPosition);
            }
          });
        }
      }
    }
  }
}
