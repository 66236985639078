import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PushNotificationService } from '../../push-notification.service';
import { PusherServiceService } from '../../pusher-service.service';

@Injectable({
  providedIn: 'root'
})
export class RealtimeService {
  private _event$ = new BehaviorSubject<any | null>(null);

  private user_id = '';
  private localData: any;

  public get event$(): Observable<any> | null {
    return this._event$.asObservable();
  }
  public set event(value: any | null) {
    this._event$.next(value);
  }

  private _init() {
    this.notifications.requestPermission();
    const pharmChannel = this.pusher.init(this.user_id);
  }

  constructor(
    private pusher: PusherServiceService,
    private notifications: PushNotificationService,
  ) {
    this.localData = JSON.parse(localStorage.getItem("currentUser"));
    console.log(this.localData);
    if (this.localData !== null) {
      this.user_id = this.localData?._id;
      this._init();
    }

  }

}
